import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getTipId, onFinishTip } from '../../Redux/tipDuck'
import { formatNumber } from "../../utils";
import ItemTableTip from "./item.table.tip";
import TemplateMenu from '../../template/menu.template'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toogleLoader } from '../../Redux/alertDuck'

const API_URL = process.env.REACT_APP_API_URL


const EditTipPage = ({ getTipId, fetching_by_id, tips, onFinishTip, pdfs, fetching_finish, toogleLoader }) => {

    const [data, setData] = useState({ branch_office: { title: '' }, amount: 0 })
    const [detail, setDetail] = useState([])
    const history = useHistory();
    const [total, setTotal] = useState(0)

    let { id } = useParams()

    console.log('fetching_by_id', fetching_by_id);

    useEffect(() => {
        if (fetching_finish === 2) {
            console.log('pdfs', pdfs);
            toogleLoader(false)

            console.log(window.open(`${API_URL}/${pdfs}`, '_blank'));
        }
    }, [fetching_finish])

    useEffect(() => {
        if (fetching_by_id === 2) {

            setData(tips)
            setDetail(tips.detail)

            let total_list = 0
            tips.detail.map(d => {
                let { workers } = d
                total_list += workers.reduce((a, b) => a = a + parseFloat(b.amount) - (parseFloat(b.amount) * (b.discount / 100)), 0);

            })

            setTotal(total_list)
            toogleLoader(false)

        }
    }, [fetching_by_id])

    useEffect(() => {
        toogleLoader(true)
        getTipId(id)
    }, [id])


    const onFinish = () => {
        toogleLoader(true)
        onFinishTip(id)
    }

    // let total_list = detail.reduce((a, b) => a = a + parseFloat(data.amount * (b.percent / 100)), 0



    return <TemplateMenu>
        <Row className="my-2">
            <Col><button className="btn btn-secondary btn-sm" onClick={() => history.push("/propinas")}>Volver</button></Col>
        </Row>
        <Row>
            <Col className="h3">Liquidación de ({data.date_formated}) a ({data.date_end_formated})</Col>
            <Col lg={2}>
                <button className="btn btn-info" onClick={onFinish}>Finalizar</button>
            </Col>
        </Row>
        <Row>
            {/* <Col>Sucursal: {data.branch_office.title}</Col> */}
        </Row>
        <Row>
            <Col>Total Propina: {formatNumber(data.amount)}</Col>
        </Row>
        <Row>
            <Col>
                <table className="table table-sm table-bordered">
                    <thead className="thead-dark">
                        <tr>
                            <th></th>
                            <th>Area</th>
                            <th>Porcentaje</th>
                            <th># Personas</th>
                            <th>Total a pagar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {detail.map(d => <ItemTableTip key={d.id} item={d} tip={data.amount} />)}
                        <tr>
                            <td colSpan={4}>TOTAL</td>
                            <td>{formatNumber(total)}</td>
                        </tr>
                    </tbody>
                </table>
            </Col>
        </Row>
    </TemplateMenu>
}
const mapsState = (props) => {
    let { tip } = props
    console.log('props.tip', props.tip);

    return {
        fetching_by_id: tip.fetching_by_id,
        tips: tip.row,
        pdfs: tip.pdfs,
        fetching_finish: tip.fetching_finish,
    }
}

export default connect(mapsState, { getTipId, onFinishTip, toogleLoader })(EditTipPage);