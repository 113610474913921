import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import TemplateMenu from "../../template/menu.template";
import { getReviewEndMonth } from "../../Redux/reportDuck";
import { connect } from "react-redux";
import { formatNumber } from "../../utils";
import ItemDetailTurn from "./item.detail.turn";
import ItemDetailWorker from "./item.detail.worker";
import ItemDetailIntern from "./item.detail.intern";
import ItemDetailExpense from "./item.detail.expense";

const ReportEndMonth = ({ getReviewEndMonth, data_end, fetching_by_end }) => {
  const [form, setForm] = useState({
    aldelo: 0,
    tips: 0,
    operation: 0,
    payroll: 0,
    turn: 0,
    store: 0,
    office: 0,
    settlement: 0,
    adelantos: 0,
    warehouse: 0,
    office: 0,
    liquidation: 0,
    expenses: [],
    total: 0,
    tax: 0,
    ingreso: 0,
    total_altern: 0
  });
  const [listIntern, setListIntern] = useState([]);
  const [listExpense, setListExpense] = useState([]);
  const [totalExpense, setTotalExpense] = useState(0);
  const [totalIntern, setTotalIntern] = useState(0);
  const [listExpenseGroup, setListExpenseGroup] = useState([]);
  const [listTurn, setListTurn] = useState([]);
  const [listFijos, setListFijos] = useState([]);
  const [quantityDelivery, setQuantityDelivery] = useState(0);
  const [totalDelivery, setTotalDelivery] = useState(0);
  const [listNovelty, setListNovelty] = useState([])
  const [listAdditional, setListAdditional] = useState([])
  const [showAdditional, setShowAdditional] = useState(false)

  const [branch, setBranch] = useState([]);
  useEffect(() => {
    getReviewEndMonth();
  }, []);

  useEffect(() => {
    if (fetching_by_end === 2) {
      let {
        results_totals,
        results_payroll,
        results_ingreso,
        results_settlement,
        results_expense,
        results_intern_payment,
        results_expense_ingreso,
        results_group_expense, allowed,
        result_expense_group,
        result_list_turn,
        result_list_worker,
        total_delivery,
        quantity_delivery,
        results_novelty

      } = data_end;

      setListNovelty(results_novelty)
      setQuantityDelivery(quantity_delivery)
      setTotalDelivery(total_delivery)

      setListTurn(result_list_turn.filter(d => d.is_turn === true))
      setListFijos(result_list_worker.filter(d => d.is_turn === false))

      setListExpenseGroup(result_expense_group)
      let aldelo = results_totals !== undefined ? results_totals.aldelo : 0;
      let tips = results_totals !== undefined ? results_totals.tips : 0;
      let turn = results_totals !== undefined ? results_totals.turn : 0;
      let operation =
        results_totals !== undefined ? results_totals.payments : 0;
      let adelantos =
        results_totals !== undefined ? results_totals.adelantos : 0;
      let diference =
        results_totals !== undefined ? results_totals.diferencia : 0;

      let _payroll_alter = { total: 0 }

      if (results_group_expense !== undefined) {
        _payroll_alter = results_group_expense.filter(d => d.concepto === 'NOMINA')[0];
      }

      let payroll = 0
      if (_payroll_alter !== undefined) {
        payroll = _payroll_alter.total
      }

      let settlement = { total: 0 }
      if (results_settlement !== undefined) {
        settlement = results_settlement.total;
      }

      let _group = 0

      if (data_end.results_group_expense !== undefined) {
        _group = data_end.results_group_expense.reduce(
          (a, b) => a + parseFloat(b.total),
          0
        );
      }


      let warehouse = data_end.expense_warehouse.total;
      let office = data_end.expense_office.total;
      let liquidation = data_end.expense_liquidation.total;
      let tax = data_end.results_tax.total;

      let expense =
        parseFloat(tips || 0) +
        parseFloat(operation || 0) +
        parseFloat(adelantos || 0) +
        parseFloat(turn || 0) +
        parseFloat(tax || 0) +
        parseFloat(_group);

      let _total_ingreso = results_expense_ingreso.reduce((a, b) => a + parseFloat(b.amount), 0)

      setListAdditional(results_expense_ingreso);

      let total = parseFloat(aldelo || 0) + parseFloat(_total_ingreso || 0) - (expense || 0);

      let total_altern =
        parseFloat(_total_ingreso || 0) - parseFloat(_group)

      setForm({
        ...form,
        ["aldelo"]: aldelo,
        ["allowed"]: allowed,
        ["ingreso"]: _total_ingreso,
        ["tips"]: tips || 0,
        ["turn"]: turn,
        ["fijos"]: result_list_worker.reduce((a, b) => a + parseFloat(b.total), 0),
        ["operation"]: operation,
        ["payroll"]: payroll,
        ["settlement"]: settlement,
        ["expenses"]: results_expense,
        ["adelantos"]: adelantos,
        ["warehouse"]: warehouse,
        ["office"]: office,
        ["liquidation"]: liquidation,
        ["total"]: total || 0,
        ["total_altern"]: total_altern,
        ["tax"]: tax,
        ["diference"]: diference,
      });

      setListIntern(data_end.results_intern_payment);
      setListExpense(data_end.results_group_expense);

      let _intern = data_end.results_intern_payment.reduce(
        (a, b) => a + parseFloat(b.total),
        0
      );
      setTotalIntern(_intern);

      setTotalIntern(_intern);
      setTotalExpense(_group);
      setBranch(results_ingreso);

      let branch = JSON.parse(localStorage.getItem("branch"))

      setBranch(branch)
    }
  }, [fetching_by_end]);

  console.log('listTurn--', listTurn);


  return (
    <TemplateMenu>
      <Row className="mt-4">
        <Col>
          <h4>REPORTE FIN DE MES</h4>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <Row>
            <Col>
              <h3>RESUMEN GENERAL</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              {(parseInt(branch.contribute_id) !== 3) ?
                <Table className="table-sm table-bordered table-hover">
                  <thead className="thead-dark">
                    <tr>
                      <th>Descripción</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>INGRESOS TOTALES</td>
                      <td align="right">{formatNumber(form.ingreso)}</td>
                    </tr>
                    <tr>
                      <td>GASTOS</td>
                      <td align="right">{formatNumber(totalExpense)}</td>
                    </tr>
                    <tr>
                      <td>TOTAL</td>
                      <td align="right">{formatNumber(form.total_altern)}</td>
                    </tr>
                  </tbody>
                </Table> :
                <Table className="table-sm table-bordered table-hover">
                  <thead className="thead-dark">
                    <tr>
                      <th>Descripción</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>VENTAS TOTAL</td>
                      <td align="right">{formatNumber(form.aldelo)}</td>
                    </tr>
                    <tr onClick={() => setShowAdditional(!showAdditional)}>
                      <td>INGRESO ADICIONAL</td>
                      <td align="right">{formatNumber(form.ingreso || 0)}</td>
                    </tr>
                    {showAdditional &&
                      <tr>
                        <td colSpan={2}>
                          <Table className="table table-sm table-bordered table-hovers">
                            <thead className="thead-dark">
                              <tr>
                                <th>Fecha</th>
                                <th>Observación</th>
                                <th>Monto</th>
                              </tr>
                            </thead>
                            <tbody>{listAdditional.map((d, k) =>
                              <tr key={k}>
                                <td>{d.registration_date}</td>
                                <td>{d.observation}</td>
                                <td align="right">{formatNumber(d.amount)}</td>
                              </tr>
                            )}</tbody>
                          </Table>

                        </td>
                      </tr>}
                    {/* <tr>
                      <td>DIFERENCIA DE CAJA</td>
                      <td align="right">{formatNumber(form.diference)}</td>
                    </tr> */}
                    <tr>
                      <td>PROPINA</td>
                      <td align="right">{formatNumber(form.tips || 0)}</td>
                    </tr>
                    <tr>
                      <td>PAGOS POS</td>
                      <td align="right">
                        {formatNumber(
                          parseFloat(form.operation || 0) +
                          parseFloat(form.adelantos || 0) +
                          parseFloat(form.turn || 0)
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td>COMISIÓN DATAFONO</td>
                      <td align="right">
                        {formatNumber(parseInt(form.tax || 0))}
                      </td>
                    </tr>
                    <tr>
                      <td>GASTOS</td>
                      <td align="right">{formatNumber(totalExpense)}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>TOTAL</b>
                      </td>
                      <td align="right">
                        <b>{formatNumber(parseInt(form.total || 0))}</b>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}></td>
                    </tr>
                  </tbody>
                </Table>}

            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <Table className="table-sm table-striped table-dark">
                <tbody>
                  <tr>
                    <td>NOMINA</td>
                    <td align="right">
                      {formatNumber(
                        parseFloat(form.payroll || 0) +
                        parseFloat(form.adelantos || 0) +
                        parseFloat(form.turn || 0)
                      )}
                    </td>
                  </tr>

                  {
                    (parseInt(branch.contribute_id) === 3) && <tr>
                      <td>BODEGA</td>
                      <td align="right">{formatNumber(form.warehouse || 0)}</td>
                    </tr>
                  }


                  <tr>
                    <td>OFICINA</td>
                    <td align="right">{formatNumber(form.office || 0)}</td>
                  </tr>
                  <tr>
                    <td>LIQUIDACIÓN</td>
                    <td align="right">{formatNumber(form.liquidation || 0)}</td>
                  </tr>
                  {
                    (parseInt(branch.contribute_id) === 3) && <tr>
                      <td>VENTAL TOTAL - PROPINAS</td>
                      <td align="right">{formatNumber(form.aldelo - form.tips)}</td>
                    </tr>
                  }
                  {
                    (parseInt(branch.contribute_id) !== 3) && <tr>
                      <td>PROPINAS</td>
                      <td align="right">{formatNumber(form.allowed)}</td>
                    </tr>
                  }

                  {form.expenses.map((ex, k) => (
                    <tr key={k}>
                      <td>{ex.parameter}</td>
                      <td align="right">{formatNumber(ex.total)}</td>
                    </tr>
                  ))}
                  <tr>
                    <td>CANTIDAD DOMICILIOS</td>
                    <td align="right">{formatNumber(quantityDelivery)}</td>
                  </tr>
                  <tr>
                    <td>TOTAL DOMICILIOS</td>
                    <td align="right">{formatNumber(totalDelivery)}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>PAGOS POR SUCURSALES</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table className="table-sm table-bordered table-hover">
                <thead className="thead-dark">
                  <tr>
                    <th>SUCURSAL</th>
                    <th>TOTAL</th>
                  </tr>
                </thead>
                <tbody>
                  {listExpenseGroup.map((d, k) => <tr key={k}>
                    <td>{d.branch}</td>
                    <td align="right">{formatNumber(d.total)}</td>
                  </tr>)}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Col>
        <Col lg={4}>
          <Row>
            <Col>
              <h3>PAGOS INTERNOS</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table className="table-sm table-bordered table-hover">
                <thead className="thead-dark">
                  <tr>
                    <th>Descripción</th>
                    <th>Cantidad</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {listIntern.map((d, k) => <ItemDetailIntern item={d} key={k} />)}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={2}>
                      <b>TOTAL</b>
                    </td>
                    <td align="right">
                      <b>{formatNumber(totalIntern)}</b>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <b>PAGO A TURNEROS</b>
                    </td>
                    <td align="right">
                      <b>{formatNumber(form.turn || 0)}</b>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <b>PAGO A EMPLEADOS</b>
                    </td>
                    <td align="right">
                      <b>{formatNumber(form.adelantos || 0)}</b>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <b>TOTAL</b>
                    </td>
                    <td align="right">
                      <b>
                        {" "}
                        {formatNumber(
                          parseFloat(form.operation || 0) +
                          parseFloat(form.adelantos || 0) +
                          parseFloat(form.turn || 0)
                        )}
                      </b>
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>PAGOS TURNEROS</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table className="table-sm table-bordered table-hover">
                <thead className="thead-dark">
                  <tr>
                    <th>TRABAJADOR</th>
                    <th>TURNOS</th>
                    <th>TOTAL</th>
                  </tr>
                </thead>
                <tbody>
                  {listTurn.map((d, k) => <ItemDetailTurn item={d} key={k} />)}
                </tbody>
                <tfoot>
                  <tr>
                    <td><b>TOTAL</b></td>
                    <td align="right">{listTurn.reduce((a, b) => a + parseFloat(b.turnos), 0)}</td>
                    <td align="right">{formatNumber(listTurn.reduce((a, b) => a + parseFloat(b.total), 0))}</td>
                  </tr>
                </tfoot>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>PAGOS FIJOS</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table className="table-sm table-bordered table-hover">
                <thead className="thead-dark">
                  <tr>
                    <th>TRABAJADOR</th>
                    <th>TURNOS</th>
                    <th>TOTAL</th>
                  </tr>
                </thead>
                <tbody>
                  {listFijos.map((d, k) => <ItemDetailWorker item={d} key={k} />)}
                </tbody>
                <tfoot>
                  <tr>
                    <td><b>TOTAL</b></td>
                    <td align="right">{listTurn.reduce((a, b) => a + parseFloat(b.turnos), 0)}</td>
                    <td align="right">{formatNumber(form.fijos)}</td>
                  </tr>
                </tfoot>
              </Table>
            </Col>
          </Row>
        </Col>
        <Col lg={4}>
          <Row>
            <Col>
              <h3>GASTOS</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table className="table-sm table-bordered table-hover">
                <thead className="thead-dark">
                  <tr>
                    <th>Descripción</th>
                    <th>Cantidad</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {listExpense.map((d, k) => <ItemDetailExpense item={d} key={k} />)}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={2}>
                      <b>TOTAL</b>
                    </td>
                    <td align="right">
                      <b>{formatNumber(totalExpense)}</b>
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>NOVEDADES</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table className="table-sm table-bordered table-hover">
                <thead className="thead-dark">
                  <tr>
                    <th>Fecha</th>
                    <th>Observación</th>
                  </tr>
                </thead>
                <tbody>
                  {listNovelty.map((d, k) => <tr key={k}>
                    <td width={"25%"}>{d.registration_date}</td>
                    <td>{d.observation}</td>
                  </tr>)}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Col>
      </Row>
    </TemplateMenu>
  );
};

const mapsState = (props) => {
  let { report } = props;

  return {
    data_end: report.data_end,
    fetching_by_end: report.fetching_by_end,
  };
};

export default connect(mapsState, { getReviewEndMonth })(ReportEndMonth);
