import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import BtnBlueModal from '../Components/Widgets/BtnBlueModal';
import BtnOrangeSm from '../Components/Widgets/BtnOrangeSm';

const ModifierGroupItem = ({ item, onEdit, deleteMainGroup }) => {
    return (
        <Fragment >
            <Row className="card-groups active_list_reservation">
                <Col lg={3} className="border-dot-naranja d-flex flex-column justify-content-center align-items-center">
                    <span className="text-naranja">Orden</span>
                    <h3 className="display-4 text-naranja">{item.priority}</h3>
                </Col>
                <Col lg={7}>
                    <Row>
                        <Col lg={3}>
                            <span className="text-gray-60">Título</span>
                        </Col>
                        <Col lg={9}>
                            <span>{item.title}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={3}>
                            <span className="text-gray-60">Descripción</span>

                        </Col>
                        <Col lg={9}>
                            <span>{item.description}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={3}>
                            <span className="text-gray-60">Obligatorio</span>

                        </Col>
                        <Col lg={9}>
                            <span>{item.required ? 'Si' : "No"}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={3}>
                            <span className="text-gray-60">Estado</span>

                        </Col>
                        <Col lg={9}>
                            <span>{item.status_text}</span>
                        </Col>
                    </Row>

                </Col>
                <Col lg={2} className="d-flex flex-column justify-content-center align-items-center">
                    <BtnBlueModal
                        title='Editar'
                        onClick={() => onEdit(item)} />
                    <BtnOrangeSm
                        title='Eliminar'
                        onClick={() => deleteMainGroup(item)} />
                </Col>
            </Row>
        </Fragment>
    )
}
export default ModifierGroupItem;