import React, { useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap';
import BlueTitleBack from '../Components/Widgets/BlueTitleBack';
import { MdClose } from "react-icons/md";
import uuid from 'react-uuid';

const initState = { amount: '', parameter_id: 0, parameter: {} };
const ModalLoanIngresos = ({ show, onSuccess, onHide, listTime }) => {

    const [form, setForm] = useState(initState)

    const onSubmitTmp = () => {
        let _form = form
        _form.parameter = listTime.filter(row => row.id === parseInt(form.parameter_id))[0]

        let quote = _form.parameter.id === 215 ? 15 : 30

        _form.quote = form.amount / quote
        _form._id = uuid();

        onSuccess(_form)
        setForm(initState)
    }
    const updateField = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Modal show={show} onHide={onHide}>
            <div className="modal-header border-bottom-custom mt-2">
                <BlueTitleBack title={"Agregar Prestamo"} />
                <button type="button" className="close p-0 m-0" data-dismiss="modal" aria-label="Close" onClick={onHide}>
                    <MdClose />
                </button>
            </div>
            <Modal.Body>
                <Row className='my-2'>
                    <Col>
                        <div className="form-group">
                            <label>Modo Descuento</label>
                            <select className='form-control' name='parameter_id' value={form.parameter_id} onChange={updateField}>
                                <option value={0}>Seleccion tiempo</option>
                                {listTime.map(row => <option key={row.id} value={row.id}>{row.description}</option>)}

                            </select>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Agregar Monto</label>
                            <input className='form-control' type="number" placeholder='Agregar monto' name="amount" value={form.amount} onChange={updateField} />
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row >
                    <Col>
                        <button className='btn btn-danger' onClick={onHide}>Cerrar</button>
                    </Col>
                    <Col>
                        <button className='btn btn-success' onClick={onSubmitTmp}>Guardar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalLoanIngresos;