import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import ItemConfiguration from './item.configuration';
import { connect } from 'react-redux';

const TipsConfig = ({ list, onSubmit }) => {


    const onSave = () => {
        onSubmit(list)
    }

    let total = list.reduce((a, b) => a = a + parseFloat(b.value), 0)


    return (
        <Container fluid>
            <Row>
                <Col lg={6}>
                    <table className='table table-sm'>
                        <thead>
                            <tr>
                                <th>Concepto</th>
                                <th>Valor</th>
                                <th>Tipo</th>
                                <th>Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map(row => <ItemConfiguration row={row} key={row.id} />)}
                            <tr>
                                <td>Total</td>
                                <td>{total||0}</td>
                            </tr>
                        </tbody>
                    </table>

                </Col>
            </Row>
            <Row>
                <Col>
                    <button className='btn btn-success' onClick={onSave}>Guardar</button>
                </Col>
            </Row>
        </Container>
    )
}

const mapsState = (props) => {
    return {
    }
}

export default connect(mapsState, {})(TipsConfig);