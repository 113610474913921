import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import FormProduct from './FormProduct';

import { getCategories } from '../../Redux/categoryDuck';
import { getBranch } from '../../Redux/branchofficeDuck';
import { getGroups } from '../../Redux/modifierDuck';
import { toogleAlert } from '../../Redux/alertDuck';

import { createProduct, initDuck} from '../../Redux/productDuck'

import { useToasts } from 'react-toast-notifications';


import { connect } from 'react-redux';
import TemplateMenu from '../../template/menu.template'



const AddProduct = ({ getCategories, getGroups, getBranch, fetching_category, fetching_branch, categories, branchs, groups, fetching_groups,
  toogleAlert, createProduct, fetching_edit, errors, initDuck }) => {


  const [listCategory, setListCategory] = useState([])
  const [listGroup, setListGroup] = useState([])
  const [listBranch, setListBranch] = useState([])

  const { addToast } = useToasts();


  useEffect(() => {
    getCategories()
    getGroups()
    getBranch()
  }, [])

  useEffect(() => {
    if (fetching_edit === 2) {
      toogleAlert(false) 
      addToast('Guardado con Exito', { appearance: 'success', autoDismiss: true });
    }

    if (fetching_edit === 3) {
      toogleAlert(false)
      initDuck()
      addToast('Problemas con la ejecución', { appearance: 'error', autoDismiss: true });
    }
  }, [fetching_edit])

  useEffect(() => {

    if (fetching_category === 2) {
      setListCategory(categories)
    }

  }, [fetching_category])

  useEffect(() => {

    if (fetching_branch === 2) {
      setListBranch(branchs)
    }

  }, [fetching_branch])

  useEffect(() => {

    if (fetching_groups === 2) {
      setListGroup(groups)
    }

  }, [fetching_groups])


  const onSubmit = (form) => {
    toogleAlert(true)
    createProduct(form)
  }

  return (
    <TemplateMenu>
      <Row className="p-3">
        <Col>
          <FormProduct
            onSubmit={onSubmit}
            categories={listCategory}
            listBrand={listBranch}
            setListBranch={setListBranch}
            branchs={listBranch}
            onSaveModifier={() => {
              console.log('llleoge edited')
            }

            }
            mod={listGroup}
            errors={errors} />
        </Col>

      </Row>
    </TemplateMenu>

  )
}

const mapsState = (props) => {

  return {
    categories: props.category.data,
    fetching_category: props.category.fetching_list,
    fetching_branch: props.branchoffice.fetching,
    groups: props.modifier.data_group,
    fetching_groups: props.modifier.fetching_list,
    branchs: props.branchoffice.data,
    fetching_edit: props.product.fetching_edit,
    errors: props.product.errors,
  }
}

export default connect(mapsState, { getCategories, getGroups, getBranch, toogleAlert, createProduct, initDuck })(AddProduct);