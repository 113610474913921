import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import { formatNumber, getHeaders } from "../../utils";
import moment from "moment";
import ItemProduction from "./item.production";
import { getLastInventory } from "../../Redux/inventoryDuck";
import { connect } from "react-redux";

const API_URL = process.env.REACT_APP_API_URL;

const initStateSelected = { measure_unit: { description: "" }, cost: 0 };

const FormProduction = ({
  onSubmit,
  title,
  formEdit,
  getLastInventory,
  fetching_by_id,
  row,
}) => {
  const [form, setForm] = useState({
    product_id: 0,
    amount: 0,
    amount_generate: 0,
    registration_date: moment().format("YYYY-MM-DD"),
    date_init: '',
    date_end: ''
  });
  const [total, setTotal] = useState(0);

  const [listSearch, setListSearch] = useState([]);
  const [product, setProduct] = useState("");
  const [selected, setSelected] = useState(initStateSelected);
  const [listProduction, setListProduction] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [inventory, setInventory] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    let { date_init, date_end } = JSON.parse(localStorage.date);
    setForm({
      ...form,
      "date_init": date_init,
      "date_end": date_end,
    })

  }, [])

  useEffect(() => {
    if (Object.keys(formEdit).length > 0) {
      let { product, production_product, inventory } = formEdit;
      let { supplier, measure_unit } = product;

      setIsEdit(true)

      formEdit.registration_date = moment(formEdit.registration_date).format(
        "YYYY-MM-DD"
      );

      setForm(formEdit);
      setProduct(formEdit.product);

      //TODO: pending avaiable
      setProduct({
        label: product.name,
        value: product.id,
        supplier: supplier.business,
        available: inventory.amount_total,
        cost_average: product.cost_average,
        measure_unit,
      });
      setListProduction(production_product);

      let _quantity = production_product.reduce((a, b) => (a = a + parseFloat(b.amount || 0)), 0);

      setQuantity(_quantity * form.amount);
      let _cost = production_product.reduce((a, b) => (a = a + parseFloat(b.cost_average * b.amount * formEdit.amount || 0)), 0);

      setTotal(_cost);
    } else {
      setIsEdit(false)

    }
  }, [formEdit]);

  useEffect(() => {
    if (fetching_by_id === 2) {
      setInventory(row);
    }
  }, [fetching_by_id]);

  const loadOptions = async (inputValue) => {
    let branch = JSON.parse(localStorage.getItem("branch"));
    let url = `${API_URL}/api/subprescription-search/${inputValue}?registration_date=${form.registration_date}&branch=${branch.id}`;

    let _search = await fetch(url, {
      headers: getHeaders(),
    }).then((resp) => resp.json());

    setListSearch(_search);
    return _search;
  };

  const updateInput = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "amount") {
      let _quantity = e.target.value * listProduction.reduce((a, b) => (a = a + parseFloat(b.amount || 0)), 0);
      let _total = listProduction.reduce(
        (a, b) =>
          (a = a + parseFloat(b.cost_average * (b.amount * e.target.value) || 0)), 0);

      setTotal(_total);
    }

    if (e.target.name === "registration_date" && product !== null && product !== "") {
      getLastInventory(product.value, e.target.value);
    }
  };

  const onDelete = (item) => {
    console.log("item", item);
  };

  const onSubmitPre = () => {
    let _form = form;
    _form.product_id = product.value;
    _form.list_products = listProduction;
    _form.cost_total = total;
    let _validate = listProduction.filter((l) => l.is_available === false);

    if (_validate.length > 0) {
      alert("No cuentas con inventario");
    } else {
      onSubmit(_form);
    }
  };



  return (
    <>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Row>
                <Col>Producción</Col>
                <Col className="d-flex justify-content-end">
                  {!isEdit && <button className="btn btn-sm btn-success" onClick={onSubmitPre} >
                    {title}
                  </button>}

                </Col>
              </Row>
            </Card.Header>

            <Card.Body>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <div className="form-group">
                        <label>Fecha Creacion</label>
                        <input
                          type={"date"}
                          className="form-control"
                          name="registration_date"
                          min={form.date_init}
                          max={form.date_end}
                          value={form.registration_date}
                          onChange={updateInput}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <AsyncSelect
                        value={product}
                        placeholder="Busca el Receta de Producto"
                        loadOptions={loadOptions}
                        // defaultOptions={defaultOptions}
                        onChange={(e) => {
                          let _filter = listSearch.filter(
                            (s) => parseInt(s.value) === parseInt(e.value)
                          )[0];
                          setSelected(_filter);
                          setProduct(e);
                          let { prescription } = _filter;
                          let { prescription_product } = e;

                          setListProduction(prescription_product);

                          let _quantity = prescription_product.reduce((a, b) => (a = a + parseFloat(b.amount || 0)), 0);

                          setQuantity(_quantity * form.amount);
                          let _cost = prescription_product.reduce((a, b) => (a = a + parseFloat(b.cost_average || 0)), 0);
                          setTotal(_cost);

                          getLastInventory(e.value, form.registration_date);
                        }}
                      />
                    </Col>
                  </Row>
                  {product !== "" && (
                    <Row className="my-2">
                      <Col>
                        <Row>
                          <Col>
                            <div className="form-group">
                              <label>Cantidad a generar</label>
                              <input
                                type={"number"}
                                className="form-control"
                                name="amount"
                                value={form.amount}
                                placeholder="Cantidad a generar"
                                onChange={updateInput}
                              />
                            </div>
                          </Col>
                          <Col>
                            <div className="form-group">
                              <label>Cantidad resultado</label>
                              <input
                                type={"number"}
                                className="form-control"
                                name="amount_generate"
                                value={form.amount_generate}
                                placeholder="Cantidad a generarada"
                                onChange={updateInput}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col>
                  {product !== "" && (
                    <>
                      <Row>
                        <Col>Proveedor</Col>
                        <Col>{product.supplier}</Col>
                      </Row>
                      <Row>
                        <Col>Medida</Col>
                        <Col>{product.measure_unit.description}</Col>
                      </Row>
                      <Row>
                        <Col>Costo</Col>
                        <Col>{formatNumber(form.cost || 0)}</Col>
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <Card>
            <Card.Header>
              <Row>
                <Col>Productos de la Receta</Col>
              </Row>
            </Card.Header>

            <Card.Body>
              <table className="table table-sm table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th>Producto</th>
                    <th>Cantidad disponible</th>
                    <th>Cantidad Receta</th>
                    <th>Cantidad Solicitada</th>
                    <th>Costo Unidad</th>
                    <th>Subtotal Producción</th>
                  </tr>
                </thead>
                <tbody>
                  {listProduction.length > 0 ? (
                    listProduction.map((p) => (
                      <ItemProduction
                        row={p}
                        key={p.id}
                        onDelete={onDelete}
                        amount={form.amount}
                        measure={selected.measure_unit}
                      />
                    ))
                  ) : (
                    <tr>
                      <td>No tienes productos en esta Receta</td>
                    </tr>
                  )}
                </tbody>
                {listProduction.length > 0 && (
                  <tfoot>
                    <tr>
                      <td colSpan={5}>TOTAL PRODUCCIÓN</td>
                      <td align="right">
                        <b>{formatNumber(total)}</b>
                      </td>
                    </tr>
                  </tfoot>
                )}
              </table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

const mapsState = (props) => {
  let { inventory } = props;

  return {
    fetching_by_id: inventory.fetching_by_id,
    row: inventory.row,
  };
};

export default connect(mapsState, { getLastInventory })(FormProduction);
