import React, { useState } from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap';
import InputField from '../Components/Widgets/InputField';

const ModalScreen = ({ show, onHide, onSubmit }) => {

    const [form, setForm] = useState({ description: '', status: false })

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }
    const updateCheck = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.checked
        })
    }

    const onSubmitTmp = () => {
        onSubmit(form)
    }
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Agregar Pantallas</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label >Descripción</label>
                                <InputField

                                    placeholder="Agrega una descripción"
                                    checked={form.description || ''}
                                    name="description"
                                    onChange={updateInput}
                                // error={errors.recomended}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label >Estado</label>
                                <InputField
                                    type="checkbox"
                                    checked={form.status || false}
                                    name="status"
                                    onChange={updateCheck}
                                // error={errors.recomended}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Row className="justify-content-center">
                    <Col>
                        <button className='btn btn-success'
                            onClick={onSubmitTmp}>Guardar</button>
                    </Col>
                    <Col>
                        <button
                            className='btn btn-danger'
                            onClick={onHide}
                        >Cerrar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalScreen;