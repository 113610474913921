import React, { useState } from 'react'
import { Col, ListGroup, Row } from 'react-bootstrap';
import { formatNumber } from '../../utils';
import { AiFillEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import ItemTableWorker from './item.table.worker';
import {setTemporalData} from '../../Redux/tipDuck'
import { connect } from 'react-redux';


const ItemConfig = ({ item, total, setTemporalData, showDetail }) => {
    let { configuration, workers } = item

    const [showTable, setShowTable] = useState(showDetail)

    let percent = (configuration!==null)?configuration.value:0;
    let total_tip = total * (percent / 100)
    let total_workers = workers.filter(r => r.type_worker === 1)
    
    let total_turn = workers.filter(r => r.type_worker === 2)
    let pay_ind = total_tip / (workers.length===0?1:workers.filter(r => r.tips === true).length);
    let pay_worker = pay_ind * (total_workers.length===0?1:total_workers.length);

    item.pay_individual = pay_ind

    let _w = workers.map(row=>{
        row.pay_individual = pay_ind;
        return row;
    })


    const [listWorker, setListWorker] = useState(_w)
    setTemporalData(_w)

    let pay_turn = (total_turn.length === 0) ? 0 : (pay_ind * total_turn.length);
    let _total = listWorker.filter(r => r.tips === true).reduce((a, b) => a = a + parseFloat(b.pay_individual)  - (b.pay_individual * (b.discount / 100) || 0), 0)


    const onChangeStatus = (row) => {
        let _workers = workers.map(r => {
            if (row.id === r.id) {
                r.tips = !r.tips
            }
            return r;
        })
        setTemporalData(_workers)
        setListWorker(_workers)
    }

    const onChangeDiscount = (row,discount) => {

        
        let _w = workers.map(r => {
            if (row.id === r.id) {
                r.discount = discount
            }
            return r;
        })

        setTemporalData(_w)
        setListWorker(_w)
    }


    return (
        <>
            <tr>
                <td>
                    {showTable
                        ? <AiOutlineEyeInvisible onClick={() => setShowTable(false)} className="cursor-pointer" />
                        : <AiFillEye onClick={() => setShowTable(true)} className="cursor-pointer" />
                    }
                </td>
                <td>
                    <span className='text-capitalize'>{item.description}</span></td>
                <td >{percent}%</td>
                <td align='right'>{formatNumber(total_tip)}</td>
                <td align='right'>{total_workers.length}</td>
                <td align='right'>{formatNumber(pay_worker)}</td>
                <td align='right'>{total_turn.length}</td>
                <td align='right'>{formatNumber(pay_turn)}</td>
                <td align='right'>{formatNumber(item.pay_individual)}</td>
            </tr>
            {showTable && <tr>
                <td colSpan={9}>
                    <table className='table table-bordered table-sm'>
                        <thead className='thead-primary'>
                            <tr>
                                <th></th>
                                <th>Nombre</th>
                                <th>Cargo</th>
                                <th>Tipo</th>
                                <th>Propina a pagar</th>
                                <th>Descuento</th>
                                <th>Total</th>
                            </tr>

                        </thead>
                        <tbody>
                            {listWorker.map(w => <ItemTableWorker item={w} key={w.id} tip={pay_ind} 
                            onChangeStatus={onChangeStatus} 
                            onChangeDiscount={onChangeDiscount} />)}
                            <tr className="table-success">
                                <td colSpan={6}><b>TOTAL</b></td>
                                <td>${formatNumber(_total)}</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>}
        </>
    )
}

const mapsState=(props)=>{
    return {}
}

export default connect(mapsState,{setTemporalData})(ItemConfig);