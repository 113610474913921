import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import TemplateMenu from '../../template/menu.template'
import { onCreateSubPrescription, onUpdateSubPrescription } from '../../Redux/subprescriptionDuck';
import FormSubPrescription from './form.subprescription';

const CreateSubPrescriptionPage = ({ onCreateSubPrescription, onUpdateSubPrescription, fetching_create }) => {
    const history = useHistory();

    useEffect(() => {
        if (fetching_create === 2) {
            history.goBack();
        }
    }, [fetching_create])


    const onSucess = (data) => {

        if (Object.keys(data.subprescription).length > 0) {
            data.id = data.subprescription.id;
            onUpdateSubPrescription(data)
        } else {
            onCreateSubPrescription(data);
        }
    }

    return (
        <TemplateMenu>
            <FormSubPrescription formEdit={{}} onSuccess={onSucess} />
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { subprescription } = props
    return {
        errors: {},
        fetching_create: subprescription.fetching_create
    }
}

export default connect(mapsState, { onCreateSubPrescription, onUpdateSubPrescription })(CreateSubPrescriptionPage);