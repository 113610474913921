import React, { useEffect, useState } from 'react'
import { Row, Col, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import TemplateMenu from '../../template/menu.template'
import { getSettlement } from '../../Redux/settlementDuck'
import { Link } from 'react-router-dom';
import ItemTableSettlement from './item.table.settlement';



const SettlementPage = ({ getSettlement, fetching_list, data }) => {

    const [list, setList] = useState([])

    useEffect(() => {
        getSettlement()
    }, [])

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
        }
    }, [fetching_list])

    return (
        <TemplateMenu>
            <Row className='mt-3'>
                <Col lg={6}>
                    <Link to="/liquidaciones/new" className='btn btn-info'>Realizar Liquidación</Link>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col>
                    <Table className='table-sm table-hover table-bordered'>
                        <thead className='thead-dark'>
                            <tr>
                                <th>Nombres y Apellidos</th>
                                <th>Documento</th>
                                <th>Fecha de Inicio</th>
                                <th>Fecha Finalización</th>
                                <th>Total</th>
                                <th>Estado</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.length > 0 ? list.map(l => <ItemTableSettlement item={l} key={l.id} />) : <tr><td colSpan={5}>No hay registros</td></tr>}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { settlement } = props
    return {
        data: settlement.data,
        fetching_list: settlement.fetching_list,
    }
}

export default connect(mapsState, { getSettlement })(SettlementPage);