import React, { useState, memo } from 'react';

import { Row, Col, Container } from 'react-bootstrap';
import delete_img from 'Images/delete.svg';
import { MdClose, MdModeEdit } from "react-icons/md";
import { FaChair } from 'react-icons/fa'


const DetailReservationItem = memo(({ item, day, reserve, content, index, cancelReservationTemp, showReservation, refs, restartContent,
    setReservationItem, selectRow, selected }) => {

    let _index = index + 1


    let reference = (_index % 20 == 0) ? refs : null
    // let reference= (item.last!==undefined)?refs:null

    let { dining_table, reservation } = item
    let { user } = reservation || []
    let pet = (item.pet === true) ? <MdModeEdit /> : null

    const [current, setCurrent] = useState({})


    const showDetail = (item) => {
        setCurrent(item)
    }
    const closeDetail = () => {
        setCurrent({})
    }

    return (<>
        <tr
            className={`p-0 mx-0 active_list_reservation m-0 w-100 ${item.is_extern ? '' : 'table-danger-orange '} 
            ${_index} ${item.reservation_id !== null ? 'table-secondary-blue' : ''}
            ${selected.id == item.id ? 'row-selected' : ''}
            `}
            ref={reference} id={`row_${_index}`}
            onClick={() => selectRow(item)}>
            <td ><span className="pt-3 pb-2">{item.branch}</span></td>
            <td ><span className="pt-3 pb-2">{item.position} (<FaChair /> {item.people}) {pet}</span></td>
            <td ><span>{day}<br />{item.content}</span></td>
            <td ><span>{item.name}</span></td>
            <td ><span>{item.phone}</span></td>
            <td ><span>{item.reservation_person}</span></td>
            <td ><span>{(item.pet_client === true) ? 'SI' : ''}</span></td>

            <td className="text-center">
                {item.reservation_id === null ? (<MdModeEdit onClick={() => reserve(item)} />) :
                    <>

                        <Row className="m-0 p-0 w-100">
                            <Col xs={12} className=" m-0 d-flex justify-content-center align-items-center px-2 py-0">
                                {content.id === item.id ? (
                                    <>
                                        <button className="btn-clear bg-white rounded-pill" onClick={() => cancelReservationTemp(item)}>
                                            <img src={delete_img} className="icon-sm" alt="Cancelar" />
                                        </button>
                                        <button className="btn-clear bg-none" onClick={() => restartContent({})}>
                                            <MdClose />
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        {item.reservation_status_id === 1 ? (
                                            <>
                                                <button className="btn-clear bg-green text-center mr-2 rounded pl-2 pr-2" onClick={() => setReservationItem(item)}>Activar {item.reservation_id}</button>
                                                <button className="btn-clear bg-naranja text-center rounded pl-2 pr-2 text-white" onClick={() => showReservation(item)}>Eliminar</button>
                                                <button
                                                    className={`btn-clear bg-blue text-center rounded ml-2 text-white ${current.id === item.id ? 'd-none' : ''}`} onClick={() => showDetail(item)}>Ver</button>
                                            </>
                                        ) : (
                                            <>
                                                <Row>
                                                    <Col><span className="pl-2 pr-2">Activo #{item.reservation_id}</span></Col>
                                                </Row>
                                                <Row>
                                                    <Col><span className="pl-2 pr-2">Factura #{item.aldelo_id}</span></Col>
                                                </Row>
                                                <Row>
                                                    <Col><span className="pl-2 pr-2">Orden Día#{item.order_day}</span></Col>
                                                </Row>
                                            </>


                                        )}
                                    </>
                                )}

                            </Col>
                        </Row>
                    </>
                }
                {(current.id === item.id && item.reservation_id !== null) ? (
                    <Container>
                        <Row className="mt-3">
                            <Col>Reserva #{item.reservation_id}</Col>
                        </Row>
                        <Row>
                            <Col>{item.email}</Col>
                        </Row>
                        <Row>
                            <Col>Comentarios: <b>{item.recommendation}</b></Col>
                        </Row>
                        <Row>
                            <Col><button className="btn-clear px-2 rounded text-center my-2 bg-white text-azul" onClick={closeDetail}>Cerrar</button></Col>
                        </Row>
                    </Container>
                ) : null}

            </td>
        </tr>
    </>)
})

export default DetailReservationItem;