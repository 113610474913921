import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getBranch } from '../../Redux/branchofficeDuck'
import { getSuppliers } from '../../Redux/supplierDuck'
import { getParameterBySegment } from '../../Redux/parameterDuck'
import { onCreateorUpdateExpenses } from '../../Redux/expensesDuck'
import ItemExpenses from './item.expenses';
import ModalExpenses from './modal.expenses';
import { formatNumber } from '../../utils';
import { useHistory } from 'react-router-dom';

const CreateExpensePage = ({ user, errors, getBranch, fetching_branch, branch_offices, getSuppliers, fetching_list_supplier, suppliers, getParameterBySegment,
    fetching_by_segment, data_segment, onCreateorUpdateExpenses, fetching_create, expense }) => {
    const [modal, setModal] = useState(false)
    const [form, setForm] = useState({ branch_id: 0, registration_date: '' })
    const [listBranch, setListBranch] = useState([]);
    const [listExpenses, setListExpenses] = useState([])
    const [listSupplier, setListSupplier] = useState([])
    const [listTypePayments, setListTypePayments] = useState([])
    const history = useHistory();

    useEffect(() => {
        getBranch()
        getSuppliers()
        getParameterBySegment("methods_payment")
    }, [])

    useEffect(() => {
        if (fetching_branch === 2) {
            setListBranch(branch_offices)
        }
    }, [fetching_branch])



    useEffect(() => {
        if (fetching_by_segment === 2) {
            setListTypePayments(data_segment)
        }
    }, [fetching_by_segment])

    useEffect(() => {
        if (fetching_list_supplier === 2) {
            setListSupplier(suppliers)
        }

    }, [fetching_list_supplier])

    useEffect(() => {
        if (fetching_create === 2) {
            history.push(`/gastos/${expense.id}`)
        }
    }, [fetching_create])


    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onAddExpenses = (form) => {
        setModal(false)
        setListExpenses([
            ...listExpenses,
            form
        ])
    }

    const onDeleteTmp = (item) => {
        let _list = listExpenses.filter(r => r._id !== item._id)
        setListExpenses(_list)
    }

    const onSave = () => {
        let _form = form
        _form.payments = listExpenses
        onCreateorUpdateExpenses(_form)
    }

    return (
        <Container fluid>
            <Row>
                <Col>
                    <button className='btn btn-secondary btn-sm mr-3' onClick={() => history.push("/gastos")}>Volver</button>
                    <button className='btn btn-info btn-sm ' onClick={onSave}>Guardar</button>
                </Col>
                <Col lg={2}>
                    <button className='btn btn-success'>Guardar y Liquidar</button>
                </Col>
            </Row>
            <Row>
                <Col lg={4}>

                    {(user.role_id === 1 || user.role_id === 2) && <Row className='my-2'>
                        <Col lg={4}>Sucursal</Col>
                        <Col>
                            <select className='form-control' name='branch_id' value={form.branch_id} onChange={updateInput}>
                                <option value={0}>Seleccione la sucursal</option>
                                {listBranch.map(row => <option key={row.id} value={row.id}>{row.title}</option>)}
                            </select>
                            {errors.branch_id !== undefined && (<label className="error-label">{errors.branch_id}</label>)}

                        </Col>
                    </Row>}
                    <Row>
                        <Col lg={4}>Fecha</Col>
                        <Col>
                            <input
                                className='form-control'
                                type="date"
                                value={form.registration_date || ''}
                                name="registration_date"
                                min={moment().subtract(1, 'd').format("YYYY-MM-DD")} max={moment().format("YYYY-MM-DD")}
                                onChange={updateInput}

                            />
                            {errors.registration_date !== undefined && (<label className="error-label">{errors.registration_date}</label>)}

                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col >Agregar Gastos</Col>
                        <Col><button className='btn btn-primary' onClick={() => setModal(true)}>Agregar</button></Col>
                    </Row>
                    <Row className='my-2'>
                        <Col>
                            <table className='table table-sm table-bordered'>
                                <thead className='thead-dark'>
                                    <tr>
                                        <th>Concepto</th>
                                        <th>Tipo</th>
                                        <th>Monto</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listExpenses.length === 0 ? <tr><td colSpan={4} align="center">No hay datos</td></tr>
                                        : listExpenses.map((row, k) => <ItemExpenses row={row} key={k} onDeleteItem={onDeleteTmp} />)}
                                    <tr>
                                        <td colSpan={2}><b>Total</b></td>
                                        <td>{formatNumber(listExpenses.reduce((a, b) => a = a + parseFloat(b.amount), 0))}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <ModalExpenses show={modal} typePayments={listTypePayments} suppliers={listSupplier} onSubmit={onAddExpenses} onHide={() => setModal(false)} />
        </Container>
    )
}

const mapsState = (props) => {
    return {
        user: props.user.data,
        branch_offices: props.branchoffice.data,
        fetching_branch: props.branchoffice.fetching,
        fetching_list_supplier: props.supplier.fetching_list,
        suppliers: props.supplier.data,
        fetching_by_segment: props.parameter.fetching_by_segment,
        data_segment: props.parameter.data_segment,
        errors: props.expense.errors,
        fetching_create: props.expense.fetching_create,
        expense: props.expense.row
    }
}

export default connect(mapsState, { getBranch, getSuppliers, getParameterBySegment, onCreateorUpdateExpenses })(CreateExpensePage);