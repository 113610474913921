import React from 'react'
import { Col, Modal, Row, Table } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { formatNumber } from '../../utils';

const ModalCorrectionDate = ({ show, listProducts, onHide }) => {

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Listado de Productos Con Ajustes</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Table className='table table-sm table-bordered'>
                            <thead className='thead-dark'>
                                <tr>
                                    <th>Producto</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listProducts.map((d, k) => <tr key={k}>
                                    <td>
                                        <Link to={`/inventario?product_id=${d.id}&title=${d.title}`}>{d.title}</Link>
                                    </td>
                                </tr>)}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalCorrectionDate;