import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import TemplateMenu from '../../template/menu.template'
import { getPurchaseById, restartPurchase, onEditPurchases } from '../../Redux/purchaseDuck'
import { onCreateInventory } from '../../Redux/inventoryDuck'
import { getSuppliers } from "../../Redux/supplierDuck";
import { toogleLoader } from "../../Redux/alertDuck";
import ItemPurchaseEdit from './item.purchase.edit';
import moment from 'moment';
import FormPurchase from './form.purchase';

const EditPurchasePage = ({ getPurchaseById, row, fetching_by_id, getSuppliers,
    fetching_create, restartPurchase, onEditPurchases, fetching_create_purchase, fetching_list, suppliers,
    toogleLoader, fetching_app, row_app }) => {
    const { id } = useParams();
    const [data, setData] = useState({ supplier: {}, registration_date: '' })
    const [list, setList] = useState([])
    const [status, setStatus] = useState(0)
    const [listSupplier, setListSupplier] = useState([]);

    const [isLock, setIsLock] = useState(false)


    const history = useHistory();
    useEffect(() => {
        getPurchaseById(id)
        getSuppliers("?status=1");
        toogleLoader(true)
    }, [id])

    useEffect(() => {
        if (fetching_app === 2) {
            if (row_app.value === 0) {
                setIsLock(true)
            }
        }
    }, [fetching_app]);

    useEffect(() => {
        if (fetching_list === 2) {
            setListSupplier(suppliers);
            toogleLoader(false)
        }
    }, [fetching_list]);


    useEffect(() => {
        if (fetching_by_id === 2) {
            setData(row)
            setList(row.purchase_product)
        }
    }, [fetching_by_id])

    useEffect(() => {
        if (fetching_create === 2) {
            restartPurchase()

        }
    }, [fetching_create])

    useEffect(() => {
        if (fetching_create_purchase === 2) {
            history.push("/compras")
        }
    }, [fetching_create_purchase])

    const onEdit = (form) => {
        onEditPurchases(form)
    }


    return (
        <TemplateMenu>
            <Row className="d-flex justify-content-center">
                <Col lg={11}>
                    <FormPurchase listSupplier={listSupplier} onEdit={onEdit} formEdit={data} isLock={isLock} />
                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { purchase, inventory, supplier, setting } = props
    return {
        row: purchase.row,
        fetching_by_id: purchase.fetching_by_id,
        fetching_create_purchase: purchase.fetching_create,
        fetching_create: inventory.fetching_create,
        fetching_list: supplier.fetching_list,
        suppliers: supplier.data,
        fetching_app: setting.fetching_app,
        row_app: setting.row

    }
}

export default connect(mapsState, {
    getPurchaseById, onCreateInventory, restartPurchase, onEditPurchases,
    getSuppliers, toogleLoader
})(EditPurchasePage);