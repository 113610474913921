import React, { useEffect, useState } from 'react'
import { Col, Row, Tab, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import TemplateMenu from '../../template/menu.template'
import SearchSection from '../Components/SearchSection';
import { getSubPrescription, onDeleteSubPrescription } from '../../Redux/subprescriptionDuck';
import ItemTransformationTable from '../Transformation/item.transformation.table';

const SubPrescriptionPage = ({ getSubPrescription, fetching_list, data, onDeleteSubPrescription, fetching_create }) => {

    const [list, setList] = useState([])

    useEffect(() => {
        getSubPrescription()
    }, [])

    useEffect(() => {
        if (fetching_create === 2) {
            getSubPrescription()
        }
    }, [fetching_create])

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
        }
    }, [fetching_list])

    const filterContent = () => {
    }
    const onCleanFilter = () => {
    }
    const onDelete = (pre) => {
        onDeleteSubPrescription(pre.id)
    }

    return (
        <TemplateMenu>
            <Row className='mt-3'>
                <Col lg={6}>
                    <Link to="/subrecetas/new" className='btn btn-info'>Crear SubReceta</Link>
                </Col>
                <Col lg={6} className="pr-4 justify-content-end d-flex align-items-center">
                    <SearchSection
                        placeholder='Buscar Producto'
                        value=''
                        name='buscar_usuario'
                        onClick={filterContent}
                        onClean={onCleanFilter}
                    >

                    </SearchSection>
                </Col>
            </Row>
            <Row className='my-2'>
                <Col>
                    <Table className='table table-sm table-bordered'>
                        <thead className='thead-dark'>
                            <tr>
                                <th>Nombre</th>
                                <th>Cantidad Items</th>
                                <th>Costo Subreceta</th>
                                <th>Estado</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>{list.length > 0 ? list.map(p => <ItemTransformationTable key={p.id} item={p} onDelete={() => onDelete(p)} />) : <tr><td colSpan={5}>No se encontraron Registros</td></tr>}</tbody>
                    </Table>
                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { subprescription } = props
    return {
        fetching_list: subprescription.fetching_list,
        fetching_create: subprescription.fetching_create,
        data: subprescription.data
    }
}

export default connect(mapsState, { getSubPrescription, onDeleteSubPrescription })(SubPrescriptionPage);