import React, { useState } from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap';
import chooseimage from './../../Images/no_available.png'

const ModalPublicity = ({ show, item, onHide, onSubmit }) => {

    const [image, setImage] = useState('');
    const [form, setForm] = useState({ input: '', image: '' });

    const inputFile = (e) => {
        setImage(e.target.files[0])

        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setForm({ ...form, image: reader.result })
            }
        }
        reader.readAsDataURL(file)
    }

    const onAddImage = () => {

    }
    const onSubmitTmp = () => {
        item.image = image
        onSubmit(item)
    }

    return (
        <Modal show={show} onHide={onHide} size={"lg"}>
            <Modal.Header closeButton>
                <Modal.Title>Agregar Imagenes a ({item.description})</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="chooseimage" className="uploadfile">
                                    {form.image !== '' ? (<img src={form.image} className="img-fluid" />) : <img src={chooseimage} className="img-choose" />}

                                </label>
                                <input type="file" name="photo" className="form-control" onChange={inputFile} id="chooseimage" />
                            </div>
                        </Col>
                        <Col lg={2}>
                            <button className='btn btn-info' onClick={onAddImage}>Agregar</button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>

                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Row className="justify-content-center">
                    <Col>
                        <button
                            className='btn btn-success'

                            onClick={onSubmitTmp}
                        >Guardar</button>
                    </Col>
                    <Col>
                        <button
                            className='btn btn-danger'
                            onClick={onHide}
                        >Cerrar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalPublicity;