import { getHeaders } from '../utils';

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    fetching_create: 0,
    fetching_by_id: 0,
    data: [],
    errors: {}
}

let LIST_TRANSFER = "LIST_TRANSFER";
let LIST_TRANSFER_SUCCESS = "LIST_TRANSFER_SUCCESS";
let CREATE_TRANSFER = "CREATE_TRANSFER";
let CREATE_TRANSFER_SUCCESS = "CREATE_TRANSFER_SUCCESS";
let TRANSFER_BY_ID = "TRANSFER_BY_ID";
let TRANSFER_BY_ID_SUCCESS = "TRANSFER_BY_ID_SUCCESS";
let DELETE_TRANSFER = "DELETE_TRANSFER";
let DELETE_TRANSFER_SUCCESS = "DELETE_TRANSFER_SUCCESS";

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_TRANSFER:
            return { ...state, fetching_list: 1, }
        case LIST_TRANSFER_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        case CREATE_TRANSFER:
            return { ...state, fetching_create: 1 }
        case CREATE_TRANSFER_SUCCESS:
            return { ...state, fetching_create: 2 }
        case TRANSFER_BY_ID:
            return { ...state, fetching_by_id: 1 }
        case TRANSFER_BY_ID_SUCCESS:
            return { ...state, fetching_by_id: 2, row: action.payload }
        case DELETE_TRANSFER:
            return { ...state, fetching_delete: 1 }
        case DELETE_TRANSFER_SUCCESS:
            return { ...state, fetching_delete: 2 }
        default:
            return state;
    }
}

export let getListTransfer = (type) => (dispatch, getState) => {
    dispatch({
        type: LIST_TRANSFER,
    });

    let branch = JSON.parse(localStorage.getItem("branch"))
    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))

    let url = `${API_URL}/api/transfer?branch=${branch.id}&date_init=${date_init}&date_end=${date_end}&type=${type}`;

    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {

        dispatch({
            type: LIST_TRANSFER_SUCCESS,
            payload: resp.results
        });

    })
}

export let getTransferById = (id) => (dispatch, getState) => {
    dispatch({
        type: TRANSFER_BY_ID,
    });


    let url = `${API_URL}/api/transfer/${id}`;

    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {

        dispatch({
            type: TRANSFER_BY_ID_SUCCESS,
            payload: resp
        });

    })
}

export let onCreateTransfer = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_TRANSFER,
    });
    let url = `${API_URL}/api/transfer`;

    let branch = JSON.parse(localStorage.branch);

    form.branch_id = branch.id;

    fetch(url, {
        method: "POST",
        body: JSON.stringify(form),
        headers: getHeaders(),
    })
        .then((resp) => resp.json())
        .then((resp) => {
            dispatch({
                type: CREATE_TRANSFER_SUCCESS,
            });
        });
};

export let onDeleteTransfer = (id) => (dispatch, getState) => {
    dispatch({
        type: DELETE_TRANSFER,
    });
    let url = `${API_URL}/api/transfer/${id}`;


    fetch(url, {
        method: "DELETE",
        headers: getHeaders(),
    })
        .then((resp) => resp.json())
        .then((resp) => {
            dispatch({
                type: DELETE_TRANSFER_SUCCESS,
            });
        });
};

export let onDeleteTransferDetail = (id) => (dispatch, getState) => {
    dispatch({
        type: CREATE_TRANSFER,
    });
    let url = `${API_URL}/api/transfer-detail/${id}`;


    fetch(url, {
        method: "DELETE",
        headers: getHeaders(),
    })
        .then((resp) => resp.json())
        .then((resp) => {
            dispatch({
                type: CREATE_TRANSFER_SUCCESS,
            });
        });
};