import React, { useState } from 'react';
import { FcPlus } from "react-icons/fc";
import { Row } from 'react-bootstrap';
import { MdOutlineModeEditOutline } from 'react-icons/md';


const RowTableClient = ({ item, index, editUser, refs }) => {
  let _index = index + 1

  let reference = (_index % 20 == 0) && refs


  let { addresses } = item || []
  const [selected, setSelected] = useState({})

  const [data, setData] = useState([])

  const showAddress = () => {
    if (item.id === selected.id) {
      setSelected({})
    } else {
      setSelected(item)
    }
  }

  const printAddress = (row) => {

    return (
      <tr key={item.id} className="p-0 mx-0 border-bottom active_list_reservation m-0 w-100">
        <td className="text-center border-right"><span className="font-15 pt-3 pb-2">{row.address}</span></td>
      </tr>
    )
  }

  return (
    <>
      <tr className="p-0 mx-0 border-bottom active_list_reservation m-0 w-100" ref={reference}>
        <td className="text-center border-right">
          <button className="btn" onClick={showAddress}>
            <FcPlus />
          </button>

        </td>
        <td className="text-center border-right"><span className="font-15 pt-3 pb-2">{item.name}</span></td>
        <td className="text-center border-right"><span className="font-15 pt-3 pb-2">{item.email}</span></td>
        <td className="text-center border-right"><span className="font-15 pt-3 pb-2">{item.phone}</span></td>
        <td className="text-center border-right"><span className="font-15 pt-3 pb-2">{item.document}</span></td>
        {/* <td className="text-center border-right"><span className="font-15 pt-3 pb-2"></span></td> */}
        <td className="text-center">
          <MdOutlineModeEditOutline onClick={() => editUser(item)} />
        </td>
      </tr>
      {selected.id === item.id && (
        <tr >
          <td>
            <table className="p-0 bg-azul table-bordered rounded-lg m-0 w-100 table-hover table-header-custom" >
              <thead>
                <tr>
                  <td>Direcciones</td>
                </tr>
              </thead>
              <tbody>
                {(addresses !== undefined) && addresses.map(printAddress)}
              </tbody>

            </table>
          </td>
        </tr>
      )}

    </>
  )
}
export default RowTableClient;