import axios from "axios";
import {
  removeSession,
  errorLoginServer,
  validateUser,
  getHeaders,
} from "../utils";
import { client } from "websocket";
import { restartPermision } from "./permissionDuck";
import { toogleAlert } from "./alertDuck";
import uuid from 'react-uuid';

const API_URL = process.env.REACT_APP_API_URL;

let initialState = {
  fetching: 0,
  fetching_list: 0,
  fetching_email: 0,
  fetching_timer: 0,
  fetching_client: 0,
  fetching_search: 0,
  fetching_register: 0,
  fetching_save: 0,
  fetching_working: 0,
  fetching_dates: 0,
  fetching_close: 0,
  fetching_logout: 0,
  loggedIn: false,
  open: false,
  openNumber: false,
  selected: {},
  data: [],
  working: [],
  list: [],
  clients: [],
  errors: {},
  current_page: 1,
  last_page: 0,
  fetching_rol: 0,
  data_roles: [],
  data_dates: [],
  form_permission: [],
  reload: false
};

const LOGIN = "LOGIN";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_CLOSE = "LOGIN_CLOSE";
const LOGIN_CLOSE_SUCCESS = "LOGIN_CLOSE_SUCCESS";
const USER = "USER";
const USER_SUCCESS = "USER_SUCCESS";
const LOGOUT = "LOGOUT";
const LOGOUT_SERVER = "LOGOUT_SERVER";
const LOGOUT_SERVER_SUCCESS = "LOGOUT_SERVER_SUCCESS";
const LOGIN_ERROR = "LOGIN_ERROR";
const RESTORE_SESSION = "RESTORE_SESSION";
const RESTORE_SESSION_SUCCESS = "RESTORE_SESSION_SUCCESS";
const RESTORE_SESSION_ERROR = "RESTORE_SESSION_ERROR";
const LIST_USER = "LIST_USER";
const LIST_USER_SUCCESS = "LIST_USER_SUCCESS";

const REGISTER = "REGISTER";
const REGISTER_SUCCESS = "REGISTER_SUCCESS";
const REGISTER_ERROR = "REGISTER_ERROR";
const USER_SELECTED = "USER_SELECTED";
const USER_CLEAR = "USER_CLEAR";
const UPDATE_USER = "UPDATE_USER";
const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
const EMAIL = "EMAIL";
const EMAIL_SUCCESS = "EMAIL_SUCCESS";
const EMAIL_NOT_FOUND = "EMAIL_NOT_FOUND";

const LIST_WORKING = "LIST_WORKING";
const LIST_WORKING_SUCCESS = "LIST_WORKING_SUCCESS";
const REGISTER_TIMER = "REGISTER_TIMER";
const REGISTER_TIMER_SUCCESS = "REGISTER_TIMER_SUCCESS";
const SEARCH = "SEARCH";
const SEARCH_SUCCESS = "SEARCH_SUCCESS";
const ROLES = "ROLES";
const ROLES_SUCCESS = "ROLES_SUCCESS";
const LIST_DATES = "LIST_DATES";
const LIST_DATES_SUCCESS = "LIST_DATES_SUCCESS";
const LOGIN_CLOSE_ERROR = "LOGIN_CLOSE_ERROR";
const FORM_PERMISSION = "FORM_PERMISSION";
const FORM_PERMISSION_SUCCESS = "FORM_PERMISSION_SUCCESS";

export default function reducer(state = initialState, action) {
  let clients;
  switch (action.type) {
    case LOGIN:
      return { ...state, fetching: 1, fetching_logout: 0 };
    case LOGIN_SUCCESS:
      return { ...state, fetching: 2, ...action.payload, loggedIn: true };
    case LOGIN_ERROR:
      return { ...state, fetching: 3, errors: action.payload, loggedIn: false };
    case RESTORE_SESSION:
      return { ...state, fetching: 1 };
    case RESTORE_SESSION_SUCCESS:
      return { ...state, fetching: 2, ...action.payload, loggedIn: true };
    case RESTORE_SESSION_ERROR:
      return { ...state, fetching: 0, loggedIn: false };
    case USER:
      return { ...state, fetching_info: 1 };
    case USER_SUCCESS:
      return {
        ...state,
        fetching_info: 2,
        data: action.payload,
        loggedIn: true,
      };
    case EMAIL:
      return { ...state, fetching_email: 1 };
    case EMAIL_SUCCESS:
      return { ...state, fetching_email: 2, selected: action.selected };
    case EMAIL_NOT_FOUND:
      return { ...state, fetching_email: 3 };
    case LIST_USER:
      return { ...state, fetching_list: 1 };
    case LIST_USER_SUCCESS:
      return { ...state, fetching_list: 2, list: action.payload };
    case LOGOUT:
      return { ...state, fetching: false, loggedIn: false };
    case REGISTER:
      return { ...state, fetching_save: 1 };
    case REGISTER_SUCCESS:
      return { ...state, fetching_save: 2 };

    case REGISTER_ERROR:
      return { ...state, fetching_save: 3, errors: action.payload };
    case UPDATE_USER:
      return { ...state, fetching_save: 1 };
    case UPDATE_USER_SUCCESS:
      return { ...state, fetching_save: 2 };
    case USER_SELECTED:
      return {
        ...state,
        fetching: 0,
        fetching_save: 0,
        selected: action.payload,
      };
    case USER_CLEAR:
      return { ...state, fetching: 0, selected: {}, errors: {} };
    case LIST_WORKING:
      return { ...state, fetching_working: 1 };
    case LIST_WORKING_SUCCESS:
      return { ...state, fetching_working: 2, working: action.payload };
    case REGISTER_TIMER:
      return { ...state, fetching_timer: 1 };
    case REGISTER_TIMER_SUCCESS:
      return { ...state, fetching_timer: 2 };
    case LIST_USER:
      return { ...state, fetching_list: 1 };
    case LIST_USER_SUCCESS:
      return {
        ...state,
        fetching_list: 2,
        list: action.payload,
        current_page: action.current_page,
        last_page: action.last_page,
      };
    case SEARCH:
      return { ...state, fetching_search: 1 };
    case SEARCH_SUCCESS:
      clients = action.payload;
      return {
        ...state,
        fetching_search: 2,
        clients,
        current_page: action.current_page,
        last_page: action.last_page,
      };
    case ROLES:
      return { ...state, fetching_rol: 1 };
    case ROLES_SUCCESS:
      return { ...state, fetching_rol: 2, data_roles: action.payload };
    case LIST_DATES:
      return { ...state, fetching_dates: 1 };
    case LIST_DATES_SUCCESS:
      return { ...state, fetching_dates: 2, data_dates: action.payload, reload: action.reload };
    case LOGIN_CLOSE:
      return { ...state, fetching_close: 1 };
    case LOGIN_CLOSE_SUCCESS:
      return { ...state, fetching_close: 2 };
    case LOGIN_CLOSE_ERROR:
      return { ...state, fetching_close: 3, error: action.payload };
    case LOGOUT_SERVER:
      return { ...state, fetching_logout: 1 };
    case LOGOUT_SERVER_SUCCESS:
      return { ...state, fetching_logout: 2 };
    case FORM_PERMISSION:
      return { ...state, fetching_permission: 1 };
    case FORM_PERMISSION_SUCCESS:
      return { ...state, fetching_permission: 2, form_permission: action.payload };
    default:
      return state;
  }
}

export let onSearchUser = (txt) => (dispatch, getState) => {
  dispatch({
    type: SEARCH,
  });

  let page = 1;

  axios
    .get(`${API_URL}/api/users-admin/${txt}?page=${page}&limit=30`, {
      headers: getHeaders(),
    })
    .then((response) => {
      dispatch({
        type: SEARCH_SUCCESS,
        payload: response.data.results,
        current_page: response.data.current_page,
        last_page: response.data.last_page,
      });
    })
    .catch((error) => {
      let { status } = error.response;

      if (status === 401) {
        logOutAction()(dispatch, getState);
      }
    });
};

export let getUserPhone = (phone) => (dispatch, getState) => {
  dispatch({
    type: EMAIL,
  });

  let token = localStorage.getItem("access_token");

  axios
    .get(`${API_URL}/api/users-admin/${phone}`, {
      headers: {
        Authorization: "Bearer " + token, //the token is a variable which holds the token
      },
    })
    .then((response) => {
      console.log("response", response);

      if (response.data.status === true) {
        dispatch({
          type: EMAIL_SUCCESS,
          selected: response.data.row,
        });
      }
      if (response.data.status === false) {
        dispatch({
          type: EMAIL_NOT_FOUND,
        });
      }
    })
    .catch((error) => {
      let { status } = error.response;

      if (status === 401) {
        logOutAction()(dispatch, getState);
      }
    });
};

export let updateUser = (form) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_USER,
  });

  let val = validateUser(form, true);

  if (Object.keys(val).length > 0) {
    dispatch({
      type: REGISTER_ERROR,
      payload: val,
    });
    return;
  }

  axios
    .put(`${API_URL}/api/user/${form.id}`, form, {
      headers: getHeaders(),
    })
    .then((response) => {
      console.log("response", response);

      dispatch({
        type: UPDATE_USER_SUCCESS,
      });
    })
    .catch((error) => {
      let { status, data } = error.response;

      console.log("status", status);

      if (status === 401) {
        logOutAction()(dispatch, getState);
      }

      console.log("error.response", error.response);

      if (status === 400) {
        dispatch({
          type: REGISTER_ERROR,
          payload: data.errors,
        });
      }
      console.log("status", status);
    });
};

export let setSelectedItem = (item) => (dispatch, getState) => {
  dispatch({
    type: USER_SELECTED,
    payload: item,
  });
};
export let clearSelected = () => (dispatch, getState) => {
  dispatch({
    type: USER_CLEAR,
  });
};

export let createUser = (form) => (dispatch, getState) => {
  dispatch({
    type: REGISTER,
  });

  let branch = JSON.parse(localStorage.getItem("branch"));

  form.branch_id = branch.id;

  let val = validateUser(form);

  if (Object.keys(val).length > 0) {
    dispatch({
      type: REGISTER_ERROR,
      payload: val,
    });
    return;
  }

  axios
    .post(`${API_URL}/api/user`, form, {
      headers: getHeaders(),
    })
    .then((response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });
    })
    .catch((error) => {
      let { status } = error.response;
      console.log("status", status);
    });
};

export let onLogin = (form) => (dispatch, state) => {
  dispatch({
    type: LOGIN,
    fetching: true,
  });

  // let url = `${API_URL}/api/auth/login`
  let url = `${API_URL}/api/login-admin`;

  form.last_session = uuid()
  axios
    .post(url, form)
    .then((response) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: response.data,
      });
      localStorage.branch = JSON.stringify({ id: response.data.user.branch_id })
      saveStorage(response.data);
    })
    .catch((error) => {
      let err = {};
      let { status } = error.response;

      if (status === 422) {
        err = errorLoginServer(error);
        dispatch({
          type: LOGIN_ERROR,
          payload: err,
        });
      } else {
        err.message = error.response.data.msg === undefined ? "Usuario o clave no valido" : error.response.data.msg;
        dispatch({
          type: LOGIN_ERROR,
          payload: err,
        });
      }
    });
};
export let onLoginCloseMonth = (form) => (dispatch, state) => {
  dispatch({
    type: LOGIN_CLOSE,
    fetching: true,
  });

  let branch = JSON.parse(localStorage.getItem("branch"));
  let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));
  // let url = `${API_URL}/api/auth/login`
  let url = `${API_URL}/api/inventory-end-month/${branch.id}`;

  form.date_init = date_init
  form.date_end = date_end

  axios
    .post(url, form, {
      headers: getHeaders(),
    }).then((response) => {
      dispatch({
        type: LOGIN_CLOSE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      if (error.response) {

        console.log('error.response.status', error.response.status);

        if (error.response.status === 400) {
          dispatch({
            type: LOGIN_CLOSE_ERROR,
            payload: error.response.data,
          });
        }
      }


      //   let err = {};
      //   let { status } = error.response;

      //   if (status === 422) {
      //     err = errorLoginServer(error);
      //     dispatch({
      //       type: LOGIN_ERROR,
      //       payload: err,
      //     });
      //   } else {
      //     err.message = "Usuario o clave no valido";
      //     dispatch({
      //       type: LOGIN_ERROR,
      //       payload: err,
      //     });
      //   }
    });
};

export let logOutAction = () => (dispatch, getState) => {
  toogleAlert(false)(dispatch, getState)
  removeSession();
  dispatch({
    type: LOGOUT,
  });
};

export let logOutServer = () => (dispatch) => {

  dispatch({
    type: LOGOUT_SERVER,
  });

  axios
    .get(`${API_URL}/api/logout`, {
      headers: getHeaders(),
    })
    .then((response) => {
      dispatch({
        type: LOGOUT_SERVER_SUCCESS,
      });
    })
    .catch((error) => {

    });
};

export let getAllUser = () => (dispatch, getState) => {
  dispatch({
    type: LIST_USER,
  });

  let branch = JSON.parse(localStorage.getItem("branch"));

  axios
    .get(`${API_URL}/api/user?branch_id=${branch.id}`, {
      headers: getHeaders(),
    })
    .then((response) => {
      dispatch({
        type: LIST_USER_SUCCESS,
        payload: response.data.results,
      });
    })
    .catch((error) => {
      let { status } = error.response;

      if (status === 401) {
        logOutAction()(dispatch, getState);
      }
    });
};

export let getInformationUser = () => (dispatch, getState) => {
  dispatch({
    type: USER,
  });

  let branch = JSON.parse(localStorage.getItem("branch"));

  if (branch !== null) {
    axios
      .get(`${API_URL}/api/user-admin?branch_id=${branch.id}`, {
        headers: getHeaders(),
      })
      .then((response) => {
        dispatch({
          type: USER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        let { status } = error.response;

        if (status === 401) {
          logOutAction()(dispatch, getState);
        }
      });
  }
};

export let getDates = (branchId, reload) => (dispatch, getState) => {
  dispatch({
    type: LIST_DATES,
  });

  if (getState().user.loggedIn === true) {


    // if (localStorage.getItem("date") !== null) {
    let { date_init } = JSON.parse(localStorage.getItem("date")) || { date_init: '' }


    // if (range === null) {

    axios
      .get(`${API_URL}/api/range-dates?branch_id=${branchId}`, {
        headers: getHeaders(),
      })
      .then((response) => {
        dispatch({
          type: LIST_DATES_SUCCESS,
          payload: response.data.results,
          reload
        });

      })
      .catch((error) => {
        let { status } = error.response;

        if (status === 401) {
          logOutAction()(dispatch, getState);
        }
      });
  }
}

export let getRoles = () => (dispatch, getState) => {
  dispatch({
    type: ROLES,
  });

  // axios.get(`${API_URL}api/user-admin`, {
  axios
    .get(`${API_URL}/api/roles`, {
      headers: getHeaders(),
    })
    .then((response) => {
      dispatch({
        type: ROLES_SUCCESS,
        payload: response.data.results,
      });

      restartPermision()(dispatch, getState)
    })
    .catch((error) => {
      let { status } = error.response;

      if (status === 401) {
        logOutAction()(dispatch, getState);
      }
    });
};

export let restoreSessionAction = () => (dispatch) => {
  dispatch({
    type: RESTORE_SESSION,
  });

  let storage = localStorage.getItem("storage");
  storage = JSON.parse(storage);

  if (storage) {
    dispatch({
      type: RESTORE_SESSION_SUCCESS,
      payload: storage,
    });
  } else {
    dispatch({
      type: RESTORE_SESSION_ERROR,
      payload: storage,
    });
  }
};

export let getUserActive = () => (dispatch, getState) => {
  dispatch({
    type: LIST_WORKING,
  });

  axios
    .get(`${API_URL}/api/user-working`, {
      headers: getHeaders(),
    })
    .then((response) => {
      dispatch({
        type: LIST_WORKING_SUCCESS,
        payload: response.data.results,
      });
    })
    .catch((error) => {
      let { status } = error.response;

      if (status === 401) {
        logOutAction()(dispatch, getState);
      }
    });
};

export let onRegisterTime = (form) => (dispatch, state) => {
  dispatch({
    type: REGISTER_TIMER,
    fetching: true,
  });

  axios
    .post(`${API_URL}/api/user-time`, form, {
      headers: getHeaders(),
    })
    .then((response) => {
      console.log("response", response);

      dispatch({
        type: REGISTER_TIMER_SUCCESS,
      });
    })
    .catch((error) => {
      // let err = {}
      // let { status } = error.response
      // if (status === 422) {
      //     err = errorLoginServer(error)
      //     dispatch({
      //         type: LOGIN_ERROR,
      //         payload: err
      //     });
      // } else {
      //     err.message = "Usuario o clave no valido"
      //     dispatch({
      //         type: LOGIN_ERROR,
      //         payload: err
      //     });
      // }
    });
};

export let getFormPermissions = () => (dispatch, state) => {
  dispatch({
    type: FORM_PERMISSION,
    fetching: true,
  });

  axios
    .get(`${API_URL}/api/form-permission`, {
      headers: getHeaders(),
    })
    .then((response) => {
      dispatch({
        type: FORM_PERMISSION_SUCCESS,
        payload: response.data
      });
    })
    .catch((error) => {
      // let err = {}
      // let { status } = error.response
      // if (status === 422) {
      //     err = errorLoginServer(error)
      //     dispatch({
      //         type: LOGIN_ERROR,
      //         payload: err
      //     });
      // } else {
      //     err.message = "Usuario o clave no valido"
      //     dispatch({
      //         type: LOGIN_ERROR,
      //         payload: err
      //     });
      // }
    });
};

export let onRegisterTimeRest = (form) => (dispatch, state) => {
  dispatch({
    type: REGISTER_TIMER,
    fetching: true,
  });

  axios
    .post(`${API_URL}/api/user-time-rest`, form, {
      headers: getHeaders(),
    })
    .then((response) => {
      console.log("response", response);

      dispatch({
        type: REGISTER_TIMER_SUCCESS,
      });
    })
    .catch((error) => {
      // let err = {}
      // let { status } = error.response
      // if (status === 422) {
      //     err = errorLoginServer(error)
      //     dispatch({
      //         type: LOGIN_ERROR,
      //         payload: err
      //     });
      // } else {
      //     err.message = "Usuario o clave no valido"
      //     dispatch({
      //         type: LOGIN_ERROR,
      //         payload: err
      //     });
      // }
    });
};

function saveStorage(storage) {
  storage.loggedIn = true;
  localStorage.storage = JSON.stringify(storage);
  localStorage.access_token = storage.access_token;
}
