import React from 'react'
import { Modal, Container, Row, Col, ModalFooter, ModalBody } from 'react-bootstrap'
import ModalHeader from 'react-bootstrap/esm/ModalHeader'
import { MdClose } from "react-icons/md";
import InputForm from '../Components/Widgets/InputForm'
import BtnBlueModal from '../Components/Widgets/BtnBlueModal'

const FreeTableModal = ({ show }) => {
    return (
        <Modal show={show} size="md" centered>
            <Modal.Header className="bg-white p-1">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">
                        <MdClose />
                    </span>
                </button>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center align-items-center flex-column">
                <h2 className="font-title h6 text-center">¿ DESEAS CERRAR LA MESA ?</h2>
                <Row className="justify-content-center w-100 p-1  ">
                    <Col lg={6}>
                        <form className="w-100 py-4">
                            <InputForm
                                type='password'
                                name='password'
                                placeholder='Contraseña'
                                error=''
                                value=''
                                onChange=''
                            />
                            <BtnBlueModal title='Confirmar' />
                        </form>

                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className="m-0 p-0 justify-content-center">
                <img src={'pendined'} className="w-80 px-4" />
            </Modal.Footer>
        </Modal>
    )
}

export default FreeTableModal;