import React, { useEffect, Fragment, useState } from 'react'
import { Container, Row, Col, Card, Button, Table, Tab, Tabs } from 'react-bootstrap'
import { connect } from 'react-redux'
import {
    getModifiers, createAssociation, deleteGroupById, deleteModifierById, selectedDefaultModifier,
    deleteAssociateById,
    getGroups
} from '../../Redux/modifierDuck'
import { toogleAlert } from '../../Redux/alertDuck'
import ModalGroup from './ModalGroup'
import ModalModifier from './ModalModifier'
import { formatNumber } from '../../utils'
import './index.css'
import BtnWhiteHeader from './../Components/BtnWhiteHeader'
import BtnBlueModal from '../Components/Widgets/BtnBlueModal';
import BtnOrangeSm from './../Components/Widgets/BtnOrangeSm'
import BlueTitleBack from './../Components/Widgets/BlueTitleBack'

import ModifierItem from './ModifierItem'
import ModifierGroupItem from './ModifierGroupItem'
import AssociativeItems from './AssociativeItems'
import TemplateMenu from '../../template/menu.template'



const Modifier = ({ getModifiers, groups, modifiers, createAssociation, deleteGroupById, deleteModifierById, toogleAlert,
    selectedDefaultModifier, deleteAssociateById, getGroups, fetching }) => {

    const [modalGroup, setModalGroup] = useState(false)
    const [modalModifier, setModalModifier] = useState(false)
    const [group, setGroup] = useState({ modifier_id: '0', modifier_group_id: '0' })
    const [formEditGroup, setFormEditGroup] = useState({})
    const [formEditMod, setFormEditMod] = useState({})

    useEffect(() => {
        getGroups()
        getModifiers()
    }, [])

    useEffect(() => {
        if (fetching === 4) {
            window.location.href = "/home";
        }
    }, [groups, fetching])

    const showModalGroup = () => {
        setModalGroup(!modalGroup)
    }

    const showModalModifier = () => {

        setModalModifier(!modalModifier)
    }

    const onEditModifier = (item) => {
        setFormEditMod(item)
        setModalModifier(!modalModifier)
    }
    const onEditGroup = (item) => {
        setModalGroup(!modalGroup)
        setFormEditGroup(item)
    }


    const onGroup = () => {
        let err = "";
        if (group.modifier_group_id === undefined || group.modifier_group_id === '0') {

            err = "Grupo"
        }
        if (group.modifier_id === undefined || group.modifier_id === '0') {

            err += (err == '') ? '' : ', '
            err += " Modificador"
        }

        if (err !== '') {
            toogleAlert("Datos obligatorios " + err)
            return;
        }


        createAssociation(group);

    }

    const updateInput = (e) => {
        setGroup({
            ...group,
            [e.target.name]: e.target.value
        })
    }

    const onHideGroup = () => {
        setModalGroup(false)
        console.log("onHideGroup")
    }
    const onHideModifier = () => {
        setModalModifier(false)

        console.log("onHideModifier")
    }

    const onSuccessGroup = () => {
        setModalGroup(false)
        getGroups()
        console.log('onSuccessGroup')
    }

    const onSuccessModifier = () => {
        setModalModifier(false)
        getModifiers()
        console.log('onSuccessModifier')
    }


    const deleteMainGroup = (item) => {
        if (window.confirm("¿Realmente deseas Eliminar Este Grupo?")) {

            deleteGroupById(item.id)
        }
    }
    const deleteModifier = (item) => {
        if (window.confirm("¿Realmente deseas Eliminar Este modificador?")) {

            deleteModifierById(item.id)
        }
    }

    return (
        <TemplateMenu>
            <Container fluid>
                <Row>
                    <Col md={12} className=" mt-3">

                        <Tabs defaultActiveKey="grupos" id="uncontrolled-tab-modificadores" className="menuTab">
                            <Tab eventKey="grupos" title="Grupos">
                                <Row className="justify-content-center">
                                    <Col lg={9}>
                                        <Row className="mb-5 mt-4">
                                            <Col lg={10} className="text-center">
                                                <h2>Grupos</h2>
                                            </Col>
                                            <Col lg={2} className="d-flex justify-content-end align-items-center pr-4">
                                                <BtnWhiteHeader
                                                    title='CREAR'
                                                    onClick={showModalGroup} />
                                            </Col>
                                        </Row>
                                        {(groups !== undefined) && groups.map(row => <ModifierGroupItem item={row} key={row.id} onEdit={onEditGroup} deleteMainGroup={deleteMainGroup} />)}
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="modificador" title="Modificador">
                                <Row className="justify-content-center">
                                    <Col lg={9}>
                                        <Row className=" mb-5 mt-4">
                                            <Col lg={10} className="text-center">
                                                <h2>Modificadores</h2>
                                            </Col>
                                            <Col lg={2} className="d-flex justify-content-center align-items-center">
                                                <BtnWhiteHeader
                                                    title='CREAR'
                                                    onClick={showModalModifier} />
                                            </Col>
                                        </Row>
                                        {(modifiers !== undefined) && modifiers.map(row => <ModifierItem item={row} key={row.id} onEdit={onEditModifier} />)}
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="agrupar" title="Agrupar Modificadores">
                                <Row className="justify-content-center">
                                    <Col lg={9}>
                                        <Row className="mt-3 d-flex justify-content-center">
                                            <Col lg={5} className="text-center">
                                                <h2>Agrupaciones</h2>
                                            </Col>
                                        </Row>
                                        <Row className="d-flex justify-content-center">
                                            <Col lg={5} className="text-center">
                                                <div className="form-group my-5">
                                                    <label className="font-title ">ESCOGE EL GRUPO</label>
                                                    <select className="form-control" name="modifier_group_id" onChange={updateInput}>
                                                        <option value="0">Seleccione</option>
                                                        {(groups !== undefined) && groups.map((row, i) => <option value={row.id} key={i}>{row.title}</option>)}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label className="font-title">ASOCIALE EL MODIFICADOR</label>
                                                    <select className="form-control" name="modifier_id" onChange={updateInput}>
                                                        <option value="0">Seleccione</option>
                                                        {(modifiers !== undefined) && modifiers.map((row, i) => <option value={row.id} key={i}>{row.title} ({formatNumber(row.price)})</option>)}
                                                    </select>
                                                </div>
                                                <div className="text-center pt-4">
                                                    <BtnWhiteHeader
                                                        title='AGRUPAR'
                                                        onClick={onGroup} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="my-4">
                                            <Col lg={12}>
                                                {(groups !== undefined) && groups.map(row => <AssociativeItems item={row} key={row.id} />)}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>

                <ModalGroup
                    showModal={modalGroup}
                    onHideGroup={onHideGroup}
                    onSuccess={onSuccessGroup}
                    formEdit={formEditGroup}
                />
                <ModalModifier
                    showModal={modalModifier}
                    onHide={onHideModifier}
                    onSuccess={onSuccessModifier}
                    formEdit={formEditMod}


                />


            </Container>
        </TemplateMenu>)
}

const mapsState = (props) => {

    return {
        groups: props.modifier.data_group,
        modifiers: props.modifier.data,
        fetching: props.modifier.fetching,

    }
}

export default connect(mapsState, {
    getModifiers, createAssociation, deleteGroupById, deleteModifierById, toogleAlert,
    selectedDefaultModifier, deleteAssociateById, getGroups
})(Modifier);