import React, { useContext, useEffect, useRef, useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { Alert, Card, Col, Container, FormControl, InputGroup, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getMessages, onSendMessage } from '../../Redux/whatsappDuck'
import { AiOutlineSend } from 'react-icons/ai';
import { SocketContext } from '../../context/SocketContext';

import moment from 'moment'

const WhatsappPage = ({ getMessages, data, fetching_list, onSendMessage, fetching_send }) => {

    const [listContact, setListContact] = useState([])
    const [contact, setContact] = useState({})
    const [listMessages, setListMessages] = useState([])
    const [form, setForm] = useState({ message: '', customer_id: 31 });
    const { socket } = useContext(SocketContext)

    const divScroll = useRef();

    useEffect(() => {
        getMessages()
    }, [])

    useEffect(() => {
        if (socket) {
            socket.on("send-message-whatsapp", data => {
                getMessages()
            })
        }
    }, [data])

    useEffect(() => {
        if (fetching_send === 2) {
            getMessages()
        }
    }, [fetching_send])

    useEffect(() => {
        if (fetching_list === 2) {

            console.log('contact--', contact);

            setContact(data[0])
            setListContact(data)

            console.log('data--', data);

            setListMessages(data[0].messages)
            console.log('divScroll.current.scrollTop', divScroll.current.scrollTop);
            console.log('divScroll.current.scrollHeight', divScroll.current.scrollHeight);
            console.log('divScroll.current.offsetHeight', divScroll.current.offsetHeight);

            //divScroll.current.scrollTo({ bottom: 300, behavior: 'smooth' });
            // divScroll.current.addEventListener("scroll", onScroll)
        }
    }, [fetching_list])


    const onSendMessageForm = () => {
        let _form = form
        setForm({
            ...form,
            "message": ''
        });

        _form.customer_id = contact.id

        console.log('_form', _form);

        onSendMessage(_form)
    }

    const updateField = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onKeyUp = (e) => {
        if (e.keyCode === 13) {
            let _form = form
            setForm({
                ...form,
                "message": ''
            });

            _form.customer_id = contact.id
            console.log('_form', _form);
            onSendMessage(_form)
        }
    }

    const onScroll = (e) => {
        console.log('e', e.target);
        // console.log('e.scrollHeight', e.target.scrollHeight);
        // console.log('e.scrollTop', e.target.scrollTop);
        // console.log('e.clientHeight', e.target.clientHeight);
    }

    const onChangeContact = (cont) => {
        setContact(cont)
        setListMessages(cont.messages)
    }

    return (
        <TemplateMenu>
            <Container fluid>
                <Row className='my-2'>
                    <Col>WhatsApp</Col>
                </Row>
                <Row>
                    <Col lg={2}>
                        <Card>
                            <Card.Body className='px-0 py-0'>
                                {listContact.map((d, k) =>
                                    <Alert key={k} className='cursor-pointer mb-0' variant={`${d.id === contact.id ? 'secondary' : ''}`}
                                        onClick={() => onChangeContact(d)}>{d.name}</Alert>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Body className='size-chat' onScroll={onScroll} ref={divScroll}>
                                {listMessages.map((d, k) => <Row key={k} className='my-2'>
                                    <Col>{(d.type_message_id === 2) && <>{d.message} <span className="text-muted font-11">{moment(d.updatedAt).format("hh:mm A")}</span></>}</Col>
                                    <Col className='text-right'>{(d.type_message_id === 1) && <>{d.message} <span className="text-muted font-11">{moment(d.updatedAt).format("hh:mm A")}</span></>}</Col>
                                </Row>)}

                            </Card.Body>
                            <Card.Footer>
                                <Row>
                                    <Col>
                                        <InputGroup className="mb-3">
                                            <FormControl
                                                placeholder="Escribe un mensaje Mensaje"
                                                aria-label="Username"
                                                aria-describedby="basic-addon1"
                                                name='message'
                                                onChange={updateField}
                                                value={form.message}
                                                onKeyUp={onKeyUp}
                                            />
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="basic-addon1" onClick={onSendMessageForm} className='cursor-pointer'>
                                                    <AiOutlineSend />
                                                </InputGroup.Text>
                                            </InputGroup.Prepend>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { whatsapp } = props
    return {
        fetching_list: whatsapp.fetching_list,
        fetching_send: whatsapp.fetching_send,
        data: whatsapp.data,
    }
}

export default connect(mapsState, { getMessages, onSendMessage })(WhatsappPage);