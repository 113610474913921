import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import TemplateMenu from '../../template/menu.template'
import { onCreatePrescription, onUpdatePrescription } from '../../Redux/prescriptionDuck';
import { getProductById } from '../../Redux/productDuck';
import { getModifierById } from '../../Redux/modifierDuck';
import { toogleLoader } from '../../Redux/alertDuck';
import FormPrescription from './form.prescription';

const CreatePrescriptionPage = ({ onCreatePrescription, onUpdatePrescription, fetching_create, toogleLoader,
    getProductById, selected, fetching_by_id, getModifierById, fetching_by_id_modifier, row }) => {
    const history = useHistory();


    const search = window.location.search;
    const params = new URLSearchParams(search);
    const productId = params.get('product');
    const type = params.get('type');
    const [product, setProduct] = useState({})

    useEffect(() => {
        if (productId !== null) {
            if (parseInt(type) === 1) {
                getProductById(productId)
            } else {
                getModifierById(productId)
            }
        }

    }, [productId])

    useEffect(() => {
        if (fetching_by_id === 2) {
            setProduct(selected)
        }
    }, [fetching_by_id])

    useEffect(() => {
        if (fetching_by_id_modifier === 2) {
            setProduct(row)
        }
    }, [fetching_by_id_modifier])

    useEffect(() => {
        if (fetching_create === 2) {
            //history.goBack();
            toogleLoader(false)
            history.push("/recetas");
        }
    }, [fetching_create])

    const onSucess = (data) => {
        toogleLoader(true)
        if (Object.keys(data.prescription).length > 0) {
            data.id = data.prescription.id;
            onUpdatePrescription(data)
        } else {
            onCreatePrescription(data);
        }
    }


    return (
        <TemplateMenu>
            <FormPrescription formEdit={{}} onSuccess={onSucess} product_parameter={product} />
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { prescription, product, modifier } = props

    return {
        errors: {},
        fetching_create: prescription.fetching_create,
        fetching_by_id: product.fetching_by_id,
        fetching_by_id_modifier: modifier.fetching_by_id,
        row: modifier.row,
        selected: product.selected,
    }
}

export default connect(mapsState, {
    onCreatePrescription, onUpdatePrescription, toogleLoader,
    getProductById, getModifierById
})(CreatePrescriptionPage);