import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import TemplateMenu from "../../template/menu.template";
import {
  getCourtesyById,
  onCreateCourtesyDetail,
} from "../../Redux/courtesyDuck";

const initState = {
  consecutive: "",
  name: "",
  phone: "",
  invoice: "",
};
const EditCourtesyPage = ({
  getCourtesyById,
  row,
  fetching_by_id,
  onCreateCourtesyDetail,
  fetching_create,
}) => {
  let { id } = useParams();
  const [data, setData] = useState({});
  const [list, setList] = useState([]);
  const [form, setForm] = useState(initState);

  useEffect(() => {
    if (fetching_create === 2) {
      setForm(initState);
      getCourtesyById(id);
    }
    if (fetching_create === 3) {
      alert(`Consecutivo (${form.consecutive}) ya Existe`);
    }
  }, [fetching_create]);
  useEffect(() => {
    if (fetching_by_id === 2) {
      setData(row);
      setList(row.detail);
    }
  }, [fetching_by_id]);

  useEffect(() => {
    getCourtesyById(id);
  }, [id]);

  const onSave = () => {
    let _form = form;
    _form.courtesy_id = id;
    onCreateCourtesyDetail(form);
  };
  const updateInput = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <TemplateMenu>
      <Row className="my-2">
        <Col lg={2}>
          <h3>{data.name}</h3>
        </Col>
        <Col lg={5}>
          <h3>Cantidad: {data.amount}</h3>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col lg={10}>
          <Table className="table-sm table-bordered table-hover">
            <thead className="thead-dark">
              <tr>
                <th>Numero</th>
                <th>Cliente</th>
                <th>Celular</th>
                <th>Factura POS</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    className="form-control"
                    placeholder="numero de Tiquete"
                    type={"number"}
                    name="consecutive"
                    value={form.consecutive}
                    onChange={updateInput}
                  />
                </td>
                <td>
                  <input
                    className="form-control"
                    placeholder="Nombre Cliente"
                    name="name"
                    value={form.name}
                    onChange={updateInput}
                  />
                </td>
                <td>
                  <input
                    type={"number"}
                    className="form-control"
                    placeholder="Numero de Celular"
                    name="phone"
                    value={form.phone}
                    onChange={updateInput}
                  />
                </td>
                <td>
                  <input
                    type={"number"}
                    className="form-control"
                    placeholder="Factura POS"
                    name="invoice"
                    value={form.invoice}
                    onChange={updateInput}
                  />
                </td>
                <td>
                  <button className="btn btn-sm btn-info" onClick={onSave}>
                    Guardar
                  </button>
                </td>
              </tr>
              {list.map((d) => (
                <tr key={d.id}>
                  <td>{d.consecutive}</td>
                  <td>{d.name}</td>
                  <td>{d.phone}</td>
                  <td>{d.invoice}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </TemplateMenu>
  );
};

const mapsState = (props) => {
  let { courtesy } = props;
  return {
    fetching_by_id: courtesy.fetching_by_id,
    fetching_create: courtesy.fetching_create,
    row: courtesy.row,
  };
};
export default connect(mapsState, { getCourtesyById, onCreateCourtesyDetail })(
  EditCourtesyPage
);
