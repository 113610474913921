import React, { useEffect, useState } from 'react'
import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import TemplateMenu from '../../template/menu.template'
import { onCreateTransformation } from '../../Redux/transformationDuck';
import FormTransformation from './form.transformation';
const API_URL = process.env.REACT_APP_API_URL

const CreateTransformationPage = ({ onCreateTransformation, errors, fetching_create }) => {


    const history = useHistory();

    useEffect(() => {
        if (fetching_create === 2) {
            history.goBack();
        }
    }, [fetching_create])


    const onSavePrescription = (form) => {
        onCreateTransformation(form)
    }

    return (
        <TemplateMenu>
            <Row className='d-flex justify-content-center'>
                <Col>
                    <Row className='my-2'>
                        <Col>
                            <button className='btn btn-sm btn-secondary' onClick={() => history.goBack()}>Volver</button>
                        </Col>
                    </Row>
                    <Row className='d-flex justify-content-center'>
                        <Col>
                            <FormTransformation title="Crear Transformación" errors={errors} formEdit={{}} onSubmit={onSavePrescription} />
                        </Col>
                    </Row>
                </Col >
            </Row >
        </TemplateMenu >
    )
}

const mapsState = (props) => {
    let { transformation } = props

    return {
        errors: {},
        fetching_create: transformation.fetching_create
    }
}

export default connect(mapsState, { onCreateTransformation })(CreateTransformationPage);