import React, { useEffect, Fragment, useState } from 'react'
import { connect } from 'react-redux';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { getCategories, clearSelected, deleteCategory } from '../../Redux/categoryDuck'
import ModalCategory from './ModalCategory'
import BtnWhiteHeader from '../Components/BtnWhiteHeader';
import logo_madera from '../../Images/logo_madera.png'
import TemplateMenu from '../../template/menu.template'


import './index.css'

const API_URL = process.env.REACT_APP_API_URL


const Categories = ({ getCategories, data, clearSelected, deleteCategory, fetching_delete }) => {

    const [showModal, setShowModal] = useState(false);
    const [formEdit,setFormEdit] = useState({})

    useEffect(() => {
        getCategories()
    }, [])


    useEffect(() => {
        if (fetching_delete === 2) {
            getCategories()
        }

    }, [fetching_delete])

    const deleteCategoryForm = (item) => {

        if (window.confirm("¿Realmente Deseas Eliminar esta Categoria?")) {
            deleteCategory(item)
        }
    }
    const editCategory = (item) => {
        setFormEdit(item)
        setShowModal(true)
    }
    const onHide = () => {
        setShowModal(false);
    }

    const showModalTmp = () => {
        setShowModal(true);
        clearSelected()
    }

    const onSuccess = () => {
        getCategories()
        setShowModal(false);
    }


    const printDetail = (item, i) => {

        let photo = item.photo===''?logo_madera:API_URL + "/"+item.photo;

        return (<Fragment key={i}>

            <Col lg={4} md={6} className="my-1">
                <Row>
                    <Col>
                        <img src={photo} className="img-fluid" />
                    </Col>
                </Row>
                <Row className={` rounded-lg p-2 border active_list_reservation m-2 ${item.status_id === 2 ? 'category-disabled' : ''}`}>
                    <Col lg={5} md={4} className="d-flex flex-column justify-content-center align-items-center border-dot-naranja">
                        <span>Orden No.</span>
                        <span className="text-naranja display-4 text-center">{item.priority}</span>
                    </Col>
                    <Col lg={7} md={8} className="d-flex flex-column justify-content-center align-items-start">
                        <span className="text-naranja">Título</span>
                        <h5>{item.title}</h5>
                        <span className="">Estado {item.status_id === 1 ? 'Activo' : 'Inactivo'}</span>
                    </Col>
                    <Col lg={12} md={12} className="border-top mt-2">
                        <Row>
                            <Col lg={6} md={6} className="justify-content-center d-flex mt-2">
                                <button className="btn-clear bg-light rounded-lg w-100 " onClick={() => deleteCategoryForm(item)}>
                                    Desactivar
                                </button>
                            </Col>
                            <Col lg={6} md={6} className="justify-content-center d-flex mt-2 ">
                                <button className="btn-clear bg-naranja text-white rounded-lg w-100" onClick={() => editCategory(item)}>
                                    Editar
                                </button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>



        </Fragment>)
    }

    return <TemplateMenu>
        <Container fluid>
            <Row className="my-4">
                <Col lg={2} md={4}>

                    <h4 className="font-title pt-3">CATEGORÍAS</h4>
                </Col>
                <Col lg={2} md={4}>
                    <BtnWhiteHeader title="Crear" onClick={showModalTmp} />
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Row>
                        {(data !== undefined) && data.map(printDetail)}
                    </Row>
                </Col>
            </Row>
            <ModalCategory showModal={showModal} onHide={()=>onHide()} onSuccess={()=>onSuccess()} selected={formEdit}/>
        </Container>
    </TemplateMenu>
}

const mapsState = (props) => {
    return {
        data: props.category.data,
        fetching_delete: props.category.fetching_delete
    }
}

export default connect(mapsState, { getCategories, clearSelected, deleteCategory })(Categories);