import axios from 'axios'

import { getHeaders } from '../utils';

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    fetching_list_filter: 0,
    fetching_create: 0,
    fetching_create_category: 0,
    fetching_delete: 0,
    fetching_by_id: 0,
    data: [],
    data_filter: [],
    errors: {},
    row: {}
}

let LIST_RECIPE_BOOK = "LIST_RECIPE_BOOK";
let LIST_RECIPE_BOOK_SUCCESS = "LIST_RECIPE_BOOK_SUCCESS";
let LIST_RECIPE_BOOK_SUCCESS_FILTER = "LIST_RECIPE_BOOK_SUCCESS_FILTER";
let RECIPE_BOOK = "RECIPE_BOOK";
let RECIPE_BOOK_SUCCESS = "RECIPE_BOOK_SUCCESS";
let RECIPE_BOOK_BY_ID = "RECIPE_BOOK_BY_ID";
let RECIPE_BOOK_BY_ID_SUCCESS = "RECIPE_BOOK_BY_ID_SUCCESS";
let CREATE_CATEGORY_RECIPE = "CREATE_CATEGORY_RECIPE";
let CREATE_CATEGORY_RECIPE_SUCCESS = "CREATE_CATEGORY_RECIPE_SUCCESS";
let CREATE_CATEGORY_RECIPE_ERROR = "CREATE_CATEGORY_RECIPE_ERROR";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_RECIPE_BOOK:
            return { ...state, fetching_list: 1, fetching_list_filter: 1 }
        case LIST_RECIPE_BOOK_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload, fetching_create: 0 }
        case LIST_RECIPE_BOOK_SUCCESS_FILTER:
            return { ...state, fetching_list_filter: 2, data_filter: action.payload, fetching_create: 0 }
        case RECIPE_BOOK:
            return { ...state, fetching_create: 1 }
        case RECIPE_BOOK_SUCCESS:
            return { ...state, fetching_create: 2 }
        case RECIPE_BOOK_BY_ID:
            return { ...state, fetching_by_id: 1 }
        case RECIPE_BOOK_BY_ID_SUCCESS:
            return { ...state, fetching_by_id: 2, row: action.payload }
        case CREATE_CATEGORY_RECIPE:
            return { ...state, fetching_create_category: 1 }
        case CREATE_CATEGORY_RECIPE_SUCCESS:
            return { ...state, fetching_create_category: 2 }
        case CREATE_CATEGORY_RECIPE_ERROR:
            return { ...state, fetching_create_category: 3, errors: action.payload }
        default:
            return state;
    }
}

export let getRecipeBooks = () => (dispatch, getState) => {
    dispatch({
        type: LIST_RECIPE_BOOK,
    });


    let url = `${API_URL}/api/recipe-book`;


    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: LIST_RECIPE_BOOK_SUCCESS,
            payload: resp.results
        });

    })
}

export let getRecipeBookId = (id) => (dispatch, getState) => {
    dispatch({
        type: RECIPE_BOOK_BY_ID,
    });


    let url = `${API_URL}/api/recipe-book/${id}`;

    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: RECIPE_BOOK_BY_ID_SUCCESS,
            payload: resp
        });

    })
}
export let getRecipeBookAddId = (id) => (dispatch, getState) => {
    dispatch({
        type: RECIPE_BOOK_BY_ID,
    });


    let url = `${API_URL}/api/recipe-book-add/${id}`;

    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: RECIPE_BOOK_BY_ID_SUCCESS,
            payload: resp
        });

    })
}

export let createOrUpdateRecipeBooks = (form) => (dispatch, getState) => {
    dispatch({
        type: RECIPE_BOOK,
    });

    let url = `${API_URL}/api/recipe-book`;

    console.log('form:::', form);


    let _form = new FormData()
    _form.append("id", form.product_id);
    _form.append("image", form.image);
    _form.append("video_preparation", form.video_preparation);
    _form.append("image_on_table", form.image_on_table);
    _form.append("image_to_take", form.image_to_take);
    _form.append("list_preparation", JSON.stringify(form.list_preparation));

    axios.post(url, _form, {
        headers: getHeaders()
    }).then(resp => {
        dispatch({
            type: RECIPE_BOOK_SUCCESS,
        });
        console.log('resp', resp);


    })

}

export let createOrUpdateRecipeBooksAdd = (form) => (dispatch, getState) => {
    dispatch({
        type: RECIPE_BOOK,
    });

    let url = `${API_URL}/api/recipe-book-add`;

    let _form = new FormData()
    _form.append("id", form.id || '');
    _form.append("title", form.title);
    _form.append("category_id", form.category_id);
    _form.append("image", form.image);
    _form.append("image_preparation", form.image_preparation);
    _form.append("image_ingredient", form.image_ingredient);
    _form.append("video", form.video);
    _form.append("list_preparation", JSON.stringify(form.listPreparations));
    _form.append("list_ingredients", JSON.stringify(form.listIngredients));

    axios.post(url, _form, {
        headers: getHeaders()
    }).then(resp => {
        dispatch({
            type: RECIPE_BOOK_SUCCESS,
        });
        console.log('resp', resp);


    })
}

export let createCategoryRecipe = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_CATEGORY_RECIPE,
    });

    let url = `${API_URL}/api/recipe-book-category`;

    axios.post(url, form, {
        headers: getHeaders()
    }).then(resp => {
        dispatch({
            type: CREATE_CATEGORY_RECIPE_SUCCESS,
        });

    }).catch(err => {
        dispatch({
            type: CREATE_CATEGORY_RECIPE_ERROR,
        });
    })
}

export let updateCategoryRecipe = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_CATEGORY_RECIPE,
    });

    let url = `${API_URL}/api/recipe-book-category/${form.id}`;

    axios.put(url, form, {
        headers: getHeaders()
    }).then(resp => {
        dispatch({
            type: CREATE_CATEGORY_RECIPE_SUCCESS,
        });

    })
}

export let updateStatusForApp = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_CATEGORY_RECIPE,
    });

    let url = `${API_URL}/api/recipe-book-for-app/${form.id}`;

    axios.put(url, form, {
        headers: getHeaders()
    }).then(resp => {
        dispatch({
            type: CREATE_CATEGORY_RECIPE_SUCCESS,
        });

    })
}

export let getCategoryRecipe = (form) => (dispatch, getState) => {
    dispatch({
        type: LIST_RECIPE_BOOK,
    });


    let branch = JSON.parse(localStorage.getItem("branch"));
    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));

    let url = `${API_URL}/api/recipe-book-category?branch=${branch.id}&date_init=${date_init}&date_end=${date_end}`;
    url += `&search=${form.search}&category_id=${form.category_id}&filter_app=false`

    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {

        if (form.category_id !== 0 || form.search !== '') {
            dispatch({
                type: LIST_RECIPE_BOOK_SUCCESS_FILTER,
                payload: resp.results
            });
        } else {
            dispatch({
                type: LIST_RECIPE_BOOK_SUCCESS,
                payload: resp.results
            });
        }
    })
}
export let getCategoryRecipeById = (id) => (dispatch, getState) => {
    dispatch({
        type: RECIPE_BOOK_BY_ID,
    });

    let branch = JSON.parse(localStorage.getItem("branch"));
    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));

    let url = `${API_URL}/api/recipe-book-category/${id}?branch=${branch.id}&date_init=${date_init}`;


    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: RECIPE_BOOK_BY_ID_SUCCESS,
            payload: resp
        });

    })
}


export let deleteRecipeBook = (id) => (dispatch, getState) => {
    dispatch({
        type: RECIPE_BOOK,
    });


    let url = `${API_URL}/api/recipe-book/${id}`;

    fetch(url, {
        method: "delete",
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: RECIPE_BOOK_SUCCESS,
        });

    })
}

export let deleteRecipeBookCategory = (id) => (dispatch, getState) => {
    dispatch({
        type: RECIPE_BOOK,
    });


    let url = `${API_URL}/api/recipe-book-category/${id}`;

    fetch(url, {
        method: "delete",
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: RECIPE_BOOK_SUCCESS,
        });

    })
}