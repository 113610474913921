import React from "react";
import { formatNumber } from "../../utils";
import {
  AiFillEdit,
  AiFillEye,
  AiFillFilePdf,
  AiOutlineFileDone,
  AiOutlineLock,
} from "react-icons/ai";

const IngresosItem = ({ item, user, onUnLock, is_up, showBranch, onDoubleClick }) => {
  let { role_id } = user;
  let total_sales = parseFloat(item.aldelo) - parseFloat(item.payments);

  let status = item.diference < 0 ? "text-danger" : "";
  let show =
    role_id === 5 || role_id === 1 || role_id === 2 || role_id === 4
      ? true
      : false;

  let aldelo = item.aldelo;
  let cash = item.cash;
  let nequi = item.nequi;
  let datafono = item.datafono;
  let daviplata = item.daviplata;
  let tips = item.tips;
  let cancellations = item.cancellations;
  let credit_home = item.credit_home;

  if (item.status_id === 4) {
    aldelo = item.aldelo_real;
    cash = item.cash_real;
    nequi = item.nequi_real;
    datafono = item.datafono_real;
    daviplata = item.daviplata_real;
    tips = item.tips_real;
    cancellations = item.cancellations_real;
    credit_home = item.credit_home_real;
  }

  return (
    <tr className={`${item.status_id === 3 ? 'table-warning' : ''} ${item.status_id === 4 ? 'table-success' : ''}`}>
      {/* <td>{item.id}</td> */}
      <td className="text-nowrap text-date">
        <a href="#" onDoubleClick={onDoubleClick}>{item.registration_date_text}</a>
      </td>
      {showBranch &&
        <td className="text-date">{item.branch}</td>}
      <td align="right" className={`${is_up ? '' : ''}`}>{formatNumber(aldelo || 0)}</td>
      <td align="right">{formatNumber(aldelo - tips)}</td>
      <td align="right">{formatNumber(item.payments)}</td>
      <td align="right">{formatNumber(total_sales || 0)}</td>
      <td align="right">{formatNumber(cash || 0)}</td>
      <td align="right">{formatNumber(datafono || 0)}</td>
      <td align="right">{formatNumber(nequi || 0)}</td>
      <td align="right">{formatNumber(daviplata || 0)}</td>
      <td align="right">{formatNumber(item.rappi || 0)}</td>
      <td align="right">{formatNumber(credit_home || 0)}</td>
      <td align="right">{formatNumber(item.entregado || 0)}</td>
      <td align="right" className={`${status} `}>
        {formatNumber(item.diference || 0)}
      </td>
      <td align="right">{formatNumber(tips || 0)}</td>
      <td className="text-date">{item.status.toUpperCase()}</td>
      {!showBranch && <td>
        <a className="" href={`/ingresos/${item.id}`}>
          {item.status_id === 1 && <AiFillEdit size={25} />}
        </a>
        {(item.status_id === 2 ||
          item.status_id === 4 ||
          item.status_id === 3) &&
          show == true && (
            <a
              className="ml-2"
              href={`/ingresos/${item.registration_date}/check`}
            >
              <AiOutlineFileDone size={25} />
            </a>
          )}
        {(role_id === 1 || role_id === 2) && item.status_id === 4 && (
          <AiOutlineLock
            size={25}
            className="cursor-pointer"
            onClick={() => onUnLock(item)}
          />
        )}
      </td>}

    </tr>
  );
};
export default IngresosItem;
