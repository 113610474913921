import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import { connect } from "react-redux";
import TemplateMenu from "../../template/menu.template";
import { getInventory, resetStatus, onReloadItemKardex } from "../../Redux/inventoryDuck";
import ItemTableInventory from "./item.table.inventory";
import { getProductsInventory } from "../../Redux/ProductInventoryDuck";
import { toogleLoader } from "../../Redux/alertDuck";
import { debounceData, formatNumber, getHeaders } from "../../utils";
import AsyncSelect from "react-select/async";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { RiFileExcel2Fill } from "react-icons/ri";


const API_URL = process.env.REACT_APP_API_URL;

const InventoryPage = ({ getInventory, fetching_list, data, getProductsInventory, fetching_list_product, products,
  resetStatus, toogleLoader, onReloadItemKardex, fetching_reload }) => {
  const [list, setList] = useState([]);
  const [form, setForm] = useState({ product_id: "", date_init: '', date_end: '', move_id: 0 });
  const [listProduct, setListProduct] = useState([]);
  const [product, setProduct] = useState("");
  const [quantityOut, setQuantityOut] = useState(0);
  const [quantityIn, setQuantityIn] = useState(0);
  const [costIn, setCostIn] = useState(0);
  const [costOut, setCostOut] = useState(0);
  const tableRef = useRef(null);
  const debounceRef = useRef();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const productId = params.get('product_id');
  const title = params.get('title');

  useEffect(() => {

    if (productId !== null) {
      let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))

      let _form = form
      _form.product_id = productId;
      _form.date_init = date_init
      _form.date_end = date_end

      setProduct({
        label: title,
        value: productId
      })
      getInventory(_form);
    }

  }, [productId])

  useEffect(() => {
    if (fetching_reload === 2) {
      getInventory(form);
    }
  }, [fetching_reload])

  useEffect(() => {
    resetStatus()
    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))
    setForm({
      ...form,
      ["date_init"]: date_init,
      ["date_end"]: date_end,
    })
  }, [])


  useEffect(() => {
    if (fetching_list === 2 || fetching_list === 4) {
      setList(data);

      let _out = data
        .filter((d) => d.type_move_id === 2)
        .reduce((a, b) => a + parseFloat(b.amount), 0);
      setQuantityOut(_out);
      let _cost_out = data
        .filter((d) => d.type_move_id === 2)
        .reduce((a, b) => a + parseFloat(b.cost) * parseFloat(b.amount), 0);
      setCostOut(_cost_out);

      let _cost_in = data
        .filter((d) => d.type_move_id === 1)
        .reduce((a, b) => a + parseFloat(b.cost) * parseFloat(b.amount), 0);
      setCostIn(_cost_in);
      let _in = data
        .filter((d) => d.type_move_id === 1)
        .reduce((a, b) => a + parseFloat(b.amount), 0);
      setQuantityIn(_in);
      toogleLoader(false)
    }
  }, [fetching_list]);


  const updateInput = (e) => {
    let _form = {
      ...form,
      [e.target.name]: e.target.value,
    };
    setForm(_form);

    if (_form.product_id !== '') {
      getInventory(_form);
    }
  }

  const loadOptions = async (inputValue) => {

    if (debounceRef.current)
      clearTimeout(debounceRef.current)


    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));
    let branch = JSON.parse(localStorage.getItem("branch"));
    let url = `${API_URL}/api/product-inventory-search/${inputValue}?date_init=${date_init}&date_end=${date_end}&branch=${branch.id}`;

    return await debounceData(debounceRef, url)
  };

  const onSearch = () => {
    if (Object.keys(product).length > 0) {
      toogleLoader(true)
      getInventory(form);
    }
  }


  const onReloadDate = (item) => {
    if (window.confirm(`¿Deseas Recalcular este Registro?`)) {
      onReloadItemKardex(item)
    }
  }


  return (
    <TemplateMenu>
      <Row>
        <Col lg={1}>
          <h2>Kardex</h2>
        </Col>
        <Col className="d-flex align-items-center">
          <DownloadTableExcel
            filename="Kardex"
            sheet="kardex"
            currentTableRef={tableRef.current}>
            <RiFileExcel2Fill size={25} color="green" />
          </DownloadTableExcel>
        </Col>
      </Row>
      <Row className="my-2">
        <Col lg={4}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Producto</Form.Label>
            <AsyncSelect
              ref={debounceRef}
              value={product}
              loadOptions={loadOptions}
              placeholder="Busca el producto"
              onChange={(e) => {
                setProduct(e)
                setForm({
                  ...form,
                  ["product_id"]: e.value
                })
                let _form = form
                _form.product_id = e.value;
                getInventory(_form);
                toogleLoader(true)
              }}
            />
          </Form.Group>

        </Col>
        <Col lg={2}>
          <div className='form-group'>
            <label>Tipo de Movimiento</label>
            <select className='form-control' name="move_id" value={form.move_id} onChange={updateInput}>
              <option value={0}>VER TODOS</option>
              <option value={1}>Inventario Inicial</option>
              <option value={2}>Transferencias</option>
              <option value={3}>Compras</option>
              <option value={4}>Bajas</option>
              <option value={5}>Transformaciones</option>
              <option value={6}>Producciones</option>
              <option value={7}>Ventas POS</option>

            </select>
          </div>
        </Col>
        <Col lg={2}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Fecha Inicio</Form.Label>
            <Form.Control
              type="date"
              min={form.date_init}
              max={form.date_end}
              value={form.date_init}
              name="date_init"
              onChange={updateInput}
            />
          </Form.Group>
        </Col>
        <Col lg={2}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Fecha Fin</Form.Label>
            <Form.Control
              type="date"
              value={form.date_end}
              name="date_end"
              onChange={updateInput}
            />
          </Form.Group>
        </Col>
        <Col>
          <button className="btn btn-sm btn-info" onClick={onSearch}>
            Buscar
          </button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table className="table-bordered table-sm table-hover" responsive ref={tableRef}>
            <thead className="thead-dark">
              <tr>
                <th rowSpan={2}></th>
                <th rowSpan={2} align="center">
                  Fecha
                </th>
                <th rowSpan={2} className="text-center">
                  Descripción
                </th>
                <th rowSpan={2} className="text-center">
                  Valor Unit
                </th>
                <th colSpan={2} className="text-center">
                  Entradas
                </th>
                <th colSpan={2} className="text-center">
                  Salidas
                </th>
                <th colSpan={2} className="text-center">
                  Saldo
                </th>
              </tr>
              <tr>
                <th className="text-center">Cantidad</th>
                <th className="text-center">Valor</th>
                <th className="text-center">Cantidad</th>
                <th className="text-center">Valor</th>
                <th className="text-center">Cantidad</th>
                <th className="text-center">Valor</th>
              </tr>
            </thead>
            <tbody>
              {list.length > 0 ? (
                list.map((row, index) => <ItemTableInventory row={row} key={index} index={index + 1} onReloadDate={onReloadDate} />)
              ) : (
                <tr>
                  <td colSpan={12} align="center">
                    No se encontraron registros
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={4}>
                  <b>TOTAL</b>
                </td>
                <td align="right">
                  <b>{formatNumber(quantityIn)}</b>
                </td>
                <td align="right">
                  <b>{formatNumber(costIn)}</b>
                </td>
                <td align="right">
                  <b>{formatNumber(quantityOut)}</b>
                </td>
                <td align="right">
                  <b>{formatNumber(costOut)}</b>
                </td>
              </tr>
            </tfoot>
          </Table>
        </Col>
      </Row>
    </TemplateMenu>
  );
};
const mapsState = (props) => {
  let { inventory, product_inventory } = props;
  return {
    fetching_list: inventory.fetching_list,
    fetching_reload: inventory.fetching_reload,
    data: inventory.data,
    products: product_inventory.data,
    fetching_list_product: product_inventory.fetching_list,
  };
};

export default connect(mapsState, { getInventory, getProductsInventory, resetStatus, toogleLoader, onReloadItemKardex })(InventoryPage);
