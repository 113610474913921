import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { formatNumber } from '../../utils';

const ItemTransferEditDelivery = ({ item,show }) => {
    let { product_inventory } = item
    let {measure_unit} = product_inventory
    
    let subtotal = item.cost * item.quantity;
    let charge = (subtotal * item.charge/100)
    let subtotal_charge=subtotal + charge
    
    return (
        <tr>
            <td>{product_inventory.name} ({measure_unit.description})</td>
            <td align='right'>{item.quantity}</td>
            <td align='right'>{formatNumber(subtotal_charge/item.quantity)}</td>
            <td align='right'>{formatNumber(subtotal_charge)}</td>
        </tr>
    )
}

export default ItemTransferEditDelivery;