import { getHeaders, validateExpense, validateExpensePersonal } from '../utils';
const API_URL = process.env.REACT_APP_API_URL
let initialState = {
    fetching_list: 0,
    fetching_create: 0,
    fetching_by_id: 0,
    fetching_delete: 0,
    fetching_mark: 0,
    data: [],
    errors: {},
    row: {}
}

let LIST_EXPENSES = "LIST_EXPENSES";
let LIST_EXPENSES_SUCCESS = "LIST_EXPENSES_SUCCESS";
let CREATE_EXPENSES = "CREATE_EXPENSES";
let CREATE_EXPENSES_SUCCESS = "CREATE_EXPENSES_SUCCESS";
let CREATE_EXPENSES_ERROR = "CREATE_EXPENSES_ERROR";
let GET_EXPENSES_BY_ID = "GET_EXPENSES_BY_ID";
let GET_EXPENSES_BY_ID_SUCCESS = "GET_EXPENSES_BY_ID_SUCCESS";
let DELETE_EXPENSE_PAYMENT = "DELETE_EXPENSE_PAYMENT";
let DELETE_EXPENSE_PAYMENT_SUCCESS = "DELETE_EXPENSE_PAYMENT_SUCCESS";
let MARK_ROW = "MARK_ROW";
let MARK_ROW_SUCCESS = "MARK_ROW_SUCCESS";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_EXPENSES:
            return { ...state, fetching_list: 1, }
        case LIST_EXPENSES_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload, fetching_mark: 0 }
        case CREATE_EXPENSES:
            return { ...state, fetching_create: 1, }
        case CREATE_EXPENSES_SUCCESS:
            return { ...state, fetching_create: 2, row: action.payload, errors: {} }
        case CREATE_EXPENSES_ERROR:
            return { ...state, fetching_create: 3, errors: action.payload }
        case GET_EXPENSES_BY_ID:
            return { ...state, fetching_by_id: 1 }
        case GET_EXPENSES_BY_ID_SUCCESS:
            return { ...state, fetching_by_id: 2, row: action.payload }
        case DELETE_EXPENSE_PAYMENT:
            return { ...state, fetching_delete: 1 }
        case DELETE_EXPENSE_PAYMENT_SUCCESS:
            return { ...state, fetching_delete: 2 }
        case MARK_ROW:
            return { ...state, fetching_mark: 1 }
        case MARK_ROW_SUCCESS:
            return { ...state, fetching_mark: 2 }
        default:
            return state;
    }
}

export let getExpenses = (q) => (dispatch, getState) => {
    dispatch({
        type: LIST_EXPENSES,
    });

    let branch = JSON.parse(localStorage.getItem("branch"))


    console.log('q', q);



    let url = `${API_URL}/api/expense?branch_id=${branch.id}${q}`;

    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {

        dispatch({
            type: LIST_EXPENSES_SUCCESS,
            payload: resp.total
        });
    })
}

export let getExpenseById = (id) => (dispatch, getState) => {
    dispatch({
        type: GET_EXPENSES_BY_ID,
    });

    let url = `${API_URL}/api/expense/${id}`;

    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {

        dispatch({
            type: GET_EXPENSES_BY_ID_SUCCESS,
            payload: resp
        });
    })
}

export let getExpenseByType = (type, _filter) => (dispatch, getState) => {
    dispatch({
        type: GET_EXPENSES_BY_ID,
    });

    let branch = JSON.parse(localStorage.getItem("branch"))

    let url = `${API_URL}/api/expense-type/${type}${_filter || ''}&branch_id=${branch.id}`;

    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {

        dispatch({
            type: GET_EXPENSES_BY_ID_SUCCESS,
            payload: resp
        });
    })
}

export let deleteExpensePayment = (id) => (dispatch, getState) => {
    dispatch({
        type: DELETE_EXPENSE_PAYMENT,
    });

    let url = `${API_URL}/api/expense-payment/${id}`;

    fetch(url, {
        method: "delete",
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {

        dispatch({
            type: DELETE_EXPENSE_PAYMENT_SUCCESS,
        });
    })
}


export let deleteExpensePersonal = (id) => (dispatch, getState) => {
    dispatch({
        type: DELETE_EXPENSE_PAYMENT,
    });

    let url = `${API_URL}/api/expense-personal/${id}`;

    fetch(url, {
        method: "delete",
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {

        dispatch({
            type: DELETE_EXPENSE_PAYMENT_SUCCESS,
        });
    })
}

export let onCreateorUpdateExpenses = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_EXPENSES,
    });
    let branch = JSON.parse(localStorage.getItem("branch"))

    form.branch = branch

    let validate = validateExpensePersonal(form)

    if (Object.keys(validate).length > 0) {
        dispatch({
            type: CREATE_EXPENSES_ERROR,
            payload: validate
        })
        return;
    }

    let method = "POST";
    let url = `${API_URL}/api/expense`;

    if (form.id !== undefined) {
        method = "PUT";
        url = `${API_URL}/api/expense/${form.id}`;
    }

    fetch(url, {
        method,
        headers: getHeaders(),
        body: JSON.stringify(form)
    }).then(resp => resp.json()).then(resp => {

        if (resp.status === false) {
            console.log('resp|', resp);

            dispatch({
                type: CREATE_EXPENSES_ERROR,
                payload: resp.errors
            })
        } else {
            dispatch({
                type: CREATE_EXPENSES_SUCCESS,
                payload: resp
            });
        }


    })
}

export let onCreateExpensesPersonal = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_EXPENSES,
    });

    let method = "POST";
    let url = `${API_URL}/api/expense-personal`;

    let _branch = JSON.parse(localStorage.getItem("branch"))

    form.branch_id = _branch.id

    fetch(url, {
        method,
        headers: getHeaders(),
        body: JSON.stringify(form)
    }).then(resp => resp.json()).then(resp => {

        if (resp.status === false) {

            dispatch({
                type: CREATE_EXPENSES_ERROR,
                payload: resp.errors
            })
        } else {
            dispatch({
                type: CREATE_EXPENSES_SUCCESS,
                payload: resp
            });
        }


    })
}

export let onUpdateExpensesPersonal = (id, amount) => (dispatch, getState) => {
    dispatch({
        type: CREATE_EXPENSES,
    });

    let method = "PUT";
    let url = `${API_URL}/api/expense-personal/${id}`;


    fetch(url, {
        method,
        headers: getHeaders(),
        body: JSON.stringify({ amount })
    }).then(resp => resp.json()).then(resp => {

        if (resp.status === false) {

            dispatch({
                type: CREATE_EXPENSES_ERROR,
                payload: resp.errors
            })
        } else {
            dispatch({
                type: CREATE_EXPENSES_SUCCESS,
                payload: resp
            });
        }


    })
}

export let onMarkSelection = (item, selectedColor) => (dispatch, getState) => {
    dispatch({
        type: MARK_ROW,
    });

    let method = "PUT";
    let url = `${API_URL}/api/expense-personal-mark/${item.id}`;


    fetch(url, {
        method,
        headers: getHeaders(),
        body: JSON.stringify({ selectedColor })
    }).then(resp => resp.json()).then(resp => {

        if (resp.status === false) {

            dispatch({
                type: CREATE_EXPENSES_ERROR,
                payload: resp.errors
            })
        } else {
            dispatch({
                type: MARK_ROW_SUCCESS,
            });
        }


    })
}
export let onMarkSelectionTotal = (item, selectedColor) => (dispatch, getState) => {
    dispatch({
        type: MARK_ROW,
    });

    let method = "PUT";
    let url = `${API_URL}/api/ingresos-mark/${item.id}`;


    fetch(url, {
        method,
        headers: getHeaders(),
        body: JSON.stringify({ selectedColor })
    }).then(resp => resp.json()).then(resp => {

        if (resp.status === false) {

            dispatch({
                type: CREATE_EXPENSES_ERROR,
                payload: resp.errors
            })
        } else {
            dispatch({
                type: MARK_ROW_SUCCESS,
            });
        }


    })
}