import React, { useEffect, useState } from 'react'
import { formatNumber } from '../../utils';


const ItemDetailPurchase = ({ item, onDelete, listProduct, isEdit, onSaveRow, isLock }) => {

    let { product } = item

    let { measure_unit } = product

    const [form, setForm] = useState({ amount: '', cost: '', total: '' })
    const [show, setShow] = useState(false)

    useEffect(() => {
        setForm({
            ...form,
            cost: item.cost
        });

    }, [item])

    const onEdit = (row) => {
        setShow(true);
        setForm({
            ...form,
            amount: row.amount,
            cost: row.cost,
            total: row.total,
        })
    }
    const updateInput = (e) => {
        console.log('e.target.name', e.target.name);

        if (e.target.name === 'amount') {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
                cost: form.total / e.target.value
            })
        }

        if (e.target.name === 'total') {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
                cost: e.target.value / form.amount
            })
        }
    }

    const onSave = () => {
        setShow(false);

        item.amount = form.amount
        item.cost = form.cost
        item.total = form.cost * form.amount

        onSaveRow(item)
    }

    return (
        <tr>
            <td>
                {item.product.name} - ({measure_unit.description})
            </td>
            <td></td>
            {/* {!isEdit && <td align='right'>{formatNumber(item.cost_average || 0)}</td>} */}
            <td align='right'>
                {!show ? item.amount : <input className='form-control' value={form.amount} name="amount" onChange={updateInput} />}
            </td>
            <td align='right'> {formatNumber(form.cost)}</td>
            <td align='right'>{product.tax.description}</td>
            <td align='right'>

                {!show ? formatNumber(item.total) : <input className='form-control' value={form.total} name="total" onChange={updateInput} />}
            </td>
            <td width={"18%"}>
                {!show ? <>
                    {!isLock && <>
                        <button className='btn btn-info btn-sm' onClick={() => onEdit(item)}>Editar</button>
                        <button className='btn btn-danger btn-sm mx-3' onClick={() => onDelete(item)}>Borrar</button>
                    </>}

                </> :
                    <>
                        <button className='btn btn-success btn-sm' onClick={onSave}>Guardar</button>
                        <button className='btn btn-danger btn-info btn-sm mx-2' onClick={() => setShow(false)}>Cancelar</button>
                    </>}

            </td>
        </tr>
    )
}

export default ItemDetailPurchase;