import React, { useEffect } from 'react';
import { Modal, Container, Row, Col, Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { exchangeDining } from '../../Redux/reservationDuck'
import BtnOrangeSm from '../Components/Widgets/BtnOrangeSm'
import BtnBlueModal from '../Components/Widgets/BtnBlueModal'


const ChangeDiningModal = ({ show, first, second, onHide, exchangeDining, fetching_exchange, onSuccess }) => {

    useEffect(() => {

        if (fetching_exchange === 2) {
            onSuccess()
        }

    }, [fetching_exchange])

    const changeDiningTable = () => {
        exchangeDining(first, second)
    }

    return (
        <Modal show={show}>
            <Modal.Header className=" border-bottom-custom py-1 m-0">
                <h5 className="align-middle text-gris font-15 pt-3">Intercambio de Mesa</h5>
                <button type="button" className="close p-0 m-0" data-dismiss="modal" aria-label="Close" onClick={onHide}>
                    {/* <img src={close} className="size-icon-35 icon-close" /> */}
                </button>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col>Reserva {first.reservation_id}</Col>
                                    </Row>
                                    <Row>
                                        <Col>Mesa {first.position}</Col>
                                    </Row>
                                    <Row>
                                        <Col>Horario {first.content}</Col>
                                    </Row>
                                </Card.Body>

                            </Card>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg={3}>
                            {/* <img src={rotate} className="px-4 img-fluid arrow_rotate mt-4" /> */}
                        </Col>
                    </Row>
                    <Row className="pt-3">
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col>Reserva {second.reservation_id}</Col>
                                    </Row>
                                    <Row>
                                        <Col>Mesa {second.position}</Col>
                                    </Row>
                                    <Row>
                                        <Col>Horario {second.content}</Col>
                                    </Row>
                                </Card.Body>

                            </Card>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col>
                            <BtnOrangeSm
                                title='InterCambiar'
                                onClick={changeDiningTable}
                            />
                        </Col>
                        <Col>
                            <BtnBlueModal
                                title='Cerrar'
                                onClick={onHide}
                            />
                        </Col>
                    </Row>
                </Container>

            </Modal.Body>
        </Modal>

    )
}

const mapsState = (props) => {
    return {
        fetching_exchange: props.reservation.fetching_exchange
    }
}

export default connect(mapsState, { exchangeDining })(ChangeDiningModal);