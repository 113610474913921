import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Container, Row, Col, } from 'react-bootstrap';

import { getDiningTables, clearSelected, getDiningId } from '../../Redux/diningDuck'
import { toogleAlert } from '../../Redux/alertDuck'
import { getBranch } from '../../Redux/branchofficeDuck'
import ModalDining from './ModalDining';
import SearchSection from '../Components/SearchSection';
import CardDinig from './CardDining';
import BtnWhiteHeader from '../Components/BtnWhiteHeader';
import TemplateMenu from '../../template/menu.template'


const DiningTable = ({ data, clearSelected, getDiningTables, getBranch, branch, getDiningId, fetching, detail, toogleAlert, fetching_list }) => {

    const [content, setContent] = useState([]);

    useEffect(() => {
        getDiningTables()
        getBranch()
    }, [])
    useEffect(() => {
        toogleAlert(true)
        setContent(data)
    }, [data])

    useEffect(() => {
        if (fetching_list === 2) {
            toogleAlert(false)
        }
    }, [fetching_list])

    const filterContent = (value) => {
        let _content = data.filter(row => row.position === parseInt(value) || row.branch_office.title.includes(value));
        setContent(_content)
    }

    const selectedFilted = (item) => {
        let _content = data.filter(row => row.branch_id === item.id);
        setContent(_content)
    }

    const onCleanFilter = () => {
        setContent(data)
    }

    const showModalTmp = () => {
        
    }

    return <TemplateMenu>
        <Container fluid>
            <Row className="mt-4">
                <Col className=" justify-content-end d-flex align-items-end ">
                    <SearchSection
                        placeholder='Buscar Mesa'
                        value=''
                        name='buscar_usuario'
                        onClick={filterContent}
                        onClean={onCleanFilter}
                        selectedFilted={selectedFilted}
                        list={branch}
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col className="d-flex justify-content-center align-items-center">
                    <span className=" h4 font-title pt-2">MESAS</span>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Row>
                        <Col>
                            {(content !== undefined) ? content.map((row, i) => <CardDinig key={i} item={row} />) :
                                <Row><Col>No se encontraron Registros</Col></Row>}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>

    </TemplateMenu>
}

const mapsState = (props) => {

    return {
        data: props.dining.data,
        fetching: props.dining.fetching_detail,
        fetching_list: props.dining.fetching_list,
        detail: props.dining.detail,
        branch: props.branchoffice.data
    }
}

export default connect(mapsState, { clearSelected, getDiningTables, getBranch, getDiningId, toogleAlert })(DiningTable);
