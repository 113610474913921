import React, { useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { MdClose } from "react-icons/md";

import BtnBlueModal from '../Components/Widgets/BtnBlueModal';


const ModalCancel = ({ item, show, onHide, onSubmit }) => {

  const [comment, setComment] = useState('')

  const onSubmitTmp = () => {
    onSubmit(item, comment)
  }


  return (
    <Modal show={show}>
      <Modal.Header className=" border-bottom-custom py-1 m-0">
        <h5 className="align-middle text-gris font-15 pt-3">Agrega el Motivo</h5>
        <button type="button" className="close p-0 m-0" data-dismiss="modal" aria-label="Close" onClick={onHide}>
          <MdClose />
        </button>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <textarea className="form-control" placeholder="Razón" value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
          </Col>
        </Row>

        <Row className="my-2">
          <Col lg={12} md={12}>
            <BtnBlueModal title='Agregar' onClick={onSubmitTmp} />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}
export default ModalCancel;