import React, { Fragment } from 'react'

const InputFieldCheck = ({ type, name, checked, placeholder, onChange, error }) => {
    type = type || 'text'
    return (
        <Fragment>
            <input
                type="checkbox"
                className="form-control checkpato"
                name={name}
                checked={checked}
                placeholder={placeholder}
                onChange={onChange} />
            {error !== undefined && (<label className="error-label">{error}</label>)}
        </Fragment>
    )
}

export default InputFieldCheck