import React, { useEffect, useState } from 'react'

const ItemConfiguration = ({ row }) => {

    const [value, setValue] = useState(0)
    const [status, setStatus] = useState(false)
    const [typeValue, setTypeValue] = useState(0)
    const [show, setShow] = useState(false)

    const updateCheck = (e) => {
        if (e.target.type === "checkbox") {
            setStatus(e.target.checked)
        } else {
            setValue(e.target.value)
        }
    }


    const onEdit = () => {
        setShow(true)
        console.log('row.status', row);


        setValue(row.value || 0)
        setStatus(row.status)
        setTypeValue(row.type_value)
    }

    const onSucess = () => {
        setShow(false)
        row.value = value
        row.status = status
        row.type_value = typeValue || 1
    }

    return <tr>
        <td>{row.description}</td>
        <td>
            {show === true ? <input type={"number"} className='form-control' name='value' placeholder={`${row.description}`}
                value={value} onChange={updateCheck} /> : row.value}

        </td>
        <td>
            {show === true ?
                <select className='form-control' onChange={(e) => setTypeValue(e.target.value)} value={typeValue}>
                    <option value={1}>Número</option>
                    <option value={2}>Porcentaje</option>
                </select>
                : (row.type_value === 1 ? 'Número' : 'Porcentaje')}
        </td>
        <td>
            {show === true ? <input type={"checkbox"} className='form-control'
                onChange={updateCheck} checked={status} /> : (row.status === true ? 'Activo' : 'Inactivo')}
        </td>
        <td>
            <button className={`btn btn-sm btn-${show === true ? 'success' : 'info'}`} onClick={show === true ? onSucess : onEdit}>{show === true ? 'Guardar' : 'Editar'}</button>
        </td>
    </tr>
}

export default ItemConfiguration