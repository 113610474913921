import React, { useEffect, useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { connect } from 'react-redux'
import { Card, Col, Row } from 'react-bootstrap'
import chooseimage from "./../../Images/no_available.png";

const ProfilePage = ({ user, fetching_info }) => {

    const [data, setData] = useState({ business: {} })
    const [form, setForm] = useState({ image: '' })
    const [image, setImage] = useState('')

    useEffect(() => {
        if (fetching_info === 2) {
            setData(user)
        }
    }, [fetching_info])

    const inputFile = (e) => {
        setImage(e.target.files[0]);

        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setForm({ ...form, image: reader.result });
            }
        };
        reader.readAsDataURL(file);
    }

    return (
        <TemplateMenu>
            <Row>
                <Col lg={5}>
                    <Card>
                        <Card.Header>
                            <Row className='d-flex justify-content-end'>
                                <Col lg={3}>
                                    <button className='btn btn-sm btn-success'>Guardar</button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>Nombre</Col>
                                <Col className='text-right'>{data.name}</Col>
                            </Row>
                            <Row className='my-1'>
                                <Col>Empresa</Col>
                                <Col className='text-right'>{data.business.business_name}</Col>
                            </Row>
                            <Row className='my-1'>
                                <Col className="justify-content-center d-flex">
                                    <div className="form-group">
                                        <label htmlFor="chooseimage" className="uploadfile">
                                            {form.image !== "" ? (
                                                <img src={form.image} className="img-fluid" />
                                            ) : (
                                                <img src={chooseimage} className="img-fluid" />
                                            )}
                                        </label>
                                        <input
                                            type="file"
                                            name="photo"
                                            className="form-control"
                                            onChange={inputFile}
                                            id="chooseimage"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { user } = props

    return {
        user: user.data,
        fetching_info: user.fetching_info
    }
}

export default connect(mapsState, {})(ProfilePage);