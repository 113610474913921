import { getHeaders } from '../utils';

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    fetching_create: 0,
    fetching_by_id: 0,
    fetching_delete: 0,
    data: [],
    errors: {},
    row: {}
}

let LIST_TRANSFORMATION = "LIST_TRANSFORMATION";
let TRANSFORMATION_BY_ID = "TRANSFORMATION_BY_ID";
let TRANSFORMATION_BY_ID_SUCCESS = "TRANSFORMATION_BY_ID_SUCCESS";
let LIST_TRANSFORMATION_SUCCESS = "LIST_TRANSFORMATION_SUCCESS";
let CREATE_TRANSFORMATION = "CREATE_TRANSFORMATION";
let CREATE_TRANSFORMATION_SUCCESS = "CREATE_TRANSFORMATION_SUCCESS";
let EDIT_TRANSFORMATION = "EDIT_TRANSFORMATION";
let EDIT_TRANSFORMATION_SUCCESS = "EDIT_TRANSFORMATION_SUCCESS";
let DELETE_TRANSFORMATION = "DELETE_TRANSFORMATION";
let DELETE_TRANSFORMATION_SUCCESS = "DELETE_TRANSFORMATION_SUCCESS";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_TRANSFORMATION:
            return { ...state, fetching_list: 1, }
        case LIST_TRANSFORMATION_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload, fetching_create: 0, row: {} }
        case CREATE_TRANSFORMATION:
            return { ...state, fetching_create: 1 }
        case CREATE_TRANSFORMATION_SUCCESS:
            return { ...state, fetching_create: 2 }
        case TRANSFORMATION_BY_ID:
            return { ...state, fetching_by_id: 1 }
        case TRANSFORMATION_BY_ID_SUCCESS:
            return { ...state, fetching_by_id: 2, row: action.payload }
        case EDIT_TRANSFORMATION:
            return { ...state, fetching_create: 1 }
        case EDIT_TRANSFORMATION_SUCCESS:
            return { ...state, fetching_create: 2 }
        case DELETE_TRANSFORMATION:
            return { ...state, fetching_delete: 1 }
        case DELETE_TRANSFORMATION_SUCCESS:
            return { ...state, fetching_delete: 2 }
        default:
            return state;
    }
}

export let getListTransformation = () => (dispatch, getState) => {
    dispatch({
        type: LIST_TRANSFORMATION,
    });

    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))

    fetch(`${API_URL}/api/transformation?date_init=${date_init}&date_end=${date_end}`, {
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: LIST_TRANSFORMATION_SUCCESS,
            payload: resp.results
        })
    })
}
export let getListTransformationById = (id) => (dispatch, getState) => {
    dispatch({
        type: TRANSFORMATION_BY_ID,
    });

    fetch(`${API_URL}/api/transformation/${id}`, {
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: TRANSFORMATION_BY_ID_SUCCESS,
            payload: resp
        })
    })
}

export let getListTransformationId = (id) => (dispatch, getState) => {
    dispatch({
        type: TRANSFORMATION_BY_ID,
    });

    fetch(`${API_URL}/api/transformation/${id}`, {
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: TRANSFORMATION_BY_ID_SUCCESS,
            payload: resp
        })
    })
}

export let onCreateTransformation = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_TRANSFORMATION,
    });

    let branch = JSON.parse(localStorage.branch)
    let { date_init, date_end } = JSON.parse(localStorage.date)

    form.branch_id = branch.id
    form.date_init = date_init
    form.date_end = date_end

    fetch(`${API_URL}/api/transformation`, {
        method: 'POST',
        body: JSON.stringify(form),
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: CREATE_TRANSFORMATION_SUCCESS,
        })
    })
}

export let onEditTransformation = (form) => (dispatch, getState) => {
    dispatch({
        type: EDIT_TRANSFORMATION,
    });

    let branch = JSON.parse(localStorage.branch)

    form.branch_id = branch.id

    fetch(`${API_URL}/api/transformation/${form.id}`, {
        method: 'PUT',
        body: JSON.stringify(form),
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: EDIT_TRANSFORMATION_SUCCESS,
        })
    })
}

export let onDeleteTransformation = (data) => (dispatch, getState) => {
    dispatch({
        type: DELETE_TRANSFORMATION,
    });

    let branch = JSON.parse(localStorage.branch)
    let { date_init, date_end } = JSON.parse(localStorage.date)


    fetch(`${API_URL}/api/transformation/${data.id}/${branch.id}?date_init=${date_init}&date_end=${date_end}`, {
        method: 'DELETE',
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: DELETE_TRANSFORMATION_SUCCESS,
        })
    })
}