import React from 'react'
import { formatNumber } from '../../utils';

const ItemExpenses = ({ row, onDeleteItem }) => {
    let { supplier, payment } = row
    let color = row.id!==undefined?'success':'danger';
    return (
        <tr>
            <td>{supplier.business}</td>
            <td>{payment.description}</td>
            <td align='rigth'>{formatNumber(row.amount)}</td>
            <td><button className={`btn btn-${color} btn-sm`} onClick={() => onDeleteItem(row)}>X</button></td>
        </tr>
    )
}

export default ItemExpenses;