import moment from 'moment';
import React from 'react'
import { Link } from 'react-router-dom';
import { formatNumber } from '../../utils';

const ItemTableTransform = ({ item, onDelete }) => {
    let { product } = item;
    let { measure_unit } = product

    return (
        <tr>
            <td>{item.id}</td>
            <td>{moment(item.registration_date).format("YYYY-MM-DD hh:mm A")}</td>
            <td>{item.product}</td>
            {/* <td>{item.product.name} ({measure_unit.description})</td> */}
            <td align='right'>{item.amount}</td>
            <td align='right'>{item.surplus}</td>
            {/* <td align='right'>{item.amount_total}</td> */}
            <td align='right'>{formatNumber(item.cost)}</td>
            <td align='right'>{formatNumber(item.cost_total)}</td>
            <td>
                <Link to={`/transformaciones/${item.id}`} className="btn btn-info btn-sm" >Ver</Link>
                <button className='btn btn-danger btn-sm mx-1' onClick={() => onDelete(item)}>Eliminar</button>
            </td>
        </tr>
    )
}

export default ItemTableTransform;