import React, { Fragment } from 'react'

const SelectField = ({ name, value, data, onChange, error }) => {
    
    return (
        <Fragment>
            <select className="form-control" name={name} value={value} onChange={onChange}>
                <option value="0">Seleccione</option>
                {data.map((row, i) => <option key={i} value={row.id}>{row.title}</option>)}
            </select>
            {error !== undefined && (<label className="error-label">{error}</label>)}
        </Fragment>
    )
}

export default SelectField