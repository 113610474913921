import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { AiFillFilter } from 'react-icons/ai';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getExpenses, onCreateorUpdateExpenses } from '../../Redux/expensesDuck'
import ItemTableExpense from './item.table.expense';
import ModalExpenses from './modal.expenses';
import { getBranch } from '../../Redux/branchofficeDuck'
import { getSuppliers } from '../../Redux/supplierDuck'
import { getParameterBySegment } from '../../Redux/parameterDuck'
import TemplateMenu from '../../template/menu.template'


const ExpensesPage = ({ getExpenses, expenses, fetching_list, fetching_by_segment, data_segment, getParameterBySegment, getBranch, getSuppliers, fetching_branch,
    branch_offices, fetching_list_supplier, suppliers, onCreateorUpdateExpenses, fetching_create }) => {
    const [filter, setFilter] = useState({ date_init: moment().format("YYYY-MM-01"), date_end: moment().format("YYYY-MM-DD") })
    const [form, setForm] = useState();
    const [showModal, setShowModal] = useState(false);
    const [modalFilter, setModalFilter] = useState(false);
    const [listExpense, setListExpense] = useState([]);
    const [listTypePayments, setListTypePayments] = useState([])
    const [listSupplier, setListSupplier] = useState([])
    const [listBranch, setListBranch] = useState([]);

    useEffect(() => {
        getExpenses()
        getBranch()
        getSuppliers()
        getParameterBySegment("methods_payment")
    }, [])

    useEffect(() => {
        if (fetching_create === 2) {
            window.location.reload()
        }
    }, [fetching_create])

    useEffect(() => {
        if (fetching_list === 2) {
            setListExpense(expenses)
        }
    }, [fetching_list])

    useEffect(() => {
        if (fetching_branch === 2) {
            setListBranch(branch_offices)
        }
    }, [fetching_branch])

    useEffect(() => {
        if (fetching_by_segment === 2) {
            setListTypePayments(data_segment)
        }
    }, [fetching_by_segment])

    useEffect(() => {
        if (fetching_list_supplier === 2) {
            setListSupplier(suppliers)
        }

    }, [fetching_list_supplier])

    const updateInput = (e) => {

    }

    const onFilter = (e) => {

    }
    const onSubmit = (form) => {
        onCreateorUpdateExpenses(form)
    }

    return (
        <TemplateMenu>
        <Container fluid>
            <Row className="my-4">
                <Col lg={3} md={4}>

                    <h4 className="font-title pt-3">REGISTRO DE GASTOS</h4>
                </Col>
                <Col lg={2} md={4} className="d-flex align-items-center">
                    {/* <Link className="btn btn-info" to="/gastos/new" >Crear</Link> */}
                    <button className='btn btn-info' onClick={() => setShowModal(true)}>Crear</button>
                </Col>
                <Col lg={1} md={2} className="d-flex align-items-center">
                    <button className='btn btn-secondary' onClick={() => setModalFilter(true)}>
                        <AiFillFilter />
                    </button>
                </Col>
                <Col lg={5} md={4} className="d-flex align-items-center">
                    <Row>
                        <Col><input className='form-control input-sm' name='date_init' type={"date"} onChange={updateInput} value={filter.date_init} /></Col>
                        <Col><input className='form-control input-sm' name='date_end' type={"date"} onChange={updateInput} value={filter.date_end} /></Col>
                        <Col><button className='btn btn-warning' onClick={onFilter}>Filtrar</button></Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <table className="table table-sm table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                <th>Id</th>
                                <th>Punto</th>
                                <th>Método de Pago</th>
                                <th>Valor</th>
                                <th>Fecha</th>
                                <th>Estado</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(listExpense.length > 0) ? listExpense.map(row =>
                                <ItemTableExpense item={row} key={row.id} />) : <tr><td colSpan={6} className="text-center">No Tienes Registros</td></tr>}
                        </tbody>
                        <tfoot>
                            <tr className="table-secondary">

                            </tr>
                        </tfoot>
                    </table>
                </Col>
            </Row >

            <ModalExpenses show={showModal} typePayments={listTypePayments} suppliers={listSupplier} listBranch={listBranch} onSubmit={onSubmit} />
        </Container>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    return {
        fetching_list: props.expense.fetching_list,
        expenses: props.expense.data,
        fetching_by_segment: props.parameter.fetching_by_segment,
        data_segment: props.parameter.data_segment,
        suppliers: props.supplier.data,
        fetching_branch: props.branchoffice.fetching,
        fetching_list_supplier: props.supplier.fetching_list,
        branch_offices: props.branchoffice.data,
        fetching_create: props.expense.fetching_create,


    }
}

export default connect(mapsState, { getExpenses, getParameterBySegment, getBranch, getSuppliers, onCreateorUpdateExpenses })(ExpensesPage);