import React, { useEffect, useRef, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import TemplateMenu from '../../template/menu.template'
import { getWorkerById, createWorkerDocument, getWorkerDocument } from '../../Redux/workerDuck'
import { MdClose, MdCloudUpload, MdSend, MdOutlineImage } from 'react-icons/md';
import './index.css'
import ModalImageDocument from './modal.image.document';


const initState = { description: '', document: '' }

const WorkerDocuments = ({ getWorkerById, createWorkerDocument, fetching_document, getWorkerDocument, fetching_docs, data_docs }) => {
    let { id } = useParams()
    const [options, setOptions] = useState(false)
    const [form, setForm] = useState(initState)
    const [image, setImage] = useState(null)
    const [modal, setModal] = useState(false)
    const [pathImage, setPathImage] = useState(null)
    const [list, setList] = useState([])
    const inputFile = useRef(null)

    useEffect(() => {
        getWorkerById(id)
        getWorkerDocument(id)
    }, [])

    useEffect(() => {
        if (fetching_docs === 2) {
            setList(data_docs)
        }

    }, [fetching_docs])

    useEffect(() => {
        if (fetching_document === 2) {
            setForm(initState)
            setImage(null)
            getWorkerDocument(id)
        }
    }, [fetching_document])

    const onShowOptions = () => {
        inputFile.current.click();
    }

    const onSend = () => {
        let _form = form
        _form.worker_id = id;

        createWorkerDocument(_form)
    }

    const onChangeImage = (e) => {
        const file = e.target.files[0]

        setForm({
            ...form,
            ["document"]: file
        });

        const objectURL = URL.createObjectURL(file);
        setImage(objectURL);
        // console.log('e.target.files', e.target.files[0]);

        // setImage(file)
    }

    const onShowImage = (path) => {
        setModal(true)
        setPathImage(path)
    }

    return (
        <TemplateMenu>
            <Row className='d-flex justify-content-center mt-2'>
                <Col className='text-center'><h4>Documentos de Trabajadores</h4></Col>
            </Row>
            <Row className='d-flex justify-content-center mt-3'>
                <Col lg={1} className="d-flex justify-content-end  align-items-center">
                    <MdCloudUpload className='cursor-pointer' onClick={onShowOptions} size={30} />
                </Col>
                <Col lg={4}>
                    <textarea className='form-control' value={form.description} onChange={(e) => setForm({ ...form, ["description"]: e.target.value })}></textarea>
                </Col>
                <Col lg={1} className="d-flex justify-content-start  align-items-center">
                    <MdSend className='cursor-pointer' onClick={onSend} size={30} />
                </Col>
            </Row>
            {image !== null && <Row className='d-flex justify-content-center'>
                <Col lg={2} className="text-center">
                    <div className='content-image'>
                        <div className='button-close-image'>
                            <MdClose size={30} />
                        </div>
                        <img src={image} className="img-fluid" />
                    </div>

                </Col>
            </Row>}

            <input className='' type={"file"} style={{ display: 'none' }} ref={inputFile} onChange={onChangeImage} />
            <Row className='d-flex justify-content-center mt-4'>
                <Col lg={8}>
                    {list.map((d, k) => <Row className='mt-1' key={k}>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col lg={2}>
                                            <div className='circle-user text-center'>{d.user.alias}</div>
                                        </Col>
                                        <Col lg={8} className="d-flex align-items-center">
                                            {d.description}
                                        </Col>
                                        <Col lg={2} className="text-muted d-flex align-items-center justify-content-end">
                                            {d.createdAt}
                                        </Col>
                                    </Row>
                                    {(d.extension === 'jpeg' || d.extension === 'jpg' || d.extension === 'png') && <Row className='mt-2'>
                                        <Col lg={1}>
                                            <img src={d.document} className='img-fluid cursor-pointer' onClick={() => onShowImage(d.document)} />
                                        </Col>
                                    </Row>}

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>)}


                </Col>
            </Row>
            <ModalImageDocument show={modal} path={pathImage} onHide={() => setModal(false)} />
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { worker } = props

    return {
        fetching_document: worker.fetching_document,
        fetching_docs: worker.fetching_docs,
        data_docs: worker.data_docs
    }
}
export default connect(mapsState, { getWorkerById, createWorkerDocument, getWorkerDocument })(WorkerDocuments);