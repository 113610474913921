import React, { useState } from 'react'
import { AiFillEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { formatNumber } from '../../utils';
import ItemDetailTable from './item.detail.table';
import ItemTableWorker from './item.table.worker';

const ItemTableTip=({item,tip})=>{
    const [showTable, setShowTable] = useState(false)

    let {parameter,workers} = item
    let total = (item.percent/100) * tip
    
    let subtotal = workers.reduce((a, b) => a = a + parseFloat(b.amount) - (parseFloat(b.amount) * (b.discount/100)), 0)

    return <>
    <tr>
        <td>
        {showTable
                        ? <AiOutlineEyeInvisible onClick={() => setShowTable(false)} className="cursor-pointer" />
                        : <AiFillEye onClick={() => setShowTable(true)} className="cursor-pointer" />
                    }
        </td>
        <td>{parameter.description}</td>
        <td>{item.percent}%</td>
        <td>{workers.length}</td>
        <td>{formatNumber(total)}</td>
    </tr>
    {showTable && <tr>
        <td colSpan={9}>
            <table className='table table-bordered table-sm'>
                <thead className='thead-primary'>
                    <tr>
                        <th>Nombre</th>
                        <th>Cargo</th>
                        <th>Tipo</th>
                        <th>Propina a pagar</th>
                        <th>Descuento</th>
                        <th>Total</th>
                    </tr>

                </thead>
                <tbody>
                    {workers.map(w => <ItemDetailTable item={w} key={w.id}
                    // onChangeStatus={onChangeStatus} 
                    // onChangeDiscount={onChangeDiscount} 
                    />)}
                    
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={5}>TOTAL</td>
                        <td>{formatNumber(subtotal)}</td>
                    </tr>
                </tfoot>
            </table>
        </td>
    </tr>}
    </>
}

export default ItemTableTip