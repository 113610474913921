import React, { useEffect, useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { Card, Col, ListGroup, Row, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getCategoryRecipeById, createOrUpdateRecipeBooks } from '../../Redux/recipe.book.Duck'
import { connect } from 'react-redux';
import not_available from '../../Images/no_available.png'
import ItemPrescriptionDetail from './item.prescription.detail';
import uuid from 'react-uuid';
const API_URL = process.env.REACT_APP_API_URL


const EditCategoryRecipePage = ({ getCategoryRecipeById, fetching_by_id, row, createOrUpdateRecipeBooks }) => {

    let { id } = useParams()
    const [data, setData] = useState({ title: '', description: '', products: [] })
    const [listPreparations, setListPreparations] = useState([])
    const [item, setItem] = useState({ id: 0, description: '' })
    const [form, setForm] = useState({
        image: null, image_view: null,
        video_preparation: null, video_preparation_view: null,
        image_on_table: null, image_on_table_view: null,
        image_to_take: null, image_to_take_view: null,
        products: []
    });

    useEffect(() => {
        getCategoryRecipeById(id)
    }, [id])
    useEffect(() => {
        if (fetching_by_id === 2) {
            let _row = row

            row.image_view = `${API_URL}/${row.image}`
            row.image_on_table_view = `${API_URL}/${row.image_on_table}`
            row.image_to_take_view = `${API_URL}/${row.image_to_take}`

            if (row.video_preparation !== null) {
                row.video_preparation_view = `${API_URL}/${row.video_preparation}`
            }
            setForm(row)
            setListPreparations(row.list_preparation)
        }
    }, [fetching_by_id])


    const inputFile = (e) => {
        if (e.target.files[0] !== undefined) {

            if (e.target.name === 'video_preparation') {
                if (e.target.files[0].type !== 'video/mp4') {
                    alert("Solo se puede Videos en MP3")
                    return;
                }
            }

            setForm({
                ...form,
                [e.target.name]: e.target.files[0],
                [`${e.target.name}_view`]: URL.createObjectURL(e.target.files[0])
            })
        }

        // let file = e.target.files[0];
        // let reader = new FileReader();
        // reader.onload = () => {
        //     if (reader.readyState === 2) {

        //         setForm({ ...form, [e.target.name]: reader.result })
        //     }
        // }
        // reader.readAsDataURL(file)
    }

    const onEdit = (row) => {
        setItem(row)
    }

    const onDeleteDetail = (row) => {
        let _list = []
        if (row.id !== undefined) {
            _list = listPreparations.map(d => {
                if (d.id === row.id) {
                    d.delete = true;
                }
                return d
            })
        } else {
            _list = listPreparations.filter(l => l._id !== row._id)
        }

        setListPreparations(_list)

    }

    const onUpdateInput = (e) => {
        let _form = {}
        _form._id = uuid()
        _form.description = item.description

        if (e.key === 'Enter') {

            if (item.id === 0) {
                setListPreparations([
                    ...listPreparations,
                    _form
                ]);
            } else {
                let _list = listPreparations.map(d => {
                    if (d._id === item._id) {
                        d.description = item.description
                    }

                    return d;
                })

                setListPreparations(_list)
            }

            setItem({ id: 0, description: '' })

        }

    }

    const onSave = () => {
        let _form = form
        _form.list_preparation = listPreparations;

        createOrUpdateRecipeBooks(form)
    }

    return (
        <TemplateMenu>
            <Row className='my-3'>
                <Col>
                    <button className='btn btn-sm btn-info' onClick={onSave}>Guardar contenido Receta</button>
                </Col>
            </Row>
            <Row>
                <Col lg={4}>
                    <Card>
                        <Card.Header className='text-center'>
                            {form.title}
                        </Card.Header>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label htmlFor="chooseimage" className="uploadfile">
                                        Imagen Principal
                                        <img src={form.image_view} className="img-fluid" />
                                    </label>
                                    <input type="file" name="image" className="form-control" onChange={inputFile} id="chooseimage" />
                                </div>
                            </Col>
                        </Row>

                        <Card.Body className='px-0'>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label htmlFor="chooseimage2" className="uploadfile">
                                            Video Preparación
                                            {form.video_preparation !== null ? (<video width={"400"} controls>
                                                <source src={form.video_preparation_view} />
                                            </video>)
                                                : <img src={not_available} className="img-fluid" />}
                                        </label>
                                        <input type="file" name="video_preparation"
                                            className="form-control"
                                            onChange={inputFile} id="chooseimage2" />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='px-2'>
                                <Col>Descripción</Col>
                                <Col>{data.description}</Col>
                            </Row>
                            <Row>
                                <Col>{data.price}</Col>
                            </Row>
                            <Row className=' px-3 pt-2'>
                                <Col className='border-top text-center'><h4>Preparación</h4></Col>
                            </Row>
                            <Row className='px-3'>
                                <Col >
                                    <div className='form-group'>
                                        <label>{`${item.id === 0 ? 'Agrega' : 'Edita'}`} los Ingredientes</label>
                                        <input className='form-control' value={item.description}
                                            name="description"
                                            placeholder='Describe los Preparación'
                                            onChange={(e) => setItem({ ...item, ["description"]: e.target.value })}
                                            onKeyDown={(e) => onUpdateInput(e, 2)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='px-3'>
                                <Col>
                                    <ListGroup>
                                        {listPreparations.filter(d => d.delete !== true).map((ing, k) => <ItemPrescriptionDetail index={k + 1} item={ing} key={k}
                                            onEdit={onEdit}
                                            onDelete={onDeleteDetail} />)}
                                    </ListGroup>

                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card>
                        <Card.Header>
                            Imagen Para la Mesa
                        </Card.Header>
                        <div className="form-group">
                            <label htmlFor="chooseimage3" className="uploadfile">
                                {(form.image_on_table !== null) ? (<img src={form.image_on_table_view} className="img-fluid" />)
                                    : <img src={not_available} className="img-fluid" />}
                            </label>
                            <input type="file" name="image_on_table" className="form-control" onChange={inputFile} id="chooseimage3" />
                        </div>
                        <Card.Body>
                            <Card.Title className='text-center'>Para la Mesa</Card.Title>
                            <Table className='table table-sm table-hover table-bordered' >
                                <thead className=' thead-dark'>
                                    <tr>
                                        <th>Producto</th>
                                        <th className='text-center'>Unidad</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {form.products.filter(d => d.type_product_id === 1).map((d, k) => <tr key={k}>
                                        <td>{d.product}</td>
                                        <td align='right'>{d.amount}</td>
                                    </tr>)}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card>
                        <Card.Header>
                            Imagen Para la Llevar
                        </Card.Header>
                        <div className="form-group">
                            <label htmlFor="chooseimage4" className="uploadfile">
                                {(form.image_to_take !== null) ? (<img src={form.image_to_take_view} className="img-fluid" />)
                                    : <img src={not_available} className="img-fluid" />}
                            </label>
                            <input type="file" name="image_to_take" className="form-control" onChange={inputFile} id="chooseimage4" />
                        </div>
                        <Card.Body>
                            <Card.Title className='text-center'>Para llevar</Card.Title>
                            <Table className='table table-sm table-hover table-bordered' >
                                <thead className=' thead-dark'>
                                    <tr>
                                        <th>Producto</th>
                                        <th className='text-center'>Unidad</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {form.products.map((d, k) => <tr key={k}>
                                        <td>{d.product}</td>
                                        <td align='right'>{d.amount}</td>
                                    </tr>)}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { recipe_book } = props
    return {
        fetching_by_id: recipe_book.fetching_by_id,
        row: recipe_book.row,
    }
}

export default connect(mapsState, { getCategoryRecipeById, createOrUpdateRecipeBooks })(EditCategoryRecipePage);