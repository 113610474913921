import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Alert, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { formatNumber, getHeaders } from '../../utils';
import InputField from '../Components/Widgets/InputField';
import AsyncSelect from 'react-select/async';
import RowProductTransformation from './row.product.transformation';
import ItemTransformation from './item.transformation';
import { getLastInventory } from '../../Redux/inventoryDuck';
import { connect } from 'react-redux';
const API_URL = process.env.REACT_APP_API_URL


const initStateSelected = { measure_unit: { description: '' }, cost: 0 }

const FormTransformation = ({ title, onSubmit, errors, formEdit, getLastInventory, fetching_by_id, row }) => {

    const [form, setForm] = useState({ product_id: 0, registration_date: moment().format("YYYY-MM-DD"), amount: 0, date_init: '', date_end: '' })
    const [listTransformation, setListTransformation] = useState([])
    const [amountGenerate, setAmountGenerate] = useState(0)
    const [amountUsed, setAmountUsed] = useState(0)
    const [total, setTotal] = useState(0)
    const [productTransform, setProductTransform] = useState('')
    const [inventory, setInventory] = useState({ amount_total: 0 })
    const [listSearch, setListSearch] = useState([])
    const [selected, setSelected] = useState(initStateSelected)
    const [show, setShow] = useState(false)
    const [surplus, setSurplus] = useState(0)
    const [totalTransform, setTotalTransform] = useState(0)
    const [totalTransformHistorico, setTotalTransformHistorico] = useState(0)
    const [formEditRow, setFormEditRow] = useState({})
    const [errorQuantity, setErrorQuantity] = useState(false)

    const [isEdit, setIsEdit] = useState(false)

    useEffect(() => {

        if (Object.keys(formEdit).length > 0) {
            setIsEdit(true)

            let { product, transformation_product, cost } = formEdit
            let { measure_unit, supplier, } = product

            formEdit.registration_date = moment(formEdit.registration_date).format("YYYY-MM-DD")
            setForm(formEdit)

            let _used = transformation_product.reduce((a, b) => a = a + parseFloat(parseFloat(b.amount_used) || 0), 0)
            let _generate = transformation_product.reduce((a, b) => a = a + parseFloat(parseFloat(b.amount_generate) || 0), 0)

            setAmountUsed(_used)
            setAmountGenerate(_generate)
            setTotalTransformHistorico((formEdit.amount * cost).toFixed(3))
            setSurplus(parseFloat(formEdit.amount) - _used)


            setProductTransform({ label: product.name, value: product.id, supplier: supplier.business, measure_unit: measure_unit.description, cost_average: cost })

            setListTransformation(transformation_product)
            getLastInventory(formEdit.product_id, formEdit.registration_date)

        } else {
            let { date_init, date_end } = JSON.parse(localStorage.date)
            setForm({
                ...form,
                date_init: date_init,
                date_end: date_end,
            })
            setIsEdit(false)
        }
    }, [formEdit])


    useEffect(() => {
        if (fetching_by_id === 2) {
            setInventory(row)
            setTotalTransform(row.cost_average * form.amount)
        }
    }, [fetching_by_id])

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        if (e.target.name === 'amount') {
            setSurplus(e.target.value - amountUsed)
            setTotalTransform(e.target.value * inventory.cost_average)
        }

        // if (e.target.name === 'registration_date' && productTransform !== '') {
        //     getLastInventory(productTransform.id, e.target.value)
        // }
    }

    const validateInventory = (e) => {
        if (parseFloat(inventory.amount_total) < parseFloat(e.target.value)) {
            setForm({
                ...form,
                ["amount"]: ''
            })

            setTotalTransform('0')
            setErrorQuantity(true)
            return;
        }
        setErrorQuantity(false)
    }

    const loadOptions = async (inputValue) => {
        let branch = JSON.parse(localStorage.getItem("branch"));
        let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));
        let url = `${API_URL}/api/product-inventory-search/${inputValue}?date_init=${date_init}&date_end=${date_end}&branch=${branch.id}`

        let _search = await fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json())

        setListSearch(_search)

        return _search;
    }

    const onAddItems = (item) => {

        let res = [
            ...listTransformation,
            item
        ]

        setListTransformation(res)


        let _generate = res.reduce((a, b) => a = a + parseFloat(parseFloat(b.amount_generate) || 0), 0)
        let _used = res.reduce((a, b) => a = a + parseFloat(parseFloat(b.amount_used) || 0), 0)

        setAmountGenerate(_generate)
        setAmountUsed(_used)

        setSurplus(form.amount - _used);
        setShow(false)
    }

    const addNewRow = () => {
        setShow(true)
    }

    const onEditRow = (row) => {

        let _list = listTransformation.map(r => {
            if (r._id === row._id) {
                r = row;
            }

            return r;
        })



        let _generate = _list.reduce((a, b) => a = a + parseFloat(parseFloat(b.amount_generate) || 0), 0)
        let _used = _list.reduce((a, b) => a = a + parseFloat(parseFloat(b.amount_used) || 0), 0)

        setAmountGenerate(_generate.toFixed(3))
        setAmountUsed(_used.toFixed(3))

        setSurplus((form.amount - _used).toFixed(3));

        setListTransformation(_list)
        setShow(false)
        setFormEditRow({})
    }

    const onDelete = (item) => {
        if (item.id !== undefined) {
            let _list = listTransformation.map(d => {
                if (d.id === item.id) {
                    d.delete = true
                }

                return d;
            })

            setListTransformation(_list);


            let _generate = _list.filter(d => d.delete !== true).reduce((a, b) => a = a + parseFloat(parseFloat(b.amount_generate) || 0), 0)
            let _used = _list.filter(d => d.delete !== true).reduce((a, b) => a = a + parseFloat(parseFloat(b.amount_used) || 0), 0)
            setAmountGenerate(_generate)
            setAmountUsed(_used)

            setSurplus(form.amount - _used);

            setListTransformation(_list)
            setShow(false)
            setFormEditRow({})
        } else {
            let _list = listTransformation.filter(d => d._id !== item._id)

            let _generate = _list.reduce((a, b) => a = a + parseFloat(parseFloat(b.amount_generate) || 0), 0)
            let _used = _list.reduce((a, b) => a = a + parseFloat(parseFloat(b.amount_used) || 0), 0)
            setAmountGenerate(_generate)
            setAmountUsed(_used)
            setSurplus(form.amount - _used);
            setListTransformation(_list)
            setShow(false)
            setFormEditRow({})
        }
    }

    const onEdit = (row) => {
        setShow(true)
        setFormEditRow(row)
    }

    const onSubmitBefore = () => {
        let _form = form

        _form.product_parent = productTransform
        _form.list_products = listTransformation
        _form.surplus = surplus;
        _form.amountUsed = amountUsed;
        _form.total_transform = totalTransform;

        if (listTransformation.length === 0) {
            alert("Debes agregar productos");
            return;
        }

        if (surplus < 0) {
            alert("No cuentas con inventario");
            return;
        }

        onSubmit(_form)
    }

    const notifyUpdate = (row) => {
        console.log('row---', row);


        let _list = listTransformation.map(r => {
            if (r.id === undefined) {
                if (r._id === row._id) {
                    r = row;
                }
            }


            return r;
        })



        let _generate = _list.filter(d => d.delete !== true).reduce((a, b) => a = a + parseFloat(parseFloat(b.amount_generate) || 0), 0)
        let _used = _list.filter(d => d.delete !== true).reduce((a, b) => a = a + parseFloat(parseFloat(b.amount_used) || 0), 0)


        setAmountGenerate(_generate)
        setAmountUsed(_used)

        setSurplus(form.amount - _used);

        setListTransformation(_list)
        setShow(false)
        setFormEditRow({})
    }

    return <>
        <Row className='d-flex justify-content-center'>
            <Col lg={5}>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col>{title}</Col>
                            <Col className='d-flex justify-content-end'>
                                {!isEdit && <button className='btn btn-sm btn-success' onClick={onSubmitBefore}>Guardar</button>}

                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col>
                                <div className='form-group'>
                                    <label>Fecha de Registro</label>
                                    <input
                                        disabled={form.id !== undefined ? "disabled" : ''}
                                        className='form-control'
                                        type="date"
                                        min={form.date_init}
                                        max={form.date_end}
                                        value={form.registration_date || ''}
                                        name="registration_date"
                                        onChange={updateInput}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <AsyncSelect

                                    value={productTransform}
                                    placeholder="Busca el Producto"
                                    loadOptions={loadOptions}
                                    // defaultOptions={defaultOptions}
                                    onChange={(e) => {

                                        setProductTransform(e)
                                        getLastInventory(e.id, moment().format("YYYY-MM-DD"))
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {productTransform !== '' &&
                                    <>
                                        <Row className='mt-2'>
                                            <Col>Proveedor</Col>
                                            <Col>{productTransform.supplier}</Col>
                                        </Row>
                                        {/* <Row>
                                            <Col>Medida</Col>
                                            <Col>{productTransform.measure_unit.description}</Col>
                                        </Row> */}
                                        {errorQuantity && <Alert variant='danger'>
                                            <Alert.Heading>No cuentas con suficiente inventario</Alert.Heading>
                                        </Alert>}

                                        <Row className={`${errorQuantity && 'text-danger'}`}>
                                            <Col>Disponibles</Col>
                                            <Col>{inventory.amount_total}</Col>
                                        </Row>
                                        <Row>
                                            <Col>Costo Actual</Col>
                                            <Col>${formatNumber(inventory.cost_average || 0)}</Col>
                                        </Row>
                                        <Row className='my-2'>
                                            <Col>
                                                <InputGroup className="mb-3">
                                                    <Form.Control
                                                        className='input-sm'
                                                        type='number'
                                                        onBlur={validateInventory}
                                                        onChange={updateInput}
                                                        value={form.amount}
                                                        name='amount'
                                                        placeholder="Cantidad a usar"
                                                        aria-describedby="basic-addon2"
                                                    />
                                                    <InputGroup.Text id="basic-addon2">{productTransform.measure_unit}</InputGroup.Text>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>Costo de Transformación</Col>
                                            <Col>${formatNumber(totalTransform || 0)}</Col>
                                        </Row>

                                    </>}

                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <Row className='mt-2 d-flex justify-content-center'>
            <Col lg={10}>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col>Productos a Generar</Col>
                        </Row>
                    </Card.Header>

                    <Card.Body>
                        <table className='table table-sm table-bordered'>
                            <thead className='thead-dark'>
                                <tr>
                                    <th>Producto</th>
                                    <th>Costo Unit</th>
                                    <th width="18%">Cantidad a Generar</th>
                                    <th width="18%">Cantidad a Usada</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {listTransformation.filter(d => d.delete != true).map((p, k) => <ItemTransformation
                                    cost={productTransform.cost_average}
                                    amountUsed={amountUsed}
                                    totalTransform={totalTransform}
                                    surplus={surplus} row={p} key={k}
                                    notifyUpdate={notifyUpdate}
                                    onDelete={onDelete} onEdit={onEdit} formEdit={formEditRow}
                                    isEdit={isEdit}
                                />)}
                                {show && <RowProductTransformation cost={productTransform.cost_average} product={selected}
                                    onEditRow={onEditRow}
                                    onCancel={() => setShow(false)} onSucccess={onAddItems} />}
                                {
                                    (!show && !isEdit && form.amount > 0) && <tr>
                                        <td>
                                            <button className='btn btn-sm btn-info' onClick={addNewRow}>Agregar Producto</button>
                                        </td>
                                    </tr>
                                }
                            </tbody >
                            {
                                listTransformation.length > 0 && <tfoot>
                                    <tr>
                                        <th colSpan={2}>TOTAL TRANSFORMACIÓN</th>
                                        <th className='text-right'>{amountGenerate}</th>
                                        <th className='text-right'>{amountUsed}</th>
                                        {/* <th className='text-right'>{formatNumber(total)}</th> */}
                                    </tr>
                                    <tr>
                                        <th colSpan={3}>Desperdicio</th>
                                        <th className='text-right'>{formatNumber(parseFloat(surplus).toFixed(3))}</th>
                                    </tr>
                                </tfoot>
                            }
                        </table >
                    </Card.Body >
                </Card >
            </Col >
        </Row >
    </>

}

const mapsState = (props) => {
    let { inventory } = props

    return {
        fetching_by_id: inventory.fetching_by_id,
        row: inventory.row
    }
}

export default connect(mapsState, { getLastInventory })(FormTransformation);