import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { formatNumber } from "../../utils";

import {
  liquidatePayroll,
  deletePayroll,
  getPayroll,
  updatePayroll,
  onCreateMultiplePdf,
  reversePayroll,
} from "../../Redux/payrollDuck";

import { AiFillFilePdf } from "react-icons/ai";
import { Link } from "react-router-dom";
import { toogleLoader } from "../../Redux/alertDuck";
import { AiFillEdit } from "react-icons/ai";
import ItemLiquidate from "./item.liquidate";
import ModalConfirmPayroll from "./modal.confirm.segment";
import ModalUnLock from "./modal.unlock.payroll";
const API_URL = process.env.REACT_APP_API_URL;

const ListLiquidate = ({
  fetching_list_pay,
  nomina,
  liquidatePayroll,
  deletePayroll,
  getPayroll,
  fetching_delete,
  toogleLoader,
  fetching_liquidate,
  listMethod,
  updatePayroll,
  status,
  onCreateMultiplePdf,
  fetching_pdf,
  link,
  user,
  reversePayroll,
  fetching_reverse,
}) => {
  const [listPaysheet, setListPaysheet] = useState([]);
  const [listPaysheetFilter, setListPaysheetFilter] = useState([]);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState({});
  const [isNegative, setIsNegative] = useState(false)
  const [totalSelected, setTotalSelected] = useState(0)
  const [request, setRequest] = useState({
    cash: 0,
    nequi: 0,
    daviplata: 0,
    datafono: 0,
  });

  useEffect(() => {
    if (fetching_delete === 2) {
      getPayroll();
    }
  }, [fetching_delete]);

  useEffect(() => {
    if (fetching_reverse === 2) {
      window.location.reload();
    }
  }, [fetching_reverse]);

  useEffect(() => {
    if (fetching_pdf === 2) {
      console.log(window.open(`${API_URL}/${link}`, "_blank"));
    }
  }, [fetching_pdf]);

  useEffect(() => {
    if (fetching_list_pay === 2) {
      nomina = nomina.map((r) => {
        r.selected = false;
        r.checked = false;
        return r;
      });

      setListPaysheet(nomina);
      setListPaysheetFilter(nomina)
    }
  }, [fetching_list_pay]);

  useEffect(() => {
    if (fetching_liquidate === 2) {
      // getPayroll()
      // toogleLoader()
      window.location.reload();
    }
  }, [fetching_liquidate]);



  const onSavePay = () => {
    setModalConfirm(true);
    let _list = listPaysheet.filter((r) => r.checked === true);

    let _cash = _list
      .filter((l) => l.method_payment_id === 374)
      .reduce((a, b) => (a = a + parseFloat(b.payment)), 0);

    let _daviplata = _list
      .filter((l) => l.method_payment_id === 17)
      .reduce((a, b) => (a = a + parseFloat(b.payment)), 0);
    let _datafono = _list
      .filter((l) => l.method_payment_id === 375)
      .reduce((a, b) => (a = a + parseFloat(b.payment)), 0);
    let _nequi = _list
      .filter((l) => l.method_payment_id === 16)
      .reduce((a, b) => (a = a + parseFloat(b.payment)), 0);

    if (isNegative) {
      _cash = listPaysheet
        .filter((r) => r.checked === true && parseFloat(r.payment) > 0)
        .filter((l) => l.method_payment_id === 374)
        .reduce((a, b) => a + parseFloat(b.payment || 0), 0);

      _daviplata = listPaysheet
        .filter((r) => r.checked === true && parseFloat(r.payment) > 0)
        .filter((l) => l.method_payment_id === 17)
        .reduce((a, b) => (a = a + parseFloat(b.payment)), 0);

      _datafono = listPaysheet
        .filter((r) => r.checked === true && parseFloat(r.payment) > 0)
        .filter((l) => l.method_payment_id === 375)
        .reduce((a, b) => (a = a + parseFloat(b.payment)), 0);

      _nequi = listPaysheet
        .filter((r) => r.checked === true && parseFloat(r.payment) > 0)
        .filter((l) => l.method_payment_id === 16)
        .reduce((a, b) => (a = a + parseFloat(b.payment)), 0);
    }


    setRequest({
      ...request,
      ["cash"]: _cash,
      ["daviplata"]: _daviplata,
      ["datafono"]: _datafono,
      ["nequi"]: _nequi,
    });

    // toogleLoader()
    // liquidatePayroll(_list)
  };

  const onSuccessPayment = (form, _request) => {
    setModalConfirm(false);
    let _list = listPaysheet.filter((r) => r.checked === true);

    toogleLoader();
    liquidatePayroll(_list, form, _request);
  };

  const onDeletePayRoll = (item) => {
    if (window.confirm("¿Realmente deseas Eliminar este Registro?")) {
      deletePayroll(item.id);
    }
  };

  const onSelect = (item, status) => {

    // console.log('status----', status);

    // let _total = 0;
    // let _list = listPaysheet.map(r => {
    //   if (r.id === item.id) {
    //     _total += parseFloat(r.payment);
    //     r.checked = status
    //   }
    //   return r;
    // });

    // setListPaysheet(_list);

    // // if (isNegative === true) {
    // //   _total = _list.filter((r) => r.checked === true && parseFloat(r.payment) > 0).reduce((a, b) => a + parseFloat(b.payment || 0), 0);
    // // } else {
    // //   _total = _list.filter((r) => r.checked === true).reduce((a, b) => a + parseFloat(b.payment || 0), 0);
    // // }

    // setTotalSelected(_total)
  }

  const onToogleSelect = (item, status) => {
    let _list = listPaysheet.map((r) => {
      if (r.id === item.id) {
        r.checked = status
      }
      return r;
    });

    let _total = 0;

    if (isNegative) {
      _total = _list.filter((r) => r.checked === true && parseFloat(r.payment) > 0).reduce((a, b) => a + parseFloat(b.payment || 0), 0);
    } else {
      _total = _list.filter((r) => r.checked === true).reduce((a, b) => a + parseFloat(b.payment || 0), 0);
    }


    setTotalSelected(_total)
    setListPaysheet(Object.assign([], _list));
  };

  const onPrintPdf = () => {
    console.log(
      "listPaysheet.filter( r=>r.selected===true)",
      listPaysheet.filter((r) => r.selected === true)
    );
    onCreateMultiplePdf(listPaysheet.filter((r) => r.selected === true));
    // listPaysheet.filter(r => r.selected === true).map(async r => {
    //     // console.log(window.open(`http://ws.manaexpress.online/pdf/${r.id}.pdf`, '_blank'));
    //     console.log(window.open(`${API_URL}/pdf/${r.id}.pdf`, '_blank'));
    // })
  };

  const onChangeMethod = (item, value) => {
    updatePayroll(item.id, { method_payment_id: value });
  };
  const unLock = (item) => {
    setSelected(item);
    setModal(true);
  };

  const onReversePayroll = (item) => {
    // setModal(false)
    reversePayroll(item);
  };

  const onSelectAll = (e) => {
    if (e.target.checked === true) {
      let _listPaysheet = listPaysheet;

      _listPaysheet = _listPaysheet.map((r) => {
        r.selected = true;
        r.checked = true;
        return r;
      });

      setListPaysheet(_listPaysheet);
    } else {
      let _listPaysheet = listPaysheet;

      _listPaysheet = _listPaysheet.map((r) => {
        r.selected = false;
        return r;
      });

      setListPaysheet(_listPaysheet);
    }
  }

  const onRecalculate = (e) => {
    let _total = 0;
    if (e.target.checked) {
      setIsNegative(true)
      _total = listPaysheet.filter((r) => r.checked === true && parseFloat(r.payment) > 0).reduce((a, b) => a + parseFloat(b.payment || 0), 0);
    } else {
      setIsNegative(false)
      _total = listPaysheet.filter((r) => r.checked === true).reduce((a, b) => a + parseFloat(b.payment || 0), 0);
    }

    setTotalSelected(_total)

  }

  let total_payment = listPaysheet
    .filter((r) => r.status_id === 2)
    .reduce((a, b) => (a = a + parseFloat(b.payment || 0)), 0);
  let other_deducts = listPaysheet
    .filter((r) => r.status_id === 2)
    .reduce((a, b) => (a = a + parseFloat(b.other_deductions || 0)), 0);

  let total_selected = listPaysheet
    .filter((r) => r.checked === true)
    .reduce((a, b) => (a = a + parseFloat(b.payment || 0)), 0);

  return (
    <Container fluid>
      <Row className="my-2 d-flex justify-content-end">
        <Col lg={3}>
          <input className='' type='checkbox' checked={isNegative} onChange={onRecalculate} /> Pagar sin Negativos
        </Col>
        <Col lg={2}>
          <button
            className="btn btn-success"
            onClick={onSavePay}
            disabled={
              listPaysheet.filter((r) => r.checked === true).length > 0
                ? false
                : true
            }
          >
            Realizar Pago
          </button>
        </Col>
        {parseInt(status) === 2 && (
          <Col lg={2}>
            <button
              className="btn btn-info"
              onClick={onPrintPdf}
              disabled={
                listPaysheet.filter((r) => r.selected === true).length > 0
                  ? false
                  : true
              }
            >
              Imprimr ({listPaysheet.filter((r) => r.selected === true).length})
            </button>
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <table className="table table-bordered table-sm table-hover">
            <thead className="thead-dark">
              <tr>
                <th></th>
                <th>Fecha Corte</th>
                <th>Nombre</th>
                <th>Sucursal</th>
                <th>Dias</th>
                <th>Básico</th>
                <th>Bonificación</th>
                <th>Desc</th>
                <th>Otros Desc</th>
                <th>Otros Pagos</th>
                <th>Subtotal</th>
                <th>A pagar</th>
                <th>
                  Método{" "}
                  <input
                    type={"checkbox"}
                    className="mx-3"
                    onChange={onSelectAll}
                  />
                </th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {listPaysheet.map((r) => (
                <ItemLiquidate
                  key={r.id}
                  item={r}
                  onSelect={onSelect}
                  onToogleSelect={onToogleSelect}
                  listMethod={listMethod}
                  onChangeMethod={onChangeMethod}
                  onDeletePayRoll={onDeletePayRoll}
                  user={user}
                  unLock={unLock}
                />
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={10}>
                  <b>Subtotal</b>
                </td>
                <td>${formatNumber(other_deducts)}</td>
                <td>${formatNumber(total_payment)}</td>
              </tr>

              <tr>
                <td colSpan={11}>
                  <b>Total Seleccionado</b>
                </td>
                <td>${formatNumber(totalSelected)}</td>
              </tr>
            </tfoot>
          </table>
        </Col>
      </Row>
      <ModalConfirmPayroll
        show={modalConfirm}
        onSuccess={onSuccessPayment}
        onHide={() => setModalConfirm(false)}
        request={request}
        total={totalSelected}
        isNegative={isNegative}
      />

      <ModalUnLock
        show={modal}
        item={selected}
        onSuccess={onReversePayroll}
        onHide={() => setModal(false)}
      />
    </Container>
  );
};
const mapsState = (props) => {
  let { payroll, user } = props;
  return {
    nomina: payroll.data,
    fetching_list_pay: payroll.fetching_list,
    fetching_delete: payroll.fetching_delete,
    fetching_liquidate: payroll.fetching_liquidate,
    fetching_pdf: payroll.fetching_pdf,
    fetching_reverse: payroll.fetching_reverse,
    link: payroll.link,
    user: user.data,
  };
};

export default connect(mapsState, {
  liquidatePayroll,
  deletePayroll,
  getPayroll,
  toogleLoader,
  updatePayroll,
  onCreateMultiplePdf,
  reversePayroll,
})(ListLiquidate);
