import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getListTransfer, onDeleteTransfer } from '../../Redux/transferDuck';
import TemplateMenu from '../../template/menu.template'
import { connect } from 'react-redux';
import ListTransferOrigin from './list.transfer.origin';
import ListTransferDeliver from './list.transfer.deliver';


const TransferPage = ({ }) => {

    return (<TemplateMenu>
        <Container fluid>
            <Row className='mt-3'>
                <Col lg={6}>
                    <Link to="/transferencias/new" className='btn btn-info btn-sm'>Realizar Transferencia</Link>
                </Col>               
            </Row>
            <Row className='mt-2'>
                <Col>
                    <Tabs
                      defaultActiveKey="home"
                      id="uncontrolled-tab-example"
                      className="mb-3">
                          <Tab eventKey="home" title="Transferencias Realizadas">
                            <ListTransferOrigin />
                        </Tab>
                        <Tab eventKey="profile" title="Transferencias Recibidas">
                           <ListTransferDeliver />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </Container>
    </TemplateMenu>
    )
}


const mapsState = (props) => {
    let { transfer } = props
    return {
    }
}

export default connect(mapsState, { getListTransfer, onDeleteTransfer })(TransferPage);