import React from 'react';
import moment from 'moment'
import { BsCamera, BsStopwatchFill, BsStopwatch } from 'react-icons/bs';

const RowTableWorker = ({ item, onClick, onClickTimer }) => {
    const { worker, worker_rest, worker_rests } = item

    const icon = worker_rest === null ? <BsStopwatch size={25} /> : <BsStopwatchFill size={25} />

    return (
        <tr className="p-0 mx-0 border-bottom active_list_reservation m-0 w-100">
            <td><span className="font-15 pt-3 pb-2">{worker.name}</span></td>
            <td>{moment(item.updatedAt).format("MMM-DD-YYYY, h:mm:ss a")}</td>
            <td>{worker_rests.length}</td>
            <td>{item.status_id_text}</td>
            <td>
                <button onClick={() => onClickTimer(item)} className='btn mx-2'>{icon}</button>
                {item.photo !== "" && <button onClick={() => onClick(item)} className='btn'><BsCamera size={25} /></button>}

            </td>
        </tr>
    )
}
export default RowTableWorker;