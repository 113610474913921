import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import TemplateMenu from '../../template/menu.template'
import { getPrescriptionById, onUpdatePrescription } from '../../Redux/prescriptionDuck'
import FormPrescription from './form.prescription'
import { toogleLoader } from '../../Redux/alertDuck';


const EditPrescription = ({ getPrescriptionById, row, fetching_by_id, onUpdatePrescription, fetching_create, toogleLoader }) => {

    let { id, type } = useParams();
    const history = useHistory();
    const [form, setForm] = useState({})

    useEffect(() => {
        // toogleLoader(true)

        getPrescriptionById(id, type)
    }, [id])

    useEffect(() => {
        if (fetching_by_id === 2) {
            let _row = row
            setForm(_row)
            toogleLoader(false)
        }
    }, [fetching_by_id])

    useEffect(() => {
        if (fetching_create === 2) {
            toogleLoader(false)

            history.push("/recetas");
            //history.goBack()
        }
    }, [fetching_create])

    const onSuccess = (form) => {
        toogleLoader(true)
        form.type = type;
        onUpdatePrescription(form)
    }

    console.log('form--', form);


    return (
        <TemplateMenu >
            <FormPrescription title={"Editar"} formEdit={form} onSuccess={onSuccess} product_parameter={{}} />
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { prescription } = props

    return {
        row: prescription.row,
        fetching_by_id: prescription.fetching_by_id,
        fetching_create: prescription.fetching_create
    }
}

export default connect(mapsState, { getPrescriptionById, onUpdatePrescription, toogleLoader })(EditPrescription);