import moment from "moment";
import React, { useEffect, useState } from "react";
import { formatNumber } from "../../utils";
import loading from '../../Images/loading.gif'
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const ItemTableDiference = ({ row, onUpdate, index, form }) => {
  const [amount, setAmount] = useState("");

  useEffect(() => {
    if (row.amount !== null) {
      setAmount(row.amount);
    }
  }, [row.amount]);

  let color = "";
  if (row.difference > 0) {
    color = "table-success";
  } else if (row.difference < 0) {
    color = "table-danger";
  } else {
    color = "";
  }

  return (
    <tr>
      <td className="font-12">{index}</td>
      {/* <td>{row.product}  {row.is_loading && <img src={loading} className="size-loading" />}</td> */}
      <td className="font-14">
        <Link target="_blank" to={`/diferencias-detail/${row.id}?date_init=${form.date_init}&date_end=${form.date_end}`}>{row.product}</Link></td>
      <td align="right" className="td-blue">{formatNumber(row.initial_inv)}</td>
      <td align="right" className="td-blue">{formatNumber(row.purchases)}</td>
      <td align="right" className="td-blue">{formatNumber(row.production_in)}</td>
      <td align="right" className="td-blue">{formatNumber(row.transformation_in)}</td>
      <td align="right" className="td-orange">{formatNumber(row.lowdown)}</td>
      <td align="right" className="td-orange">{formatNumber(row.production_out)}</td>
      <td align="right" className="td-orange">{formatNumber(row.transformation_out)}</td>
      <td align="right" className="td-orange">{formatNumber(row.sales)}</td>
      <td align="right" className="td-orange">{"No"}</td>
      <td align="right">{formatNumber(row.last_inventory)}</td>
      <td align="right">{formatNumber(row.counter) || '-'}</td>
      <td align="right" className={`${color}`}>{formatNumber(row.difference)}</td>
    </tr>
  );
};

export default ItemTableDiference;
