import React, { useEffect, Fragment, useState } from 'react'
import { Col, Container, Modal, Row } from "react-bootstrap";

const ModalUnlockIngresos = ({ show, item, onDeleteIngreso, onSuccess,handleClose }) => {
    const [form, setForm] = useState({ registration_date: '' });

    useEffect(() => {
        if (item !== undefined) {
            setForm(item)
        }

    }, [item])

    return <Modal show={show}  onHide={handleClose}>
        <Modal.Header closeButton>
            Modificaciones al Cierre
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col>Cierre</Col>
                <Col>{form.registration_date}</Col>
            </Row>
            <Row>
                <Col>Sede</Col>
                <Col>{form.branch}</Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
            <Container fluid>
                <Row>
                    <Col className='d-flex justify-content-center'>
                        <button className='btn btn-danger' onClick={()=>onDeleteIngreso(item)}>Eliminar Cierre</button>
                    </Col>
                    <Col className='d-flex justify-content-center'>
                        <button className='btn btn-success' onClick={() => onSuccess(item)}>Guardar</button>
                    </Col>
                </Row>
            </Container>

        </Modal.Footer>
    </Modal>
}

export default ModalUnlockIngresos;