import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Form, Row, Table } from "react-bootstrap";
import { calculateMargin, debounceData, formatNumber, getHeaders } from "../../utils";
import AsyncSelect from "react-select/async";
import ItemPrescription from "./item.prescription";
import RowProductPrescription from "./row.product.prescription";
import { getPrescriptionByProductId } from '../../Redux/prescriptionDuck'


import moment from "moment";
import { connect } from "react-redux";

const API_URL = process.env.REACT_APP_API_URL;

const initStateSelected = { measure_unit: "", cost_average: 0 };

const typeTable = {
  Product: 1,
  Delivery: 2,
};

const FormPrescription = ({ formEdit, title, onSuccess, fetching_validation, row_validation, product_parameter, getPrescriptionByProductId }) => {
  const [product, setProduct] = useState("");
  const [form, setForm] = useState({ product: {} });
  const [listPrescription, setListPrescription] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [total, setTotal] = useState(0);
  const [subTotal, setSubtotal] = useState(0);
  const [totalDelivery, setTotalDelivery] = useState(0);
  const [totalGain, setTotalGain] = useState(0);
  const [totalGainCost, setTotalGainCost] = useState(0);
  const [totalGainDelivery, setTotalGainDelivery] = useState(0);
  const [totalGainCostDelivery, setTotalGainCostDelivery] = useState(0);
  const [listSearch, setListSearch] = useState([]);
  const [selected, setSelected] = useState(initStateSelected);
  const [show, setShow] = useState(false);
  const [showOnTable, setShowOnTable] = useState(false);
  const [showDelivery, setShowDelivery] = useState(false);
  const [rowProduct, setRowProduct] = useState({});
  const [prescription, setPrescription] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [registrationDate, setRegistrationDate] = useState(moment().format("YYYY-MM-DD"));
  const debounceRef = useRef();


  useEffect(() => {
    if (fetching_validation === 2) {
      if (Object.keys(row_validation).length > 0) {
        if (window.confirm(`¿Ya tienes un receta creata con (${row_validation.product}), Deseas Editarlo?`)) {
          let { prescription_product } = row_validation

          window.location.href = `/recetas/${row_validation.id}/${row_validation.type_prescription}`;
        } else {
          setProduct('')
        }
      }
    }
  }, [fetching_validation])


  useEffect(() => {

    if (product_parameter !== null)
      if (Object.keys(product_parameter).length > 0) {

        if (product_parameter.type_product === 2) {
          setProduct({
            label: `${product_parameter.product} (Modificador)`,
            value: product_parameter.pos_id,
            price: product_parameter.price,
            product_type: 2,
          })
        } else {
          setProduct({
            label: `${product_parameter.description} (Producto)`,
            value: product_parameter.pos_id,
            price: product_parameter.price,
            product_type: 1,
          })
        }

      } else {
        // setProduct('')
      }

  }, [product_parameter])


  useEffect(() => {

    if (Object.keys(formEdit).length > 0) {

      let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));

      setRegistrationDate(date_end)

      let { product, product_id, price, category, prescription_product, group_pos } = formEdit;
      setIsEdit(true)
      setForm(formEdit);

      setProduct({
        label: product,
        value: product_id,
        price: price,
        category: category,
        group: group_pos,
      });

      setPrescription({ id: formEdit.id });

      let _prescription_product = prescription_product;
      setListPrescription(_prescription_product);
      let _quantity = _prescription_product.reduce((a, b) => (a = a + parseFloat(b.amount || 0)), 0);
      setQuantity(_quantity);

      let _cost = _prescription_product.filter((d) => d.type_product_id === 1)
        .reduce((a, b) => (a = a + parseFloat(b.cost_average || 0) * parseFloat(b.amount)), 0);

      setTotal(_cost);

      let _subtotal = _prescription_product.reduce((a, b) => (a = a + parseFloat(b.cost_average || 0) * parseFloat(b.amount)), 0);

      setSubtotal(_subtotal);

      let _prescription_product_delivery = prescription_product.filter((pre) => pre.type_product_id === 2);

      let _cost_delivery = _prescription_product_delivery.reduce((a, b) => (a = a + parseFloat(b.cost_average || 0) * parseFloat(b.amount)), 0);
      setTotalDelivery(_cost_delivery + _cost);

      let { gain_percent, gain_cost } = calculateMargin(_cost, price);
      setTotalGain(gain_percent.toFixed(2));
      setTotalGainCost(gain_cost.toFixed(2));

      let _costDelivery = prescription_product
        .filter((d) => d.delete !== true && d.type_product_id === 2)
        .reduce((a, b) => (a = a + parseFloat(b.cost_average * b.amount || 0)), 0);

      let total_cost_delivery = _costDelivery + _cost;

      let onDeliveryMargin = calculateMargin(total_cost_delivery, price);
      setTotalDelivery(total_cost_delivery);

      setTotalGainDelivery(onDeliveryMargin.gain_percent.toFixed(2));
      setTotalGainCostDelivery(onDeliveryMargin.gain_cost.toFixed(2));
    }
  }, [formEdit]);

  const loadOptions = async (inputValue) => {

    if (debounceRef.current)
      clearTimeout(debounceRef.current)


    let branch = JSON.parse(localStorage.getItem("branch"));
    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));

    let url = `${API_URL}/api/products-search/${inputValue}?branch=${branch.id}&date_init=${date_init}&date_end=${date_end}`;


    return await debounceData(debounceRef, url);
  };

  const onSavePrescription = () => {
    let _form = form;
    _form.product = product;
    _form.prescription = prescription;
    _form.list_products = listPrescription;

    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));
    _form.date_init = date_init;
    _form.date_end = date_end;
    onSuccess(_form);
  };

  const onAddItems = (item, type) => {

    let { product_inventory } = item;
    let { measure_unit, cost_average } = product_inventory;
    item.cost_average = cost_average;
    item.product = product_inventory.name;
    item.measure_unit = measure_unit.description;

    let res = [...listPrescription, item];

    let _subtotal = res
      .filter((d) => d.delete !== true)
      .reduce((a, b) => (a = a + parseFloat(b.cost_average * b.amount || 0)), 0);

    setSubtotal(_subtotal);

    setListPrescription(res);

    let _quantity = res
      .filter((d) => d.delete !== true && d.type_product_id === 1)
      .reduce((a, b) => (a = a + parseFloat(b.amount || 0)), 0);
    setQuantity(_quantity);

    let _cost = res.filter((d) => d.delete !== true && d.type_product_id === 1)
      .reduce((a, b) => (a = a + parseFloat(b.cost_average * b.amount || 0)), 0);

    setTotal(_cost);
    setShow(false);

    let _costDelivery = res
      .filter((d) => d.delete !== true && d.type_product_id === 2)
      .reduce((a, b) => (a = a + parseFloat(b.cost_average || 0 * b.amount || 0)), 0);

    let onTableMargin = calculateMargin(_cost, product.price);


    setTotalGain(onTableMargin.gain_percent.toFixed(2));
    setTotalGainDelivery(onTableMargin.gain_cost.toFixed(2));

    let total_cost_delivery = _costDelivery + _cost;

    let onDeliveryMargin = calculateMargin(total_cost_delivery, product.price);
    setTotalDelivery(total_cost_delivery);

    setTotalGainDelivery(onDeliveryMargin.gain_percent.toFixed(2));
    setTotalGainCostDelivery(onDeliveryMargin.gain_cost.toFixed(2));
  };

  const onDeleteItem = (item) => {
    let _list = [];

    if (item.id !== undefined) {
      _list = listPrescription.map((l) => {
        if (l.id === item.id) {
          l.delete = true;
        }

        return l;
      });
    } else {
      _list = listPrescription.filter((d) => item._id !== d._id);
    }

    setListPrescription(_list);
  }

  const onNotifyRow = () => {
    reCalculateValues();
  };

  const reCalculateValues = () => {
    let _quantity = listPrescription
      .filter((d) => d.type_product_id === 1)
      .reduce((a, b) => (a = a + parseFloat(b.amount || 0)), 0);

    let _cost = listPrescription
      .filter((d) => d.type_product_id === 1)
      .reduce((a, b) => (a = a + parseFloat(b.cost_average * b.amount || 0)), 0);

    setTotal(_cost);
    setQuantity(_quantity);
    let onTableMargin = calculateMargin(_cost, product.price);
    setTotalGain(onTableMargin.gain_percent.toFixed(2));
    setTotalGainCost(onTableMargin.gain_cost.toFixed(2));

    let _costDelivery = listPrescription
      .filter((d) => d.delete !== true && d.type_product_id === 2)
      .reduce((a, b) => (a = a + parseFloat(b.cost_average * b.amount || 0)), 0
      );

    let total_cost_delivery = _costDelivery + _cost;

    let onDeliveryMargin = calculateMargin(total_cost_delivery, product.price);
    setTotalDelivery(total_cost_delivery);

    setTotalGainDelivery(onDeliveryMargin.gain_percent.toFixed(2));
    setTotalGainCostDelivery(onDeliveryMargin.gain_cost.toFixed(2));
  };

  const onEditItem = (item) => { };

  const onChangeType = (item) => {
    let _list = listPrescription.map((p) => {
      if (item.id === undefined) {
        if (p._id === item._id) {
          p.type_product_id = item.type_product_id;
        }
      } else {
        if (p.id === item.id) {
          p.type_product_id = item.type_product_id;
        }
      }

      return p;
    });

    setListPrescription(_list);
    reCalculateValues();
  };

  return (
    <Form>
      <Row className="d-flex justify-content-center mt-3">
        <Col lg={10}>
          <Card>
            <Card.Header>
              <Row>
                <Col lg={2}>Receta</Col>
                <Col className="d-flex justify-content-end">
                  <button type="button" className="btn btn-sm btn-success" onClick={onSavePrescription} >Guardar</button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col lg={5}>
                  <Row>
                    <Col>
                      <AsyncSelect
                        isDisabled={isEdit}
                        value={product}
                        placeholder={`Busca el Producto`}
                        loadOptions={loadOptions}
                        // defaultOptions={defaultOptions}
                        onChange={(e) => {
                          let _filter = listSearch.filter(
                            (s) => parseInt(s.value) === parseInt(e.value)
                          )[0];
                          setSelected(_filter);
                          setProduct(e)
                          getPrescriptionByProductId(parseInt(e.value), e.product_type)
                        }}
                      />
                    </Col>
                  </Row>
                  {Object.keys(product).length > 0 && (
                    <>
                      <Row className="mt-2">
                        <Col>Categoria</Col>
                        <Col>{product.category}</Col>
                      </Row>
                      <Row className="mt-1">
                        <Col>Familia</Col>
                        <Col>{product.group}</Col>
                      </Row>
                    </>
                  )}
                </Col>
                <Col>
                  {product !== "" && (
                    <>
                      <Row className="border-bottom my-1">
                        <Col>
                          <Table className="table-sm table-bordered">
                            <thead className="thead-dark">
                              <tr>
                                <th className="text-center" colSpan={2}>PARA MESA</th>
                              </tr>
                              <tr>
                                <th>DESCRIPCIÓN</th>
                                <th>VALOR</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td><b>Precio</b></td>
                                <td align="right">
                                  {formatNumber(product.price)}
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <b>Totales Costo Receta</b>
                                </td>
                                <td align="right">{formatNumber(total)}</td>
                              </tr>

                              <tr>
                                <td>
                                  <b>Margen de Ganancia %</b>
                                </td>
                                <td align="right">
                                  <b>{totalGain}%</b>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Margen de Ganancia $</b>
                                </td>
                                <td align="right">
                                  <b>${formatNumber(totalGainCost)}</b>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                        <Col>
                          <Table className="table-sm table-bordered">
                            <thead className="thead-dark">
                              <tr>
                                <th className="text-center" colSpan={2}>
                                  PARA LLEVAR
                                </th>
                              </tr>
                              <tr>
                                <th>DESCRIPCIÓN</th>
                                <th>VALOR</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <b>Precio</b>
                                </td>
                                <td align="right">
                                  {formatNumber(product.price)}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Totales Costo Receta</b>
                                </td>
                                <td align="right">
                                  {formatNumber(totalDelivery)}
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <b>Margen de Ganancia %</b>
                                </td>
                                <td align="right">
                                  <b>{totalGainDelivery}%</b>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Margen de Ganancia $</b>
                                </td>
                                <td align="right">
                                  <b>${formatNumber(totalGainCostDelivery)}</b>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-2 d-flex justify-content-center">
        <Col lg={11}>
          <Row>
            <Col>
              <Card>
                <Card.Header>Productos Seleccionados</Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <Table className="table-bordered table-sm">
                        <thead className="thead-dark">
                          <tr>
                            <th>Producto</th>
                            <th>Unidad</th>
                            <th className="text-center">Costo Unidad</th>
                            <th className="text-center">Cantidad</th>
                            <th className="text-center">Costo Receta</th>
                            <th className="text-center">Para Llevar</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {listPrescription.length > 0 ? (
                            listPrescription
                              .filter((l) => l.delete !== true)
                              .map((p, i) => (
                                <ItemPrescription
                                  item={p}
                                  key={i}
                                  onNotify={onNotifyRow}
                                  onDelete={onDeleteItem}
                                  onChangeType={onChangeType}

                                />
                              ))
                          ) : (
                            <tr>
                              <td colSpan={5}>No tienes productos agregados</td>
                            </tr>
                          )}
                          {show && (
                            <RowProductPrescription
                              registration_date={registrationDate}
                              product={rowProduct}
                              onCancel={() => setShow(false)}
                              onSucccess={(item) =>
                                onAddItems(item, typeTable.Product)
                              }
                              onEditItem={onEditItem}
                            />
                          )}
                          {!show && (
                            <tr>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-info"
                                  onClick={() => setShow(true)}>
                                  Agregar Producto
                                </button>
                              </td>
                            </tr>
                          )}
                        </tbody>
                        {listPrescription.length > 0 && (
                          <tfoot>
                            <tr>
                              <th colSpan={3}>TOTAL RECETA</th>
                              <th className="text-right">{formatNumber(quantity)}</th>
                              <th className="text-right">{formatNumber(subTotal)}</th>
                            </tr>
                          </tfoot>
                        )}
                      </Table>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

const mapsState = (props) => {
  let { prescription } = props
  return {
    fetching_validation: prescription.fetching_validation,
    row_validation: prescription.row_validation,

  }
}

export default connect(mapsState, { getPrescriptionByProductId })(FormPrescription);