import React, { useEffect } from 'react'
import { Col, Modal, Row } from 'react-bootstrap';

const ModalProductExists = ({ show, onConfirm, product, onHide }) => {

    useEffect(() => {


    }, [product])

    return (
        <Modal show={show} onHide={onHide} size={"lg"}>
            <Modal.Header closeButton>
                <Modal.Title className='text-center'>Producto Existente</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col className='text-center'><b>¿Deseas Replicarlo en Bodega?</b></Col>
                </Row>
                <Row className='mt-1'>
                    <Col><b>Nombre</b></Col>
                    <Col>{product.name}</Col>
                    <Col><b>Código</b></Col>
                    <Col>{product.code}</Col>
                </Row>
                <Row className='my-1'>
                    <Col><b>Unidad de Medidad</b></Col>
                    <Col>{product.measure_unit.description}</Col>
                    <Col><b>Familia</b></Col>
                    <Col>{product.family_inventory.description}</Col>
                </Row>
                <Row>
                    <Col><b>Grupo</b></Col>
                    <Col>{product.group_inventory.description}</Col>
                    <Col><b>Tipo de Control</b></Col>
                    <Col>{product.type_inventory.description}</Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col>
                        <button className='btn btn-sm btn-info' onClick={onConfirm}>Replicarlo</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalProductExists;