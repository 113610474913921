import axios from 'axios'

import { getHeaders, validateWorker } from '../utils'

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching_list: 0,
    fetching_doc: 0,
    fetching_create: 0,
    fetching_by_id: 0,
    fetching_novelty: 0,
    fetching_novelty_delete: 0,
    fetching_filter: 0,
    fetching_list_turn: 0,
    fetching_create_turn: 0,
    fetching_update: 0,
    fetching_delete: 0,
    fetching_document: 0,
    data: [],
    data_docs: [],
    data_turn: [],
    selected: {},
    errors: {},
    row: {},
    filter: '',
    msg: ''
}

let LIST_WORKER = "LIST_WORKER";
let LIST_WORKER_SUCCESS = "LIST_WORKER_SUCCESS";
let CREATE_WORKER = "CREATE_WORKER";
let CREATE_WORKER_SUCCESS = "CREATE_WORKER_SUCCESS";
let CREATE_WORKER_ERROR = "CREATE_WORKER_ERROR";
let WORKER_BY_ID = "WORKER_BY_ID";
let WORKER_BY_ID_SUCCESS = "WORKER_BY_ID_SUCCESS";
let ADD_NOVELTY = "ADD_NOVELTY";
let ADD_NOVELTY_SUCCESS = "ADD_NOVELTY_SUCCESS";
let DELETE_NOVELTY = "DELETE_NOVELTY";
let DELETE_NOVELTY_SUCCESS = "DELETE_NOVELTY_SUCCESS";
let SAVE_FILTER = "SAVE_FILTER";
let GET_FILTER = "GET_FILTER";
let GET_FILTER_SUCCESS = "GET_FILTER_SUCCESS";
let LIST_WORKER_TURN = "LIST_WORKER_TURN";
let LIST_WORKER_TURN_SUCCESS = "LIST_WORKER_TURN_SUCCESS";
let CREATE_WORKER_TURN = "CREATE_WORKER_TURN";
let CREATE_WORKER_TURN_SUCCESS = "CREATE_WORKER_TURN_SUCCESS";
let UPDATE_TURN = "UPDATE_TURN";
let UPDATE_TURN_SUCCESS = "UPDATE_TURN_SUCCESS";
let DELETE_WORKER = "DELETE_WORKER";
let DELETE_WORKER_SUCCESS = "DELETE_WORKER_SUCCESS";
let DELETE_WORKER_ERROR = "DELETE_WORKER_ERROR";
let CREATE_WORKER_DOCUMENT = "CREATE_WORKER_DOCUMENT";
let CREATE_WORKER_DOCUMENT_SUCCESS = "CREATE_WORKER_DOCUMENT_SUCCESS";
let LIST_WORKER_DOCUMENT = "LIST_WORKER_DOCUMENT";
let LIST_WORKER_DOCUMENT_SUCCESS = "LIST_WORKER_DOCUMENT_SUCCESS";
let LIST_NOVELTY = "LIST_NOVELTY";
let LIST_NOVELTY_SUCCESS = "LIST_NOVELTY_SUCCESS";

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_WORKER:
            return { ...state, fetching_list: 1, }
        case LIST_WORKER_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload, fetching_novelty: 0 }
        case CREATE_WORKER:
            return { ...state, fetching_create: 1, }
        case CREATE_WORKER_SUCCESS:
            return { ...state, fetching_create: 2, selected: action.payroll, errors: {} }
        case CREATE_WORKER_ERROR:
            return { ...state, fetching_create: 3, errors: action.payload, form: action.form }
        case WORKER_BY_ID:
            return { ...state, fetching_by_id: 1 }
        case WORKER_BY_ID_SUCCESS:
            return { ...state, fetching_by_id: 2, selected: action.payload }
        case ADD_NOVELTY:
            return { ...state, fetching_novelty: 1 }
        case ADD_NOVELTY_SUCCESS:
            return { ...state, fetching_novelty: 2, row: action.payload }
        case LIST_NOVELTY:
            return { ...state, fetching_novelty: 1 }
        case LIST_NOVELTY_SUCCESS:
            return { ...state, fetching_novelty: 2, data: action.payload }
        case DELETE_NOVELTY:
            return { ...state, fetching_novelty_delete: 1 }
        case DELETE_NOVELTY_SUCCESS:
            return { ...state, fetching_novelty_delete: 2 }
        case SAVE_FILTER:
            return { ...state }
        case GET_FILTER:
            return { ...state, fetching_filter: 1 }
        case GET_FILTER_SUCCESS:
            return { ...state, fetching_filter: 2, filter: action.payload }
        case LIST_WORKER_TURN:
            return { ...state, fetching_list_turn: 1 }
        case LIST_WORKER_TURN_SUCCESS:
            return { ...state, fetching_list_turn: 2, data_turn: action.payload }
        case CREATE_WORKER_TURN:
            return { ...state, fetching_create_turn: 1 }
        case CREATE_WORKER_TURN_SUCCESS:
            return { ...state, fetching_create_turn: 2 }
        case UPDATE_TURN:
            return { ...state, fetching_update: 1 }
        case UPDATE_TURN_SUCCESS:
            return { ...state, fetching_update: 2 }
        case DELETE_WORKER:
            return { ...state, fetching_delete: 1 }
        case DELETE_WORKER_SUCCESS:
            return { ...state, fetching_delete: 2 }
        case DELETE_WORKER_ERROR:
            return { ...state, fetching_delete: 3, msg: action.payload }
        case CREATE_WORKER_DOCUMENT:
            return { ...state, fetching_document: 1 }
        case CREATE_WORKER_DOCUMENT_SUCCESS:
            return { ...state, fetching_document: 2 }
        case LIST_WORKER_DOCUMENT:
            return { ...state, fetching_docs: 1 }
        case LIST_WORKER_DOCUMENT_SUCCESS:
            return { ...state, fetching_docs: 2, data_docs: action.payload }
        default:
            return state;
    }
}

export let saveFilter = (query) => (dispatch, getState) => {
    dispatch({
        type: SAVE_FILTER,
    });
    localStorage.setItem("query", query)
}
export let getFilter = (query) => (dispatch, getState) => {
    dispatch({
        type: GET_FILTER,
    });

    dispatch({
        type: GET_FILTER_SUCCESS,
        payload: localStorage.getItem("query")
    });
}


export let getWorkers = (query) => (dispatch, getState) => {
    dispatch({
        type: LIST_WORKER,
    });

    let branch = JSON.parse(localStorage.getItem("branch"));


    let url = `${API_URL}/api/worker?branch_id=${branch.id}`;

    if (query !== undefined && query !== null) {
        // url  = `${API_URL}/api/ingresos/${filter.date_init}/${filter.date_end}`;
        url += query;
    }


    fetch(url, {
        method: "GET",
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: LIST_WORKER_SUCCESS,
            payload: resp.results
        })
    })
}

export let getWorkersTurn = (query) => (dispatch, getState) => {
    dispatch({
        type: LIST_WORKER_TURN,
    });

    let branch = JSON.parse(localStorage.getItem("branch"));

    let url = `${API_URL}/api/worker`;

    if (query !== undefined && query !== null) {
        // url  = `${API_URL}/api/ingresos/${filter.date_init}/${filter.date_end}`;
        url = `${API_URL}/api/worker${query}&branch_id=${branch.id}`;
    }

    fetch(url, {
        method: "GET",
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: LIST_WORKER_TURN_SUCCESS,
            payload: resp.results
        })
    })
}


export let getWorkersActives = (query) => (dispatch, getState) => {
    dispatch({
        type: LIST_WORKER,
    });


    let branch = JSON.parse(localStorage.getItem("branch"));
    query = query || ''

    // let url = `${API_URL}/api/worker-active?${branch.filter}${query}`
    let url = `${API_URL}/api/worker-active?branch_id=${branch.id}`

    fetch(url, {
        method: "GET",
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: LIST_WORKER_SUCCESS,
            payload: resp.results
        })
    })
}

export let getWorkersTurnActives = (query) => (dispatch, getState) => {
    dispatch({
        type: LIST_WORKER_TURN,
    });

    let branch = JSON.parse(localStorage.getItem("branch"))

    let url = `${API_URL}/api/worker-turn-active?branch_id=${branch.id}`

    if (query !== undefined) {
        url = `${API_URL}/api/worker-turn-active${query}&branch_id=${branch.id}`
    }

    fetch(url, {
        method: "GET",
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: LIST_WORKER_TURN_SUCCESS,
            payload: resp.results
        })
    })
}

export let getWorkerById = (id) => (dispatch, getState) => {
    dispatch({
        type: WORKER_BY_ID,
    });

    fetch(`${API_URL}/api/worker/${id}`, {
        method: "GET",
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: WORKER_BY_ID_SUCCESS,
            payload: resp
        })
    })
}

export let createWorker = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_WORKER,
    });

    let validate = validateWorker(form)


    if (Object.keys(validate).length > 0) {
        dispatch({
            type: CREATE_WORKER_ERROR,
            payload: validate,
            form: form
        })
        return;
    }


    fetch(`${API_URL}/api/worker`, {
        method: "POST",
        body: JSON.stringify(form),
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {
        if (resp.status === true) {
            dispatch({
                type: CREATE_WORKER_SUCCESS,
            })
        } else {
            dispatch({
                type: CREATE_WORKER_ERROR,
                payload: resp.errors
            })
        }

    })
}

export let createWorkerTurn = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_WORKER_TURN,
    });

    let validate = validateWorker(form)

    if (Object.keys(validate).length > 0) {
        dispatch({
            type: CREATE_WORKER_ERROR,
            payload: validate,
            form: form
        })
        return;
    }


    fetch(`${API_URL}/api/worker-turn`, {
        method: "POST",
        body: JSON.stringify(form),
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {
        if (resp.status === true) {
            dispatch({
                type: CREATE_WORKER_TURN_SUCCESS,
            })
        } else {
            dispatch({
                type: CREATE_WORKER_ERROR,
                payload: resp.errors
            })
        }

    })
}


export let updateWorker = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_WORKER,
    });

    let validate = validateWorker(form)


    if (Object.keys(validate).length > 0) {
        dispatch({
            type: CREATE_WORKER_ERROR,
            payload: validate,
            form: form
        })
        return;
    }

    fetch(`${API_URL}/api/worker/${form.id}`, {
        method: "PUT",
        body: JSON.stringify(form),
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: CREATE_WORKER_SUCCESS,
            payroll: resp
        })
    })
}

export let updateWorkerTurn = (form) => (dispatch, getState) => {
    dispatch({
        type: UPDATE_TURN,
    });

    let validate = validateWorker(form)


    if (Object.keys(validate).length > 0) {
        dispatch({
            type: CREATE_WORKER_ERROR,
            payload: validate,
            form: form
        })
        return;
    }

    fetch(`${API_URL}/api/worker/${form.id}`, {
        method: "PUT",
        body: JSON.stringify(form),
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: UPDATE_TURN_SUCCESS,
        })
    })
}

export let getNoveltyWorker = (workerID) => (dispatch, getState) => {
    dispatch({
        type: LIST_NOVELTY,
    });

    fetch(`${API_URL}/api/worker-novelty/${workerID}`, {
        method: "GET",
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: LIST_NOVELTY_SUCCESS,
            payload: resp.results
        })
    })
}

export let addNoveltyWorker = (form) => (dispatch, getState) => {
    dispatch({
        type: ADD_NOVELTY,
    });

    fetch(`${API_URL}/api/worker-novelty/${form.id}`, {
        method: "PUT",
        body: JSON.stringify(form),
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {
        form.id = resp.id
        dispatch({
            type: ADD_NOVELTY_SUCCESS,
            payload: form,

        })
    })
}

export let deleteNoveltyWorker = (item) => (dispatch, getState) => {
    dispatch({
        type: DELETE_NOVELTY,
    });

    fetch(`${API_URL}/api/worker-novelty/${item.id}`, {
        method: "delete",
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: DELETE_NOVELTY_SUCCESS,
        })
    })
}

export let deleteWorker = (item) => (dispatch, getState) => {
    dispatch({
        type: DELETE_WORKER,
    });

    fetch(`${API_URL}/api/worker/${item.id}`, {
        method: "delete",
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {

        if (resp.ok === false) {
            dispatch({
                type: DELETE_WORKER_ERROR,
                payload: resp.msg
            })
        } else {
            dispatch({
                type: DELETE_WORKER_SUCCESS,
            })
        }

    })
}

export let createWorkerDocument = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_WORKER_DOCUMENT,
    });

    let _form = new FormData()
    _form.append("description", form.description);
    _form.append("document", form.document);
    _form.append("worker_id", form.worker_id);

    axios.post(`${API_URL}/api/worker-document`, _form, {
        headers: getHeaders()

    }).then(resp => {
        dispatch({
            type: CREATE_WORKER_DOCUMENT_SUCCESS,
        });

    }).catch(error => {
        let err = {}

        for (const [key, value] of Object.entries(error.response.data.errors)) {
            // if ('title' === key) {
            //     err.title = value[0]
            // }
            // if ('description' === key) {
            //     err.description = value[0]
            // }
            // if ('priority' === key) {
            //     err.priority = value[0]
            // }
            // if ('price' === key) {
            //     err.price = value[0]
            // }
            // if ('category_id' === key) {
            //     err.category_id = value[0]
            // }

        }

        // dispatch({
        //     type: PRODUCT_ERROR,
        //     payload: err,
        //     form: form
        // })
    });
}

export let getWorkerDocument = (id) => (dispatch, getState) => {
    dispatch({
        type: LIST_WORKER_DOCUMENT,
    });

    let branch = JSON.parse(localStorage.getItem("branch"))

    axios.get(`${API_URL}/api/worker-document/${id}?branch=${branch.id}&&w`, {
        headers: getHeaders()

    }).then(resp => {
        console.log('resp', resp);

        dispatch({
            type: LIST_WORKER_DOCUMENT_SUCCESS,
            payload: resp.data.results
        });

    }).catch(error => {
        let err = {}

        for (const [key, value] of Object.entries(error.response.data.errors)) {
            // if ('title' === key) {
            //     err.title = value[0]
            // }
            // if ('description' === key) {
            //     err.description = value[0]
            // }
            // if ('priority' === key) {
            //     err.priority = value[0]
            // }
            // if ('price' === key) {
            //     err.price = value[0]
            // }
            // if ('category_id' === key) {
            //     err.category_id = value[0]
            // }

        }

        // dispatch({
        //     type: PRODUCT_ERROR,
        //     payload: err,
        //     form: form
        // })
    });
}