import React, { useEffect, useState } from 'react'
import { Card, Col, Form, Row, Table } from 'react-bootstrap';
import { formatNumber, getHeaders } from '../../utils';
import AsyncSelect from 'react-select/async';
import ItemSubPrescription from './item.subprescription';
import RowProductPrescription from './row.product.prescription';
import moment from 'moment';
import { getSubPrescriptionByProductId } from '../../Redux/subprescriptionDuck'
import { connect } from 'react-redux';

const API_URL = process.env.REACT_APP_API_URL

const initStateSelected = { measure_unit: '', cost_average: 0 }

const FormSubPrescription = ({ formEdit, title, onSuccess, getSubPrescriptionByProductId, fetching_validation, row_validation }) => {

    const [product, setProduct] = useState('')
    const [form, setForm] = useState({ product_id: 0 })
    const [listPrescription, setListPrescription] = useState([])
    const [quantity, setQuantity] = useState(0)
    const [total, setTotal] = useState(0)
    const [listSearch, setListSearch] = useState([])
    const [selected, setSelected] = useState(initStateSelected)
    const [show, setShow] = useState(false)
    const [rowProduct, setRowProduct] = useState({})
    const [subprescription, setSubprescription] = useState({})
    const [registrationDate, setRegistrationDate] = useState(moment().format("YYYY-MM-DD"));


    useEffect(() => {
        if (Object.keys(formEdit).length > 0) {
            let { product, subprescription_product, subprescription } = formEdit
            let { supplier, measure_unit } = product
            setForm(formEdit)

            setProduct({ label: product.name, value: product.id, cost_average: product.cost_average, measure_unit: measure_unit.description, supplier: supplier.business })
            setSubprescription(subprescription)

            setListPrescription(subprescription_product)


            let _quantity = subprescription_product.reduce((a, b) => a = a + parseFloat(b.amount || 0), 0)
            setQuantity(_quantity)

            let _cost = subprescription_product.reduce((a, b) => a = a + parseFloat(b.product_inventory.cost_average || 0) * parseFloat(b.amount), 0)
            setTotal(_cost)
        }

    }, [formEdit])


    useEffect(() => {
        if (fetching_validation === 2) {
            if (row_validation !== null) {
                console.log('row_validation', row_validation);

                if (window.confirm(`¿Ya tienes un receta creata con (${row_validation.product.name}), Deseas Editarlo?`)) {
                    window.location.href = `/subrecetas/${row_validation.id}`;
                } else {
                    setProduct('')
                }
            }
        }
    }, [fetching_validation])

    const loadOptions = async (inputValue) => {
        let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))
        let branch = JSON.parse(localStorage.getItem("branch"))
        let url = `${API_URL}/api/product-inventory-search/${inputValue}?type_inventory=661&date_init=${date_init}&date_end=${date_end}&branch=${branch.id}`

        let _search = await fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json())

        setListSearch(_search)

        return _search;
    }


    const onSavePrescription = () => {
        let _form = form
        _form.product_id = product.value;
        _form.subprescription = subprescription;
        _form.list_products = listPrescription;
        onSuccess(_form)
    }

    const onAddItems = (item) => {

        let { product_inventory } = item;
        let { measure_unit } = product_inventory;
        item.cost_average = product_inventory.cost_average
        item.product = product_inventory.name
        item.measure_unit = measure_unit.description

        let res = [
            ...listPrescription,
            item
        ]

        setListPrescription(res)

        let _quantity = res.filter(d => d.delete !== true).reduce((a, b) => a = a + parseFloat(b.amount || 0), 0)
        setQuantity(_quantity)
        let _cost = res.filter(d => d.delete !== true).reduce((a, b) => a = a + parseFloat(b.product_inventory.cost_average * b.amount || 0), 0)

        setTotal(_cost)
        setShow(false)
    }

    const onDeleteItem = (item) => {

        let _list = []

        if (item.id !== undefined) {
            _list = listPrescription.map(l => {
                if (l.id === item.id) {
                    l.delete = true;
                }

                return l
            })
        } else {
            _list = listPrescription.filter(d => item._id !== d._id)
        }

        setListPrescription(_list)


    }

    const onNotifyRow = () => {
        let _quantity = listPrescription.reduce((a, b) => a = a + parseFloat(b.amount || 0), 0)
        let _cost = listPrescription.reduce((a, b) => a = a + parseFloat(b.product_inventory.cost_average * b.amount || 0), 0)
        setTotal(_cost)
        setQuantity(_quantity)
    }

    const onEditItem = (item) => {

    }


    console.log('product', product);

    return (
        <Form>
            <Row className='d-flex justify-content-center mt-2'>
                <Col lg={11}>
                    <Card>

                        <Card.Header>
                            <Row>
                                <Col>{title} SubReceta</Col>
                                <Col className='d-flex justify-content-end'>
                                    <button type='button' className='btn btn-sm btn-success' onClick={onSavePrescription}>Guardar</button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>

                            <Row>
                                <Col>
                                    <AsyncSelect
                                        value={product}
                                        placeholder="Busca el Producto"
                                        loadOptions={loadOptions}
                                        // defaultOptions={defaultOptions}
                                        onChange={(e) => {

                                            console.log('e', e);
                                            getSubPrescriptionByProductId(e.id)

                                            // let _filter = listSearch.filter(s => parseInt(s.value) === parseInt(e.value))[0]
                                            // let { subprescription_product, subprescription } = _filter
                                            // console.log('_filter', _filter);


                                            // if (Object.keys(subprescription).length > 0) {

                                            // }
                                            setProduct(e)

                                            // setSubprescription(subprescription);


                                            // setListPrescription(subprescription_product)

                                            // let _quantity = subprescription_product.reduce((a, b) => a = a + parseFloat(b.amount || 0), 0)
                                            // setQuantity(_quantity)
                                            // let _cost = subprescription_product.reduce((a, b) => a = a + parseFloat(b.cost_average * b.amount || 0), 0)
                                            // setTotal(_cost)
                                            // setShow(false)
                                        }}
                                    />
                                </Col>
                                <Col>
                                    {product !== '' &&
                                        <>
                                            <Row>
                                                <Col>Costo</Col>
                                                <Col>{formatNumber(product.cost_average)}</Col>
                                            </Row>

                                            <Row>
                                                <Col>Proveedor</Col>
                                                <Col>{product.supplier}</Col>
                                            </Row>
                                        </>}

                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className='mt-2 d-flex justify-content-center'>
                <Col lg={11} >
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Table className='table-bordered table-sm' >
                                        <thead className='thead-dark'>
                                            <tr>
                                                <th>Producto</th>
                                                <th>Unidad</th>
                                                <th>Costo Unidad</th>
                                                <th>Cantidad</th>
                                                <th>Costo Subreceta</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(listPrescription.length > 0) ? listPrescription.filter(l => l.delete !== true).map((p, i) =>
                                                <ItemSubPrescription item={p} key={i} onNotify={onNotifyRow} onDelete={onDeleteItem} />)
                                                : <tr><td colSpan={5}>No tienes productos agregados</td></tr>}
                                            {show && <RowProductPrescription
                                                product={rowProduct}
                                                onCancel={() => setShow(false)} onSucccess={onAddItems}
                                                registration_date={registrationDate}
                                                onEditItem={onEditItem} />}
                                            {!show && <tr>
                                                <td>
                                                    <button type='button' className='btn btn-sm btn-info' onClick={() => setShow(true)}>Agregar Producto</button>
                                                </td>
                                            </tr>}
                                        </tbody>
                                        {listPrescription.length > 0 && <tfoot>
                                            <tr>
                                                <th colSpan={3}>TOTAL RECETA</th>
                                                <th>{quantity.toFixed(3)}</th>
                                                <th>{formatNumber(total)}</th>
                                            </tr>
                                        </tfoot>}
                                    </Table>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                </Col>
            </Row>
        </Form >
    )
}

const mapsState = (props) => {
    let { subprescription } = props

    return {
        fetching_validation: subprescription.fetching_validation,
        row_validation: subprescription.row_validation,
    }
}

export default connect(mapsState, { getSubPrescriptionByProductId })(FormSubPrescription);