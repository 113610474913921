
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const SOCKET_URL = process.env.REACT_APP_API_URL


const ItemBanner = ({ row }) => {
    let image = SOCKET_URL + row.image
    return (
        <Col lg={3}>
            <Row>
                <Col>
                    <Link to={`/banners/${row.id}`}>
                        <img src={image} className="img-fluid" alt="img-banner" />
                    </Link>
                </Col>
            </Row>
            <Row>
                <Col>{row.title}</Col>
            </Row>
            <Row>
                <Col>{row.description}</Col>
            </Row>
        </Col>
    )
}
export default ItemBanner;