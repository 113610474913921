import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { formatNumber } from "../../utils";
import { getReviewCash } from "../../Redux/reportDuck";
import { connect } from "react-redux";

const ModalConfirmPayroll = ({ show, onHide, onSuccess, request, total, getReviewCash, data, fetching_review,
  isNegative }) => {
  const [form, setForm] = useState({
    date_init: moment().format("YYYY-MM-01"),
    date_end: moment().format("YYYY-MM-01"),
  });
  const [selected, setSelected] = useState(false);
  const [errors, setErrors] = useState({});
  const [currentMoney, setCurrentMoney] = useState({
    cash: 0,
    nequi: 0,
    daviplata: 0,
    datafono: 0,
  });

  useEffect(() => {

    if (fetching_review === 2) {
      if (Object.keys(data).length > 0) {

        let { total_expense, total } = data;

        let _expense_cash = total_expense
          .filter((l) => l.id === 374)
          .reduce((a, b) => (a = a + parseFloat(b.total)), 0);
        let _expense_daviplata = total_expense
          .filter((l) => l.id === 17)
          .reduce((a, b) => (a = a + parseFloat(b.total)), 0);
        let _expense_datafono = total_expense
          .filter((l) => l.id === 375)
          .reduce((a, b) => (a = a + parseFloat(b.total)), 0);
        let _expense_nequi = total_expense
          .filter((l) => l.id === 16)
          .reduce((a, b) => (a = a + parseFloat(b.total)), 0);


        setCurrentMoney({
          ...currentMoney,
          ["cash"]: total.cash - _expense_cash - total.tips,
          ["daviplata"]: total.daviplata - _expense_daviplata,
          ["datafono"]: total.datafono - _expense_datafono,
          ["nequi"]: total.nequi - _expense_nequi,
        });

        setSelected(true);
      }
    }
  }, [fetching_review]);

  const updateInput = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "date_end") {
      getReviewCash(form.date_init, e.target.value);
    }
  };

  const onSuccessBefore = () => {
    let int = 0;

    if (request.cash > 0 && request.cash > currentMoney.cash) {
      setErrors({
        ...form,
        ["cash"]: "Supera el Monto",
      });
      int++;
    }

    if (request.daviplata > 0 && request.daviplata > currentMoney.daviplata) {
      setErrors({
        ...form,
        ["daviplata"]: "Supera el Monto",
      });
      int++;
    }

    if (request.nequi > 0 && request.nequi > currentMoney.nequi) {
      setErrors({
        ...form,
        ["nequi"]: "Supera el Monto",
      });
      int++;
    }
    if (request.datafono > 0 && request.datafono > currentMoney.datafono) {
      setErrors({
        ...form,
        ["datafono"]: "Supera el Monto",
      });
      int++;
    }

    form.isNegative = isNegative

    if (int === 0) {
      onSuccess(form, request);
    } else {
      if (window.confirm("No tienes Disponible para realizar la operación, ¿Deseas continuar?")) {
        onSuccess(form, request);
      }
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <h4>Resumen de Pago</h4>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <div className="form-group">
              <label>Fecha de Inicio</label>
              <input
                type={"date"}
                className="form-control"
                name="date_init"
                value={form.date_init}
                onChange={updateInput}
              />
            </div>
          </Col>
          <Col>
            <div className="form-group">
              <label>Fecha Final</label>
              <input
                type={"date"}
                className="form-control"
                name="date_end"
                value={form.date_end}
                onChange={updateInput}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="text-danger text-center">
            {Object.keys(errors).length > 0 ? (
              <h5>Los montos solicitados no esta disponibles</h5>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <table className="table table-sm table-bordered">
              <thead>
                <tr align="center">
                  <th>Descripción</th>
                  <th>Monto Requerido</th>
                  <th>Monto Disponible {selected.text}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  className={`${errors.cash !== undefined ? "table-danger" : ""
                    }`}
                >
                  <td>EFECTIVO</td>
                  <td align="right">${formatNumber(request.cash)}</td>
                  <td
                    align="right"
                    className={`${request.cash > currentMoney.cash
                      ? "text-danger"
                      : "text-success"
                      }`}
                  >
                    {formatNumber(currentMoney.cash)}
                  </td>
                </tr>
                <tr>
                  <td>DAVIPLATA</td>
                  <td align="right">${formatNumber(request.daviplata)}</td>
                  <td
                    align="right"
                    className={`${request.daviplata > currentMoney.daviplata
                      ? "text-danger"
                      : "text-success"
                      }`}
                  >
                    {formatNumber(currentMoney.daviplata)}
                  </td>
                </tr>
                <tr>
                  <td>DATAFONO</td>
                  <td align="right">${formatNumber(request.datafono)}</td>
                  <td
                    align="right"
                    className={`${request.datafono > currentMoney.datafono
                      ? "text-danger"
                      : "text-success"
                      }`}
                  >
                    {formatNumber(currentMoney.datafono)}
                  </td>
                </tr>
                <tr>
                  <td>BANCOLOMBIA</td>
                  <td align="right">${formatNumber(request.nequi)}</td>
                  <td
                    align="right"
                    className={`${request.nequi > currentMoney.nequi
                      ? "text-danger"
                      : "text-success"
                      }`}
                  >
                    {formatNumber(currentMoney.nequi)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>TOTAL A PAGAR</b>
                  </td>
                  <td align="right">
                    <b>${formatNumber(total)}</b>
                  </td>
                  <td align="right">0</td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Container fluid>
          <Row>
            <Col className="d-flex justify-content-center">
              <button className="btn btn-secondary" onClick={onHide}>
                Cerrar
              </button>
            </Col>
            <Col className="d-flex justify-content-center">
              <button
                className="btn btn-info"
                onClick={() => onSuccessBefore()}
                disabled={selected ? false : true}
              >
                Confirmar
              </button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
};

const mapsState = (props) => {
  let { report } = props;

  return {
    data: report.data,
    fetching_review: report.fetching,
  };
};

export default connect(mapsState, { getReviewCash })(ModalConfirmPayroll);
