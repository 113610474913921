import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  getProductById, initDuck, addModifierProduct, deleteGroup,
  editModifierProduct, deleteProduct, editModifierProductHeader, createProduct
} from '../../Redux/productDuck'

import { getCategories } from '../../Redux/categoryDuck';
import { getBranch } from '../../Redux/branchofficeDuck';
import { getGroups } from '../../Redux/modifierDuck';
import { toogleAlert } from '../../Redux/alertDuck';
import { Container, Row, Col } from 'react-bootstrap';
import FormProduct from './FormProduct';

import { ToastProvider, useToasts, removeAllToasts } from 'react-toast-notifications';
import TemplateMenu from '../../template/menu.template'


const EditProduct = ({ match, getProductById, getCategories, getGroups, getBranch, fetching_by_id, toogleAlert, selected,
  fetching_category, categories, fetching_branch, branchs, fetching_groups, groups, createProduct, fetching_edit, addModifierProduct }) => {

  let id = match.params.id

  const [listCategory, setListCategory] = useState([])
  const [listGroup, setListGroup] = useState([])
  const [listGroupExt, setListGroupExt] = useState([])
  const [listBranch, setListBranch] = useState([])
  const [modifiers, setModifiers] = useState([])
  const [form, setForm] = useState({})
  const [group, setGroup] = useState(0)

  const { addToast } = useToasts();


  useEffect(() => {
    getCategories()
    getGroups()
    getBranch()
  }, [])

  useEffect(() => {
    if (fetching_by_id === 2) {
      toogleAlert(false)

      setForm(selected)

      // setModifiers(selected.modifier_group)
      // setListBranch(selected.product_brand)

      console.log('selected', selected)

      
    }
  }, [fetching_by_id])


  useEffect(() => {
    if (fetching_edit === 2) {
      toogleAlert(false)
      addToast('Guardado con Exito', { appearance: 'success', autoDismiss: true });

    }
  }, [fetching_edit])


  useEffect(() => {
    getProductById(id)
  }, [id])


  useEffect(() => {

    if (fetching_category === 2) {
      setListCategory(categories)
    }

  }, [fetching_category])

  useEffect(() => {

    if (fetching_branch === 2) {
      setListBranch(branchs)
    }

  }, [fetching_branch])

  useEffect(() => {

    if (fetching_groups === 2) {
      setListGroup(groups)
    }

  }, [fetching_groups])


  const onUpdateGroup = (e) => {

    setGroup(e.target.value)
  }


  const onSaveModifier = () => {

    if (group === 0) {
      addToast('Selecciona un grupo', { appearance: 'error' });
      return;
    }
    addModifierProduct(form, group)
  }


  const onSubmit = (form) => {
    createProduct(form)
    toogleAlert(true)
  }

  return (
    <TemplateMenu fluid>
      <Row className="p-3">
        <Col>
          <FormProduct
            onSubmit={onSubmit}
            categories={listCategory}
            listBrand={listBranch}
            setListBranch={setListBranch}
            branchs={listBranch}
            mod={listGroup}
            updateGroup={onUpdateGroup}
            onSaveModifier={onSaveModifier}
            formEdit={form} />
        </Col>

      </Row>
    </TemplateMenu>
  )
}

const mapState = (props) => {


  return {
    fetching_by_id: props.product.fetching_by_id,
    fetching_edit: props.product.fetching_edit,
    selected: props.product.selected,
    fetching_category: props.category.fetching_list,
    categories: props.category.data,
    fetching_branch: props.branchoffice.fetching,
    branchs: props.branchoffice.data,
    groups: props.modifier.data_group,
    fetching_groups: props.modifier.fetching_list,
  }
}

getCategories()
export default connect(mapState, { getProductById, getBranch, getCategories, getGroups, toogleAlert, createProduct, addModifierProduct })(withRouter(EditProduct));