import React, { useEffect, useState } from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { onUpdateRegistrationDate, onCreateRegistrationDate } from '../../Redux/IngresosDuck'
import { toogleLoader } from '../../Redux/alertDuck'
import { connect } from 'react-redux';
import moment from 'moment';
import { formatNumber } from '../../utils';
import { MdAdd, MdCheck, MdPlusOne } from 'react-icons/md';
import ModalConfirmation from '../Components/modal.confirmation';

const ModalRegistrationDate = ({ show, formEdit, title, onUpdateRegistrationDate, onHide, fetching_registration,
    errors, onEditSuccess, onCreateSuccess, toogleLoader, onCreateRegistrationDate, fetching_create_registration,
    row, data_calendar, fetching_list }) => {
    const [form, setForm] = useState({ registration_date: moment().format("YYYY-MM-DD") })

    const [listCalendar, setListCalendar] = useState([]);
    const [modalConfirm, setModalConfirm] = useState(false)

    useEffect(() => {
        if (Object.keys(formEdit).length > 0) {
            setForm({ ...form, "registration_date": formEdit.registration_date })
        }

    }, [formEdit])

    useEffect(() => {

        if (fetching_list === 2) {
            setListCalendar(data_calendar)

        }
    }, [fetching_list]);

    useEffect(() => {
        if (fetching_registration === 2) {
            toogleLoader(false)
            onEditSuccess()
        }
        if (fetching_registration === 3) {
            toogleLoader(false)
        }

    }, [fetching_registration])

    useEffect(() => {
        if (fetching_create_registration === 2) {
            toogleLoader(false)
            onCreateSuccess(row)
        }
        if (fetching_create_registration === 3) {
            toogleLoader(false)
        }

    }, [fetching_create_registration])


    const onSave = () => {
        if (Object.keys(formEdit).length > 0) {
            if (form.registration_date === formEdit.registration_date) {
                alert("Las fechas son Iguales");
                return;
            }

            let _form = formEdit
            _form.registration_date_new = form.registration_date;
            toogleLoader(true)
            onUpdateRegistrationDate(_form)
        } else {
            onCreateRegistrationDate(form)
        }
    }

    const onConfirmClose = () => {
        toogleLoader(true)
        setModalConfirm(false)
        if (Object.keys(formEdit).length > 0) {
            let _form = formEdit
            _form.registration_date_new = form.registration_date;
            onUpdateRegistrationDate(_form)
        } else {
            onCreateRegistrationDate(form)
        }
    }

    const onGoToDetail = (row) => {
        if (row.blocked === false) {
            setForm({
                ...form,
                ["registration_date"]: row.date_text
            })
            setModalConfirm(true)
        }
    }

    return (
        <>
            <Modal show={show} onHide={onHide} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            {/* <div className='form-group'>
                            <label>Fecha de Cierre</label>
                            <input className='form-control' type='date' name="registration_date" value={form.registration_date}
                                onChange={(e) => setForm({ ...form, [e.target.name]: e.target.value })} />
                            {errors.registration_date !== undefined && (<label className="error-label">{errors.registration_date}</label>)}
                        </div> */}

                            <div className="container-calendar-ingresos-cierres">
                                <div className="container-box-ingresos-cierres">Domingo</div>
                                <div className="container-box-ingresos-cierres">Lunes</div>
                                <div className="container-box-ingresos-cierres">Martes</div>
                                <div className="container-box-ingresos-cierres">Miercoles</div>
                                <div className="container-box-ingresos-cierres">Jueves</div>
                                <div className="container-box-ingresos-cierres">Viernes</div>
                                <div className="container-box-ingresos-cierres">Sabado</div>
                                {listCalendar.map((d, k) =>
                                    <div key={k} className={`container-box-ingresos-cierres
                                    ${d.current === true ? 'alert-success' : ''}
                                    ${(!isNaN(d.day) && d.registration_date !== undefined) ? 'alert-secondary' : 'cursor-pointer'}`} onClick={() => onGoToDetail(d)}>
                                        <div className={`container-title-ingresos`}>{d.day}</div>
                                        {d.day === '' ? '' : (d.blocked === true ? <div className="circle-sales-ingresos">
                                            <MdCheck size={35} />
                                        </div> : <div className="circle-sales-ingresos cursor-pointer">
                                            <MdAdd size={35} color='green' onClick={() => onGoToDetail(d)} />
                                        </div>)}
                                    </div>)}
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Container fluid>
                        <Row>
                            <Col className='d-flex justify-content-center'>
                                <button className='btn btn-success' onClick={onSave}>Guardar</button>
                            </Col>
                        </Row>
                    </Container> */}

                </Modal.Footer>
            </Modal >
            <ModalConfirmation
                show={modalConfirm}
                text={
                    <span>
                        {`¿Deseas Realizar el Cierre de`}
                        <br />
                        <h2>{`${moment(form.registration_date).format("dddd D [de] MMMM YYYY")}?`.toUpperCase()}</h2>
                    </span>
                }
                onHide={() => setModalConfirm(false)}
                onSubmit={onConfirmClose}
            />
        </>
    )
}

const mapsState = (props) => {
    let { ingresos } = props

    return {
        fetching_registration: ingresos.fetching_registration,
        fetching_create_registration: ingresos.fetching_create_registration,
        row: ingresos.row,
        errors: ingresos.errors,
        data_calendar: ingresos.calendar,
        fetching_list: ingresos.fetching_list,
    }
}

export default connect(mapsState, { onUpdateRegistrationDate, toogleLoader, onCreateRegistrationDate })(ModalRegistrationDate);