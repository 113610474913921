import React, { useState, useEffect } from 'react'
import { Modal, Container, Row, Col, Button } from 'react-bootstrap';
import { MdClose } from "react-icons/md";

import InputField from '../Components/Widgets/InputField';
import { connect } from 'react-redux';
import { createParameter } from '../../Redux/parameterDuck'

const initState = { title: '', description: '', priority: '' };
const ModalParameter = ({ showModal, onHide, errors, selected, fetching, createParameter, onSuccess, formEdit }) => {


    const [form, setForm] = useState(initState)

    useEffect(() => {

        if (fetching === 2) {
            onSuccess()
            onHide()
        }


    }, [fetching])

    useEffect(() => {
        if (formEdit !== undefined) {
            setForm(formEdit)
        }

    }, [formEdit])

    const closeModalTmp = () => {
        onHide()
    }

    const updateInput = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const updateCheck = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.checked
        })
    }

    const onSubmit = () => {
        createParameter(form)
    }


    return (
        <Modal show={showModal} onHide={closeModalTmp}>
            <div className="modal-header border-bottom-custom">
                <h5 className="modal-title text-center">Parametros</h5>
                <button type="button" className="close p-0 m-0" data-dismiss="modal" aria-label="Close" onClick={closeModalTmp}>
                    <MdClose />
                </button>
            </div>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Descripción Parametro</label>
                                <InputField
                                    placeholder="Descripcion de la Parametro"
                                    value={form.description || ''}
                                    name="description"
                                    onChange={updateInput}
                                    error={errors.description}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Segmento</label>
                                <InputField
                                    placeholder="Segmento"
                                    value={form.segment || ''}
                                    name="segment"
                                    onChange={updateInput}
                                    error={errors.description}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Orden</label>
                                <InputField
                                    placeholder="Orden"
                                    value={form.priority || ''}
                                    name="priority"
                                    onChange={updateInput}
                                    error={errors.priority}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Estado</label>
                                <input
                                    type="checkbox"
                                    className="form-control"
                                    checked={form.status || false}
                                    name="status"
                                    onChange={updateCheck}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={closeModalTmp}>Cerrar</Button>
                <Button variant="primary" onClick={onSubmit}>Guardar</Button>
            </Modal.Footer>
        </Modal>
    )
}

const mapsState = (props) => {
    console.log("props", props.parameter)
    return {
        errors: props.parameter.errors,
        selected: props.parameter.selected,
        fetching: props.parameter.fetching_create
    }
}

export default connect(mapsState, { createParameter })(ModalParameter);