import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import TemplateMenu from '../../template/menu.template'

import { getCouponById, onUpdateInvoice } from '../../Redux/couponDuck'
import { updateClient } from '../../Redux/ClientDuck'
import ItemCouponClient from './item.coupon.client'
import ModalClient from './modal.client'

const EditCouponPage = ({ getCouponById, fetching_by_id, row, selected, onUpdateInvoice, fetching_create, fetching_asign, updateClient, fetching_update_client }) => {
    const [data, setData] = useState({})
    const [list, setList] = useState([])
    const [listBk, setListBk] = useState([])
    const [modal, setModal] = useState(false)
    const [client, setClient] = useState({})

    const [listFiltered, setListFiltered] = useState([])
    let { id } = useParams();

    useEffect(() => {

        getCouponById(id)

    }, [id])


    useEffect(() => {
        if (fetching_create === 2) {
            getCouponById(id)
        }
    }, [fetching_create])
    useEffect(() => {
        if (fetching_update_client === 2) {
            setModal(false)
            setClient({})
            getCouponById(id)

        }
    }, [fetching_update_client])

    useEffect(() => {
        if (fetching_asign === 2) {
            let _list = list.map(l => {
                if (l.id === selected.id) {
                    l.invoice = '';
                }

                return l;
            })
            setList(_list)
        }
    }, [fetching_asign])

    useEffect(() => {
        if (fetching_by_id === 2) {
            setData(row)
            setList(row.detail)
            setListFiltered(row.detail)
            //
        }
    }, [fetching_by_id])

    const onSaveInvoice = (item) => {
        setListBk(list)
        onUpdateInvoice(item)
    }

    const onSearch = (e) => {

        if (e.target.value === '') {
            setListFiltered(list)
        } else {
            let _list = list.filter(l => l.client.business.includes(e.target.value)
                || l.client.email.includes(e.target.value)
                || l.client.phone.includes(e.target.value)
                || l.code.includes(e.target.value))
            setListFiltered(_list)
        }


    }

    const onOpenModalClient = (client) => {
        setModal(true)
        setClient(client)
    }

    const onEditClient = (form) => {
        updateClient(form)
    }


    return (
        <TemplateMenu>
            <Row className='d-flex justify-content-center'>
                <Col lg={4}>
                    <table className='table table-sm table-bordered'>
                        <thead className='thead-dark'>
                            <tr>
                                <th>Descripción</th>
                                <th>Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Nombre</td>
                                <td>{data.name}</td>
                            </tr>
                            <tr>
                                <td>Código</td>
                                <td>{data.code}</td>
                            </tr>
                            <tr>
                                <td>Cantidad</td>
                                <td>{data.amount}</td>
                            </tr>
                            <tr>
                                <td>Valor</td>
                                <td>{data.value}</td>
                            </tr>
                        </tbody>
                    </table>
                </Col>

            </Row>
            <Row className='d-flex justify-content-center'>
                <Col lg={10}>
                    <Row className='mb-2 d-flex justify-content-end'>
                        <Col lg={2}>
                            <input className='form-control input-sm' placeholder='Buscar' onChange={onSearch} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <table className='table table-sm table-bordered'>
                                <thead className='thead-dark'>
                                    <tr>
                                        <th>Cliente</th>
                                        <th>Email</th>
                                        <th>Celular</th>
                                        <th>Código</th>
                                        <th>Fecha de Registro</th>
                                        <th>Fecha de Redención</th>
                                        <th>Estado</th>
                                        <th>Factura</th>
                                        <th>Asignar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listFiltered.length > 0 ? listFiltered.map(l =>
                                        <ItemCouponClient key={l.id} item={l}
                                            onSuccess={onSaveInvoice}
                                            showModalClient={onOpenModalClient}
                                        />) : <tr><td>No se encontraron Registros</td></tr>}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <ModalClient show={modal} client={client} onHide={() => setModal(false)} onSuccess={onEditClient} />

        </TemplateMenu>

    )
}

const mapsState = (props) => {
    let { coupon, client } = props
    return {
        fetching_by_id: coupon.fetching_by_id,
        fetching_create: coupon.fetching_create,
        row: coupon.row,
        fetching_asign: coupon.fetching_asign,
        selected: coupon.selected,
        fetching_update_client: client.fetching_update_client,

    }
}

export default connect(mapsState, { getCouponById, onUpdateInvoice, updateClient })(EditCouponPage);