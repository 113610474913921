import React, { useState, useEffect } from 'react'
import { Modal, Container, Row, Col, Button } from 'react-bootstrap';
import { MdClose } from "react-icons/md";

import InputField from '../Components/Widgets/InputField';
import { connect } from 'react-redux';
import { createIngresos } from '../../Redux/IngresosDuck'
import BtnOrangeSm from '../Components/Widgets/BtnOrangeSm'
import BtnBlueModal from '../Components/Widgets/BtnBlueModal'
import BlueTitleBack from '../Components/Widgets/BlueTitleBack'
import chooseimage from './../../Images/no_available.png'
import uuid from 'react-uuid'
import { formatNumber } from '../../utils';
import InputFieldCurrency from '../Components/Widgets/InputFieldCurrency';



const ModalAddTurn = ({ showModal, onHide, selected, fetching, createIngresos, title, typeData, onSubmit, listWorkerTurn, onOpenParameter }) => {
    const initState = { amount: '', worker_id: '', text: '', type: typeData }
    const [errors, setErrors] = useState({})
    const [form, setForm] = useState(initState)


    useEffect(() => {
        if (fetching === 2) {
            onHide()
        }


        if (Object.keys(selected).length > 0) {
            selected.status_id = (selected.status_id === 2) ? false : true;
            setForm(selected);

        }
        else {
            if (fetching !== 3 && fetching !== 2) {
                setForm(initState)
            }
        }
    }, [fetching, selected])

    const closeModalTmp = () => {
        setErrors({})
        onHide()
    }

    const updateInput = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const updateInputSelect = (e) => {
        let param = listWorkerTurn.filter(row => row.id === parseInt(e.target.value))[0]
        setForm({
            ...form,
            [e.target.name]: e.target.value,
            ["text"]: param.description
        })
    }

    const printWorker = (row) => {
        return <option key={row.id} value={row.id}>{row.name}</option>
    }

    const onSubmitTmp = () => {

        let _form = form
        _form.worker = listWorkerTurn.filter(row => row.id === parseInt(form.worker_id))[0]
        _form.type_data = typeData
        _form._id = uuid();
        _form.amount_formated = "$ " + formatNumber(_form.amount)
        _form.amount_real = 0;
        _form.amount_real_formated = "$ 0";

        let validate = false
        let err = {}

        if (form.worker_id === '') {
            err.worker_id = "Turnero Requerido"
            validate = true;
        }

        if (form.amount === '') {
            err.amount = "Valor Requerido"
            validate = true;
        }

        if (validate === false) {
            form.is_turn = true;
            onSubmit(form)
            setForm(initState)
        } else {
            setErrors(err)
        }
    }

    return (
        <Modal centered show={showModal} onHide={closeModalTmp}>
            <div className="modal-header border-bottom-custom mt-2">
                <BlueTitleBack title={"TURNEROS"} />
                <button type="button" className="close p-0 m-0" data-dismiss="modal" aria-label="Close" onClick={closeModalTmp}>
                    <MdClose />
                </button>
            </div>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <Col>
                            <Row className='d-flex justify-content-center'>
                                <Col>
                                    <div className="form-group">
                                        <label>Turnero</label>
                                        <select className="form-control" name="worker_id" value={form.worker_id} onChange={updateInputSelect}>
                                            <option>Seleccione</option>
                                            {listWorkerTurn.map(printWorker)}
                                        </select>
                                        {errors.parameter_id !== undefined && (<label className="error-label">{errors.worker_id}</label>)}

                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Valor</label>
                                        <InputFieldCurrency
                                            name={"amount"}
                                            placeholder={title}
                                            error={errors.amount}
                                            onValueChange={(value, name) =>
                                                setForm({ ...form, ["amount"]: value })
                                            }
                                        />

                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Container>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Row >
                    <Col>
                        <button className='btn btn-danger' onClick={closeModalTmp}>Cerrar</button>
                    </Col>
                    <Col>
                        <button className='btn btn-success' onClick={onSubmitTmp}>Guardar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal >
    )
}

const mapsState = (props) => {
    return {
        errors: props.ingresos.errors,
        selected: props.ingresos.selected,
        fetching: props.ingresos.fetching_create
    }
}

export default connect(mapsState, { createIngresos })(ModalAddTurn);