import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap';
import { MdClose } from "react-icons/md";

import BlueTitleBack from './Widgets/BlueTitleBack';


const ModalConfirmation = ({ show, title, text, onHide, onSubmit }) => {
    return <Modal show={show} size='md' onHide={onHide}>
        <div className="modal-header border-bottom-custom mt-2">
            <BlueTitleBack title={title} />
            <button type="button" className="close p-0 m-0" data-dismiss="modal" aria-label="Close" onClick={onHide}>
                <MdClose />
            </button>
        </div>
        <Modal.Body>
            <Row>
                <Col className='text-center'><h4>{text}</h4></Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
            <Row className='w-100 d-flex justify-content-center'>
                <Col lg={4}>
                    <button className='btn btn-danger form-control' onClick={onHide}>No</button>
                </Col>
                <Col lg={4}>
                    <button className='btn btn-success form-control' onClick={onSubmit}>Si</button>
                </Col>
            </Row>
        </Modal.Footer>
    </Modal>
}

export default ModalConfirmation;