import React from 'react'

const ItemTableSupplier = ({ item, edit }) => {
    return (
        <tr>
            <td>{item.business}</td>
            <td>{item.business_name}</td>
            <td>{item.document}</td>
            <td>{item.address}</td>
            <td>{item.email}</td>
            <td>{item.status_text}</td>
            <td>
                <button className="btn btn-info mr-3 btn-sm" onClick={() => edit(item)}>Editar</button>
            </td>
        </tr>
    )
}

export default ItemTableSupplier;