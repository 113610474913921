import axios from 'axios'
import { getHeaders } from '../utils'
import { logOutAction } from './userDuck'

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_drawer: 0,
    fetching_filter: 0,
    data: [],
    errors: '',
    drawer: true,
    branch: {},
}

const MENU = "MENU"
const MENU_SUCCESS = "MENU_SUCCESS"
const MENUPDF = "MENUPDF"
const MENUPDF_SUCCESS = "MENUPDF_SUCCESS"
const TOOGLE_DRAWER = "TOOGLE_DRAWER"
const SET_FILTER = "SET_FILTER"
const SET_FILTER_SUCCESS = "SET_FILTER_SUCCESS"
const GET_FILTER = "GET_FILTER"
const GET_FILTER_SUCCESS = "GET_FILTER_SUCCESS"
const SET_FILTER_DATE = "SET_FILTER_DATE"
const SET_FILTER_DATE_SUCCESS = "SET_FILTER_DATE_SUCCESS"


export default function reducer(state = initialState, action) {

    switch (action.type) {
        case MENU: {
            return { ...state, fetching: 1 };
        }
        case MENU_SUCCESS: {
            return { ...state, fetching: 2, data: action.payload };
        }
        case MENUPDF: {
            return { ...state, fetching: 1 };
        }
        case MENUPDF_SUCCESS: {
            return { ...state, fetching: 2 };
        }
        case TOOGLE_DRAWER: {
            return { ...state, drawer: action.payload };
        }
        case SET_FILTER: {
            return { ...state, fetching_filter: 1 };
        }
        case SET_FILTER_SUCCESS: {
            return { ...state, fetching_filter: 2, branch: action.payload };
        }
        case GET_FILTER: {
            return { ...state, fetching_filter: 1 };
        }
        case GET_FILTER_SUCCESS: {
            return { ...state, fetching_filter: 2, filter: action.payload };
        }
        default:
            return state;
    }
}

export let onToogleDrawer = () => (dispatch, getState) => {
    let payload = !getState().menu.drawer;
    dispatch({
        type: TOOGLE_DRAWER,
        payload
    });
}

export let onGetOrSetFilter = (branch) => (dispatch, getState) => {

    dispatch({
        type: SET_FILTER,
    });

    let _branch = branch

    if (branch !== undefined) {
        branch.filter = `branch=${branch.id}`;
        localStorage.setItem("branch", JSON.stringify(_branch))
    } else {
        _branch = { id: 0, filter: `branch=0` }
        localStorage.setItem("branch", JSON.stringify(_branch))
    }

    dispatch({
        type: SET_FILTER_SUCCESS,
        payload: _branch
    });
}

export let onGetFilter = () => (dispatch, getState) => {

    dispatch({
        type: GET_FILTER,
    });

    let branch = JSON.parse(localStorage.getItem("branch"));

    dispatch({
        type: GET_FILTER_SUCCESS,
        payload: branch.filter
    });
}


export let getMenu = () => (dispatch, getState) => {
    dispatch({
        type: MENU
    });

    let url = `${API_URL}/api/menu`

    let storage = JSON.parse(localStorage.getItem("menu"))

    if (storage == null) {
        axios.get(url,
            {
                headers: getHeaders()
            }).then(resp => {

                dispatch({
                    type: MENU_SUCCESS,
                    payload: resp.data.results
                });

                localStorage.setItem("menu", JSON.stringify(resp.data.results));

            }).catch(function (error) {
                let { status } = error.response;

                if (status === 401) {
                    logOutAction()(dispatch, getState)
                }

            });
    } else {
        dispatch({
            type: MENU_SUCCESS,
            payload: storage
        });
    }
}


export let uploadMenuPdf = (form) => (dispatch, getState) => {

    dispatch({
        type: MENUPDF
    });


    let url = `${API_URL}/api/menu-pdf`;
    console.log('llego', url)


    axios.post(url, form, {
        headers: getHeaders()

    }).then(resp => {

    });
}