import React, { useEffect, useState } from "react";
import { Col, Container, ListGroup, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { getWorkers } from "../../Redux/workerDuck";
import { onSearchData, onSaveTip } from "../../Redux/tipDuck";
import ItemConfig from "./item.config";
import moment from "moment";
import { formatNumber } from "../../utils";
import { useHistory } from "react-router-dom";
import TemplateMenu from "../../template/menu.template";
import { AiFillEdit } from "react-icons/ai";

const CreateTipsPage = ({
  getWorkers,
  onSearchData,
  fetching_search,
  data_search,
  onSaveTip,
  fetching_create,
}) => {
  const [form, setForm] = useState({
    date_init: moment().format("YYYY-MM-01"),
    date_end: moment().format("YYYY-MM-DD"),
    branch_id: 0,
  });
  const [listConfig, setListConfig] = useState([]);
  const [total, setTotal] = useState(0);
  const [allow, setAllow] = useState(0);
  const [totalAlt, setTotalAlt] = useState(0);
  const [showEdit, setShowEdit] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  const history = useHistory();

  useEffect(() => {
    getWorkers();
    onSearchData();
  }, []);

  useEffect(() => {
    if (fetching_search === 2) {
      let _total =
        data_search.altern !== 0 ? data_search.altern : data_search.tips;
      setTotal(_total);
      setAllow(data_search.allowed);
      setListConfig(data_search.parameter);
    }
  }, [fetching_search]);

  useEffect(() => {
    if (fetching_create === 2) {
      history.push(`/propinas/${data_search.id}`);
    }
  }, [fetching_create]);

  const onSubmit = () => {
    onSaveTip(total);
  };

  const updateInput = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const onSearch = () => {
    setListConfig([]);
    setShowDetail(false);
    onSearchData(form);
  };

  const onEdit = () => {
    setTotal(totalAlt);
    setShowEdit(false);
  };

  let total_percent = listConfig.reduce(
    (a, b) => (a = a + parseFloat(b.configuration.value || 0)),
    0
  );
  let total_list = listConfig.reduce(
    (a, b) => (a = a + parseFloat((b.configuration.value / 100) * total)),
    0
  );
  let total_workers = listConfig.reduce(
    (a, b) => (a = a + b.workers.filter((w) => w.type_worker === 1).length),
    0
  );
  let total_turns = listConfig.reduce(
    (a, b) => (a = a + b.workers.filter((w) => w.type_worker === 2).length),
    0
  );

  return (
    <TemplateMenu fluid>
      <Row className="my-3">
        <Col lg={1}>
          <button
            className="btn btn-secondary"
            onClick={() => history.push("/propinas")}
          >
            Volver
          </button>
        </Col>
        <Col lg={8}>
          <button className="btn btn-success" onClick={() => onSubmit()}>
            Guardar
          </button>
        </Col>
        <Col lg={3}>
          <Row>
            <Col>
              <h5>TOTAL PROPINAS</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              {showEdit ? (
                <input
                  className="form-control"
                  value={totalAlt}
                  onChange={(e) => setTotalAlt(e.target.value)}
                />
              ) : (
                <h2>${formatNumber(total)}</h2>
              )}
            </Col>
            <Col className="d-flex align-items-center">
              {showEdit ? (
                <button className="btn btn-info btn-sm" onClick={onEdit}>
                  Editar
                </button>
              ) : (
                <AiFillEdit
                  className="cursor-pointer"
                  onClick={() => {
                    setTotalAlt(total);
                    setShowEdit(true);
                  }}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col>TOTAL AUTORIZADO</Col>
          </Row>
          <Row>
            <Col>${formatNumber(allow)}</Col>
          </Row>
        </Col>
        {/* <Col lg={3} className="d-flex justify-content-end">
                    <button className="btn btn-success" onClick={() => onSubmit()}>Guardar y finalizar</button>
                </Col> */}
      </Row>
      <Row className="my-2">
        <Col lg={6}>
          {/* <Row className='my-2'>
                        <Col lg={4}>
                            <input className='form-control input-sm' name='date_init' type={"date"} onChange={updateInput} value={form.date_init} /></Col>
                        <Col lg={4}>
                            <input className='form-control input-sm' name='date_end' type={"date"} onChange={updateInput} value={form.date_end} />
                        </Col>
                        <Col lg={3}>
                            <button className='btn btn-info' onClick={onSearch}>Buscar</button>
                        </Col>
                    </Row> */}
        </Col>
      </Row>

      <Row className="my-2">
        <Col lg={12}>
          <table className="table table-bordered table-condensed table-sm">
            <thead className="thead-dark">
              <tr>
                <th></th>
                <th>Areas</th>
                <th>Porcentaje</th>
                <th>Equivalencia</th>
                <th># Trabajadores</th>
                <th>Total a pagar a Trabajadores</th>
                <th># Turneros</th>
                <th>Total a Pagar a Turneros</th>
                <th>Pago por Trabajador</th>
              </tr>
            </thead>
            <tbody>
              {listConfig.length === 0 ? (
                <tr>
                  <td colSpan={9} align="center">
                    No se encontraron Registros
                  </td>
                </tr>
              ) : (
                listConfig.map((c) => (
                  <ItemConfig key={c.id} item={c} total={total} />
                ))
              )}
              <tr>
                <td colSpan={2}>TOTALES</td>
                <td>{formatNumber(total_percent) || 0}%</td>
                <td align="right">{formatNumber(total_list) || 0}</td>
                <td align="right">{formatNumber(total_workers) || 0}</td>
                <td></td>
                <td align="right">{formatNumber(total_turns) || 0}</td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
    </TemplateMenu>
  );
};

const mapsState = (props) => {
  let { tip } = props;
  return {
    fetching_search: tip.fetching_search,
    fetching_create: tip.fetching_create,
    data_search: tip.data,
  };
};

export default connect(mapsState, { getWorkers, onSearchData, onSaveTip })(
  CreateTipsPage
);
