import React from 'react'
import ColDiningTable from './col.dining.table';

const RowDiningTable = ({ item, onSelectDining }) => {

    return (
        <div className='row-dining'>
            {item.map((i, k) => <ColDiningTable item={i} key={k} onSelectDining={onSelectDining} />)}
        </div>
    )
}

export default RowDiningTable;