import React from 'react';
import { MdOutlineModeEditOutline } from 'react-icons/md';


const RowTableUser = ({ item, editUser }) => {
  let { branch_office, rol } = item

  return (
    <>
      <tr>
        <td>{item.name}</td>
        <td>{item.email}</td>
        <td>{item.phone}</td>
        <td>{(branch_office !== null) ? branch_office.title : ''}</td>
        <td>{(rol !== null) ? rol.description : ''}</td>
        <td>{item.status_text}</td>
        <td className="text-center">
          <MdOutlineModeEditOutline className='cursor' onClick={() => editUser(item)} />
        </td>
      </tr>
    </>
  )
}
export default RowTableUser;