import { getHeaders } from '../utils';

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    fetching_create: 0,
    fetching_by_id: 0,
    data: [],
    row: {},
    errors: {}
}

let LIST_PRODUCTION = "LIST_PRODUCTION";
let LIST_PRODUCTION_SUCCESS = "LIST_PRODUCTION_SUCCESS";
let CREATE_PRODUCTION = "CREATE_PRODUCTION";
let CREATE_PRODUCTION_SUCCESS = "CREATE_PRODUCTION_SUCCESS";
let PRODUCTION_BY_ID = "PRODUCTION_BY_ID";
let PRODUCTION_BY_ID_SUCCESS = "PRODUCTION_BY_ID_SUCCESS";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_PRODUCTION:
            return { ...state, fetching_list: 1, fetching_by_id: 0, fetching_create: 0 }
        case LIST_PRODUCTION_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        case CREATE_PRODUCTION:
            return { ...state, fetching_create: 1 }
        case CREATE_PRODUCTION_SUCCESS:
            return { ...state, fetching_create: 2 }
        case PRODUCTION_BY_ID:
            return { ...state, fetching_by_id: 1 }
        case PRODUCTION_BY_ID_SUCCESS:
            return { ...state, fetching_by_id: 2, row: action.payload }
        default:
            return state;
    }
}

export let getListProduction = () => (dispatch, getState) => {
    dispatch({
        type: LIST_PRODUCTION,
    });


    let branch = JSON.parse(localStorage.getItem("branch"))
    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))

    let url = `${API_URL}/api/production?date_init=${date_init}&date_end=${date_end}&branch_id=${branch.id}`;

    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: LIST_PRODUCTION_SUCCESS,
            payload: resp.results
        });

    })
}

export let getProductionById = (id) => (dispatch, getState) => {
    dispatch({
        type: PRODUCTION_BY_ID,
    });

    let url = `${API_URL}/api/production/${id}`;
    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: PRODUCTION_BY_ID_SUCCESS,
            payload: resp
        });

    })
}

export let onCreateProduction = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_PRODUCTION,
    });

    let url = `${API_URL}/api/production`;

    let branch = JSON.parse(localStorage.branch)
    let { date_init, date_end } = JSON.parse(localStorage.date)

    form.branch_id = branch.id
    form.date_init = date_init
    form.date_end = date_end

    fetch(url, {
        method: "POST",
        body: JSON.stringify(form),
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: CREATE_PRODUCTION_SUCCESS,
        });

    })
}

export let onUpdateProduction = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_PRODUCTION,
    });

    let url = `${API_URL}/api/production/${form.id}`;

    let branch = JSON.parse(localStorage.branch)

    form.branch_id = branch.id

    fetch(url, {
        method: "PUT",
        body: JSON.stringify(form),
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: CREATE_PRODUCTION_SUCCESS,
        });

    })
}

export let onDeleteProduction = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_PRODUCTION,
    });

    let branch = JSON.parse(localStorage.branch)
    let { date_init, date_end } = JSON.parse(localStorage.date)

    let url = `${API_URL}/api/production/${form.id}?date_init=${date_init}&date_end=${date_end}`;

    form.branch_id = branch.id

    fetch(url, {
        method: "DELETE",
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: CREATE_PRODUCTION_SUCCESS,
        });

    })
}