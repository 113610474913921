import React from 'react'
import { Link } from 'react-router-dom';
import { formatNumber } from '../../utils';

const ItemTableSettlement = ({ item }) => {
    let { worker } = item
    return (
        <tr>
            <td>{worker.name}</td>
            <td>{worker.document}</td>
            <td>{item.date_start_contract}</td>
            <td>{item.date_end_contract}</td>
            <td>{formatNumber(item.total)}</td>
            <td>{item.status_id_text}</td>
            <td>
                <Link to={`/liquidaciones/${item.id}`}>Editar</Link>
            </td>
        </tr>
    )
}

export default ItemTableSettlement;