import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import TemplateMenu from '../../template/menu.template'
import { getListScreen, toogleFullScreen, addImageToScreen, createScreen, deleteScreen } from '../../Redux/screenDuck'
import { connect } from 'react-redux';
import ItemPublicity from './item.publicity';
import ModalPublicity from './modal.publicity';
import ModalScreen from './modal.screen';

const PublicityPage = ({ getListScreen, fetching_list, data, toogleFullScreen, addImageToScreen, fetching_create, createScreen, deleteScreen, 
    fetching_delete }) => {

    const [list, setList] = useState([])
    const [modal, setModal] = useState(false)
    const [screen, setScreen] = useState(false)
    const [selected, setSelected] = useState({})

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
        }
    }, [fetching_list])

    useEffect(() => {
        if (fetching_delete === 2) {
            getListScreen();
        }
    }, [fetching_delete])

    useEffect(() => {
        if (fetching_create === 2) {
            setModal(false)
            setScreen(false)
            getListScreen();
        }
    }, [fetching_create])

    useEffect(() => {
        getListScreen();
    }, [])

    const onShowFullScreen = (item) => {
        toogleFullScreen(item)

        window.document.documentElement.requestFullscreen().catch(e => {
            console.log('eee', e);
            console.log('eee');
        })
    }

    const onOpenModal = (item) => {
        setSelected(item)
        setModal(true)
    }

    const onAddImage = (form) => {
        addImageToScreen(form)
    }

    const onCreateScreen = (form) => {
        createScreen(form)
    }
    const onClickDelete = (form) => {
        if (window.confirm("¿Realmente deseas Eliminar este Registro?")) {
            deleteScreen(form.id)
        }
    }

    return (
        <TemplateMenu>
            <Row className='mt-2'>
                <Col><button className='btn btn-info btn-sm' onClick={() => setScreen(true)}>Agregar Pantalla</button></Col>
            </Row>
            <Row>
                <Col lg={3}>
                    <table className='table table-sm table-hover'>
                        <thead>
                            <tr>
                                <th>Descripción</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((p, i) => <ItemPublicity item={p} key={i} onClick={onShowFullScreen} onClickAdd={onOpenModal} onClickDelete={onClickDelete} />)}
                        </tbody>
                    </table>
                </Col>
            </Row>

            <ModalPublicity show={modal} onHide={() => setModal(false)} item={selected} onSubmit={onAddImage} />
            <ModalScreen show={screen} onSubmit={onCreateScreen} />
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { screen } = props

    return {
        fetching_list: screen.fetching_list,
        fetching_create: screen.fetching_create,
        fetching_delete: screen.fetching_delete,
        data: screen.data
    }
}

export default connect(mapsState, { getListScreen, toogleFullScreen, addImageToScreen, createScreen, deleteScreen })(PublicityPage);