import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import uuid from 'react-uuid';
import { formatNumber, getHeaders } from '../../utils';
import { getLastInventory } from '../../Redux/inventoryDuck'
import { connect } from 'react-redux';

const API_URL = process.env.REACT_APP_API_URL

const initState = { amount: '' };
const initStateSelected = { measure_unit: '', cost_average: 0, inventory: { amount_total: 0 } }

const RowProductPrescription = ({ product, onCancel, onSucccess, onEditItem, getLastInventory, registration_date,
    fetching_by_id, row }) => {
    const [form, setForm] = useState(initState)
    const [type, setType] = useState('')
    const [listSearch, setListSearch] = useState([])
    const [selected, setSelected] = useState(initStateSelected)
    const [selectedProduct, setSelectedProduct] = useState({ tax: { description: '' }, measure_unit: { description: '' } });
    const [showButton, setShowButton] = useState(false)
    const [productSearch, setProductSearch] = useState('')

    useEffect(() => {
        if (Object.keys(product).length > 0) {
            setForm(product)
            setSelectedProduct(product.product)
            setShowButton(true)
            setProductSearch({ label: product.product.name, value: product.product.id });
        }

    }, [product])

    useEffect(() => {
        if (fetching_by_id === 2) {
            setSelected({
                ...selected,
                "cost_average": row.cost_average
            })

        }

    }, [fetching_by_id])

    const loadOptions = async (inputValue) => {
        let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))
        let branch = JSON.parse(localStorage.getItem("branch"))
        let url = `${API_URL}/api/product-inventory-search/${inputValue}?product=${product.value}&date_init=${date_init}&date_end=${date_end}&branch=${branch.id}`

        let _search = await fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json())

        setListSearch(_search)

        return _search;
    }

    const updateField = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSaveRow = () => {
        let { prescription } = selectedProduct

        console.log('selectedProduct', selectedProduct);

        let _form = form
        _form._id = uuid();
        _form.product_inventory = selected
        // _form.prescription_id = prescription.id
        onSucccess(_form)
    }

    return <tr>
        <td width={"30%"}>
            <AsyncSelect
                value={productSearch}
                placeholder="Busca el Producto agregar"
                loadOptions={loadOptions}
                // defaultOptions={defaultOptions}
                onChange={(e) => {
                    console.log('e----', e);

                    let _filter = listSearch.filter(s => parseInt(s.value) === parseInt(e.value))[0]
                    setSelectedProduct(_filter)
                    setShowButton(true)
                    setSelected(_filter)
                    setProductSearch({ label: _filter.name, value: _filter.id });
                    getLastInventory(e.id, registration_date)
                }} />
        </td>
        <td>
            {selected.measure_unit.description}
        </td>
        <td>
            {formatNumber(selected.cost_average)}
        </td>
        <td>
            <input type={"number"} className='form-control' name='amount' placeholder='Cantidad para la receta' value={form.amount} onChange={updateField} />
        </td>
        <td>
            {formatNumber(selected.cost_average * form.amount)}
        </td>

        {showButton && <td>

            {form._id !== undefined
                ? <button type='button' className='btn btn-sm btn-info mx-2' onClick={() => onEditItem(form)}>Editar</button> :
                <button type='button' className='btn btn-sm btn-success mx-2' onClick={() => onSaveRow(form)}>Guardar</button>
            }


            <button className='btn btn-sm btn-danger' onClick={onCancel}>Cancelar</button>
        </td>}
    </tr>
}

const mapState = (props) => {
    let { inventory } = props

    console.log('inventory', inventory);


    return {
        fetching_by_id: inventory.fetching_by_id,
        row: inventory.row,
    }

}

export default connect(mapState, { getLastInventory })(RowProductPrescription);