let initialState = {
  fetching: 0,
  message: "",
  modal_detail: false,
  loader: false,
};

let ALERT = "ALERT";
let ALERT_FINISH = "ALERT_FINISH";
let MODAL_DETAIL = "MODAL_DETAIL";
let TOOGLE_LOADER = "TOOGLE_LOADER";
let DATE_SYSTEM = "DATE_SYSTEM";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ALERT:
      return { ...state, fetching: 1, message: action.payload };
    case ALERT_FINISH:
      return { ...state, fetching: 0, message: action.payload };
    case MODAL_DETAIL:
      return { ...state, modal_detail: action.payload };
    case TOOGLE_LOADER:
      return { ...state, loader: action.payload };
    default:
      return state;
  }
}

export let toogleAlert = (show, message) => (dispatch, getState) => {
  show = show || false;
  message = message || "Espere por favor...";

  if (show) {
    dispatch({
      type: ALERT,
      payload: message,
    });
  } else {
    dispatch({
      type: ALERT_FINISH,
      payload: message,
    });
  }
};

export let toogleLoader = (status) => (dispatch, getState) => {
  let loader = !getState().alert.loader;

  dispatch({
    type: TOOGLE_LOADER,
    payload: status,
  });
};
export let toogleModalDetail = (show) => (dispatch, getState) => {
  show = show || false;

  dispatch({
    type: MODAL_DETAIL,
    payload: show,
  });
};

export let getCurrentMonth = () => (dispatch, getState) => {
  dispatch({
    type: DATE_SYSTEM,
  });
};
