import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import {
  getProductsInventory, getProductsInventoryLite, onAssocListProduct, onDeleteAssocProduct,
  getProductInventoryById, onDeleteProductInventory
} from "../../Redux/ProductInventoryDuck";
import { toogleLoader } from "../../Redux/alertDuck";
import TemplateMenu from "../../template/menu.template";
import { connect } from "react-redux";
import ItemProductInventory from "./item.product.inventory";
import FormProductRecipe from "./form.product.recipe";
import { MdClose, MdExpandLess } from "react-icons/md";
import CounterResults from "../Components/ConterResults";
import { debounceData, formatNumber } from "../../utils";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { RiFileExcel2Fill } from "react-icons/ri";
import AsyncSelect from "react-select/async";
import uuid from "react-uuid";

const API_URL = process.env.REACT_APP_API_URL;

const ProductRecipesPage = ({ getProductsInventory, fetching_list, products, toogleLoader,
  onAssocListProduct, getProductInventoryById, fetching_by_id, row, onDeleteAssocProduct,
  fetching_assoc, onDeleteProductInventory }) => {
  const [listFilter, setListFilter] = useState([]);
  const [list, setList] = useState([]);
  const [form, setForm] = useState({});
  const [order, setOrder] = useState(false);
  const [showAssoc, setShowAssoc] = useState(false)
  const [product, setProduct] = useState('')
  const [listAssoc, setListAssoc] = useState([])
  const [proportion, setProportion] = useState(100)
  const tableRef = useRef(null);
  const debounceRef = useRef();

  useEffect(() => {
    getProductsInventory();
    toogleLoader(true)
  }, []);
  useEffect(() => {
    if (fetching_list === 2) {
      setList(products);
      setListFilter(products);
      toogleLoader(false)
      setListAssoc([])
      setShowAssoc(false)
      setForm({});
    }

  }, [fetching_list]);


  useEffect(() => {
    if (fetching_assoc === 2) {
      getProductsInventory();
      toogleLoader(true)
    }
  }, [fetching_assoc])

  useEffect(() => {
    if (fetching_by_id == 2) {
      setForm(row);


      if (row.product_inventory_assoc.length > 0) {
        setShowAssoc(true);
        setListAssoc(row.product_inventory_assoc)
      } else {
        setShowAssoc(false);
        setListAssoc([])
      }
      window.location.href = "#main-div"
    }
  }, [fetching_by_id])

  const onFilterContent = (form) => {
    let _list = list;

    _list = list.filter((row) => {
      if (row.name.includes(form.name) && row.code.includes(form.code)) {
        return true;
      }
      return false;
    });
    let fill = 0;

    setListFilter(_list);
  };

  const onCleanFilter = () => { }

  const onRealod = () => {
    setForm({});
    getProductsInventory();
  };

  const onEdit = (row) => {
    getProductInventoryById(row.id)
    // setForm(row);
    // window.location.href = "#main-div"
  }

  const onSearch = (e) => {
    let _list = list;
    let value = e.target.value.toLowerCase();

    _list = list.filter((row) => {
      if (
        row.name.toLowerCase().includes(value) ||
        row.code.toLowerCase().includes(value) ||
        row.code.toLowerCase().includes(value)
      ) {
        return true;
      }
      return false;
    });
    setListFilter(_list);
  }

  const loadOptions = async (inputValue) => {

    if (debounceRef.current)
      clearTimeout(debounceRef.current)


    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));
    let branch = JSON.parse(localStorage.getItem("branch"));
    let url = `${API_URL}/api/product-inventory-search/${inputValue}?date_init=${date_init}&date_end=${date_end}&branch=${branch.id}`;

    return await debounceData(debounceRef, url)
  }

  const orderBy = (field, type) => {
    setOrder(!order)
    let _type = order === true ? 'asc' : 'desc'

    getProductsInventory(`&order_by=${field}&type=${_type}`);
  }

  const onAddItemAssoc = () => {

    let validate = listAssoc.filter(d => parseInt(d.value) === parseInt(product.id))

    let row = {
      uid: uuid(),
      product_inventory: product,
      product_id: product.id,
      proportion: proportion
    }

    setListAssoc([
      ...listAssoc,
      row
    ]);

    setProduct('')
  }

  const onDeleteAssoc = (product) => {

    if (window.confirm(`¿Realmente deseas Desasociar El producto (${product.product_inventory.name})?`)) {
      if (product.id !== undefined) {
        onDeleteAssocProduct(product.id)
      } else {
        let _list = listAssoc.filter(d => parseInt(d.value) !== parseInt(product.id))
        setListAssoc(_list)
      }
    }

  }

  const onCloseAssoc = () => {
    setListAssoc([])
    setShowAssoc(false)
  }

  const onAssocProduct = () => {

    let total = listAssoc.reduce((a, b) => a + parseFloat(b.proportion), 0)
    if (total > 100) {
      alert("No puedes superar el 100%");
      return
    }

    form.list_products = listAssoc
    onAssocListProduct(form)

  }

  const validateOpenAssoc = () => {

    if (Object.keys(form).length > 0) {
      setShowAssoc(true)
    } else {
      alert("Selecciona un producto");
    }
  }

  const onEditDeleteProductInventory = (item) => {
    if (window.confirm(`¿Deseas Eliminar el Producto?`)) {
      onDeleteProductInventory(item)
    }

  }

  return (
    <TemplateMenu>
      <Container fluid id="main-div">
        <Row className="d-flex justify-content-center">
          <Col lg={7}>
            <FormProductRecipe
              onSearch={onFilterContent}
              formEdit={form}
              onReload={onRealod}
              showAssoc={showAssoc}
              onOpenAssoc={validateOpenAssoc}
              onEditDeleteProductInventory={onEditDeleteProductInventory}
              listAssoc={listAssoc}
            />
          </Col>
          {showAssoc &&
            <Col lg={5}>
              <Card>
                <Card.Header>
                  <Row>
                    <Col>Asociar Productos</Col>
                    <Col lg={2}>
                      <button className="btn btn-sm btn-warning" onClick={onCloseAssoc}>Cancelar</button>
                    </Col>
                    <Col lg={2}>
                      <button className="btn btn-sm btn-success" onClick={onAssocProduct}>Asociar</button>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <AsyncSelect
                        ref={debounceRef}
                        value={product}
                        loadOptions={loadOptions}
                        placeholder="Busca el producto"
                        onChange={(e) => {
                          setProduct(e)
                        }}
                      />
                    </Col>
                    <Col lg={3}>
                      <input placeholder="Proporcion" className="form-control text-right" value={proportion}
                        onChange={(e) => setProportion(e.target.value)} />
                    </Col>
                    <Col lg={3}>
                      <button className="btn btn-sm btn-info" onClick={onAddItemAssoc}>Agregar</button>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <Table className="table table-sm table-bordered table-hover table-striped">
                        <thead className="thead-dark">
                          <tr>
                            <th>Producto</th>
                            <th>Proporción</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {listAssoc.length === 0 ? <tr><td colSpan={2} align="center">No tiene Productos</td></tr> : listAssoc.map((d, k) => <tr key={k}>
                            <td>{d.product_inventory.name}</td>
                            <td align="right">{d.proportion}</td>
                            <td>
                              <button className="btn btn-sm" onClick={() => onDeleteAssoc(d)}>
                                <MdClose />
                              </button></td>
                          </tr>)}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th>TOTAL</th>
                            <th className="text-right">{listAssoc.reduce((a, b) => a + parseFloat(b.proportion), 0)}</th>
                          </tr>
                        </tfoot>
                      </Table>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>}

        </Row>
        <Row className="mt-2">
          <Col lg={3}>
            <input className="form-control" placeholder="Buscar" onChange={onSearch} />
          </Col>
          <Col>
            <DownloadTableExcel
              filename="products_inventory"
              sheet="products"
              currentTableRef={tableRef.current}>
              <RiFileExcel2Fill size={25} color="green" className="cursor-pointer" />
            </DownloadTableExcel>
          </Col>
          <Col className="text-right mr-2 text-muted"><CounterResults filter={listFilter.length} total={list.length} /></Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <table className="table table-sm table-bordered table-hover table-striped" ref={tableRef}>
              <thead className="thead-dark">
                <tr>
                  <th>Ord</th>
                  <th>Nombre</th>
                  <th className="one-line">
                    Código
                    <MdExpandLess onClick={() => orderBy("code", "asc")} />
                  </th>
                  {/* <th>Código Barras </th> */}
                  <th className="text-center">Unidad Medida</th>
                  <th>Imp</th>
                  {/* <th>Costo</th> */}
                  <th>Familia</th>
                  <th className="text-center">Tipo Inventario</th>
                  <th>Grupo</th>
                  <th className="text-center">Tipo Control</th>
                  <th>Proveedor</th>
                  <th className="text-center">Cantidad</th>
                  <th className="text-center">Costo</th>
                  <th>Estado</th>
                  <th>Modificar</th>
                </tr>
              </thead>
              <tbody>
                {listFilter.length > 0 ? (
                  listFilter.map((row) => (
                    <ItemProductInventory
                      row={row}
                      key={row.id}
                      onEdit={onEdit}
                      product={form}
                    />
                  ))
                ) : (
                  <tr>
                    <td colSpan={6}>No se encontraron registros</td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={9}><b>SUBTOTAL</b></td>
                  <td align="right">{formatNumber(listFilter.reduce((a, b) => a + parseFloat(b.quantity_available), 0))}</td>
                  <td align="right">{formatNumber(listFilter.reduce((a, b) => a + parseFloat(b.cost_average), 0))}</td>
                </tr>
              </tfoot>
            </table>

            {/* <PaginationTable /> */}
          </Col>
        </Row>
      </Container>
    </TemplateMenu>
  );
};

const mapsState = (props) => {
  let { product_inventory } = props;
  return {
    fetching_list: product_inventory.fetching_list,
    fetching_by_id: product_inventory.fetching_by_id,
    fetching_assoc: product_inventory.fetching_assoc,
    row: product_inventory.row,
    products: product_inventory.data,
  };
};

export default connect(mapsState, {
  getProductsInventory, toogleLoader,
  onAssocListProduct, getProductInventoryById, onDeleteAssocProduct, onDeleteProductInventory
})(ProductRecipesPage);
