import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import uuid from "react-uuid";
import { debounceData, formatNumber, getHeaders } from "../../utils";
import { Form, InputGroup } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import InputFieldCurrency from "../Components/Widgets/InputFieldCurrency";
import { getLastInventory } from '../../Redux/inventoryDuck'
import { connect } from "react-redux";


const API_URL = process.env.REACT_APP_API_URL;

const initState = {
  tax: { description: "" },
  measure_unit: "",
};

const RowProduct = ({ onDelete, onAddItem, formEdit, onEditItem, getLastInventory, registration_date, fetching_by_id, row, supplier_id }) => {
  const [form, setForm] = useState({ amount: '', cost: 0, product: {}, total: "", });

  const [selectedProduct, setSelectedProduct] = useState(initState);
  const [showButton, setShowButton] = useState(false);
  const [productSearch, setProductSearch] = useState("");
  const [listSearch, setListSearch] = useState([]);
  const debounceRef = useRef();

  useEffect(() => {
    if (fetching_by_id === 2) {
      setSelectedProduct({
        ...selectedProduct,
        ["cost_average"]: row.cost_average
      })

    }
  }, [fetching_by_id])

  useEffect(() => {
    if (Object.keys(formEdit).length > 0) {
      setForm(formEdit);
      setSelectedProduct(formEdit.product);
      setShowButton(true);
      setProductSearch({
        label: formEdit.product.name,
        value: formEdit.product.id,
      });
    }
  }, [formEdit]);

  const loadOptions = async (inputValue) => {

    if (debounceRef.current)
      clearTimeout(debounceRef.current)

    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));
    let branch = JSON.parse(localStorage.getItem("branch"));
    let url = `${API_URL}/api/product-inventory-search/${inputValue}?date_init=${date_init}&date_end=${date_end}&branch=${branch.id}`;

    return await debounceData(debounceRef, url);


  };

  const updateInput = (e) => {
    let value = e.target.value

    if (e.target.name === "amount") {
      value = value.replace(/[^0-9\.]+/, '');


      setForm({
        ...form,
        [e.target.name]: value,
        ["cost"]: form.total / value,
      });
    }

    if (e.target.name === "total") {
      value = value.replace(/[^0-9\,]+/, '');
      setForm({
        ...form,
        [e.target.name]: value,
        ["cost"]: value / form.amount,
      });
    }
  };

  const onAddItemBefore = (item) => {

    if (form.total === '') {
      alert("El subtotal no puede estar Vacio")
      return;
    }
    if (form.total <= 0) {
      alert("El subtotal tiene que ser Mayor a Cero")
      return;
    }
    if (form.amount === '') {
      alert("La cantidad no puede estar Vacio")
      return;
    }
    if (form.amount <= 0) {
      alert("La cantidad tiene que ser Mayor a Cero")
      return;
    }
    if (productSearch === '') {
      alert("Escoge el producto")
      return;
    }

    item._id = uuid();
    item.cost_average = selectedProduct.cost_average
    onAddItem(item);
    setForm({
      ...form,
      "amount": '',
      "cost": '',
      "total": ''
    });
    setProductSearch("")
  }

  const updateInputCurrency = (value, name) => {

    if (name === "amount") {
      setForm({
        ...form,
        [name]: value,
        ["cost"]: form.total / value,
      });
    }

    if (name === "total") {
      setForm({
        ...form,
        [name]: value,
        ["cost"]: value / form.amount,
      });
    }
  }

  return (
    <tr>
      <td width={"30%"}>
        <AsyncSelect
          ref={debounceRef}
          isClearable
          value={productSearch}
          loadOptions={loadOptions}
          onChange={(e) => {

            if (e === null) {
              setSelectedProduct(initState)
              setProductSearch('')
            } else {
              setSelectedProduct(e);

              getLastInventory(e.id, registration_date)
              setForm({
                ...form,
                ["cost"]: 0,
                ["product"]: e,
              });
              setShowButton(true);
              setProductSearch({ label: e.name, value: e.id });
            }

          }}
          placeholder="Busca el producto"
        />
      </td>
      <td align="right">{formatNumber(selectedProduct.cost_average || 0)}</td>
      <td align="right" width={"18%"}>
        <InputGroup className="mb-3">
          <Form.Control onChange={updateInput}
            value={form.amount}
            className="text-right"
            name="amount"
            placeholder="Cantidad"
            aria-describedby="basic-addon2"
            autoComplete="off"
          />
          <InputGroup.Text id="basic-addon2">
            {selectedProduct.measure_unit}
          </InputGroup.Text>
        </InputGroup>
      </td>

      <td align="right">
        {formatNumber(form.cost || 0)}<br />
        <span className={`${selectedProduct.cost_average < form.cost ? 'text-danger' : 'text-success'}`}>{formatNumber((selectedProduct.cost_average || 0) - (form.cost || 0))}</span>

      </td>
      {/* <td align="right">{selectedProduct.tax_id.description}</td> */}
      <td align="right" width={"15%"}>
        <InputFieldCurrency name={"total"}
          placeholder="Total"
          value={form.total}
          onValueChange={updateInputCurrency} />
      </td>


      <td width={"15%"} align="center">
        {
          <button className="btn btn-sm btn-success mx-2" onClick={() => onAddItemBefore(form)} disabled={supplier_id === 0 ? true : false}>
            Guardar
          </button>
        }
      </td>

    </tr >
  );
};

const mapsState = (props) => {
  let { inventory } = props

  return {
    fetching_by_id: inventory.fetching_by_id,
    row: inventory.row,
  }
}

export default connect(mapsState, { getLastInventory })(RowProduct);
