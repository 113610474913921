import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import TemplateMenu from "../../template/menu.template";
import { getReportEndMonth } from "../../Redux/inventoryDuck";
import { onLoginCloseMonth, getDates } from "../../Redux/userDuck";
import { toogleLoader } from "../../Redux/alertDuck";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import InputField from "../Components/Widgets/InputField";
import ModalDetailStock from "./modal.detail.stock";
import ModalNoPrescription from "./modal.no_prescription";
import ModalCorrectionDate from "./modal.correction.date";
import './index.css'

const EndMonth = ({ getReportEndMonth, data_end, fetching_by_end, onLoginCloseMonth, fetching_close, toogleLoader,
  getDates, fetching_dates }) => {

  const [data, setData] = useState({
    days_inventory: 0,
    result_prescription: { total: 0 },
    result_negative: { total: 0 },
    result_no_inventory: { total: 0 },
    result_total_products: { total: 0 },
    result_no_prescription: [],
    result_dates: []
  });

  const [form, setForm] = useState({ password: "" });
  const [error, setError] = useState("");
  const [enable, setEnable] = useState(true);
  const [modal, setModal] = useState(false);
  const [modalDate, setModalDate] = useState(false);
  const [modalNoPrescription, setModalNoPrescription] = useState(false);
  const [listProducts, setListProducts] = useState([]);
  const [listNoProducts, setListNoProducts] = useState([]);
  const [listCalendar, setListCalendar] = useState([]);

  useEffect(() => {


    if (fetching_close === 2) {
      let branch = JSON.parse(localStorage.branch)
      getDates(branch.id)
      setEnable(false)
    }

    if (fetching_close === 3) {
      toogleLoader(false)
      alert("Confirma la Contraseña")
    }


  }, [fetching_close]);

  useEffect(() => {
    if (fetching_dates === 2) {
      toogleLoader(true)

      getReportEndMonth();
    }
  }, [fetching_dates])


  useEffect(() => {
    if (fetching_by_end === 2) {
      toogleLoader(false)
      let { month_close_result } = data_end
      if (month_close_result === undefined) {
        setEnable(false)
      } else {
        if (month_close_result.status_id === 1) {
          setEnable(true)
        }
      }
      setListCalendar(data_end.calendar)
      setData(data_end);
    }


  }, [fetching_by_end]);


  // useEffect(() => {
  //   getReportEndMonth();
  // }, []);

  const onCloseMonth = () => {
    let { date_init } = JSON.parse(localStorage.getItem("date"));
    let month = moment(date_init).format("MMMM");


    if (parseInt(data.result_no_inventory.total) > 0) {
      alert(
        `No puedes finalizar (${month}) porque tiene Productos sin inventario al fin de Mes (${data.result_no_inventory.total})`
      );
    } else {
      if (form.password.length === 0) {
        setError("Clave requerida");
        return;
      }
      if (window.confirm("¿Realmente deseas hacer el Cierre?")) {
        toogleLoader(true)
        onLoginCloseMonth(form);
      }

    }
  };

  const showDetailStock = (data) => {
    setModal(true)
    setListProducts(data)
  }

  const showNoPrescription = (data) => {
    setModalNoPrescription(true)
    setListNoProducts(data)
  }
  const showCorrectionDate = (data) => {
    setModalDate(true)
    setListNoProducts(data)
  }

  return (
    <TemplateMenu>
      <Row className="my-2">
        <Col>Cierre de Mes</Col>
      </Row>
      <Row>
        <Col>
          <Row className="">
            <Col className="bg-sales">Días cargados con <b>VENTAS:</b> {data.days_inventory}</Col>
          </Row>
          <Row className="">
            <Col className="bg-counter">Días con ingreso de <b>INVENTARIO FISICO:</b> {data.days_counter}</Col>
          </Row>
          <Row className="">
            <Col className="bg-lowdown">Días con <b>BAJAS:</b> {data.days_lowdown}</Col>
          </Row>
          <Row className="">
            <Col className="bg-purchase">Días con <b>COMPRAS:</b> {data.days_purchases}</Col>
          </Row>
          <Row className="">
            <Col>
              Productos sin <b>INVENTARIO</b> en fin de mes:
              <a className={`${parseInt(data.result_no_inventory.total) > 0 ? 'text-danger font-weight-bold' : ''}`} href="/conteo"> {data.result_no_inventory.total}</a>
            </Col>
          </Row>
          <Row className="my-2">
            <Col>
              Productos con recetas {data.result_prescription.total} de {" "}
              {data.result_total_products.total} <button className="btn btn-sm btn-danger" onClick={() => showNoPrescription(data.result_no_prescription)}>{data.result_no_prescription.length}</button>
            </Col>
          </Row>
          <Row>
            <Col>Productos con Stock en Negativo <button className="btn btn-sm btn-danger" onClick={() => showDetailStock(data.result_negative)}>{data.result_negative.length}</button></Col>
          </Row>
          <Row>
            <Col>Productos con Corrección <button className="btn btn-sm btn-danger" onClick={() => showCorrectionDate(data.result_dates)}>{data.result_dates.length}</button></Col>
          </Row>
        </Col>
        <Col>
          <Row className="d-flex center">
            <Col lg={5}>
              <div className="container-calendar">
                <div className="container-box">{"Domingo"}</div>
                <div className="container-box">{"Lunes"}</div>
                <div className="container-box">{"Martes"}</div>
                <div className="container-box">{"Miercoles"}</div>
                <div className="container-box">{"Jueves"}</div>
                <div className="container-box">{"Viernes"}</div>
                <div className="container-box">{"Sabado"}</div>
                {listCalendar.map((d, k) => <div key={k} className="container-box">
                  <div className="container-title">{d.day}</div>
                  {d.sales !== undefined && <div className="circle-sales"></div>}
                  {d.counter !== undefined && <div className="circle-counter"></div>}
                  {d.lowdown !== undefined && <div className="circle-lowdown"></div>}
                  {d.purchases !== undefined && <div className="circle-purchases"></div>}
                </div>)}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>


      {enable && <Row className="mt-4 border-top">
        <Col lg={2}>Cerrar Mes</Col>
        <Col lg={2}>
          <div className="form-group">
            <label>Clave de Administrador</label>
            <InputField
              type={"password"}
              placeholder="****"
              value={form.password || ""}
              name="password"
              onChange={(e) =>
                setForm({ ...form, ["password"]: e.target.value })
              }
              error={error}
            />
          </div>
        </Col>
        <Col lg={2} className="d-flex align-items-center">
          <button className="btn btn-sm btn-success" onClick={onCloseMonth}>
            Cerrar
          </button>
        </Col>
      </Row>}

      <ModalDetailStock show={modal} listProducts={listProducts} onHide={() => setModal(false)} />
      <ModalNoPrescription show={modalNoPrescription} listProducts={listNoProducts} onHide={() => setModalNoPrescription(false)} />
      <ModalCorrectionDate show={modalDate} listProducts={listNoProducts} onHide={() => setModalDate(false)} />
    </TemplateMenu>
  );
};

const mapsState = (props) => {
  let { report, user } = props;

  return {
    fetching_by_end: report.fetching_by_end,
    data_end: report.data_end,
    fetching_close: user.fetching_close,
    fetching_dates: user.fetching_dates

  };
};

export default connect(mapsState, { getReportEndMonth, onLoginCloseMonth, toogleLoader, getDates })(
  EndMonth
);
