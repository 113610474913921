import React, { useState } from 'react';

const initialStateBrand = { title: "", group: 0, image: '', brand_id: '' }


const ItemsBrandSaved = ({ listBrand, item, brandSelected, onCancelBrand, setBrandSelected, updateListBrand }) => {

    console.log('item', item)
    let row = listBrand.filter(row => row.id === item.id)[0];
    let { branch_office } = item
    const [formBrand, setFormBrand] = useState(initialStateBrand)

    const onActiveBrand = (brand_id) => {
        setFormBrand(initialStateBrand)
        setBrandSelected(brand_id)


        if (listBrand.length > 0) {
            let brand = listBrand.filter(row => row.brand_id === brand_id)[0];


            if (brand !== undefined) {

                setFormBrand({
                    ...formBrand,
                    ["price"]: brand.price
                })
            } else {
                setFormBrand({
                    ...formBrand,
                    ["price"]: 0
                })
            }
        }
    }


    const onSaveBrand = (brand_id) => {

        console.log('brand_id---', brand_id)
        let row = { brand_id, price: formBrand.price }

        if (listBrand.length === 0) {

            updateListBrand(row)

        } else {

            console.log('listBrandaaa', listBrand)

            let _listBrand = [...listBrand];

            let exists = false;

            _listBrand.map((row, i) => {
                if (row.id === brand_id) {
                    exists = true;
                    _listBrand[i].price = formBrand.price
                }
            })

            if (exists === false) {
                _listBrand.push(row);
            }

            console.log('exists', exists)

            console.log('_listBrand', _listBrand)

            updateListBrand(_listBrand)
        }



        setBrandSelected(0)
        setFormBrand(initialStateBrand)
        // console.log('listBrand', listBrand)

    }


    const updateFieldBrand = (e) => {
        console.log('e.target.value', e.target.value)
        setFormBrand({
            ...formBrand,
            [e.target.name]: e.target.value
        })
    }
    return (
        <tr className="mt-1">
            <td>{branch_office.title}</td>
            <td>
                {(brandSelected === item.id) ? (
                    <input type="text" placeholder="Precio" name="price" className="form-control" value={formBrand.price} onChange={updateFieldBrand} />
                ) : (item.price)}

                {/*  */}
            </td>
            <td>
                {
                    (brandSelected === item.id) ? (
                        <>
                            <button type="button" className="btn btn-success" onClick={() => onSaveBrand(item.id)}>Guardar</button>
                            <button type="button" className="btn btn-warning ml-2" onClick={() => onCancelBrand()}>Cancelar</button>
                        </>
                    ) : (
                        <button type="button" className="btn btn-info" onClick={() => onActiveBrand(item.id)}>Activar</button>
                    )
                }

            </td>
        </tr>)
}
export default ItemsBrandSaved;