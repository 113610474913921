import React, { Fragment, useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap';
import moment from 'moment'
import { FcSynchronize } from "react-icons/fc";

const OrderPreparation = ({ item, onCancel, showDetail, clean, onSync }) => {

    const [timer, setTimer] = useState(0)


    useEffect(() => {
        // reload = reloadTimer();
        // setTimer(moment().diff(item.updated_at, 'minutes'))


        // if(clean===false){
        //     console.log('clear')
        //     clearInterval(reload)
        // }else{
        //     console.log('start')
        //     reload = reloadTimer();
        // }
    }, [item, clean])

    const reloadTimer = () => {

        setInterval(() => {
            setTimer(moment().diff(item.updated_at, 'minutes'))
            console.log('ejecuto')
            // },60000)
        }, 3000)
    }

    return (
        <Fragment>


            <Row className="my-1 p-0 rounded-lg bg-white border active_list_reservation">
                <Col>
                    <Row className=" my-2 p-0">
                        <Col lg={4}>No. {item.id}</Col>
                        <Col lg={4}>Orden Dia. {item.order_day}</Col>
                        <Col lg={4} className="text-right">
                            <span >{moment().diff(item.updated_at, 'minutes')} min</span>
                        </Col>
                    </Row>
                    <Row className=" my-2 p-0">
                        <Col lg={4}>Fac. {item.aldelo_id}
                        </Col>
                        <Col lg={3}>
                            <button className="btn" onClick={() => onSync(item)}>
                                <FcSynchronize />
                            </button>
                        </Col>
                    </Row>

                    <Row className="px-2 pb-2">
                        <Col lg={6} className=" pl-0">
                            <button className="bg-gris-20 rounded text-center text-gris  py-1 w-100" onClick={onCancel}>
                                Cancelar
                            </button>
                        </Col>
                        <Col lg={6} className="m-0 px-1 pr-0">
                            <button className="bg-naranja rounded text-center text-white w-100 py-1 btn-naranja" onClick={() => showDetail(item)}>
                                Listo</button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Fragment>
    )
}

export default OrderPreparation;