import React, { useState, useEffect } from 'react'
import { Modal, Container, Row, Col, Button, InputGroup, Form } from 'react-bootstrap';
import { MdClose } from "react-icons/md";
import InputField from '../Components/Widgets/InputField';
import { connect } from 'react-redux';
import { createUser, updateUser, clearSelected } from '../../Redux/userDuck'
import { getBranch } from '../../Redux/branchofficeDuck'
import BlueTitleBack from '../Components/Widgets/BlueTitleBack'

const initState = { name: '', email: '', phone: '', password: '', confirmation: '', role_id: 0, branch_id: 0, status: true };

const ModalUsers = ({ showModal, onHide, errors, fetching, createUser, getBranch, branch, updateUser, onSuccess, listRoles, formEdited, clearSelected,
    tenant, domain }) => {

    const [form, setForm] = useState(initState)

    useEffect(() => {
        if (Object.keys(formEdited).length > 0) {
            formEdited.email = formEdited.email.replace(`@${domain}`, "")
            setForm(formEdited)
        }
    }, [formEdited])

    useEffect(() => {
        if (fetching === 2) {
            onHide()
            onSuccess()
        }
        // if(Object.keys(selected).length>0){
        //     setForm(selected);
        // }
        // else{
        //     if(fetching!==3 && fetching!==2){
        //         setForm(initState)
        //     }
        // }
    }, [fetching])

    const closeModalTmp = () => {
        clearSelected()
        onHide()
    }

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const updateCheck = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.checked
        })
    }

    const onSubmit = () => {

        if (form.id !== undefined) {
            updateUser(form)
        } else {
            createUser(form)
        }
    }

    return (
        <Modal show={showModal} onHide={closeModalTmp} size="lg">
            <div className="modal-header border-bottom-custom">
                <BlueTitleBack title='USUARIOS' />
                <button type="button" className="close p-0 m-0" data-dismiss="modal" aria-label="Close" onClick={closeModalTmp}>
                    <MdClose />
                </button>
            </div>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Nombres</label>
                                <InputField
                                    placeholder="Agrega los nombres"
                                    value={form.name || ''}
                                    name="name"
                                    onChange={updateInput}
                                    error={errors.name}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Usuario</label>
                                <InputGroup className="mb-3">
                                    <InputField
                                        placeholder="Agrega Usuario"
                                        value={form.email || ''}
                                        name="email"
                                        onChange={updateInput}
                                        error={errors.email}
                                    />
                                    <InputGroup.Text>@{domain}</InputGroup.Text>
                                </InputGroup>

                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Clave</label>
                                <InputField
                                    type="password"
                                    placeholder="Agrega la Clave"
                                    value={form.password || ''}
                                    name="password"
                                    onChange={updateInput}
                                    error={errors.password}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Confirmación</label>
                                <InputField
                                    type="password"
                                    placeholder="Confirmación de la clave"
                                    value={form.confirmation || ''}
                                    name="confirmation"
                                    onChange={updateInput}
                                    error={errors.confirmation}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Celular</label>
                                <InputField
                                    type="number"
                                    placeholder="Agrega el Celular"
                                    value={form.phone || ''}
                                    name="phone"
                                    onChange={updateInput}
                                    error={errors.phone}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Rol</label>
                                <select className="form-control" name="role_id" value={form.role_id} onChange={updateInput}>
                                    <option value="0">Seleccione</option>
                                    {listRoles.map(row => <option key={row.id} value={row.id}>{row.description}</option>)}
                                </select>
                                {errors !== undefined && (<label className="error-label">{errors.role_id}</label>)}

                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Código POS</label>
                                <InputField
                                    type="number"
                                    placeholder="Agrega tu codigo"
                                    value={form.code || ''}
                                    name="code"
                                    onChange={updateInput}
                                    error={errors.code}
                                />
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="form-group">
                                <label>Estado</label>
                                <input
                                    type="checkbox"
                                    className="form-control"
                                    checked={form.status}
                                    name="status"
                                    onChange={updateCheck}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Row className="justify-content-center">
                    <Col>
                        <button className='btn btn-success' onClick={onSubmit}>Guardar</button>
                    </Col>
                    <Col>
                        <button className='btn btn-danger' onClick={closeModalTmp}>Cerrar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

const mapsState = (props) => {
    let { user, branchoffice } = props

    return {
        errors: user.errors,
        selected: user.selected,
        fetching: user.fetching_save,
        branch: branchoffice.data,
        branch: branchoffice.data,
        tenant: user.data.tenant,
        domain: user.data.domain,
    }
}

export default connect(mapsState, { createUser, getBranch, updateUser, clearSelected })(ModalUsers);