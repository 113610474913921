import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { AiFillFilter } from 'react-icons/ai';
import ItemTableExpense from '../Expenses/item.table.expense';
import { getExpenses, onCreateorUpdateExpenses, onMarkSelection, onMarkSelectionTotal, deleteExpensePersonal } from '../../Redux/expensesDuck'
import { connect } from 'react-redux';
import { getBranch } from '../../Redux/branchofficeDuck'
import { getSuppliers } from '../../Redux/supplierDuck'
import { getParameterBySegment, createParameter, getParameterByGroup } from '../../Redux/parameterDuck'
import { getReviewCash } from '../../Redux/reportDuck'

import moment from 'moment';
import ModalAddAlterExpense from './modal.add.alter.expense';
import ModalAddParameter from '../Components/ModalAddParameter';
import { formatNumber } from '../../utils';
import { useHistory } from 'react-router-dom';
import CardDataAlternative from './card.data.alternative';
import ModalAddAlterIngreso from './modal.add.alter.ingreso';
import ModalAddAlterOtherExpense from './modal.add.alter.other.expense';
import BoxColor from '../Components/BoxColor';
import TemplateMenu from '../../template/menu.template'

const initStateAddExpense = {
    registration_date: moment().format("YYYY-MM-DD"), status_id: 2, parameter_id: 0, method_payment_id: 0, observation: '', amount: '',
    parameter_group_id: 0
};

const BalanceAlternativePage = ({ getExpenses, expenses, fetching_list, fetching_by_segment, data_segment, getParameterBySegment, getBranch, getSuppliers,
    fetching_branch, branch_offices, fetching, review, onCreateorUpdateExpenses, fetching_create, createParameter, segment, getReviewCash, errors,
    onMarkSelection, onMarkSelectionTotal, fetching_create_param, user, deleteExpensePersonal, fetching_delete, fetching_mark,
    getParameterByGroup, fetching_group, data_group }) => {

    const [filter, setFilter] = useState({ date_init: moment().format("YYYY-MM-01"), date_end: moment().format("YYYY-MM-DD"), status_selected: 0, branch_origin_id: 0, group_selected: 0, concept_selected: 0 })
    const [form, setForm] = useState();
    const [modal, setModal] = useState();
    const [modalIngreso, setModalIngreso] = useState(false);
    const [modalOther, setModalOther] = useState(false);
    const [listExpense, setListExpense] = useState([]);
    const [listTypePayments, setListTypePayments] = useState([])
    const [listBranch, setListBranch] = useState([]);
    const [listParameterGroup, setListParameterGroup] = useState([]);
    const [modalParam, setModalParam] = useState({ open: false, title: 'Pendiente' });
    const [lastParam, setLastParam] = useState({})
    const [selectedColor, setSelectedColor] = useState({})
    const [data, setData] = useState({ total: { nequi: 0, datafono: 0, cash: 0, daviplata: 0 }, total_expense: [], branch: {} })
    const [titleColor, setTitleColor] = useState('');
    const [totales, setTotales] = useState({ expense: 0, ingreso: 0, tips: 0, total: 0 });

    const [expenseNequi, setExpenseNequi] = useState(0)
    const [expenseCash, setExpenseCash] = useState(0)
    const [expenseDatafono, setExpenseDatafono] = useState(0)
    const [expenseDaviplata, setExpenseDaviplata] = useState(0)
    const [available, setAvailable] = useState({ cash: 0, datafono: 0, nequi: 0, daviplata: 0 })
    const [listParameter, setListParameter] = useState([])

    const history = useHistory()

    useEffect(() => {

        let _date = JSON.parse(localStorage.date);
        let q = `&init=${_date.date_init}&end=${_date.date_end}&type_payment=${filter.status_selected}&group=0&concept=0&branch_origin_id=0`

        getExpenses(q)
        getBranch()
        getSuppliers()
        getParameterBySegment("methods_payment")
        getReviewCash()
        getParameterByGroup("category")
    }, [])

    useEffect(() => {
        if (fetching_branch === 2) {
            let branch = JSON.parse(localStorage.getItem("branch"))
            setListBranch(branch_offices.filter(r => r.id !== branch.id))
        }
    }, [fetching_branch])



    useEffect(() => {
        if (fetching_mark === 2) {
            let _date = JSON.parse(localStorage.date);
            let q = `&init=${_date.date_init}&end=${_date.date_end}&type_payment=${filter.status_selected}&group=0&concept=0&branch_origin_id=0`
            console.log('q-----', q);

            getExpenses(q)
        }
    }, [fetching_mark])

    useEffect(() => {
        if (fetching_delete === 2) {
            let _date = JSON.parse(localStorage.date);
            let q = `&init=${_date.date_init}&end=${_date.date_end}&type_payment=${filter.status_selected}&group=0&concept=0`
            getExpenses(q)
        }
    }, [fetching_delete])

    useEffect(() => {
        if (fetching_create_param === 2) {
            setModalParam({ ...modalParam, "open": false })
            getParameterBySegment("methods_payment")
        }
    }, [fetching_create_param])

    useEffect(() => {
        if (fetching === 2) {

            let expense_nequi = review.total_expense.filter(r => r.description == 'NEQUI')[0] || 0
            let expense_cash = review.total_expense.filter(r => r.id == 374)[0]
            let expense_datafono = review.total_expense.filter(r => r.description == 'DATAFONO')[0]
            let expense_daviplata = review.total_expense.filter(r => r.description == 'DAVIPLATA')[0]

            expense_nequi = (expense_nequi === undefined) ? 0 : expense_nequi.total;
            expense_cash = (expense_cash === undefined) ? 0 : expense_cash.total;
            expense_datafono = (expense_datafono === undefined) ? 0 : expense_datafono.total;
            expense_daviplata = (expense_daviplata === undefined) ? 0 : expense_daviplata.total;

            setExpenseCash(expense_cash)
            setExpenseNequi(expense_nequi || 0)
            setExpenseDatafono(expense_datafono)
            setExpenseDaviplata(expense_daviplata)
            setData(review)
        }
    }, [fetching])

    useEffect(() => {
        if (fetching_list === 2) {
            let total_expense = expenses.reduce((a, b) => a = a + parseFloat(b.expense), 0)
            let total_ingreso = expenses.reduce((a, b) => a = a + parseFloat(b.ingreso), 0)
            let total_tips = expenses.reduce((a, b) => a = a + parseFloat(b.tips || 0), 0)

            setTotales({
                ...totales,
                ["expense"]: total_expense,
                ["ingreso"]: total_ingreso,
                ["tips"]: total_tips,
                ["total"]: total_ingreso - total_expense - total_tips,
            })

            setListExpense(expenses)
        }
    }, [fetching_list])

    useEffect(() => {
        if (fetching_create === 2) {
            let _date = JSON.parse(localStorage.date);
            let q = `&init=${_date.date_init}&end=${_date.date_end}&type_payment=${filter.status_selected}&group=0&concept=0`
            setModal(false)
            setModalIngreso(false)
            setModalOther(false)
            getExpenses(q)

        }
    }, [fetching_create])

    useEffect(() => {
        if (fetching_by_segment === 2) {
            if (segment === "methods_payment") {
                setListTypePayments(data_segment)
            }
        }
    }, [fetching_by_segment])

    useEffect(() => {
        if (fetching_group === 2) {
            setListParameterGroup(data_group)
        }
    }, [fetching_group])

    const updateInput = (e) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        })
        if (e.target.name === 'group_selected') {
            setListParameter(listParameterGroup.filter(f => parseInt(f.id) === parseInt(e.target.value))[0].children)
        }

    }


    const onOpenParameter = (title, dataType) => {


        let _title = dataType === 1 ? 'Agregar otro Concepto' : 'Pago otro Turneros';

        setModalParam({
            ...modalParam,
            "open": true,
            "title": _title,
            dataType
        })

    }

    const onAddExpenses = (form) => {
        form.movement_type_id = 1;
        onCreateorUpdateExpenses(form)
    }

    const onAddIngresos = (form) => {
        form.movement_type_id = 2;
        onCreateorUpdateExpenses(form)
    }

    const onAddIngresosOther = (form) => {
        form.movement_type_id = 1;
        onCreateorUpdateExpenses(form)
    }

    const onFilter = () => {
        let q = `&init=${filter.date_init}&end=${filter.date_end}&type_payment=${filter.status_selected}&branch_origin_id=${filter.branch_origin_id}&group=${filter.group_selected}&concept=${filter.concept_selected}`
        getExpenses(q)
    }

    const onCreateParameter = (form) => {
        form.segment = 'expense'
        form.priority = 1;
        setLastParam(form)
        createParameter(form)
    }

    const onGoToReview = (id) => {
        history.push(`/balance/${id}`)
    }

    const onEdit = (item) => {
        setModal(true)
        setForm(item)
    }

    // const onSelectColor = (color) => {

    //     if (color.id === selectedColor.id) {
    //         setTitleColor("")
    //         setSelectedColor({})
    //     } else {
    //         setTitleColor("Marcador Activado")
    //         setSelectedColor(color)
    //     }

    // }

    const onSelectColor = (e) => {
        setTitleColor("Marcador Activado")
        setSelectedColor(e.target.value)
    }


    const onMarkExpense = (item) => {
        onMarkSelection(item, selectedColor)
    }

    const onMarkTotal = (item) => {
        onMarkSelectionTotal(item, selectedColor)
    }
    const openModalAddExpense = (item) => {
        setForm(initStateAddExpense)
        setModal(true)
    }
    const onClickDelete = (item) => {
        if (window.confirm("Deseas eliminar este Registro")) {
            deleteExpensePersonal(item.id)
        }
    }

    return <TemplateMenu>
        <Container fluid>
            <Row className='my-1'>
                <Col>
                    <button className='btn btn-secondary btn-sm' onClick={() => history.goBack()}>Volver</button>
                </Col>
            </Row>
            <Row className='my-2'>
                <Col>
                    <CardDataAlternative title="EFECTIVO" available={data.total.cash - expenseCash - parseFloat(data.total.tips)} onClick={() => onGoToReview(374)} />
                </Col>
                <Col>
                    <CardDataAlternative title="DATAFONO" available={data.total.datafono - expenseDatafono} onClick={() => onGoToReview(375)} />
                </Col>
                <Col>
                    <CardDataAlternative title="NEQUI" available={data.total.nequi - expenseNequi} onClick={() => onGoToReview(16)} />
                </Col>
                <Col>
                    <CardDataAlternative title="DAVIPLATA" available={data.total.daviplata - expenseDaviplata} onClick={() => onGoToReview(17)} />
                </Col>
            </Row>
            <Row className="my-4">
                <Col>
                    <Row className='my-2'>
                        <Col lg={3}><h3>REGISTRO DE GASTOS</h3></Col>
                        <Col lg={1} className='d-flex align-items-center'>
                            {/* <Row >
                            {colors.map(c => <BoxColor key={c.id} item={c} onClick={onSelectColor} selected={selectedColor} />)}
                        </Row> */}
                            <div className='form-group'>
                                <label>Marcador</label>
                                <input type="color" className='form-control' onChange={onSelectColor} />
                            </div>

                        </Col>
                        <Col className='d-flex align-items-center'>
                            <h4 className={`text-${selectedColor.color}`}>{titleColor}</h4>
                        </Col>
                    </Row>
                    <Row className="d-flex align-items-center">
                        <Col lg={4} md={12} >
                            <Row>
                                <Col><button className='btn btn-success' onClick={() => setModalIngreso(true)}>+ Ingreso</button></Col>
                                <Col md={4}><button className='btn btn-warning' onClick={() => openModalAddExpense()}>+ Gastos</button></Col>
                                <Col lg={5} md={4}><button className='btn btn-info' onClick={() => setModalOther(true)}>Pagar a Otro Punto</button></Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col md={3}>
                                    <div className='form-group'>
                                        <label>Fecha de Inicio</label>
                                        <input className='form-control input-sm' name='date_init' type={"date"} onChange={updateInput} value={filter.date_init} />
                                    </div>

                                </Col>
                                <Col md={3}>
                                    <div className='form-group'>
                                        <label>Fecha de Fin</label>
                                        <input className='form-control input-sm' name='date_end' type={"date"} onChange={updateInput} value={filter.date_end} />
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='form-group'>
                                        <label>Método de Pago</label>
                                        <select className='form-control' name="status_selected" value={filter.status_selected} onChange={updateInput}>
                                            <option value={0}>VER TODOS</option>
                                            {listTypePayments.map(r => <option key={r.id} value={r.id}>{r.description}</option>)}
                                        </select>
                                    </div>
                                </Col>

                                <Col md={3}>
                                    <div className='form-group'>
                                        <label>Sucursales Pagada</label>
                                        <select className='form-control' name="branch_origin_id" value={filter.branch_origin_id} onChange={updateInput}>
                                            <option value={0}>VER TODOS</option>
                                            {listBranch.map(r => <option key={r.id} value={r.id}>{r.title}</option>)}
                                        </select>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className='form-group'>
                                        <label>Grupo</label>
                                        <select className='form-control' name="group_selected" value={filter.group_selected} onChange={updateInput}>
                                            <option value={0}>VER TODOS</option>
                                            {listParameterGroup.map(r => <option key={r.id} value={r.id}>{r.description}</option>)}
                                        </select>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className='form-group'>
                                        <label>Concepto</label>
                                        <select className='form-control' name="concept_selected" value={filter.concept_selected} onChange={updateInput}>
                                            <option value={0}>VER TODOS</option>
                                            {listParameter.map(r => <option key={r.id} value={r.parameter_id}>{r.parameter.description}</option>)}
                                        </select>
                                    </div>
                                </Col>
                                <Col className='d-flex align-items-center'>
                                    <button className='btn btn-warning' onClick={onFilter}>Filtrar</button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <table className={`table table-sm table-bordered ${titleColor === '' ? '' : 'table-hover'}`}>
                        <thead className="thead-dark">
                            <tr>
                                <th>FECHA</th>
                                <th>GRUPO</th>
                                <th>CONCEPTO</th>
                                <th>OBSERVACIÓN</th>
                                <th>MÉTODO DE PAGO</th>
                                <th>GASTO</th>
                                <th>INGRESO</th>
                                <th>ACCIONES</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(listExpense.length > 0) ? listExpense.map((row, k) =>
                                <ItemTableExpense item={row} key={k} onClick={onEdit}
                                    onClickDelete={onClickDelete}
                                    role_id={user.role_id}
                                    titleColor={titleColor}
                                    selectedColor={selectedColor}
                                    onMarkExpense={onMarkExpense}
                                    onMarkTotal={onMarkTotal}
                                />) : <tr><td colSpan={6} className="text-center">No Tienes Registros</td></tr>}
                            <tr>
                                <td colSpan={5}><b>SUBTOTALES</b></td>
                                <td align='right'><b>{formatNumber(totales.expense)}</b></td>
                                <td align='right'><b>{formatNumber(totales.ingreso)}</b></td>
                            </tr>
                            <tr>
                                <td colSpan={5}><b>PROPINAS</b></td>

                                <td align='right'><b>{formatNumber(totales.tips)}</b></td>
                            </tr>
                            <tr>
                                <td colSpan={5}><b>TOTALES</b></td>

                                <td align='right'><b>{formatNumber(totales.total)}</b></td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr className="table-secondary">

                            </tr>
                        </tfoot>
                    </table>
                </Col>
            </Row >

            <ModalAddAlterExpense
                show={modal}
                listBranch={listBranch}
                listParameterGroup={listParameterGroup}
                onSubmit={onAddExpenses}
                onOpenParameter={onOpenParameter}
                listMethods={listTypePayments}
                onHide={() => setModal(false)}
                formEdit={form}
                errors={errors}
                data={data}

            />

            <ModalAddAlterIngreso
                show={modalIngreso}
                listBranch={listBranch}
                listParameterGroup={listParameterGroup}
                onSubmit={onAddIngresos}
                onOpenParameter={onOpenParameter}
                listMethods={listTypePayments}
                onHide={() => setModalIngreso(false)}
                formEdit={form}
                errors={errors}
            />

            <ModalAddAlterOtherExpense
                show={modalOther}
                listBranch={listBranch}
                listParameterGroup={listParameterGroup}
                onSubmit={onAddIngresosOther}
                onOpenParameter={onOpenParameter}
                listMethods={listTypePayments}
                onHide={() => setModalOther(false)}
                formEdit={form}
                errors={errors}
                data={data}
            />

            <ModalAddParameter
                show={modalParam.open}
                title={modalParam.title}
                dataType={modalParam.dataType}
                onHide={() => setModalParam({ ...modalParam, "open": false })}
                onSuccess={onCreateParameter}
            />
        </Container>
    </TemplateMenu>
}


const mapsState = (props) => {
    let { expense, parameter, supplier, branchoffice, report, user } = props

    return {
        fetching_list: expense.fetching_list,
        expenses: expense.data,
        errors: expense.errors,
        fetching_create: expense.fetching_create,
        fetching_delete: expense.fetching_delete,
        fetching_mark: expense.fetching_mark,
        fetching_by_segment: parameter.fetching_by_segment,
        data_segment: parameter.data_segment,
        fetching_create_param: parameter.fetching_create,
        segment: parameter.segment,
        fetching_group: parameter.fetching_group,
        data_group: parameter.data_group,
        suppliers: supplier.data,
        fetching_list_supplier: supplier.fetching_list,
        fetching_branch: branchoffice.fetching,
        branch_offices: branchoffice.data,
        fetching: report.fetching,
        review: report.data,
        user: user.data
    }
}
export default connect(mapsState, {
    getExpenses, getParameterBySegment, getBranch, getSuppliers, onCreateorUpdateExpenses, createParameter, getReviewCash,
    onMarkSelection, onMarkSelectionTotal, deleteExpensePersonal, getParameterByGroup
})(BalanceAlternativePage);