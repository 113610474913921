import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BtnWhiteHeader from '../Components/BtnWhiteHeader';
import PaginationTable from '../Components/PaginationTable';
import SearchSection from '../Components/SearchSection';
import TemplateMenu from '../../template/menu.template'
import { getListTransformation, onDeleteTransformation } from '../../Redux/transformationDuck'
import { connect } from 'react-redux';
import { formatNumber } from '../../utils';
import ItemTableTransform from './item.table.transform';


const TransformationPage = ({ getListTransformation, fetching_list, data, onDeleteTransformation, fetching_delete }) => {

    const [list, setList] = useState([])

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
        }
    }, [fetching_list])

    useEffect(() => {
        if (fetching_delete === 2) {
            getListTransformation()
        }
    }, [fetching_delete])

    useEffect(() => {
        getListTransformation()
    }, [])

    const filterContent = () => {

    }
    const onCleanFilter = () => {

    }

    return (<TemplateMenu>
        <Container fluid>
            <Row className='mt-3'>
                <Col lg={6}>
                    <Link to="/transformaciones/new" className='btn btn-info btn-sm'>Crear Producto Transformado</Link>

                </Col>


                <Col lg={6} className="pr-4 justify-content-end d-flex align-items-center">
                    <SearchSection
                        placeholder='Buscar Producto'
                        value=''
                        name='buscar_usuario'
                        onClick={filterContent}
                        onClean={onCleanFilter}
                    >

                    </SearchSection>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col>
                    <table className="table table-sm table-bordered">
                        <thead className='thead-dark'>
                            <tr>
                                <th>Id</th>
                                <th>Fecha</th>
                                <th>Producto</th>
                                <th className='text-center'>Unidades Usadas</th>
                                <th className='text-center'>Desperdicio</th>
                                {/* <th className='text-center'>Unidades Generadas</th> */}
                                <th className='text-center'>Costo Unitario</th>
                                <th className='text-center'>Costo Total</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(list.length > 0) ? list.map(row =>
                                <ItemTableTransform key={row.id} item={row} onDelete={onDeleteTransformation} />) :
                                <tr>
                                    <td colSpan={6}>No se encontraron registros</td>
                                </tr>}
                        </tbody>
                    </table>

                    {/* <PaginationTable s/> */}
                </Col>
            </Row>
        </Container>
    </TemplateMenu>
    )
}


const mapsState = (props) => {
    let { transformation } = props

    return {
        fetching_list: transformation.fetching_list,
        fetching_delete: transformation.fetching_delete,
        data: transformation.data
    }
}

export default connect(mapsState, { getListTransformation, onDeleteTransformation })(TransformationPage);