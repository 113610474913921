import React, { useState, useEffect, Fragment, useCallback, useRef } from 'react';
import RowTableClient from './row.table.client';
import { connect } from 'react-redux';

import { getAllClients } from '../../Redux/ClientDuck'

import { toogleAlert } from '../../Redux/alertDuck'
import { Row, Col, Container } from 'react-bootstrap';
import SearchSection from '../Components/SearchSection';
import BtnWhiteHeader from '../Components/BtnWhiteHeader';
import TemplateMenu from '../../template/menu.template'



const ClientPage = ({ getAllClients, data, fetching, toogleAlert,current_page,last_page, fetching_search, fetching_list }) => {

    const [list, setList] = useState([])
    const observer = useRef()
    const [status, setStatus] = useState(0)


    useEffect(() => {

        getAllClients()
        toogleAlert(true)
    }, [])

    useEffect(() => {
        if (fetching_list === 2) {
            toogleAlert(false)

            console.log('data',data);
            
            setList(data)
        }
    }, [fetching_list])

    useEffect(() => {
        if (fetching_search === 2) {
            
            setList(data)
        }
    }, [fetching_search])

    const showModalTmp = () => {

    }

    const filterContent = (txt) => {
     
    }

    const onCleanFilter = () => {
        getAllClients()
    }

    const lastBookElementRef = useCallback(node => {
        if (observer.current) observer.current.disconnect()

        // console.log('current_page',current_page)

        observer.current = new IntersectionObserver(entries => {

            if (entries[0].isIntersecting && current_page <= last_page) {
                toogleAlert(true,"Cargando datos...")
                getAllClients(parseInt(current_page) + 1)
                
            }
        })

         console.log('node',node)
        if (node) observer.current.observe(node)

    }, [current_page, status])

    return (
        <TemplateMenu>
        <Container fluid>
            <Row className="mb-3">
                <Col lg={6} className="pl-2">
                    <BtnWhiteHeader title={'Lista'} to="/clientes"/>
                    <BtnWhiteHeader title={'Crear Usuario'} onClick={showModalTmp} />
                </Col>


                <Col lg={6} className="pr-4 justify-content-end">
                    <SearchSection
                        placeholder='Buscar Usuario'
                        value=''
                        name='buscar_usuario'
                        onClick={filterContent}
                        onClean={onCleanFilter}
                        list={[{ id: 1, title: "Semanal" }]}
                    >

                    </SearchSection>
                </Col>
            </Row>

            <Row>
                <Col>
                    <table className="table table-bordered table-sm table-hover">
                        <thead className='thead-dark'>
                            <tr>
                                <th>Direcciones</th>
                                <th>Nombre Cliente</th>
                                <th>Correo Electrónico</th>
                                <th>No. Celular</th>
                                <th>Documento</th>
                                <th>Modificar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(list !== undefined) && list.map((row,i) => <RowTableClient 
                            item={row} 
                            index={i}
                            key={i}
                            refs={lastBookElementRef}  />)}
                        </tbody>


                    </table>
                </Col>
            </Row>
        </Container>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    
    
    let { data, fetching_list,current_page,last_page,fetching_search } = props.client
    

    return {
        data:data,
        
        fetching_list:fetching_list,
        current_page,
        last_page,
        fetching_search
    }
}

export default connect(mapsState, { getAllClients, toogleAlert })(ClientPage);