import moment from "moment";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { formatNumber } from "../../utils";
import { MdDangerous } from "react-icons/md"
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const ItemTableInventory = ({ row, index, onReloadDate }) => {
  let color = "warning";

  if (row.type_move_id === 1) {
    color = 'success'
  } else {
    if (row.move_id === 4) {
      color = 'danger'
    } else {
      color = 'warning'
    }
  }

  if (row.move_id === 1) {
    color = "secondary";
  }

  if (row.move_id === 7) {
    color = "warning";
  }


  const formatFloat = (amount) => {
    // if (amount.indexOf(".") !== -1) {
    //     if (amount.length > 4) {
    //         return parseFloat(amount).toFixed(3)
    //     }
    // }

    return parseFloat(amount);
  }

  const formatFloatMoney = (amount) => {
    return formatNumber(parseFloat(amount).toFixed(3));

    // if (amount.toString().indexOf(".") === -1) {
    //     return formatNumber(parseFloat(amount))
    // } else {
    //     return formatNumber(parseFloat(amount).toFixed(3))
    // }
  };

  return (
    <tr className={`table-${color}`}>
      <td>{index}</td>
      <td>
        {row.registration_date_old === null ? <Row className="p-0 m-0">
          <Col>{moment(row.registration_date).format("YYYY-MM-DD")} {moment(row.registration_date).format("HH:mm:ss")}</Col>
        </Row> : <>
          <Row className="p-0 m-0">
            <Col>{moment(row.registration_date).format("YYYY-MM-DD")} {moment(row.registration_date).format("HH:mm:ss")}</Col>
          </Row>
          <Row className="p-0 m-0">
            <Col className="font-12">
              <button className="btn btn-sm btn-danger" onClick={() => onReloadDate(row)}>{moment(row.registration_date_old).format("YYYY-MM-DD")}<MdDangerous size={15} /></button>
            </Col>
          </Row>
        </>}
      </td>
      <td>
        {row.move_id === 3 ? <Link to={`/compras/${row.transaction_id}`}>{row.description}</Link> : row.description}
        ({formatFloatMoney(row.cost)})
      </td>
      <td align="right">{formatFloatMoney(row.cost_average)}</td>
      {row.type_move_id === 1 ? (
        <>
          <td align="right">{formatFloat(row.amount)}</td>
          <td align="right">{formatFloatMoney(row.cost * row.amount)}</td>
          <td></td>
          <td></td>
        </>
      ) : (
        <>
          <td></td>
          <td></td>
          <td align="right">{formatFloat(row.amount)}</td>
          <td align="right">{formatFloatMoney(row.cost * row.amount)}</td>
        </>
      )}

      <td align="right">
        {formatFloat(parseFloat(row.amount_total).toFixed(3))}
      </td>
      <td align="right">{formatFloatMoney(row.cost_total)}</td>
    </tr>
  );
};

export default ItemTableInventory;
