import React from 'react';
import moment from 'moment'
import { Link } from 'react-router-dom';
import { AiFillCloseSquare } from 'react-icons/ai';
import { MdEditNote, MdOutlineListAlt } from "react-icons/md";


const RowTableWorker = ({ item, onEdit, user, onDelete }) => {
    return (
        <tr className={`p-0 mx-0 border-bottom active_list_reservation m-0 w-100 ${item.status === true ? '' : 'table-secondary'}`}>
            <td className="text-center border-right"><span className="font-15 pt-3 pb-2">{item.name}</span></td>
            <td className="text-center border-right"><span className="font-15 pt-3 pb-2">{item.document}</span></td>
            <td className="text-center border-right"><span className="font-15 pt-3 pb-2">{item.country.description}</span></td>
            <td className="text-center border-right"><span className="font-15 pt-3 pb-2">{item.position.description}</span></td>
            <td className="text-center border-right"><span className="font-15 pt-3 pb-2">{item.branch_office.title}</span></td>
            <td className="text-center border-right"><span className="font-15 pt-3 pb-2">{item.area.description}</span></td>
            <td className="text-center border-right"><span className="font-15 pt-3 pb-2">{item.date_of_admission}</span></td>
            <td className={`text-center border-right ¡${item.status === true ? '' : 'table-secondary'}`}><span className="font-15 pt-3 pb-2">{item.status_text}</span></td>
            <td>
                <MdEditNote className='cursor-pointer' onClick={() => onEdit(item)} size={30} />
                <Link className='btn btn-success btn-sm mx-1' to={`/trabajadores/${item.id}`}>Novedades</Link>
                <Link className='cursor-pointer' to={`/trabajadores-documents/${item.id}`}><MdOutlineListAlt size={30} /></Link>
                {/* {(user.role_id === 1 || user.role_id === 2 )&& <AiFillCloseSquare size={25} onClick={() => onDelete(item)} className="cursor-pointer"/>} */}
            </td>
            <td>

            </td>
        </tr>
    )
}
export default RowTableWorker;