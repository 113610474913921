import axios from 'axios'
import React, { createContext, useState, useCallback, useContext } from 'react'
import { getHeaders, saveStorage } from '../utils'
// import { fetchSinToken, fetchConToken } from '../helpers/fetch'
// import { ChatContext } from '../context/chat/ChatContext'
// import { types } from '../types/types'
const API_URL = process.env.REACT_APP_API_URL


export const AuthContext = createContext()


const initialState = {
    checking: false,
    logged: false,
}


export const AuthProvider = ({ children }) => {


    const [auth, setAuth] = useState(initialState)

    // const {chatState,dispatch} = useContext(ChatContext)

    const login = async (email, password) => {
        // const resp = await fetchSinToken("login",{email,password},"POST");

        // if(resp.ok){
        //     localStorage.setItem("token",resp.token)
        //     setAuth({
        //         uid:resp.user.uid,
        //         logged:true,
        //         checking:false,
        //         name:resp.user.name,
        //         email:resp.user.email,
        //     })
        // }else{

        // }


        // return resp.ok;
        return;
    }

    const register = async (name, email, password) => {
        // const resp = await fetchSinToken("login/new",{name,email,password},"POST");

        // if(resp.ok){
        //     localStorage.setItem("token",resp.token)
        //     setAuth({
        //         uid:resp.user.uid,
        //         logged:true,
        //         checking:false,
        //         name:resp.user.name,
        //         email:resp.user.email,
        //     })
        // }else{

        // }

        // return resp.ok;
        return;
    }

    const verificaToken = useCallback(async () => {
        const token = localStorage.getItem("access_token")

        if (!token) {
            setAuth(initialState)
            return false
        }


        axios.get(`${API_URL}/api/renew`, {
            headers: getHeaders()

        }).then(resp => {
            saveStorage(resp.data);
        }).catch(function (error) {
            console.log(error);
        });



        // if(resp.ok){
        //     localStorage.setItem("token",resp.token)
        //     setAuth({
        //         uid:resp.user.uid,
        //         logged:true,
        //         checking:false,
        //         name:resp.user.name,
        //         email:resp.user.email,
        //     })

        //     return true
        // }else{
        //     setAuth(initialState)
        //     return false;
        // }

    }, []);


    const logout = () => {
        localStorage.removeItem("token")
        setAuth(initialState)
        // dispatch({
        //     type:types.cerrarSession
        // })
    }

    return (
        // <AuthContext.Provider value={{ auth, login, register, verificaToken, logout }}>
        <AuthContext.Provider value={{ auth, verificaToken }}>
            {children}
        </AuthContext.Provider>
    )
}