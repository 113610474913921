import React from "react";
import { Link } from "react-router-dom";

const ItemTableCoupon = ({ item, onEdit }) => {
  let value = item.is_percent === true ? `${item.value}%` : `${item.value}`;

  return (
    <tr>
      <td>{item.name}</td>
      <td>{item.code}</td>
      <td>{item.amount}</td>
      <td>{value}</td>
      <td>{item.delivered}</td>
      <td>{item.current_amount}</td>
      <td>{item.date_init}</td>
      <td>{item.date_end}</td>
      <td>{item.status === true ? "Activo" : "Inactivo"}</td>
      <td>
        <Link to={`cupones/${item.id}`} className="btn btn-info btn-sm">
          validar
        </Link>
        <button
          className="btn btn-warning btn-sm mx-2"
          onClick={() => onEdit(item)}
        >
          Edit
        </button>
      </td>
    </tr>
  );
};

export default ItemTableCoupon;
