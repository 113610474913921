import React from 'react'
import { Card, Col, Row } from 'react-bootstrap';
const API_URL = process.env.REACT_APP_API_URL


const ItemGroupDiningTable = ({ item, deleteCategory, editCategory }) => {
    let { branch_office } = item
    let image = `${API_URL}/${item.qr}`;

    return (
        <Col lg={4} md={6} className="mt-2 ">
            <Card className="active_list_reservation rounded-lg">
                <Card.Body>
                    <Row className="bg-white border-dot rounded-lg p-2 mx-1">
                        <Col lg={6} md={6}>
                            <Row className="text-gris justify-content-center">Mesa</Row>
                            <Row className="justify-content-center ">
                                <h5 className="text-naranja">{item.position}</h5>
                            </Row>
                        </Col>
                        <Col lg={6} md={6}>
                            <Row className="text-gris justify-content-center">Capacidad</Row>
                            <Row className="justify-content-center text-naranja">{item.people} Personas</Row>
                        </Col>
                        <Col lg={6} md={6}>
                            <Row className="text-gris justify-content-center">Estado</Row>
                            <Row className="justify-content-center text-naranja">{item.status_id === true ? 'Activo' : 'Inactivo'}</Row>
                        </Col>
                        <Col lg={6} md={6}>
                            <Row className="text-gris justify-content-center">Sucursal</Row>
                            <Row className="justify-content-center text-naranja">{branch_office.title}</Row>
                        </Col>
                    </Row>
                    <Row className="justify-content-center pt-4">
                        <h3 className="font-title font-13">CÓDIGO QR </h3>
                    </Row>

                    <Row className="justify-content-center">
                        <Col lg={9} md={12} className="justify-content-center d-flex">
                            <img src={image} />
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Body className="pt-0 pb-2">
                    <Row className="justify-content-end border-top">
                        <Col lg={6} md={6} className="justify-content-center  d-flex">
                            <button className="text-gris py-2 bg-light w-100 mt-1 rounded-lg" onClick={() => deleteCategory(item)} >Eliminar</button>

                        </Col>
                        <Col lg={6} md={6} className="justify-content-center">
                            <button className="text-white py-2 bg-naranja w-100 mt-1 rounded-lg" onClick={() => editCategory(item)} >Editar</button>

                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Col>
    )
}

export default ItemGroupDiningTable;