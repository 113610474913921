import axios from 'axios'
import { getHeaders, validateBranch } from '../utils'
const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_save: 0,
    fetching_schedule: 0,
    current_page: 1,
    data: [],
    schedule: [],
    selected: {},
    errors: {},
    branch: {}
}

let BRANCH = "BRANCH";
let BRANCH_SUCCESS = "BRANCH_SUCCESS";
let BRANCH_ERROR = "BRANCH_ERROR";
let BRANCH_SAVE = "BRANCH_SAVE";
let BRANCH_SAVE_SUCCESS = "BRANCH_SAVE_SUCCESS";
let SCHEDULE = "SCHEDULE";
let SCHEDULE_SUCCESS = "SCHEDULE_SUCCESS";

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case BRANCH:
            return { ...state, fetching: 1 }
        case BRANCH_SUCCESS:
            return { ...state, fetching: 2, data: action.payload, branch: action.branch }
        case SCHEDULE:
            return { ...state, fetching_schedule: 1 }
        case SCHEDULE_SUCCESS:
            return { ...state, fetching_schedule: 2, ...action.payload }
        case BRANCH_SAVE:
            return { ...state, fetching_save: 1 }
        case BRANCH_SAVE_SUCCESS:
            return { ...state, fetching_save: 2 }

        case BRANCH_ERROR:
            return { ...state, errors: action.payload, fetching: 3 }
        default:
            return state;
    }
}


export let getBranch = () => (dispatch, getState) => {
    dispatch({
        type: BRANCH
    })

    if (getState().user.loggedIn) {

        let list_branch = JSON.parse(localStorage.getItem("list_branch"));

        if (list_branch == null) {
            axios.get(`${API_URL}/api/branch-office-admin`, {
                headers: getHeaders()

            }).then(resp => {

                let branch = JSON.parse(localStorage.getItem("branch"));
                dispatch({
                    type: BRANCH_SUCCESS,
                    payload: resp.data.results,
                    branch
                });

                localStorage.setItem("list_branch", JSON.stringify(resp.data.results))

            }).catch(function (error) {
                console.log(error);
            });
        } else {
            let branch = JSON.parse(localStorage.getItem("branch"));

            dispatch({
                type: BRANCH_SUCCESS,
                payload: list_branch,
                branch
            });
        }


    } else {
        console.log("no Ingreso");
    }
}


export let getListBranch = () => (dispatch, getState) => {
    dispatch({
        type: BRANCH
    })

    if (getState().user.loggedIn) {


        axios.get(`${API_URL}/api/list-branch-office`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: BRANCH_SUCCESS,
                payload: resp.data.results
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}


export let createBranch = (form, schedule) => (dispatch, getState) => {

    dispatch({
        type: BRANCH_SAVE
    })

    let validate = validateBranch(form);


    if (Object.keys(validate).length > 0) {
        dispatch({
            type: BRANCH_ERROR,
            payload: validate
        })
        return;
    }

    form.schedule = schedule

    if (getState().user.loggedIn) {
        axios.post(`${API_URL}/api/branch-office-admin`, form, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: BRANCH_SAVE_SUCCESS
            });

        }).catch(error => {
            let err = {}

            for (const [key, value] of Object.entries(error.response.data.errors)) {
                if ('title' === key) {
                    err.title = value[0]
                }
                if ('description' === key) {
                    err.description = value[0]
                }
            }

            dispatch({
                type: BRANCH_ERROR,
                payload: err,
                form: form
            })
        });
    } else {
        console.log("no Ingreso");
    }
}

export let updateBranch = (form, schedule) => (dispatch, getState) => {

    dispatch({
        type: BRANCH_SAVE
    })


    let validate = validateBranch(form);

    if (Object.keys(validate).length > 0) {
        dispatch({
            type: BRANCH_ERROR,
            payload: validate
        })
        return;
    }

    form.schedule = schedule

    if (getState().user.loggedIn) {

        axios.put(`${API_URL}/api/branch-office-admin/${form.id}`, form, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: BRANCH_SAVE_SUCCESS
            });

        }).catch(error => {
            let err = {}

            console.log('error', error);


            for (const [key, value] of Object.entries(error.response.data.errors)) {
                if ('title' === key) {
                    err.title = value[0]
                }
                if ('description' === key) {
                    err.description = value[0]
                }
            }

            dispatch({
                type: BRANCH_ERROR,
                payload: err,
                form: form
            })
        });
    } else {
        console.log("no Ingreso");
    }
}

export let deleteBranch = (form) => (dispatch, getState) => {

    dispatch({
        type: BRANCH_SAVE
    })

    if (getState().user.loggedIn) {

        axios.delete(`${API_URL}/api/branch-office-admin/${form.id}`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: BRANCH_SAVE_SUCCESS
            });

        }).catch(error => {
            let err = {}

            for (const [key, value] of Object.entries(error.response.data.errors)) {
                if ('title' === key) {
                    err.title = value[0]
                }
                if ('description' === key) {
                    err.description = value[0]
                }
            }

            dispatch({
                type: BRANCH_ERROR,
                payload: err,
                form: form
            })
        });
    } else {
        console.log("no Ingreso");
    }
}

export let deleteItemBranch = (form) => (dispatch, getState) => {

    console.log("updateBranch", form)

    dispatch({
        type: BRANCH
    })

    if (getState().user.loggedIn) {

        axios.delete(`${API_URL}/api/delete-item-day/${form.id}`, form, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: BRANCH_SUCCESS,
                payload: resp.data.results,
            });

        }).catch(error => {
            let err = {}

            for (const [key, value] of Object.entries(error.response.data.errors)) {
                if ('title' === key) {
                    err.title = value[0]
                }
                if ('description' === key) {
                    err.description = value[0]
                }
            }

            dispatch({
                type: BRANCH_ERROR,
                payload: err,
                form: form
            })
        });
    } else {
        console.log("no Ingreso");
    }
}



