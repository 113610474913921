import React from 'react'
import { formatNumber } from '../../utils';

const ItemDetailPayment = ({ row, onRemoveItem }) => {
    let { worker } = row

    return (
        <tr>
            <td>{worker.name}</td>
            <td>{formatNumber(row.amount)}</td>
            <td><button className='btn btn-sm btn-danger' onClick={onRemoveItem}>X</button></td>
        </tr>
    )
}

export default ItemDetailPayment;