import axios from 'axios'
import { getHeaders, removeSession } from '../utils';

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching_list: 0,
    fetching_create: 0,
    fetching_by_id: 0,
    data: [],
    data_group: [],
    selected: {},
    errors: {}
}

let LIST_GROUP = "LIST_GROUP";
let LIST_GROUP_SUCCESS = "LIST_GROUP_SUCCESS";
let CREATE_GROUP = "CREATE_GROUP"
let CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS"
let LIST_MODIFIER = "LIST_MODIFIER";
let LIST_MODIFIER_SUCCESS = "LIST_MODIFIER_SUCCESS";
let CREATE_MODIFIER = "CREATE_MODIFIER"
let CREATE_MODIFIER_SUCCESS = "CREATE_MODIFIER_SUCCESS"
let ASSOCIATE_GROUP_MODIFIER = "ASSOCIATE_GROUP_MODIFIER"
let ASSOCIATE_GROUP_MODIFIER_SUCCESS = "ASSOCIATE_GROUP_MODIFIER_SUCCESS"
let MODIFIER_ID = "MODIFIER_ID"
let MODIFIER_ID_SUCCESS = "MODIFIER_ID_SUCCESS"



export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_GROUP:
            return { ...state, fetching_list: 1, fetching_create: 0 }
        case LIST_GROUP_SUCCESS:
            return { ...state, fetching_list: 2, data_group: action.payload }
        case CREATE_GROUP:
            return { ...state, fetching_create: 1 }
        case CREATE_GROUP_SUCCESS:
            return { ...state, fetching_create: 2 }
        case LIST_MODIFIER:
            return { ...state, fetching_list: 1, fetching_create: 0 }
        case LIST_MODIFIER_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        case CREATE_MODIFIER:
            return { ...state, fetching_create: 1 }
        case CREATE_MODIFIER_SUCCESS:
            return { ...state, fetching_create: 2 }
        case ASSOCIATE_GROUP_MODIFIER:
            return { ...state, fetching_create: 1 }
        case ASSOCIATE_GROUP_MODIFIER_SUCCESS:
            return { ...state, fetching_create: 2 }
        case MODIFIER_ID:
            return { ...state, fetching_by_id: 1 }
        case MODIFIER_ID_SUCCESS:
            return { ...state, fetching_by_id: 2, row: action.payload }

        default:
            return state;
    }
}


export let selectedDefaultModifier = (form) => (dispatch, getState) => {

    // dispatch({
    //     type:MODIFIER_CREATE
    // })

    if (getState().user.loggedIn) {
        let token = localStorage.getItem("access_token")

        axios.put(`${API_URL}/api/modifier-default/${form.modifiers_assoc_id}`, form, {
            headers: {
                Authorization: 'Bearer ' + token //the token is a variable which holds the token
            }
        }).then(resp => {

            // dispatch({
            //     type:MODIFIER_CREATE_SUCCESS,
            //     payload:resp.data.results,
            // });

        }).catch(err => {

        });
    } else {
        console.log("no Ingreso");
    }
}

export let createGroup = (form) => (dispatch, getState) => {

    console.log("createOrder", form)
    dispatch({
        type: CREATE_GROUP
    })

    if (getState().user.loggedIn) {
        let token = localStorage.getItem("access_token")

        axios.post(`${API_URL}/api/modifier-group`, form, {
            headers: {
                Authorization: 'Bearer ' + token //the token is a variable which holds the token
            }
        }).then(resp => {
            dispatch({
                type: CREATE_GROUP_SUCCESS,
                payload: resp.data.results,
            });

        }).catch(error => {
            let err = {}

            for (const [key, value] of Object.entries(error.response.data.errors)) {
                if ('title' === key) {
                    err.title = value[0]
                }

                if ('description' === key) {
                    err.description = value[0]
                }

                if ('priority' === key) {
                    err.priority = value[0]
                }
            }

            // dispatch({
            //     type: MODIFIER_GROUP_ERROR,
            //     payload: err,
            //     form:form
            // })
        });
    } else {
        console.log("no Ingreso");
    }
}


export let createModifier = (form) => (dispatch, getState) => {

    console.log("createOrder", form)
    dispatch({
        type: CREATE_MODIFIER
    })


    if (getState().user.loggedIn) {
        let token = localStorage.getItem("access_token")

        axios.post(`${API_URL}/api/modifiers`, form, {
            headers: {
                Authorization: 'Bearer ' + token //the token is a variable which holds the token
            }
        }).then(resp => {
            dispatch({
                type: CREATE_MODIFIER_SUCCESS,
                payload: resp.data.results
            });

        }).catch(function (error) {
            let err = { title: '' }

            for (const [key, value] of Object.entries(error.response.data.errors)) {
                if ('title' === key) {
                    err.title = value[0]
                }
                if ('description' === key) {
                    err.description = value[0]
                }
                if ('priority' === key) {
                    err.priority = value[0]
                }
                if ('price' === key) {
                    err.price = value[0]
                }

            }

            // dispatch({
            //     type: MODIFIER_ERROR,
            //     payload: err,
            //     form:form
            // })
        });
    } else {
        console.log("no Ingreso");
    }
}

export let createAssociation = (form) => (dispatch, getState) => {

    console.log("createOrder", form)
    dispatch({
        type: ASSOCIATE_GROUP_MODIFIER
    })


    if (getState().user.loggedIn) {

        axios.post(`${API_URL}/api/associate-modifiers`, form, {
            headers: getHeaders()

        }).then(resp => {
            dispatch({
                type: ASSOCIATE_GROUP_MODIFIER_SUCCESS,
                payload: resp.data.results
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let deleteGroupById = (id) => (dispatch, getState) => {
    // dispatch({
    //     type:MODIFIER,
    //     payload:[]
    // })

    if (getState().user.loggedIn) {

        axios.delete(`${API_URL}/api/modifier-group/${id}`, {
            headers: getHeaders()

        }).then(resp => {
            // dispatch({
            //     type:MODIFIER_SUCCESS,
            //     payload:resp.data
            // });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}


export let deleteModifierById = (id) => (dispatch, getState) => {
    // dispatch({
    //     type:MODIFIER,
    //     payload:[]
    // })

    if (getState().user.loggedIn) {
        let token = localStorage.getItem("access_token")

        axios.delete(`${API_URL}/api/modifiers/${id}`, {
            headers: {
                Authorization: 'Bearer ' + token //the token is a variable which holds the token
            }
        }).then(resp => {
            // dispatch({
            //     type:MODIFIER_SUCCESS,
            //     payload:resp.data
            // });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let deleteAssociateById = (item) => (dispatch, getState) => {
    // dispatch({
    //     type:GROUP,
    //     payload:[]
    // })

    if (getState().user.loggedIn) {
        let token = localStorage.getItem("access_token")

        axios.delete(`${API_URL}api/delete-associate/${item.modifiers_assoc_id}`, {
            headers: {
                Authorization: 'Bearer ' + token //the token is a variable which holds the token
            }
        }).then(resp => {
            // dispatch({
            //     type:GROUP_SUCCESS,
            //     payload:resp.data.results
            // });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}



export let getModifierById = (id) => (dispatch, getState) => {
    dispatch({
        type: MODIFIER_ID,
        payload: []
    })

    if (getState().user.loggedIn) {
        let token = localStorage.getItem("access_token")

        axios.get(`${API_URL}/api/modifiers/${id}`, {
            headers: {
                Authorization: 'Bearer ' + token //the token is a variable which holds the token
            }
        }).then(resp => {
            dispatch({
                type: MODIFIER_ID_SUCCESS,
                payload: resp.data
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}


export let getModifiers = () => (dispatch, getState) => {
    dispatch({
        type: LIST_MODIFIER
    })

    if (getState().user.loggedIn) {
        let token = localStorage.getItem("access_token")

        axios.get(`${API_URL}/api/modifiers`, {
            headers: {
                Authorization: 'Bearer ' + token //the token is a variable which holds the token
            }
        }).then(resp => {
            dispatch({
                type: LIST_MODIFIER_SUCCESS,
                payload: resp.data.results
            });

        }).catch(error => {
            let { status } = error.response

            if (status == 401) {
                removeSession()
                // dispatch({
                //     type:LOSE_SESSION
                // }); 
            }
        });
    } else {
        console.log("no Ingreso");
    }
}

export let getGroups = () => (dispatch, getState) => {
    dispatch({
        type: LIST_GROUP
    })

    if (getState().user.loggedIn) {
        let token = localStorage.getItem("access_token")

        axios.get(`${API_URL}/api/modifier-group`, {
            headers: {
                Authorization: 'Bearer ' + token //the token is a variable which holds the token
            }
        }).then(resp => {
            dispatch({
                type: LIST_GROUP_SUCCESS,
                payload: resp.data.results
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}
