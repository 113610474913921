import React, { useEffect, useState } from 'react'

const RowTableInitial = ({ item, onUpdateRow }) => {
    const [form, setForm] = useState({ amount: 0, cost: 0 })


    useEffect(() => {
        setForm({
            ...form,
            amount: item.amount === null ? 0 : item.amount,
            cost: item.cost_average
        })
    }, [item])

    const updateField = (e) => {
        let value = e.target.value.replace(/[^0-9\.]+/g, '');

        setForm({
            ...form,
            [e.target.name]: value
        })
    }
    const updateFieldBlur = (e) => {
        let _form = form
        _form.id = item.id
        _form.initial_inventory_id = item.initial_inventory_id
        _form.inventory_id = item.inventory_id
        console.log('_form', _form);


        onUpdateRow(_form)
    }

    return (
        <tr>
            <td>{item.name}</td>
            <td>{item.measure_unit}</td>
            <td>
                <input className='form-control input-sm text-right' min={0} name="amount" value={form.amount} onChange={updateField}
                    onBlur={updateFieldBlur}
                />
            </td>
            <td>
                <input className='form-control input-sm text-right' name='cost' value={form.cost} onChange={updateField}
                    onBlur={updateFieldBlur} />
            </td>

        </tr>
    )
}

export default RowTableInitial;