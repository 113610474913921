import React, { useEffect, Fragment } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { acceptPartialOrder, onFreeDining } from '../../Redux/orderDuck'
import { toogleAlert } from '../../Redux/alertDuck'
import BlueTitleBack from '../Components/Widgets/BlueTitleBack'
import BtnBlueModal from '../Components/Widgets/BtnBlueModal'
import { MdClose } from "react-icons/md";

const API_URL = process.env.REACT_APP_API_URL


const DetailModal = ({ show, current, data, onClose, onSuccessFull, acceptPartialOrder, fetching, onFreeDining, silenceDuck
    , reservation, toogleAlert }) => {

    let { user } = reservation || [];


    useEffect(() => {
        if (fetching === 2) {
            toogleAlert(false)
            silenceDuck();
            onSuccessFull()
        }
    }, [fetching])


    useEffect(() => {

    }, [data])


    const goToPartial = () => {

        let detail = data.filter(row => row.status_id === 2);

        if (detail.length > 0) {
            toogleAlert(true)
            acceptPartialOrder(reservation)
        } else {
            alert("No tienes Pedientes")
        }
    }

    const gotoFreeDinitable = () => {
        onFreeDining(reservation.id)
    }

    const printLeft = (item, i) => {
        let image = API_URL + item.image
        return (
            <Fragment key={i}>


                <Row className="bg-orange-light border-box-product p-2 mx-2 rounded-lg my-1 active_list_reservation">
                    <Col lg={2} className="d-flex justify-content-start align-items-center px-0 m-0">
                        <img src={image} className="img_tumbl" />
                    </Col>
                    <Col lg={7} className="d-flex flex-column justify-content-center align-items-start border-dot-naranja">
                        <h4 className="font-15 my-0 d-block text-left pb-1 text-azul">
                            {item.title}
                        </h4>

                    </Col>
                    <Col lg={3} className="d-flex flex-column justify-content-center align-items-start mx-0 p-0">
                        <span className="font-13 text-gris  d-block text-center w-100 ml-1">Cantidad</span>
                        <h4 className="my-0 d-block text-center pb-1 text-naranja h1 w-100 ml-1">{item.quantity}.</h4>
                    </Col>
                </Row>


            </Fragment>

        )
    }
    const printPending = (item, i) => {
        let image = API_URL + item.image

        return (
            <Fragment key={i}>


                <Row className=" p-2 mx-2 rounded-lg my-1 cursor marcado">
                    <Col lg={2} className="d-flex justify-content-start align-items-center px-0 m-0">
                        <img src={image} className="img_tumbl" />
                    </Col>
                    <Col lg={7} className="d-flex flex-column justify-content-center align-items-start border-dot-naranja">
                        <h4 className="font-15 my-0 d-block text-left pb-1 text-azul">
                            {item.title}
                        </h4>

                    </Col>
                    <Col lg={3} className="d-flex flex-column justify-content-center align-items-start mx-0 p-0">
                        <span className="font-13 text-gris  d-block text-center w-100 ml-1">Cantidad</span>
                        <h4 className="my-0 d-block text-center pb-1 text-naranja h1 w-100 ml-1">{item.quantity}.</h4>
                    </Col>
                </Row>
            </Fragment>
        )
    }


    return (
        <Modal show={show} size="xl" onHide={onClose}>

            <Modal.Header className="border-bottom">
                <Row className="mx-0 w-100 py-1 d-flex align-items-center config_btn">
                    <button type="" className="p-0 mx-0 my-1  rounded-lg section-card-table" onClick={gotoFreeDinitable}>
                        <span className="px-4 py-2 rounded-pill bg-azul text-white mr-3 close-table">Cerrar Mesa</span>
                    </button>
                    <span className="font-13 text-gris  d-block text-left pr-2">Reservado por</span>
                    <span className="font-13 text-naranja  d-block text-left border-right pr-3">{(user !== undefined ? user.name : '')}</span>
                    <span className="font-13 text-gris  d-block text-left pr-2 ml-3">Hora</span>
                    <span className="font-13 text-naranja  d-block text-left  pr-3">
                        {(reservation !== undefined) ? reservation.content_hour : 0}
                    </span>
                    <span className="font-13 text-gris  d-block text-left pr-2 border-left pl-3">No. Orden</span>
                    <span className="font-13 text-naranja  d-block text-left pl2">{reservation !== undefined ? reservation.consecutive : 0} </span>
                </Row>
                <button type="button" className="close" aria-label="Close" onClick={onClose}>
                    <span aria-hidden="true">
                        <MdClose />
                    </span>
                </button>
            </Modal.Header>

            <Modal.Body className="p-0 m-0">
                <Row className="m-0 px-0 pb-3">
                    <Col lg={6} className="px-0  border-right d-flex flex-column justify-content-start">
                        <div className="py-2">
                            <BlueTitleBack title='historial de pedidos' />
                        </div>

                        {data.filter(row => row.status_id === 3).map(printLeft)}


                    </Col>
                    <Col lg={6} className=" d-flex flex-column justify-content-start">
                        <div className="py-2">
                            <BlueTitleBack title='órdenes pendientes' />
                        </div>
                        {data.filter(row => row.status_id === 2).map(printPending)}

                    </Col>
                </Row>

            </Modal.Body>

            <Modal.Footer className="border-top">
                <Row className="w-100 mx-0 px-0">
                    <Col lg={6} className="d-flex justify-content-start align-items-center mx-0 px-0">
                        <Row className="w-100 mx-0 px-0">
                            <Col lg={6} className="mx-0 px-0 d-flex  align-items-center">
                                <span className="text-gris font-13 ">Total Parcial</span>
                                <span className="text-azul h3 my-0 py-0 ml-3">$ 0</span>
                            </Col>
                            <Col lg={6} className="d-flex justify-content-end mx-0 pl-4">
                                <BtnBlueModal title='Ir al Pago' />
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={6}>
                        <Row className="m-0 p-0">
                            <Col lg={5} className="mx-0 px-0 d-flex  align-items-center">
                                <span className="text-gris font-13 ">Órdenes Pendientes</span>
                                <span className="text-azul h3 my-0 py-0 ml-3">1.</span>
                            </Col>
                            <Col lg={7} className="d-flex justify-content-end mx-0 pl-4 pr-0">
                                <BtnBlueModal title='Marchar Productos' onClick={goToPartial} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal.Footer>


        </Modal>
    )
}

const mapsState = (props) => {
    return {
        data: props.order.data,
        reservation: props.order.reservation,
        fetching: props.order.fetching_partial
    }
}

export default connect(mapsState, { acceptPartialOrder, onFreeDining, onFreeDining, toogleAlert })(DetailModal);