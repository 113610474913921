import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import { AiFillFilter } from 'react-icons/ai';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getWorkerById } from '../../Redux/workerDuck'
import { createPayroll, deletePayrollOtherDeduction, deletePayrollOtherAdditional, getTimerWorker } from '../../Redux/payrollDuck'
import { toogleLoader } from '../../Redux/alertDuck'
import { onGetConfiguration, createParameter, getParameterBySegment } from '../../Redux/parameterDuck'
import { formatNumber } from '../../utils';
import ModalAddNovelty from '../Workers/ModalAddNovelty';
import ItemPayment from './item.payment';
import ModalAddParameter from '../Components/ModalAddParameter';
import uuid from 'react-uuid';
import ModalDeduction from './modal.deduction';
import { useToasts } from 'react-toast-notifications';
import ItemNovelty from './item.novelty';
import ModalAdditionalPayment from './modal.additional.payment';
import TemplateMenu from '../../template/menu.template'
import { MdEdit } from 'react-icons/md';
import ModalWorker from '../Workers/ModalWorker';


const q = `?f=true`

const EditNominaPage = ({ getWorkerById, selected, fetching_by_id, onGetConfiguration, fetching_list_config, data_config, createParameter, getParameterBySegment,
    data_segment, fetching_by_segment, segment, fetching_create, createPayroll, errors, fetching_create_payroll, toogleLoader,
    deletePayrollOtherDeduction, deletePayrollOtherAdditional, fetching_delete, getTimerWorker, fetching_timer, data_worker,
    user }) => {
    let { id } = useParams()
    // const current_date = moment().format("YYYY-MM-DD");
    const current_date = parseInt(moment().format("DD"));

    let date_init = (current_date >= 20) ? moment().format("YYYY-MM-16") : moment().format("YYYY-MM-01");
    let date_end = current_date > 20 ? moment().endOf('month').format("YYYY-MM-DD") : moment().format("YYYY-MM-15");

    const [worker, setWorker] = useState({ name: '', position: { description: '' }, branch_office: {}, bonification: '' })
    const [pension, setPension] = useState([])
    const [health, setHealth] = useState([])
    const [transport, setTranport] = useState(0)
    const [salaryDay, setSalaryDay] = useState(0)
    const [bonificationDay, setBonificationDay] = useState(0)
    const [hours, setHours] = useState(0)
    const [showDate, setShowDate] = useState({ show: false, title: 'Por fecha' })
    const history = useHistory();
    let d = (moment.duration(moment(date_init).diff(moment(date_end).format("YYYY-MM-DD"))).asDays() * -1) + 1
    const [diff, setDiff] = useState(d > 15 ? 15 : d)


    const [form, setForm] = useState({
        salary: 0,
        date_init: date_init, date_end: date_end,
        salary_by_day: 0, salary_by_hour: 0, extra_hours: 0,
        worked_days: diff > 15 ? 15 : diff, total: 0, extra_hours: 0,
        alter_date_id: 0, legal_id: 0, pension: 0, health: 0,
        pension_value: 0, health_value: 0,
    })

    const [modalParam, setModalParam] = useState({ open: false, title: 'Pendiente' });

    const [modal, setModal] = useState(false)
    const [modalDeduction, setModalDeduction] = useState(false)
    const [modalPayment, setModalPayment] = useState(false)

    const [listNovelty, setListNovelty] = useState([])
    const [listPayments, setListPayments] = useState([])
    const [listParameter, setListParameter] = useState([])
    const [listOther, setListOther] = useState([])
    const [listAditional, setListAditional] = useState([])
    const [lastParam, setLastParam] = useState({})
    const [modalWorker, setModalWorker] = useState(false)

    const [listCountry, setListCountry] = useState([])
    const [listPosition, setListPosition] = useState([])
    const [listArea, setListArea] = useState([])
    const [listEps, setListEps] = useState([])
    const [listArl, setListArl] = useState([])
    const [listTypePayment, setListTypePayment] = useState([])

    const last_month = moment().subtract(1, 'month')

    const [listDates, setListDates] = useState([
        {
            id: 1, text: last_month.format("MMM 01") + " a " + last_month.format("MMM 15"), init: last_month.format("YYYY-MM-01"), end: last_month.format("YYYY-MM-15")
        },
        {
            id: 2, text: last_month.format("MMM 16") + " a " + last_month.endOf('month').format("MMM DD"), init: last_month.format("YYYY-MM-16"), end: last_month.endOf('month').format("YYYY-MM-DD")
        },
        {
            id: 3, text: moment().format("MMM 01") + " a " + moment().format("MMM 15"), init: moment().format("YYYY-MM-01"), end: moment().format("YYYY-MM-15")
        },
        {
            id: 4, text: moment().format("MMM 16") + " a " + moment().endOf("month").format("MMM DD"), init: moment().format("YYYY-MM-16"), end: moment().endOf("month").format("YYYY-MM-DD")
        },
    ]);

    const { addToast } = useToasts();

    useEffect(() => {
        getWorkerById(id)
    }, [id])

    useEffect(() => {
        if (fetching_delete === 2) {
            // toogleLoader()            
            getWorkerById(id)
        }
    }, [fetching_delete])

    useEffect(() => {
        if (fetching_timer === 2) {
            setHours(data_worker.hours.toFixed(2));
        }
    }, [fetching_timer])

    useEffect(() => {
        getParameterBySegment("novelties")
    }, [])
    useEffect(() => {
        if (fetching_by_segment === 2) {
            if (segment === 'novelties') {
                setListParameter(data_segment)
                getParameterBySegment("country")
            }

            if (segment === 'country') {
                setListCountry(data_segment)
                getParameterBySegment("position_worker")
            }

            if (segment === 'position_worker') {
                setListPosition(data_segment)
                getParameterBySegment("worker_area")
            }
            if (segment === 'worker_area') {
                setListArea(data_segment)
                getParameterBySegment("eps")
            }

            if (segment === 'eps') {
                setListEps(data_segment)
                getParameterBySegment("arl")
            }
            if (segment === 'arl') {
                setListArl(data_segment)
                getParameterBySegment("type_payment")
            }



            if (segment === 'type_payment') {
                setListTypePayment(data_segment)
            }

        }
    }, [fetching_by_segment])

    useEffect(() => {
        if (fetching_create_payroll === 2) {
            history.push("/nomina");
        }
    }, [fetching_create_payroll])

    useEffect(() => {
        if (fetching_create === 2) {
            setModalParam({
                ...modalParam,
                ["open"]: false
            })

            getParameterBySegment("novelties")
        }
    }, [fetching_create])


    useEffect(() => {
        if (fetching_list_config === 2) {
            let _pension = data_config.filter(r => r.parameter_id === 235);
            let _health = data_config.filter(r => r.parameter_id === 234);
            let _transport = data_config.filter(r => r.id === 3 && r.status === true);
            setTranport(0)

            _health = _health.length > 0 ? (parseFloat(_health[0].value) / 100) : 0
            _pension = _pension.length > 0 ? (parseFloat(_pension[0].value) / 100) : 0

            // let health_total = _health.length > 0 ? (parseFloat(_health[0].value) / 100) * form.salary : 0
            // let pension_total = _pension.length > 0 ? parseFloat(_pension[0].value) / 100 * form.salary : 0

            setForm({
                ...form,
                "health": 0,
                "pension": 0,
                "health_value": _health,
                "pension_value": _pension,
            })
        }
    }, [fetching_list_config])

    useEffect(() => {
        if (fetching_by_id === 2) {
            onGetConfiguration()
            let { salary, bonification, novelties, deductions, other_deductions, payroll, other_payment } = selected
            setWorker(selected)

            setListAditional(other_payment)

            setListOther(other_deductions)
            let _payment = selected.payments.map(row => {
                deductions.map(r => {
                    if (r.deduction_id === row.id) {
                        row.checked = true;
                    }
                })
                return row;
            })

            let _novelties = novelties.map(r => {
                let { novelty_worker } = r


                if (novelty_worker === null) {
                    r.checked = false
                } else {
                    r.checked = true
                }
                return r;
            })


            setListNovelty(_novelties)
            setListPayments(_payment)


            let _salary_day = salary / 30;

            setSalaryDay(_salary_day)
            let _salary = _salary_day * form.worked_days

            let _bonification_day = bonification / 30
            setBonificationDay(_bonification_day)

            let extra_hours = 0;

            if (payroll !== null) {
                extra_hours = payroll.extra_hours;
            }

            setForm({
                ...form,
                "salary": _salary,
                "total": (((salary / 30) * diff) + transport).toFixed(2),
                "pension": 0,
                "health": 0,
                "extra_hours": extra_hours,
                "transport": 0
            })
        }
    }, [fetching_by_id])

    const onRemoveItem = (item) => {
        let _list = listNovelty.filter(r => r._id !== item._id)
        setListNovelty(_list)
    }

    const onRemoveDeduction = (item) => {
        let _list = listOther.filter(r => r._id !== item._id)
        setListOther(_list)
    }

    const onRemoveAdditional = (item) => {
        let _list = listAditional.filter(r => r._id !== item._id)
        setListAditional(_list)
    }

    const onRemoveDeductionServer = (item) => {
        if (window.confirm('¿Realmente deseas eliminar este Registro?')) {
            toogleLoader()
            deletePayrollOtherDeduction(item.id)
        }

    }

    const onRemoveAdditionaServer = (item) => {
        if (window.confirm('¿Realmente deseas eliminar este Registro?')) {
            toogleLoader()
            deletePayrollOtherAdditional(item.id)
        }
    }

    const printOther = (row, key) => {

        return <tr key={key}>
            <td>{row.detail}</td>
            <td align='right'>${formatNumber(row.amount)}</td>
            <td>
                {row.status_id === 1 && <button className='btn btn-danger btn-sm' onClick={() => onRemoveDeductionServer(row)}>X</button>}
                {row._id !== undefined && <button className='btn btn-danger btn-sm' onClick={() => onRemoveDeduction(row)}>X</button>}
            </td>
        </tr>
    }

    const printAdditional = (row, key) => {

        return <tr key={key}>
            <td>{row.detail}</td>
            <td>${formatNumber(row.amount)}</td>
            <td>
                {row.status_id === 1 && <button className='btn btn-danger btn-sm' onClick={() => onRemoveAdditionaServer(row)}>X</button>}
                {row._id !== undefined && <button className='btn btn-danger btn-sm' onClick={() => onRemoveAdditional(row)}>X</button>}
            </td>
        </tr>
    }

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const updateInputDate = (e, label) => {
        let day = 0

        if (label === 'date_init') {
            day = (moment.duration(moment(form.date_end).diff(e.target.value)).asDays()) + 1;
        } else {
            day = ((moment.duration(moment(form.date_init).diff(e.target.value)).asDays()) * -1) + 1;
        }

        setForm({
            ...form,
            ["worked_days"]: day,
            [label]: e.target.value
        })

        day = day > 15 ? 15 : day

        setDiff(day)
    }

    const calculateDays = (e) => {


        let day = (moment.duration(moment(e.target.value).diff(form.date_init)).asDays()) + 1;

        // setForm({
        //     ...form,
        //     ["worked_days"]: day,
        // })
    }

    const updateInputDay = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        setDiff(e.target.value)
    }


    const onChangePayment = (item) => {
        let _pay = Object.assign([], listPayments);
        setListPayments(_pay)
    }

    const onChangeNovelty = (item) => {
        let _pay = Object.assign([], listNovelty);
        setListNovelty(_pay)
    }

    const onOpenParameter = (title, dataType) => {

        setModalParam({
            ...modalParam,
            "open": true,
            "title": "Agregar otro Concepto",
            dataType
        })

    }

    const onCreateParameter = (form) => {

        form.segment = 'novelties'
        form.priority = 1;
        setLastParam(form)
        createParameter(form)
    }

    const onSubmitModal = (form) => {

        let _form = form
        _form._id = uuid();

        setListNovelty([
            ...listNovelty,
            form
        ])

        setModal(false)
    }

    const onSubmitDeduction = (form) => {
        let _form = form
        _form._id = uuid()
        _form.worker_id = id;

        setListOther([
            ...listOther,
            _form
        ])

        setModalDeduction(false)
    }

    const onSubmitPayment = (form) => {
        let _form = form
        _form._id = uuid()
        _form.worker_id = id;

        setListAditional([
            ...listAditional,
            _form
        ])

        setModalPayment(false)
    }

    const onSavePayroll = () => {
        let _form = form

        _form.worker_id = id;
        _form.deductions = listPayments.filter(row => row.checked === true)
        _form.other_deductions = listOther
        _form.novelties = listNovelty.filter(row => row.checked === true)
        _form.additionals = listAditional
        // toogleLoader()

        if (showDate.show === false) {
            if (form.alter_date_id !== 0) {
                let row = listDates.filter(r => parseInt(r.id) === parseInt(form.alter_date_id))[0]
                _form.date_init = row.init
                _form.date_end = row.end
            }
        }

        _form.showDate = showDate

        createPayroll(_form)
    }

    const onSelectDate = (e) => {
        setShowDate({
            ...showDate,
            show: e.target.checked,
            title: "Por Quincena"
        })
    }

    const onChangeQuincena = (e) => {
        setForm({ ...form, ["alter_date_id"]: e.target.value })
        let _date = listDates.filter(r => parseInt(r.id) === parseInt(e.target.value))[0]
        getTimerWorker(id, _date)
    }

    const onShowLegalPayment = (e) => {
        let value = parseInt(e.target.value)
        let _form = {
            ...form,
            legal_id: value
        }


        if (value === 1) {
            _form.health = form.health_value * form.salary;
            _form.pension = form.pension_value * form.salary;
        } else if (value === 2) {
            _form.health = form.health_value * form.salary * 2;
            _form.pension = form.pension_value * form.salary * 2;
        } else {
            _form.health = 0;
            _form.pension = 0;
        }
        setForm(_form);
    }

    const onShowModalWorker = () => {
        getParameterBySegment("country")
        setModalWorker(true)
    }

    const onSuccessWorker = (worker_updated) => {
        setWorker(worker_updated)
    }

    let total_advance = listPayments.filter(row => row.checked === true).reduce((a, b) => a = a + parseFloat(b.amount_real), 0)
    let total_other = listOther.reduce((a, b) => a = a + parseFloat(b.amount), 0);
    let total_aditional = listAditional.reduce((a, b) => a = a + parseFloat(b.amount), 0)

    let subtotal_deducido = parseFloat(form.health) + parseFloat(form.pension) + total_advance + total_other
    let subtotal_devengado = parseFloat(salaryDay * diff) + parseFloat(form.transport) + parseFloat(bonificationDay * diff || 0)

    return (
        <TemplateMenu>
            <Container fluid>
                <Row>
                    <Col><h3>Información trabajador</h3></Col>
                </Row>
                <Row className='my-2'>
                    <Col lg={1}>
                        <button className='btna btn-sm btn-secondary' onClick={() => history.goBack()}>Volver</button>
                    </Col>
                    <Col>
                        <button className='btn btn-sm btn-info' onClick={onSavePayroll}>Guardar</button>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center my-2">
                    <Col lg={5} >
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>{worker.name} <MdEdit className='cursor-pointer' onClick={onShowModalWorker} /></Card.Title>
                                        <Row>
                                            <Col><b>Identificación</b></Col>
                                            <Col>{worker.document}</Col>
                                        </Row>
                                        <Row>
                                            <Col><b>Cargo</b></Col>
                                            <Col>{worker.position.description}</Col>
                                        </Row>
                                        <Row>
                                            <Col><b>Sucursal</b></Col>
                                            <Col>{worker.branch_office.title}</Col>
                                        </Row>
                                        <Row>
                                            <Col><b>Salario Base</b></Col>
                                            <Col>${formatNumber(worker.salary || 0)}</Col>
                                        </Row>
                                        <Row>
                                            <Col><b>Bonificacion</b></Col>
                                            <Col>${formatNumber(worker.bonification || 0)}</Col>
                                        </Row>
                                        <Row>
                                            <Col><b>¿Liquidar Salud y Pensión?</b></Col>
                                            <Col className=''>
                                                <select className='form-control' onChange={onShowLegalPayment}>
                                                    <option value={0}>No Liquidar</option>
                                                    <option value={1}>Liquidar Quincenal</option>
                                                    <option value={2}>Liquidar Mensual</option>
                                                </select>
                                                {/* <input className='' type='checkbox' checked={form.legal} /> */}
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row className='my-3'>
                            <Col>
                                <Row>
                                    <Col>Fecha de Liquidación</Col>
                                    <Col>{showDate.title} <input type={"checkbox"} onChange={onSelectDate} checked={showDate.show} /></Col>
                                </Row>

                                {showDate.show === true ? <Row>
                                    <Col>
                                        <input
                                            type={"date"} name="date_init"
                                            className='form-control'
                                            value={form.date_init}
                                            onChange={(e) => updateInputDate(e, 'date_init')} />
                                    </Col>
                                    <Col>
                                        <input
                                            type={"date"}
                                            name="date_end"
                                            className='form-control'
                                            value={form.date_end}
                                            onChange={(e) => updateInputDate(e, 'date_end')}
                                            onBlur={calculateDays}
                                        />
                                    </Col>
                                </Row> : <Row>
                                    <Col>
                                        <div className='form-group'>
                                            <label>Quincena</label>
                                            <select className='form-control' name="alter_date_id" onChange={onChangeQuincena}>
                                                <option value="0">Seleccione</option>
                                                {listDates.map(r => <option key={r.id} value={r.id}>{r.text}</option>)}
                                            </select>
                                            {errors.alter_date_id !== undefined ?? (<label className="error-label">{errors.alter_date_id}</label>)}

                                        </div>
                                    </Col>
                                </Row>}

                                <Row>
                                    <Col>
                                        <div className='form-group'>
                                            <label>Días Trabajados</label>
                                            <input className='form-control' name='worked_days' placeholder='Días trabajados' value={form.worked_days} onChange={updateInputDay} />
                                            {errors.worked_days !== undefined ?? (<label className="error-label">{errors.worked_days}</label>)}

                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='form-group'>
                                            <label>Horas Extras</label>
                                            <input className='form-control' name='extra_hours' value={form.extra_hours} placeholder='Horas Extras'
                                                onChange={updateInput}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card>
                                            <Card.Body className='px-2 py-2'>
                                                <Row>
                                                    <Col lg={5}>Horas Registradas</Col>
                                                    <Col className='text-right pr-4'>{hours}</Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <table className='table table-bordered table-sm'>
                                    <tbody>
                                        <tr>
                                            <td>Concepto</td>
                                            <td>Devengado</td>
                                            <td>Deducido</td>
                                        </tr>

                                        <tr>
                                            <td>Salario Base</td>
                                            <td align="right">${formatNumber((salaryDay * diff).toFixed(2))}</td>
                                            <td></td>
                                        </tr>
                                        {transport.length > 0 ?? <tr>
                                            <td>Subsidio de Transporte</td>
                                            <td align="right">
                                                ${formatNumber(form.transport)}
                                            </td>
                                            <td></td>
                                        </tr>}
                                        {total_aditional > 0 && <tr>
                                            <td>Pagos Adicionales</td>
                                            <td align="right">${formatNumber(total_aditional)}</td>
                                        </tr>}

                                        <tr>
                                            <td>Bonificaciones</td>
                                            <td align="right">${formatNumber(bonificationDay * diff)}</td>
                                            <td></td>
                                        </tr>

                                        {form.legal_id !== 0 && form.health !== '' && <tr>
                                            <td>Aportes a Salud</td>
                                            <td></td>
                                            <td align="right">
                                                ${formatNumber(form.health)}
                                            </td>
                                        </tr>}

                                        {form.legal_id !== 0 && form.pension !== '' && <tr>
                                            <td>Aportes a Pensión</td>
                                            <td></td>
                                            <td align="right">
                                                ${formatNumber(form.pension)}
                                            </td>
                                        </tr>}


                                        <tr>
                                            <td>Anticipos</td>
                                            <td></td>
                                            <td align="right">${formatNumber(total_advance)}</td>
                                        </tr>
                                        <tr>
                                            <td>Deducciones</td>
                                            <td></td>
                                            <td align="right">${formatNumber(total_other)}</td>
                                        </tr>
                                        <tr className='table-secondary'>
                                            <td>SubTotal</td>
                                            <td align="right">
                                                ${formatNumber(subtotal_devengado + total_aditional)}
                                            </td>
                                            <td align="right">
                                                ${formatNumber(subtotal_deducido)}
                                            </td>
                                        </tr>
                                        <tr className='table-success'>
                                            <td colSpan={2}><b>Total a Pagar</b></td>
                                            <td align="right">
                                                <b>${formatNumber(subtotal_devengado - subtotal_deducido + total_aditional)}</b>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </Col>
                        </Row>


                    </Col>
                    <Col>
                        <Row>
                            <Col className='text-center'>
                                <h4>Novedades<button className='btn btn-sm btn-info ml-2' onClick={() => setModal(true)}>+</button></h4>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <table className="table table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th>Fecha</th>
                                            <th>Descripción</th>
                                            <th>Razón</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(listNovelty.length > 0) ? listNovelty.map(row => <ItemNovelty row={row} key={row.id} onChange={onChangeNovelty} />) : <tr><td colSpan={3}>No tiene Novedades</td></tr>}
                                    </tbody>
                                </table>

                            </Col>
                        </Row>

                        <Row>
                            <Col className='text-center'>
                                <h4>Anticipos</h4>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <table className="table table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th>Fecha</th>
                                            <th>Descripción</th>
                                            <th>Valor</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(listPayments.length > 0) ? listPayments.map(row => <ItemPayment
                                            row={row} key={row.id}
                                            onChange={onChangePayment} />) : <tr><td colSpan={3}>No tiene Novedades</td></tr>}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan={2}><b>TOTAL</b></td>
                                            <td align='right'><b>${formatNumber(total_advance)}</b></td>
                                        </tr>
                                    </tfoot>
                                </table>

                            </Col>
                        </Row>

                        <Row>
                            <Col className='text-center'>
                                <h4>Deducciones<button className='btn btn-sm btn-info ml-2' onClick={() => setModalDeduction(true)}>+</button></h4>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <table className="table table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th>Descripción</th>
                                            <th>Valor</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(listOther.length > 0) ? listOther.map(printOther) : <tr><td colSpan={3}>No tiene otras Deducciones</td></tr>}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td><b>TOTAL</b></td>
                                            <td align='right'><b>${formatNumber(total_other)}</b></td>
                                        </tr>
                                    </tfoot>
                                </table>

                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-center'>
                                <h4>Pagos Adicionales<button className='btn btn-sm btn-info ml-2' onClick={() => setModalPayment(true)}>+</button></h4>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <table className="table table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th>Descripción</th>
                                            <th>Valor</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(listAditional.length > 0) ? listAditional.map(printAdditional) : <tr><td colSpan={3}>No tiene otros Pagos</td></tr>}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td><b>TOTAL</b></td>
                                            <td><b>${formatNumber(total_aditional)}</b></td>
                                        </tr>
                                    </tfoot>
                                </table>

                            </Col>
                        </Row>


                    </Col>
                </Row>

                <ModalAddNovelty
                    title={"Agregar Novedad"}
                    onHide={() => setModal(false)}
                    showModal={modal}
                    parameters={listParameter}
                    onOpenParameter={onOpenParameter}
                    onSubmit={onSubmitModal}
                />

                <ModalAddParameter
                    show={modalParam.open}
                    title={modalParam.title}
                    dataType={modalParam.dataType}
                    onHide={() => setModalParam({ ...modalParam, "open": false })}
                    onSuccess={onCreateParameter} />

                <ModalDeduction
                    show={modalDeduction}
                    onHide={() => setModalDeduction(false)}
                    onSubmit={onSubmitDeduction}
                />

                <ModalAdditionalPayment
                    show={modalPayment}
                    onHide={() => setModalPayment(false)}
                    onSubmit={onSubmitPayment}
                />

                <ModalWorker
                    show={modalWorker}
                    user={user}
                    showModal={modalWorker}
                    formEdited={worker}
                    onHide={() => setModalWorker(false)}
                    onSuccess={onSuccessWorker}
                    listCountry={listCountry}
                    listPosition={listPosition}
                    listArea={listArea}
                    listEps={listEps}
                    listArl={listArl}
                    listTypePayment={listTypePayment} />
            </Container>
        </TemplateMenu>
    )
}

const mapsState = (props) => {

    let { payroll, worker, parameter, user } = props

    return {
        selected: worker.selected,
        fetching_by_id: worker.fetching_by_id,
        fetching_list_config: parameter.fetching_list_config,
        data_config: parameter.data_config,
        fetching_by_segment: parameter.fetching_by_segment,
        data_segment: parameter.data_segment,
        segment: parameter.segment,
        fetching_create: parameter.fetching_create,
        fetching_create_payroll: payroll.fetching_create,
        fetching_delete: payroll.fetching_delete,
        errors: payroll.errors,
        fetching_timer: payroll.fetching_timer,
        data_worker: payroll.data_worker,
        user: user.user
    }
}

export default connect(mapsState, {
    getWorkerById, onGetConfiguration, createParameter, getParameterBySegment, createPayroll, toogleLoader,
    deletePayrollOtherDeduction, getTimerWorker, deletePayrollOtherAdditional
})(EditNominaPage);