import React from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FiAlertTriangle } from "react-icons/fi";


const ListWorker = ({ listWorker }) => {

    const printRow = (row, key) => {
        let { branch_office, payroll } = row

        return <tr key={key}>
            <td>{payroll === null ? null : <FiAlertTriangle />}</td>
            <td>{row.name}</td>
            <td>{row.document}</td>
            <td>{branch_office.title}</td>
            <td>
                <Link className='btn btn-info btn-sm' to={`nomina/${row.id}`}>Liquidar</Link>
            </td>
        </tr>
    }

    return (
        <Row>
            <Col>

                <Table className='table table-bordered table-sm table-hover' responsive>
                    <thead className='thead-dark'>
                        <tr>
                            <th></th>
                            <th>Nombre</th>
                            <th>Documento</th>
                            <th>Sucursal</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listWorker.map(printRow)}
                    </tbody>
                </Table>
            </Col>
        </Row>
    )
}

export default ListWorker;