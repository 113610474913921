import React, { useEffect, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import RowCharge from './row.charge';

const FormCharge = ({ onCreate, list, formEdit, onEdit }) => {

    const [form, setForm] = useState({ name: '' })
    const [isEdit, setIsEdit] = useState(false)

    useEffect(() => {
        console.log('formEdit', formEdit);
        if (formEdit !== undefined) {
            if (formEdit.id !== undefined) {
                setForm(formEdit)
                setIsEdit(true)
            }
        }

    }, [formEdit])

    const onUpdateRow = (value, row) => {

        list = list.map(d => {
            d.products.map(pro => {
                if (row.id === pro.id) {
                    pro.value = value;
                }

                return pro;
            })

            return d;
        })
    }

    const onSucess = () => {
        let _list = list.map(d => {
            d.products = d.products.filter(d => parseFloat(d.value) > 0)
            return d
        });

        let _form = form
        _form.list_products = _list;
        if (!isEdit) {
            onCreate(_form);
        } else {
            onEdit(_form)
        }
    }

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    return (
        <>
            <Row>
                <Col lg={5}>
                    <div className='form-group'>
                        <label>Nombre Listado</label>
                        <input className='form-control' name="name" placeholder='Nombre Listado' onChange={updateInput} value={form.name} />
                    </div>
                </Col>
                <Col lg={2} className='d-flex align-items-center'>
                    {!isEdit ? <button className='btn btn-sm btn-success' onClick={onSucess}>Guardar</button> :
                        <button className='btn btn-sm btn-info' onClick={onSucess}>Editar</button>}

                </Col>
            </Row>
            <Row>

                <Col lg={10}>
                    {(list.length > 0) ? list.map((d, k) => {
                        let { products } = d
                        return <Table className='table-sm' key={k}>
                            <thead className='thead-dark'>
                                <tr>
                                    <th>{d.description}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <Table className='table-sm table-bordered table-hover'>
                                            <thead>
                                                <tr className='thead-dark'>
                                                    <th>Producto</th>
                                                    <th>Medida</th>
                                                    <th width="15%">Tipo</th>
                                                    <th width="15%">Valor</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {products.map((row, k) => <RowCharge key={k} item={row} onBlur={onUpdateRow} />)}
                                            </tbody>

                                        </Table>
                                    </td>
                                </tr>
                            </tbody>

                        </Table>
                    }
                    ) : <h4 className='text-center'>No se encontraron Productos</h4>}

                </Col>
            </Row>
        </>
    )
}

export default FormCharge;