import moment from 'moment';
import React from 'react'
import { Link } from 'react-router-dom';
import { formatNumber } from '../../utils';
import {
    AiFillEdit,
    AiFillEye,
    AiFillFilePdf,
    AiOutlineFileDone,
    AiOutlineLock,
} from "react-icons/ai";

const ItemSupplier = ({ row, title, onDelete, type, titleButton, branch, show_delete, onReverse, show_reverse }) => {
    let { user } = row
    let table = 'primary'

    if (row.status_id === 2) {
        table = "warning"
    }
    if (row.status_id === 3) {
        table = "success"
    }

    return (
        <tr >
            <td>{row.id}</td>
            <td>{moment(row.registration_date).format("YYYY-MM-DD")}</td>
            <td>{title}</td>
            <td>{user.name}</td>
            <td className={`table-${table} text-center`}>{row.status_text}</td>
            <td>
                <Link className='btn btn-sm btn-info' to={`/requisiciones/${row.id}`}>{titleButton}</Link>
                {show_delete && <button className='btn btn-danger btn-sm mx-3' onClick={() => onDelete(row)}>Borrar</button>}
                {(row.status_id === 3 && show_reverse) && <AiOutlineLock size={25} onClick={() => onReverse(row)} />}
            </td>
        </tr>
    )
}

export default ItemSupplier;