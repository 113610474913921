import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import TemplateMenu from '../../template/menu.template'
import { onCreateProduction } from '../../Redux/ProductionDuck';
import AsyncSelect from 'react-select/async';
import { formatNumber, getHeaders } from '../../utils';
import ItemProduction from './item.production';
import moment from 'moment';
import FormProduct from '../Products/FormProduct';
import FormProduction from './form.production';
import { toogleLoader } from "../../Redux/alertDuck";

const API_URL = process.env.REACT_APP_API_URL

const initStateSelected = { measure_unit: { description: '' }, cost: 0 }

const CreateProductionPage = ({ onCreateProduction, errors, fetching_create, toogleLoader }) => {

    const [form, setForm] = useState({ product_id: 0, amount: 0, registration_date: moment().format("YYYY-MM-DD") })
    const [modal, setModal] = useState(false)
    const [listProduction, setListProduction] = useState([])
    const [quantity, setQuantity] = useState(0)
    const [total, setTotal] = useState(0)
    const [product, setProduct] = useState('')
    const [listSearch, setListSearch] = useState([])
    const [selected, setSelected] = useState(initStateSelected)

    const history = useHistory();

    useEffect(() => {
        if (fetching_create === 2) {
            history.goBack();
        }
    }, [fetching_create])

    const onSavePrescription = (data) => {
        toogleLoader(true)
        onCreateProduction(data)
    }



    return (
        <TemplateMenu>
            <Row className='d-flex justify-content-center'>
                <Col lg={8}>
                    <Row className='my-2'>
                        <Col>
                            <button className='btn btn-sm btn-secondary' onClick={() => history.goBack()}>Volver</button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormProduction title={"Crear Producción"} onSubmit={onSavePrescription} formEdit={{}} />
                        </Col>
                    </Row>
                </Col>
            </Row >
        </TemplateMenu >
    )
}

const mapsState = (props) => {
    let { production } = props
    return {
        errors: {},
        fetching_create: production.fetching_create,
    }
}

export default connect(mapsState, { onCreateProduction, toogleLoader })(CreateProductionPage);