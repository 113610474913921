import moment from 'moment';
import React from 'react'
import { Link } from 'react-router-dom';
import { formatNumber } from '../../utils';

const ItemSupplier = ({ title, row, onDelete, type }) => {
    let { user } = row

    return (
        <tr >
            <td>{row.id}</td>
            <td>{moment(row.registration_date).format("YYYY-MM-DD")}</td>
            <td>{title}</td>
            <td>{user.name}</td>
            <td>0</td>
            <td>
                <Link className='btn btn-sm btn-info' to={`/transferencias/${row.id}`}>Ver</Link>
                {(type === 1 && row.status_id === 1) &&
                    <button className='btn btn-danger btn-sm mx-3' onClick={() => onDelete(row)}>Borrar</button>}

            </td>
        </tr>
    )
}

export default ItemSupplier;