import axios from "axios";
import { getHeaders, validateInput, validateParameter } from "../utils";
const API_URL = process.env.REACT_APP_API_URL;

let initialState = {
  fetching: 0,
  fetching_by_segment: 0,
  fetching_create: 0,
  fetching_config: 0,
  fetching_list_config: 0,
  fetching_franchise: 0,
  fetching_franchise_list: 0,
  fetching_group: 0,
  data: [],
  data_segment: [],
  data_franchise: [],
  data_config: [],
  data_group: [],
  segment: "",
  selected: {},
  errors: {},
  modal: false,
};

let PARAMETER = "PARAMETER";
let PARAMETER_SUCCESS = "PARAMETER_SUCCESS";
let PARAMETER_ERROR = "PARAMETER_ERROR";
let SET_PARAMETER = "SET_PARAMETER";
let SET_PARAMETER_SUCCESS = "SET_PARAMETER_SUCCESS";
let PARAMETER_NEW = "PARAMETER_NEW";
let PARAMETER_NEW_SUCCESS = "PARAMETER_NEW_SUCCESS";
let PARAMETER_UPDATE = "PARAMETER_UPDATE";
let PARAMETER_UPDATE_SUCCESS = "PARAMETER_UPDATE_SUCCESS";
let CLOSE_MODAL = "CLOSE_MODAL";
let OPEN_MODAL = "OPEN_MODAL";
let PARAMETER_BY_SEGMENT = "PARAMETER_BY_SEGMENT";
let PARAMETER_BY_SEGMENT_SUCCESS = "PARAMETER_BY_SEGMENT_SUCCESS";
let SET_CONFIG = "SET_CONFIG";
let SET_CONFIG_SUCCESS = "SET_CONFIG_SUCCESS";
let GET_CONFIG = "GET_CONFIG";
let GET_CONFIG_SUCCESS = "GET_CONFIG_SUCCESS";

let SAVE_FRANCHISE = "SAVE_FRANCHISE";
let SAVE_FRANCHISE_SUCCESS = "SAVE_FRANCHISE_SUCCESS";
let LIST_FRANCHISE = "LIST_FRANCHISE";
let LIST_FRANCHISE_SUCCESS = "LIST_FRANCHISE_SUCCESS";
let PARAMETER_GROUP = "PARAMETER_GROUP";
let PARAMETER_GROUP_SUCCESS = "PARAMETER_GROUP_SUCCESS";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case PARAMETER:
      return { ...state, fetching: 1 };
    case PARAMETER_SUCCESS:
      return { ...state, fetching: 2, data: action.payload };
    case PARAMETER_ERROR:
      return {
        ...state,
        errors: action.payload,
        fetching: 3,
        selected: action.form,
      };
    case SET_PARAMETER:
      return { ...state, fetching: 1 };
    case SET_PARAMETER_SUCCESS:
      return { ...state, fetching: 2, selected: action.payload, modal: true };
    case PARAMETER_NEW:
      return { ...state, fetching_create: 1 };
    case PARAMETER_NEW_SUCCESS:
      return { ...state, fetching_create: 2, data: action.payload };
    case CLOSE_MODAL:
      return { ...state, fetching: 1, selected: {}, modal: false };
    case OPEN_MODAL:
      return { ...state, fetching: 1, selected: {}, modal: true };
    case PARAMETER_BY_SEGMENT:
      return { ...state, fetching_by_segment: 1 };
    case PARAMETER_BY_SEGMENT_SUCCESS:
      return {
        ...state,
        fetching_by_segment: 2,
        data_segment: action.payload,
        segment: action.segment,
      };
    case SET_CONFIG:
      return { ...state, fetching_config: 1 };
    case SET_CONFIG_SUCCESS:
      return { ...state, fetching_config: 2 };
    case GET_CONFIG:
      return { ...state, fetching_list_config: 1 };
    case GET_CONFIG_SUCCESS:
      return { ...state, fetching_list_config: 2, data_config: action.payload };
    case SAVE_FRANCHISE:
      return { ...state, fetching_franchise: 1 };
    case SAVE_FRANCHISE_SUCCESS:
      return { ...state, fetching_franchise: 2 };
    case LIST_FRANCHISE:
      return { ...state, fetching_franchise_list: 1 };
    case LIST_FRANCHISE_SUCCESS:
      return {
        ...state,
        fetching_franchise_list: 2,
        data_franchise: action.payload,
      };
    case PARAMETER_GROUP:
      return { ...state, fetching_group: 1 };
    case PARAMETER_GROUP_SUCCESS:
      return { ...state, fetching_group: 2, data_group: action.payload };
    default:
      return state;
  }
}

export let setParameter = (item) => (dispatch, getState) => {
  dispatch({
    type: SET_PARAMETER,
  });
  dispatch({
    type: SET_PARAMETER_SUCCESS,
    payload: item,
  });
};

export let closeModal = (item) => (dispatch, getState) => {
  dispatch({
    type: CLOSE_MODAL,
  });
};
export let openModal = (item) => (dispatch, getState) => {
  dispatch({
    type: OPEN_MODAL,
  });
};

export let getParameter = (filter) => (dispatch, getState) => {
  dispatch({
    type: PARAMETER,
  });

  let url = `${API_URL}/api/parameters`;

  if (filter !== undefined) {
    url = `${API_URL}/api/parameters${filter}`;
  }

  if (getState().user.loggedIn) {
    axios
      .get(url, {
        headers: getHeaders(),
      })
      .then((resp) => {
        dispatch({
          type: PARAMETER_SUCCESS,
          payload: resp.data.results,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    console.log("no Ingreso");
  }
};
export let getParameterAdmin = (filter) => (dispatch, getState) => {
  dispatch({
    type: PARAMETER,
  });

  let url = `${API_URL}/api/parameters-admin`;

  if (filter !== undefined) {
    url = `${API_URL}/api/parameters${filter}`;
  }

  if (getState().user.loggedIn) {
    axios
      .get(url, {
        headers: getHeaders(),
      })
      .then((resp) => {
        dispatch({
          type: PARAMETER_SUCCESS,
          payload: resp.data.groups,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    console.log("no Ingreso");
  }
};

export let onUpdateParameter = (form) => (dispatch, getState) => {
  dispatch({
    type: PARAMETER_UPDATE,
  });

  let url = `${API_URL}/api/parameters/${form.id}`;

  axios
    .put(url, form, {
      headers: getHeaders(),
    })
    .then((resp) => {
      dispatch({
        type: PARAMETER_UPDATE_SUCCESS,
      });
    })
    .catch(function (error) {
      console.log(error);
    });
};

export let getFranchiseByMethod = (form) => (dispatch, getState) => {
  dispatch({
    type: LIST_FRANCHISE,
  });

  let url = `${API_URL}/api/parameters-franchise/${form.id}`;

  if (getState().user.loggedIn) {
    axios
      .get(url, {
        headers: getHeaders(),
      })
      .then((resp) => {
        dispatch({
          type: LIST_FRANCHISE_SUCCESS,
          payload: resp.data,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    console.log("no Ingreso");
  }
};

export let getFranchiseByMethodTotal = (form) => (dispatch, getState) => {
  dispatch({
    type: LIST_FRANCHISE,
  });

  let url = `${API_URL}/api/parameters-franchise/${form.id}/${form.total_id}`;

  if (getState().user.loggedIn) {
    axios
      .get(url, {
        headers: getHeaders(),
      })
      .then((resp) => {
        dispatch({
          type: LIST_FRANCHISE_SUCCESS,
          payload: resp.data,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    console.log("no Ingreso");
  }
};

export let getParameterBySegment =
  (segment, filter) => (dispatch, getState) => {
    dispatch({
      type: PARAMETER_BY_SEGMENT,
    });

    let _filter = filter === true ? "f=true&" : "";

    let branch = JSON.parse(localStorage.getItem("branch"));

    if (getState().user.loggedIn) {
      let url = `${API_URL}/api/parameters/${segment}/segment?${_filter}&branch=${branch.id}`;

      axios
        .get(url, {
          headers: getHeaders(),
        })
        .then((resp) => {
          dispatch({
            type: PARAMETER_BY_SEGMENT_SUCCESS,
            payload: resp.data.results,
            segment,
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      console.log("no Ingreso");
    }
  };

export let getParameterByGroup = (group) => (dispatch, getState) => {
  dispatch({
    type: PARAMETER_GROUP,
  });

  let branch = JSON.parse(localStorage.getItem("branch"));

  if (getState().user.loggedIn) {
    let url = `${API_URL}/api/parameters-group/${group}?branch_id=${branch.id}`;

    axios
      .get(url, {
        headers: getHeaders(),
      })
      .then((resp) => {
        dispatch({
          type: PARAMETER_GROUP_SUCCESS,
          payload: resp.data.results,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    console.log("no Ingreso");
  }
};
export let onSaveConfiguration = (list) => (dispatch, getState) => {
  dispatch({
    type: SET_CONFIG,
  });

  let url = `${API_URL}/api/parameters-config`;
  let branch = JSON.parse(localStorage.getItem("branch"));

  fetch(url, {
    method: "POST",
    body: JSON.stringify({ list, branch: branch.id }),
    headers: getHeaders(),
  }).then((resp) => { });

  dispatch({
    type: SET_CONFIG_SUCCESS,
  });
};

export let onSaveFranchise = (form) => (dispatch, getState) => {
  dispatch({
    type: SAVE_FRANCHISE,
  });

  let url = `${API_URL}/api/parameters-franchise`;

  fetch(url, {
    method: "POST",
    body: JSON.stringify(form),
    headers: getHeaders(),
  }).then((resp) => { });

  dispatch({
    type: SAVE_FRANCHISE_SUCCESS,
  });
};

export let onGetConfiguration = (list) => (dispatch, getState) => {
  dispatch({
    type: GET_CONFIG,
  });

  let branch = JSON.parse(localStorage.getItem("branch"));

  let url = `${API_URL}/api/parameters-config?branch=${branch.id}`;

  fetch(url, {
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: GET_CONFIG_SUCCESS,
        payload: resp.results,
      });
    });
};

export let createParameter = (form) => (dispatch, getState) => {
  dispatch({
    type: PARAMETER_NEW,
  });

  let rules = [{ field: "description", msg: "El Concepto es Requerido" }];

  let validate = validateInput(form, rules);

  if (validate !== null) {
    dispatch({
      type: PARAMETER_ERROR,
      payload: validate,
    });
    return;
  }

  let branch = JSON.parse(localStorage.getItem("branch"));


  form.branch = branch;

  if (form.id !== undefined) {
    if (getState().user.loggedIn) {
      axios
        .put(`${API_URL}/api/parameters/${form.id}`, form, {
          headers: getHeaders(),
        })
        .then((resp) => {
          dispatch({
            type: PARAMETER_NEW_SUCCESS,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("error", error);

          let err = {};

          for (const [key, value] of Object.entries(
            error.response.data.errors
          )) {
            if ("title" === key) {
              err.title = value[0];
            }
            if ("description" === key) {
              err.description = value[0];
            }
          }

          dispatch({
            type: PARAMETER_ERROR,
            payload: err,
            form: form,
          });
        });
    } else {
      console.log("no Ingreso");
    }
  } else {
    if (getState().user.loggedIn) {
      axios
        .post(`${API_URL}/api/parameters`, form, {
          headers: getHeaders(),
        })
        .then((resp) => {
          dispatch({
            type: PARAMETER_NEW_SUCCESS,
            payload: resp.data,
          });
        })
        .catch((error) => {
          console.log("error", error);

          let err = {};

          for (const [key, value] of Object.entries(
            error.response.data.errors
          )) {
            if ("title" === key) {
              err.title = value;
            }
            if ("description" === key) {
              err.description = value;
            }
          }

          dispatch({
            type: PARAMETER_ERROR,
            payload: err,
            form: form,
          });
        });
    } else {
      console.log("no Ingreso");
    }
  }
};
