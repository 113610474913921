import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Card, Container } from 'react-bootstrap'
import { connect } from 'react-redux'
import './index.css'
import { getReservation, joinReservation, cancelReservation, getReservationDetail } from '../../Redux/reservationDuck'
import { getOrdersDiningTable } from '../../Redux/orderDuck'
import { getDiningTablesReservation, getDetailDining } from '../../Redux/diningDuck'
import { toogleAlert } from '../../Redux/alertDuck'
import logo_patos from 'Images/logo_madera.png'
import pedido_alert from './../../Images/logo_madera.png'

import DetailModal from './detail.modal'
import audio2 from '../../Audio/pato.mpeg'

import { Howl } from 'howler';

import socketIOClient from "socket.io-client";
import BlueTitleBack from '../Components/Widgets/BlueTitleBack'
import FreeTableModal from './freeTable.modal'
import FormReservationModal from './formreservation.modal'
import { useHistory } from 'react-router-dom'


const ENDPOINT = process.env.REACT_APP_API;

const Reservation = ({ getReservation, data, fetching, dining, getDiningTablesReservation, fetching_activate,
    cancelReservation, fetching_cancel, getOrdersDiningTable, fetching_detail, fetching_reservation, orders, getReservationDetail,
    fetching_id, detail_reservation, toogleAlert, fetching_list }) => {

    const history = useHistory()

    const [showDetail, setShowDetail] = useState(false)
    const [reserve, setReserve] = useState([])
    const [current, setCurrent] = useState({})
    const [modalForm, setModalForm] = useState(false)

    const [player, setPlayer] = useState(new Howl({
        src: [audio2],
        loop: true
    }))

    const [diningTable, setDiningTable] = useState({})
    const [listDining, setListDining] = useState([])
    const [client, setClient] = useState({})

    useEffect(() => {
        toogleAlert(true)
        getDiningTablesReservation()
    }, [])

    useEffect(() => {
        if (dining.length > 0) {
            setListDining(dining)
        }
    }, [dining])

    useEffect(() => {

        if (fetching_list === 2) {
            toogleAlert(false)
        }
    }, [fetching_list])



    useEffect(() => {

        if (fetching_activate === 2) {
            getDiningTablesReservation()
            player.stop()
        }

    }, [fetching_activate])


    useEffect(() => {

        if (fetching_id === 2) {
            setClient(detail_reservation)
        }

    }, [fetching_id])

    useEffect(() => {

        if (fetching === 2) {
            setReserve(data)
        }

    }, [fetching])

    useEffect(() => {

        if (fetching_cancel === 2) {
            getDiningTablesReservation()
        }

    }, [fetching_cancel])

    useEffect(() => {

        if (fetching_detail === 2) {
            setShowDetail(true)
        }

    }, [fetching_detail])


    useEffect(() => {

        if (fetching_reservation === 2) {
            setCurrent(orders)
            toogleAlert(false)
        }

    }, [fetching_reservation])

    useEffect(() => {

        const socket = socketIOClient(ENDPOINT);
        socket.on("connect", function () {
            console.log("connected socket")
        })

        socket.on("message", data => {
            let { type, row } = data;

            console.log("llego dato nuevo", data);

            if (type === 'partial') {
                updateDining(row)
                notify(row)
            }
            if (type === 'finish-reservation') {
                getDiningTablesReservation()
            }
        });

        return () => socket.disconnect();
    }, [listDining,fetching_list])

    const updateDining = (row) => {

        console.log('row-', row)
        let _listDining = [...listDining]

        _listDining.map((item, i) => {
            if (item.id === row.id) {
                _listDining[i].notify = row.notify
            }
        })

        setListDining(_listDining)
        player.play()
    }

    const notify = (row) => {

        const options = {
            body: `Nuevo Pedido Mesa #${row.position}`,
            icon: logo_patos,
            badge: logo_patos,
        }
        const notification = new Notification("Mana express Reservas", options)

        notification.addEventListener('close', () => console.log('close'))
        //console.log(notification)
    }


    const getOrderDiningTable = (item) => {
        let { reservation } = item
        if (reservation !== null) {
            toogleAlert(true);
            getOrdersDiningTable(item.reservation_id)
            setShowDetail(true)
        }
    }

    const onClose = () => {
        setCurrent({})
        setShowDetail(false)
    }

    const onSuccessFull = () => {

        setCurrent({})
        setShowDetail(false)
        getReservation()
        getDiningTablesReservation()
    }

    const silenceDuck = () => {
        player.stop()
    }


    const onHideForm = () => {
        setModalForm(false)
    }


    const cancelReservationForm = (id) => {
        if (window.confirm("¿Deseas cancelar esta Reserva?")) {
            cancelReservation(id)
        }
    }


    const printDining = (item, i) => {

        let { branch_office, reservation } = item || null

        return (

            <Col key={i} lg={3} md={6} className="d-flex justify-content-center card-table-bg px-1  " onClick={() => getOrderDiningTable(item)}>
                <div className={`px-2 py-3 mx-0 my-1 w-100 rounded-lg bg-white d-flex flex-column card_table 
                ${(item.status_id === 2) ? 'bg-golden' : ''}`}>
                    <BlueTitleBack title={`Mesa ${item.position}`} />
                    <span className="text-center">{branch_office.title}</span>
                    {(reservation !== null && reservation !== undefined) ? (
                        <Fragment>
                            <div className="bg-azul rounded-lg my-3 mx-2">
                                <p className="text-white-50 text-center mb-0 py-1">Estado</p>
                                <span className="font-title  text-white text-center d-block ">ACTIVA</span>
                            </div>
                            <p className="text-gris text-center mb-0 ">Reserva #{reservation.id}, {reservation.people} Personas</p>
                            {item.notify === true && (
                                <div className="alert_product w-100 d-flex justify-content-center align-items-center">
                                    <img src={pedido_alert} className="img-fluid alert_globe mb-5" />
                                    <div className="text-right py-4 pendiente">
                                        <span className=" px-3 py-1 rounded-pill bg-white">Pendientes: {item.pending}</span>
                                    </div>
                                </div>
                            )}
                        </Fragment>
                    ) : (
                            <Fragment><div className="bg-libre rounded-lg my-3 mx-2">
                                <p className=" text-center mb-0 py-1">Estado</p>
                                <span className="font-title text-center d-block ">LIBRE</span>
                            </div>
                                <p className="text-gris text-center mb-0 ">...</p>
                            </Fragment>
                        )}
                </div>
            </Col>
        )
    }

    return (
        <Container fluid className="container_texture">
            <Row className="my-3">
                {/* <Col lg={4} md={6} sm={12} className="">
                    <Card className="rounded-lg pt-0 px-2 pb-3">
                        <Card.Body className="pt-0 px-0 mb-5">
                            <Row className="d-flex justify-content-center">
                                <img src={pato_reserva} className="px-3 mx-0 w-100" />
                            </Row>
                            {(reserve.length > 0) ? reserve.map(printReservation) : (<Row className="mt-4"><Col className="text-center">No hay reservas activas</Col></Row>)}
                        </Card.Body>
                    </Card>
                </Col> */}

                <Col lg={12} md={6} className="mx-0 px-0 ">
                    <Row className="p-2 mx-0  mt-3 ">
                        {listDining.map(printDining)}
                    </Row>

                </Col>
            </Row>

            <DetailModal show={showDetail} current={current} onClose={onClose} onSuccessFull={onSuccessFull} silenceDuck={silenceDuck}
            />

            <FreeTableModal show={false} />

            <FormReservationModal show={modalForm} onHide={onHideForm} onSuccess={() => console.log('test')} />


        </Container>
    )
}

const mapsState = (props) => {
    return {
        detail_reservation: props.reservation.detail,
        fetching_id: props.reservation.fetching_id,
        data: props.reservation.data,
        orders: props.order.data,
        fetching_reservation: props.order.fetching_all,
        dining: props.dining.data,
        fetching_detail: props.dining.fetching_detail,
        fetching_list: props.dining.fetching_list,
        fetching: props.reservation.fetching,
        fetching_activate: props.reservation.fetching_activate,
        fetching_cancel: props.reservation.fetching_cancel,
    }
}

export default connect(mapsState, {
    getReservation, getDiningTablesReservation, cancelReservation,
    getDetailDining, getOrdersDiningTable, getReservationDetail, toogleAlert
})(Reservation);
