import React, { useState, useEffect } from 'react';
import { Modal, Container, Col, Row } from 'react-bootstrap';

import {  getDateReserv, createReservInstant } from '../../Redux/reservationDuck'
import { getUserPhone } from '../../Redux/userDuck'
import { toogleAlert } from '../../Redux/alertDuck'
import { connect } from 'react-redux';
//components
import BtnOrangeSm from '../Components/Widgets/BtnOrangeSm'
import BtnBlueModal from '../Components/Widgets/BtnBlueModal'
import InputFieldCheck from '../Components/Widgets/InputFieldCheck'



const FormReservationModal = ({ show, day, getUserPhone, errors, row, onHide, onSuccess, createReservInstant, fetching,dates, selected,fetching_email,
    toogleAlert,fetching_error }) => {

    const initState= { name: '', email: '', phone: '', people: '', pet: false };

    const [form, setForm] = useState(initState)

    let { dining_table } = row || []
    let { branch_office } = dining_table || []

    const [branch, setBranch] = useState({})

    useEffect(()=>{
        setForm(initState)
    },[row])

    useEffect(() => {
        
        if (fetching === 2) {
            toogleAlert(false)
            setForm(initState)
            onHide();
            onSuccess();
        }
    }, [fetching])


    useEffect(()=>{
        if(fetching_error===3){
            toogleAlert(false)
        }
    },[fetching_error])


    useEffect(() => {


        console.log('fetching_email',fetching_email)

        if (fetching_email === 1) {
            toogleAlert(false)
            setForm({
                ...form,
                email:'',
                name:'',
            })

        }
        if (fetching_email === 2) {
            console.log('ingresad')
            
            setForm({
                ...form,
                name:selected.name,
                email:selected.email,
                
            })
            toogleAlert(false)
            
        }

        if(fetching_email===3){
            toogleAlert(false)
        }
    }, [fetching_email])

    useEffect(() => {
        setBranch(branch_office)
    }, [dining_table, branch_office])

    const updateField = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const updateFieldPet = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.checked
        })
    }

    const searchUser=(e)=>{
        

        if(e.target.value!==''){
            toogleAlert(true)
            getUserPhone(e.target.value)
        }
    }

    const reserve = () => {
        let _form = form
        _form.schedule_id = row.id;
        _form.branch_id = row.branch_id;

        if(createReservInstant(_form,row,dates)){
            toogleAlert(true)
            onSuccess(_form)
        }
    }

    const onHideModal=()=>{
        setForm(initState)
        onHide()
    }


    return (
        <Modal centered show={show} onHide={onHide} >
            <Modal.Body>
                <Container>
                    <Row className="my-2 text-center py-2">
                        <Col>
                            <Row>
                                
                                <Col>{(branch !== undefined) && branch.title} </Col>
                            </Row>
                            <Row>
                                <Col>{(row !== undefined) && row.content}</Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="my-2">
                        <Col>
                            <input type="number" className="form-control" placeholder="Celular" value={form.phone} 
                            name="phone" onChange={updateField} onBlur={searchUser}/>
                            {errors.phone !== undefined && (<Row className="d-inline-block w-100 mx-0 text-center">
                                <span className="text-naranja text-center ">{errors.phone}</span>
                            </Row>)}
                        </Col>
                    </Row>
                    <Row >
                        <Col>
                            <input type="email" className="form-control" value={form.email} placeholder="Correo" name="email" onChange={updateField} />
                            {errors.email !== undefined && (<Row className="d-inline-block w-100 mx-0 text-center">
                                <span className="text-naranja text-center ">{errors.email}</span>
                            </Row>)}
                        </Col>
                    </Row>
                    <Row className="my-2">
                        <Col>

                            <input type="text" className="form-control" placeholder="Nombres" value={form.name} name="name" onChange={updateField} />
                            {errors.name !== undefined && (<Row className="d-inline-block w-100 mx-0 text-center">
                                <span className="text-naranja text-center ">{errors.name}</span>
                            </Row>)}
                        </Col>
                    </Row>
                    
                   
                    <Row className="my-2">
                        <Col >
                            <input type="number" className="form-control " placeholder="Personas" value={form.people} name="people" onChange={updateField} />
                            {errors.people !== undefined && (<Row className="d-inline-block w-100 mx-0 text-center">
                                <span className="text-naranja text-center ">{errors.people}</span>
                            </Row>)}
                        </Col>
                    </Row>
                    <Row className="my-2">
                        <Col className="d-flex">
                            <h4>Mascota</h4>
                            <InputFieldCheck
                                name= 'pet'
                                onChange={updateFieldPet}
                                checked={form.pet} 
                                placeholder="Mascota"
                                />
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        <Col>
                            <BtnOrangeSm
                            title='Reservar'
                            onClick={reserve}
                            />
                        </Col>
                        <Col>
                            <BtnBlueModal
                            title='Cerrar'
                            onClick={onHideModal}
                            />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    )
}

const mapsState = (props) => {
    return {
        errors: props.reservation.errors,
        fetching: props.reservation.fetching_available_new,
        fetching_error: props.reservation.fetching,
        selected: props.user.selected,
        fetching_email: props.user.fetching_email
    }
}
export default connect(mapsState, { getUserPhone, getDateReserv, createReservInstant,toogleAlert })(FormReservationModal);
