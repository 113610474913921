import moment from 'moment';
import React, { useState } from 'react'
import { Table } from 'react-bootstrap';
import { formatNumber } from '../../utils';

const ItemDetailTurn = ({ item }) => {
    const [show, setShow] = useState(false)
    return (
        <>
            <tr onClick={() => setShow(!show)} className={`${item.turnos >= 20 ? 'table-danger' : ''}`}>
                <td>{item.worker}</td>
                <td align="right">{item.turnos}</td>
                <td align="right">{formatNumber(item.total)}</td>
            </tr>{
                show &&
                <tr>
                    <td colSpan={3}>
                        <Table className='table-sm table-hover table-bordered'>
                            <thead className='thead-dark'>
                                <tr>
                                    <th>Fecha</th>
                                    <th>Valor</th>
                                </tr>
                            </thead>
                            <tbody>
                                {item.detail.map((d, k) => <tr key={k}>
                                    <td>{moment(d.registration_date).format("YYYY-MM-DD")}</td>
                                    <td align='right'>{formatNumber(d.amount_real)}</td>
                                </tr>)}
                            </tbody>
                        </Table>
                    </td>
                </tr>}
        </>
    )
}

export default ItemDetailTurn;