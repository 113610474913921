import React, { useState } from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap';
import TemplateMenu from '../../template/menu.template'
import AsyncSelect from 'react-select/async';
import { formatNumber, getHeaders } from '../../utils';
import { onCreateSettlement } from '../../Redux/settlementDuck'
import moment from 'moment';
import { connect } from 'react-redux';
import { AiFillEdit } from 'react-icons/ai';
import { NumeroALetras } from '../../hooks/number_to_letter';
import { days360 } from '../../hooks/Days360_ararog';
const API_URL = process.env.REACT_APP_API_URL


const CreateSettlementPage = ({ onCreateSettlement }) => {
    const [worker, setWorker] = useState('')
    const [listWorker, setListWorker] = useState([])
    const [showVacation, setShowVacation] = useState(false);
    const [showAdvance, setShowAdvance] = useState(false);
    const [showSalaryPending, setShowSalaryPending] = useState(false);
    const [showExtraHour, setShowExtraHour] = useState(false);
    const [showAux, setShowAux] = useState(false);
    const [form, setForm] = useState({
        date_start_prima: '', date_end_prima: '', days_prima: 0, date_start_settlement: '', date_end_settlement: '', days_settlements: 0, date_start_layoff: '',
        date_end_layoff: '', days_layoff: 0, date_start_vacation: '', date_end_vacation: '', days_vacation: 0, total_vacation: 0, days_vacation_take: 0, days_vacation_pending: 0,
        date_start_interest: '', date_end_interest: '', days_interest: 0, total_interest: 0, total_layoff: 0, total_prima: 0, subtotal: 0, total: 0,
        health: 0, pension: 0, advances: 0, total_deductions: 0, defaul_aux_transport: 59300, aux_transport: 0, salary_pending: 0, salary_pending_total: 0,
        extra_hour: 0, aux_transport_total: 0
    })

    const loadOptions = async (inputValue) => {

        let branch = JSON.parse(localStorage.getItem("branch"))

        let url = `${API_URL}/api/worker-search/${inputValue}?branch_id=${branch.id}`

        let _search = await fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json())

        setListWorker(_search)
        return _search;
    }
    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const calculateDaysPending = (e) => {

        calculateTotal()
        setShowVacation(false)
    }

    const calculateDiscount = () => {
        let _form = form
        let total = form.total - form.advances

        setForm({
            ...form,
            ["total_deductions"]: form.advances,
            ["total"]: total
        })

        setShowAdvance(false)
    }

    const calculateSalary = () => {
        setShowSalaryPending(false)
        setForm({
            ...form,
            ["salary_pending"]: form.salary_pending,
        })

        calculateTotal()
    }

    const calculateTotal = () => {
        let days = form.days
        let salary = worker.salary;
        let days_pending = 15 * (days / 360)

        let salary_pending_total = ((salary || 0) / 30) * form.salary_pending;

        let total_vacation = (salary / 30) * days_pending;
        let total_layoff = (days * salary) / 360;
        let total_interest = (total_layoff * 0.12 * days) / 360;

        let days_vacation_pending = form.days_vacation - form.days_vacation_take;

        let aux_transport_total = (form.defaul_aux_transport / 30) * form.aux_transport;
        let total_prima = (salary * days) / 360;
        let subtotal = total_vacation + total_layoff + total_interest + total_prima + salary_pending_total + parseFloat(form.extra_hour) + parseFloat(aux_transport_total);
        let health = (salary_pending_total + form.extra_hour) * 0.04
        let pension = (salary_pending_total + form.extra_hour) * 0.04
        let total_deductions = form.advances + health + pension;
        let total = subtotal - form.advances;

        let _form = {
            ...form,
            ["health"]: health,
            ["pension"]: pension,
            ["days_vacation_pending"]: days_vacation_pending,
            ["salary_pending_total"]: salary_pending_total,
            ["aux_transport_total"]: aux_transport_total,
            ["total_vacation"]: total_vacation,
            ["total_layoff"]: total_layoff,
            ["total_interest"]: total_interest,
            ["total_prima"]: total_prima,
            ["subtotal"]: subtotal,
            ["total_deductions"]: total_deductions,
            ["total"]: total,
        }

        setForm(_form)
    }

    const calculateExtra = () => {
        setShowExtraHour(false)
        calculateTotal()
    }

    const calculateAux = () => {
        setShowAux(false)
        calculateTotal()
    }

    const onSave = () => {
        let _form = form
        _form.worker = worker;
        onCreateSettlement(_form)
    }

    return (
        <TemplateMenu>
            <Row className='d-flex justify-content-center'>
                <Col lg={4}>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header>
                                    <Row className='d-flex justify-content-end'>
                                        <Col lg={2}><button className='btn btn-sm btn-success' onClick={onSave}>Guardar</button></Col>
                                    </Row>

                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <div className='form-group'>
                                                <label>Trabajador</label>
                                                <AsyncSelect
                                                    value={worker}
                                                    placeholder="Busca el Trabajador"
                                                    loadOptions={loadOptions}
                                                    // defaultOptions={defaultOptions}
                                                    onChange={(e) => {
                                                        let _filter = listWorker.filter(s => parseInt(s.value) === parseInt(e.value))[0]
                                                        // console.log('_filter', _filter);

                                                        // setSelected(_filter)

                                                        var days = 0;
                                                        if (e.date_out !== null) {
                                                            let { salary, date_of_admission, date_out } = e
                                                            // let end = moment(date_out);
                                                            // let startTime = moment(date_of_admission);
                                                            // var duration = moment.duration(end.diff(startTime));

                                                            days = days360(new Date(date_of_admission), new Date(date_out)) + 1
                                                            // console.log('d',d);                                                            
                                                            // days = duration.asDays();

                                                            e.days = days

                                                            let days_pending = 15 * (days / 360)
                                                            let total_vacation = (salary / 30) * days_pending;
                                                            let total_layoff = (days * salary) / 360;
                                                            let total_interest = (total_layoff * 0.12 * days) / 360;
                                                            let total_prima = (salary * days) / 360;
                                                            let subtotal = total_vacation + total_layoff + total_interest + total_prima;
                                                            let total_deductions = form.advances;
                                                            let total = subtotal - form.advances;

                                                            let days_vacation_pending = days_pending - (form.days_vacation_take || 0)

                                                            setForm({
                                                                ...form,
                                                                ["date_start_prima"]: date_of_admission,
                                                                ["date_end_prima"]: date_out,
                                                                ["days_prima"]: days,
                                                                ["date_start_settlement"]: date_of_admission,
                                                                ["date_end_settlement"]: date_out,
                                                                ["date_start_layoff"]: date_of_admission,
                                                                ["date_end_layoff"]: date_out,
                                                                ["days_layoff"]: days,
                                                                ["date_start_vacation"]: date_of_admission,
                                                                ["date_end_vacation"]: date_out,
                                                                ["date_start_interest"]: date_of_admission,
                                                                ["date_end_interest"]: date_out,
                                                                ["days_interest"]: days,
                                                                ["days_vacation_take"]: form.days_vacation_take || 0,
                                                                ["days"]: days,
                                                                ["total_interest"]: total_interest,
                                                                ["total_layoff"]: total_layoff,
                                                                ["days_vacation_pending"]: days_vacation_pending,
                                                                ["total_vacation"]: total_vacation,
                                                                ["total_prima"]: total_prima,
                                                                ["days_vacation"]: days_pending,
                                                                ["subtotal"]: subtotal,
                                                                ["total"]: total,
                                                                ["total_deductions"]: total_deductions,
                                                            })
                                                        }

                                                        e.days = days
                                                        setWorker(e)
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='my-1'>
                                        <Col>Nombre</Col>
                                        <Col>{worker.label}</Col>
                                    </Row>
                                    <Row className='my-1'>
                                        <Col>Documento</Col>
                                        <Col>{worker.document}</Col>
                                    </Row>
                                    <Row className='my-1'>
                                        <Col>Cargo</Col>
                                        <Col>{worker.position}</Col>
                                    </Row>
                                    <Row className='my-1'>
                                        <Col>Salario</Col>
                                        <Col>{formatNumber(worker.salary || 0)}</Col>
                                    </Row>
                                    <Row className='my-1'>
                                        <Col>Fecha de Inicio</Col>
                                        <Col>{worker.date_of_admission}</Col>
                                    </Row>
                                    <Row className='my-1'>
                                        <Col>Fecha Fin</Col>
                                        <Col>{worker.date_out}</Col>
                                    </Row>
                                    <Row className='my-1'>
                                        <Col>Días Trabajados</Col>
                                        <Col>{worker.days}</Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>


                </Col>
                <Col>
                    <Row className='mt-2 d-flex justify-content-center'>
                        <Col lg={6}>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col><h5>Prima</h5></Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className='form-group'>
                                                <label>Fecha Liquidación Prima</label>
                                                <input type={"date"} name="date_start_prima" className='form-control' value={form.date_start_prima} onChange={updateInput}
                                                    placeholder='Fecha Liquidación Prima' />
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='form-group'>
                                                <label>Fecha Corte Prima</label>
                                                <input type={"date"} name="date_end_prima" value={form.date_end_prima} className='form-control'
                                                    placeholder='Fecha Corte Prima' onChange={updateInput} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>Días de Prima</Col>
                                        <Col>{form.days_prima.toFixed(2)}</Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                        </Col>
                        <Col lg={6}>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col><h5>Cesantias</h5></Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className='form-group'>
                                                <label>Fecha Liquidación Cesantias</label>
                                                <input type={"date"} className='form-control' name='date_start_layoff' value={form.date_start_layoff} onChange={updateInput}
                                                    placeholder='Fecha Liquidación Cesantias' />
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='form-group'>
                                                <label>Fecha Corte Cesantias</label>
                                                <input type={"date"} className='form-control' name='date_end_layoff' value={form.date_end_layoff} onChange={updateInput}
                                                    placeholder='Fecha Corte Cesantias' />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>Días de Cesantias</Col>
                                        <Col>{form.days_layoff.toFixed(2)}</Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className='mt-2 d-flex justify-content-center'>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col><h5>Vacaciones</h5></Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className='form-group'>
                                                <label>Fecha Liquidación Vacaciones</label>
                                                <input type={"date"} className='form-control' name='date_start_vacation' value={form.date_start_vacation} onChange={updateInput}
                                                    placeholder='Fecha Liquidación Vacaciones' />
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='form-group'>
                                                <label>Fecha Corte Vacaciones</label>
                                                <input type={"date"} className='form-control' name='date_end_vacation' value={form.date_end_vacation} onChange={updateInput}
                                                    placeholder='Fecha Corte Vacaciones' />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>Días de Vacaciones</Col>
                                        <Col>{form.days_vacation.toFixed(2)}</Col>
                                    </Row>
                                    <Row className='my-2'>
                                        <Col>Días Tomados</Col>
                                        <Col>
                                            {!showVacation ?
                                                <Row>
                                                    <Col>{form.days_vacation_take}</Col>
                                                    <Col><AiFillEdit className='cursor-pointer' onClick={() => setShowVacation(true)} /></Col>
                                                </Row> :
                                                <Row>
                                                    <Col lg={9}><input className='form-control' name='days_vacation_take' type={"number"} value={form.days_vacation_take}
                                                        onChange={updateInput} onBlur={calculateDaysPending} /></Col>
                                                    <Col>
                                                        <button className='btn btn-sm btn-info' onClick={calculateDaysPending}>Guardar</button>
                                                    </Col>
                                                </Row>}


                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>Días pendientes</Col>
                                        <Col>{form.days_vacation_pending.toFixed(2)}</Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                        </Col>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col><h5>Intereses de Cesantias</h5></Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className='form-group'>
                                                <label>Fecha Liquidación Intereses</label>
                                                <input type={"date"} className='form-control' name='date_start_interest' value={form.date_start_interest} onChange={updateInput}
                                                    placeholder='Fecha Liquidación Intereses' />
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='form-group'>
                                                <label>Fecha Corte Intereses</label>
                                                <input type={"date"} className='form-control' name='date_end_interest' value={form.date_end_interest} onChange={updateInput}
                                                    placeholder='Fecha Corte Intereses' />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>Días de Cesantias</Col>
                                        <Col>{form.days_interest.toFixed(2)}</Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                        </Col>
                    </Row>
                </Col>
            </Row >
            <Row className='mt-2'>
                <Col>
                    <Row>
                        <Col>
                            <Table className='table-sm table-bordered'>
                                <thead className='thead-dark'>
                                    <tr>
                                        <th>RESUMEN LIQUIDACIÓN</th>
                                        <th>VALOR</th>
                                        <th>SUBTOTAL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Días de Vaciones pendientes</td>
                                        <td>{form.days_vacation_pending.toFixed(2)}</td>
                                        <td>{formatNumber(form.total_vacation.toFixed(2))}</td>
                                    </tr>
                                    <tr>
                                        <td>Cesantias</td>
                                        <td>{formatNumber(worker.salary || 0)}</td>
                                        <td>{formatNumber(form.total_layoff)}</td>
                                    </tr>
                                    <tr>
                                        <td>Intereses de Cesantias</td>
                                        <td>{formatNumber(form.total_layoff)}</td>
                                        <td>{formatNumber(form.total_interest)}</td>
                                    </tr>
                                    <tr>
                                        <td>Prima de Servicios</td>
                                        <td>{formatNumber(worker.salary || 0)}</td>
                                        <td>{formatNumber(form.total_prima || 0)}</td>

                                    </tr>
                                    <tr>
                                        <td>Sueldo Pendiente por Cancelar</td>
                                        <td>
                                            {!showSalaryPending ?
                                                <Row>
                                                    <Col>{formatNumber(form.salary_pending)} (días)</Col>
                                                    <Col><AiFillEdit className='cursor-pointer' onClick={() => setShowSalaryPending(true)} /></Col>
                                                </Row> :
                                                <Row>
                                                    <Col lg={9}><input className='form-control' name='salary_pending' type={"number"} value={form.salary_pending}
                                                        onChange={updateInput} />
                                                    </Col>
                                                    <Col>
                                                        <button className='btn btn-sm btn-info' onClick={calculateSalary}>Guardar</button>
                                                    </Col>
                                                </Row>}

                                        </td>
                                        <td>{formatNumber(form.salary_pending_total)}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>Horas Extras Pendientes por cancelar</td>
                                        <td>
                                            {!showExtraHour ?
                                                <Row>
                                                    <Col>${formatNumber(form.extra_hour)}</Col>
                                                    <Col><AiFillEdit className='cursor-pointer' onClick={() => setShowExtraHour(true)} /></Col>
                                                </Row> :
                                                <Row>
                                                    <Col lg={9}><input className='form-control' name='extra_hour' type={"number"} value={form.extra_hour}
                                                        onChange={updateInput} />
                                                    </Col>
                                                    <Col>
                                                        <button className='btn btn-sm btn-info' onClick={calculateExtra}>Guardar</button>
                                                    </Col>
                                                </Row>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Aux. Transp. Pendientes por cancelar ({formatNumber(form.defaul_aux_transport)})</td>
                                        <td>
                                            {!showAux ?
                                                <Row>
                                                    <Col>{formatNumber(form.aux_transport)} (Días)</Col>
                                                    <Col><AiFillEdit className='cursor-pointer' onClick={() => setShowAux(true)} /></Col>
                                                </Row> :
                                                <Row>
                                                    <Col lg={9}><input className='form-control' name='aux_transport' type={"number"} value={form.aux_transport}
                                                        onChange={updateInput} />
                                                    </Col>
                                                    <Col>
                                                        <button className='btn btn-sm btn-info' onClick={calculateAux}>Guardar</button>
                                                    </Col>
                                                </Row>}
                                        </td>
                                        <td>{formatNumber(form.aux_transport_total)}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colSpan={2}>TOTAL DEVENGADO</th>
                                        <th colSpan={2}>{formatNumber(form.subtotal.toFixed(2))}</th>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Col>
                            <Table className='table-sm table-bordered'>
                                <thead className='thead-dark'>
                                    <tr>
                                        <th>RESUMEN DESCUENTOS LIQUIDACIÓN</th>
                                        <th>VALOR</th>
                                        <th>SUBTOTAL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Salud</td>
                                        <td>{"4%"}</td>
                                        <td>{formatNumber(form.health)}</td>
                                    </tr>
                                    <tr>
                                        <td>Pensión</td>
                                        <td>{"4%"}</td>
                                        <td>{formatNumber(form.pension)}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>Prestamos o anticipos</td>
                                        <td>
                                            {!showAdvance ?
                                                <Row>
                                                    <Col>{formatNumber(form.advances)}</Col>
                                                    <Col><AiFillEdit className='cursor-pointer' onClick={() => setShowAdvance(true)} /></Col>
                                                </Row> :
                                                <Row>
                                                    <Col lg={9}><input className='form-control' name='advances' type={"number"} value={form.advances}
                                                        onChange={updateInput} />
                                                    </Col>
                                                    <Col>
                                                        <button className='btn btn-sm btn-info' onClick={calculateDiscount}>Guardar</button>
                                                    </Col>
                                                </Row>}
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colSpan={2}>TOTAL DEDUCCIONES</th>
                                        <th colSpan={2}>{formatNumber(form.total_deductions)}</th>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table className='table-sm table-bordered'>
                                <thead className='thead-dark'>
                                    <tr>
                                        <th>DESCRIPCIÓN</th>
                                        <th>VALOR</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>VALOR A LIQUIDAR</td>
                                        <td>{formatNumber(form.total)}</td>
                                    </tr>
                                    <tr>
                                        <td>VALOR A LIQUIDAR EN TEXTO</td>
                                        <td>{NumeroALetras(form.total)}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Col>
            </Row>



        </TemplateMenu >
    )
}

const mapsState = (props) => {
    let { settlement } = props
    return {

    }
}

export default connect(mapsState, { onCreateSettlement })(CreateSettlementPage);