import React, { Fragment, useRef } from 'react'
import { Col, Modal, Row, Table } from 'react-bootstrap';
import { formatNumber } from '../../utils';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { RiFileExcel2Fill } from "react-icons/ri";

const ModalAdjust = ({ show, onHide, list, tableRef2 }) => {

    return (
        <Modal show={show} onHide={onHide} size='lg'>
            <Modal.Header closeButton>
                Para ajusta Inventario debes:
                <DownloadTableExcel
                    filename={`diferencias_inventario`}
                    sheet="cierres"
                    currentTableRef={tableRef2.current}>
                    <RiFileExcel2Fill size={25} color="green" className="cursor-pointer" />
                </DownloadTableExcel>
            </Modal.Header>
            <Modal.Body>
                <Table className='table table-sm' ref={tableRef2}>
                    <thead className='thead-dark'>
                        <tr>
                            <th>GRUPO</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {list.map((d, k) => {
                                    let { products } = d

                                    return <Fragment key={k}>
                                        <Table className='table table-sm table-bordered'>
                                            <thead>
                                                <tr>
                                                    <th>Realizar Compras</th>
                                                </tr>
                                                <tr>
                                                    <th>{d.description}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <Table className='table table-sm table-bordered' striped>
                                                            <thead className='thead-dark'>
                                                                <tr>
                                                                    <th>Producto</th>
                                                                    <th>Costo</th>
                                                                    <th>Cantidad</th>
                                                                    <th>Total</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {products.filter(d => parseFloat(d.difference) > 0).map((p, key) => <tr key={key}>
                                                                    <td>{p.product}</td>
                                                                    <td align='right'>{formatNumber(p.cost_average)}</td>
                                                                    <td align='right'>{formatNumber(p.difference)}</td>
                                                                    <td align='right'>{formatNumber(parseFloat(p.cost_average) * parseFloat(p.difference))}</td>
                                                                </tr>)}
                                                            </tbody>
                                                        </Table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        <Table className='table table-sm table-bordered' >
                                            <thead>
                                                <tr>
                                                    <th>Realizar Bajas</th>
                                                </tr>
                                                <tr>
                                                    <th>{d.description}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <Table className='table table-sm table-bordered' striped>
                                                            <thead className='thead-dark'>
                                                                <tr>
                                                                    <th>Producto</th>
                                                                    <th>Diferencia</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {products.filter(d => parseFloat(d.difference) < 0).map((p, key) => <tr key={key}>
                                                                    <td>{p.product}</td>
                                                                    <td align='right'>{formatNumber(p.difference)}</td>
                                                                </tr>)}
                                                            </tbody>
                                                        </Table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Fragment>
                                })}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Modal.Body>
        </Modal>
    )
}

export default ModalAdjust;