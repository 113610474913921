import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import TemplateMenu from '../../template/menu.template'
import { getRecipeBookAddId, createOrUpdateRecipeBooksAdd, getCategoryRecipe } from '../../Redux/recipe.book.Duck'
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import FormPrescriptionKitchen from './form.prescription.kitchen';
const API_URL = process.env.REACT_APP_API_URL


const EditPrescriptionKitchen = ({ getRecipeBookAddId, fetching_by_id, row, createOrUpdateRecipeBooksAdd, fetching_list, getCategoryRecipe, data, fetching_create }) => {

    const history = useHistory();
    const [form, setForm] = useState({ title: '', image: '', image_ingredient: '', image_preparation: '', image_on_table: '', image_delivery: '' })

    let { id } = useParams()

    useEffect(() => {
        if (id) {
            getRecipeBookAddId(id)
        }
    }, [id])

    useEffect(() => {
        if (fetching_by_id === 2) {
            setForm(row)
        }
    }, [fetching_by_id])

    useEffect(() => {
        if (fetching_create === 2) {
            history.goBack()
        }
    }, [fetching_create])

    const onSubmit = (form) => {
        createOrUpdateRecipeBooksAdd(form)
    }

    return (
        <TemplateMenu>
            <Row className='my-2'>
                <Col lg={1}>
                    <button className='btn btn-secondary btn-sm' onClick={() => history.goBack()}>Volver</button>
                </Col>
                <Col><h2>Modificar Recetario</h2></Col>

            </Row>
            <FormPrescriptionKitchen onSubmit={onSubmit} formEdit={form} />

        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { recipe_book } = props

    return {
        fetching_by_id: recipe_book.fetching_by_id,
        row: recipe_book.row,
        fetching_list: recipe_book.fetching_list,
        fetching_create: recipe_book.fetching_create,
        data: recipe_book.data,
    }
}

export default connect(mapsState, { getRecipeBookAddId, createOrUpdateRecipeBooksAdd, getCategoryRecipe })(EditPrescriptionKitchen);