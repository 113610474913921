let initialState = {
    fetching_list: 0,
    data:[]
}

let LIST_PRODUCT_RECIPE = "LIST_PRODUCT_RECIPE";
let LIST_PRODUCT_RECIPE_SUCCESS = "LIST_PRODUCT_RECIPE_SUCCESS";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_PRODUCT_RECIPE:
            return { ...state, fetching_list: 1,}
        case LIST_PRODUCT_RECIPE_SUCCESS:
            return { ...state, fetching_list: 2}
        default:
            return state;
    }
}

export let getProductsRecipe = () => (dispatch, getState) => {
    dispatch({
        type: LIST_PRODUCT_RECIPE,
    });
}