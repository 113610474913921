import React, { useState, useEffect } from "react";
import {
  Modal,
  Container,
  Row,
  Col,
  Button,
  Tab,
  Table,
} from "react-bootstrap";
import { MdClose } from "react-icons/md";

import InputField from "../Components/Widgets/InputField";
import { connect } from "react-redux";
import { createBranch, updateBranch } from "../../Redux/branchofficeDuck";
import BlueTitleBack from "./../Components/Widgets/BlueTitleBack";
import uuid from "react-uuid";



const initRow = {
  _id: '',
  branch_id: "",
  branch: "",
  amount: '',
};
const ModalBranch = ({
  showModal, onHide, errors, fetching, createBranch, updateBranch,
  current, onSuccess, data
}) => {
  const initState = {
    title: "",
    address: "",
    priority: "",
    is_reservation: false,
    is_ecommerce: false,
    is_warehouse: false,
    contribute_value: "",
    contribute_id: 0,
  };
  const initStateForm = {
    day: "",
    hour_open: "",
    hour_close: "",
    contribute: "",
    type_brand: 0,
  };

  const [form, setForm] = useState({
    title: "",
    contribute_id: 0,
    contribute_value: 0,
    activated: false,
  });

  const [temp, setTemp] = useState(initStateForm);
  const [days, setDays] = useState([]);
  const [listTips, setListTips] = useState([]);

  const [row, setRow] = useState(initRow);

  useEffect(() => {
    console.log("current", current);
    setForm(current);
    if (current.branch_office_tip !== undefined) {
      setListTips(current.branch_office_tip)
    }

  }, [current]);

  useEffect(() => {
    if (fetching == 2) {
      setForm(initStateForm);
      setDays([]);
      onHide();
      onSuccess();
    }
  }, [fetching]);

  const closeModalTmp = () => {
    onHide();
  };

  const updateInput = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const updateCheck = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.checked,
    });
  };
  const updateTemp = (e) => {
    setTemp({
      ...temp,
      [e.target.name]: e.target.value,
    });
  };

  const updateInputRow = (e) => {
    setRow({
      ...row,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = () => {
    let _form = form
    _form.tips = JSON.stringify(listTips)
    if (_form.id !== undefined) {
      updateBranch(_form, days);
    } else {
      createBranch(_form, days);
    }
  };
  const addTemp = () => {
    setDays([...days, temp]);
  };

  const printHour = () => {
    var arr = [];

    for (let i = 7; i <= 22; i++) {
      arr.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }

    return arr;
  };

  const deleteTemp = (item) => {
    let _list = days;

    _list.map((row, i) => {
      if (row == item) {
        delete _list[i];
      }
    });

    _list = _list.filter((row) => !!row);

    setDays(_list);

    console.log("_list", _list);
  };

  const printItem = (item, i) => {
    return (
      <tr key={i}>
        <td>
          {item.day} {item.hour_open + ":00"} a {item.hour_close + ":00"}
        </td>
        <td>
          <MdClose onClick={() => deleteTemp(item)} />
        </td>
      </tr>
    );
  };


  const addTip = () => {
    let uid = uuid()
    let _row = row
    _row._id = uid;
    let b = data.filter(d => parseInt(d.id) === parseInt(row.branch_id))[0]
    _row.branch = b
    setListTips([
      ...listTips,
      row
    ])

    setRow(initRow)
  }

  const onDeleteRow = (item) => {
    let _list = [];

    if (item.id !== undefined) {
      _list = listTips.map(d => {
        if (d.id === item.id) {
          d.delete = true
        }
        return d
      })

      setListTips(_list)
    }
  }


  console.log('listTips', listTips);


  return (
    <Modal show={showModal} onHide={closeModalTmp} size="lg" centered>
      <div className="modal-header border-bottom-custom">
        <BlueTitleBack title="SUCURSAL" />
        <button
          type="button"
          className="close p-0 m-0"
          data-dismiss="modal"
          aria-label="Close"
          onClick={closeModalTmp}
        >
          <MdClose />
        </button>
      </div>
      <Modal.Body>
        <Container fluid>
          <Row>
            <Col>
              <div className="form-group">
                <label>Título Sucursal</label>
                <InputField
                  placeholder="Título de la Sucursal"
                  value={form.title || ""}
                  name="title"
                  onChange={updateInput}
                  error={errors.title}
                />
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label>Celular Pagos</label>
                <InputField
                  type="text"
                  placeholder="Celular"
                  value={form.phone || ""}
                  name="phone"
                  onChange={updateInput}
                  error={errors.phone}
                />
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label>Orden</label>
                <InputField
                  type="number"
                  placeholder="Orden"
                  value={form.priority || ""}
                  name="priority"
                  onChange={updateInput}
                  error={errors.priority}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="form-group">
                <label>Dirección</label>
                <InputField
                  placeholder="Dirección"
                  value={form.address || ""}
                  name="address"
                  onChange={updateInput}
                  error={errors.address}
                />
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label>Complemento</label>
                <InputField
                  placeholder="complement"
                  value={form.complement || ""}
                  name="complement"
                  onChange={updateInput}
                  error={errors.complement}
                />
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label>Indicator</label>
                <InputField
                  placeholder="indicator"
                  value={form.indicator || ""}
                  name="indicator"
                  onChange={updateInput}
                  error={errors.indicator}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="form-group">
                <label>Estado</label>
                <input
                  type="checkbox"
                  className="form-control"
                  checked={form.status_id || false}
                  name="status_id"
                  onChange={updateCheck}
                />
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label>¿Abierto Hoy?</label>
                <input
                  type="checkbox"
                  className="form-control"
                  checked={form.activated || false}
                  name="activated"
                  onChange={updateCheck}
                />
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label>¿Activar para Ecommerce?</label>
                <input
                  type="checkbox"
                  className="form-control"
                  checked={form.is_ecommerce || false}
                  name="is_ecommerce"
                  onChange={updateCheck}
                />
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label>Tipo de Sucursal</label>
                <select
                  className="form-control"
                  name="contribute_id"
                  value={form.contribute_id}
                  onChange={updateInput}>
                  <option value={0}>Seleccione</option>
                  <option value={1}>Oficina(-)</option>
                  <option value={2}>Bodega(-)</option>
                  <option value={3}>Punto de Venta(+)</option>
                </select>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <Table className="table-sm table-hover table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th>Sucursal</th>
                    <th>Valor</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <select
                        className="form-control"
                        value={row.branch_id}
                        name="branch_id"
                        onChange={updateInputRow}
                      >
                        <option value={0}>Seleccione</option>
                        {data.map(d => <option value={d.id} key={d.id}>{d.title}</option>)}

                      </select>
                    </td>
                    <td>
                      <input
                        type={"number"}
                        className="form-control"
                        placeholder="Agrega Propina"
                        value={row.amount}
                        name="amount"
                        onChange={updateInputRow}
                      />
                    </td>
                    <td>
                      <button className="btn btn-sm btn-info" onClick={addTip}>Agregar</button>
                    </td>
                  </tr>
                  {listTips.filter(d => d.delete === undefined).map((d, k) =>
                    <tr key={k}>
                      <td>{d.branch.title}</td>
                      <td>{d.amount}</td>
                      <td>
                        <button className="btn btn-danger btn-sm" onClick={() => onDeleteRow(d)}>Borrar</button>
                      </td>
                    </tr>)}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="form-group">
                <label>Selecciona el Día</label>
                <select
                  name="day"
                  value={temp.day}
                  onChange={updateTemp}
                  className="form-control"
                >
                  <option value="0">Selecciona</option>
                  <option value="lunes">Lunes</option>
                  <option value="martes">Martes</option>
                  <option value="miercoles">Miercoles</option>
                  <option value="jueves">Jueves</option>
                  <option value="viernes">Viernes</option>
                  <option value="sabado">Sábado</option>
                  <option value="domingo">Domingo</option>
                </select>
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label>Hora Apertura</label>
                <select
                  name="hour_open"
                  value={temp.hour_open}
                  onChange={updateTemp}
                  className="form-control"
                >
                  <option value="0">Selecciona</option>
                  {printHour()}
                </select>
              </div>
            </Col>
            <Col>
              <div className="form-group">
                <label>Hora Cierre</label>
                <select
                  name="hour_close"
                  value={temp.hour_close}
                  onChange={updateTemp}
                  className="form-control"
                >
                  <option value="0">Selecciona</option>
                  {printHour()}
                </select>
              </div>
            </Col>
            <Col className="align-items-center d-flex justify-content-center">
              <button className="btn btn-info btn-sm" onClick={addTemp}>
                Agregar
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table>
                <thead>
                  <tr>
                    <th>Horario</th>
                    <th>Acción</th>
                  </tr>
                </thead>
                <tbody>{days.map((row, i) => printItem(row, i))}</tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={closeModalTmp}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapsState = (props) => {
  let { branchoffice } = props


  return {
    errors: branchoffice.errors,
    fetching: branchoffice.fetching_save,
    data: branchoffice.data
  };
};

export default connect(mapsState, { createBranch, updateBranch })(ModalBranch);
