import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import ItemTransfer from './item.transfer';
import { getListTransfer, onDeleteTransfer } from '../../Redux/transferDuck';
import { connect } from 'react-redux';
import SearchSection from '../Components/SearchSection';


const ListTransferOrigin = ({ getListTransfer, fetching_list, products, fetching_create, onDeleteTransfer, fetching_delete }) => {

    const [list, setList] = useState([])

    useEffect(() => {
        getListTransfer(1)
    }, [])
    useEffect(() => {
        if (fetching_create === 2) {
            getListTransfer(1)
        }
    }, [fetching_create])
    useEffect(() => {
        if (fetching_delete === 2) {
            getListTransfer(1)
        }
    }, [fetching_delete])

    useEffect(() => {
        if (fetching_list === 2) {
            let branch = JSON.parse(localStorage.getItem("branch"))
            let _p = products.filter(p => p.branch_id === branch.id);
            setList(_p)
        }
    }, [fetching_list])


    const onDelete = (item) => {
        if (window.confirm("Realmenre deseas Eliminar esta Transferencia?")) {
            onDeleteTransfer(item.id)
        }
    }


    const filterContent = () => {

    }

    const onCleanFilter = () => {

    }

    return (
        <>
            <Row className=''>
                <Col>
                    <table className="table table-sm table-bordered table-hover table-sm">
                        <thead className='thead-dark'>
                            <tr>
                                <th></th>
                                <th>Fecha de Solicitud</th>
                                <th>Punto Destino</th>
                                <th>Realizado por</th>
                                <th>Total</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(list.length > 0) ? list.map(row => <ItemTransfer type={1}
                                title={row.branch_delivery.title} row={row} key={row.id} onDelete={onDelete} />) : <tr><td colSpan={6}>No se encontraron registros</td></tr>}
                        </tbody>
                    </table>
                </Col>
            </Row>
        </>

    )
}

const mapsState = (props) => {
    let { transfer } = props
    return {
        fetching_list: transfer.fetching_list,
        fetching_create: transfer.fetching_create,
        fetching_delete: transfer.fetching_delete,
        products: transfer.data,
    }
}
export default connect(mapsState, { getListTransfer, onDeleteTransfer })(ListTransferOrigin);