import React, { useEffect, useState } from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap';
import BlueTitleBack from '../Components/Widgets/BlueTitleBack';
import uuid from 'react-uuid';
import InputField from '../Components/Widgets/InputField';


import { MdClose } from "react-icons/md";

import moment from 'moment';
import { connect } from 'react-redux';
import { formatNumber } from '../../utils';
import InputFieldCurrency from '../Components/Widgets/InputFieldCurrency';

const initState = { registration_date: moment().format("YYYY-MM-DD"), status_id: 2, other_id: 0, parameter_id: 0, method_payment_id: 0, observation: '', amount: '' };

const ModalAddAlterOtherExpense = ({ show, onHide, listMethods, listBranch, onSubmit, onOpenParameter,
    typeData, formEdit, errors, type, listParameterGroup, data, available }) => {
    const [form, setForm] = useState(initState)

    const [listParameter, setListParameter] = useState([])
    const [total, setTotal] = useState(0)
    const [error, setError] = useState('')


    useEffect(() => {
        if (formEdit !== undefined && Object.keys(formEdit).length > 0) {
            setForm(formEdit)
        }
    }, [formEdit])

    useEffect(() => {
        if (available !== undefined) {
            setTotal(available)
        }
    }, [available])


    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        if (e.target.name === 'parameter_group_id') {
            setListParameter(listParameterGroup.filter(f => parseInt(f.id) === parseInt(e.target.value))[0].children)
        }

        if (e.target.name === 'method_payment_id') {
            let expense = data.total_expense.filter(d => parseInt(d.id) === parseInt(e.target.value));
            if (expense.length > 0) {
                let total = 0;

                if (e.target.value === "375") {
                    total = data.total.datafono
                }
                if (e.target.value === "374") {
                    total = data.total.cash - data.total.tips
                }
                if (e.target.value === "17") {
                    total = data.total.daviplata
                }
                if (e.target.value === "16") {
                    total = data.total.nequi
                }

                setTotal(total - expense[0].total)
            } else {
                setTotal(0)
            }
        }
    }

    const printWorker = (row) => {
        return <option key={row.id} value={row.id}>{row.description}</option>
    }

    const printConcept = (row) => {
        let { parameter } = row
        return <option key={row.id} value={parameter.id}>{parameter.description}</option>
    }


    const onSubmitTmp = () => {
        let _form = form
        _form.status_id = 2;

        if (type !== undefined) {
            _form.method_payment_id = type;
        }

        if (total < _form.amount) {
            if (window.confirm("No tienes Cupo suficiente, deseas continuar?")) {
                onSubmit(_form)
                setForm(initState)
            }
        } else {
            onSubmit(_form)
            setForm(initState)
        }
    }


    const onHidePre = () => {
        setForm(initState)
        onHide()
    }

    return (
        <Modal show={show} onHide={onHidePre}>
            <div className="modal-header border-bottom-custom mt-2">
                <BlueTitleBack title={`Agregar Pago a otro Punto`} />
                <button type="button" className="close p-0 m-0" data-dismiss="modal" aria-label="Close" onClick={onHidePre}>
                    <MdClose />
                </button>
            </div>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Fecha de Registro</label>
                                <input type={"date"} className='form-control' name='registration_date' value={form.registration_date} onChange={updateInput} />
                            </div>
                        </Col>
                    </Row>
                    {type === undefined && <Row className='d-flex justify-content-center'>
                        <Col>
                            <div className="form-group">
                                <label>Método de Pago</label>
                                <select className="form-control" name="method_payment_id" value={form.method_payment_id} onChange={updateInput}>
                                    <option>Seleccione</option>
                                    {listMethods.map(r => <option key={r.id} value={r.id}>{r.description}</option>)}
                                </select>
                                {errors.parameter_id !== undefined && (<label className="error-label">{errors.method_payment_id}</label>)}

                            </div>
                        </Col>

                    </Row>}

                    <Row className='d-flex justify-content-center'>
                        <Col>
                            <div className="form-group">
                                <label>Sucursal a pagar</label>
                                <select className="form-control" name="other_id" value={form.other_id} onChange={updateInput}>
                                    <option>Seleccione</option>
                                    {listBranch.map(r => <option key={r.id} value={r.id}>{r.title}</option>)}
                                </select>
                                {errors.other_id !== undefined && (<label className="error-label">{errors.other_id}</label>)}
                            </div>
                        </Col>

                    </Row>
                    <Row className='d-flex justify-content-center'>
                        <Col>
                            <div className="form-group">
                                <label>Grupo</label>
                                <select className="form-control" name="parameter_group_id" value={form.parameter_group_id} onChange={updateInput}>
                                    <option>Seleccione</option>
                                    {listParameterGroup.map(printWorker)}
                                </select>
                                {errors.parameter_group_id !== undefined && (<label className="error-label">{errors.parameter_group_id}</label>)}

                            </div>
                        </Col>
                    </Row>
                    <Row className='d-flex justify-content-center'>
                        <Col xs={8}>
                            <div className="form-group">
                                <label>Concepto</label>
                                <select className="form-control" name="parameter_id" value={form.parameter_id} onChange={updateInput}>
                                    <option>Seleccione</option>
                                    {listParameter.map(printConcept)}
                                </select>
                                {errors.parameter_id !== undefined && (<label className="error-label">{errors.parameter_id}</label>)}

                            </div>
                        </Col>
                        <Col className='d-flex align-items-center'>
                            <button className='btn btn-info' onClick={() => onOpenParameter(typeData)}>Agregar</button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Observación</label>
                                <input className='form-control' name="observation" value={form.observation || ''} onChange={updateInput} />
                            </div>
                        </Col>
                    </Row>
                    <Row>

                        <Col>
                            <div className="form-group">
                                <label>Monto</label>
                                <InputFieldCurrency
                                    name={"amount"}
                                    placeholder="Monto"
                                    onValueChange={(value, name) => setForm({ ...form, [name]: value })}
                                    error={errors.amount}
                                />
                                {/* <InputField
                                    type="number"
                                    placeholder={"Monto"}
                                    value={form.amount || ''}
                                    name="amount"
                                    onChange={updateInput}
                                    error={errors.amount}
                                /> */}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='text-center'>
                            <Col className='text-center'>
                                {available < 0 ? (<><label>No tienes Suficiente Disponible </label><h4 className='text-danger'>{formatNumber(available)}</h4></>) :
                                    <span >Disponible para uso <h4 className={`${available < 0 ? 'text-danger' : 'text-success'}`}>{formatNumber(available)}</h4></span>}
                            </Col>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Row >
                    <Col>
                        <button className='btn btn-danger' onClick={onHide}>Cerrar</button>
                    </Col>
                    <Col>
                        <button className='btn btn-success' onClick={onSubmitTmp}>Guardar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

const mapsState = (props) => {
    let { } = props
    return {

    }
}

export default connect(mapsState)(ModalAddAlterOtherExpense);