import React, { Fragment } from 'react'
import 'index.css'

const InputField = ({ type, name, value, placeholder, onChange, error, readOnly, required, maxLength, textRight, onBlur }) => {
    type = type || 'text'
    readOnly = readOnly || false
    textRight = textRight || false
    required = required || ""
    maxLength = maxLength || 10

    let text_right = type === 'number' || textRight === true ? 'text-right' : ''
    return (
        <Fragment>
            <input
                required
                type={type}
                className={`form-control input-sm ${text_right}`}
                name={name}
                value={value}
                placeholder={placeholder}
                onBlur={onBlur}
                onChange={onChange}
                readOnly={readOnly} />
            {error !== undefined && (<label className="error-label">{error}</label>)}
        </Fragment>
    )
}

export default InputField