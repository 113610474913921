import React, { useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';

import { MdClose } from "react-icons/md";


const EditmodalTable = ({ show, onHide, availables, confirmEditTable }) => {

  const [item, setItem] = useState('')

  const printSelect = (item) => {
    return (
      <option key={item.id} value={item.id}>Mesa #{item.position}, Hora {item.hour_start} - {item.hour_end}</option>
    )
  }

  return (
    <Modal show={show}>
      <Modal.Header className=" border-bottom-custom py-1 m-0">
        <h5 className="align-middle text-gris font-15 pt-3">Cambio de mesa</h5>
        <button type="button" className="close p-0 m-0" data-dismiss="modal" aria-label="Close" onClick={onHide}>
          <MdClose />
        </button>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <select className="form-control" onChange={e => setItem(e.target.value)}>
              <option value='0'>Seleccione</option>
              {availables.map(printSelect)}
            </select>
          </Col>
        </Row>
        <Row className="mt-4 justify-content-end">
          <Col >
            <button className="btn btn-info" onClick={() => confirmEditTable(item)}>Reservar</button>
            <button className="btn btn-secondary ml-2" onClick={onHide}>Cerrar</button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}
export default EditmodalTable;