import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { onCreateRequisition, getListTemplate } from "../../Redux/requisitionDuck";
import TemplateMenu from "../../template/menu.template";
import { useHistory } from "react-router-dom";
import RowProduct from "./row.product";
import moment from "moment";
import { formatNumber } from "../../utils";
import { toogleLoader } from '../../Redux/alertDuck';
import ItemDetailRequisition from "./item.detail.requisition";


const CreateRequisitionPage = ({ errors, fetching_list, branch_offices, onCreateRequisition, fetching_create,
  getListTemplate, fetching_template, data_template, toogleLoader }) => {
  const [form, setForm] = useState({
    request_branch_id: 0,
    registration_date: '',
    date_init: "", date_end: "",
    has_charge: false,
    branch: { title: '' }
  });
  const history = useHistory();
  const [listBranch, setListBranch] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [listProduct, setListProduct] = useState([]);
  const [listDetail, setListDetail] = useState([]);
  const [listTemplate, setListTemplate] = useState([]);
  const [show, setShow] = useState(false);
  const [detail, setDetail] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (fetching_create === 2) {
      toogleLoader(false)
      history.goBack();
    }
  }, [fetching_create]);
  useEffect(() => {
    if (fetching_template === 2) {
      setListTemplate(data_template)
    }
  }, [fetching_template]);

  useEffect(() => {
    if (fetching_list === 2) {
      let branch = JSON.parse(localStorage.getItem("branch"))

      let _branch = branch_offices.filter(d => parseInt(d.id) !== parseInt(branch.id))
      setListBranch(_branch);
    }
  }, [fetching_list]);

  useEffect(() => {
    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));
    let branch = JSON.parse(localStorage.getItem("branch"));
    let _month = moment(date_init).format("MM");
    let _current_month = moment().format("MM");
    let _form = {
      ...form,
      ["date_init"]: date_init,
      ["date_end"]: date_end,
      branch: branch
    };
    if (_month !== _current_month) {
      _form.registration_date = date_init;
    }

    setForm(_form);

    getListTemplate()

  }, []);

  const updateInput = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === 'registration_date') {
      setDetail(true)
    }
  };
  const updateInputCheck = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.checked,
    });
  };

  const onAddProduct = () => {

    if (Object.keys(selectedProduct).length > 0) {
      let _list = [...listDetail, selectedProduct];
      setListDetail(_list);

      setSelectedProduct({});

      let _total = _list.reduce(
        (a, b) => (a = a + parseFloat(b.cost * b.quantity)),
        0
      );

      setTotal(_total);
      setShow(false);
    } else {
      setShow(true);
    }
  };

  const onDeleteItem = (item) => {

    let list = []
    if (item.id === undefined) {
      list = listDetail.filter((l) => l._id !== item._id);
    } else {
      list = listDetail.filter((l) => l.id !== item.id);
    }

    let _total = list.reduce((a, b) => (a = a + parseFloat(b.amount)), 0);

    setTotal(_total);

    setListDetail(list);
  };

  const onAddItem = (item) => {
    let validate = listDetail.filter(d => d.product.id === item.product.id).length

    let _list = [...listDetail, item];

    if (validate > 0) {
      alert("Producto ya existe")
      return
    }

    let _total = _list.reduce((a, b) => (a = a + parseFloat(b.amount)), 0);

    let _totalChange = _list.reduce(
      (a, b) => (a = a + parseFloat(b.amount) * parseFloat(b.cost_average) +
        ((parseFloat(b.amount) * parseFloat(b.cost_average) * parseFloat(b.charge / 100)))), 0);


    setTotal(_total);

    setListDetail(_list);
    setShow(false);
  };

  const onSuccess = () => {

    let validate = listDetail.filter(d => d.amount > 0)

    if (validate.length > 0) {
      let _form = form;
      _form.list_products = validate;

      toogleLoader(true)
      onCreateRequisition(_form);
    } else {
      alert("No puedes crear una orden vacia");
    }
  };

  const onEdit = (item) => {
    setShow(true);
    setSelectedProduct(item);
  };

  const onEditItem = (item) => {
    let _list = listDetail.map((d) => {
      if (item._id === d._id) {
        d.amount = item.amount;
        d.cost = item.cost;
      }
      return d;
    });

    setListDetail(_list);
    setSelectedProduct({});
    setShow(false);
  }

  const onSelectTemplate = (e) => {
    if (parseInt(e.target.value) !== 0) {
      let { detail } = listTemplate.filter(d => parseInt(d.id) === parseInt(e.target.value))[0]
      setListDetail(detail)
    } else {
      if (window.confirm("¿Deseas Eliminar el listado?")) {
        setListDetail([])
      }
    }
  }

  const onNotifierChange = () => {
    let _total = listDetail.reduce((a, b) => (a = a + parseFloat(b.amount)), 0);
    setTotal(_total)
  }

  return (
    <TemplateMenu>
      <Container fluid>
        <Row className="my-2">
          <Col>
            <button className="btn btn-sm btn-secondary" onClick={() => history.goBack()}>
              Volver
            </button>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col lg={10}>
            <Row className="my-2">
              <Col lg={10}>
                <h3 className="text-center">Realizar Requisición</h3>
              </Col>
              <Col lg={2}>
                <button className="btn btn-success btn-sm" onClick={onSuccess}>
                  Solicitar
                </button>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <Row>
                  <Col>
                    <div className="form-group">
                      <label>Solicitar a:</label>
                      <select className="form-control" name="request_branch_id" value={form.request_branch_id}
                        onChange={updateInput} >
                        <option value="0">Seleccione</option>
                        {listBranch.map((m) => (
                          <option key={m.id} value={m.id}>{m.title}</option>
                        ))}
                      </select>
                      {errors.request_branch_id !== undefined && (
                        <label className="error-label">
                          {errors.request_branch_id}
                        </label>
                      )}
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group">
                      <label>Fecha Esperada de Entrega</label>
                      <input
                        type={"date"}
                        min={form.date_init}
                        max={form.date_end}
                        className="form-control"
                        name="registration_date"
                        value={form.registration_date}
                        onChange={updateInput}
                      />
                    </div>
                  </Col>
                  <Col>
                    {detail && <div className="form-group">
                      <label>Plantilla</label>
                      <select className="form-control" onChange={onSelectTemplate}>
                        <option value={"0"}>Seleccione</option>
                        {listTemplate.map((d, k) => <option key={k} value={d.id}>{d.name}</option>)}
                      </select>
                    </div>}

                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col className="">
                {detail && <table className="table table-sm table-bordered">
                  <thead className="thead-dark">
                    <tr>
                      <th width="40%">Producto a Solicitar</th>
                      <th align="right">Stock en ({form.branch.title})</th>
                      <th width="30%">Cantidad a Solicitar</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>

                    {listDetail.map((d, key) => (
                      <ItemDetailRequisition
                        index={key + 1}
                        item={d}
                        key={key}
                        onEdit={onEdit}
                        onDelete={onDeleteItem}
                        listProduct={listProduct}
                        has_charge={form.has_charge}
                        onNotifierChange={onNotifierChange} />
                    ))}
                    {show === true && (
                      <RowProduct
                        has_charge={form.has_charge}
                        registration_date={form.registration_date}
                        request_branch_id={form.request_branch_id}
                        onEditItem={onEditItem}
                        formEdit={selectedProduct}
                        listProduct={listProduct}
                        onAddItem={onAddItem}
                        onDelete={() => {
                          setSelectedProduct({});
                          setShow(false);
                        }}
                      />
                    )}
                    <tr>
                      <td colSpan={7}>
                        {!show && (
                          <button className="btn btn-sm btn-info" onClick={onAddProduct}>
                            Agregar Producto
                          </button>
                        )}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={2} align="right">
                        TOTAL
                      </td>
                      <td align="right">{formatNumber(total)}</td>
                    </tr>
                  </tfoot>
                </table>}

              </Col>
            </Row>
          </Col>
        </Row>
        {/* <ModalReviewPurchase
          show={modal}
          listDetail={listDetail}
          onSuccess={onConfirmPurchase}
          onHide={() => setModal(false)}
        /> */}
      </Container>
    </TemplateMenu>
  );
};

const mapsState = (props) => {
  let { branchoffice, requisition } = props;

  return {
    fetching_list: branchoffice.fetching,
    branch_offices: branchoffice.data,
    fetching_create: requisition.fetching_create,
    fetching_template: requisition.fetching_template,
    data_template: requisition.data_template,
    errors: {}
  };
};

export default connect(mapsState, { onCreateRequisition, getListTemplate, toogleLoader })(CreateRequisitionPage);
