import React, { useEffect, useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getOrderId } from '../../Redux/orderDuck'

import {getOrdersFinish} from '../../Redux/orderDuck'
import ItemOrderTable from './item.order.table';
import ModalDetailOrder from './ModalDetailOrder';
import { toogleAlert } from '../../Redux/alertDuck'


const OrdersHistory = ({getOrdersFinish,fetching_finish, data, getOrderId, toogleAlert, fetching_id,detail}) => {

    const [list,setList] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [row, setRow] = useState([])


    useEffect(()=>{
        getOrdersFinish()
        setShowModal(false)
    },[])

    useEffect(() => {

        if (fetching_id === 2) {
            toogleAlert(false)
            setShowModal(true);
            setRow(detail)
        }

        if (fetching_id === 0) {
            setShowModal(false);
        }

    }, [fetching_id])


    useEffect(()=>{
        if(fetching_finish===2){
            setList(data)
        }
    },[fetching_finish])

    const showDetail=(item)=>{
        toogleAlert(true)
        getOrderId(item.id)
        
    }

    const onHide = () => {
        setShowModal(false);
    }

    return (
        <Container fluid>
            <Row>
                <Col>
                <h2>Pedidos</h2>
                </Col>
            </Row>
            <Row className="pr-3">
                <Col lg={12}>
                    <table className="p-0 mx-3 bg-azul table-bordered rounded-lg m-0 w-100 table-hover table-header-custom">
                        <thead>
                            <tr>
                                <td>#</td>
                                <td>Factura Aldelo</td>
                                <td>Orden del dia</td>
                                <td>Cliente</td>
                                <td>Estado</td>
                                <td>PDA</td>
                                <td>Estado</td>
                                <td>Fecha</td>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map(row=><ItemOrderTable key={row.id} item={row} showDetail={showDetail}/>)}
                        </tbody>
                    </table>
                </Col>
            </Row>

            <ModalDetailOrder
                
                show={showModal}
                data={row}
                
                onHide={onHide}
            />
        </Container>

    )
}

const mapsState=(props)=>{
    return {
        data:props.order.data,
        fetching_finish:props.order.fetching_order_finish,
        fetching_id: props.order.fetching_id,
        detail: props.order.detail,


    }
}
export default connect(mapsState,{getOrdersFinish, getOrderId, toogleAlert})(OrdersHistory);