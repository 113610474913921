import React, { useEffect, useRef, useState } from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import TemplateMenu from '../../template/menu.template'
import { getPayrollGroupId, onCreateMultiplePdf, reverseAllPayroll } from '../../Redux/payrollDuck'
import ItemTableGroupPayroll from './item.table.group.payroll';
import { formatNumber } from '../../utils';
import { AiFillLock } from 'react-icons/ai';
import { RiFileExcel2Fill } from "react-icons/ri";

import { DownloadTableExcel } from 'react-export-table-to-excel';

const API_URL = process.env.REACT_APP_API_URL


const PayrollGroupPage = ({ getPayrollGroupId, fetching_by_id, row, onCreateMultiplePdf,
    reverseAllPayroll, fetching_reverse, fetching_pdf, link }) => {
    let { id } = useParams()

    const [data, setData] = useState({})
    const [detail, setDetail] = useState([])
    const [total, setTotal] = useState(0)
    const history = useHistory()
    const tableRef = useRef(null);

    useEffect(() => {

        getPayrollGroupId(id)
    }, [id])

    useEffect(() => {

        if (fetching_reverse === 2) {
            history.push("/nomina");
        }
    }, [fetching_reverse])

    useEffect(() => {
        if (fetching_by_id === 2) {
            setData(row)
            setDetail(row.detail)

            let _total = row.detail.reduce((a, { payroll }) => a = a + parseFloat(payroll.salary_real) + parseFloat(payroll.bonification_real) + parseFloat(payroll.other_payment) - parseFloat(payroll.other_deductions) - parseFloat(payroll.deductions), 0)
            setTotal(_total)
        }
    }, [fetching_by_id])

    useEffect(() => {
        if (fetching_pdf === 2) {
            console.log(window.open(`${API_URL}/${link}`, '_blank'));
        }

    }, [fetching_pdf])

    const onPrintPdf = () => {

        console.log('listPaysheet.filter( r=>r.selected===true)', detail);
        onCreateMultiplePdf(detail)
        // listPaysheet.filter(r => r.selected === true).map(async r => {
        //     // console.log(window.open(`http://ws.manaexpress.online/pdf/${r.id}.pdf`, '_blank'));
        //     console.log(window.open(`${API_URL}/pdf/${r.id}.pdf`, '_blank'));
        // })
    }

    const unLockAll = () => {
        if (window.confirm("¿Estas seguro de realizar esta acción?")) {
            reverseAllPayroll(id)
        }

    }

    return (
        <TemplateMenu>
            <Row className='d-flex justify-content-center mt-4'>
                <Col lg={10}>
                    <Row className='my-3'>
                        <Col>
                            <button className='btn btn-sm btn-secondary' onClick={() => history.goBack()}>Volver</button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header>
                                    <Row>
                                        <Col lg={8}><h5>Nomina Liquidada {data.range || ''}, Total: ${formatNumber(data.total)}</h5></Col>
                                        <Col className='d-flex justify-content-end'>
                                            <button className='btn btn-sm btn-info mx-2' onClick={onPrintPdf}>Imprimir</button>
                                            <button className='btn btn-sm btn-danger' onClick={unLockAll}>Reversar Todo</button>


                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <DownloadTableExcel
                                                filename={"nomina_" + data.range || ''}
                                                sheet="nomina"
                                                currentTableRef={tableRef.current}>
                                                <RiFileExcel2Fill size={25} color="green" />
                                            </DownloadTableExcel>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Table className='table-sm table-bordered' responsive ref={tableRef}>
                                                <thead className='thead-dark'>
                                                    <tr>
                                                        <th>Fecha</th>
                                                        <th>Trabajador</th>
                                                        <th>Días</th>
                                                        <th>Básico</th>
                                                        <th>Bonificación</th>
                                                        <th>Descuentos</th>
                                                        <th>Otros Descuentos</th>
                                                        <th>Otros Pagos</th>
                                                        <th>Pagado</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {detail.map((d, k) => <ItemTableGroupPayroll key={k} item={d} />)}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan={8}>TOTAL</td>
                                                        <td align='right'>{formatNumber(total)}</td>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </TemplateMenu>

    )
}

const mapsState = (props) => {
    let { payroll } = props

    return {
        fetching_by_id: payroll.fetching_by_id,
        row: payroll.row,
        fetching_reverse: payroll.fetching_reverse,
        fetching_pdf: payroll.fetching_pdf,
        link: payroll.link,


    }
}

export default connect(mapsState, { getPayrollGroupId, onCreateMultiplePdf, reverseAllPayroll })(PayrollGroupPage);