import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { formatNumber } from "../../utils";

const ItemPrescription = ({ item, onDelete, onNotify, onChangeType }) => {
  let { product_inventory, product, cost_average, measure_unit } = item;

  const [show, setShow] = useState(false);
  const [amount, setAmount] = useState(0);

  const onEdit = (item) => {
    setShow(true);
    setAmount(item.amount);
  };

  const onSaveRow = () => {
    item.amount = amount;
    setShow(false);
    onNotify();
  };

  const updateTypeProduct = (e) => {
    item.type_product_id = e.target.checked === true ? 2 : 1;
    onChangeType(item);
  };

  return (
    <tr className={`${item.type_product_id === 1 ? "" : "table-primary"}`}>
      <td>{product}</td>
      <td>{measure_unit}</td>
      <td align="right">{formatNumber(cost_average)}</td>
      <td align="right">
        {show ? (
          <input
            className="form-control"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        ) : item.amount >= 1 ? (
          item.amount
        ) : (
          item.amount.padEnd(5, 0)
        )}
      </td>
      <td align="right">{formatNumber((cost_average * item.amount) || 0)}</td>
      <td>
        <input
          type={"checkbox"}
          className="form-control input-sm"
          checked={item.type_product_id === 1 ? false : true}
          onChange={updateTypeProduct}
        />
      </td>
      <td>
        {show ? (
          <button
            type="button"
            className="btn btn-sm btn-success"
            onClick={() => onSaveRow(item)}
          >
            Guardar
          </button>
        ) : (
          <button
            type="button"
            className="btn btn-sm btn-info"
            onClick={() => onEdit(item)}
          >
            Editar
          </button>
        )}
        <button
          type="button"
          className="btn btn-sm btn-danger mx-1"
          onClick={() => onDelete(item)}
        >
          Eliminar
        </button>
      </td>
    </tr>
  );
};

export default ItemPrescription;
