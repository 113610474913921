import axios from 'axios'

import { getHeaders, validateSupplier } from "../utils";

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching_list: 0,
    fetching_create: 0,
    fetching_department: 0,
    data: [],
    data_departments: [],
    errors: {}
}

let LIST_SUPPLIER = "LIST_SUPPLIER";
let LIST_SUPPLIER_SUCCESS = "LIST_SUPPLIER_SUCCESS";
let CREATE_SUPPLIER = "CREATE_SUPPLIER";
let CREATE_SUPPLIER_SUCCESS = "CREATE_SUPPLIER_SUCCESS";
let CREATE_SUPPLIER_ERROR = "CREATE_SUPPLIER_ERROR";
let UPDATE_SUPPLIER_SUCCESS = "UPDATE_SUPPLIER_SUCCESS";
let SUPPLIER_ERROR = "SUPPLIER_ERROR";
let LIST_DEPARTMENT = "LIST_DEPARTMENT";
let LIST_DEPARTMENT_SUCCESS = "LIST_DEPARTMENT_SUCCESS";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_SUPPLIER:
            return { ...state, fetching_list: 1, }
        case LIST_SUPPLIER_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        case CREATE_SUPPLIER:
            return { ...state, fetching_create: 1 }
        case CREATE_SUPPLIER_SUCCESS:
            return { ...state, fetching_create: 2, errors: {} }
        case CREATE_SUPPLIER_ERROR:
            return { ...state, fetching_create: 3, errors: action.payload }
        case UPDATE_SUPPLIER_SUCCESS:
            return { ...state, fetching_create: 2, errors: {} }
        case SUPPLIER_ERROR:
            return { ...state, fetching_create: 3, errors: action.payload }
        case LIST_DEPARTMENT:
            return { ...state, fetching_department: 1 }
        case LIST_DEPARTMENT_SUCCESS:
            return { ...state, fetching_department: 2, data_departments: action.payload }
        default:
            return state;
    }
}

export let getSuppliers = (filter = '') => (dispatch, getState) => {
    dispatch({
        type: LIST_SUPPLIER,
    });



    let url = `${API_URL}/api/supplier${filter}`;

    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {

        dispatch({
            type: LIST_SUPPLIER_SUCCESS,
            payload: resp.results
        });

    })
}

export let getDepartments = () => (dispatch, getState) => {
    dispatch({
        type: LIST_DEPARTMENT,
    });



    let url = `${API_URL}/api/supplier-departments`;

    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {

        dispatch({
            type: LIST_DEPARTMENT_SUCCESS,
            payload: resp.results
        });

    })
}

export let createOrUpdateSupplier = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_SUPPLIER,
    });

    let validate = validateSupplier(form);

    if (Object.keys(validate).length > 0) {
        dispatch({
            type: SUPPLIER_ERROR,
            payload: validate
        })
        return;
    }

    let url = `${API_URL}/api/supplier`;

    let request;

    if (form.id !== undefined) {
        url = `${API_URL}/api/supplier/${form.id}`;

        request = axios.put(url, form,
            { headers: getHeaders() }
        );
    } else {
        request = axios.post(url, form,
            { headers: getHeaders() }
        );
    }

    request.then(resp => {
        dispatch({
            type: CREATE_SUPPLIER_SUCCESS,

        });
    }).catch(error => {
        let err = {}

        for (const [key, value] of Object.entries(error.response.data.errors)) {

            if ('document' === key) {
                err.document = value
            }
            if ('business_name' === key) {
                err.business_name = value
            }
            if ('business' === key) {
                err.business = value
            }
        }

        dispatch({
            type: CREATE_SUPPLIER_ERROR,
            payload: err
        });


    })
}