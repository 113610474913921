import React, { useEffect, useState } from "react";
import Select from "react-select";
import uuid from "react-uuid";
import { formatNumber, getHeaders } from "../../utils";
import { Form, InputGroup } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import moment from "moment";
import { getLastInventoryByCode } from '../../Redux/inventoryDuck'
import { connect } from "react-redux";

const API_URL = process.env.REACT_APP_API_URL;

const RowProduct = ({ listProduct, onSetSelectedProduct, onDelete, onAddItem, formEdit,
  onEditItem, registration_date, request_branch_id, getLastInventoryByCode, fetching_by_id, row }) => {
  const [form, setForm] = useState({ amount: 0, cost_average: 0, product: {}, subtotal: "" });
  const [selectedProduct, setSelectedProduct] = useState({ tax: { description: "" }, measure_unit: { description: "" } });
  const [showButton, setShowButton] = useState(false);
  const [productSearch, setProductSearch] = useState("");
  const [listSearch, setListSearch] = useState([]);

  useEffect(() => {
    if (Object.keys(formEdit).length > 0) {
      setForm(formEdit);
      setSelectedProduct(formEdit.product);
      setShowButton(true);
      setProductSearch({
        label: formEdit.product.name,
        value: formEdit.product.id,
      });
    }
  }, [formEdit]);

  useEffect(() => {
    if (fetching_by_id === 2) {
      setForm({
        ...form,
        ["cost_average"]: row.cost_average,
        ["amount_total"]: row.amount_total,
      });
    }
  }, [fetching_by_id])

  const loadOptions = async (inputValue) => {
    // let branch = JSON.parse(localStorage.getItem("branch"));
    let date_init = moment(registration_date).format("YYYY-MM-01")
    let url = `${API_URL}/api/product-inventory-search/${inputValue}?date_init=${date_init}&date_end=${registration_date}&branch=${request_branch_id}`;

    let _search = await fetch(url, {
      headers: getHeaders(),
    }).then((resp) => resp.json());

    setListSearch(_search);

    return _search;
  };

  const updateInput = (e) => {

    setForm({
      ...form,
      [e.target.name]: e.target.value,
      ["subtotal"]: form.cost_average * e.target.value,
    });
  };

  const onAddItemBefore = (item) => {
    item._id = uuid();
    onAddItem(item);
  };


  return (
    <tr>
      <td>
        <AsyncSelect
          value={productSearch}
          loadOptions={loadOptions}
          onChange={(e) => {
            setSelectedProduct(e);

            setForm({
              ...form,
              ["charge"]: e.charge,
              ["cost_average"]: e.cost_average,
              // ["amount_total"]: e.amount_total,
              ["product"]: e,
            });


            getLastInventoryByCode(e.code, registration_date)
            setShowButton(true);
            setProductSearch({ label: e.name, value: e.id });
          }}
          placeholder="Busca el producto"
        />
      </td>
      <td align="right">{form.amount_total || 0}</td>
      <td align="right">
        <InputGroup className="mb-3">
          <Form.Control
            type="number"
            onChange={updateInput}
            value={form.amount}
            name="amount"
            placeholder="Cantidad"
            aria-describedby="basic-addon2"
          />
          <InputGroup.Text id="basic-addon2">
            {selectedProduct.measure_unit.description}
          </InputGroup.Text>
        </InputGroup>
      </td>

      {showButton && (
        <td>
          {form._id !== undefined ? (
            <button className="btn btn-sm btn-info mx-2" onClick={() => onEditItem(form)} >
              Editar
            </button>
          ) : (
            <button className="btn btn-sm btn-success mx-2" onClick={() => onAddItemBefore(form)} >
              Guardar
            </button>
          )}

          <button className="btn btn-sm btn-danger" onClick={() => onDelete(form)} >
            Cancelar
          </button>
        </td>
      )}

    </tr>
  );
};

const mapsState = (props) => {
  let { inventory } = props

  return {
    fetching_by_id: inventory.fetching_by_id,
    row: inventory.row
  }
}

export default connect(mapsState, { getLastInventoryByCode })(RowProduct);
