import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { getSuppliers } from "../../Redux/supplierDuck";
import {
  onCreatePurchases,
  onCreateDirectPurchases,
} from "../../Redux/purchaseDuck";
import TemplateMenu from "../../template/menu.template";
import { useHistory } from "react-router-dom";
import FormPurchase from "./form.purchase";

const CreatePurchasePage = ({ getSuppliers, fetching_list, suppliers, onCreatePurchases, fetching_create,
  onCreateDirectPurchases }) => {

  const history = useHistory();
  const [listSupplier, setListSupplier] = useState([]);

  useEffect(() => {
    if (fetching_create === 2) {
      history.goBack();
    }
  }, [fetching_create]);

  useEffect(() => {
    if (fetching_list === 2) {
      setListSupplier(suppliers);
    }
  }, [fetching_list]);

  useEffect(() => {
    getSuppliers("?status=1");
  }, []);

  const onCreate = (form) => {
    onCreateDirectPurchases(form)
  }

  return (
    <TemplateMenu>
      <Container fluid>

        <Row className="d-flex justify-content-center">
          <Col lg={12}>
            <FormPurchase listSupplier={listSupplier} onCreate={onCreate} />
          </Col>
        </Row>

      </Container>
    </TemplateMenu>
  );
};

const mapsState = (props) => {
  let { supplier, purchase } = props;
  return {
    fetching_list: supplier.fetching_list,
    suppliers: supplier.data,
    errors: supplier.errors,
    fetching_create: purchase.fetching_create,
  };
};

export default connect(mapsState, {
  getSuppliers,
  onCreatePurchases,
  onCreateDirectPurchases,
})(CreatePurchasePage);
