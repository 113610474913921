import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { AiFillEdit, AiOutlineBgColors, AiOutlineDelete } from 'react-icons/ai';
import { formatNumber } from '../../utils';

const ItemTableExpense = ({ item, onClick, titleColor, selectedColor, onMarkExpense, onMarkTotal, role_id, onClickDelete }) => {
    let { origin, child, parameter_group } = item
    let color = item.color !== null ? { backgroundColor: item.color } : {}

    //let group = parameter_group.parent.description

    let group = parameter_group.parent === null ? item.concept : parameter_group.parent.description

    //let group = "asdsadsa"

    // console.log('parameter_group', parameter_group);




    const onSelectColor = (item) => {

        // if (color == selectedColor.css) {
        //     setColor('')
        // } else {
        //     setColor(selectedColor.css)
        // }

        if (item.row === undefined) {
            onMarkTotal(item)
        } else {
            onMarkExpense(item.row)
        }
    }


    return (
        <tr style={color}>
            <td>{item.registration_date}</td>
            <td>{group}</td>
            <td>{item.concept}</td>
            <td>{item.observation}</td>
            <td>{item.method}</td>
            <td align='right'>
                <>
                    <Row>
                        <Col>{formatNumber(item.expense)}</Col>
                    </Row>
                    {
                        child !== '' && <Row>
                            <Col className='text-muted font-size-11'>{child.branch_office.title}</Col>
                        </Row>
                    }
                </>
            </td>
            <td align='right'>
                <>
                    <Row>
                        <Col>{formatNumber(item.ingreso)}</Col>
                    </Row>
                    {
                        origin !== '' && <Row>
                            <Col className='text-muted font-size-11'>{origin.branch_office.title}</Col>
                        </Row>
                    }
                </>
            </td>
            <td>
                <>
                    {item.row !== undefined & origin === '' && item.move_id !== 3 && <>
                        <button className='btn btn-sm' type='button' onClick={() => onClick(item.row)}><AiFillEdit size={20} /></button>
                        {(role_id === 1 || role_id === 2 || role_id === 5) && <button className='btn btn-sm' onClick={() => onClickDelete(item.row)}><AiOutlineDelete size={20} /></button>}

                    </>}

                    {titleColor !== '' &&
                        <button className='btn btn-sm' type='button' onClick={() => onSelectColor(item)}><AiOutlineBgColors size={20} /></button>}
                </>
            </td>
        </tr>
    )
}

export default ItemTableExpense;