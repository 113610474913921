import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createBanner } from '../../Redux/bannerDuck'
import { toogleAlert } from '../../Redux/alertDuck'
import TemplateMenu from '../../template/menu.template'
import { ToastProvider, useToasts, removeAllToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';

const initState = { title: '', description: '', status: true, is_general: false };
const CreateBannerPage = ({ createBanner, banner, toogleAlert }) => {

  const [form, setForm] = useState(initState)
  const [photo, setPhoto] = useState(null)

  const { addToast } = useToasts();

  const history = useHistory()

  let { fetching_save } = banner

  useEffect(() => {
    if (fetching_save === 2) {
      toogleAlert(false)
      setForm(initState)
      setPhoto(null)
      addToast('Guardado con Exito', { appearance: 'success', autoDismiss: true });
    }
  }, [fetching_save])

  const updateField = (e) => {

    if (e.target.type === 'checkbox') {
      setForm({
        ...form,
        [e.target.name]: e.target.checked
      })
    } else {
      setForm({
        ...form,
        [e.target.name]: e.target.value
      })
    }


  }
  const updateFieldInput = (e) => {

    setPhoto(e.target.files[0])
  }

  const onSubmit = (e) => {
    e.preventDefault()
    toogleAlert(true)
    let _form = form
    _form.image = photo
    createBanner(form)
  }

  const goBack = () => {
    history.goBack()
  }


  return (
    <TemplateMenu>
      <Row className=''>
        <Col>
          <button className="btn btn-sm btn-warning" onClick={goBack}>Volver</button>
        </Col>
      </Row>
      <Form encType="" onSubmit={onSubmit}>
        <Row className="justify-content-center">
          <Col lg={5}>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Titulo</Form.Label>
                  <Form.Control type="text" value={form.title} name="title" placeholder="Agrega un título" onChange={updateField} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Description</Form.Label>
                  <Form.Control type="text" value={form.description} name="description" placeholder="Agrega un descripción"
                    onChange={updateField} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label="¿Es para el menu General?" name='is_general'
                    onChange={updateField} checked={form.is_general} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label="¿Esta activo?" name='status'
                    onChange={updateField} checked={form.status} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formFileMultiple" className="mb-3">
                  <Form.Label>Multiple files input example</Form.Label>
                  <Form.Control type="file" name="photo" onChange={updateFieldInput} />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Button variant="primary" type="submit">Guardar</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </TemplateMenu>
  )
}

const mapsState = (props) => {
  return {
    banner: props.banner
  }
}

export default connect(mapsState, { createBanner, toogleAlert })(CreateBannerPage);