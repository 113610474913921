import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getWorkerById, addNoveltyWorker, deleteNoveltyWorker, getNoveltyWorker } from '../../Redux/workerDuck'
import { getParameterBySegment } from '../../Redux/parameterDuck'
import { toogleAlert } from '../../Redux/alertDuck'
import ModalAddNovelty from './ModalAddNovelty';
import ModalLoanIngresos from './modal.loan';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import TemplateMenu from '../../template/menu.template'
import toast from 'react-hot-toast';

const NoveltiesPage = ({ getWorkerById, fetching_by_id, selected, getParameterBySegment, fetching_by_segment, segment, data_segment, addNoveltyWorker,
    fetching_novelty, deleteNoveltyWorker, fetching_novelty_delete, getNoveltyWorker, row_novelty, toogleAlert }) => {
    let { id } = useParams()

    const [worker, setWorker] = useState({ name: '', position: { description: '' }, branch_office: {} })
    const [showModal, setShowModal] = useState(false)
    const [showLoan, setShowLoan] = useState(false)
    const [listParameter, setListParameter] = useState([])
    const [listNovelty, setListNovelty] = useState([])
    const [listLoan, setListLoan] = useState([])
    const [listTime, setListTime] = useState([])
    const history = useHistory();

    useEffect(() => {
        getWorkerById(id)
    }, [id])
    useEffect(() => {
        if (fetching_novelty === 2) {
            setListNovelty([
                ...listNovelty,
                row_novelty
            ])
            toogleAlert(false)
            setShowModal(false)
            toast.success('Categoria Creada!')

        }
    }, [fetching_novelty])

    useEffect(() => {
        if (fetching_by_segment === 2) {
            if (segment === 'novelties') {
                setListParameter(data_segment)
                getParameterBySegment("loan")
            }

            if (segment === 'loan') {
                setListTime(data_segment)
            }

        }
    }, [fetching_by_segment])

    useEffect(() => {
        getParameterBySegment("novelties")
    }, [])


    useEffect(() => {
        if (fetching_by_id === 2) {
            setWorker(selected)
            setListNovelty(selected.novelties)
        }
    }, [fetching_by_id])

    const removeItem = (item) => {
        let _list = listNovelty.filter(f => f.id !== item.id)
        setListNovelty(_list)
        deleteNoveltyWorker(item)
    }

    const removeItemLoan = (item) => {

    }
    const onAddNovelty = (form) => {
        toogleAlert(true)
        form.id = id;
        addNoveltyWorker(form)
    }

    const onSubmit = () => {
        let _form = { id, listNovelty }
        addNoveltyWorker(_form)
    }


    const onAddLoan = (form) => {
        setListLoan([
            ...listLoan,
            form
        ])

        setShowLoan(false)
    }

    const printIntern = (row, key) => {
        let { parameter } = row
        return <tr key={key}>
            <td>{row.registration_date}</td>
            <td>{parameter.description}</td>
            <td>{row.reason}</td>
            <td><button className='btn btn-sm btn-warning' onClick={() => removeItem(row)}>X</button></td>
        </tr>
    }

    const printLoan = (row, key) => {
        let { parameter } = row

        return <tr key={key}>
            <td>{parameter.description}</td>
            <td>{row.amount}</td>
            <td><button className='btn btn-sm btn-warning' onClick={() => removeItemLoan(row)}>X</button></td>
        </tr>
    }

    return (
        <TemplateMenu>
            {/* <Row className="my-3">
                <Col lg={9}>
                    <button className="btn btn-info" onClick={() => onSubmit(1)}>Guardar</button>
                </Col>
                <Col lg={3} className="d-flex justify-content-end">
                    <button className="btn btn-success" onClick={() => onSubmit(2)}>Guardar y Finalizar</button>
                </Col>
            </Row> */}
            <Row className='mt-3'>
                <Col lg={5}>
                    <Row>
                        <Col><h3>Información trabajador</h3></Col>
                    </Row>
                    <Row>
                        <Col>
                            <table className='table table-bordered'>
                                <tbody>
                                    <tr>
                                        <td>Nombre</td>
                                        <td>{worker.name}</td>
                                    </tr>
                                    <tr>
                                        <td>Cargo</td>
                                        <td>{worker.position.description}</td>
                                    </tr>
                                    <tr>
                                        <td>Sucursal</td>
                                        <td>{worker.branch_office.title}</td>
                                    </tr>
                                </tbody>

                            </table>
                        </Col>
                    </Row>
                </Col>
                <Col lg={5}>
                    <Row>
                        <Col>Agregar Novedad</Col>
                        <Col><button className="btn btn-info" onClick={() => setShowModal(true)}>+</button></Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            <table className="table table-bordered table-striped table-sm">
                                <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Descripción</th>
                                        <th>Razón</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(listNovelty.length > 0)
                                        ? listNovelty.map(printIntern)
                                        : <tr><td colSpan={2}>No tiene Novedades</td></tr>}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col>Prestamos</Col>
                        <Col><button className="btn btn-info" onClick={() => setShowLoan(true)}>+</button></Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            <table className="table table-bordered table-striped table-sm">
                                <thead>
                                    <tr>
                                        <th>Modo</th>
                                        <th>Coutas</th>
                                        <th>Estado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(listLoan.length > 0) ? listLoan.map(printLoan) : <tr><td colSpan={2}>No tiene Prestamos Activos</td></tr>}
                                </tbody>
                            </table>

                        </Col>
                    </Row>
                </Col>
            </Row>

            <ModalAddNovelty title={"Novedades"}
                showModal={showModal}
                parameters={listParameter}
                onSubmit={onAddNovelty}
                onHide={() => setShowModal(false)} />

            <ModalLoanIngresos
                show={showLoan}
                onSuccess={onAddLoan}
                onHide={() => setShowLoan(false)}
                listTime={listTime}
            />

        </TemplateMenu>
    )
}


const mapsState = (props) => {
    let { worker, parameter } = props
    return {
        selected: worker.selected,
        fetching_by_id: worker.fetching_by_id,
        fetching_novelty: worker.fetching_novelty,
        fetching_novelty_delete: worker.fetching_novelty_delete,
        row_novelty: worker.row,
        data_segment: parameter.data_segment,
        segment: props.parameter.segment,
        fetching_by_segment: parameter.fetching_by_segment,
    }
}

export default connect(mapsState, {
    getWorkerById, getParameterBySegment, addNoveltyWorker, deleteNoveltyWorker,
    getNoveltyWorker, toogleAlert
})(NoveltiesPage);