import React, { useState } from 'react'
import { formatNumber } from '../../utils';
import { AiFillCloseSquare, AiFillEdit } from "react-icons/ai";
import Select from 'react-select'


const ItemDetailTransfer = ({ item, onDelete, listProduct, charge_id }) => {

    let { product } = item
    let cost = item.cost_average !== '' ? item.cost_average : product.cost
    let charge = item.amount * item.cost_average * (item.charge / 100);
    const [form, setForm] = useState({ amount: '', cost: '', subtotal: '' })
    const [show, setShow] = useState(false)

    const onEdit = (row) => {
        setShow(true);
        setForm({
            ...form,
            ["amount"]: row.amount,
            ["cost"]: row.cost,
            ["subtotal"]: row.subtotal,
        })
    }

    const updateInput = (e) => {
        console.log('e.target.name', e.target.name);

        if (e.target.name === 'amount') {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
                ["cost"]: form.subtotal / e.target.value
            })
        }
    }

    const onSaveRow = () => {
        if (parseFloat(form.amount) <= parseFloat(item.amount_total)) {
            if (parseFloat(form.amount) > 0) {
                setShow(false);
                item.amount = form.amount
                item.cost = form.cost
                item.subtotal = form.cost * form.amount
            } else {
                alert(`Solo puedes Solicitar Valores superiores a 1`)

            }

        } else {
            alert("No puedes superar el Stock Actual " + item.amount_total)
        }
    }


    return (
        <tr>
            <td>
                {item.product.name}
            </td>
            <td align='right'>{formatNumber(item.cost_average)}</td>
            <td align='right'>
                {!show ? item.amount : <input className='form-control' value={form.amount} name="amount" onChange={updateInput} />}
            </td>
            <td align='right'>
                {!show ? formatNumber(item.subtotal) : <input className='form-control' value={form.subtotal} name="subtotal" onChange={updateInput} />}
            </td>
            {charge_id !== 0 && <>
                <td align='right'>{item.charge}%</td>
                <td align='right'>{formatNumber(charge + item.subtotal)}</td>
            </>}

            <td>
                {!show ? <>
                    <button className='btn btn-info btn-sm' onClick={() => onEdit(item)}>Editar</button>
                    <button className='btn btn-danger btn-sm mx-3' onClick={() => onDelete(item)}>Borrar</button>
                </> :
                    <>
                        <button className='btn btn-success btn-info btn-sm' onClick={onSaveRow}>Guardar</button>
                        <button className='btn btn-danger btn-info btn-sm mx-2' onClick={() => setShow(false)}>Cancelar</button>
                    </>}

            </td>
        </tr>
    )
}

export default ItemDetailTransfer;