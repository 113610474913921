import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import uuid from 'react-uuid';
import { formatNumber, getHeaders } from '../../utils';
const API_URL = process.env.REACT_APP_API_URL

const initState = { amount_used: '', amount_generate: '' };
const initStateSelected = { measure_unit: '', cost_average: 0, inventory: { amount_total: 0 } }
const RowProductTransformation = ({ product, onCancel, onSucccess, onEditRow, cost }) => {
    const [form, setForm] = useState(initState)
    const [type, setType] = useState('')
    const [listSearch, setListSearch] = useState([])
    const [selected, setSelected] = useState(initStateSelected)
    const [status, setStatus] = useState(0)

    const loadOptions = async (inputValue) => {
        let branch = JSON.parse(localStorage.getItem("branch"))
        let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))
        let url = `${API_URL}/api/product-inventory-search/${inputValue}?date_init=${date_init}&date_end=${date_end}&branch=${branch.id}`

        let _search = await fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json())

        setListSearch(_search)

        return _search;
    }

    const updateField = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSaveRow = () => {
        let errors = {}
        if (form.amount_used == '') {
            errors.amount_used = "Pendiente"
        }
        if (form.amount_used == '') {
            errors.amount_used = "Pendiente"
        }

        if (Object.keys(errors).length > 0) {
            alert("Completa la información")
            return;
        }

        let _form = form
        _form._id = uuid();
        _form.cost_average = (cost * form.amount_used) / form.amount_generate;
        _form.product = selected
        onSucccess(_form)
    }


    return <tr>
        <td>
            <AsyncSelect
                value={type}
                placeholder="Busca el Producto agregar"
                loadOptions={loadOptions}
                // defaultOptions={defaultOptions}
                onChange={(e) => {
                    let _filter = listSearch.filter(s => parseInt(s.value) === parseInt(e.value))[0]
                    setSelected(_filter)

                    setType(e)
                }} />
        </td>
        <td align='right'>
            {formatNumber(selected.cost_average)}
        </td>
        <td>
            <input width="15%" type={"number"} className='form-control' name='amount_generate' placeholder='Cantidad a generar' value={form.amount_generate} onChange={updateField} />
        </td>
        <td>
            <input type={"number"} className='form-control' name='amount_used' placeholder='Cantidad Usada' value={form.amount_used} onChange={updateField} />
        </td>
        <td>
            {status === 0
                ? <button className='btn btn-sm btn-success mx-1' onClick={onSaveRow}>Guardar</button>
                : <button className='btn btn-sm btn-success mx-1' onClick={() => onEditRow(form)}>Editar</button>}

            <button className='btn btn-sm btn-danger' onClick={onCancel}>Cerrar</button>
        </td>
    </tr>
}

export default RowProductTransformation;