import React, { useEffect, useState } from 'react'
import { formatNumber } from '../../utils'

const ItemTableFranchiseValues = ({ item, onUpdateList }) => {
    const [show, setShow] = useState(false)
    const [value, setValue] = useState(0)

    useEffect(() => {

        if (item.value === undefined) {
            item.value = 0;

        }
    }, [item])

    const onSaveRow = () => {
        item.value = value;
        setShow(false)
        onUpdateList(item)
    }
    return (
        <tr>
            <td>
                {show === true ? <input className='form-control input-sm' type="number" onChange={(e) => setValue(e.target.value)} value={value} /> : formatNumber(item.value)}
            </td>
            <td>
                {show === true ? <button className='btn btn-success btn-sm' onClick={onSaveRow}>Guardar</button> : <a className='cursor-pointer' onClick={() => setShow(true)}>Agregar</a>}

            </td>
        </tr>
    )
}

export default ItemTableFranchiseValues;