import React, { useState } from 'react'
import { Col } from 'react-bootstrap';
import { formatNumber } from '../../utils';

const ItemSubPrescription = ({ item, onDelete, onNotify }) => {
    let { product_inventory } = item
    let { measure_unit, cost_average } = product_inventory
    const [show, setShow] = useState(false)
    const [amount, setAmount] = useState(0)


    const onEdit = (item) => {
        setShow(true)
        setAmount(item.amount)
    }

    const onSaveRow = () => {
        item.amount = amount
        setShow(false)
        onNotify()
    }


    return (
        <tr>
            <td>{product_inventory.name}</td>
            <td>{measure_unit.description}</td>
            <td>{formatNumber(cost_average)}</td>
            <td>
                {show ? <input className='form-control' value={amount} onChange={(e) => setAmount(e.target.value)} /> : (item.amount >= 1 ? item.amount : item.amount.padEnd(5, 0))}
            </td>
            <td>{formatNumber(cost_average * item.amount)}</td>
            <td>

                {show ? <button type='button' className='btn btn-sm btn-success' onClick={() => onSaveRow(item)}>Guardar</button> :
                    <button type='button' className='btn btn-sm btn-info' onClick={() => onEdit(item)}>Editar</button>
                }
                <button type='button' className='btn btn-sm btn-danger mx-1' onClick={() => onDelete(item)}>X</button>

            </td>
        </tr>
    )
}

export default ItemSubPrescription;