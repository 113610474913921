import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Row, Col, Card, Container } from 'react-bootstrap';
import { connect } from 'react-redux';

import { getMenu } from '../../Redux/menuDuck'
import { getReview, getReviewSales } from '../../Redux/reportDuck'
import { toogleLoader } from '../../Redux/alertDuck'

import { formatNumber } from '../../utils'

import { Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ComposedChart, ResponsiveContainer } from 'recharts';

import TemplateMenu from '../../template/menu.template'
import './index.css'
import moment from 'moment';
import { SocketContext } from '../../context/SocketContext';
import { Doughnut } from 'react-chartjs-2';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const links = [
    { title: "CIERRES", link: "/ingresos" },
    { title: "COMPRAS", link: "/compras" },
    { title: "BAJAS", link: "/bajas" },
    { title: "CONTEO FISICO", link: "/conteo" },
    { title: "DIFERENCIAS DE INVENTARIO", link: "/diferencias" },
    { title: "KARDEX", link: "/inventario" },
]

const Home = ({ getReview, fetching, data, toogleLoader, getReviewSales, fetching_sales, data_sales,
    fetching_branch, user }) => {

    const [branch, setBranch] = useState([])
    const [listTotal, setListTotal] = useState([])
    const [dataGraph, setDataGraph] = useState([])
    const [dataGraphFooter, setDataGraphFooter] = useState([])
    const [dataCircle, setDataCircle] = useState([])


    const { socket } = useContext(SocketContext)


    useEffect(() => {

        let b = JSON.parse(localStorage.getItem("branch"))

        if (b !== null) {
            if (user.role_id === 1) {
                getReviewSales()
            }
        }
    }, [])

    useEffect(() => {
        if (fetching_branch === 2) {
            let b = JSON.parse(localStorage.getItem("branch"))

            setBranch(b)


        }
    }, [fetching_branch])

    useEffect(() => {

        if (fetching === 2) {

            let { total, days } = data
            toogleLoader(false)
            setListTotal(data)

            let _label = data.map(row => row.branch)
            let _values = data.map(row => parseFloat(row.total))

            console.log('_label', _label);

            setDataCircle(
                {
                    labels: _label,
                    datasets: [
                        {
                            label: '$ Total Ventas',
                            data: _values,
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)',
                            ],
                            borderWidth: 1,
                        },
                    ],
                }
            )

            // let { total, days } = data
            // setCategory(data.category)
            // setProduct(data.products)
            // setBranch(data.branchs)
            // setListTotal(data.total)
            // setListDays(data.days)

            // let _label = total.map(row => row.branch)
            // let _data = total.map(row => row.total)

            // setTotal({
            //     labels: _label,
            //     datasets: [
            //         {
            //             label: '# of Votes',
            //             data: _data,
            //             backgroundColor: [
            //                 'rgba(255, 99, 132, 0.2)',
            //                 'rgba(54, 162, 235, 0.2)',
            //                 'rgba(255, 206, 86, 0.2)',
            //                 'rgba(75, 192, 192, 0.2)',
            //                 'rgba(153, 102, 255, 0.2)',
            //                 'rgba(255, 159, 64, 0.2)',
            //             ],
            //             borderColor: [
            //                 'rgba(255, 99, 132, 1)',
            //                 'rgba(54, 162, 235, 1)',
            //                 'rgba(255, 206, 86, 1)',
            //                 'rgba(75, 192, 192, 1)',
            //                 'rgba(153, 102, 255, 1)',
            //                 'rgba(255, 159, 64, 1)',
            //             ],
            //             borderWidth: 1,
            //         },
            //     ],
            // })

            // let _label2 = days.map(row => row.days)
            // let _data2 = days.map(row => row.total)

            // setDays(
            //     {
            //         labels: _label2,
            //         datasets: [
            //             {
            //                 label: '# of Votes',
            //                 data: _data2,
            //                 fill: false,
            //                 backgroundColor: 'rgb(255, 99, 132)',
            //                 borderColor: 'rgba(255, 99, 132, 0.2)',
            //             },
            //         ],
            //     }
            // )
        }

    }, [fetching])

    useEffect(() => {

        if (fetching_sales === 2) {
            toogleLoader(false)

            setDataGraph(data_sales.results)
            setDataGraphFooter(data_sales.branches)
        }

    }, [fetching_sales])

    useEffect(() => {
        if (socket) {
            socket.on("refresh-totals", data => {
                toogleLoader(true)
                getReview()
            })
        }
    }, [socket])



    const onRequestData = (row) => {

        if (socket) {
            console.log('socket si sirve');
            socket.emit("request-data", listTotal[0])
        }
    }


    return (
        <TemplateMenu>
            <Container fluid>
                <Row>
                    <Col>ACCESOS RAPIDOS</Col>
                </Row>
                <Row>
                    {links.map((d, k) => <Col key={k}>
                        <Link to={`${d.link}`}>
                            <Card>
                                <Card.Body>
                                    {d.title}
                                </Card.Body>

                            </Card>
                        </Link>
                    </Col>)}
                </Row>
                <Row className='my-1'>
                    <Col className='text-center'><h2>{data.range}</h2></Col>
                </Row>
                {user.role_id === 1 ?
                    <>
                        <Row>
                            <Col>
                                <ResponsiveContainer width="96%" height={500}>

                                    <ComposedChart
                                        height={500}
                                        data={dataGraph}
                                        margin={{
                                            top: 5,
                                            right: 10,
                                            left: 70,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" scale="band" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        {dataGraphFooter.map((d, k) => <Fragment key={k}>
                                            <Bar dataKey={d.branch} barSize={30} fill={d.color} />
                                            {branch.id !== 0 && <Line type="monotone" dataKey={d.branch} stroke={d.color} />}
                                            {/* <Line type="monotone" dataKey={d.branch} stroke={d.color} /> */}

                                        </Fragment>
                                        )}
                                    </ComposedChart>
                                </ResponsiveContainer>
                            </Col>
                        </Row>
                        <Row className='my-2'>
                            {/* <Col>
                                <button className='btn btn-sm btn-success' onClick={onRequestData}>Actualizar</button>
                            </Col> */}
                        </Row>
                        <Row className='mt-4 d-flex justify-content-center'>

                            <Col lg={3} xs={12} className="">
                                <Card className="card-home">
                                    <Card.Body>
                                        <Row>
                                            <Col className="text-center">Ventas por Sucursales</Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {Object.keys(dataCircle).length > 0 && <Doughnut data={dataCircle} />}
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                            </Col>
                            <Col lg={9}>
                                <table className='table table-sm table-bordered'>
                                    <thead className='thead-dark'>
                                        <tr>
                                            <th>SUCURSAL</th>
                                            <th className='text-center'>Ultima Actualización</th>
                                            <th className='text-center'>Cantidad Facturas</th>
                                            <th className='text-center'>Total</th>
                                            {/* <th>Estado</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listTotal.map(t =>
                                            <tr key={t.branch} >
                                                <td>{t.branch}</td>
                                                <td>{moment(t.updated_at).format("YYYY-MM-DD hh:mm A")}</td>
                                                <td align='right'>{t.orders}</td>
                                                <td align='right'>{formatNumber(t.total)}</td>

                                                {/* <td className={`${t.is_online ? 'table-success' : 'table-danger'}`}>
                                                    {t.state}
                                                </td> */}
                                            </tr>)}
                                        <tr>
                                            <td colSpan={2}><b>TOTAL</b></td>
                                            <td align='right'><b>{formatNumber(listTotal.reduce((a, b) => (a = a + parseFloat(b.orders)), 0))}</b></td>
                                            <td align='right'>{formatNumber(listTotal.reduce((a, b) => (a = a + parseFloat(b.total)), 0))}</td>
                                        </tr>
                                    </tbody>

                                </table>
                            </Col>

                        </Row>
                    </> : <Row>
                        <Col><h2>ONIX CONTROL</h2></Col>
                    </Row>
                }

                {/* <Row className="pl-4 w-100 py-4 mt-5">
                    <Col lg={3} xs={12} className="">
                        <Card className="card-home">
                            <Card.Body>
                                <Row>
                                    <Col className="text-center">Ventas por Sucursales</Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <PieChart width={500} height={250}>
                                            <Pie data={data01} cx="50%" cy="50%" outerRadius={80} label>
                                                {
                                                    data01.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} />
                                                    ))
                                                }
                                            </Pie>
                                        </PieChart>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>

                    </Col>
                    <Col lg={3} xs={12} className="">
                        <Card className="card-home">
                            <Card.Body>
                                <Row>
                                    <Col className="text-center">Ventas por Sucursales</Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {total !== null && <Bar data={total} />}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>

                    </Col>
                    <Col lg={3} >
                        <Card className="card-home">
                            <Card.Body>
                                <Row>
                                    <Col className="text-center"> Ventas por días</Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {days !== null && <Line data={days} options={options_linear} />}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>

                    </Col>
                    <Col lg={3} className="">
                        <Card className="card-home">
                            <Card.Body>
                                <Row>
                                    <Col className="text-center">Ventas Mesuales</Col>
                                </Row>
                                <Row className="d-flex justify-content-center h-100">
                                    <Col>
                                        <Bar data={data2} options={options} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row> */}

            </Container>
        </TemplateMenu >
    )
}

const mapsState = (props) => {
    let { report, branchoffice, user } = props



    return {
        data: report.data,
        fetching: report.fetching,
        fetching_sales: report.fetching_sales,
        data_sales: report.data_sales,
        fetching_branch: branchoffice.fetching,
        user: user.user,
    }
}

export default connect(mapsState, { getMenu, getReview, toogleLoader, getReviewSales })(Home);