import { getHeaders } from '../utils';

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    fetching_by_id: 0,
    fetching_send: 0,
    fetching_customer: 0,
    data: [],
    data_customer: [],
    errors: {},
    row: {}
}

let LIST_WHATSAPP = "LIST_WHATSAPP";
let LIST_WHATSAPP_SUCCESS = "LIST_WHATSAPP_SUCCESS";
let LIST_CUSTOMER = "LIST_CUSTOMER";
let LIST_CUSTOMER_SUCCESS = "LIST_CUSTOMER_SUCCESS";
let SEND_WHATSAPP = "SEND_WHATSAPP";
let SEND_WHATSAPP_SUCCESS = "SEND_WHATSAPP_SUCCESS";
let WHATSAPP_BOT_BY_ID = "WHATSAPP_BOT_BY_ID";
let WHATSAPP_BOT_BY_ID_SUCCESS = "WHATSAPP_BOT_BY_ID_SUCCESS";

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_WHATSAPP:
            return { ...state, fetching_list: 1, }
        case LIST_WHATSAPP_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        case SEND_WHATSAPP:
            return { ...state, fetching_send: 1 }
        case SEND_WHATSAPP_SUCCESS:
            return { ...state, fetching_send: 2 }
        case LIST_CUSTOMER:
            return { ...state, fetching_customer: 1, }
        case LIST_CUSTOMER_SUCCESS:
            return { ...state, fetching_customer: 2, data_customer: action.payload }
        case WHATSAPP_BOT_BY_ID:
            return { ...state, fetching_by_id: 1 }
        case WHATSAPP_BOT_BY_ID_SUCCESS:
            return { ...state, fetching_by_id: 2, row: action.payload }
        default:
            return state;
    }
}

export let getCustomers = () => (dispatch, getState) => {
    dispatch({
        type: LIST_CUSTOMER,
    });

    let branch = JSON.parse(localStorage.getItem("branch"))
    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))

    let url = `${API_URL}/api/whatsapp-customer`;

    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {

        dispatch({
            type: LIST_CUSTOMER_SUCCESS,
            payload: resp.results
        });

    })
}
export let getMessages = () => (dispatch, getState) => {
    dispatch({
        type: LIST_WHATSAPP,
    });

    let branch = JSON.parse(localStorage.getItem("branch"))
    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))

    let url = `${API_URL}/api/whatsapp`;

    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {

        dispatch({
            type: LIST_WHATSAPP_SUCCESS,
            payload: resp.results
        });

    })
}
export let getMessagesBot = () => (dispatch, getState) => {
    dispatch({
        type: LIST_WHATSAPP,
    });

    let branch = JSON.parse(localStorage.getItem("branch"))
    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))

    let url = `${API_URL}/api/whatsapp-bot`;

    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {

        dispatch({
            type: LIST_WHATSAPP_SUCCESS,
            payload: resp.results
        });

    })
}

export let getMessageBotById = (id) => (dispatch, getState) => {
    dispatch({
        type: WHATSAPP_BOT_BY_ID,
    });

    let branch = JSON.parse(localStorage.getItem("branch"))

    let url = `${API_URL}/api/whatsapp-bot/${id}`;

    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {

        dispatch({
            type: WHATSAPP_BOT_BY_ID_SUCCESS,
            payload: resp
        });

    })
}
export let editMessageBotById = (form) => (dispatch, getState) => {
    dispatch({
        type: WHATSAPP_BOT_BY_ID,
    });

    let url = `${API_URL}/api/whatsapp-bot/${form.id}`;

    fetch(url, {
        method: "PUT",
        body: JSON.stringify(form),
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {

        dispatch({
            type: WHATSAPP_BOT_BY_ID_SUCCESS,
            payload: resp
        });

    })
}

export let onSendMessage = (form) => (dispatch, getState) => {
    dispatch({
        type: SEND_WHATSAPP,
    });

    // let branch = JSON.parse(localStorage.getItem("branch"))
    // let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))

    let url = `${API_URL}/api/whatsapp`;

    fetch(url, {
        method: 'POST',
        body: JSON.stringify(form),
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {

        dispatch({
            type: SEND_WHATSAPP_SUCCESS,
            payload: resp.results
        });

    })
}