import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap';
import TemplateMenu from '../../template/menu.template'
import { formatNumber, getHeaders } from '../../utils';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { connect } from 'react-redux';
import { onCreateInventoryInitial } from '../../Redux/inventoryDuck'
import { getProductsInventoryInitital, updateInventoryInitital } from '../../Redux/ProductInventoryDuck'
import { toogleLoader } from '../../Redux/alertDuck'
import RowTableInitial from './row.table.initial';

const InitialInventoryPage = ({ getProductsInventoryInitital, fetching_list, data, updateInventoryInitital, toogleLoader }) => {
    const [list, setList] = useState([])
    const [quantity, setQuantity] = useState(0)
    const [total, setTotal] = useState(0)
    const history = useHistory();

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
            toogleLoader(false)
        }
    }, [fetching_list])

    useEffect(() => {
        getProductsInventoryInitital()
        toogleLoader(true)
    }, [])

    const onUpdateRow = (row) => {
        updateInventoryInitital(row)
    }


    return (
        <TemplateMenu>
            <Row className='my-2'>
                <Col>
                    <button className='btn btn-sm btn-secondary' onClick={() => history.goBack()}>Volver</button>
                </Col>
            </Row>
            <Row >
                <Col className='text-center'>
                    <h3>INVENTARIO INICIAL</h3>
                </Col>
            </Row>
            <Row className='d-flex justify-content-center mt-2'>

                <Col lg={9}>
                    {(list.length > 0) ? list.map((d, k) => {
                        let { products } = d
                        let quantity = products.reduce((a, b) => a + parseFloat(b.amount), 0)
                        let total = products.reduce((a, b) => a + parseFloat(b.cost), 0)
                        return <Card key={k} className='mt-2'>
                            <Card.Body>
                                <Card.Title>{d.description.toUpperCase()}</Card.Title>
                                <Table className='table-sm table-bordered table-hover' striped>
                                    <thead>
                                        <tr className='thead-dark'>
                                            <th>Producto</th>
                                            <th>Medida</th>
                                            <th width="15%" className='text-center'>Cantidad</th>
                                            <th width="18%" className='text-center'>Costo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {products.map((row, k) => <RowTableInitial key={k} item={row} onUpdateRow={onUpdateRow} />)}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan={2}>TOTAL</td>
                                            <td align='right'><span className='mr-4'>{quantity || 0}</span></td>
                                            <td align='right'><span className='mr-4'>{formatNumber(total || 0)}</span></td>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </Card.Body>
                        </Card>
                    }
                    ) : <h4 className='text-center'>No se encontraron Productos</h4>}

                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { inventory, product_inventory } = props
    return {
        fetching_create: inventory.fetching_create,
        fetching_list: product_inventory.fetching_initial,
        data: product_inventory.data_initial
    }
}



export default connect(mapsState, { getProductsInventoryInitital, updateInventoryInitital, toogleLoader })(InitialInventoryPage);