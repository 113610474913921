import React, { useState, useEffect, Fragment } from 'react'
import { Modal, Container, Row, Col, Button, Table, Card } from 'react-bootstrap';
import { MdClose } from "react-icons/md";

import InputField from '../Components/Widgets/InputField';
import { connect } from 'react-redux';
import { createDining, updateDining, getHours, deleteSchedule } from '../../Redux/diningDuck'
import { getBranch } from '../../Redux/branchofficeDuck'
import HeaderWeek from './headerWeek';
import { toogleAlert } from '../../Redux/alertDuck';

const ModalDining = ({ showModal, onHide, errors, current, fetching, createDining, onSuccess, updateDining, fetching_delete, getBranch, branch, getHours, hours,
    deleteSchedule, row, toogleAlert, id }) => {
    const initState = { position: '', people: '', status_id: false, branch_id: 0, pet: false, name: '' };
    const initHour = { hour_start: '', hour_end: '' }
    const initStateDay = [
        { id: 1, text: "Lunes" },
        { id: 2, text: "Martes" },
        { id: 3, text: "Miercoles" },
        { id: 4, text: "Jueves" },
        { id: 5, text: "Viernes" },
        { id: 6, text: "Sabado" },
        { id: 7, text: "Domingo" },
    ];

    const [form, setForm] = useState(initState)
    const [hour, setHour] = useState(initHour)
    const [day, setDay] = useState({ text: '' })

    const [showRow, setShowRow] = useState(false)
    const [week, setWeek] = useState(initStateDay)
    const [schedule, setSchedule] = useState([])
    const [listDay, setListDay] = useState([])

    useEffect(() => {

        if (Object.keys(current).length > 0) {

            if (Object.keys(current.row).length > 0) {
                console.log('current', current);

                setForm(current.row);
            } else {
                setForm(initState)

            }

            setSchedule(current.schedule)
        }
        else {
            setForm(initState)
            setSchedule([])
        }
    }, [current])


    useEffect(() => {

        if (fetching === 2) {
            onHide()
            onSuccess(form)
        }
        if (fetching_delete === 2) {
            toogleAlert(false)

            searchItemSave(row)
        }

    }, [fetching, fetching_delete])

    const closeModalTmp = () => {
        onHide()
    }

    const updateInput = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const updateCheck = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.checked
        })
    }

    const updateHour = (e) => {
        setHour({
            ...hour,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = () => {
        let _form = form
        _form.schedule = schedule
        _form.dining_group_id = id
        _form.position = current.position


        if (form.id !== undefined) {
            updateDining(_form)
        } else {
            createDining(_form)
        }
    }


    const addTmpHour = () => {
        let temp = hour

        if (temp.hour_start == '' || temp.hour_end == '') {
            alert("Se debe agregar hora de inicio y fin");
            return
        }

        let _list = listDay

        let _schedule = [...schedule];

        _list.map((row, i) => {
            _schedule.push({ hour_start: temp.hour_start, hour_end: temp.hour_end, day: row.day })
        })

        setSchedule(_schedule)

    }

    const printSelect = (item, i) => {
        return (
            <option key={i} value={item.id}>{item.title}</option>
        )
    }

    const deleteScheduleAction = (item) => {

        toogleAlert(true)

        if (item.id !== undefined) {

            deleteSchedule(item)
        } else {

            deleteScheduleTemp(item)
        }
    }

    const searchItemSave = (item) => {
        let _schedule = [...schedule]

        _schedule.map((row, i) => {
            if (row.id === item.id) {
                delete _schedule[i];
            }
        })

        _schedule = _schedule.filter(row => !!row);
        setSchedule(_schedule)
    }


    const deleteScheduleTemp = (item) => {
        let _schedule = [...schedule]

        _schedule.map((row, i) => {
            if (row === item) {
                delete _schedule[i];
            }
        })

        _schedule = _schedule.filter(row => !!row);
        setSchedule(_schedule)
    }

    const selectedDay = (day, type) => {
        setShowRow(true)

        let d = { text: week[day - 1].text }
        d.day = day
        let _list = listDay;

        let flag = false
        _list.forEach((row, i) => {
            if (row.day === d.day) {
                _list[i] = d;

                if (type == false) {
                    delete _list[i]
                }
                flag = true;
            }
        })


        _list = _list.filter(row => !!row)

        if (!flag) {
            _list.push(d)
        }

        setListDay(_list)
    }


    const printDay = (item, i) => {
        return (
            <Row key={i}>{item.text}</Row>

        )
    }

    return (
        <Fragment>
            <Modal show={showModal} onHide={closeModalTmp}>
                <div className="modal-header border-bottom-custom">
                    <h4 className="modal-title text-center">Asignar Mesa</h4>
                    <button type="button" className="close p-0 m-0" data-dismiss="modal" aria-label="Close" onClick={closeModalTmp}>
                        <MdClose />
                    </button>
                </div>
                <Modal.Body>
                    <Container fluid>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label>Sillas</label>
                                    <InputField
                                        type={"number"}
                                        placeholder="Agrega la cantidad de Personas"
                                        value={form.people || ''}
                                        name="people"
                                        onChange={updateInput}
                                        error={errors.people}
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label>Nombre</label>
                                    <InputField
                                        placeholder="Agrega el nombre de la mesa"
                                        value={form.name || ''}
                                        name="name"
                                        onChange={updateInput}
                                        error={errors.people}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="form-group">
                                    <label>¿Esta Activo?</label>
                                    <input
                                        type="checkbox"
                                        className="form-control"
                                        checked={form.status_id || false}
                                        name="status_id"
                                        onChange={updateCheck}
                                    />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="form-group">
                                    <label>¿Mostrar en E-commerce?</label>
                                    <input
                                        type="checkbox"
                                        className="form-control"
                                        checked={form.is_extern || false}
                                        name="is_extern"
                                        onChange={updateCheck}
                                    />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="form-group">
                                    <label>¿Acepta Mascotas?</label>
                                    <input
                                        type="checkbox"
                                        className="form-control"
                                        checked={form.pet || false}
                                        name="pet"
                                        onChange={updateCheck}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Row className={`${showRow ? '' : 'd-none'}`}>
                                    <Col>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Dia</th>
                                                    <th>Hora Inicio</th>
                                                    <th>Hora Fin</th>
                                                    <th>Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{listDay.map(printDay)}</td>
                                                    <td>
                                                        <input className="form-control" name="hour_start" type="time" value={hour.hour_start}
                                                            onChange={updateHour} />
                                                    </td>
                                                    <td>
                                                        <input className="form-control" name="hour_end" type="time" value={hour.hour_end}
                                                            onChange={updateHour} />
                                                    </td>
                                                    <td>
                                                        <button className="btn btn-info btn-sm" onClick={addTmpHour}>Agregar</button>
                                                        <button className="btn btn-secondary btn-sm ml-2" onClick={() => setShowRow(false)}>Cerrar</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col className="px-1">
                                        <HeaderWeek
                                            title={"Lunes"}
                                            list={schedule.filter(row => row.day === 1)}
                                            day={1}
                                            deleteScheduleAction={deleteScheduleAction}
                                            selectedDay={selectedDay} />
                                    </Col>
                                    <Col className="px-1">
                                        <HeaderWeek
                                            title={"Martes"}
                                            list={schedule.filter(row => row.day === 2)}
                                            day={2}
                                            deleteScheduleAction={deleteScheduleAction}
                                            selectedDay={selectedDay} />
                                    </Col>
                                    <Col className="px-1">
                                        <HeaderWeek title={"Miercoles"} list={schedule.filter(row => row.day === 3)} day={3}
                                            deleteScheduleAction={deleteScheduleAction}
                                            selectedDay={selectedDay} />
                                    </Col>
                                    <Col className="px-1">
                                        <HeaderWeek title={"Jueves"} list={schedule.filter(row => row.day === 4)}
                                            deleteScheduleAction={deleteScheduleAction}
                                            day={4}
                                            selectedDay={selectedDay} />
                                    </Col>
                                    <Col className="px-1">
                                        <HeaderWeek title={"Viernes"} list={schedule.filter(row => row.day === 5)}
                                            deleteScheduleAction={deleteScheduleAction}
                                            day={5}
                                            selectedDay={selectedDay} />
                                    </Col>
                                    <Col>
                                        <HeaderWeek title={"Sabado"} list={schedule.filter(row => row.day === 6)}
                                            deleteScheduleAction={deleteScheduleAction}
                                            day={6}
                                            selectedDay={selectedDay} />
                                    </Col>
                                    <Col className="px-1">
                                        <HeaderWeek title={"Domingo"} list={schedule.filter(row => row.day === 0)}
                                            deleteScheduleAction={deleteScheduleAction}
                                            day={7}
                                            selectedDay={selectedDay} />
                                    </Col>
                                </Row> */}
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={closeModalTmp}>Cerrar</Button>
                    <Button variant="primary" onClick={onSubmit}>Guardar</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

const mapsState = (props) => {

    return {
        row: props.dining.row,
        errors: props.dining.errors,
        selected: props.dining.selected,
        hours: props.dining.hours,
        fetching: props.dining.fetching,
        fetching_delete: props.dining.fetching_delete,
        branch: props.branchoffice.data
    }
}

export default connect(mapsState, { createDining, updateDining, getBranch, getHours, deleteSchedule, toogleAlert })(ModalDining);
