import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Tab, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import TemplateMenu from "../../template/menu.template";
import SearchSection from "../Components/SearchSection";
import { getPrescription } from "../../Redux/prescriptionDuck"
import ItemPrescriptionTable from "./item.prescription.table";
import { toogleLoader } from "../../Redux/alertDuck";
import CounterResults from "../Components/ConterResults";
import AsyncSelect from "react-select/async";
import { debounceData } from "../../utils";

const API_URL = process.env.REACT_APP_API_URL;


const PrescriptionPage = ({ getPrescription, fetching_list, data, toogleLoader }) => {
  const [list, setList] = useState([]);
  const [listFilter, setListFilter] = useState([]);
  const [listProducts, setListProducts] = useState([]);
  const [isDetail, setIsDetail] = useState(false)
  const [product, setProduct] = useState('')
  const debounceRef = useRef();


  useEffect(() => {
    getPrescription(isDetail, 0);
    toogleLoader(true)
  }, []);


  useEffect(() => {
    if (fetching_list === 2) {
      toogleLoader(false)
      setList(data);
      setListFilter(data)
    }
  }, [fetching_list]);

  const filterContent = (e) => {
    let _list = list;
    let value = e.target.value.toLowerCase();

    _list = list.filter((row) => {
      if (row.title.toLowerCase().includes(value)) {
        return true;
      }
      return false;
    })
    setListFilter(_list);
  }


  const onFilterProduct = (e) => {
    toogleLoader(true)

    let isDetail = false

    if (e === null) {
      setProduct('')
      setIsDetail(false)
      getPrescription(false, 0);
    } else {
      setProduct(e)
      setIsDetail(true)
      getPrescription(true, e.id);
    }
  }

  const onDetail = (e) => {
    toogleLoader(true)

    let isDetail = e.target.checked === false ? false : true

    setIsDetail(isDetail)

    if (product === '') {
      getPrescription(isDetail, 0);
    } else {
      getPrescription(!isDetail, product.id);
    }
  }

  const loadOptions = async (inputValue) => {

    if (debounceRef.current)
      clearTimeout(debounceRef.current)

    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));
    let branch = JSON.parse(localStorage.getItem("branch"));
    let url = "";
    url = `${API_URL}/api/product-inventory-search/${inputValue}?date_init=${date_init}&date_end=${date_end}&branch=${branch.id}`


    return await debounceData(debounceRef, url);
  };

  return (
    <TemplateMenu>
      <Row className="mt-3">
        <Col lg={2}>
          <Link to="/recetas/new" className="btn btn-info">
            Crear Receta
          </Link>
        </Col>
        <Col lg={3}>
          <AsyncSelect
            value={product}
            isClearable
            placeholder={`Busca el Producto`}
            loadOptions={loadOptions}
            // defaultOptions={defaultOptions}
            onChange={(e) => {
              setProduct(e)
              onFilterProduct(e)
            }}
          />
        </Col>
        <Col lg={4} className="d-flex align-items-center justify-content-center">
          <input type={"checkbox"} className="form-control" onChange={onDetail} checked={isDetail} />Detalle
        </Col>
        <Col lg={3} className="pr-4 justify-content-end d-flex align-items-center">
          <input className="form-control" placeholder="Buscar Producto" name="buscar_usuario" onChange={filterContent} />
        </Col>
      </Row>
      <Row>
        <Col className="py-0 my-0">
          <CounterResults filter={listFilter.length} total={list.length} />
        </Col>
      </Row>
      <Row className="my-2">
        <Col>
          <Table className="table table-sm table-bordered table-condensed table-hover" striped>
            <thead className="thead-dark">
              <tr>
                <th></th>
                {(product === '' && !isDetail) && <th>Categoria</th>}
                <th colSpan={product === '' ? 1 : 4}>Nombre</th>
                <th>Unid</th>
                <th>Precio Venta</th>
                {product === '' && <>
                  <th className="text-center">Costo Mesa (<span className="text-info">Margen %</span>)</th>
                  <th className="text-center">Costo LLevar (<span className="text-info">Margen %</span>)</th>
                </>}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {listFilter.length > 0 ? (
                listFilter.map((p, k) => <ItemPrescriptionTable key={k} index={k} item={p} isDetail={isDetail}
                  show_columns={product === '' ? true : false} />)
              ) : (
                <tr>
                  <td colSpan={5}>No se encontraron Registros</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </TemplateMenu>
  );
};

const mapsState = (props) => {
  let { prescription } = props;
  return {
    fetching_list: prescription.fetching_list,
    data: prescription.data
  };
};

export default connect(mapsState, { getPrescription, toogleLoader })(PrescriptionPage);
