import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../Images/logo_madera.png";

const ItemPrescription = ({ item, onDeleteRecipeProduct }) => {
  let image = item.image === "" || item.image === null ? logo : item.image;

  return (
    <Col lg={3} className="mt-2">
      <Card>
        <Card.Body>
          <Row>
            <Col className="text-center"><h3>{item.title}</h3></Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center">
              <img src={image} className="img-fluid" />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <Link to={`recetario/${item.id}`} className="btn btn-sm btn-info">
                Editar
              </Link>
            </Col>
            <Col>
              <button className="btn btn-sm btn-danger" onClick={() => onDeleteRecipeProduct(item)}>Borrar</button>
            </Col>
            <Col className="d-flex justify-content-end">
              <Link
                to={`recetario-show/${item.id}`}
                className="btn btn-sm btn-warning">Ver</Link>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default ItemPrescription;
