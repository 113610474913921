import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { AiFillFilter } from 'react-icons/ai';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getTips, onDeleteTip } from '../../Redux/tipDuck';
import ItemMainTable from './item.main.table';
import TemplateMenu from '../../template/menu.template'

const TipsPage = ({ getTips, fetching_list, data, onDeleteTip, fetching_delete }) => {
    const [showModal, setShowModal] = useState(false);
    const [modalFilter, setModalFilter] = useState(false);
    const [list, setList] = useState([]);

    useEffect(() => {
        getTips()
    }, [])

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
        }
    }, [fetching_list])

    useEffect(() => {
        if (fetching_delete === 2) {
            getTips();
        }
    }, [fetching_delete])

    const onDeleteItem = (item) => {
        if (window.confirm(`¿Deseas eliminar la liquidacion ${item.id}?`)) {
            onDeleteTip(item.id)
        }
    }

    return (<TemplateMenu>
        <Container fluid>
            <Row className="my-4">
                <Col lg={3} md={4}>

                    <h4 className="font-title pt-3">LIQUIDACION PROPINAS</h4>
                </Col>
                <Col lg={2} md={4} className="d-flex align-items-center">
                    {list.length === 0 && <Link className="btn btn-info" to="/propinas/new" >Crear</Link>}

                </Col>

                <Col lg={7} md={4} className="d-flex justify-content-end align-items-center">
                    {/* <Row>
                        <Col><input className='form-control input-sm' name='date_init' type={"date"} onChange={updateInput} value={filter.date_init} /></Col>
                        <Col><input className='form-control input-sm' name='date_end' type={"date"} onChange={updateInput} value={filter.date_end} /></Col>
                        <Col><button className='btn btn-warning' onClick={onFilter}>Filtrar</button></Col>
                    </Row> */}
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <table className="table table-sm table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                <th>Id</th>
                                <th>Fecha</th>
                                <th>Punto</th>
                                <th>Fecha Corte</th>
                                <th>Monto Propina</th>
                                <th>Estado</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(list.length > 0) ? data.map(row =>
                                <ItemMainTable item={row} key={row.id} onDeleteItem={onDeleteItem} />) : <tr><td colSpan={14} className="text-center">No Tienes Registros</td></tr>}
                        </tbody>
                        <tfoot>
                            <tr className="table-secondary">

                            </tr>
                        </tfoot>
                    </table>
                </Col>
            </Row >
        </Container>
    </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { tip } = props
    return {
        data: tip.data,
        fetching_list: tip.fetching_list,
        fetching_delete: tip.fetching_delete,
    }
}

export default connect(mapsState, { getTips, onDeleteTip })(TipsPage);