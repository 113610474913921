import React, { useRef } from 'react'
import { Col, Modal, Row, Table } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { formatNumber } from '../../utils';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { RiFileExcel2Fill } from "react-icons/ri";

const ModalNoPrescription = ({ show, listProducts, onHide }) => {
    const tableRef = useRef()

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Listado de Productos Sin Receta
                    <DownloadTableExcel
                        filename="product_sin_receta"
                        sheet="product_sin_receta"
                        currentTableRef={tableRef.current}
                    >
                        <RiFileExcel2Fill size={25} color="green" className="cursor-pointer" />
                    </DownloadTableExcel></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Table className='table table-sm table-bordered' ref={tableRef}>
                            <thead className='thead-dark'>
                                <tr>
                                    <th>Producto</th>
                                    <th>Precio</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listProducts.map((d, k) => <tr key={k}>
                                    <td>
                                        <Link to={`/recetas/new?product=${d.id}&type=${d.type_product}`}>{d.title}</Link>
                                    </td>
                                    <td align='right'>{formatNumber(d.price)}</td>
                                </tr>)}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalNoPrescription;