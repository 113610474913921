import axios from 'axios'
import { getHeaders } from '../utils';
const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching_list: 0,
    fetching_create: 0,
    fetching_update_client: 0,
    data: [],
    selected: {},
    errors: {},
    fetching_delete: 0
}

const LIST_CLIENT = "LIST_CLIENT"
const LIST_CLIENT_SUCCESS = "LIST_CLIENT_SUCCESS"
const UPDATE_CLIENT = "UPDATE_CLIENT"
const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS"

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_CLIENT:
            return {...state,fetching_list:1}
        case LIST_CLIENT_SUCCESS:
            return {...state,fetching_list:2,data:action.payload}
        case UPDATE_CLIENT:
            return {...state,fetching_update_client:1}
        case UPDATE_CLIENT_SUCCESS:
            return {...state,fetching_update_client:2}
        default:
            return state;
    }
}

export let getAllClients = (page) => (dispatch, getState) => {
    dispatch({
        type: LIST_CLIENT
    })

    page = page || getState().user.current_page
    console.log('page', page)

    let token = localStorage.getItem("access_token")


    axios.get(`${API_URL}/api/clients?page=${page}&limit=30`, {
        headers: {
            Authorization: 'Bearer ' + token //the token is a variable which holds the token
        }
    })
        .then(response => {

            const { list } = getState().user

            let _data = list || []

            _data = _data.concat(response.data.results)

            dispatch({
                type: LIST_CLIENT_SUCCESS,
                payload: _data,
                current_page: response.data.current_page,
                last_page: response.data.last_page,

            })
        })
        .catch(error => {
           

        });
}

export let updateClient = (form) => (dispatch, getState) => {
    dispatch({
        type: UPDATE_CLIENT
    })


    axios.put(`${API_URL}/api/clients/${form.id}`,form, {
        headers: getHeaders()
    })
        .then(response => {

            const { list } = getState().user

            let _data = list || []

            _data = _data.concat(response.data.results)

            dispatch({
                type: UPDATE_CLIENT_SUCCESS
            })
        })
        .catch(error => {
           

        });
}


