import React from 'react';

const ItemOrderTable = ({ item ,showDetail}) => {
  let {client} = item || {}
  return (
    <tr className={`p-0 mx-0 active_list_reservation m-0 w-100'`} >
      <td>{item.id}</td>
      <td>{item.aldelo_id}</td>
      <td>{item.order_day}</td>
      <td>{(client!==undefined)?client.name:''}</td>
      <td>{item.address}</td>
      <td>{item.branch_office}</td>
      <td>{item.status}</td>
      <td>{item.updated_at}</td>
      <td>
        <button className="btn btn-sm btn-info" onClick={()=>showDetail(item)}>Ver</button>
      </td>
    </tr>
  )
}
export default ItemOrderTable;