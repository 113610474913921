import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getParameterBySegment, onSaveConfiguration, onGetConfiguration, onSaveFranchise } from '../../Redux/parameterDuck'
import SalaryConfig from './salary.config';
import TipsConfig from './tips.config';
import { useToasts } from 'react-toast-notifications';
import TemplateMenu from '../../template/menu.template'
import FranchiseConfigPage from './franchise.config';


const ConfigurationPage = ({ getParameterBySegment, onSaveConfiguration, onGetConfiguration, fetching_by_segment, fetching_config, data_segment,
    segment, fetching_list_config, data_config, onSaveFranchise }) => {

    const [listSalary, setListSalary] = useState([])
    const [listTips, setListTips] = useState([])
    const [listMethodsPayment, setListMethodPayments] = useState([])
    const [listFranchises, setListFranchises] = useState([])

    const { addToast } = useToasts()


    useEffect(() => {
        getParameterBySegment("nomina")
    }, [])

    useEffect(() => {
        if (fetching_config === 2) {
            addToast('Guardado con Exito', { appearance: 'success', autoDismiss: true });

        }
    }, [fetching_config])

    useEffect(() => {
        if (fetching_by_segment === 2) {

            console.log('data_segment', segment);


            if (segment === "nomina") {
                setListSalary(data_segment)
                getParameterBySegment("worker_area")
            }

            if (segment === "worker_area") {
                setListTips(data_segment)
                onGetConfiguration()
                getParameterBySegment("methods_payment")
            }

            if (segment === "methods_payment") {
                setListMethodPayments(data_segment)
                getParameterBySegment("franchise")
            }
            if (segment === "franchise") {
                setListFranchises(data_segment)
            }


        }
    }, [fetching_by_segment])

    useEffect(() => {
        if (fetching_list_config === 2) {
            let _list = listSalary.map(config => {
                data_config.map(data => {

                    if (data.parameter_id === config.id) {
                        config.value = data.value
                        config.status = data.status
                        config.type_value = data.type_value
                    }
                })

                return config;
            })
            let _listTips = listTips.map(config => {
                data_config.map(data => {

                    if (data.parameter_id === config.id) {
                        config.value = data.value
                        config.status = data.status
                        config.type_value = data.type_value
                    }
                })

                return config;
            })

            setListSalary(_list);
            setListTips(_listTips)
        }
    }, [fetching_list_config])

    const onSucess = (list) => {
        onSaveConfiguration(list)
    }
    const onSucessFranchise = (list) => {
        onSaveFranchise(list)
    }

    return <TemplateMenu><Container fluid>
        <Row>
            <Col><h4>Listado Configuración</h4></Col>
        </Row>

        <Tabs defaultActiveKey="franchise" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="home" title="Nomina">
                <SalaryConfig list={listSalary} onSubmit={onSucess} />
            </Tab>
            <Tab eventKey="profile" title="Porcentaje Propina">
                <TipsConfig list={listTips} onSubmit={onSucess} />
            </Tab>
            <Tab eventKey="franchise" title="Franquicias">
                <FranchiseConfigPage listFranchises={listFranchises} listMethods={listMethodsPayment} onSubmit={onSucessFranchise} />
            </Tab>
        </Tabs>
    </Container>
    </TemplateMenu>
}
const mapsState = (props) => {
    return {
        segment: props.parameter.segment,
        fetching_by_segment: props.parameter.fetching_by_segment,
        data_segment: props.parameter.data_segment,
        fetching_list_config: props.parameter.fetching_list_config,
        data_config: props.parameter.data_config,
        fetching_config: props.parameter.fetching_config,
    }
}

export default connect(mapsState, { getParameterBySegment, onGetConfiguration, onSaveConfiguration, onSaveFranchise })(ConfigurationPage);