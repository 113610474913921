import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { onCreateTransfer } from "../../Redux/transferDuck";
import { getListCharge } from "../../Redux/ProductInventoryDuck";
import { toogleLoader } from "../../Redux/alertDuck";
import TemplateMenu from "../../template/menu.template";
import { useHistory } from "react-router-dom";
import RowProduct from "./row.product";
import ItemDetailTransfer from "./item.detail.transfer";
import moment from "moment";
import { formatNumber } from "../../utils";
import ModalReviewPurchase from "./modal.review.transfer";

const CreateTransferPage = ({ errors, fetching_list, branch_offices, onCreateTransfer, fetching_create,
  toogleLoader, getListCharge, fetching_list_charge, data_charge }) => {
  const [form, setForm] = useState({
    delivery_branch_id: 0,
    registration_date: '',
    date_init: "", date_end: "",
    charge_id: 0
  });
  const history = useHistory();
  const [listBranch, setListBranch] = useState([]);
  const [selected, setSelected] = useState({});
  const [selectedProduct, setSelectedProduct] = useState({});
  const [listProduct, setListProduct] = useState([]);
  const [listDetail, setListDetail] = useState([]);
  const [show, setShow] = useState(false);
  const [modal, setModal] = useState(false);
  const [total, setTotal] = useState(0);
  const [totalCharge, setTotalCharge] = useState(0);
  const [listCharge, setListCharge] = useState([]);
  const [selectedCharge, setSelectedCharge] = useState({});

  useEffect(() => {
    if (fetching_create === 2) {
      toogleLoader(false);
      history.goBack();
    }
  }, [fetching_create]);

  useEffect(() => {
    if (fetching_list_charge === 2) {
      toogleLoader(false);
      setListCharge(data_charge)
    }
  }, [fetching_list_charge]);

  useEffect(() => {
    if (fetching_list === 2) {
      let branch = JSON.parse(localStorage.getItem("branch"))

      let _branch = branch_offices.filter(d => parseInt(d.id) !== parseInt(branch.id))
      setListBranch(_branch);
    }
  }, [fetching_list]);

  useEffect(() => {
    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));
    let _month = moment(date_init).format("MM");
    let _current_month = moment().format("MM");
    let _form = {
      ...form,
      ["date_init"]: date_init,
      ["date_end"]: date_end,
    };
    if (_month !== _current_month) {
      _form.registration_date = '';
    }

    setForm(_form);

    toogleLoader(true)

    getListCharge()
  }, []);

  const updateInput = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  const updateInputCharge = (e) => {
    let input = parseInt(e.target.value)
    setForm({
      ...form,
      [e.target.name]: input,
    });

    if (input !== 0) {

      let _charge = listCharge.filter(d => d.id === input);

      if (_charge.length > 0) {
        _charge = _charge[0]
        setSelectedCharge(_charge)

        let { detail } = _charge

        let _list = listDetail.map(d => {
          detail.map(r => {
            if (d.product.id === r.product_id) {
              d.charge = parseFloat(r.value || 0)
            }
            return r;

          });

          return d
        })

        setListDetail(_list)

        let _totalCharge = _list.reduce((a, b) => (a = a + (parseFloat(b.amount) * parseFloat(b.cost_average)) + (parseFloat(b.amount) * parseFloat(b.cost_average)) * parseFloat(b.charge / 100)), 0);
        setTotalCharge(_totalCharge)
      }
    } else {
      let _list = listDetail.map(d => {
        d.charge = 0;
        return d;
      })

      setListDetail(_list)
    }

  }

  const onAddProduct = () => {
    if (Object.keys(selectedProduct).length > 0) {
      let _list = [...listDetail, selectedProduct];
      setListDetail(_list);

      setSelectedProduct({});

      let _total = _list.reduce((a, b) => (a = a + parseFloat(b.cost * b.quantity)), 0);

      setTotal(_total);
      setShow(false);
    } else {
      setShow(true);
    }
  };

  const onDeleteItem = (item) => {
    let list = listDetail.filter((l) => l._id !== item._id);
    let _total = list.reduce((a, b) => (a = a + parseFloat(b.amount) * parseFloat(b.cost)), 0);

    let _totalCharge = list.reduce((a, b) => (a = a + (parseFloat(b.amount) * parseFloat(b.cost)) + (parseFloat(b.amount) * parseFloat(b.cost)) * parseFloat(b.charge / 100)), 0);
    setTotalCharge(_totalCharge)

    setTotal(_total);


    setListDetail(list);
  };

  const onAddItem = (item) => {

    let validate = listDetail.filter(d => d.product.id === item.product.id).length

    let _list = [...listDetail, item];

    if (validate > 0) {
      alert("Producto ya existe")
      return
    }

    let _total = _list.reduce((a, b) => (a = a + parseFloat(b.amount) * parseFloat(b.cost_average)), 0);
    let _totalChange = _list.reduce(
      (a, b) => (a = a + parseFloat(b.amount) * parseFloat(b.cost_average) +
        ((parseFloat(b.amount) * parseFloat(b.cost_average) * parseFloat(b.charge / 100)))), 0);


    setTotal(_total);
    setTotalCharge(_totalChange)

    setListDetail(_list);
    setShow(false);
  }

  const onSuccess = () => {
    if (listDetail.length > 0) {

      if (form.delivery_branch_id !== 0) {
        let _form = form;
        _form.list_products = listDetail;
        onCreateTransfer(_form);
        toogleLoader(true)
      } else {
        alert("Selecciona Sucursal de Envio");
      }


    } else {
      alert("No puedes crear una orden vacia");
    }
  };

  const onEdit = (item) => {
    setShow(true);
    setSelectedProduct(item);
  };

  const onEditItem = (item) => {
    let _list = listDetail.map((d) => {
      if (item._id === d._id) {
        d.amount = item.amount;
        d.cost = item.cost;
      }
      return d;
    });

    setListDetail(_list);
    setSelectedProduct({});
    setShow(false);
  }

  return (
    <TemplateMenu>
      <Container fluid>
        <Row className="my-2">
          <Col>
            <button className="btn btn-sm btn-secondary" onClick={() => history.goBack()}>
              Volver
            </button>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col lg={10}>
            <Row className="my-2">
              <Col lg={10}>
                <h3 className="text-center">Realizar Transferencia</h3>
              </Col>
              <Col lg={2}>
                <button className="btn btn-success btn-sm" onClick={onSuccess}>Transferir</button>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <Row>
                  <Col lg={4}>
                    <div className="form-group">
                      <label>Enviar a:</label>
                      <select className="form-control" name="delivery_branch_id" value={form.delivery_branch_id}
                        onChange={updateInput} >
                        <option value="0">Seleccione</option>
                        {listBranch.map((m) => (
                          <option key={m.id} value={m.id}>{m.title}</option>
                        ))}
                      </select>
                      {errors.delivery_branch_id !== undefined && (
                        <label className="error-label">
                          {errors.supplier_id}
                        </label>
                      )}
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="form-group">
                      <label>Fecha de Registro</label>
                      <input type={"date"} min={form.date_init} max={form.date_end} className="form-control"
                        name="registration_date" value={form.registration_date}
                        onChange={updateInput}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="form-group">
                      <label>¿Aplica Cargo?</label>
                      <select className="form-control" name="charge_id" onChange={updateInputCharge}>
                        <option value={0}>Seleccione Preferencias</option>
                        {listCharge.map((d, k) => <option key={k} value={d.id}>{d.name}</option>)}
                      </select>
                      {/* <input
                        type={"checkbox"}
                        className="form-control"
                        name="has_charge"
                        checked={form.has_charge}
                        onChange={updateInputCheck}
                      /> */}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col className="">
                <table className="table table-sm table-bordered">
                  <thead className="thead-dark">
                    <tr>
                      <th width="30%">Producto</th>
                      <th align="right" className="text-center">Costo Unit</th>
                      <th width="20%" className="text-center">Unidades a Enviar</th>
                      <th align="right" className="text-center">SubTotal</th>
                      {form.charge_id !== 0 && <>
                        <th align="right">Cargo</th>
                        <th align="right">Subtotal + Cargo</th>
                      </>}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {listDetail.map((d, key) => (
                      <ItemDetailTransfer
                        item={d}
                        key={key}
                        onEdit={onEdit}
                        onDelete={onDeleteItem}
                        listProduct={listProduct}
                        charge_id={form.charge_id}
                        selectedCharge={selectedCharge}

                      />
                    ))}
                    {show === true && (
                      <RowProduct
                        charge_id={form.charge_id}
                        registration_date={form.registration_date}
                        onEditItem={onEditItem}
                        formEdit={selectedProduct}
                        listProduct={listProduct}
                        onAddItem={onAddItem}
                        selectedCharge={selectedCharge}
                        onDelete={() => {
                          setSelectedProduct({});
                          setShow(false);
                        }}
                      />
                    )}
                    <tr>
                      <td colSpan={7}>
                        {" "}
                        {!show && (
                          <button className="btn btn-sm btn-info" onClick={onAddProduct}>
                            Agregar Producto
                          </button>
                        )}
                      </td>

                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={3} align="right">TOTAL</td>
                      <td align="right">{formatNumber(total)}</td>
                      <td align="right"></td>
                      {form.charge_id !== 0 && <td align="right">{formatNumber(totalCharge)}</td>}
                    </tr>
                  </tfoot>
                </table>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* <ModalReviewPurchase
          show={modal}
          listDetail={listDetail}
          onSuccess={onConfirmPurchase}
          onHide={() => setModal(false)}
        /> */}
      </Container>
    </TemplateMenu>
  );
};

const mapsState = (props) => {
  let { branchoffice, transfer, product_inventory } = props;

  return {
    fetching_list: branchoffice.fetching,
    branch_offices: branchoffice.data,
    fetching_create: transfer.fetching_create,
    fetching_list_charge: product_inventory.fetching_list_charge,
    data_charge: product_inventory.charge,
    errors: {}
  };
};

export default connect(mapsState, { onCreateTransfer, toogleLoader, getListCharge })(CreateTransferPage);
