import React, { Fragment } from 'react';
import { Row, Col, Container, Card } from 'react-bootstrap';
import { formatNumber } from '../../utils'
import InputField from '../Components/Widgets/InputField';


const ItemsGroupModifierNew = ({ item, showForm, formEdit, updateField, onEditModifier, onCloseEditModifier, updateCheckRow }) => {
    console.log('item', item)

    let { modifiers_assoc } = item;

    const printModifiert = (row, key) => {
        let { modifier } = row;
        return (
            <li key={key}>{modifier.title} (${formatNumber(modifier.price)})</li>
        )
    }

    return (
        <Fragment>
            <Row className="my-1">
                <Col>
                    <Card className="border-primary">
                        <Card.Body>
                            <Row>
                                <Col lg={4}><h5>{item.title}</h5></Col>
                                <Col lg={2}>Orden ({item.priority})</Col>
                                <Col lg={2}>{item.selected ? 'Por Defecto' : ''}</Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Row className="ml-3">
                                        <Col><h6>Modificadores ({modifiers_assoc.length})</h6></Col>
                                    </Row>
                                    <Row className="ml-3">
                                        <Col>
                                            <ul>
                                                {modifiers_assoc.map(printModifiert)}
                                            </ul>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>

            </Row>
            {/* <Row className="ml-2 my-2">
                {item.id !== formEdit.id ? (
                    <Fragment>
                        <Col lg={4}>{item.title}</Col>
                        <Col lg={2}>$ {formatNumber(item.price)}</Col>
                        <Col lg={2}>Orden ({item.priority})</Col>
                        <Col lg={2}>{item.selected ? 'Por Defecto' : ''}</Col>
                        <Col lg={2}>
                            <button className="btn btn-sm btn-info" onClick={() => showForm(item)}>Editar</button>
                        </Col>
                    </Fragment>
                ) : (
                        <Fragment>
                            <Container>
                                <Card>
                                    <Card.Body>

                                        <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <label>Titulo Modificador</label>
                                                    <input value={formEdit.title} className="form-control" name="title" onChange={updateField} />
                                                </div>

                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <label>Descripción</label>
                                                    <input value={formEdit.description} className="form-control" name="description" onChange={updateField} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <label>Precio</label>
                                                    <input value={formEdit.price} className="form-control" name="price" onChange={updateField} />
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="form-group">
                                                    <label>Orden</label>
                                                    <input value={formEdit.priority} className="form-control" name="priority" onChange={updateField} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <label>Orden</label>
                                                    <input value={formEdit.priority} className="form-control" name="priority" onChange={updateField} />
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="form-group">
                                                    <label>Por Defecto</label>
                                                    <InputField
                                                        type="checkbox"
                                                        placeholder=""
                                                        value={formEdit.selected || ''}
                                                        name="selected"
                                                        onChange={updateCheckRow}
                                                    />

                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <div className="form-group">
                                                    <label>Código aldelo</label>
                                                    <input value={formEdit.aldelo_id} className="form-control" name="aldelo_id" onChange={updateField} />
                                                </div>
                                            </Col>


                                        </Row>
                                        <Row>
                                            <Col lg={2} className="align-items-center d-flex">
                                                <button className="btn btn-sm btn-success" onClick={() => onEditModifier(item)}>Guardar</button>

                                            </Col>
                                            <Col lg={2} className="align-items-center d-flex">
                                                <button className="ml-3 btn btn-sm btn-secondary" onClick={() => onCloseEditModifier()}>Cerrar</button>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>


                            </Container>
                        </Fragment>
                    )}
            </Row> */}
        </Fragment>
    )
}
export default ItemsGroupModifierNew;