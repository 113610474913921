import moment from 'moment';
import { getHeaders } from '../utils';

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
  fetching: 0,
  fetching_list: 0,
  fetching_create: 0,
  fetching_by_id: 0,
  fetching_print: 0,
  fetching_template: 0,
  fetching_template_create: 0,
  data: [],
  data_template: [],
  row: {},
  errors: {}
}

let LIST_REQUISITION = "LIST_REQUISITION";
let LIST_REQUISITION_SUCCESS = "LIST_REQUISITION_SUCCESS";
let CREATE_REQUISITION = "CREATE_REQUISITION";
let CREATE_REQUISITION_SUCCESS = "CREATE_REQUISITION_SUCCESS";
let REQUISITION_BY_ID = "REQUISITION_BY_ID";
let REQUISITION_BY_ID_SUCCESS = "REQUISITION_BY_ID_SUCCESS";
let REQUISITION_PRINT = "REQUISITION_PRINT";
let REQUISITION_PRINT_SUCCESS = "REQUISITION_PRINT_SUCCESS";
let DELETE_REQUISITION = "DELETE_REQUISITION";
let DELETE_REQUISITION_SUCCESS = "DELETE_REQUISITION_SUCCESS";
let LIST_TEMPLATE = "LIST_TEMPLATE";
let LIST_TEMPLATE_SUCCESS = "LIST_TEMPLATE_SUCCESS";
let CREATE_TEMPLATE = "CREATE_TEMPLATE"
let CREATE_TEMPLATE_SUCCESS = "CREATE_TEMPLATE_SUCCESS"

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LIST_REQUISITION:
      return { ...state, fetching_list: 1, }
    case LIST_REQUISITION_SUCCESS:
      return { ...state, fetching_list: 2, data: action.payload, fetching_delete: 0, fetching_create: 0 }
    case CREATE_REQUISITION:
      return { ...state, fetching_create: 1 }
    case CREATE_REQUISITION_SUCCESS:
      return { ...state, fetching_create: 2 }
    case DELETE_REQUISITION:
      return { ...state, fetching_delete: 1 }
    case DELETE_REQUISITION_SUCCESS:
      return { ...state, fetching_delete: 2 }
    case REQUISITION_BY_ID:
      return { ...state, fetching_by_id: 1 }
    case REQUISITION_BY_ID_SUCCESS:
      return { ...state, fetching_by_id: 2, row: action.payload }
    case REQUISITION_PRINT:
      return { ...state, fetching_print: 1 }
    case REQUISITION_PRINT_SUCCESS:
      return { ...state, fetching_print: 2, row: action.payload }
    case LIST_TEMPLATE:
      return { ...state, fetching_template: 1 }
    case LIST_TEMPLATE_SUCCESS:
      return { ...state, fetching_template: 2, data_template: action.payload }
    case CREATE_TEMPLATE:
      return { ...state, fetching_template_create: 1 }
    case CREATE_TEMPLATE_SUCCESS:
      return { ...state, fetching_template_create: 2 }
    default:
      return state;
  }
}

export let getListTemplate = () => (dispatch, getState) => {
  dispatch({
    type: LIST_TEMPLATE,
  });
  let branch = JSON.parse(localStorage.getItem("branch"))
  let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))

  let url = `${API_URL}/api/requisition-template?branch=${branch.id}&date_init=${date_init}&date_end=${date_end}`;

  fetch(url, {
    headers: getHeaders()
  }).then(resp => resp.json()).then(resp => {

    dispatch({
      type: LIST_TEMPLATE_SUCCESS,
      payload: resp.results
    });

  })
}

export let onCreateTemplate = (form) => (dispatch, getState) => {
  dispatch({
    type: CREATE_TEMPLATE,
  });
  let branch = JSON.parse(localStorage.getItem("branch"))

  let url = `${API_URL}/api/requisition-template`;
  form.branch_id = branch.id

  fetch(url, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(form)
  }).then(resp => resp.json()).then(resp => {

    dispatch({
      type: CREATE_TEMPLATE_SUCCESS,
    });

  })
}
export let onEditTemplate = (form) => (dispatch, getState) => {
  dispatch({
    type: CREATE_TEMPLATE,
  });
  let branch = JSON.parse(localStorage.getItem("branch"))

  let url = `${API_URL}/api/requisition-template/${form.id}`;
  form.branch_id = branch.id

  fetch(url, {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify(form)
  }).then(resp => resp.json()).then(resp => {

    dispatch({
      type: CREATE_TEMPLATE_SUCCESS,
    });

  })
}

export let getListRequisition = () => (dispatch, getState) => {
  dispatch({
    type: LIST_REQUISITION,
  });
  let branch = JSON.parse(localStorage.getItem("branch"))
  let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))

  let url = `${API_URL}/api/requisition?branch=${branch.id}&date_init=${date_init}&date_end=${date_end}`;

  fetch(url, {
    headers: getHeaders()
  }).then(resp => resp.json()).then(resp => {

    dispatch({
      type: LIST_REQUISITION_SUCCESS,
      payload: resp.results
    });

  })
}


export let getRequisitionById = (id, finish_at) => (dispatch, getState) => {
  dispatch({
    type: REQUISITION_BY_ID,
  });

  let url = `${API_URL}/api/requisition/${id}?finish_at=${finish_at}`;

  fetch(url, {
    headers: getHeaders()
  }).then(resp => resp.json()).then(resp => {

    dispatch({
      type: REQUISITION_BY_ID_SUCCESS,
      payload: resp
    });

  })
}

export let onCreateRequisition = (form) => (dispatch, getState) => {
  dispatch({
    type: CREATE_REQUISITION,
  });
  let url = `${API_URL}/api/requisition`;

  let branch = JSON.parse(localStorage.branch);

  form.branch_id = branch.id;

  fetch(url, {
    method: "POST",
    body: JSON.stringify(form),
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: CREATE_REQUISITION_SUCCESS,
      });
    });
};

export let onDeleteRequisition = (id) => (dispatch, getState) => {
  dispatch({
    type: DELETE_REQUISITION,
  });
  let url = `${API_URL}/api/requisition/${id}`;


  fetch(url, {
    method: "DELETE",
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: DELETE_REQUISITION_SUCCESS,
      });
    });
}
export let onReverseRequisition = (id) => (dispatch, getState) => {
  dispatch({
    type: DELETE_REQUISITION,
  });
  let url = `${API_URL}/api/requisition-reverse/${id}`;


  fetch(url, {
    method: "PUT",
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: DELETE_REQUISITION_SUCCESS,
      });
    });
}
export let onDeleteRequisitionDetail = (id) => (dispatch, getState) => {
  dispatch({
    type: CREATE_REQUISITION,
  });
  let url = `${API_URL}/api/requisition-detail/${id}`;


  fetch(url, {
    method: "DELETE",
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: CREATE_REQUISITION_SUCCESS,
      });
    });
};
export let onPrintRequisition = (param) => (dispatch, getState) => {
  dispatch({
    type: REQUISITION_PRINT,
  });

  let { date_init } = JSON.parse(localStorage.getItem("date"))
  let url = `${API_URL}/api/requisition-print/${param.id}?date_init=${date_init}`;

  console.log('param', param);

  fetch(url, {
    method: "PUT",
    body: JSON.stringify(param),
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      console.log('resp', resp);

      dispatch({
        type: REQUISITION_PRINT_SUCCESS,
        payload: resp
      });
    });
};