import React, { useEffect, useState } from 'react'
import TemplateMenu from "../../../template/menu.template";
import { Card, Col, Form, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getSalesByBrand } from '../../../Redux/reportDuck'
import { toogleLoader } from '../../../Redux/alertDuck'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { formatNumber } from '../../../utils';
import moment from 'moment';


const SalesBrandPage = ({ getSalesByBrand, fetching_by_brand, data_sales, toogleLoader }) => {
    const [list, setList] = useState([])
    const [listBranch, setListBranch] = useState([])
    const [form, setForm] = useState({ order_by_total: false, search: '', date_init: moment().format("YYYY-MM-01"), date_end: moment().format("YYYY-MM-DD") })

    useEffect(() => {
        if (fetching_by_brand === 2) {
            const { branches, list_categories } = data_sales
            setListBranch(branches)
            setList(list_categories);
            toogleLoader(false)
        }
    }, [fetching_by_brand])

    useEffect(() => {
        toogleLoader(true)

        let { date_init } = JSON.parse(localStorage.getItem("date"))
        let _form = form
        _form.date_init = date_init

        setForm(_form)
        getSalesByBrand(_form)
    }, [])


    const onChangeOrder = () => {
        toogleLoader(true)
        let _order = !form.order_by_total
        let _form = {
            ...form,
            ["order_by_total"]: _order
        }
        setForm(_form)
        getSalesByBrand(_form)
    }

    const onFilter = (e) => {
        toogleLoader(true)
        getSalesByBrand(form)
    }

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    return (
        <TemplateMenu>
            <Row className='my-2'>
                <Col><h4>Reporte de Ventas</h4></Col>
                <Col lg={2}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Fecha Inicio</Form.Label>
                        <Form.Control
                            type="date"
                            value={form.date_init}
                            name="date_init"
                            onChange={updateInput}
                        />
                    </Form.Group>
                </Col>
                <Col lg={2}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Fecha Fin</Form.Label>
                        <Form.Control
                            type="date"
                            value={form.date_end}
                            name="date_end"
                            onChange={updateInput}
                        />
                    </Form.Group>
                </Col>
                <Col lg={4} >
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Buscar Producto</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder='Buscar Producto'
                            name="search"
                            onChange={updateInput}
                        />
                    </Form.Group>
                </Col>
                <Col className='d-flex align-items-center'>
                    <button className='btn btn-sm btn-info' onClick={onFilter}>Filtrar</button>
                </Col>
            </Row>
            <Row>
                <Col>
                    {list.map((c, key) => <Card key={key} className='my-1'>
                        <Card.Body className='px-0'>
                            <Card.Title className='text-center'>{c.title}</Card.Title>
                            <Card.Body>
                                <Table className='table table-sm table-hover table-striped table-bordered'>
                                    <thead className='thead-dark'>
                                        <tr>
                                            <th>PRODUCTOS</th>
                                            {listBranch.map((d, k) => <th key={k} className='text-center'>{d.title}</th>)}
                                            <th className='text-center'>TOTAL {form.order_by_total ? <MdKeyboardArrowDown size={25}
                                                className='cursor-pointer' onClick={onChangeOrder} /> :
                                                <MdKeyboardArrowUp size={25}
                                                    className='cursor-pointer' onClick={onChangeOrder} />}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {c.products.map((d, k) => <tr key={k}>
                                            <td>{d.product}</td>
                                            {d.branches.map((b, key) => <td align='right' className={parseInt(b.total) === 0 ? 'table-warning' : ''} key={key}>{b.total}</td>)}
                                            <td align='right'>{d.total}</td>
                                        </tr>)}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th colSpan={listBranch.length + 1}>TOTAL</th>
                                            <th className='text-right'>{formatNumber(c.products.reduce((a, b) => a + parseFloat(b.total), 0))}</th>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </Card.Body>
                        </Card.Body>
                    </Card>)}

                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { report } = props

    return {
        fetching_by_brand: report.fetching_by_brand,
        data_sales: report.data_sales,
    }
}
export default connect(mapsState, { getSalesByBrand, toogleLoader })(SalesBrandPage);