import axios from 'axios'

import { getHeaders } from "../utils";

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching_create: 0,
    fetching_list: 0,
    fetching_delete: 0,
    message: '',
    data: [],
    full_screen: false,
    selected_screen: []

}

let LIST_SCREEN = "LIST_SCREEN";
let LIST_SCREEN_SUCCESS = "LIST_SCREEN_SUCCESS";
let TOOGLE_FULL_SCREEN = "TOOGLE_FULL_SCREEN";
let CREATE_SCREEN = "CREATE_SCREEN";
let CREATE_SCREEN_SUCCESS = "CREATE_SCREEN_SUCCESS";
let SCREEN_DETAIL_STORE = "SCREEN_DETAIL_STORE";
let SCREEN_DETAIL_STORE_SUCCESS = "SCREEN_DETAIL_STORE_SUCCESS";
let DELETE_SCREEN = "DELETE_SCREEN";
let DELETE_SCREEN_SUCCESS = "DELETE_SCREEN_SUCCESS";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_SCREEN:
            return { ...state, fetching_list: 1, }
        case LIST_SCREEN_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        case TOOGLE_FULL_SCREEN:
            return { ...state, full_screen: action.payload, selected_screen: action.selected }
        case CREATE_SCREEN:
            return { ...state, fetching_create: 1 }
        case CREATE_SCREEN_SUCCESS:
            return { ...state, fetching_create: 2 }
        case SCREEN_DETAIL_STORE:
            return { ...state, fetching_create: 1 }
        case SCREEN_DETAIL_STORE_SUCCESS:
            return { ...state, fetching_create: 2 }
        case DELETE_SCREEN:
            return { ...state, fetching_delete: 1 }
        case DELETE_SCREEN_SUCCESS:
            return { ...state, fetching_delete: 2 }
        default:
            return state;
    }
}

export let getListScreen = () => (dispatch, getState) => {
    dispatch({
        type: LIST_SCREEN,
    });

    let url = `${API_URL}/api/screen`;

    if (getState().user.loggedIn) {

        fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: LIST_SCREEN_SUCCESS,
                payload: resp.results
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let deleteScreen = (id) => (dispatch, getState) => {
    dispatch({
        type: DELETE_SCREEN,
    });

    let url = `${API_URL}/api/screen/${id}`;

    if (getState().user.loggedIn) {

        fetch(url, {
            method: 'DELETE',
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: DELETE_SCREEN_SUCCESS,
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let createScreen = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_SCREEN,
    });

    let url = `${API_URL}/api/screen`;

    if (getState().user.loggedIn) {

        fetch(url, {
            method: "POST",
            body: JSON.stringify(form),
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {

            dispatch({
                type: CREATE_SCREEN_SUCCESS,
            });
        })
    } else {
        console.log("no Ingreso");
    }
}

export let addImageToScreen = (form) => (dispatch, getState) => {
    dispatch({
        type: SCREEN_DETAIL_STORE,
    });

    let url = `${API_URL}/api/screen-detail`;

    if (getState().user.loggedIn) {
        let _form = new FormData()
        _form.append("image", form.image);
        _form.append("screen_id", form.id);


        console.log('_form', _form);


        axios.post(url, _form, {
            headers: getHeaders()

        }).then(resp => {
            dispatch({
                type: SCREEN_DETAIL_STORE_SUCCESS,
            });

        })
    } else {
        console.log("no Ingreso");
    }
}

export let toogleFullScreen = (item) => (dispatch, getState) => {

    item = item || []

    let full_screen = !getState().screen.full_screen

    dispatch({
        type: TOOGLE_FULL_SCREEN,
        payload: full_screen,
        selected: item
    });
}