import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { AiFillEdit } from 'react-icons/ai';
import { io } from 'socket.io-client';
import { formatNumber } from '../../utils';

const ItemRequisitionEditConfirm = ({ item, onUpdateRow, charge_id, status, onDeleteRow, show_delete,
    isTransfer, selectedCharge }) => {

    const [form, setForm] = useState({ quantity_real: '' })

    useEffect(() => {
        let quantity = item.quantity_real
        setForm({
            ...form,
            ["quantity_real"]: quantity
        })
    }, [item])

    const updateInput = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        item.quantity_real = e.target.value
        onUpdateRow(item)
    }

    const onValidate = (e) => {
        if (parseFloat(e.target.value) < 0) {
            setForm({
                ...form,
                [e.target.name]: 0
            })

            item.quantity_real = 0
            onUpdateRow(item)
        }
    }

    let subtotal = item.cost_average * form.quantity_real;
    let subtotal_charge = (item.charge / 100 * subtotal) + subtotal;


    return (
        <tr className={`${item.error ? 'table-danger' : ''}`}>
            <td>{item.product}</td>
            <td align='right'>{formatNumber(item.cost_average || 0)}</td>
            {!isTransfer && <td align='right'>{formatNumber(item.amount_total || 0)}</td>}
            <td align='right'>{item.quantity}</td>
            <td align='right'>
                {status === 3
                    ? item.quantity_real
                    : <input type='number' className='form-control text-right' name='quantity_real' value={form.quantity_real}
                        onChange={updateInput} onBlur={onValidate} />}
            </td>
            <td align='right'>{formatNumber(subtotal)}</td>
            {charge_id !== 0 && <>
                <td align='right'>{item.charge || 0}%</td>
                <td align='right'>{formatNumber(subtotal_charge)}</td>
            </>}
            <td>
                {show_delete &&
                    <button className='btn btn-sm btn-danger' onClick={() => onDeleteRow(item)}>Borrar</button>}

            </td>
        </tr>
    )
}

export default ItemRequisitionEditConfirm;