import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import TemplateMenu from '../../template/menu.template'
import {
    getRequisitionById, onPrintRequisition, onDeleteRequisitionDetail,
    onDeleteRequisition
} from '../../Redux/requisitionDuck'
import { toogleLoader } from '../../Redux/alertDuck'
import ItemRequisitionEdit from './item.requisition.edit';
import moment from 'moment';
import { formatNumber } from '../../utils';
import ItemRequisitionEditConfirm from './item.requisition.edit.confirm';
import { getListCharge } from "../../Redux/ProductInventoryDuck";


const EditRequisitionPage = ({ getRequisitionById, row, fetching_by_id, onPrintRequisition,
    fetching_print, onDeleteRequisitionDetail, fetching_create, onDeleteRequisition, fetching_delete,
    toogleLoader, getListCharge, fetching_list_charge, data_charge }) => {
    const { id } = useParams();
    const [data, setData] = useState({ supplier: {}, branch_request: { title: '' }, status_id: 1 })
    const [form, setForm] = useState({ charge_id: 0, finish_at: '' })
    const [list, setList] = useState([])
    const [point, setPoint] = useState('')
    const [total, setTotal] = useState(0)
    const [totalCharge, setTotalCharge] = useState(0)
    const [quantity, setQuantity] = useState(0)
    const [quantityReal, setQuantityReal] = useState(0)
    const [show, setShow] = useState(false)
    const [titleBranch, setTitleBranch] = useState('')
    const [branch, setBranch] = useState({})
    const [isTransfer, setIsTransfer] = useState(false)
    const [selectedCharge, setSelectedCharge] = useState({})
    const [listCharge, setListCharge] = useState([])
    const history = useHistory();

    useEffect(() => {
        let { date_init } = JSON.parse(localStorage.getItem("date"))
        getRequisitionById(id, moment(date_init).format("YYYY-MM-DD"))
        getListCharge()

    }, [id])

    useEffect(() => {

        if (fetching_list_charge === 2) {
            setListCharge(data_charge)
        }

    }, [fetching_list_charge])

    useEffect(() => {
        if (fetching_print === 2) {
            if (row.status_id === 2) {
                toogleLoader(false)
                console.log(window.open(`/requisiciones/${id}/pdf`, '_blank'));
                window.location.reload();
            } else {
                window.location.reload();
            }
        }
    }, [fetching_print])

    useEffect(() => {
        if (fetching_create === 2) {
            toogleLoader(false)
            window.location.reload();
        }
    }, [fetching_create])

    useEffect(() => {
        if (fetching_delete === 2) {
            history.push("/requisiciones");
        }
    }, [fetching_delete])


    useEffect(() => {
        if (fetching_by_id === 2) {
            setForm({
                ...form,
                ["charge_id"]: row.charge_id,
                ["request_branch_id"]: row.branch_id,
                ["finish_at"]: row.finish_at !== null ? moment(row.finish_at).format("YYYY-MM-DD") : ''
            })

            let _branch = JSON.parse(localStorage.getItem("branch"));

            setBranch(_branch)

            let _quantity = row.requisition_product.reduce((a, b) => a + parseFloat(b.quantity), 0)
            let _quantity_real = row.requisition_product.reduce((a, b) => a + parseFloat(b.quantity_real), 0)
            let _total = row.requisition_product.reduce((a, b) => a + (parseFloat(b.quantity_real === '0' || b.quantity_real === null ? b.quantity : b.quantity_real) * parseFloat(b.cost_average || 0)), 0)
            let _totalCharge = row.requisition_product.reduce((a, b) => a + ((parseFloat(b.quantity) * parseFloat(b.cost_average || 0)) +
                (parseFloat(b.quantity_real) * parseFloat(b.cost_average || 0)) * (b.charge / 100)), 0)
            if (row.status_id === 3) {
                setIsTransfer(true)
                _totalCharge = row.requisition_product.reduce((a, b) => a + ((parseFloat(b.quantity_real) * parseFloat(b.cost_average || 0)) +
                    (parseFloat(b.quantity_real) * parseFloat(b.cost_average || 0)) * (b.charge / 100)), 0)
                _total = row.requisition_product.reduce((a, b) => a + (parseFloat(b.quantity_real) * parseFloat(b.cost_average || 0)), 0)
            }

            if (_branch.id !== row.branch_id) {
                setPoint("Punto Despacho")
                setTitleBranch(row.request_branch_id)
                setShow(false)

            } else {
                setPoint("Punto Solicitud")
                setShow(true)
                setTitleBranch(row.branch_request)
            }

            setData(row)
            setList(row.requisition_product.map(d => {

                if (parseFloat(d.amount_total || 0) < parseFloat(d.quantity_real || 0)) {
                    d.error = true;
                } else {
                    d.error = false
                }

                return d
            }));

            setQuantityReal(_quantity_real)
            setQuantity(_quantity)
            setTotal(_total)
            setTotalCharge(_totalCharge)
        }
    }, [fetching_by_id])


    const onPrint = () => {

        if (form.finish_at === '') {
            alert("Por favor selecciona la Fecha de Entrega")
            return;
        } else {

            let error = list.filter(d => d.error === true);

            if (error.length === 0) {

                let validate = list.filter(d => parseFloat(d.quantity_real) > 0);

                if (validate.length > 0) {
                    let param = {
                        id,
                        list: validate,
                        charge_id: form.charge_id,
                        finish_at: form.finish_at
                    }
                    toogleLoader(true)
                    onPrintRequisition(param)
                } else {
                    alert("Unidades Erradas, por favor revisar")
                }

            } else {
                alert("Tienes errores con la Solicitud")
            }
        }

    }

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        // if (e.target.name === 'finish_at') {
        //     getRequisitionById(id, moment(e.target.value).format("YYYY-MM-DD"))
        // }
    }

    const onUpdateRow = (row) => {
        let _list = list.map(d => {
            if (d.id === row.id) {
                d.quantity_real = row.quantity_real

                if (parseFloat(d.amount_total || 0) < parseFloat(row.quantity_real || 0)) {
                    d.error = true;
                } else {
                    d.error = false;
                }
            }
            return d;
        })

        let _quantity = _list.reduce((a, b) => a + parseFloat(b.quantity_real || 0), 0)
        let _total = _list.reduce((a, b) => a + (parseFloat(b.quantity_real || 0) * parseFloat(b.cost_average || 0)), 0)

        setQuantityReal(_quantity)
        setTotal(_total)
        setList(_list)
    }

    const onDeleteRow = (item) => {
        if (window.confirm("¿Deseas Eliminar Este Registro?")) {
            if (list.length > 1) {

                onDeleteRequisitionDetail(item.id)
            } else {
                console.log('else delete');
                onDeleteRequisition(data.id)
            }
        }
    }

    const updateInputCharge = (e) => {
        let input = parseInt(e.target.value)

        setForm({
            ...form,
            [e.target.name]: input,
        });

        if (input !== 0) {
            let _charge = listCharge.filter(d => d.id === input);

            if (_charge.length > 0) {
                _charge = _charge[0]
                setSelectedCharge(_charge)

                let { detail } = _charge

                let _list = list.map(d => {
                    detail.map(r => {
                        let { product } = r
                        if (d.code === product.code) {
                            d.charge = parseFloat(r.value || 0)
                        }
                        return r;
                    });

                    return d
                });

                setList(_list)

                let _totalCharge = _list.reduce((a, b) => (a = a + (parseFloat(b.quantity_real) * parseFloat(b.cost_average)) + (parseFloat(b.quantity_real) * parseFloat(b.cost_average)) * parseFloat(b.charge / 100)), 0);
                setTotalCharge(_totalCharge)
            }
        } else {

            let _list = list.map(d => {
                d.charge = 0;
                return d;
            })

            let _total = _list.reduce((a, b) => (a = a + (parseFloat(b.quantity_real) * parseFloat(b.cost_average))), 0);

            setTotal(_total)

            setList(_list)
        }
    }

    return (
        <TemplateMenu>
            <Row className='mt-3'>
                <Col>
                    <button className='btn btn-sm btn-secondary' onClick={() => history.goBack()}>Volver</button>
                </Col>
            </Row>
            <Row className='d-flex justify-content-center'>
                <Col lg={11}>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header>
                                    <Row>
                                        <Col><h4>REQUISICIÓN <b>#{data.id}</b></h4></Col>
                                        <Col className='d-flex justify-content-end'>
                                            {show && <>
                                                {data.status_id === 1 && <button className='btn btn-sm btn-warning' onClick={onPrint}>Alistar e imprimir</button>}
                                                {data.status_id === 2 && <button className='btn btn-sm btn-success mx-4' onClick={onPrint}>Entregar</button>}
                                            </>}
                                        </Col>
                                    </Row>

                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col lg={6}>
                                            <Row>
                                                <Col>Estado</Col>
                                                <Col>{data.status_text}</Col>
                                            </Row>
                                            <Row>
                                                <Col>Fecha de Registro</Col>
                                                <Col>{moment(data.registration_date).format("YYYY-MM-DD")}</Col>
                                            </Row>
                                            <Row>
                                                <Col>{point}</Col>
                                                <Col>{titleBranch}</Col>
                                            </Row>
                                        </Col>
                                        <Col lg={6}>
                                            {(data.status_id !== 3 && (data.branch_id === branch.id)) && <Row>
                                                <Col>
                                                    <div className="form-group">
                                                        <label>¿Aplica Cargo?</label>

                                                        <select className="form-control" name="charge_id" value={form.charge_id} onChange={updateInputCharge}>
                                                            <option value={0}>Seleccione Preferencias</option>
                                                            {listCharge.map((d, k) => <option key={k} value={d.id}>{d.name}</option>)}
                                                        </select>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="form-group">
                                                        <label>Fecha de Entrega</label>
                                                        <input
                                                            type={"date"}
                                                            min={form.date_init}
                                                            max={form.date_end}
                                                            className="form-control"
                                                            name="finish_at"
                                                            value={form.finish_at}
                                                            onChange={updateInput}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>}

                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className='mt-2'>
                        <Col>
                            <Card>
                                <Card.Header>
                                    Productos Solicitados
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col>{!show ?
                                            <Table className='table-sm table-bordered'>
                                                <thead className='thead-dark'>
                                                    <tr>
                                                        <th>Producto</th>
                                                        <th>Costo Unitario</th>
                                                        <th>Cantidad Solicitada</th>
                                                        <th>Cantidad Transferida</th>
                                                        <th>Subtotal</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {list.map(p => <ItemRequisitionEdit
                                                        item={p} key={p.id}
                                                        onDeleteRow={onDeleteRow}
                                                        status={data.status_id}
                                                        charge_id={form.charge_id}
                                                    />)}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan={2}><b>TOTAL</b></td>
                                                        <td align="right"><b>{quantity}</b></td>
                                                        <td align="right"><b>{quantityReal}</b></td>
                                                        <td align="right"><b>{formatNumber(total)}</b></td>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                            :
                                            <Table className='table-sm table-bordered'>
                                                <thead className='thead-dark'>
                                                    <tr>
                                                        <th>Producto</th>
                                                        <th width="10%">Costo Unit</th>
                                                        {!isTransfer && <th>Stock</th>}
                                                        <th>Unidades Solicitada</th>
                                                        <th>Unidades a Enviar</th>
                                                        <th width="20%">Subtotal2</th>
                                                        {form.charge_id !== 0 && <>
                                                            <th>Cargo</th>
                                                            <th width="15%">Subtotal + Cargo</th>
                                                        </>}
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {list.map(p => <ItemRequisitionEditConfirm
                                                        isTransfer={isTransfer}
                                                        show_delete={data.branch_id !== branch.id}
                                                        onDeleteRow={onDeleteRow}
                                                        status={data.status_id}
                                                        charge_id={form.charge_id}
                                                        item={p} key={p.id} onUpdateRow={onUpdateRow}
                                                        selectedCharge={selectedCharge}
                                                    />)}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan={!isTransfer ? 3 : 2}><b>TOTAL</b></td>
                                                        <td align="right"><b>{quantity}</b></td>
                                                        <td align="right"><b>{quantityReal}</b></td>
                                                        <td align="right"><b>{formatNumber(total)}</b></td>
                                                        {form.charge_id !== 0 && <>
                                                            <td align="right"></td>
                                                            <td align="right"><b>{formatNumber(totalCharge)}</b></td>
                                                        </>}

                                                    </tr>
                                                </tfoot>
                                            </Table>}

                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { requisition, product_inventory } = props

    return {
        row: requisition.row,
        fetching_by_id: requisition.fetching_by_id,
        fetching_print: requisition.fetching_print,
        fetching_create: requisition.fetching_create,
        fetching_delete: requisition.fetching_delete,
        fetching_list_charge: product_inventory.fetching_list_charge,
        data_charge: product_inventory.charge,
    }
}

export default connect(mapsState, {
    getRequisitionById, onPrintRequisition, onDeleteRequisitionDetail,
    onDeleteRequisition, toogleLoader, getListCharge
})(EditRequisitionPage);