import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { AiFillEdit, AiFillFilePdf, AiFillLock } from 'react-icons/ai';
import { formatNumber } from '../../utils';
const API_URL = process.env.REACT_APP_API_URL

const ItemLiquidate = ({ item, onSelect, onDeletePayRoll, onToogleSelect, listMethod, onChangeMethod, user, unLock }) => {
    const [show, setShow] = useState(false)
    const [form, setForm] = useState(item.method_payment_id)
    const [checked, setChecked] = useState(false)

    useEffect(() => {
        setChecked(item.checked)
    }, [item.checked])

    const onEditMethod = () => {
        setShow(true)
    }

    const onChangeMethodTmp = (e) => {
        setShow(false)
        onChangeMethod(item, e.target.value)
        setForm(e.target.value)
        let method = listMethod.filter(d => parseInt(d.id) === parseInt(e.target.value))[0]
        item.method_payment = method.description
        item.method_payment_id = method.id
    }

    const onToogleSelectPre = (e) => {
        let status = e.target.checked === true ? true : false
        onToogleSelect(item, status)
        setChecked(status)
    }

    // return <tr onClick={() => onSelect(item)} className={`cursor-pointer ${item.checked === undefined || item.checked === false ? '' : 'table-primary'}`}>
    return <tr className={`cursor-pointer ${item.checked === undefined || item.checked === false ? '' : 'table-primary'}`}>
        <td>{item.id}</td>
        <td>{item.init_end}</td>
        <td>{item.worker}</td>
        <td>{item.branch_office}</td>
        <td>{item.worked_days}</td>
        <td>{formatNumber(item.salary_real)}</td>
        <td>${formatNumber(item.bonification_real)}</td>
        <td>${formatNumber(item.deductions)}</td>
        <td>${formatNumber(item.other_deductions)}</td>
        <td>${formatNumber(item.other_payment)}</td>
        <td>${formatNumber(item.payment)}</td>
        <td>${formatNumber(item.payment)}</td>
        <td>
            {show === true
                ? <Row>
                    <Col>
                        <select className='form-control' value={form} onChange={onChangeMethodTmp} >
                            {listMethod.map(r => <option key={r.id} value={r.id}>{r.description}</option>)}
                        </select>
                    </Col>
                </Row> : <>{item.method_payment} <AiFillEdit onClick={onEditMethod} /></>}
        </td>
        <td>
            {item.status_id === 1 ?
                <>
                    <Row>
                        <Col><input type="checkbox" className='form-control form-control-sm' checked={checked} onChange={onToogleSelectPre} /></Col>
                        <Col>
                            <button className='btn btn-warning btn-sm' onClick={() => onDeletePayRoll(item)}>X</button>
                        </Col>
                    </Row>
                </>
                : <>
                    <a href={`${API_URL}/pdf/${item.id}.pdf`} target="_blank"><AiFillFilePdf size={25} /></a>
                    {user.role_id === 1 || user.role_id === 2 && <AiFillLock size={25} onClick={() => unLock(item)} />}

                </>}
        </td>
    </tr>
}

export default ItemLiquidate;