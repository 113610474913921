import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row, Table } from "react-bootstrap";
import { connect } from "react-redux";
import TemplateMenu from "../../template/menu.template";
import { getSalesFiles, onConfirmUploadSales } from "../../Redux/inventoryDuck";
import { getParameterBySegment } from "../../Redux/parameterDuck";
import { toogleLoader } from "../../Redux/alertDuck";
import moment from "moment";
import { formatNumber } from "../../utils";
import { AiFillEye } from "react-icons/ai";

const UploadSalesPage = ({ getSalesFiles, data, onConfirmUploadSales, fetching_confirm, fetching_files,
  error, toogleLoader, user
}) => {
  const [form, setForm] = useState({
    date_init: moment().format("YYYY-MM-01"),
    date_end: moment().format("YYYY-MM-DD"),
  });
  const [textError, setTextError] = useState("");
  const [listUpload, setListUpload] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [lastInvoice, setLastInvoice] = useState("");


  useEffect(() => {
    let _dates = JSON.parse(localStorage.getItem("date"));

    let _form = {
      ...form,
      ["date_init"]: _dates.date_init,
      ["date_end"]: _dates.date_end,
    };
    setForm(_form);

    getSalesFiles(_form);
  }, []);

  useEffect(() => {
    if (fetching_files === 2) {
      setListUpload(data.files_upload)
      setLastInvoice(data.last_invoice)
      setReviews(data.reviews);
    }
  }, [fetching_files]);

  useEffect(() => {
    if (fetching_confirm === 2) {
      setTextError("");
      toogleLoader(false);
      getSalesFiles(form);
    }
    if (fetching_confirm === 3) {
      setTextError(error);
      toogleLoader(false);

    }
  }, [fetching_confirm]);

  const onConfirmUpload = (row) => {

    onConfirmUploadSales(row);
    toogleLoader(true);
  }

  const onFilter = () => {
    getSalesFiles(form);
  }

  const updateInput = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  }

  return (
    <TemplateMenu>
      <Row className="my-3">
        <Col lg={4}>
          <h2>Carga de Ventas</h2>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">

        <Col lg={6}>
          {/* <Row>
                <Col>
                  <Card>
                    <Card.Header>
                      <Row>
                        <Col>Archivo Pendiente por Cargar</Col>
                        <Col className="d-flex justify-content-end">
                          <button
                            className="btn btn-sm btn-success"
                            onClick={onConfirmUpload}>
                            Confirmar Carga
                          </button>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-center"><span className="text-muted">Ver Historial</span></Col>
                      </Row>
                    </Card.Header>
                  </Card>
                </Col>
              </Row> */}

          <Row className="my-2 d-flex justify-content-center">
            <Col>
              <Row>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Fecha de Inicio</Form.Label>
                    <Form.Control
                      type="date"
                      value={form.date_init}
                      name="date_init"
                      onChange={updateInput}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Fecha Fin</Form.Label>
                    <Form.Control
                      type="date"
                      value={form.date_end}
                      name="date_end"
                      onChange={updateInput}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} className="d-flex justify-content-center align-items-center">
                  <button className="btn btn-sm btn-info" onClick={onFilter}>
                    Filtrar
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col>
              <Card>
                <Card.Body>
                  <Row className="text-center">
                    <Col>Ultima fecha de Factura</Col>
                    <Col>{lastInvoice}</Col>
                  </Row>
                </Card.Body>
              </Card>

            </Col>
          </Row>
          {user.role_id === 1 && reviews.map((d, k) => {
            return (
              <Row className="d-flex justify-content-center mt-2" key={k}>
                <Col>
                  <Table className="table-bordered table-sm">
                    <thead className="thead-dark">
                      <tr>
                        <th>Sucursal</th>
                        <th className="text-center">Total Ventas</th>
                        <th className="text-center">Cantidad de Items</th>
                        <th className="text-center"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{d.branch}</td>
                        <td align="right">{formatNumber(d.total)}</td>
                        <td align="right">{d.quantity}</td>
                        <td>
                          <a href={`/carga-ventas/${d.branch_id}/${form.date_init}/${form.date_end}`}>
                            <AiFillEye />
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            );
          })}

        </Col>
        <Col lg={6}>
          <Row>
            <Col><b>HISTORIAL DE CARGAS</b></Col>
          </Row>
          <Row>
            <Col>
              <Table className="table table-sm table-bordered table-striped">
                <thead className="thead-dark">
                  <tr>
                    <th>Fecha de Creación</th>
                    <th>Fecha de Actualización</th>
                    <th>Estado</th>
                    <th>Acción</th>
                  </tr>
                </thead>
                <tbody>
                  {listUpload.map((d, k) => <tr key={k}>
                    <td>{moment(d.created_at).format("YYYY-MM-DD h:mm A")}</td>
                    <td>{moment(d.updated_at).format("YYYY-MM-DD h:mm A")}</td>
                    <td>{d.status_id === 1 ? 'Pendiente' : 'Procesado'}</td>
                    <td>{k < 2 &&
                      <button className="btn btn-sm btn-success" onClick={() => onConfirmUpload(d)}>Subir</button>}

                    </td>
                  </tr>)}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Col>
      </Row>
    </TemplateMenu>
  );
};
const mapsState = (props) => {
  let { inventory, user } = props;

  return {
    fetching_confirm: inventory.fetching_confirm,
    data: inventory.data_pos,
    fetching_files: inventory.fetching_files_pos,
    error: inventory.error,
    user: user.data
  };
};

export default connect(mapsState, {
  getSalesFiles,
  getParameterBySegment,
  onConfirmUploadSales,
  toogleLoader,
})(UploadSalesPage);
