const formatNumber = (number) => {
    number = parseFloat(number).toFixed(3)
    return new Intl.NumberFormat('es-ES').format(number)
    // return parseFloat(number).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

const calculateCost = (amountUsedRow, amountTotalUsed, totalCostTransform, amountGenerateRow) => {
    return ((((amountUsedRow * 100) / amountTotalUsed) / 100) * totalCostTransform) / amountGenerateRow;
}

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

const formatAddress = (address) => {

    let add = address[1].long_name
    let add2 = address[0].long_name

    let first = add.split(/^(Calle|Carrera|Avenida|Transversal)/).filter(row => !!row);

    first.push(add2.substring(0, 2))
    first.push(add2.substring(2, 4))

    let short = "";

    if (first[0] === 'Calle') {
        short = "Cl."
    }
    if (first[0] === 'Carrera') {
        short = "Cr."
    }
    if (first[0] === 'Transversal') {
        short = "Transv."
    }
    if (first[0] === 'Diagonal') {
        short = "Diag."
    }
    if (first[0] === 'Avenida') {
        short = "Av."
    }

    first.push(short)
    return first;

}

const removeSession = () => {
    localStorage.removeItem("access_token")
    localStorage.removeItem("storage")
    localStorage.removeItem("address")
    localStorage.removeItem("date")
    localStorage.removeItem("branch")
    localStorage.removeItem("list_branch")
    localStorage.removeItem("range")
    localStorage.removeItem("menu")
}

function validateUser(form, edit) {
    let err = {};
    edit = edit || false

    if (form.email === '') {
        err.email = "Email Requerida"
    }

    // if (form.email !== '') {
    //     if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(form.email)) {
    //         err.email = "Email no valido";
    //     }
    // }


    if (!edit) {
        if (form.password === '') {
            err.password = "Clave Requerida"
        }

        if (form.confirmation === '') {
            err.confirmation = "Confirmación Requerida"
        }
    }



    if (form.password !== '' && form.confirmation !== '') {
        if (form.password !== form.confirmation) {
            err.confirmation = "Confirmación debe coincidir con la Clave"
        }
    }

    if (form.name === '') {
        err.name = "Nombres Requeridos"
    }
    if (form.branch_id === 0) {
        err.branch_id = "Sucursal Requerida"
    }
    if (form.role_id === 0) {
        err.role_id = "Rol Requerida"
    }

    if (form.phone === '') {
        err.phone = "Celular Requerido"
    }

    console.log('err', err)

    return err;
}

function validateProduct(form) {
    let err = {};

    if (form.title === '') {
        err.title = "Titulo es requirido"
    }

    // if (form.email !== '') {
    //     if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(form.email)) {
    //         err.email = "Email no valido";
    //     }
    // }


    // if (form.password !== '' && form.confirmation !== '') {
    //     if (form.password !== form.confirmation) {
    //         err.confirmation = "Confirmación debe coincidir con la Clave"
    //     }
    // }

    if (form.description === '') {
        err.description = "Descripción es Requerida"
    }
    if (form.priority === 0 || form.priority === '') {
        err.priority = "Prioridad es Requerida"
    }
    if (form.category_id === 0) {
        err.category_id = "Categoria Requerida"
    }

    return err;
}

function validateCategory(form) {
    let err = {};

    if (form.title === '') {
        err.title = "Titulo es requirido"
    }
    if (form.description === '') {
        err.description = "Descripción es requirido"
    }

    if (form.priority === '') {
        err.priority = "Prioridad Requerida"
    }
    if (form.type_menu_id === '') {
        err.type_menu_id = "Tipo de Categoria Requerida"
    }

    console.log('form', form)


    return err;
}

function validateWorker(form) {
    let err = {};

    if (form.name === '') {
        err.name = "Nombre es requerido"
    }
    if (form.document === '') {
        err.document = "Documento es requerido"
    }
    if (form.type_worker === 1) {
        if (form.date_of_admission === '') {
            err.date_of_admission = "Fecha es requerida"
        }
    }

    if (parseInt(form.position_id) === 0) {
        err.position_id = "Posición es requerida"
    }
    if (parseInt(form.country_id) === 0) {
        err.country_id = "Pais es requerido"
    }
    if (parseInt(form.area_id) === 0) {
        err.area_id = "Area es requerido"
    }
    if (parseInt(form.branch_id) === 0) {
        err.branch_id = "Sucursal es requerido"
    }


    return err;
}

function validateBranch(form) {
    let err = {};

    if (form.title === '') {
        err.title = "Titulo Requerida"
    }

    if (form.priority === '') {
        err.priority = "Orden Requerida"
    }
    if (form.address === '') {
        err.address = "Dirección Requerida"
    }

    return err;
}

function validateIngresos(form) {
    let err = {};

    if (form.registration_date === '') {
        err.registration_date = "La fecha es Requerida"
    }

    return err;
}

export let validateSupplier = (form) => {
    let err = {};

    if (form.business_name === '' || form.business_name === undefined) {
        err.business_name = "La Razón social es Requerida"
    }
    if (form.business === '' || form.business === undefined) {
        err.business = "El alias es Requerido"
    }
    if (form.document === '' || form.document === undefined) {
        err.document = "El Documento es Requerido"
    }
    if (form.email === '' || form.email === undefined) {
        err.email = "El Email es Requerido"
    }
    if (form.phone === '' || form.phone === undefined) {
        err.phone = "El Telefono es Requerido"
    }

    return err;
}

export let validateParameter = (form) => {
    let err = {};

    if (form.description === '' || form.description === undefined) {
        err.description = "La descripción es Requerida"
    }

    return err;
}

export let validateExpense = (form) => {
    let err = {};

    if (form.registration_date === '' || form.registration_date === undefined) {
        err.registration_date = "la fecha es Requerida"
    }


    return err;
}

export let validateExpensePersonal = (form) => {
    let err = {};

    if (form.registration_date === '' || form.registration_date === undefined) {
        err.registration_date = "la fecha es Requerida"
    }
    if (form.method_payment_id === 0) {
        err.method_payment_id = "Metodo de pago es Requerida"
    }
    if (form.parameter_id === 0) {
        err.parameter_id = "Concepto es Requerido"
    }
    if (form.parameter_group_id === 0) {
        err.parameter_group_id = "El grupo es Requerido"
    }
    if (form.amount === 0 || form.amount === '') {
        err.amount = "Monto es Requerido"
    }


    return err;
}

function validateIngresosPre(form) {
    let err = {};

    if (form.registration_date === '') {
        err.registration_date = "La fecha es Requerida"
    }
    if (form.aldelo === null) {
        err.aldelo = "La venta es Requerida"
    }

    if (form.cash === null) {
        err.cash = "El efectivo es Requerida"
    }

    if (form.datafono === null) {
        err.datafono = "El datafono es Requerida"
    }

    if (form.daviplata === null) {
        err.daviplata = "El daviplata es Requerida"
    }

    if (form.nequi === null) {
        err.nequi = "El nequi es Requerida"
    }
    if (form.intern_total === null) {
        err.intern_total = "TOTAL PAGOS INTERNO POS es requerido"
    }

    if (form.amount_delivery === null || form.amount_delivery === '') {
        err.amount_delivery = "La Cantidad de domicilios es Requerida"
    }
    if (form.total_delivery === null || form.total_delivery === '') {
        err.total_delivery = "El monto total de domicilios es Requerida"
    }

    if (form.total_input === null || form.total_input === '') {
        err.total_input = "El Cantidad de Entradas es Requerida"
    }

    return err;
}

export let validatePayroll = (form) => {
    let err = {};

    let { showDate } = form;

    if (showDate.show !== true) {
        if (form.alter_date_id === 0) {
            err.alter_date_id = "Selecciona la quincena"
        }
    }


    if (form.worked_days === '') {
        err.worked_days = "Dias de trabajo Requerido"
    }

    return err;
}


function errorLoginServer(error) {
    let err = {}

    if (error.response.status === 422) {
        err = { email: '', phone: '', name: '' }

        for (const [key, value] of Object.entries(error.response.data.errors)) {
            if ('email' === key) {
                err.email = value[0]

                if (value[0] === 'The email has already been taken.') {
                    err.message = "Email ya Existe"
                }

            }
            if ('phone' === key) {
                err.phone = value[0]
                if (value[0] === 'The phone has already been taken.') {
                    err.message = "Número de Teléfono ya Existe"
                }


            }
            if ('name' === key) {
                err.name = value[0]
            }
            if ('password' === key) {
                err.password = value[0]
                if (value[0] === 'The password must be at least 4 characters.') {
                    err.message = "La contraseña debe tener por lo menos 4 Caracteres"
                }
            }
        }
    } else {
        err.message = "Datos Incorrectos"
    }

    return err;
}

export const validateInput = (form, rules) => {
    let err = {}

    for (const [key, value] of Object.entries(form)) {
        rules.map(val => {
            if (key === val.field) {
                if (value === '' || value === 0) {
                    if (val.min !== undefined) {
                        if (parseFloat(value) < val.min) {
                            err[key] = val.msg
                        }
                    } else {
                        err[key] = val.msg
                    }

                } else {
                    if (val.min !== undefined) {
                        if (parseFloat(value) < val.min) {
                            err[key] = `Valor minimo ${val.min}`
                        }
                    }
                }
            }
        });
    }

    return Object.keys(err).length === 0 ? null : err
}

const getHeaders = () => {
    return {
        "Authorization": 'Bearer ' + localStorage.access_token,
        "Content-Type": "application/json",
        "Accept": "application/json",
    }
}

const calculateMargin = (total, price,) => {
    let factor = 1;


    if (parseFloat(price) <= 0) {

        return { "gain_percent": 0, "gain_cost": 0 }
    }

    factor = (price > total) ? 1 : -1;

    let percent = total * 100 / price;

    let _gain = 0
    if (total < price) {
        _gain = (100 - percent)
    } else {
        _gain = (percent - 100)
    }
    _gain = _gain * factor;

    if (price === 0) {
        _gain = 0;
    }

    return { "gain_percent": _gain, "gain_cost": _gain / 100 * price }
}

const saveStorage = (storage) => {
    storage.loggedIn = true;
    localStorage.storage = JSON.stringify(storage);
    localStorage.access_token = storage.access_token;
}


const debounceData = (debounceRef, url) => {
    var promise = new Promise(function (resolve, reject) {
        debounceRef.current = setTimeout(async () => {

            let _search = await fetch(url, {
                headers: getHeaders(),
            }).then((resp) => resp.json());
            resolve(_search)
        }, 350)
    })

    return promise
}



export {
    formatNumber, capitalize, formatAddress, removeSession, errorLoginServer, validateUser, validateBranch, getHeaders, validateProduct,
    validateCategory, validateIngresos, validateIngresosPre, validateWorker, calculateCost, calculateMargin, saveStorage,
    debounceData
}