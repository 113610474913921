import React from 'react'
import { formatNumber } from '../../utils';

const ItemMasterPrescription = ({ item, onToogle, list_branch }) => {

    return (
        <tr>
            <td></td>
            <td>{item.category}</td>
            <td>{item.title}</td>
            {list_branch.map(d =>
                <td align='center' key={d.id}>
                    <input type='checkbox' checked={item.branchs.filter(bra => bra.id === d.id).length > 0 ? true : false} onChange={(e) => onToogle(e, item, d)} />
                </td>
            )}

        </tr>
    )
}

export default ItemMasterPrescription;