import { getHeaders } from "../utils";

const API_URL = process.env.REACT_APP_API_URL;

let initialState = {
  fetching: 0,
  fetching_list: 0,
  fetching_calendar: 0,
  fetching_create: 0,
  data: [],
  data_calendar: [],
  errors: {},
};

let LIST_REMAINDER = "LIST_REMAINDER";
let LIST_REMAINDER_SUCCESS = "LIST_REMAINDER_SUCCESS";
let LIST_CALENDAR = "LIST_CALENDAR";
let LIST_CALENDAR_SUCCESS = "LIST_CALENDAR_SUCCESS";
let CREATE_REMAINDER = "CREATE_REMAINDER";
let CREATE_REMAINDER_SUCCESS = "CREATE_REMAINDER_SUCCESS";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LIST_REMAINDER:
      return { ...state, fetching_list: 1 };
    case LIST_REMAINDER_SUCCESS:
      return { ...state, fetching_list: 2, data: action.payload };
    case LIST_CALENDAR:
      return { ...state, fetching_calendar: 1 };
    case LIST_CALENDAR_SUCCESS:
      return { ...state, fetching_calendar: 2, data_calendar: action.payload };
    case CREATE_REMAINDER:
      return { ...state, fetching_create: 1 };
    case CREATE_REMAINDER_SUCCESS:
      return { ...state, fetching_create: 2 };
    default:
      return state;
  }
}

export let getListRemainder = (content) => (dispatch, getState) => {
  dispatch({
    type: LIST_REMAINDER,
  });
  let branch = JSON.parse(localStorage.getItem("branch"));

  let url = `${API_URL}/api/remainder?branch=${branch.id}&date=${content.date}`;

  fetch(url, {
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: LIST_REMAINDER_SUCCESS,
        payload: resp.results,
      });
    });
};

export let getListCalendar = () => (dispatch, getState) => {
  dispatch({
    type: LIST_CALENDAR,
  });

  let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));
  let url = `${API_URL}/api/remainder-calendar?date_init=${date_init}&date_end=${date_end}`;

  fetch(url, {
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: LIST_CALENDAR_SUCCESS,
        payload: resp.results,
      });
    });
};

export let onCreateRemainder = (form) => (dispatch, getState) => {
  dispatch({
    type: CREATE_REMAINDER,
  });

  let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));
  let branch = JSON.parse(localStorage.getItem("branch"));
  let url = `${API_URL}/api/remainder`;
  form.branch_id = branch.id;

  fetch(url, {
    method: "POST",
    body: JSON.stringify(form),
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: CREATE_REMAINDER_SUCCESS,
      });
    });
};
