import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { connect } from "react-redux";
import TemplateMenu from "../../template/menu.template";
import ModalCourtesy from "./modal.courtesy";
import { getListCourtesy } from "../../Redux/courtesyDuck";
import { Link } from "react-router-dom";

const CourtesyPage = ({ getListCourtesy, fetching_list, data }) => {
  const [modal, setModal] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    if (fetching_list === 2) {
      setList(data);
    }
  }, [fetching_list]);

  useEffect(() => {
    getListCourtesy();
  }, []);

  const onSuccess = () => {
    setModal(false);
  };

  return (
    <TemplateMenu>
      <Row className="mt-3">
        <Col lg={2}>
          <h3>Cortesias</h3>
        </Col>
        <Col lg={2}>
          <button
            className="btn btn-sm btn-success"
            onClick={() => setModal(true)}
          >
            Agregar
          </button>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col lg={5}>
          <Table className="table table-sm table-bordered">
            <thead className="thead-dark">
              <tr>
                <th>Titulo</th>
                <th>Unidades</th>
                <th>Reclados</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {list.map((d) => (
                <tr key={d.id}>
                  <td>{d.name}</td>
                  <td align="right">{d.amount}</td>
                  <td align="right">{d.detail.length}</td>
                  <td>
                    <Link
                      className="btn btn-sm btn-secondary"
                      to={`/cortesias/${d.id}`}
                    >
                      Ver
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <ModalCourtesy show={modal} onSuccess={onSuccess} />
    </TemplateMenu>
  );
};

const mapsState = (props) => {
  let { courtesy } = props;
  return {
    fetching_list: courtesy.fetching_list,
    data: courtesy.data,
  };
};

export default connect(mapsState, { getListCourtesy })(CourtesyPage);
