import React from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap';

const API_URL = process.env.REACT_APP_API_URL


const ModalPhoto = ({ show, img, onHide }) => {
    let image = API_URL+"/"+img
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <Col><img src={image} className="img-fluid" /></Col>
                    </Row>
                </Container>

            </Modal.Body>
        </Modal>
    )
}

export default ModalPhoto;