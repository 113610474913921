import React, { useState, useEffect } from 'react'
import { Modal, Container, Row, Col, Button } from 'react-bootstrap'
import { MdClose } from "react-icons/md";

import { connect } from 'react-redux'
import { createModifier } from '../../Redux/modifierDuck'
import InputField from '../Components/Widgets/InputField'
import BlueTitleBack from '../Components/Widgets/BlueTitleBack'
import BtnOrangeSm from './../Components/Widgets/BtnOrangeSm'
import BtnBlueModal from './../Components/Widgets/BtnBlueModal'


const ModalModifier = ({ showModal, onHide, createModifier, fetching, errors, onSuccess, formEdit }) => {

    const initState = { title: '', description: '', price: '', priority: 1, category_id: 1, aldelo_id: '', status: false }

    const [form, setForm] = useState(initState)

    // useEffect(() => {

    //     if (fetching === 2) {
    //         onHide()

    //     }

    //     if (Object.keys(selected).length > 0) {
    //         console.log('selected', selected)
    //         setForm(selected);
    //     }
    //     else {
    //         if (fetching !== 3 && fetching !== 2) {
    //             setForm(initState)
    //         }
    //     }

    // }, [fetching, selected])

    useEffect(() => {

        if (Object.keys(formEdit).length > 0) {
            console.log('selected', formEdit)
            setForm(formEdit);
        }

    }, [formEdit])

    useEffect(() => {

        if (fetching === 2) {
            onSuccess()
            setForm(initState)
        }

    }, [fetching])

    const closeModalTmp = () => {
        onHide()
    }

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const updateCheck = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.checked
        })
    }

    const onSubmit = () => {
        createModifier(form)
    }


    return (
        <Modal show={showModal} onHide={onHide} centered>
            <div className="modal-header border-bottom-custom d-flex justify-content-end text-center">
                <Modal.Title>Modificadores</Modal.Title>
                <button type="button" className="close p-0 m-0" data-dismiss="modal" aria-label="Close" onClick={closeModalTmp}>
                    <MdClose />
                </button>
            </div>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label className="text-gray-60">Titulo Modificador</label>
                                <InputField
                                    type="text"
                                    placeholder="Titulo del Modificador"
                                    value={form.title}
                                    name="title"
                                    onChange={updateInput}
                                    error={errors.title}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col >
                            <Row>
                                <Col lg={6}>
                                    <div className="form-group">
                                        <label className="text-gray-60">Descripción Modificador</label>
                                        <InputField
                                            type="text"
                                            placeholder="Descripción Modificador"
                                            value={form.description}
                                            name="description"
                                            onChange={updateInput}
                                            error={errors.description}
                                        />
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="form-group">
                                        <label className="text-gray-60">Precio</label>
                                        <InputField
                                            type="number"
                                            placeholder="Precio"
                                            value={form.price}
                                            name="price"
                                            onChange={updateInput}
                                            error={errors.price}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4}>
                                    <div className="form-group">
                                        <label className="text-gray-60">Categoría</label>
                                        <select name="category_id" className="form-control" value={form.category_id} onChange={updateInput}>
                                            <option value="1">Alimento</option>
                                            <option value="2">Desechables</option>
                                        </select>
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="form-group">
                                        <label className="text-gray-60">Orden</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={form.priority || 1}
                                            name="priority"
                                            placeholder="Orden"
                                            onChange={updateInput}
                                        />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="form-group">
                                        <label className="text-gray-60">Activo/Inactivo</label>
                                        <input
                                            type="checkbox"
                                            className="form-control"
                                            checked={form.status || false}
                                            name="status"
                                            placeholder="Recomendado"
                                            onChange={updateCheck}
                                        />
                                    </div>
                                </Col>

                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <div className="form-group">
                                        <label className="text-gray-60">Código para sincronizar con Aldelo</label>
                                        <input
                                            className="form-control"
                                            value={form.aldelo_id || ''}
                                            name="aldelo_id"
                                            placeholder="Código relación de Aldelo"
                                            onChange={updateInput}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <BtnOrangeSm
                                        title='Cerrar'
                                        onClick={closeModalTmp} />
                                </Col>
                                <Col lg={6}>
                                    <BtnBlueModal
                                        title='Guardar'
                                        onClick={onSubmit} />
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    )
}

const mapsState = (props) => {
    return {
        fetching: props.modifier.fetching_create,
        selected: props.modifier.selected,
        errors: props.modifier.errors
    }

}

export default connect(mapsState, { createModifier })(ModalModifier)