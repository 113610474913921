import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { connect } from "react-redux";
import InputField from "../Components/Widgets/InputField";
import { onCreateCourtesy } from "../../Redux/courtesyDuck";
const iniState = { name: "" };

const ModalCourtesy = ({
  show,
  onCreateCourtesy,
  fetching_create,
  onSuccess,
}) => {
  const [form, setForm] = useState(iniState);

  useEffect(() => {
    if (fetching_create === 2) {
      onSuccess();
    }
  }, [fetching_create]);
  const updateInput = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const onCreate = () => {
    onCreateCourtesy(form);
  };

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>Cortesia</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <div className="form-group">
              <label>Titulo</label>
              <InputField
                placeholder="Titulo Cortesia"
                value={form.name || ""}
                name="name"
                onChange={updateInput}
                // error={errors.title}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="form-group">
              <label>Cantidad</label>
              <InputField
                type={"number"}
                placeholder="Agrega la cantidad"
                value={form.amount || ""}
                name="amount"
                onChange={updateInput}
                // error={errors.title}
              />
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row>
          <Col>
            <button className="btn btn-sm btn-success" onClick={onCreate}>
              Crear
            </button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

const mapsState = (props) => {
  let { courtesy } = props;
  return {
    fetching_create: courtesy.fetching_create,
  };
};
export default connect(mapsState, { onCreateCourtesy })(ModalCourtesy);
