import React, { useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { uploadMenuPdf } from '../../Redux/menuDuck';
import { connect } from 'react-redux';

const MenuPdfPage = ({uploadMenuPdf}) => {

    const [pdf,setPdf] = useState();
    

    const inputFile = (e) => {

        let file = e.target.files[0];
        setPdf(file)

        // let reader = new FileReader();
        // reader.onload = () => {
        //     if (reader.readyState === 2) {
        //         setForm({ ...form, pdf: reader.result })
        //     }
        // }
        // reader.readAsDataURL(file)
    }

    const onSubmit=(e)=>{
        e.preventDefault();

        const formData = new FormData();
        formData.append("pdf", pdf)

        uploadMenuPdf(formData)
    }



    return (
        <form onSubmit={onSubmit} method="POST">
            <Card>
                <Card.Body>
                    <Row>
                        <Col>Selecciona el Pdf</Col>
                    </Row>
                    <Row>
                        <Col>
                            <input type="file" className="form-control" onChange={inputFile}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <button className="btn btn-success" >Cargar</button>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <iframe width="900" height="600" src="https://menu-pdf.s3.us-east-2.amazonaws.com/menu.pdf"></iframe>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

        </form>

    )
}

const mapsState=()=>{
    return {

    }
}

export default connect(mapsState,{uploadMenuPdf})(MenuPdfPage);