import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import InputField from "../Components/Widgets/InputField";
import {
  createIngresos, getIngresoById, updateIngresos, deletePayment, deletePaymentWorker, restartIngresos,
  deleteNoveltyTotal, updateIngresosRow, updateIngresosExtra, updateIngresosWorker, updateIngresosNovelty
} from "../../Redux/IngresosDuck";
import ModalAddIngresos from "./ModalAddIngresos";
import { getParameter, createParameter } from "../../Redux/parameterDuck";
import { getWorkersActives, getWorkersTurnActives } from "../../Redux/workerDuck";
import { toogleLoader } from "../../Redux/alertDuck";
import { getAllByRole } from "@testing-library/dom";
import { ToastProvider, useToasts, removeAllToasts } from "react-toast-notifications";
import { formatNumber } from "../../utils";
import ModalAddIngresosWorker from "./ModalAddIngresosWorker";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useParams } from "react-router-dom";
import ModalAddParameter from "../Components/ModalAddParameter";
import ModalAddTurn from "./modal.add.turn";
import TemplateMenu from "../../template/menu.template";
import ModalNovelty from "./modal.novelty";
import InputFieldCurrency from "../Components/Widgets/InputFieldCurrency";
import toast from "react-hot-toast";

const initState = {
  registration_date: "",
  aldelo: 0,
  intern: "",
  cancellations: "",
  cash: 0,
  datafono: 0,
  total_collection: 0,
  nequi: 0,
  daviplata: 0,
  rappi: 0,
  tips: 0,
  transfer: 0,
  comments: "",
  discount: "",
  amount_delivery: null,
  total_delivery: null,
  intern_total: null,
  total_input: ''
};

const q = `?f=true`;

const CreateIngresosWorkerPage = ({ errors, getParameter, parameters, createIngresos, fetching,
  getWorkersActives, workers, fetching_list, row_saved, getIngresoById, fetching_edit,
  updateIngresos, deletePayment, fetching_delete_payment, deletePaymentWorker, createParameter,
  fetching_parameter, fetching_create, fetching_delete_payment_worker, fetching_list_turn,
  getWorkersTurnActives, data_turn, toogleLoader, restartIngresos, deleteNoveltyTotal, updateIngresosRow,
  fetching_update, updateIngresosExtra, fetching_extra, extra, updateIngresosWorker, fetching_worker, move_worker,
  updateIngresosNovelty, fetching_novelty_create, novelty
}) => {
  const { addToast } = useToasts();
  const [form, setForm] = useState(initState);
  const [showModal, setShowModal] = useState(false);
  const [showModalTurn, setShowModalTurn] = useState(false);
  const [showModalWorker, setShowModalWorker] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [typeData, setTypeData] = useState(0);
  const [listIntern, setListIntern] = useState([]);
  const [listTurn, setListTurn] = useState([]);
  const [listEmploy, setListEmploy] = useState([]);
  const [listWorkers, setListWorkers] = useState([]);
  const [listWorkersTurn, setListWorkersTurn] = useState([]);

  const [listParameter, setListParameter] = useState([]);
  const [modalParam, setModalParam] = useState({
    open: false,
    title: "Pendiente",
  });
  const [lastParam, setLastParam] = useState({});

  const history = useHistory();
  const { id } = useParams();

  const [readOnly, setReadOnly] = useState(false);

  const [listNovelty, setListNovelty] = useState([]);
  const [modalNovelty, setModalNovelty] = useState(false);
  const [inputAsync, setInputAsync] = useState({});

  useEffect(() => {
    getParameter(q);
    getWorkersActives();
    getWorkersTurnActives();
  }, []);

  useEffect(() => {
    getIngresoById(id);
  }, [id]);

  useEffect(() => {
    if (fetching_novelty_create === 2) {
      setListNovelty([
        ...listNovelty,
        novelty
      ])
      setModalNovelty(false)
      toogleLoader(false)
    }
  }, [fetching_novelty_create]);

  useEffect(() => {
    if (fetching_extra === 2) {
      setListIntern([...listIntern, extra]);
      setShowModal(false);
      toogleLoader(false)
    }
  }, [fetching_extra]);

  useEffect(() => {
    if (fetching_worker === 2) {
      if (move_worker.is_turn !== true) {
        setListEmploy([...listEmploy, move_worker]);
        setShowModalWorker(false);
      } else {
        setListTurn([...listTurn, move_worker]);
        setShowModalTurn(false);
      }

      toogleLoader(false)
    }
  }, [fetching_worker]);

  useEffect(() => {
    if (fetching_update === 2) {
      setInputAsync({})
    }
  }, [fetching_update]);

  useEffect(() => {
    if (fetching_list_turn === 2) {
      setListWorkersTurn(data_turn);
    }
  }, [fetching_list_turn]);

  useEffect(() => {
    if (fetching_edit === 2) {
      if (row_saved.status_id === 2 || row_saved.status_id === 4) {
        setReadOnly(true);
      }

      setForm(row_saved);
      setListIntern(
        row_saved.payment_operation.filter((pay) => pay.type_payment === 1)
      );
      setListTurn(
        row_saved.payment_worker.filter((w) => w.worker.type_worker === 2)
      );
      setListEmploy(
        row_saved.payment_worker.filter((w) => w.worker.type_worker === 1)
      );

      setListNovelty(row_saved.novelty)
    }

    if (fetching_edit === 3) {
      restartIngresos()
      toast.error("¡Problemas para Guardar!")
      toogleLoader(false)
    }
  }, [fetching_edit]);

  useEffect(() => {

    if (fetching === 2) {
      toogleLoader(false)
      if (row_saved.status_id === 2) {
        history.push("/ingresos");
      } else {
        // addToast("Guardado con Exito", {
        //   appearance: "success",
        //   autoDismiss: true,
        // });

        setForm(row_saved);

        setListIntern(
          row_saved.payment_operation.filter((pay) => pay.type_payment === 1)
        );
        setListTurn(
          row_saved.payment_worker.filter((w) => w.worker.type_worker === 2)
        );
        setListEmploy(
          row_saved.payment_worker.filter((w) => w.worker.type_worker === 1)
        );

        setListNovelty(row_saved.novelty)
      }
    }
  }, [fetching]);

  useEffect(() => {
    if (fetching_parameter === 2) {
      setListParameter(
        parameters.filter((param) => param.segment === lastParam.segment)
      );
    }
  }, [fetching_parameter]);

  useEffect(() => {
    if (fetching_delete_payment_worker === 2) {
      // getIngresoById(id)
    }
  }, [fetching_delete_payment_worker]);

  useEffect(() => {
    if (fetching_delete_payment === 2) {

    }
  }, [fetching_delete_payment]);

  useEffect(() => {
    if (fetching_list === 2) {
      setListWorkers(workers);
    }
  }, [fetching_list]);

  useEffect(() => {
    if (fetching_create === 2) {
      setModalParam(false);
      getParameter(q);
    }
  }, [fetching_create]);

  const updateInput = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const onHide = () => {
    setShowModal(false);
  };

  const onHideTurn = () => {
    setShowModalTurn(false);
  };

  const onHideWorker = () => {
    setShowModalWorker(false);
  };

  const onOpenParameter = (title, dataType) => {
    let _title =
      dataType === 1 ? "Agregar otro Concepto" : "Pago otro Turneros";

    setModalParam({
      ...modalParam,
      open: true,
      title: _title,
      dataType,
    });
  };

  const showModalTmp = (title, segment, type) => {
    setTitleModal(title);
    setTypeData(type);

    let param = parameters.filter((row) => row.segment === segment);

    setListParameter(param);
    setShowModal(true);
  };

  const showModalTmpWorker = (type) => {
    setTypeData(type);
    setShowModalWorker(true);
  };

  const onSubmitModal = (row) => {
    let _form = form
    _form.payment_intern = row
    toogleLoader(true)
    updateIngresosExtra(_form);
  };

  const onSubmitModalTurn = (row) => {
    let _form = form
    _form.payment_worker = row
    toogleLoader(true)
    updateIngresosWorker(_form)


  };

  const onSubmitModalWorker = (row) => {
    let _form = form
    _form.payment_worker = row
    toogleLoader(true)
    updateIngresosWorker(_form)
  }

  const removeItem = (item) => {
    if (item.id !== undefined) {
      deletePayment(item);

      let data;
      if (item.type_payment === 1) {
        data = [...listIntern.filter((intern) => intern.id !== item.id)];
        setListIntern(data);
      }
      if (item.type_payment === 2) {
        data = [...listTurn.filter((intern) => intern.id !== item.id)];
        setListTurn(data);
      }
    } else {
      let data;
      if (item.type_data === 1) {
        data = [...listIntern.filter((intern) => intern._id !== item._id)];
        setListIntern(data);
      }
      if (item.type_data === 2) {
        data = [...listTurn.filter((intern) => intern._id !== item._id)];
        setListTurn(data);
      }
    }
  };

  const removeItemWorkerTurn = (item) => {
    if (item.id !== undefined) {
      let data;
      deletePaymentWorker(item);

      data = [...listTurn.filter((intern) => intern.id !== item.id)];
      setListTurn(data);
    } else {
      let data;
      data = [...listTurn.filter((intern) => intern._id !== item._id)];
      setListTurn(data);
    }
  };

  const removeItemWorker = (item) => {
    if (item.id !== undefined) {
      deletePaymentWorker(item);

      let _list = listEmploy.filter((row) => row.id !== item.id);
      setListEmploy(_list);
    } else {
      let _list = listEmploy.filter((emp) => emp._id !== item._id);

      setListEmploy(_list);
    }
  };

  const printIntern = (row, key) => {
    let { parameter } = row;

    return (
      <tr key={key}>
        <td>{parameter.description}</td>
        <td>{row.observation}</td>
        <td align="right">{formatNumber(row.amount)}</td>
        <td>
          <button className={`btn btn-sm btn-danger`} onClick={() => removeItem(row)}> X</button>
        </td>
      </tr>
    );
  };
  const printWorker = (row, key) => {
    let { worker } = row;

    return (
      <tr key={key}>
        <td>{worker.name}</td>
        <td align="right">{formatNumber(row.amount)}</td>
        <td>
          <button className={`btn btn-sm btn-danger`} onClick={() => removeItemWorker(row)}>X</button>
        </td>
      </tr>
    );
  };

  const printTurn = (row, key) => {
    let { worker } = row;

    return (
      <tr key={key}>
        <td>{worker.name}</td>
        <td align="right">{formatNumber(row.amount)}</td>
        <td>
          <button className={`btn btn-sm btn-danger`} onClick={() => removeItemWorkerTurn(row)}>X</button>
        </td>
      </tr>
    );
  };

  const onCreateParameter = (form) => {
    form.segment = form.dataType === 2 ? "payment_turn" : "payment_intern";
    form.priority = 1;
    setLastParam(form);
    createParameter(form);
  };

  const onSubmit = (status_id, status) => {
    let _form = form;
    _form.intern = listIntern;
    _form.turn = listTurn;
    _form.employee = listEmploy;
    _form.novelty = listNovelty;
    _form.status_id = status_id;
    _form.status = status;

    if (status_id === 2) {
      if (listNovelty.length === 0) {
        alert("Las novedades del día son Obligatorias")
        return;
      }
    }

    if (listIntern.length === 0) {
      if (!window.confirm(`¿Realmente deseas realizar el cierre sin PAGOS INTERNOS?`)) {
        return;
      }
    }

    toogleLoader(true)

    if (form.id !== undefined) {
      updateIngresos(_form);
    } else {
      createIngresos(_form);
    }
  };

  const onAddNovelty = (data) => {
    let _form = form
    _form.novelties = data
    updateIngresosNovelty(_form)
  }

  const updateInputAsync = (e) => {
    let value = (e.target.value).replaceAll(".", '')

    if (e.target.value !== '') {
      setInputAsync({
        ...inputAsync,
        [e.target.name]: value
      })

      let _form = {
        ...form,
        [`${e.target.name}_new`]: value
      }
      updateIngresosRow(_form);
    }
  }

  const onDeleteNovelty = (data) => {
    deleteNoveltyTotal(data)
    let _list = [...listNovelty.filter((d) => d.id !== data.id)];
    setListNovelty(_list)
  }

  let total_sales =
    form.aldelo -
    (listEmploy.reduce((a, b) => (a = a + parseFloat(b.amount)), 0) +
      listIntern.reduce((a, b) => (a = a + parseFloat(b.amount)), 0) +
      listTurn.reduce((a, b) => (a = a + parseFloat(b.amount)), 0));
  let total_recaudado =
    parseFloat(form.cash || 0) +
    parseFloat(form.datafono || 0) +
    parseFloat(form.nequi || 0) +
    parseFloat(form.daviplata || 0) +
    parseFloat(form.rappi || 0) +
    parseFloat(form.credit_home || 0);

  let diference = total_recaudado - total_sales;

  let total_intern = listIntern.reduce((a, b) => (a = a + parseFloat(b.amount)), 0) +
    listEmploy.reduce((a, b) => (a = a + parseFloat(b.amount)), 0) +
    listTurn.reduce((a, b) => (a = a + parseFloat(b.amount)), 0);

  return (
    <TemplateMenu>
      <Container fluid className="pb-5">
        <Row className="my-3">
          {readOnly === false && (
            <>
              <Col lg={9}>
                <button className="btn btn-sm btn-secondary mr-3" onClick={() => history.push("/ingresos")}>
                  Volver
                </button>
                {/* <button className="btn btn-info" onClick={() => onSubmit(1, 'nuevo')}>
                  Guardar
                </button> */}
              </Col>
              <Col lg={3}>
                <button className="btn btn-sm btn-success" onClick={() => onSubmit(2, 'editando')}>
                  Guardar y finalizar
                </button>
              </Col>
            </>
          )}
        </Row>
        <Row className="my-3">
          <Col>
            {row_saved.status_id === 4 && (
              <>
                <Row>
                  <Col lg={2}>Usuario</Col>
                  <Col>
                    <h5 className="text-capitalize">{row_saved.user.name}</h5>
                  </Col>
                </Row>
                <Row className="">
                  <Col lg={2}>Sede</Col>
                  <Col>
                    <h5>{row_saved.branch_office.title}</h5>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col lg={2}>Ultima actualización</Col>
                  <Col>
                    <h5>{row_saved.updated_at_formated}</h5>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
        <Row>
          <Col className="text-center"><b>FECHA DE CIERRE</b>
            <h2>{form.registration_date || ''}</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            {/* <Row>
              <Col lg={4}>Fecha</Col>
              <Col>
                <input
                  className="form-control"
                  type="date"
                  value={form.registration_date || ""}
                  name="registration_date"
                  min={moment().subtract(1, "d").format("YYYY-MM-DD")}
                  max={moment().format("YYYY-MM-DD")}
                  onChange={updateInput}
                  readOnly={readOnly}
                />
                {errors.registration_date !== undefined && (
                  <label className="error-label">
                    {errors.registration_date}
                  </label>
                )}
              </Col>
            </Row> */}
            <Row className="mt-2">
              <Col lg={4}>Ventas POS *</Col>
              <Col>
                <InputFieldCurrency
                  name={"aldelo"}
                  placeholder="Ventas POS"
                  value={form.aldelo}
                  onValueChange={(value, name) => setForm({ ...form, [name]: value })}
                  onBlur={updateInputAsync}
                  error={errors.aldelo}
                />
              </Col>
              {inputAsync.aldelo !== undefined && <Col lg={1}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </Col>}

            </Row>
            <Row className="mt-2">
              <Col lg={4}>Pagos Internos</Col>
              <Col>
                <InputField
                  placeholder="Pagos Internos"
                  readOnly={true}
                  textRight={true}
                  value={formatNumber(
                    listIntern.reduce(
                      (a, b) => (a = a + parseFloat(b.amount)),
                      0
                    )
                  )}
                  name="intern"
                  onChange={updateInput}
                  error={errors.intern}
                />
              </Col>
            </Row>
            <Row className="my-2">
              <Col lg={4}>Pagos Empleados</Col>
              <Col>
                <InputField placeholder="Pagos Empleados"
                  readOnly={true}
                  name="intern"
                  textRight={true}
                  value={formatNumber(listEmploy.reduce((a, b) => (a = a + parseFloat(b.amount)), 0))}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={4}>Pagos Turneros</Col>
              <Col>
                <InputField
                  placeholder="Pagos Turneros"
                  readOnly={true}
                  textRight={true}
                  value={formatNumber(
                    listTurn.reduce((a, b) => (a = a + parseFloat(b.amount)), 0)
                  )}
                  name="employ"
                />
              </Col>
            </Row>

            <Row className="mt-2">
              <Col lg={4}>Ventas Totales</Col>
              <Col>
                <InputField
                  placeholder="Ventas totales"
                  readOnly={true}
                  textRight={true}
                  name="total"
                  value={formatNumber(total_sales)}
                  onChange={updateInput}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={4}>Efectivo *</Col>
              <Col>
                <InputFieldCurrency
                  name={"cash"}
                  placeholder="Efectivo"
                  value={form.cash}
                  onValueChange={(value, name) => setForm({ ...form, [name]: value })}
                  onBlur={updateInputAsync}
                  error={errors.cash}
                />
                {/* <InputField
                  type="number"
                  placeholder="Efectivo"
                  value={form.cash || ""}
                  name="cash"
                  onChange={updateInput}
                  onBlur={updateInputAsync}
                  error={errors.cash}
                  readOnly={readOnly}
                /> */}
              </Col>
              {inputAsync.cash !== undefined && <Col lg={1}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </Col>}
            </Row>
            <Row className="my-1">
              <Col lg={4}>DataFono *</Col>
              <Col>
                <InputFieldCurrency
                  name={"datafono"}
                  placeholder="Datafono"
                  value={form.datafono}
                  onValueChange={(value, name) => setForm({ ...form, [name]: value })}
                  onBlur={updateInputAsync}
                  error={errors.datafono}
                />
              </Col>
              {inputAsync.datafono !== undefined && <Col lg={1}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </Col>}
            </Row>

            <Row>
              <Col lg={4}>Bancolombia</Col>
              <Col>
                <InputFieldCurrency
                  name={"nequi"}
                  placeholder="Bancolombia"
                  value={form.nequi}
                  onValueChange={(value, name) => setForm({ ...form, [name]: value })}
                  onBlur={updateInputAsync}
                  error={errors.nequi}
                />
              </Col>
              {inputAsync.nequi !== undefined && <Col lg={1}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </Col>}
            </Row>
            <Row className="my-1">
              <Col lg={4}>Davivienda</Col>
              <Col>
                <InputFieldCurrency
                  name={"daviplata"}
                  placeholder="Davivienda"
                  value={form.daviplata}
                  onValueChange={(value, name) => setForm({ ...form, [name]: value })}
                  onBlur={updateInputAsync}
                  error={errors.daviplata}
                />
              </Col>
              {inputAsync.daviplata !== undefined && <Col lg={1}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </Col>}
            </Row>
            <Row className="my-1">
              <Col lg={4}>Rappi</Col>
              <Col>
                <InputFieldCurrency
                  name={"rappi"}
                  placeholder="Rappi"
                  value={form.rappi}
                  onValueChange={(value, name) => setForm({ ...form, [name]: value })}
                  onBlur={updateInputAsync}
                  error={errors.rappi}
                />
              </Col>
              {inputAsync.rappi !== undefined && <Col lg={1}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </Col>}
            </Row>

            <Row className="my-1">
              <Col lg={4}>Credito de la Casa</Col>
              <Col>
                <InputFieldCurrency
                  name={"credit_home"}
                  placeholder="Credito de la casa"
                  value={form.credit_home}
                  onValueChange={(value, name) => setForm({ ...form, [name]: value })}
                  onBlur={updateInputAsync}
                  error={errors.credit_home}
                />
              </Col>
              {inputAsync.credit_home !== undefined && <Col lg={1}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </Col>}
            </Row>

            <Row className="mt-4">
              <Col lg={4}>Total Recaudo</Col>
              <Col>
                <InputField
                  placeholder="Total Recaudo"
                  value={formatNumber(total_recaudado || 0)}
                  readOnly={true}
                  textRight={true}
                  name="total_collection"
                  onChange={updateInput}
                  error={errors.total_collection}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg={4}>Diferencia</Col>
              <Col>
                <input
                  className={`form-control text-right ${diference > 0 ? "input-success" : "input-danger"
                    }`}
                  placeholder="Total Recaudo"
                  readOnly={true}
                  value={formatNumber(diference || 0)}
                  name="total_collection"
                  onChange={updateInput}
                  error={errors.total_collection}
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col lg={4}>Cantidad de Domicilios</Col>
              <Col>
                <InputFieldCurrency
                  name={"amount_delivery"}
                  placeholder="Cantidad Domicilios"
                  value={form.amount_delivery}
                  onValueChange={(value, name) => setForm({ ...form, [name]: value })}
                  onBlur={updateInputAsync}
                  error={errors.amount_delivery}
                />
              </Col>
              {inputAsync.amount_delivery !== undefined && <Col lg={1}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </Col>}
            </Row>
            <Row className="mt-2">
              <Col lg={4}>Monto Total Domicilios</Col>
              <Col>
                <InputFieldCurrency
                  name={"total_delivery"}
                  placeholder="Monto Total Domicilios"
                  value={form.total_delivery}
                  onValueChange={(value, name) => setForm({ ...form, [name]: value })}
                  onBlur={updateInputAsync}
                  error={errors.total_delivery}
                />
              </Col>
              {inputAsync.total_delivery !== undefined && <Col lg={1}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </Col>}
            </Row>
            <Row className="mt-2">
              <Col lg={4}>Número de Entradas</Col>
              <Col>
                <InputFieldCurrency
                  name={"total_input"}
                  placeholder="Número de Entradas"
                  value={form.total_input}
                  onValueChange={(value, name) => setForm({ ...form, [name]: value })}
                  onBlur={updateInputAsync}
                  error={errors.total_input}
                />
              </Col>
              {inputAsync.total_input !== undefined && <Col lg={1}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </Col>}
            </Row>

            <Row className="mt-3">
              <Col lg={4}>Cancelaciones</Col>
              <Col>
                <InputFieldCurrency
                  name={"cancellations"}
                  placeholder="Cancelaciones"
                  value={form.cancellations}
                  onValueChange={(value, name) => setForm({ ...form, [name]: value })}
                  onBlur={updateInputAsync}
                  error={errors.cancellations}
                />
              </Col>
              {inputAsync.cancellations !== undefined && <Col lg={1}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </Col>}
            </Row>

            <Row className="mt-2">
              <Col lg={4}>Propinas</Col>
              <Col>
                <InputFieldCurrency
                  name={"tips"}
                  placeholder="Propinas"
                  value={form.tips}
                  onValueChange={(value, name) => setForm({ ...form, [name]: value })}
                  onBlur={updateInputAsync}
                  error={errors.tips}
                />
              </Col>
              {inputAsync.tips !== undefined && <Col lg={1}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </Col>}
            </Row>

            <Row className="mt-2">
              <Col lg={4}>Descuentos</Col>
              <Col>
                <InputFieldCurrency
                  name={"discount"}
                  placeholder="Descuentos"
                  value={form.discount}
                  onValueChange={(value, name) => setForm({ ...form, [name]: value })}
                  onBlur={updateInputAsync}
                  error={errors.discount}
                />
              </Col>
              {inputAsync.discount !== undefined && <Col lg={1}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </Col>}
            </Row>

            <Row className="mt-2">
              <Col lg={4}>Comentarios</Col>
              <Col>
                <textarea
                  className="form-control"
                  name="comments"
                  onChange={updateInput}
                  value={form.comments || ''}
                  placeholder="Agrega Comentarios"
                  onBlur={updateInputAsync}
                ></textarea>
              </Col>
              {inputAsync.comments !== undefined && <Col lg={1}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </Col>}
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col>
                        <div className="form-group">
                          <label>TOTAL PAGOS INTERNOS POS</label>
                          <InputFieldCurrency
                            value={form.intern_total}
                            name={"intern_total"}
                            placeholder="Valor total PAGOS INTERNOS"
                            error={errors.intern_total}
                            onBlur={updateInputAsync}
                            onValueChange={(value, name) =>
                              setForm({ ...form, ["intern_total"]: value })
                            } />

                        </div>
                      </Col>
                      {inputAsync.intern_total !== undefined && <Col lg={1}>
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </Col>}
                    </Row>

                    <Row className="mb-1">
                      <Col>DIFERENCIA</Col>
                      <Col className={`${(form.intern_total - listIntern.reduce(
                        (a, b) => (a = a + parseFloat(b.amount)), 0)) > 0 ? 'text-danger' : 'text-success'} text-right`}>{formatNumber(form.intern_total - total_intern
                        )}</Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>Agregar Pagos Internos</Col>
              <Col>
                {readOnly === false && (
                  <button className="btn btn-info" onClick={() => showModalTmp("Pagos Internos", "payment_intern", 1)}>+</button>
                )}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <table className="table table-bordered table-striped table-sm">
                  <thead>
                    <tr>
                      <th>Descripción</th>
                      <th>Observación</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>{listIntern.map(printIntern)}</tbody>
                  <tfoot>
                    <tr className="table-info">
                      <td colSpan={2}>
                        <b>Total</b>
                      </td>
                      <td align="right">
                        {formatNumber(
                          listIntern.reduce((a, b) => (a = a + parseFloat(b.amount)), 0)
                        )}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>Agregar pago a Empleados</Col>
              <Col>
                {readOnly === false && (
                  <button className="btn btn-info" onClick={() => showModalTmpWorker(2)}>+</button>
                )}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <table className="table table-bordered table-striped table-sm">
                  <thead>
                    <tr>
                      <th>Descripción</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>{listEmploy.map(printWorker)}</tbody>
                  <tfoot>
                    <tr className="table-info">
                      <td>
                        <b>Total</b>
                      </td>
                      <td align="right">
                        {formatNumber(listEmploy.reduce((a, b) => (a = a + parseFloat(b.amount)), 0))}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </Col>
            </Row>

            <Row>
              <Col>Agregar pago a Turneros</Col>
              <Col>
                {readOnly === false && (
                  <button className="btn btn-info" onClick={() => setShowModalTurn(true)}>+</button>
                )}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <table className="table table-bordered table-striped table-sm">
                  <thead>
                    <tr>
                      <th>Descripción</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>{listTurn.map(printTurn)}</tbody>
                  <tfoot>
                    <tr className="table-info">
                      <td>
                        <b>Total</b>
                      </td>
                      <td>
                        {formatNumber(
                          listTurn.reduce(
                            (a, b) => (a = a + parseFloat(b.amount)), 0)
                        )}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col lg={9}>Novedades del día <span className="text-danger">(Obligatorio)</span></Col>
              <Col className="d-flex justify-content-end">
                <button className="btn btn-info" onClick={() => setModalNovelty(true)}>+</button>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <table className="table table-bordered table-striped table-sm">
                  <thead>
                    <tr>
                      <th>Descripción</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {listNovelty.length > 0 ? listNovelty.map((row, k) => (
                      <tr key={k}>
                        <td>{row.observation}</td>
                        <td>
                          <button className="btn btn-sm btn-danger" onClick={() => onDeleteNovelty(row)}>X</button>
                        </td>
                      </tr>
                    )) : <tr>
                      <td colSpan={2} align="center">No tienes Novedades</td>
                    </tr>}

                  </tbody>
                </table>
              </Col>
            </Row>
          </Col>
        </Row>
        <ModalAddTurn
          showModal={showModalTurn}
          onHide={onHideTurn}
          onSubmit={onSubmitModalTurn}
          listWorkerTurn={listWorkersTurn}
        />

        <ModalAddIngresos
          showModal={showModal}
          onHide={onHide}
          title={titleModal}
          typeData={typeData}
          onSubmit={onSubmitModal}
          onOpenParameter={onOpenParameter}
          parameters={listParameter}
        />

        <ModalAddIngresosWorker
          showModal={showModalWorker}
          onHide={onHideWorker}
          typeData={typeData}
          onSubmit={onSubmitModalWorker}
          workers={listWorkers}
        />

        <ModalAddParameter
          show={modalParam.open}
          title={modalParam.title}
          dataType={modalParam.dataType}
          onHide={() => setModalParam({ ...modalParam, open: false })}
          onSuccess={onCreateParameter}
        />

        <ModalNovelty show={modalNovelty} onHide={() => setModalNovelty(false)} onSubmit={onAddNovelty} />

      </Container>
    </TemplateMenu>
  );
};

const mapsState = (props) => {
  let { ingresos, parameter, worker } = props

  return {
    errors: ingresos.errors,
    selected: ingresos.selected,
    fetching_edit: ingresos.fetching_edit,
    fetching_update: ingresos.fetching_update,
    fetching_extra: ingresos.fetching_extra,
    extra: ingresos.extra,
    fetching_worker: ingresos.fetching_worker,
    move_worker: ingresos.worker,
    fetching_novelty_create: ingresos.fetching_novelty_create,
    novelty: ingresos.novelty,
    tag: ingresos.tag,
    row_saved: ingresos.row,
    fetching: ingresos.fetching_create,
    parameters: parameter.data,
    workers: worker.data,
    fetching_list: worker.fetching_list,
    fetching_delete_payment: ingresos.fetching_delete_payment,
    fetching_delete_payment_worker: ingresos.fetching_delete_payment_worker,
    fetching_parameter: parameter.fetching,
    fetching_create: parameter.fetching_create,
    fetching_list_turn: worker.fetching_list_turn,
    data_turn: worker.data_turn,
  };
};

export default connect(mapsState, {
  createIngresos,
  getParameter,
  getWorkersActives,
  getIngresoById,
  updateIngresos,
  deletePayment,
  deletePaymentWorker,
  createParameter,
  getWorkersTurnActives,
  toogleLoader,
  restartIngresos,
  deleteNoveltyTotal,
  updateIngresosRow,
  updateIngresosExtra,
  updateIngresosWorker,
  updateIngresosNovelty
})(withRouter(CreateIngresosWorkerPage));
