import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import PaginationTable from '../Components/PaginationTable';
import SearchSection from '../Components/SearchSection';
import ModalWorkerTurn from './modal.worker.turn';
import RowTableWorkerTurn from './row.table.worker.turn';
import { getParameterBySegment } from '../../Redux/parameterDuck'
import { getWorkersTurn } from '../../Redux/workerDuck'


const SegmentWorkerTurn = ({ workers, getWorkersTurn, fetching_list, getParameterBySegment, segment, data_segment, fetching_by_segment,
    listCountry, listArea, listPosition, user }) => {

    const [showModal, setShowModal] = useState(false);
    const [form, setForm] = useState({})
    const [list, setList] = useState([]);

    useEffect(() => {
        let q = "?type_worker=2"
        getWorkersTurn(q)
        getParameterBySegment("country")
    }, [])

    useEffect(() => {
        if (fetching_list === 2) {
            setList(workers)
        }
    }, [fetching_list])



    const showModalTmp = () => {
        setShowModal(true);
        setForm({})
        // clearSelected()
    }

    const filterContent = (txt) => {
        let _list = list

        _list = list.filter(row => row.name.toLowerCase().includes(txt) || row.document.includes(txt))

        setList(_list)

    }

    const onCleanFilter = () => {
        setList(workers)
    }

    const onEdit = (item) => {
        setForm(item)
        setShowModal(true)

    }

    const onSuccess = () => {
        let q = "?type_worker=2"
        getWorkersTurn(q)
    }

    const onHide = () => {
        setShowModal(false)

    }



    return (
        <Container fluid>
            <Row className='my-1'>
                <Col lg={6}>
                    {user.role_id === 1 && <button className='btn btn-info' onClick={showModalTmp}>Crear Turnero</button>}

                </Col>


                <Col lg={6} className="pr-4 justify-content-end d-flex align-items-center">
                    <SearchSection
                        placeholder='Buscar Turnero'
                        value=''
                        name='search_worker'
                        onClick={filterContent}
                        onClean={onCleanFilter}
                        list={[{ id: 1, title: "Semanal" }]}
                    >

                    </SearchSection>


                </Col>
            </Row>

            <Row className="">
                <Col>
                    <table className="table table-bordered table-sm">
                        <thead className='thead-dark'>
                            <tr>
                                <th>Nombres</th>
                                <th>Documento</th>
                                <th>Nacionalidad</th>
                                <th>Cargo</th>
                                <th>Sucursal</th>
                                <th>Area</th>
                                <th>Estado</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(list.length > 0) ? list.map(row => <RowTableWorkerTurn key={row.id} item={row} onEdit={onEdit} />)
                                : <tr><td colSpan={8}>No se Encontraron Datos</td></tr>}
                        </tbody>


                    </table>

                    {/* <PaginationTable /> */}
                </Col>
            </Row>

            <ModalWorkerTurn
                user={user}

                showModal={showModal}
                formEdited={form}
                onHide={onHide}
                onSuccess={() => onSuccess()}
                listCountry={listCountry}
                listPosition={listPosition}
                listArea={listArea}
            />
        </Container>
    )
}

const mapsState = (props) => {
    let { worker, parameter, user } = props
    return {
        workers: worker.data_turn,
        fetching_list: worker.fetching_list_turn,
        fetching_by_segment: parameter.fetching_by_segment,
        segment: parameter.segment,
        data_segment: parameter.data_segment,
        user: user.data

    }
}


export default connect(mapsState, { getParameterBySegment, getWorkersTurn })(SegmentWorkerTurn);