import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import uuid from "react-uuid";
import { debounceData, formatNumber, getHeaders } from "../../utils";
import { getLastInventory } from '../../Redux/inventoryDuck'
import { connect } from "react-redux";
const API_URL = process.env.REACT_APP_API_URL;

const initState = { amount: "", type_product_id: 1 };
const initStateSelected = {
  measure_unit: "",
  cost_average: 0,
  inventory: { amount_total: 0 },
  cost_average: 0
};

const RowProductPrescription = ({ product, onCancel, onSucccess, onEditItem, isModifier, getLastInventory, registration_date, fetching_by_id, row }) => {
  const [form, setForm] = useState(initState);
  const [type, setType] = useState("");
  const [listSearch, setListSearch] = useState([]);
  const [selected, setSelected] = useState(initStateSelected);
  const [selectedProduct, setSelectedProduct] = useState({
    tax: { description: "" },
    measure_unit: { description: "" },
  });
  const [showButton, setShowButton] = useState(false);
  const [productSearch, setProductSearch] = useState("");
  const debounceRef = useRef();


  useEffect(() => {
    if (fetching_by_id === 2) {
      setSelected({
        ...selected,
        ["cost_average"]: row.cost_average
      })
    }
  }, [fetching_by_id])

  useEffect(() => {
    if (Object.keys(product).length > 0) {
      setForm(product);
      setSelectedProduct(product.product);
      setShowButton(true);
      setProductSearch({
        label: product.product.name,
        value: product.product.id,
      });
    }
  }, [product]);

  const loadOptions = async (inputValue) => {

    if (debounceRef.current)
      clearTimeout(debounceRef.current)

    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));
    let branch = JSON.parse(localStorage.getItem("branch"));
    let url = "";
    url = `${API_URL}/api/product-inventory-search/${inputValue}?date_init=${date_init}&date_end=${date_end}&branch=${branch.id}`

    return debounceData(debounceRef, url);
  };

  const updateField = (e) => {
    if (e.target.type === "checkbox") {
      setForm({
        ...form,
        [e.target.name]: e.target.checked === true ? 2 : 1,
      });
    } else {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // 👇 Get input value
      let _form = {
        ...form,
        [event.target.name]: event.target.value,
      };
      setForm(_form);

      if (form.id === undefined) {
        onSaveRow(_form);
      } else {
        onEditItem(_form);
      }
    }
  };

  const onSaveRow = () => {
    let { prescription } = selectedProduct;

    if (form.amount === "") {
      alert("Por favor ingresa la cantidad");
      return;
    }

    let _form = form;
    _form._id = uuid();
    _form.product_inventory = selected;
    _form.measure_unit_id = selected.measure_unit_id;
    _form.product_id = selected.id;
    _form.product = selectedProduct.name;

    onSucccess(_form);
  };


  console.log('selected', selected);


  return (
    <tr>
      <td width={"35%"}>
        <AsyncSelect
          value={productSearch}
          placeholder="Busca el Producto agregar"
          loadOptions={loadOptions}
          onChange={(e) => {
            // setSelectedProduct(_filter);
            setSelectedProduct(e)
            getLastInventory(e.id, registration_date)
            setShowButton(true);
            setSelected(e);
            setProductSearch({ label: e.label, value: e.id });
          }}
        />
      </td>
      <td>{selected.measure_unit}</td>
      <td>{formatNumber(selected.cost_average)}</td>
      <td>
        <input
          type={"number"}
          className="form-control" name="amount"
          placeholder="Cantidad para la receta"
          value={form.amount}
          onChange={updateField}
          onKeyDown={handleKeyDown}
        />
      </td>
      <td>{formatNumber(selected.cost_average || 0 * form.amount)}</td>
      <td>
        <input type={"checkbox"} className="form-control input-sm" name="type_product_id" value={form.type_product_id}
          onChange={updateField}
        />
      </td>

      {showButton && (
        <td>
          {form._id !== undefined ? (
            <button type="button" className="btn btn-sm btn-info mx-2" onClick={() => onEditItem(form)}
            >
              Editar
            </button>
          ) : (
            <button type="button" className="btn btn-sm btn-success mx-2" onClick={() => onSaveRow(form)}>
              Guardar
            </button>
          )}

          <button className="btn btn-sm btn-danger" onClick={onCancel}>
            Cancelar
          </button>
        </td>
      )}
    </tr>
  );
};

const mapsState = (props) => {
  let { inventory } = props
  return {
    fetching_by_id: inventory.fetching_by_id,
    row: inventory.row,
  }
}

export default connect(mapsState, { getLastInventory })(RowProductPrescription);
