import React from 'react'
import { Col, Container, Form, FormControl, Nav, NavDropdown, Navbar, Row } from 'react-bootstrap';
import img from '../../Images/construccion.jpeg'
const LandingPage = () => {
    return (
        <>
            <Navbar bg="dark" variant="dark" expand="lg">
                <Navbar.Brand href="/">Onix Control</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link href="#link">Productos</Nav.Link>
                        <Nav.Link href="#link">Clientes</Nav.Link>
                        <Nav.Link href="#link">Nosotros</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text>
                        <a href="/login">Iniciar Sesion</a>
                    </Navbar.Text>
                </Navbar.Collapse>
            </Navbar>
            <Container fluid>
                <Row className='h-100'>
                    <Col className='text-center'><h3>En Construcción</h3></Col>
                </Row>
                <Row className='d-flex justify-content-center'>
                    <Col lg={5}>
                        <img src={img} className='img-fluid rounded' />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default LandingPage;