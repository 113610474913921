import moment from "moment";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { AiFillEdit } from "react-icons/ai";


const ItemCouponClient = ({ item, onSuccess, showModalClient }) => {
  let { client } = item;
  const [show, setShow] = useState(false);
  const [invoice, setInvoice] = useState("");

  const onSaveInvoiceTmp = () => {
    item.invoice = invoice;
    onSuccess(item);
    setShow(false);
  };

  const onEditRow = () => {
    setShow(true);
    setInvoice(item.invoice);
  };

  return (
    <tr>
      <td>{client.business} <AiFillEdit className="cursor-pointer" onClick={() => showModalClient(client)} /></td>
      <td>{client.email}</td>
      <td>{client.phone}</td>
      <td>{item.code}</td>
      <td>{item.registration_date}</td>
      <td>{moment(item.updatedAt).format("YYYY-MM-DD hh:mm") }</td>
      <td>{item.status_text}</td>
      <td>
        {show === true ? (
          <Row>
            <Col lg={8}>
              <input
                className="form-control"
                placeholder="Agrega el numero de factura"
                value={invoice}
                onChange={(e) => setInvoice(e.target.value)}
              />
            </Col>
            <Col>
              <button
                className="btn btn-success btn-sm"
                onClick={onSaveInvoiceTmp}
              >
                Guardar
              </button>
              <button className="btn btn-warning btn-sm mx-2">Cancelar</button>
            </Col>
          </Row>
        ) : (
          item.invoice
        )}
      </td>
      <td>
        {/* {show !== true && <button className='btn btn-info btn-sm' onClick={onEditRow}>Redimir</button>} */}
        {item.status_id === 1 && show !== true && (
          <button className="btn btn-info btn-sm" onClick={onEditRow}>
            Redimir
          </button>
        )}
      </td>
    </tr>
  );
};

export default ItemCouponClient;
