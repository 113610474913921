import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import RowProduct from './row.product';
import ItemDetailPurchase from './item.detail.purchase';
import { formatNumber } from "../../utils";
import ModalReviewPurchase from './modal.review.purchase';
import { onCreatePurchasesRow, getPurchaseById, onEditPurchasesRow, onDeletePurchasesRow, onEditPurchases } from '../../Redux/purchaseDuck'
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toogleLoader } from '../../Redux/alertDuck'
const initState = {
    id: '',
    supplier_id: 0, registration_date: moment().format("YYYY-MM-DD"), date_init: "",
    date_end: "", amount: 0, total: 0
}

const FormPurchase = ({ errors, listSupplier, onCreate, formEdit, onEdit, onCreatePurchasesRow, fetching_create_row, row,
    getPurchaseById, fetching_by_id, onEditPurchasesRow, onDeletePurchasesRow, toogleLoader, onEditPurchases, isLock }) => {

    const [form, setForm] = useState(initState);
    const [selectedProduct, setSelectedProduct] = useState({});
    const [selected, setSelected] = useState({ business: '' });
    const [listProduct, setListProduct] = useState([]);
    const [listDetail, setListDetail] = useState([]);
    const [show, setShow] = useState(false);
    const [modal, setModal] = useState(false);
    const [modalHeader, setModalHeader] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const history = useHistory();

    useEffect(() => {

        if (formEdit !== undefined) {
            let { purchase_product } = formEdit
            if (purchase_product !== undefined) {
                setListDetail(purchase_product)
            }

            setSelected(formEdit.supplier)

            setForm(formEdit)
            setIsEdit(true)
        } else {
            setIsEdit(false)

        }
    }, [formEdit])

    useEffect(() => {
        if (fetching_by_id === 2) {

            let { purchase_product } = row
            if (purchase_product !== undefined) {
                setListDetail(purchase_product)
            }

            if (Object.keys(row).length > 0) {
                setSelected(row.supplier)
                setForm(row)
            }

            toogleLoader(false)

        }
    }, [fetching_by_id])


    useEffect(() => {
        if (fetching_create_row === 2) {
            setForm({
                ...form,
                ["id"]: row.id
            });

            window.history.pushState({}, null, row.id);

            if (row.id !== undefined) {
                getPurchaseById(row.id)
            }
        }
    }, [fetching_create_row])


    useEffect(() => {
        let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));
        let _month = moment(date_init).format("MM");
        let _current_month = moment().format("MM");
        let _form = {
            ...form,
            ["date_init"]: date_init,
            ["date_end"]: date_end,
        };
        if (_month !== _current_month) {
            _form.registration_date = date_init;
        }

        setForm(_form);
    }, []);


    const onEditRow = (item) => {
        setShow(true);
        setSelectedProduct(item);
    }

    const onEditItem = (item) => {
        let _list = listDetail.map((d) => {
            if (item._id === d._id) {
                d.amount = item.amount;
                d.cost = item.cost;
            }
            return d;
        });

        setListDetail(_list);
        setSelectedProduct({});
        setShow(false);
    }

    const onAddProduct = () => {

        if (Object.keys(selectedProduct).length > 0) {
            let _list = [...listDetail, selectedProduct];
            setListDetail(_list);

            setSelectedProduct({});

            setShow(false);
        } else {
            setShow(true);
        }
    }

    const onSuccessFast = () => {
        if (listDetail.length > 0) {
            if (form.supplier_id === 0) {
                alert("Selecciona un proveedor");
                return
            }
            setModal(true);
        } else {
            alert("No puedes crear una orden vacia");
        }
    };


    const onAddItem = (row) => {
        toogleLoader(true)
        form.row = row
        onCreatePurchasesRow(form)

        // let _list = [...listDetail, item];

        // let _total = _list.reduce(
        //     (a, b) => (a = a + parseFloat(b.amount) * parseFloat(b.cost)),
        //     0
        // );

        // setTotal(_total);

        // setListDetail(_list);
        // setShow(false);
    }


    const onDeleteItem = (item) => {
        if (window.confirm(`¿Realmente deseas Eliminar (${item.product.name}), Cant: (${item.amount})?`)) {
            toogleLoader(true)
            onDeletePurchasesRow(item)
        }
    }

    const updateInput = (e) => {
        if (!isLock) {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }


        // if (e.target.name === "supplier_id") {
        //     let _selected = listSupplier.filter(
        //         (s) => s.id === parseInt(e.target.value)
        //     )[0];
        //     setSelected(_selected);

        //     let _products = _selected.products.map((p) => {
        //         p.label = p.name;
        //         p.value = p.id;
        //         return p;
        //     });
        //     setListProduct(_products);
        // }

        if (e.target.name === 'registration_date' && form.id !== '') {
            if (isLock) {
                alert("Edición Bloqueada")
                return;
            }
            let _form = form
            _form.registration_date = e.target.value

            toogleLoader(true)
            onEditPurchases(form)
        }

        if (e.target.name === 'supplier_id' && form.id !== '') {
            let _form = form
            _form.supplier_id = e.target.value
            toogleLoader(true)
            onEditPurchases(form)
        }
    }

    const onSuccess = () => {
        if (listDetail.length > 0) {
            // let _form = form;
            // _form.list_products = listDetail;
            // onCreatePurchases(_form);
        } else {
            alert("No puedes crear una orden vacia");
        }
    }

    const onConfirmPurchase = () => {
        let _form = form;
        _form.list_products = listDetail;
        // onCreateDirectPurchases(_form);

        if (form.id === undefined) {
            onCreate(_form)
        } else {
            onEdit(_form)
        }
    }


    const onSaveRow = (row) => {
        onEditPurchasesRow(row)
    }

    const onGoBack = () => {
        setListDetail([])
        setForm({ id: '' })
        history.push("/compras")
    }

    const onShowHeader = () => {
        setModalHeader(true)
    }

    return (
        <>
            <Row className="my-1">
                <Col>
                    <button className="btn btn-sm btn-secondary" onClick={onGoBack}>
                        Volver
                    </button>
                </Col>
            </Row>
            <Row>
                <Col className='text-center'>
                    <h4>ORDEN DE COMPRA #{form.id === '' ? 'Nuevo' : form.id}</h4>
                </Col>
            </Row>
            <Row>
                <Col lg={7}>
                    <Row className='mt-1'>
                        <Col>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Fecha de Registro</label>
                                        <input
                                            type={"date"}
                                            min={form.date_init}
                                            max={form.date_end}
                                            className="form-control"
                                            name="registration_date"
                                            value={form.registration_date}
                                            onChange={updateInput}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Proveedor</label>
                                        <select className="form-control" name="supplier_id" value={form.supplier_id}
                                            onChange={updateInput}>
                                            <option value="0">Seleccione</option>
                                            {listSupplier.map((m) => (
                                                <option key={m.id} value={m.id}>
                                                    {m.business}
                                                </option>
                                            ))}
                                        </select>
                                        {errors.supplier_id !== undefined && (
                                            <label className="error-label">
                                                {errors.supplier_id}
                                            </label>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row className='text-center my-1'>
                                <Col>RESUMEN</Col>
                            </Row>
                            <Row className='border-bottom'>
                                <Col>ITEMS</Col>
                                <Col className='text-right'><h5>{formatNumber(form.amount)}</h5></Col>
                            </Row>
                            <Row className='border-bottom'>
                                <Col>TOTAL</Col>
                                <Col className='text-right'><h5>{formatNumber(form.total)}</h5></Col>
                            </Row>

                        </Col>
                    </Row>

                </Col>
                <Col>
                    <Card>
                        <Card.Header>
                            <Card.Title>Información Proveedor</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            {selected !== undefined && <>
                                <Row>
                                    <Col>
                                        <b>Razon Social</b>
                                    </Col>
                                    <Col>{selected.business}</Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <b>Documento</b>
                                    </Col>
                                    <Col>{selected.document}</Col>
                                </Row>
                            </>}

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="mt-2" >
                <Col>


                </Col>
                <Col>

                </Col>
            </Row >
            <Row className="mt-4">
                <Col className="">
                    <table className="table table-sm table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                <th>Producto</th>
                                {/* {!isEdit && <th>Costo Promedio</th>} */}
                                <th>Costo Promedio</th>
                                <th>Cantidad</th>
                                <th align="right">Costo Unit</th>
                                {/* <th align="right">Imp</th> */}
                                <th align="right">SubTotal</th>
                                <th></th>
                            </tr>
                            {!isLock && <RowProduct
                                registration_date={form.registration_date}
                                onEditItem={onEditItem}
                                formEdit={selectedProduct}
                                listProduct={listProduct}
                                onAddItem={onAddItem}
                                onDelete={() => {
                                    setSelectedProduct({});
                                    setShow(false);
                                }}
                                supplier_id={form.supplier_id}
                            />}

                        </thead>
                        <tbody>
                            {listDetail.filter(d => d.deleted === undefined).length !== 0 && <tr>
                                <td className='table-success' colSpan={7}></td>
                            </tr>}

                            {listDetail.filter(d => d.deleted === undefined).length !== 0 ? listDetail.filter(d => d.deleted === undefined).map((d, key) => (
                                <ItemDetailPurchase
                                    item={d}
                                    key={key}
                                    onEdit={onEditRow}
                                    onDelete={onDeleteItem}
                                    listProduct={listProduct}
                                    isEdit={isEdit}
                                    onSaveRow={onSaveRow}
                                    isLock={isLock}
                                />
                            )) : <tr><td colSpan={7} align='center'><h4>No tienes Registros</h4></td></tr>}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th align="right" colSpan={2}>TOTAL</th>
                                <th className="text-right">{formatNumber(form.amount)}</th>
                                <th></th>
                                <th></th>
                                <th className="text-right">{formatNumber(form.total)}</th>
                                <th></th>
                            </tr>
                        </tfoot>
                    </table>
                </Col>
            </Row>
            <ModalReviewPurchase
                show={modal}
                listDetail={listDetail}
                onSuccess={onConfirmPurchase}
                onHide={() => setModal(false)}
            />
        </>
    )
}

const mapsState = (props) => {
    let { supplier, purchase } = props;
    return {
        fetching_list: supplier.fetching_list,
        suppliers: supplier.data,
        errors: supplier.errors,
        fetching_by_id: purchase.fetching_by_id,
        fetching_create: purchase.fetching_create,
        fetching_create_row: purchase.fetching_create_row,
        row: purchase.row,
    }
}
export default connect(mapsState, {
    onCreatePurchasesRow, getPurchaseById, onEditPurchasesRow, onDeletePurchasesRow,
    onEditPurchases, toogleLoader
})(FormPurchase);