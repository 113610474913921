import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap';
import InputField from '../Components/Widgets/InputField';

const iniState = { name: '' };

const ModalCategoryRecipe = ({ show, onHide, onCreate, onEdit, formEdit }) => {
    const [form, setForm] = useState(iniState)

    useEffect(() => {

        if (Object.keys(formEdit).length > 0) {
            setForm(formEdit)
        }
    }, [formEdit])

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSuccess = () => {

        if (form.id !== undefined) {
            onEdit(form)
        } else {
            onCreate(form)
        }

        setForm(iniState)
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Categoria</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Titulo Categoría</label>
                            <InputField
                                placeholder="Titulo de la Categoría"
                                value={form.name || ''}
                                name="name"
                                onChange={updateInput}
                            // error={errors.title}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Orden</label>
                            <InputField
                                type={"number"}
                                placeholder="Order"
                                value={form.priority || ''}
                                name="priority"
                                onChange={updateInput}
                            // error={errors.title}
                            />
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col><button className='btn btn-sm btn-success' onClick={onSuccess}>Crear</button></Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalCategoryRecipe;