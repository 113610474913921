import React, { useEffect, useState } from 'react'
import { Carousel, Col, Container, Row } from 'react-bootstrap';
import ItemFullScreen from './item.full_screen';

const API_URL = process.env.REACT_APP_API_URL


const FullScreenPage = ({ show, onClick, selected }) => {

    let { detail } = selected

    return (
        <Container fluid className={`fill-window ${show ? '' : 'd-none'}`}>
            <Row>
                <Col>
                    <button onClick={onClick}>Close</button>
                </Col>
            </Row>
            <Row>
                <Col >
                    {detail !== undefined && <Carousel >
                        {detail.map(d => <Carousel.Item key={d.id}>
                            <img
                                className={`d-block vh-100`}
                                src={API_URL + d.url}
                                alt="First slide"
                            />
                            {/* <Carousel.Caption>
                <h3>First slide label</h3>
                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption> */}
                        </Carousel.Item>)}


                    </Carousel>}

                </Col>
            </Row>
        </Container>
    )
}

export default FullScreenPage;