import axios from 'axios'
import { getHeaders } from '../utils';
const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    fetching_delete: 0,
    fetching_detail: 0,
    data: [],
    row: {},
    schedules: [],
    selected: {},
    errors: {},
    hours: [],
    detail: []
}

let DINING = "DINING";
let DINING_SUCCESS = "DINING_SUCCESS";

let CATEGORY_ERROR = "CATEGORY_ERROR";
let CATEGORY_SELECTED = "CATEGORY_SELECTED";
let HOURS = "HOURS";
let HOURS_SUCCESS = "HOURS_SUCCESS";
let DINING_SAVE = "DINING_SAVE";
let DINING_SAVE_SUCCESS = "DINING_SAVE_SUCCESS";
let DINING_ERROR = "DINING_ERROR";
let DINING_CLEAR = "DINING_CLEAR";


let SCHEDULE = "SCHEDULE";
let SCHEDULE_SUCCESS = "SCHEDULE_SUCCESS";
let DETAIL_DINING = "DETAIL_DINING";
let DETAIL_DINING_SUCCESS = "DETAIL_DINING_SUCCESS";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case DINING:
            return { ...state, fetching_list: 1 }
        case DINING_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        case DINING_SAVE:
            return { ...state, fetching: 1 }
        case DINING_SAVE_SUCCESS:
            return { ...state, fetching: 2 }

        case DINING_ERROR:
            return { ...state, errors: action.payload, fetching: 3, selected: action.form }
        case CATEGORY_SELECTED:
            return { ...state, fetching: 0, selected: action.payload }
        case DINING_CLEAR:
            return { ...state, fetching: 0, selected: {}, errors: {} }

        case HOURS:
            return { ...state, fetching: 1 }
        case HOURS_SUCCESS:
            return { ...state, fetching: 2, hours: action.payload }

        case SCHEDULE:
            return { ...state, fetching_delete: 1 }
        case SCHEDULE_SUCCESS:
            return { ...state, fetching_delete: 2, row: action.payload }
        case DETAIL_DINING:
            return { ...state, fetching_detail: 1 }
        case DETAIL_DINING_SUCCESS:
            return { ...state, fetching_detail: 2, detail: action.payload }
        default:
            return state;
    }
}


export let getDetailDining = (active) => (dispatch, getState) => {
    // dispatch({
    //     type: DETAIL_DINING
    // })

    // if (getState().user.loggedIn) {

    //     axios.get(`${API_URL}/api/dining-table-detail/${active.id}`, {
    //         headers: getHeaders()

    //     }).then(resp => {
    //         dispatch({
    //             type: DETAIL_DINING_SUCCESS,
    //             payload: resp.data
    //         });

    //     }).catch(function (error) {
    //         console.log(error);
    //     });
    // } else {
    //     console.log("no Ingreso");
    // }
}


export let getDiningId = (id) => (dispatch, getState) => {
    dispatch({
        type: DETAIL_DINING
    })

    if (getState().user.loggedIn) {

        axios.get(`${API_URL}/api/dining-table/${id}`, {
            headers: getHeaders()

        }).then(resp => {

            dispatch({
                type: DETAIL_DINING_SUCCESS,
                payload: resp.data.results
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let setSelectedItem = (item) => (dispatch, getState) => {
    dispatch({
        type: CATEGORY_SELECTED,
        payload: item
    })
}
export let clearSelected = () => (dispatch, getState) => {
    dispatch({
        type: DINING_CLEAR
    })
}



export let getDiningTables = () => (dispatch, getState) => {
    dispatch({
        type: DINING
    })

    if (getState().user.loggedIn) {

        axios.get(`${API_URL}/api/dining-table`, {
            headers: getHeaders()

        }).then(resp => {
            dispatch({
                type: DINING_SUCCESS,
                payload: resp.data.results
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let getDiningTableId = (item) => (dispatch, getState) => {
    dispatch({
        type: DINING
    })

    if (getState().user.loggedIn) {

        axios.get(`${API_URL}/api/dining-table/${item.id}`, {
            headers: getHeaders()

        }).then(resp => {
            dispatch({
                type: DINING_SUCCESS,
                payload: resp.data.results
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let getDiningTablesAdmin = () => (dispatch, getState) => {
    dispatch({
        type: DINING
    })

    if (getState().user.loggedIn) {

        axios.get(`${API_URL}/api/dining-table-admin`, {
            headers: getHeaders()

        }).then(resp => {
            dispatch({
                type: DINING_SUCCESS,
                payload: resp.data.results
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let getDiningTablesReservation = () => (dispatch, getState) => {
    dispatch({
        type: DINING
    })

    if (getState().user.loggedIn) {

        axios.get(`${API_URL}/api/reservation/dining-table`, {
            headers: getHeaders()

        }).then(resp => {
            dispatch({
                type: DINING_SUCCESS,
                payload: resp.data.results
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let getHours = () => (dispatch, getState) => {
    dispatch({
        type: HOURS
    })

    if (getState().user.loggedIn) {

        axios.get(`${API_URL}/api/list-dates/1`, {
            headers: getHeaders()

        }).then(resp => {

            dispatch({
                type: HOURS_SUCCESS,
                payload: resp.data.results.hours
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}



export let createDining = (form) => (dispatch, getState) => {

    dispatch({
        type: DINING_SAVE
    })

    let cont = 0;

    let err = {}

    if (form.people === '' || form.people === undefined) {
        err.people = "Cantidad de Personas Requerida";
        cont++;
    }

    if (form.name === '' || form.name === undefined) {
        err.people = "El nombre es requerido";
        cont++;
    }

    if (cont > 0) {
        dispatch({
            type: DINING_ERROR,
            payload: err,
            form: form
        })
        return
    }


    let url = `${API_URL}/api/dining-table`;

    let branch = JSON.parse(localStorage.branch)

    form.branch_id = branch.id

    if (getState().user.loggedIn) {

        axios.post(url, form, {
            headers: getHeaders()

        }).then(resp => {

            if (resp.data.status === false) {
                err.position = "Mesa ya existe";
                dispatch({
                    type: DINING_ERROR,
                    payload: err,
                    form: form
                })
                return;
            } else {
                dispatch({
                    type: DINING_SAVE_SUCCESS,
                });
            }



        }).catch(error => {

            for (const [key, value] of Object.entries(error.response.data.errors)) {
                if ('position' === key) {
                    err.position = value[0]
                }
                if ('people' === key) {
                    err.people = value[0]
                }
            }

            dispatch({
                type: DINING_ERROR,
                payload: err,
                form: form
            })
        });
    } else {
        console.log("no Ingreso");
    }
}

export let updateDining = (form) => (dispatch, getState) => {


    dispatch({
        type: DINING_SAVE
    })

    let url = `${API_URL}/api/dining-table/${form.id}`;


    if (getState().user.loggedIn) {

        axios.put(url, form, {
            headers: getHeaders()

        }).then(resp => {
            dispatch({
                type: DINING_SAVE_SUCCESS
            });

        }).catch(error => {
            let err = {}

            for (const [key, value] of Object.entries(error.response.data.errors)) {
                if ('position' === key) {
                    err.position = value[0]
                }
                if ('people' === key) {
                    err.people = value[0]
                }
            }

            dispatch({
                type: CATEGORY_ERROR,
                payload: err,
                form: form
            })
        });
    } else {
        console.log("no Ingreso");
    }
}

export let deleteSchedule = (item) => (dispatch, getState) => {


    dispatch({
        type: SCHEDULE
    })

    let url = `${API_URL}/api/dining-table-schedule/${item.id}`;


    if (getState().user.loggedIn) {

        axios.delete(url, {
            headers: getHeaders()

        }).then(resp => {
            dispatch({
                type: SCHEDULE_SUCCESS,
                payload: item
            });

        }).catch(error => {
            let err = {}

            for (const [key, value] of Object.entries(error.response.data.errors)) {
                if ('position' === key) {
                    err.position = value[0]
                }
                if ('people' === key) {
                    err.people = value[0]
                }
            }

            dispatch({
                type: CATEGORY_ERROR,
                payload: err
            })
        });
    } else {
        console.log("no Ingreso");
    }
}



