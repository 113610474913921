import React from 'react'
import { Link } from 'react-router-dom';
import { formatNumber } from '../../utils';

const ItemPrescriptionTable = ({ item, isDetail, index, show_columns }) => {
    let { detail } = item

    let margin_table = item.price > 0 ? (100 - (item.total_table / item.price) * 100).toFixed(2) : 1
    let margin_table_text = item.price > 0 ? `(${margin_table}%)` : 'N/A'
    let margin_delivery = item.price > 0 ? (100 - (item.total_delivery / item.price) * 100).toFixed(2) : 1
    let margin_delivery_text = item.price > 0 ? `(${margin_delivery}%)` : 'N/A'

    let colorTable = '', colorDelivery = ''
    if (margin_table > 75) {
        colorTable = "text-success"
    } else if (margin_table > 50 && margin_table < 75) {
        colorTable = "text-info"
    } else if (margin_table > 40 && margin_table < 50) {
        colorTable = "text-warning"
    } else {
        colorTable = "text-danger"
    }

    if (margin_delivery > 75) {
        colorDelivery = "text-success"
    } else if (margin_delivery > 50 && margin_delivery < 75) {
        colorDelivery = "text-info"
    } else if (margin_delivery > 40 && margin_delivery < 50) {
        colorDelivery = "text-warning"
    } else {
        colorDelivery = "text-danger"
    }

    return (
        <>
            <tr className={`${isDetail ? 'table-info' : ''}`}>
                <td align='right'>{index + 1}</td>
                {(show_columns && !isDetail) && <td> {item.category}</td>}
                <td width={"35%"} colSpan={show_columns ? 1 : 4}>{item.title}</td>
                <td align='right'>{item.quantity}</td>
                <td align='right'>{formatNumber(item.price)}</td>
                {show_columns && <>
                    <td align='right'>{formatNumber(item.total_table)} <span className={`${colorTable}`}>{margin_table_text}</span></td>
                    <td align='right'>{formatNumber(item.total_delivery)} <span className={`${colorDelivery}`}>{margin_delivery_text}</span></td>

                </>}
                <td><Link className='btn btn-sm btn-info' to={`/recetas/${item.id}/${item.type_product}`}>Editar</Link></td>
            </tr >
            {(isDetail && detail !== undefined) &&
                <>
                    <tr>
                        <td colSpan={5} align='center'>PRODUCTOS</td>
                    </tr>
                    <tr className='thead-light'>
                        <th></th>
                        <th>Receta</th>
                        <th className='text-center'>Cantidad</th>
                        <th className='text-center'>Costo</th>
                        <th></th>
                    </tr>
                    {detail.map((d, k) => <tr key={k} className={`${d.type_product_id === 2 && 'table-primary'}`}>
                        <td></td>
                        <td width={"60%"}>{d.product}</td>
                        <td align='right'>{d.amount}</td>
                        <td align='right'>{formatNumber(d.cost_average * d.amount)}</td>
                        <td>{d.type_product_id === 2 ? 'LLEVAR' : 'MESA'}</td>
                    </tr>)}
                    <tr>
                        <td colSpan={5}></td>
                    </tr>
                </>
            }

        </>
    )
}

export default ItemPrescriptionTable;