import React, { useEffect, useState } from "react";
import { Card, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { connect } from "react-redux";
import TemplateMenu from "../../template/menu.template";
import { getListCoupon, onCreateOrUpdateCoupon } from "../../Redux/couponDuck";
import ItemTableCoupon from "./item.table.coupon";
import InputField from "../Components/Widgets/InputField";
import moment from "moment";
import { useToasts } from "react-toast-notifications";

const API_URL = process.env.REACT_APP_API_URL


const initState = {
  name: "",
  code: "",
  amount: '',
  date_init: moment().format("YYYY-MM-01"),
  date_end: moment().format("YYYY-MM-DD"),
  is_percent: true,
  value: "",
  content_email: ''
};

const CouponPage = ({ getListCoupon, fetching_list, coupons, errors, onCreateOrUpdateCoupon, fetching_create }) => {
  const [form, setForm] = useState(initState);
  const [list, setList] = useState([]);
  const [image, setImage] = useState({ header: '', body: '', main_image: '', confirmation_main: '' });
  const [imageLoad, setImageLoad] = useState({ header: '', body: '', main_image: '', confirmation_main: '' });

  const { addToast } = useToasts();

  useEffect(() => {
    getListCoupon();
  }, []);

  useEffect(() => {
    if (fetching_list === 2) {
      setList(coupons);
    }
  }, [fetching_list]);


  useEffect(() => {
    if (fetching_create === 2) {
      setForm(initState);
      getListCoupon();
    }
  }, [fetching_create]);

  const updateInput = (e) => {
    let _form = {
      ...form, [e.target.name]: e.target.value,
    };

    setForm(_form);
    // onSearch(_form);
  };

  const updateInputFile = (e) => {
    setImage({
      ...image,
      [e.target.name]: e.target.files[0]
    });
  };

  const updateInputCheck = (e) => {
    let _form = {
      ...form,
      [e.target.name]: e.target.checked,
    };

    setForm(_form);
  };

  const onEdit = (item) => {
    setForm(item);

    setImageLoad({
      main_image: API_URL + item.main_image,
      confirmation_image: API_URL + item.confirmation_image,
      header: API_URL + item.header,
      body: API_URL + item.body,
    })
  }

  const onCreateCouponForm = () => {
    let _form = form
    _form.header = image.header
    _form.body = image.body
    _form.main_image = image.main_image
    _form.confirmation_image = image.confirmation_image
    onCreateOrUpdateCoupon(form);
  };

  return (
    <TemplateMenu>
      <Row className="d-flex justify-content-center mt-3">
        <Col lg={11}>
          <Card>
            <Card.Header>
              <Row>
                <Col>Cupones</Col>
                <Col className="d-flex justify-content-end">
                  <button
                    className="btn btn-info btn-sm  mx-2"
                    onClick={() => setForm(initState)}
                    type="button"
                  >
                    Nuevo
                  </button>
                  <button type="button" className="btn btn-success btn-sm" onClick={onCreateCouponForm}>Guardar</button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col lg={8}>
                  <Row>
                    <Col>
                      <div className="form-group">
                        <label>Nombre</label>
                        <InputField
                          placeholder="Nombre"
                          value={form.name || ""}
                          name="name"
                          onChange={updateInput}
                          error={errors.name}
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="form-group">
                        <label>Código</label>
                        <InputField
                          placeholder="Código de promoción"
                          value={form.code || ""}
                          name="code"
                          onChange={updateInput}
                          error={errors.code}
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="form-group">
                        <label>Fecha Inicio</label>
                        <InputField
                          type={"date"}
                          value={form.date_init || ""}
                          name="date_init"
                          onChange={updateInput}
                          error={errors.date_init}
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="form-group">
                        <label>Fecha Fin</label>
                        <InputField
                          type={"date"}
                          value={form.date_end || ""}
                          name="date_end"
                          onChange={updateInput}
                          error={errors.date_end}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="form-group">
                        <label>Cupos</label>
                        <InputField
                          type={"number"}
                          placeholder="Cupos"
                          value={form.amount || ""}
                          name="amount"
                          onChange={updateInput}
                          error={errors.amount}
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="form-group">
                        <label>¿Es porcentaje?</label>
                        <input
                          type={"checkbox"}
                          className="form-control"
                          checked={form.is_percent || false}
                          name="is_percent"
                          onChange={updateInputCheck}
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="form-group">
                        <label>¿Activo?</label>
                        <input
                          type={"checkbox"}
                          className="form-control"
                          checked={form.status || false}
                          name="status"
                          onChange={updateInputCheck}
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="form-group">
                        <label>Valor</label>
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon1">
                            {form.is_percent ? "%" : "$"}
                          </InputGroup.Text>
                          <Form.Control placeholder="Valor" aria-label="Username" aria-describedby="basic-addon1" value={form.value} name="value" onChange={updateInput}
                          />
                        </InputGroup>
                        {errors.value !== undefined && (<label className="error-label">{errors.value}</label>)}

                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Row>
                        <Col>
                          <div className="form-group">
                            <label>Principal</label>
                            <input type={"file"} name="main_image" className="form-control" onChange={updateInputFile} />
                          </div></Col>
                      </Row>
                      <Row>
                        <Col><img src={imageLoad.main_image} className="img-fluid" /></Col>
                      </Row>


                    </Col>
                    <Col>
                      <Row>
                        <Col>
                          <div className="form-group">
                            <label>Confirmación</label>
                            <input type={"file"} name="confirmation_image" className="form-control" onChange={updateInputFile} />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col><img src={imageLoad.confirmation_image} className="img-fluid" /></Col>
                      </Row>

                    </Col>
                    <Col>
                      <Row>
                        <Col>
                          <div className="form-group">
                            <label>Header</label>
                            <input type={"file"} name="header" className="form-control" onChange={updateInputFile} />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col><img src={imageLoad.header} className="img-fluid" /></Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <Col>
                          <div className="form-group">
                            <label>Body</label>
                            <input type={"file"} name="body" className="form-control" onChange={updateInputFile} />
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col><img src={imageLoad.body} className="img-fluid" /></Col>
                      </Row>

                    </Col>
                  </Row>

                </Col>
                <Col>
                  <div className="form-group">
                    <label>Mensaje Email</label>
                    <textarea value={form.content_email} rows={10} name="content_email" className="form-control" onChange={updateInput} error={errors.content_email}></textarea>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Row>
            <Col><Table className="table-sm table-bordered">
              <thead className="thead-dark">
                <tr>
                  <th>Nombre</th>
                  <th>Código</th>
                  <th>Cupos</th>
                  <th>Valor</th>
                  <th>Activados</th>
                  <th>Redimidos</th>
                  <th>Fecha Inicio</th>
                  <th>Fecha Fin</th>
                  <th>Estado</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {list.length > 0 ? (
                  list.map((l) => (
                    <ItemTableCoupon item={l} key={l.id} onEdit={onEdit} />
                  ))
                ) : (
                  <tr>
                    <td>No se encontraron registros</td>
                  </tr>
                )}
              </tbody>
            </Table></Col>
          </Row>

        </Col>
      </Row>
    </TemplateMenu>
  );
};

const mapsState = (props) => {
  let { coupon } = props;
  return {
    coupons: coupon.data,
    errors: coupon.errors,
    fetching_list: coupon.fetching_list,
    fetching_create: coupon.fetching_create,
  };
};

export default connect(mapsState, { getListCoupon, onCreateOrUpdateCoupon })(
  CouponPage
);
