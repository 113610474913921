import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PaginationTable from '../Components/PaginationTable';
import SearchSection from '../Components/SearchSection';
import { getPurchases, onDeletePurchases } from '../../Redux/purchaseDuck';
import { getProductsInventory } from "../../Redux/ProductInventoryDuck"
import { toogleLoader } from '../../Redux/alertDuck';
import TemplateMenu from '../../template/menu.template'
import { connect } from 'react-redux';
import ItemPurchase from './item.purchase';
import { formatNumber } from '../../utils';
import AsyncSelect from "react-select/async";
import { debounceData } from "../../utils";
import moment from 'moment';

const API_URL = process.env.REACT_APP_API_URL;

const PurchasePage = ({ getPurchases, fetching_list, products, onDeletePurchases, fetching_create, toogleLoader,
    getProductsInventory, fetching_list_inventory, data_product, fetching_app, row_app }) => {

    const [list, setList] = useState([])
    const [listProducts, setListProducts] = useState([])
    const [isDetail, setIsDetail] = useState(false)
    const [product, setProduct] = useState('')
    const debounceRef = useRef();
    const [isLock, setIsLock] = useState(false)

    useEffect(() => {
        getPurchases(isDetail, product)
        getProductsInventory()
        toogleLoader(true)
    }, [])

    useEffect(() => {
        if (fetching_app === 2) {
            if (row_app.value === 0) {
                setIsLock(true)
            }
        }
    }, [fetching_app]);

    useEffect(() => {
        if (fetching_list_inventory === 2) {
            setListProducts(data_product)
        }
    }, [fetching_list_inventory]);

    useEffect(() => {
        if (fetching_list === 2) {
            let { date_init } = JSON.parse(localStorage.getItem("date"));
            setList(products)
            toogleLoader(false)
        }
    }, [fetching_list])

    useEffect(() => {
        if (fetching_create === 2) {
            getPurchases(isDetail, product)
        }
    }, [fetching_create])

    const onDelete = (item) => {
        if (window.confirm("Realmenre deseas Eliminar esta Order De Compra?")) {
            toogleLoader(true)
            onDeletePurchases(item.id)
        }
    }

    const onFilterProduct = (e) => {
        toogleLoader(true)

        let is_detail = isDetail

        if (is_detail !== true) {
            is_detail = !is_detail
            setIsDetail(is_detail)
        }

        if (e === null) {
            getPurchases(is_detail, 0)
            setIsDetail(false)
        } else {
            getPurchases(is_detail, e.id)
        }


    }

    const onDetail = (e) => {
        toogleLoader(true)
        let is_detail = !isDetail
        setIsDetail(is_detail)

        if (product === '' || product === null) {
            getPurchases(is_detail, 0)
        } else {
            console.log('product', product);

            getPurchases(is_detail, product.id)
        }

    }

    const loadOptions = async (inputValue) => {

        if (debounceRef.current)
            clearTimeout(debounceRef.current)

        let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));
        let branch = JSON.parse(localStorage.getItem("branch"));
        let url = "";
        url = `${API_URL}/api/product-inventory-search/${inputValue}?date_init=${date_init}&date_end=${date_end}&branch=${branch.id}`


        return await debounceData(debounceRef, url);
    };

    return (<TemplateMenu>
        <Container fluid>
            <Row className='mt-3'>
                <Col lg={2} className='d-flex align-items-center'>
                    <Link to="/compras/new" className='btn btn-info btn-sm'>Realizar Compra</Link>
                </Col>
                <Col lg={4}>
                    <AsyncSelect
                        value={product}
                        isClearable
                        placeholder={`Busca el Producto`}
                        loadOptions={loadOptions}
                        // defaultOptions={defaultOptions}
                        onChange={(e) => {
                            setProduct(e)
                            onFilterProduct(e)
                        }}
                    />

                </Col>
                <Col lg={3} className='d-flex align-items-center justify-content-center'>
                    <input type={"checkbox"} className="form-control" onChange={onDetail} checked={isDetail} />Ver Detalles
                </Col>


            </Row>
            <Row className='mt-3'>
                <Col>
                    <Table striped className="table table-sm table-bordered table-condensed">
                        <thead className='thead-dark'>
                            <tr>
                                <th></th>
                                <th>Fecha de Solicitud</th>
                                <th>Proveedor</th>
                                <th className='text-center'>Cantidad Item</th>
                                <th className='text-center'>Total</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(list.length > 0) ? list.map(row => <ItemPurchase row={row}
                                isLock={isLock}
                                key={row.id}
                                onDelete={onDelete}
                                isDetail={isDetail} />) : <tr><td colSpan={6}>No se encontraron registros</td></tr>}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan={3}>TOTAL</th>
                                <th className='text-right'>{formatNumber(list.reduce((a, b) => a + parseFloat(b.amount), 0))}</th>
                                <th className='text-right'>{formatNumber(list.reduce((a, b) => a + parseFloat(b.total), 0))}</th>
                            </tr>
                        </tfoot>
                    </Table>

                    {/* <PaginationTable /> */}
                </Col>
            </Row>
        </Container>
    </TemplateMenu>
    )
}


const mapsState = (props) => {
    let { purchase, product_inventory, user, setting } = props

    return {
        fetching_list: purchase.fetching_list,
        fetching_create: purchase.fetching_create,
        products: purchase.data,
        fetching_list_inventory: product_inventory.fetching_list,
        data_product: product_inventory.data,
        data_dates: user.data_dates,
        fetching_app: setting.fetching_app,
        row_app: setting.row
    }

}

export default connect(mapsState, { getPurchases, onDeletePurchases, toogleLoader, getProductsInventory })(PurchasePage);