import React, { useEffect, useState, useContext } from 'react'
import { Container, Col, Row, Card } from 'react-bootstrap';
import { connect } from 'react-redux';

import logo_patos from 'Images/logo_madera.png'


import { getOrders, restartOrder, onCancelOrder, getOrderId } from '../../Redux/orderDuck'
import { toogleAlert } from '../../Redux/alertDuck'
import { getListBranch } from '../../Redux/branchofficeDuck'
import OrderDetailCart from './OrderDetailCart';
import audio2 from '../../Audio/pato.mpeg'
import { Howl } from 'howler';
//components

import BtnWhiteHeader from './../Components/BtnWhiteHeader'
import SearchSection from './../Components/SearchSection'
import BlueTitleBack from './../Components/Widgets/BlueTitleBack'
import OrderPreparation from './OrderPreparation';
import ModalDetailOrder from './ModalDetailOrder';
import ModalCancel from './cancel.modal';
import { useHistory } from 'react-router-dom';
import { SocketContext } from '../../context/SocketContext';
import Swal from 'sweetalert2'
import TemplateMenu from '../../template/menu.template'


const API_URL = process.env.REACT_APP_API_URL


const Orders = ({ getOrders, orders, fetching, restartOrder, onCancelOrder, fetching_cancel, getOrderId, fetching_id, detail, toogleAlert, user,
    getListBranch, fetching_branch, branchs }) => {

    const [data, setData] = useState([])
    const [selected, setSelected] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [showModalCancel, setShowModalCancel] = useState(false)
    const [row, setRow] = useState([])

    const [currentBranch, setCurrentBranch] = useState({ status: false, text: "Fuera de Linea", id: 0, socket: '' })


    const history = useHistory()

    const { socket } = useContext(SocketContext)

    const [player, setPlayer] = useState(new Howl({
        src: [audio2],
        loop: true
    }))

    const [message, setMessage] = useState()

    useEffect(() => {
        setShowModal(false)
    }, [])


    useEffect(() => {
        if (fetching === 2) {
            toogleAlert(false)
            setData(orders)
        }

    }, [fetching])


    useEffect(() => {

        console.log('fetching_id', fetching_id)

        if (fetching_id === 2) {
            toogleAlert(false)
            setShowModal(true);
            setRow(detail)
        }

        if (fetching_id === 0) {
            setShowModal(false);
        }

    }, [fetching_id])

    useEffect(() => {

        if (fetching_cancel === 2) {
            setShowModalCancel(false)
            player.stop()
            getOrders();
        }

    }, [fetching_cancel])


    useEffect(() => {
        toogleAlert(true)
        getOrders()

        if (Notification.permission !== 'granted') {
            console.log('getPermissos')
            getPermission()
        }

    }, [])

    const getPermission = () => {
        Notification.requestPermission()
            .then(res => console.log(res))
            .catch(err => console.log(err))
    }

    useEffect(() => {

        if (socket) {

            socket.on("confirm-order", (data) => {
                toogleAlert(false)
                setShowModal(false)
                updateOrder(data)
                console.log('data-aserver', data)
            })

            socket.on("error-order", (message) => {
                toogleAlert(false)
                Swal.fire({

                    title: "Servidor Offline",
                    text: message.message,
                    icon: "error",


                })
            })


            socket.on("message", data_server => {
                let { type, row, type_request, branch_id } = data_server;



                if (type === 'online' && user.branch_id === branch_id) {
                    notify(row)
                    player.play()
                }

                if (type === 'confirm-order' && type_request === 2 && user.branch_id === branch_id) {
                    toogleAlert(false)
                    setShowModal(false)
                    updateOrder(row)
                }
                if (type === 'close-order' && user.branch_id === branch_id) {
                    updateOrder(row)
                }

                console.log('data_server', data_server)
            });


            socket.on("server-online", data => {
                updateStatusServer(data, true)
                console.log('server-online', data)
            })

            socket.on("server-offline", data => {
                toogleAlert(false)
                updateStatusServer(data, false)
                console.log('server-offline', data)
            })
            socket.on("order-open", data => {
                toogleAlert(false)
                Swal.fire({
                    title: "Order Abierta en Aldelo",
                    text: data.message,
                    icon: "info",
                })
            })

            socket.on("order-close", data => {
                deleteOrder(data)
            })
            socket.on("confirm-cart", data => {

                // toogleAlert(false)
                setShowModal(false)
                addOrder(data)

            })
        }

    }, [data])

    const updateStatusServer = (data, status) => {
        setCurrentBranch({ status: status })
    }

    const deleteOrder = (row) => {
        let _data = [...data]

        _data.map((item, i) => {
            if (item.id === row.id) {
                delete _data[i];
            }
        })

        _data = _data.filter(row => !!row)

        setData(_data)
        toogleAlert(false)

        Swal.fire({

            title: "Order Sincronizada",
            text: "Order Sincronizada",
            icon: "success",
        })
    }

    const updateOrder = (row) => {
        console.log('updateOrder', row)
        let _data = [...data]

        _data.map((item, i) => {
            if (item.id === row.id) {
                _data[i] = row
            }
        })

        setData(_data)
        player.stop()
    }
    const addOrder = (row) => {
        let _data = [...data]

        _data.push(row)

        setData(_data)
        player.stop()
    }


    const notify = (row) => {
        let _data = [...data]

        _data.unshift(row)

        setData(_data)

        const options = {
            body: `Pedido Nuevo. #${row.id}`,
            icon: logo_patos,
            badge: logo_patos,
        }
        const notification = new Notification("Al Agua Patos", options)

        notification.addEventListener('close', () => console.log('close'))
        //console.log(notification)
    }


    const loadData = (data) => {
        player.stop()
        setData(data)
    }


    const onPlay = () => {
        player.stop()
    }

    const onHide = () => {
        setShowModal(false);
    }

    const showDetail = (item) => {
        toogleAlert(true)
        getOrderId(item.id)
    }

    const onCancel = (item) => {
        setSelected(item)
        setShowModalCancel(true)
    }

    const onSubmitCancel = (item, comment) => {
        onCancelOrder(item, comment)
    }


    const acceptOrder = (data) => {
        let message = "Esperando confirmación de Aldelo...."
        if (data.status_id === 3 || data.status_id === 4) {
            message = "Espere por favor"
        }

        toogleAlert(true, message)

        if (socket) {
            socket.emit("order_online_aldelo", data)
        }

        // acceptOrder(data.id);
    }


    const onSyncOrder = (order) => {
        console.log('order', order)

        order.branch = currentBranch


        if (socket) {
            toogleAlert(true, `Sincronizando Orden #${order.aldelo_id} con aldelo, Espere Por favor`)
            socket.emit("validate_order", order)
        }
    }

    return (
        <TemplateMenu>
            <Container fluid>

                <Row className=" p-2 w-100 mx-0 mt-2 ">
                    <Col lg={4} md={12} className="p-0 justify-content-center justify-content-lg-start d-flex">
                        <BtnWhiteHeader title={'Gestionar'} to="/ordenes" />
                        <BtnWhiteHeader title={'Lista'} to="/historial-pedidos" />
                        {/* <BtnWhiteHeader title={'Programados'} to="/programados" /> */}
                    </Col>
                    <Col lg={4} md={4} className="justify-content-center">
                        <Row className="text-center">
                            <Col>
                                <span className="font-title h6 pt-3" >TUS PEDIDOS </span>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col className="text-center">

                                {currentBranch.status ? (
                                    <span className="font-title-online"> (En linea)</span>
                                ) : (
                                    <span className="font-title-offline"> (Fuera de linea)</span>
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={4} md={8} className="align-items-center justify-content-end d-flex">
                        <Row className=" p-0 mt-1">
                            <SearchSection
                                placeholder='Buscar Órden'
                                value=''
                                name='buscar_orden'
                            >
                            </SearchSection>
                        </Row>
                    </Col>
                </Row>

                <Row className="mt-3 justify-content-center">

                    <Col lg={5} >
                        <Card className="rounded-lg border pb-3">
                            <Card.Body  >
                                <Container fluid>
                                    <Row className="pt-2 pb-3">
                                        <BlueTitleBack title="Por Aceptar" onClick={onPlay} />
                                    </Row>
                                    <Row className="p-2 m-0 justify-content-center">
                                        <Col>
                                            {(data !== undefined) ? (data.filter(row => row.status_id === 2).length > 0) &&
                                                data.filter(row => row.status_id === 2).map((row, i) =>
                                                    <OrderDetailCart item={row} onClick={showDetail} key={i} onCancel={onCancel}

                                                    />) :
                                                <Row className="text-center w-100 d-flex flex-column">
                                                    <Col >No hay Pendientes por Aceptar</Col>
                                                </Row>
                                            }
                                        </Col>
                                    </Row>
                                </Container>
                            </Card.Body>
                        </Card>
                    </Col>


                    <Col lg={5}>
                        <Card className="rounded-lg border pb-3">
                            <Card.Body>
                                <Container fluid>
                                    <Row className="pt-2 pb-3">
                                        <BlueTitleBack title="En Preparación" />
                                    </Row>
                                    <Row>
                                        <Col lg={12} className="p-2 m-0 justify-content-center">

                                            {(data !== undefined) ? (data.filter(row => row.status_id === 3).length > 0) &&
                                                data.filter(row => row.status_id === 3).map((row, i) =>
                                                    <OrderPreparation item={row}
                                                        showDetail={showDetail} key={i}
                                                        onCancel={onCancel}
                                                        clean={(data.filter(row => row.status_id === 2).length > 0 ? true : false)}
                                                        onSync={onSyncOrder}
                                                    />) :
                                                <Row className="text-center w-100 d-flex flex-column">
                                                    <Col>No hay Pendientes por Aceptar</Col>

                                                </Row>
                                            }
                                        </Col>
                                    </Row>

                                </Container>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>


                <ModalDetailOrder
                    updateOrder={updateOrder}
                    show={showModal}
                    onSubmit={acceptOrder}
                    data={row}
                    onData={loadData}
                    onHide={onHide}
                />

                <ModalCancel show={showModalCancel} item={selected} onSubmit={onSubmitCancel} onHide={() => setShowModalCancel(false)} />

            </Container>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    return {
        user: props.user.data,
        detail: props.order.detail,
        fetching_id: props.order.fetching_id,
        orders: props.order.data,
        fetching_cancel: props.order.fetching_cancel,
        fetching: props.order.fetching_all,
        branchs: props.branchoffice.data,
        fetching_branch: props.branchoffice.fetching,
    }
}

export default connect(mapsState, { getOrders, restartOrder, onCancelOrder, getOrderId, toogleAlert, getListBranch })(Orders);