import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import InputField from '../Components/Widgets/InputField';
import { onCreateProductInventory } from '../../Redux/ProductInventoryDuck';
import { getParameterBySegment, createParameter } from '../../Redux/parameterDuck';
import { getSuppliers } from '../../Redux/supplierDuck';
import TemplateMenu from '../../template/menu.template'
import { AiOutlinePlusCircle } from "react-icons/ai";
import ModalAddParameter from '../Components/ModalAddParameter';
import { useHistory } from 'react-router-dom';


const CreateProductRecipePage = ({ errors, onCreateProductInventory, getParameterBySegment, fetching_by_segment, data_segment, segment,
    createParameter, fetching_create, getSuppliers, fetching_list, suppliers, fetching_create_inventory }) => {
    const [form, setForm] = useState({
        name: '', code: '', bar_code: '', measure_unit_id: 0, tax_id: 0, cost: '', family_inventory_id: 0, type_inventory_id: 0,
        group_inventory_id: 0, control_inventory_id: 0, supplier_id: 0
    })

    const [meausureUnits, setMeasureUnits] = useState([])
    const [listTax, setListTax] = useState([])
    const [listControl, setListControl] = useState([])
    const [listFamily, setListFamily] = useState([])
    const [listType, setListType] = useState([])
    const [listGroup, setListGroup] = useState([])
    const [listSupplier, setListSupplier] = useState([])
    const [modalParam, setModalParam] = useState({ open: false, title: '' })
    const history = useHistory()

    useEffect(() => {
        getParameterBySegment("measure_unit")
        getSuppliers('?status=1')
    }, [])

    useEffect(() => {
        if (fetching_create_inventory === 2) {
            history.goBack()
        }
    }, [fetching_create_inventory])

    useEffect(() => {
        if (fetching_by_segment === 2) {
            if (segment === "measure_unit") {
                setMeasureUnits(data_segment)
                getParameterBySegment("tax")

            }
            if (segment === "tax") {
                setListTax(data_segment)
                getParameterBySegment("control_inventory")
            }
            if (segment === "control_inventory") {
                setListControl(data_segment)
                getParameterBySegment("family_inventory")
            }
            if (segment === "family_inventory") {
                setListFamily(data_segment)
                getParameterBySegment("type_inventory")
            }

            if (segment === "type_inventory") {
                setListType(data_segment)
                getParameterBySegment("group_inventory")
            }
            if (segment === "group_inventory") {
                setListGroup(data_segment)
            }

        }
    }, [fetching_by_segment])

    useEffect(() => {
        if (fetching_list === 2) {
            setListSupplier(suppliers)
        }
    }, [fetching_list])


    useEffect(() => {
        if (fetching_create === 2) {
            setModalParam({
                ...modalParam,
                open: false
            })
            getParameterBySegment("measure_unit")
        }
    }, [fetching_create])



    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const openModalParameter = (title, segment) => {
        setModalParam({
            ...modalParam,
            open: true,
            title: title,
            dataType: segment
        })
    }

    const onSuccessParameter = (form) => {
        form.segment = form.dataType
        form.priority = 1;

        createParameter(form)
    }

    return (
        <TemplateMenu>
            <Container fluid>
                <Row className='d-flex justify-content-center'>
                    <Col lg={6}>
                        <Row className='my-2'>
                            <Col>
                                <button className='btn btn-sm btn-secondary' onClick={() => history.goBack()}>Volver</button>
                            </Col>
                        </Row>
                        <Row className='my-2'>
                            <Col><h3 className='text-center'>Creación de Productos para Inventario</h3></Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col>
                                <div className="form-group">
                                    <label>Nombre</label>
                                    <InputField
                                        placeholder="Nombre"
                                        value={form.name || ''}
                                        name="name"
                                        onChange={updateInput}
                                        error={errors.name}
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label>Código</label>
                                    <InputField
                                        placeholder="Código"
                                        value={form.code || ''}
                                        name="code"
                                        onChange={updateInput}
                                        error={errors.code}
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label>Código de Barras</label>
                                    <InputField
                                        placeholder="Código de Barras"
                                        value={form.bar_code || ''}
                                        name="bar_code"
                                        onChange={updateInput}
                                        error={errors.bar_code}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="form-group">
                                    <label>Unidad Medida <AiOutlinePlusCircle className='cursor-pointer' size={20}
                                        onClick={() => openModalParameter("Agregar Unidad de medida", "measure_unit")} /></label>
                                    <select className='form-control' name='measure_unit_id' value={form.measure_unit_id} onChange={updateInput}>
                                        <option value="0">Seleccione</option>
                                        {meausureUnits.map(m => <option key={m.id} value={m.id}>{m.description}</option>)}
                                    </select>
                                    {errors.measure_unit_id !== undefined && (<label className="error-label">{errors.measure_unit_id}</label>)}

                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label>Impuesto <AiOutlinePlusCircle className='cursor-pointer' size={20}
                                        onClick={() => openModalParameter("Agregar Impuesto", "tax")} /></label>
                                    <select className='form-control' name='tax_id' value={form.tax_id} onChange={updateInput}>
                                        <option value="0">Seleccione</option>
                                        {listTax.map(m => <option key={m.id} value={m.id}>{m.description}</option>)}
                                    </select>
                                    {errors.tax !== undefined && (<label className="error-label">{errors.tax}</label>)}
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label>Costo</label>
                                    <InputField
                                        placeholder="Costo Producto"
                                        value={form.cost || ''}
                                        name="cost"
                                        onChange={updateInput}
                                        error={errors.cost}
                                    />
                                </div>
                            </Col>

                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="form-group">
                                    <label>Familia <AiOutlinePlusCircle className='cursor-pointer' size={20}
                                        onClick={() => openModalParameter("Agregar Familia", "family_inventory")} /></label>
                                    <select className='form-control' name='family_inventory_id' value={form.family_inventory_id} onChange={updateInput}>
                                        <option value="0">Seleccione</option>
                                        {listFamily.map(m => <option key={m.id} value={m.id}>{m.description}</option>)}
                                    </select>
                                    {errors.family_inventory_id !== undefined && (<label className="error-label">{errors.family_inventory_id}</label>)}
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group">
                                    <label>Tipo de Inventario</label>
                                    <select className='form-control' name='type_inventory_id' value={form.type_inventory_id} onChange={updateInput}>
                                        <option value="0">Seleccione</option>
                                        {listType.map(m => <option key={m.id} value={m.id}>{m.description}</option>)}
                                    </select>
                                    {errors.type_inventory_id !== undefined && (<label className="error-label">{errors.type_inventory_id}</label>)}
                                </div>
                            </Col>


                            <Col>
                                <div className="form-group">
                                    <label>Grupo <AiOutlinePlusCircle className='cursor-pointer' size={20}
                                        onClick={() => openModalParameter("Agregar Grupo", "group_inventory")} /></label>
                                    <select className='form-control' name='group_inventory_id' value={form.group_inventory_id} onChange={updateInput}>
                                        <option value="0">Seleccione</option>
                                        {listGroup.map(m => <option key={m.id} value={m.id}>{m.description}</option>)}
                                    </select>
                                    {errors.group_inventory_id !== undefined && (<label className="error-label">{errors.group_inventory_id}</label>)}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <div className="form-group">
                                    <label>Tipo de Control</label>
                                    <select className='form-control' name='control_inventory_id' value={form.control_inventory_id} onChange={updateInput}>
                                        <option value="0">Seleccione</option>
                                        {listControl.map(m => <option key={m.id} value={m.id}>{m.description}</option>)}
                                    </select>
                                    {errors.control_inventory_id !== undefined && (<label className="error-label">{errors.control_inventory_id}</label>)}
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="form-group">
                                    <label>Proveedor</label>
                                    <select className='form-control' name='supplier_id' value={form.supplier_id} onChange={updateInput}>
                                        <option value="0">Seleccione</option>
                                        {listSupplier.map(m => <option key={m.id} value={m.id}>{m.business}</option>)}
                                    </select>
                                    {errors.supplier_id !== undefined && (<label className="error-label">{errors.supplier_id}</label>)}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='d-flex justify-content-end'>
                                <button className='btn btn-success' onClick={() => onCreateProductInventory(form)}>Guardar</button>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <ModalAddParameter
                    show={modalParam.open}
                    title={modalParam.title}
                    dataType={modalParam.dataType}
                    onHide={() => setModalParam({ ...modalParam, "open": false })}
                    onSuccess={onSuccessParameter}

                />
            </Container >

        </TemplateMenu >
    )
}

const mapsState = (props) => {
    let { product_inventory, parameter, supplier } = props
    return {
        errors: product_inventory.errors,
        fetching_create_inventory: product_inventory.fetching_create,
        segment: parameter.parameter,
        fetching_by_segment: parameter.fetching_by_segment,
        data_segment: parameter.data_segment,
        segment: parameter.segment,
        fetching_create: parameter.fetching_create,
        fetching_list: supplier.fetching_list,
        suppliers: supplier.data
    }
}

export default connect(mapsState, { onCreateProductInventory, getParameterBySegment, createParameter, getSuppliers })(CreateProductRecipePage);