import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { MdKeyboardArrowLeft, MdModeEditOutline } from "react-icons/md";
import { connect } from "react-redux";
import { getRecipeBookId } from "../../Redux/recipe.book.Duck";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

const API_URL = process.env.REACT_APP_API_URL;

const ShowPrescription = ({ getRecipeBookId, fetching_by_id, row }) => {
  let { id } = useParams();
  const history = useHistory();

  const [item, setItem] = useState({ detail: [] });

  useEffect(() => {
    if (fetching_by_id === 2) {
      console.log("item", row);

      setItem(row);
    }
  }, [fetching_by_id]);

  useEffect(() => {
    if (id) {
      getRecipeBookId(id);
    }
  }, [id]);


  console.log('item', item);


  return (
    <Container fluid>
      <div className="content-recipe-item">
        <div className="btn-back">
          <MdKeyboardArrowLeft size={30} onClick={() => history.goBack()} />
        </div>
        <Row>
          <Col xs={12}>
            <Card className="mt-custom">
              <Card.Body>
                <Row>
                  <Col className="text-center">
                    <h2>{item.title}</h2>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Row>
                      <Col>
                        {/* <img src={item.image} className="img-recipe" /> */}
                        <img src={item.image} className="img-fluid" />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="content-video my-2 d-flex justify-content-center">

                        {item.video !== "" && item.video !== null && (
                          <video width="100%" height="100%" src={item.video} controls autoPlay>
                          </video>
                        )}

                        {/* {(item.link_youtube !== "" && item.link_youtube !== null) &&
                          <iframe width="560" height="315" src={item.link_youtube} title={item.title} frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen>
                          </iframe>} */}
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <Card>
                          <Card.Body>
                            <Row className="mt-2">
                              <Col className="text-center">
                                <h2 className="title-recipe">Ingredientes</h2>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="text-center">
                                <img src={item.image_ingredient} className="img-fluid" />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <ul>
                                  {item.detail.filter(
                                    (d) => d.recipe_type === 1
                                  ).length > 0 ? (
                                    item.detail
                                      .filter((d) => d.recipe_type === 1)
                                      .map((d, k) => (
                                        <li key={k}>{d.description}</li>
                                      ))
                                  ) : (
                                    <li>No tiene Indicaciones</li>
                                  )}
                                </ul>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <Card>
                          <Card.Body>
                            <Row>
                              <Col className="text-center">
                                <h2 className="title-recipe">Preparación</h2>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="text-center">
                                <img src={item.image_preparation} className="img-fluid" />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <ul>
                                  {item.detail.filter(
                                    (d) => d.recipe_type === 2
                                  ).length > 0 ? (
                                    item.detail
                                      .filter((d) => d.recipe_type === 2)
                                      .map((d, k) => (
                                        <li key={k}>{d.description}</li>
                                      ))
                                  ) : (
                                    <li>No tiene Indicaciones</li>
                                  )}
                                </ul>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Col>

                  <Col>
                    <Row className="mt-3">
                      <Col>
                        <Card>
                          <Card.Body>
                            <Row>
                              <Col className="text-center">
                                <h2 className="title-recipe">Para la Mesa</h2>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="text-center">
                                <img src={item.image_on_table} className="img-fluid" />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <ul>
                                  {item.detail.filter(
                                    (d) => d.recipe_type === 3
                                  ).length > 0 ? (
                                    item.detail
                                      .filter((d) => d.recipe_type === 3)
                                      .map((d, k) => (
                                        <li key={k}>{d.description}</li>
                                      ))
                                  ) : (
                                    <li>No tiene Indicaciones</li>
                                  )}
                                </ul>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col>
                        <Card>
                          <Card.Body>
                            <Row>
                              <Col className="text-center">
                                <h2 className="title-recipe">Para Domicilio</h2>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="text-center">
                                <img src={item.image_delivery} className="img-fluid" />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <ul>
                                  {item.detail.filter(
                                    (d) => d.recipe_type === 4
                                  ).length > 0 ? (
                                    item.detail
                                      .filter((d) => d.recipe_type === 4)
                                      .map((d, k) => (
                                        <li key={k}>{d.description}</li>
                                      ))
                                  ) : (
                                    <li>No tiene Indicaciones</li>
                                  )}
                                </ul>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

const mapsState = (props) => {
  let { recipe_book } = props;
  return {
    fetching_by_id: recipe_book.fetching_by_id,
    row: recipe_book.row,
  };
};

export default connect(mapsState, { getRecipeBookId })(ShowPrescription);
