import React, { useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap';
import { MdClose } from "react-icons/md";

import BlueTitleBack from '../Components/Widgets/BlueTitleBack';
import InputField from '../Components/Widgets/InputField';
import moment from 'moment';


const initState = { amount: 0 }
const ModalAdditionalPayment = ({ show, onHide, onSubmit }) => {

    const [form, setForm] = useState(initState)
    const [errors, setErrors] = useState({})

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }
    const onSubmitTmp = () => {
        setForm(initState)
        onSubmit(form)
    }

    return (
        <Modal show={show}>
            <div className="modal-header border-bottom-custom mt-2">
                <BlueTitleBack title={"Agregar otro Pago"} />
                <button type="button" className="close p-0 m-0" data-dismiss="modal" aria-label="Close" onClick={onHide}>
                    <MdClose />
                </button>
            </div>
            <Modal.Body>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Detalle</label>
                            <InputField
                                placeholder={"Agrega detalle"}
                                value={form.detail || ''}
                                name="detail"
                                onChange={updateInput}
                                error={errors.reason}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Monto</label>
                            <InputField
                                type={"number"}
                                placeholder={"Agrega el monto"}
                                value={form.amount || ''}
                                name="amount"
                                onChange={updateInput}
                                error={errors.reason}
                            />
                        </div>
                    </Col>
                </Row>

            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Row >
                    <Col>
                        <button className='btn btn-danger' onClick={onHide}>Cerrar</button>
                    </Col>
                    <Col>
                        <button className='btn btn-success' onClick={onSubmitTmp}>Guardar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalAdditionalPayment;