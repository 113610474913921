import React, { useState, useEffect } from "react";
import { Container, Col, Row, Form, Table } from "react-bootstrap";
import InputField from "../Components/Widgets/InputField";
import TextAreaField from "../Components/Widgets/TextAreaField";
import InputFieldCheck from "../Components/Widgets/InputFieldCheck";
import SelectField from "../Components/Widgets/SelectField";
import BtnOrangeSm from "../Components/Widgets/BtnOrangeSm";
import BtnBlueModal from "../Components/Widgets/BtnBlueModal";
import chooseimage from "./../../Images/no_available.png";
import ItemsModifiers from "./ItemsModifier";
import ItemsGroupModifier from "./ItemsGroupModifier";
import ItemsBrand from "./ItemsBrand";
import ItemsGroupModifierNew from "./ItemsGroupModifierNew";
import ItemsBrandSaved from "./ItemsBrandSaved";

const API_URL = process.env.REACT_APP_API_URL;

const initialState = {
  title: "",
  description: "",
  image: "",
  category_id: 0,
  hot_spicy: false,
  is_new: false,
  banner: "",
  status: false,
  aldelo_id: "",
};

const FormProduct = ({
  categories,
  deleteProductAlert,
  branchs,
  listBrand,
  setListBranch,
  updateGroup,
  mod,
  onSaveModifier,
  modifiers,
  onSubmit,
  formEdit,
  errors,
}) => {
  const [form, setForm] = useState(initialState);
  const [brandSelected, setBrandSelected] = useState(0);
  const [banner, setBanner] = useState("");
  const [image, setImage] = useState("");
  const [listModifier, setListModifier] = useState([]);
  const [listModifierExt, setListModifierExt] = useState([]);
  const [group, setGroup] = useState(0);
  const [listPrice, setListPrice] = useState([]);

  useEffect(() => {
    if (formEdit !== undefined) {
      console.log("formEdit", formEdit);
      let pro = formEdit;

      pro.image = API_URL + pro.image;
      pro.banner = pro.banner == null ? chooseimage : API_URL + pro.banner;

      setForm(pro);
      setListModifierExt(pro.modifier_group);
      setListPrice(formEdit.products_brand);

      console.log("pro.products_brand", pro.products_brand);
    }
  }, [formEdit]);

  const onSubmitPre = (e) => {
    e.preventDefault();
    let _form = form;
    _form.banner = banner;
    _form.image = image;
    _form.brands = listBrand;
    _form.groups = listModifier;
    onSubmit(_form);
  };

  const updateInput = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const inputFile = (e) => {
    setImage(e.target.files[0]);

    let file = e.target.files[0];
    let reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setForm({ ...form, image: reader.result });
      }
    };
    reader.readAsDataURL(file);
  };

  const inputFileBanner = (e) => {
    setBanner(e.target.files[0]);
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setForm({ ...form, banner: reader.result });
      }
    };
    reader.readAsDataURL(file);
  };

  const updateCheck = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.checked,
    });
  };

  const onCancelBrand = () => {
    setBrandSelected(0);
  };

  const onSaveModifierTmp = () => {
    if (group !== 0) {
      let _group = mod.filter((row) => parseInt(row.id) === parseInt(group))[0];

      setListModifier([...listModifier, _group]);
    }
  };

  let view_branch =
    listPrice !== undefined && listPrice.length > 0
      ? listPrice.map((row) => (
        <ItemsBrandSaved
          key={row.id}
          item={row}
          brandSelected={brandSelected}
          listBrand={listBrand}
          setBrandSelected={setBrandSelected}
          updateListBrand={(list) => setListBranch(list)}
          onCancelBrand={onCancelBrand}
        />
      ))
      : branchs.map((row) => (
        <ItemsBrand
          key={row.id}
          item={row}
          brandSelected={brandSelected}
          listBrand={listBrand}
          setBrandSelected={setBrandSelected}
          updateListBrand={(list) => setListBranch(list)}
          onCancelBrand={onCancelBrand}
        />
      ));
  return (
    <Container fluid>
      <Form onSubmit={onSubmitPre}>
        <Row className="mb-3">
          <Col lg={6}>
            <Row className="mb-3">
              <Col lg={6}>
                <button className="btn btn-success" type="submit">
                  Guardar
                </button>
              </Col>
              <Col lg={3}>
                <button
                  className="btn btn-info"
                  type="button"
                  onClick={deleteProductAlert}
                >
                  Eliminar
                </button>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <div className="form-group">
                  <label>Título del Producto</label>
                  <InputField
                    placeholder="Título"
                    value={form.title || ""}
                    name="title"
                    onChange={updateInput}
                  // error={errors.title}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <div className="form-group">
                  <label>Descripción Producto</label>
                  <TextAreaField
                    placeholder="Descripción"
                    value={form.description || ""}
                    name="description"
                    onChange={updateInput}
                  // error={errors.description}
                  />
                </div>
              </Col>
            </Row>

            <Row className=" mb-4">
              <Col lg={3} sm={6}>
                <div className="form-group">
                  <label>Recomendado</label>
                  <InputField
                    type="checkbox"
                    placeholder="Titulo del Grupo"
                    checked={form.recomended || ""}
                    name="recomended"
                    onChange={updateCheck}
                  // error={errors.recomended}
                  />
                </div>
              </Col>
              <Col lg={3} sm={6}>
                <div className="form-group">
                  <label>¿Es nuevo?</label>
                  <InputFieldCheck
                    checked={form.is_new || ""}
                    name="is_new"
                    onChange={updateCheck}
                  // error={errors.is_new}
                  />
                </div>
              </Col>
              <Col lg={3} sm={6}>
                <div className="form-group">
                  <label>¿Picante?</label>
                  <InputFieldCheck
                    checked={form.hot_spicy || ""}
                    name="hot_spicy"
                    onChange={updateCheck}
                  // error={errors.hot_spicy}
                  />
                </div>
              </Col>
              <Col lg={3} sm={6}>
                <div className="form-group">
                  <label>Estado</label>
                  <InputFieldCheck
                    checked={form.status || false}
                    name="status"
                    onChange={updateCheck}
                  // error={errors.status_id}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={4}>
                <div className="form-group">
                  <label>Categoría</label>
                  <SelectField
                    data={categories}
                    name="category_id"
                    value={form.category_id}
                    onChange={updateInput}
                  // error={errors.category_id}
                  />
                </div>
              </Col>
              <Col lg={2}>
                <div className="form-group">
                  <label>Prioridad</label>
                  <InputField
                    type="number"
                    placeholder="Orden"
                    value={form.priority || ""}
                    name="priority"
                    onChange={updateInput}
                  // error={errors.priority}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="form-group pb-3">
                  <label>Producto Id Aldelo</label>
                  <InputField
                    placeholder="Código Aldelo"
                    value={form.aldelo_id || ""}
                    name="aldelo_id"
                    onChange={updateInput}
                  // error={errors.aldelo_id}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <Table>
                  <thead>
                    <tr>
                      <td colSpan="3" align="center">
                        <h4>Locales</h4>
                      </td>
                    </tr>
                    <tr>
                      <th>Local</th>
                      <th>Precio</th>
                      <th>Estado</th>
                    </tr>
                  </thead>
                  <tbody>{view_branch}</tbody>
                </Table>
              </Col>
            </Row>
          </Col>
          <Col lg={6}>
            <Row>
              <Col className="pl-5 pt-5 mt-5">
                <Row className="mb-5">
                  <Col lg={7}>
                    <div className="form-group">
                      <label>Grupo Modificadores</label>
                      <select
                        className="form-control"
                        name="group_id"
                        value={group}
                        onChange={(e) => setGroup(e.target.value)}
                      >
                        <option value="0">Seleccione</option>
                        {mod.map((row, i) => {
                          return (
                            <option key={i} value={row.id}>
                              {row.title}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </Col>
                  <Col
                    lg={5}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <BtnBlueModal title="Agregar" onClick={onSaveModifierTmp} />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    {listModifier.length > 0 && (
                      <>
                        <Row>
                          <Col className="text-primary font-weight-bold">
                            Nuevo
                          </Col>
                        </Row>
                        {listModifier.map((row, key) => (
                          <ItemsGroupModifierNew key={key} item={row} />
                        ))}
                      </>
                    )}
                    {listModifierExt !== undefined &&
                      listModifierExt.length > 0 && (
                        <>
                          <Row>
                            <Col className="text-default font-weight-bold">
                              Actual
                            </Col>
                          </Row>
                          {listModifierExt.map((row, key) => (
                            <ItemsGroupModifier key={key} item={row} />
                          ))}
                        </>
                      )}
                  </Col>
                </Row>
                <Row className="justify-content-center"></Row>
              </Col>
            </Row>

            <Row className="pt-5 pb-2">
              <Col>
                <Row>
                  <Col className="text-center">
                    <h4>Imagen</h4>
                  </Col>
                </Row>
                <Row>
                  <Col className="justify-content-center d-flex">
                    <div className="form-group">
                      <label htmlFor="chooseimage" className="uploadfile">
                        {form.image !== "" ? (
                          <img src={form.image} className="img-fluid" />
                        ) : (
                          <img src={chooseimage} className="img-fluid" />
                        )}
                      </label>
                      <input
                        type="file"
                        name="photo"
                        className="form-control"
                        onChange={inputFile}
                        id="chooseimage"
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col className="text-center">
                    <h4>Banner</h4>
                  </Col>
                </Row>
                <Row>
                  <Col className="justify-content-center d-flex">
                    <div className="form-group">
                      <label htmlFor="choosebanner" className="uploadfile">
                        {form.banner !== "" ? (
                          <img src={form.banner} className="img-fluid" />
                        ) : (
                          <img src={chooseimage} className="img-fluid" />
                        )}
                      </label>
                      <input
                        type="file"
                        name="banner"
                        className="form-control"
                        onChange={inputFileBanner}
                        id="choosebanner"
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};
export default FormProduct;
