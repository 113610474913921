import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import ItemTransfer from './item.transfer';
import { getListTransfer, onDeleteTransfer } from '../../Redux/transferDuck';
import { connect } from 'react-redux';
import SearchSection from '../Components/SearchSection';


const ListTransferDeliver = ({ getListTransfer, fetching_list, products, fetching_create, onDeleteTransfer }) => {

    const [list, setList] = useState([])

    // useEffect(() => {
    //     getListTransfer(2)
    // }, [])

    useEffect(() => {
        if (fetching_create === 2) {
            getListTransfer(2)
        }
    }, [fetching_create])

    useEffect(() => {
        if (fetching_list === 2) {
            let branch = JSON.parse(localStorage.getItem("branch"))
            let _p = products.filter(p => p.delivery_branch_id === branch.id);
            setList(_p)
        }
    }, [fetching_list])


    const onDelete = (item) => {
        if (window.confirm("Realmenre deseas Eliminar esta Transferencia?")) {
            onDeleteTransfer(item.id)
        }
    }


    const filterContent = () => {

    }

    const onCleanFilter = () => {

    }

    return (
        <>
            {/* <Row>
            <Col lg={6} className="pr-4 justify-content-end d-flex align-items-center">
                    <SearchSection
                        placeholder='Buscar Transferencia'
                        value=''
                        name='buscar_usuario'
                        onClick={filterContent}
                        onClean={onCleanFilter}
                    ></SearchSection>
                </Col>
            </Row> */}
            <Row className=''>
                <Col>
                    <table className="table table-sm table-bordered">
                        <thead className='thead-dark'>
                            <tr>
                                <th></th>
                                <th>Fecha de Transferencia</th>
                                <th>Punto Origen</th>
                                <th>Quien Solicito</th>
                                <th>Total</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(list.length > 0) ? list.map(row =>
                                <ItemTransfer type={2} title={row.branch.title} row={row} key={row.id} onDelete={onDelete} />) : <tr><td colSpan={6}>No se encontraron registros</td></tr>}
                        </tbody>
                    </table>
                </Col>
            </Row>
        </>

    )
}

const mapsState = (props) => {
    let { transfer } = props
    return {
        fetching_list: transfer.fetching_list,
        fetching_create: transfer.fetching_create,
        products: transfer.data,
    }
}
export default connect(mapsState, { getListTransfer })(ListTransferDeliver);