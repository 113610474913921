import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { AiFillEdit } from 'react-icons/ai';
import { io } from 'socket.io-client';
import { formatNumber } from '../../utils';

const ItemRequisitionEdit = ({ item, onDeleteRow, status, charge_id }) => {

    let cost = charge_id !== 0 ? parseFloat(item.cost_average) + (parseFloat(item.cost_average) * (item.charge / 100)) : item.cost_average
    let subtotal = cost * item.quantity_real


    return (
        <tr>
            <td>{item.product}</td>
            <td align='right'>{formatNumber(cost)}</td>
            <td align='right'>{item.quantity}</td>
            <td align='right'>{item.quantity_real}</td>
            <td align='right'>{formatNumber(subtotal)}</td>
            <td align='right'>
                {status === 1 &&
                    <button className='btn btn-sm btn-danger' onClick={() => onDeleteRow(item)}>Borrar</button>}

            </td>
        </tr>
    )
}

export default ItemRequisitionEdit;