import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getReviewCash } from "../../Redux/reportDuck";
import { formatNumber } from "../../utils";
import CardData from "./card.data";
import TemplateMenu from "../../template/menu.template";

const BalancePage = ({ getReviewCash, fetching, review }) => {
  const [data, setData] = useState({
    total: { nequi: 0, datafono: 0, cash: 0, daviplata: 0 },
    total_expense: [],
    branch: {},
  });

  const history = useHistory();
  const [expenseNequi, setExpenseNequi] = useState(0);
  const [expenseCash, setExpenseCash] = useState(0);
  const [expenseDatafono, setExpenseDatafono] = useState(0);
  const [expenseDaviplata, setExpenseDaviplata] = useState(0);
  const [datafono, setDatafono] = useState(0);
  const [datafonoAvailable, setDatafonoAvailable] = useState(0);

  useEffect(() => {
    if (fetching === 2) {
      let expense_nequi =
        review.total_expense.filter((r) => r.id === 16)[0] || 0;
      let expense_cash = review.total_expense.filter((r) => r.id == 374)[0];
      let expense_datafono = review.total_expense.filter(
        (r) => r.description == "DATAFONO"
      )[0];
      let expense_daviplata = review.total_expense.filter(
        (r) => r.description == "DAVIPLATA"
      )[0];

      expense_nequi = expense_nequi === undefined ? 0 : expense_nequi.total;
      expense_cash = expense_cash === undefined ? 0 : expense_cash.total;
      expense_datafono =
        expense_datafono === undefined ? 0 : expense_datafono.total;
      expense_daviplata =
        expense_daviplata === undefined ? 0 : expense_daviplata.total;

      setExpenseCash(expense_cash);
      setExpenseNequi(expense_nequi);
      setExpenseDatafono(expense_datafono);
      setExpenseDaviplata(expense_daviplata);

      let _datafono =
        review.total.datafono_with_tax !== "" &&
        review.total.datafono_with_tax !== "0"
          ? review.total.datafono_with_tax
          : review.total.datafono;
      setDatafono(_datafono);
      setDatafonoAvailable(_datafono - expense_datafono);

      setData(review);
    }
  }, [fetching]);

  useEffect(() => {
    getReviewCash();
  }, []);

  const goToExpense = (type) => {
    history.push(`/balance/${type}`);
  };
  const onChangeView = () => {
    history.push(`/balance-alternative`);
  };

  return (
    <TemplateMenu>
      <Container fluid className="mt-2">
        <Row>
          <Col lg={10}>
            <h2>Balance ({data.branch.title})</h2>{" "}
            <h4>
              {data.init} / {data.end}
            </h4>
          </Col>
          <Col lg={2} className="text-right">
            <button className="btn btn-info" onClick={onChangeView}>
              Cambio de Vista
            </button>
          </Col>
        </Row>
        <Row>
          <Col
            lg={4}
            className="my-1 cursor-pointer"
            onClick={() => goToExpense("374")}
          >
            <CardData
              onClick={() => goToExpense("374")}
              title="EFECTIVO"
              amount={data.total.cash}
              expense={expenseCash}
              available={data.total.cash - expenseCash - data.total.tips || 0}
              tips={data.total.tips}
            />
          </Col>
          <Col lg={4} className="my-1" onClick={() => goToExpense("375")}>
            <CardData
              onClick={() => goToExpense("375")}
              title="DATAFONO"
              amount={datafono}
              expense={expenseDatafono}
              available={datafonoAvailable || 0}
            />
          </Col>
        </Row>
        <Row className="my-2">
          <Col lg={4}>
            <CardData
              onClick={() => goToExpense("16")}
              title="BANCOLOMBIA"
              amount={data.total.nequi}
              expense={expenseNequi || 0}
              available={data.total.nequi - (expenseNequi || 0)}
            />
          </Col>
          <Col lg={4} className="my-1 cursor-pointer">
            <CardData
              onClick={() => goToExpense("17")}
              title="DAVIPLATA"
              amount={data.total.daviplata}
              expense={expenseDaviplata}
              available={data.total.daviplata - expenseDaviplata || 0}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <h3>TOTAL DISPONIBLE</h3>
          </Col>
          <Col lg={2}>
            <h3>
              {formatNumber(
                data.total.daviplata -
                  expenseDaviplata +
                  (data.total.nequi -
                    (expenseNequi || 0) +
                    (datafonoAvailable || 0) +
                    (data.total.cash - expenseCash - data.total.tips || 0))
              )}
            </h3>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <h3>TOTAL GASTO</h3>
          </Col>
          <Col lg={2}>
            <h3>
              {formatNumber(
                parseFloat(expenseCash) +
                  parseFloat(expenseDaviplata) +
                  parseFloat(expenseNequi) +
                  parseFloat(expenseDatafono)
              )}
            </h3>
          </Col>
        </Row>
      </Container>
    </TemplateMenu>
  );
};

const mapsState = (props) => {
  return {
    review: props.report.data,
    fetching: props.report.fetching,
  };
};
export default connect(mapsState, { getReviewCash })(BalancePage);
