import React, { useEffect, useState } from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap';

const PaymentBar = ({ user, isPayment }) => {
    let { plan } = user

    const [data, setData] = useState({})
    const [payment, setPayment] = useState({})
    const [modal, setModal] = useState(false)
    const [days, setDays] = useState(0)
    const [message, setMessage] = useState(0)

    useEffect(() => {
        if (plan !== undefined) {
            setData(plan)

            if (plan.payment === null) {
                setModal(true)
                setMessage("No se ha reportado tu Pago.")
            } else {
                setDays(plan.payment.date_diff)
                setPayment(plan.payment)

                if (plan.payment.date_diff > 4) {

                    let word = plan.payment.date_diff === 1 ? 'día' : 'días'

                    setModal(true)
                    setMessage(`Hace (${plan.payment.date_diff} ${word}) No se ha reportado el Pago correspondiente.`)
                }
            }
        }
    }, [plan])



    return (
        <>{isPayment === false && <Container fluid className='fixed-top'>
            <Row className='bg-danger'>
                <Col className='text-white my-2 text-center'><h5>Tu Pago esta vencido hace {days} {days === 1 ? 'día' : 'días'}, por favor Cancelar para poder Seguir usando la Plataforma</h5></Col>
            </Row>
        </Container>
        }
            <Modal Modal show={modal} size={"lg"} centered>
                <Modal.Body>
                    <Container className='my-4'>
                        <Row>
                            <Col className='text-center text-red'><h2>Lo sentimos, lo Días permitidos se han agotado</h2></Col>
                        </Row>
                        <Row>
                            <Col className='text-center text-red'><h3>{message}</h3></Col>
                        </Row>
                    </Container>
                </Modal.Body></Modal>
        </>

    )
}

export default PaymentBar;