import React from 'react'
import { Col, Row } from 'react-bootstrap';
import ItemPrescription from './item.prescription';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { MdAddCircleOutline, MdDelete } from 'react-icons/md';


const ItemCategoryPrescription = ({ item, onDeleteRecipeProduct, onDeleteCategory }) => {
    let { recipe } = item || []

    return (
        <>
            <Row className='mt-2 d-flex justify-content-center'>
                <Col className='text-center'>
                    {recipe.length === 0 && <button className='btn' onClick={() => onDeleteCategory(item)} to={`/recetario/new?category_id=${item.id}`}>
                        <MdDelete color='red' size={25} />
                    </button>}
                    <span className='font-weigth mr-2'>{item.name}</span><Link to={`/recetario/new?category_id=${item.id}`}><MdAddCircleOutline color='green' size={25} /></Link>
                </Col>
            </Row>

            <Row className='border-bottom'>
                {(recipe !== undefined && recipe.length > 0) ? recipe.map((d, k) => <ItemPrescription key={k} item={d} onDeleteRecipeProduct={onDeleteRecipeProduct} />) : <Col className='text-center my-3'>No tienes Recetas Adicionales en esta Categoria</Col>}
            </Row>

        </>
    )
}

export default ItemCategoryPrescription;