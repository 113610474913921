import React, { useEffect } from 'react';
import { Modal, Container, Row, Col, Card } from 'react-bootstrap';

import { MdClose } from "react-icons/md";
import { connect } from 'react-redux';
import { exchangeDining } from '../../Redux/reservationDuck'
import { updateChangeDining } from '../../Redux/reservationDuck'
import BtnOrangeSm from '../Components/Widgets/BtnOrangeSm'
import BtnBlueModal from '../Components/Widgets/BtnBlueModal'


const ReassignModal = ({ show, first, second, onHide, onConfirm }) => {
    return (
        <Modal show={show}>
            <Modal.Header className=" border-bottom-custom py-1 m-0">
                <h5 className="align-middle text-gris font-15 pt-3 pl-3">Intercambio de Mesa</h5>
                <button type="button" className="close p-0 m-0" data-dismiss="modal" aria-label="Close" onClick={onHide}>

                    <MdClose />
                </button>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            <Card className="rounded-lg">
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <h4 className="text-naranja">Actual</h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col><span className="text-gray-60 pr-2">Reserva</span> {first.reservation_id}</Col>
                                    </Row>
                                    <Row>
                                        <Col><span className="text-gray-60 pr-2">Mesa</span> {first.position}</Col>
                                    </Row>
                                    <Row>
                                        <Col><span className="text-gray-60 pr-2">Horario</span> {first.content}</Col>
                                    </Row>
                                </Card.Body>

                            </Card>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg={3}>
                            {/* <img src={rotate} className="px-4 img-fluid arrow_rotate mt-4" /> */}
                        </Col>
                    </Row>
                    <Row className="pt-3">
                        <Col>
                            <Card className="rounded-lg">
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <h4 className="text-naranja">Nuevo</h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col><span className="text-gray-60 pr-2">Mesa</span> {second.position}</Col>
                                    </Row>
                                    <Row>
                                        <Col><span className="text-gray-60 pr-2">Horario</span>{second.content}</Col>
                                    </Row>
                                </Card.Body>

                            </Card>
                        </Col>
                    </Row>
                    <Row className="justify-content-center mt-2">
                        <Col>
                            <BtnOrangeSm
                                title='ReAsignar'
                                onClick={onConfirm}
                            />
                        </Col>
                        <Col>
                            <BtnBlueModal
                                title='Cerrar'
                                onClick={onHide}
                            />
                        </Col>
                    </Row>
                </Container>

            </Modal.Body>
        </Modal>

    )
}

const mapsState = (props) => {
    return {

    }
}

export default connect(mapsState, { updateChangeDining })(ReassignModal);