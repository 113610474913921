import React, { useState, useEffect } from 'react'
import { Modal, Container, Row, Col, Button } from 'react-bootstrap';
import { MdClose } from "react-icons/md";

import InputField from '../Components/Widgets/InputField';
import { connect } from 'react-redux';
import { createCategory } from '../../Redux/categoryDuck'
import BtnOrangeSm from './../Components/Widgets/BtnOrangeSm'
import BtnBlueModal from './../Components/Widgets/BtnBlueModal'
import BlueTitleBack from './../Components/Widgets/BlueTitleBack'
import chooseimage from './../../Images/no_available.png'

const API_URL = process.env.REACT_APP_API_URL



const ModalCategory = ({ showModal, onHide, errors, onSuccess, selected, fetching, createCategory }) => {
    const initState = { title: '', description: '', priority: '', status_id: false, type_menu_id: '' };

    const [form, setForm] = useState({ title: '' })
    const [photo, setPhoto] = useState('')
    const [banner, setBanner] = useState('')


    useEffect(() => {

        if (fetching === 2) {
            onSuccess()
            setForm(initState);
        }
    }, [fetching])

    useEffect(() => {

        if (Object.keys(selected).length > 0) {
            selected.status_id = (selected.status_id === 2) ? false : true;
            selected.photo = (selected.image == undefined) ? chooseimage : API_URL + selected.image;
            selected.banner = (selected.banner == undefined) ? chooseimage : API_URL + selected.banner;
            setForm(selected);

        }
        else {
            if (fetching !== 3 && fetching !== 2) {
                setForm(initState)
            }
        }
    }, [selected])

    const closeModalTmp = () => {
        onHide()
    }

    const updateInput = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const updateCheck = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.checked
        })
    }

    const onSubmit = () => {
        let _form = form;
        _form.photo = photo;
        _form.banner = banner;
        createCategory(form)
    }

    const inputFile = (e) => {
        setPhoto(e.target.files[0]);
        let file = e.target.files[0];

        let reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setForm({ ...form, photo: reader.result })
            }
        }
        reader.readAsDataURL(file)
    }

    const inputFileBanner = (e) => {
        setBanner(e.target.files[0])
        let file = e.target.files[0];

        let reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setForm({ ...form, banner: reader.result })
            }
        }
        reader.readAsDataURL(file)
    }


    console.log('errors', errors)

    return (
        <Modal centered show={showModal} onHide={closeModalTmp} size="lg">
            <div className="modal-header border-bottom-custom mt-2">
                <BlueTitleBack title='CATEGORÍA' />
                <button type="button" className="close p-0 m-0" data-dismiss="modal" aria-label="Close" onClick={closeModalTmp}>
                    <MdClose />
                </button>
            </div>
            <Modal.Body>
                <Container fluid>

                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Titulo Categoría</label>
                                        <InputField
                                            placeholder="Titulo de la Categoría"
                                            value={form.title || ''}
                                            name="title"
                                            onChange={updateInput}
                                            error={errors.title}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Descripción Categoría</label>
                                        <InputField
                                            placeholder="Descripcion de la Categoría"
                                            value={form.description || ''}
                                            name="description"
                                            onChange={updateInput}
                                            error={errors.description}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Orden</label>
                                        <InputField
                                            type="number"
                                            placeholder="Orden"
                                            value={form.priority || ''}
                                            name="priority"
                                            onChange={updateInput}
                                            error={errors.priority}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label>Tipo de Categoría</label>
                                        <select name="type_menu_id" value={form.type_menu_id} onChange={updateInput} className="form-control">
                                            <option value="0">Seleccione</option>
                                            <option value="1">Alimento</option>
                                            <option value="2">Bebida</option>
                                        </select>
                                    </div>
                                    {errors.type_menu_id !== undefined && (<label className="error-label">{errors.type_menu_id}</label>)}

                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label>Estado</label>
                                        <input
                                            type="checkbox"
                                            className="form-control"
                                            checked={form.status_id || false}
                                            name="status_id"
                                            onChange={updateCheck}
                                        />
                                    </div>
                                </Col>
                            </Row>


                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <div className="form-group">
                                                <label>Foto Banner</label>
                                                {/* <input
                                                    type="file"
                                                    className="form-control"
                                                    checked={form.banner || false}
                                                    name="banner"
                                                    onChange={inputFileBanner}
                                                /> */}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="justify-content-center text-center">
                                            <div className="form-group">
                                                <label htmlFor="chooseimage" className="uploadfile">
                                                    {form.banner !== '' ? (<img src={form.banner} className="img-fluid" />) : <img src={chooseimage} />}

                                                </label>
                                                <input type="file" name="banner" className="form-control" onChange={inputFileBanner} id="chooseimage" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col>
                                    <Row>

                                        <Col>
                                            <div className="form-group">
                                                <label>Foto</label>
                                                {/* <input
                                                    type="file"
                                                    className="form-control"
                                                    checked={form.photo || false}
                                                    name="photo"
                                                    onChange={inputFile}
                                                /> */}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="justify-content-center text-center">
                                            <div className="form-group">
                                                <label htmlFor="chooseimage" className="uploadfile">
                                                    {form.photo !== '' ? (<img src={form.photo} className="img-fluid" />) : <img src={chooseimage} />}

                                                </label>
                                                <input type="file" name="photo" className="form-control" onChange={inputFile} id="chooseimage" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>
                        </Col>
                    </Row>

                </Container>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Row >
                    <Col>
                        <BtnOrangeSm
                            title='Guardar'
                            onClick={onSubmit}
                        />
                    </Col>
                    <Col>
                        <BtnBlueModal
                            title='Cerrar'
                            onClick={closeModalTmp}
                        />
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

const mapsState = (props) => {
    console.log(props)
    return {
        errors: props.category.errors,
        fetching: props.category.fetching_create
    }
}

export default connect(mapsState, { createCategory })(ModalCategory);