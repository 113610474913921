import React, { useState, useEffect } from 'react'
import { Modal, Container, Row, Col, Button } from 'react-bootstrap';
import { MdClose } from "react-icons/md";
import InputField from '../Components/Widgets/InputField';
import { connect } from 'react-redux';
import { createOrUpdateSupplier } from '../../Redux/supplierDuck'


const initState = {
    business: '', business_name: '', phone: '', email: '', status: true,
    department_id: 0, city_id: 0, address: '', is_declarant: false, type_supplier_id: 1
};

const ModalParameter = ({ showModal, onHide, errors, fetching_create, createOrUpdateSupplier, onSuccess, formEdit, listDepartment }) => {


    const [form, setForm] = useState(initState)
    const [listCity, setListCity] = useState([])


    useEffect(() => {
        if (fetching_create === 2) {
            onSuccess()
            onHide()
        }
    }, [fetching_create])



    useEffect(() => {

        if (listDepartment.length > 0) {
            let department = listDepartment.filter(d => parseInt(d.id) === parseInt(formEdit.department_id))[0]

            if (department !== undefined) {
                setListCity(department.city)
            }
        }


        if (Object.keys(formEdit).length === 0) {
            setForm(initState)
        } else {
            setForm(formEdit)
        }


    }, [formEdit])

    const closeModalTmp = () => {
        onHide()
    }

    const updateInput = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        if (e.target.name === 'department_id') {
            let department = listDepartment.filter(d => parseInt(d.id) === parseInt(e.target.value))[0]
            setListCity(department.city)
        }
    }

    const updateCheck = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.checked
        })
    }

    const onSubmit = () => {
        createOrUpdateSupplier(form)
    }


    return (
        <Modal show={showModal} onHide={closeModalTmp} size={"lg"}>
            <div className="modal-header border-bottom-custom">
                <h5 className="modal-title text-center">Proveedores</h5>
                <button type="button" className="close p-0 m-0" data-dismiss="modal" aria-label="Close" onClick={closeModalTmp}>
                    <MdClose />
                </button>
            </div>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Alias</label>
                                <InputField
                                    placeholder="Alias "
                                    value={form.business || ''}
                                    name="business"
                                    onChange={updateInput}
                                    error={errors.business}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Razon Social</label>
                                <InputField
                                    placeholder="Razón Social"
                                    value={form.business_name || ''}
                                    name="business_name"
                                    onChange={updateInput}
                                    error={errors.business_name}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Departamento</label>
                                <select className='form-control' name="department_id" value={form.department_id} onChange={updateInput}>
                                    <option>Seleccione</option>
                                    {listDepartment.map(d => <option value={d.id} key={d.id}>{d.description}</option>)}
                                </select>
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Ciudad</label>
                                <select className='form-control' name="city_id" value={form.city_id} onChange={updateInput}>
                                    <option>Seleccione</option>
                                    {listCity.map(d => <option value={d.id} key={d.id}>{d.description}</option>)}
                                </select>
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Dirección</label>
                                <InputField
                                    placeholder="Dirección"
                                    value={form.address || ''}
                                    name="address"
                                    onChange={updateInput}
                                    error={errors.address}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>

                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Identificación</label>
                                <InputField
                                    placeholder="Identificacón"
                                    value={form.document || ''}
                                    name="document"
                                    onChange={updateInput}
                                    error={errors.document}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Email</label>
                                <InputField
                                    type="email"
                                    placeholder="Ingresa tu Email"
                                    value={form.email || ''}
                                    name="email"
                                    onChange={updateInput}
                                    error={errors.email}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Telefono</label>
                                <InputField
                                    placeholder="Ingresa tu celular"
                                    value={form.phone || ''}
                                    name="phone"
                                    onChange={updateInput}
                                    error={errors.phone}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Estado</label>
                                <input
                                    type="checkbox"
                                    className="form-control"
                                    checked={form.status}
                                    name="status"
                                    onChange={updateCheck}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>¿Es declarante?</label>
                                <input
                                    type="checkbox"
                                    className="form-control"
                                    checked={form.is_declarant || false}
                                    name="is_declarant"
                                    onChange={updateCheck}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>¿Tipo de Proveedor?</label>
                                <select className='form-control' name="type_supplier_id" value={form.type_supplier_id} onChange={updateInput}>
                                    <option value={0}>Seleccione</option>
                                    <option value={1}>Productos</option>
                                    <option value={2}>Servicios</option>
                                </select>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={closeModalTmp}>Cerrar</Button>
                <Button variant="primary" onClick={onSubmit}>Guardar</Button>
            </Modal.Footer>
        </Modal>
    )
}

const mapsState = (props) => {

    let { supplier } = props
    return {
        errors: supplier.errors,
        fetching_create: supplier.fetching_create,

    }
}

export default connect(mapsState, { createOrUpdateSupplier })(ModalParameter);