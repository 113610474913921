import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import TemplateMenu from '../../template/menu.template'
import { getReviewFranchise } from '../../Redux/reportDuck'
import { getFranchiseByMethod } from '../../Redux/parameterDuck'
import { addFranchiseManual } from '../../Redux/franchiseDuck'
import ItemTableFranchise from './item.table.franchise';
import { formatNumber } from '../../utils';
import ItemTableFranchiseValues from './item.table.franchise.values';
import moment from 'moment';
moment.tz("America/bogota")


const FranchiseReportPage = ({ getReviewFranchise, fetching_by_end, data_end, getFranchiseByMethod, fetching_franchise_list, data_franchise,
    addFranchiseManual, fetching_franchise }) => {

    const [list, setList] = useState([])
    const [listFranchise, setListFranchise] = useState([])
    const [listManual, setListManual] = useState([])
    const [totalReal, setTotalReal] = useState(0)
    const [totalTax, setTotalTax] = useState(0)
    const [total, setTotal] = useState(0)
    const [totalManual, setTotalManual] = useState(0)
    const [filter, setFilter] = useState({ franchise_id: 0, init: moment().format("YYYY-MM-01"), end: moment().format("YYYY-MM-DD") })
    const [filterManual, setFilterManual] = useState({ franchise_id: 0, init: moment().format("YYYY-MM-01"), end: moment().tz("America/bogota").format("YYYY-DD-MM") })

    useEffect(() => {
        // getReviewFranchise();
        getFranchiseByMethod({ id: 375 });
    }, [])


    useEffect(() => {
        if (fetching_by_end === 2 || fetching_by_end === 3) {

            // console.log('data_end', data_end);

            // let _total = data_end.reduce((a, b) => a = a + parseFloat(b.subtotal || 0), 0)
            // let _tax = data_end.reduce((a, b) => a = a + parseFloat(b.tax || 0), 0)
            // let _totalReal = data_end.reduce((a, b) => a = a + parseFloat(b.total || 0), 0)
            // setTotal(_total)
            // setTotalTax(_tax)
            // setTotalReal(_totalReal)

            // setList(data_end)
            // if (fetching_by_end !== 3) {
            //     setListManual(data_end)
            // }

        }

    }, [fetching_by_end])

    useEffect(() => {
        if (fetching_franchise_list === 2) {
            setListFranchise(data_franchise);
        }
    }, [fetching_franchise_list])


    const onFilter = (e) => {
        if (filter.franchise_id === 0) {
            getReviewFranchise();
        } else {
            let url = `&franchise_id=${filter.franchise_id}&init=${filter.init}&end=${filter.end}`
            getReviewFranchise(url);
        }

    }

    const onFilterManual = (e) => {
        if (filterManual.franchise_id === 0) {
            getReviewFranchise();
        } else {
            let url = `&franchise_id=${filterManual.franchise_id}&init=${filterManual.init}&end=${filterManual.end}`
            getReviewFranchise(url);
        }
    }

    const onUpdateList = (item) => {
        addFranchiseManual(item)
    }

    const updateInput = (e) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        })
    }

    const inputField = (e) => {
        setFilterManual({
            ...filterManual,
            [e.target.name]: e.target.value
        })
    }


    return (
        <TemplateMenu>
            <Row className='my-3'>
                <Col lg={2}>Reporte de Pago por Franquicias</Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col><h4>Automaticos</h4></Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className='form-group'>
                                        <label>Franquicia</label>
                                        <select className='form-control' name='franchise_id' onChange={updateInput} value={filter.franchise_id}>
                                            <option value="0">TODOS</option>
                                            {listFranchise.map(l => <option value={l.id} key={l.id}>{l.parameter.description}</option>)}
                                        </select>
                                    </div>
                                </Col>
                                <Col>
                                    <div className='form-group'>
                                        <label>Fecha de Inicio</label>
                                        <input type={"date"} className='form-control' name='init' value={filter.init} onChange={updateInput} />
                                    </div>
                                </Col>
                                <Col>
                                    <div className='form-group'>
                                        <label>Fecha de Inicio</label>
                                        <input type={"date"} className='form-control' name='end' value={filter.end} onChange={updateInput} />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <button className='btn btn-success btn-sm' onClick={onFilter}>Filtrar</button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col >
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col><h4>Manual</h4></Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className='form-group'>
                                        <label>Franquicia</label>
                                        <select className='form-control' onChange={onFilterManual}>
                                            <option value="0">Seleccione</option>
                                            {listFranchise.map(l => <option value={l.id} key={l.id}>{l.parameter.description}</option>)}
                                        </select>
                                    </div>
                                </Col>
                                <Col>
                                    <div className='form-group'>
                                        <label>Fecha de Inicio</label>
                                        <input type={"date"} className='form-control' value={filterManual.init} name="init" onChange={inputField} />
                                    </div>
                                </Col>
                                <Col>
                                    <div className='form-group'>
                                        <label>Fecha de Inicio</label>
                                        <input type={"date"} className='form-control' value={filterManual.end} name="end" onChange={inputField} />
                                    </div>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>

                </Col>
            </Row>
            <Row>
                <Col className='pr-0'>
                    <Table className='table-sm table-bordered table-hover table-striped'>
                        <thead className='thead-dark'>
                            <tr>
                                <th>Fecha</th>
                                <th>Franquicia</th>
                                <th>Porcentaje</th>
                                <th>Valor Sin Impuesto</th>
                                <th>Total Impuesto</th>
                                <th>Total Ingreso</th>
                            </tr>
                        </thead>

                        <tbody>
                            {list.map((row, k) => <ItemTableFranchise key={k} item={row} />)}

                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan={3}>TOTALES</th>
                                <th>{formatNumber(total)}</th>
                                <th>{formatNumber(totalTax)}</th>
                                <th>{formatNumber(totalReal)}</th>
                            </tr>
                        </tfoot>
                    </Table>
                </Col>
                <Col lg={2} className="pl-0">
                    <Table className='table-sm table-bordered table-hover table-striped'>
                        <thead className='thead-dark'>
                            <tr>
                                <th>Valor Manual</th>
                                <th>Agregar</th>
                            </tr>
                        </thead>

                        <tbody>
                            {listManual.map((row, k) => <ItemTableFranchiseValues key={k} item={row}
                                onUpdateList={onUpdateList} />)}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>{formatNumber(totalManual)}</th>
                            </tr>
                        </tfoot>
                    </Table>
                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { report, parameter, franchise } = props


    return {
        fetching_by_end: report.fetching_by_end,
        data_end: report.data_end.results,
        fetching_franchise_list: parameter.fetching_franchise_list,
        data_franchise: parameter.data_franchise,
        fetching_franchise: franchise.fetching
    }
}

export default connect(mapsState, { getReviewFranchise, getFranchiseByMethod, addFranchiseManual })(FranchiseReportPage);