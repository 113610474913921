import React, { useEffect, useState } from 'react'
import TemplateMenu from "../../template/menu.template";
import { connect } from 'react-redux';
import { Col, Row, Table } from 'react-bootstrap';
import { getProductsCharge, onCreateProductsCharge } from '../../Redux/ProductInventoryDuck'
import { toogleLoader } from '../../Redux/alertDuck'
import RowCharge from './row.charge';
import { formatNumber } from '../../utils';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import FormCharge from './form.charge';


const CreateChargePage = ({ getProductsCharge, toogleLoader, fetching_charge, data_charge,
    onCreateProductsCharge, fetching_create_charge }) => {

    const [list, setList] = useState({})

    const history = useHistory();

    useEffect(() => {
        if (fetching_create_charge === 2) {
            history.goBack()
        }
    }, [fetching_create_charge])

    useEffect(() => {
        if (fetching_charge === 2) {
            toogleLoader(false)
            setList(data_charge)
        }
    }, [fetching_charge])

    useEffect(() => {
        getProductsCharge()
        toogleLoader(true)
    }, [])



    const onSucess = (form) => {
        onCreateProductsCharge(form)
    }

    return (
        <TemplateMenu>
            <Row>
                <Col><h4>Crear Listado</h4></Col>
            </Row>
            <FormCharge list={list} onCreate={onSucess} />


        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { product_inventory } = props
    return {
        fetching_charge: product_inventory.fetching_charge,
        fetching_create_charge: product_inventory.fetching_create_charge,
        data_charge: product_inventory.data_charge,
    }
}

export default connect(mapsState, { getProductsCharge, toogleLoader, onCreateProductsCharge })(CreateChargePage);