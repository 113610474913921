import React, { useState } from 'react'

const ItemNovelty=({row, onRemoveItem,  onChange})=>{
    const [form,setForm] = useState(row.checked)

    const updateInput=(e)=>{
        setForm(e.target.checked)
        row.checked = e.target.checked;
        onChange(row)
    }

    let { parameter } = row
        return <tr>
            <td>{row.registration_date}</td>
            <td>{parameter.description}</td>
            <td>{row.reason}</td>
            <td>
                {row._id !== undefined && <button className='btn btn-danger btn-sm' onClick={() => onRemoveItem(row)}>X</button>}
            </td>
            <td><input type="checkbox" className='form-control-custom' checked={form} onChange={updateInput}/></td>
        </tr>
}

export default ItemNovelty;