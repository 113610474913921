import React, { useEffect, Fragment, useState, useRef, useCallback, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getListBranch } from '../../Redux/branchofficeDuck'
import {
    getDateReserv, cancelReservation, getSchedule, getReservationDetail, joinReservation,
    getDiningAvailableChange, updateChangeDining
} from '../../Redux/reservationDuck'
import { toogleAlert } from '../../Redux/alertDuck'
import FormreservationModal from './formreservation.modal';
import SelectFieldReservation from '../Components/Widgets/SelectFieldReservation';
import SelectFieldDate from './SelectFieldDate';

import DetailReservationItem from './detail_reservation_item';
import ReservationModal from './reservation.modal';
import EditmodalTable from './editTable.modal';
import ChangeDiningModal from './changeDining.modal';
import ReassignModal from './reassign.modal';
import { SocketContext } from '../../context/SocketContext';
import Swal from 'sweetalert2'
import './index.css'


const API_URL = process.env.REACT_APP_API;

const AllReservationPage = ({ branch, fetching, getSchedule, getDateReserv, dates, schedule, fetching_schedule,
    cancelReservation, fetching_cancel, current_page, last_page, getReservationDetail, fetching_id, detail_reservation,
    joinReservation, fetching_activate, toogleAlert, getListBranch, getDiningAvailableChange, fetching_available,
    availables, updateChangeDining, fetching_change, user, fetching_dates, date_text, date_selected }) => {

    const initalState = { temperature: '', symptoms: false }

    const [status, setStatus] = useState(0)

    const { socket } = useContext(SocketContext)

    const [showModal, setShowModal] = useState(false)
    const [modalReassign, setModalreassign] = useState(false)
    const [modalChange, setModalChange] = useState(false)
    const [showModalEdit, setShowModalEdit] = useState(false)
    const [show, setShow] = useState(false)
    const [row, setRow] = useState([])
    const [selected, setSelected] = useState({})
    const [second, setSecond] = useState({})
    const [formTemp, setFormTemp] = useState(initalState)

    const [form, setForm] = useState({ date_start: '', branch_id: 0 })
    const [content, setContent] = useState({})

    const [listBranch, setListBranch] = useState([])
    const [client, setClient] = useState({})

    const observer = useRef()
    const reference = useRef()
    const referenceBranch = useRef()

    useEffect(() => {
        toogleAlert(true, "Espere por favor...")
        getDateReserv(1)
        getListBranch()

    }, [])


    useEffect(() => {
        if (fetching_dates === 2 && user !== undefined) {

            setForm({
                ...form,
                branch_id: user.branch_id
            })

            if (user.branch_id !== undefined) {
                getSchedule(reference.current.value, user.branch_id, current_page, true)
            }
        }
    }, [fetching_dates, user])

    useEffect(() => {
        setForm({
            ...form,
            branch_id: user.branch_id
        })
    }, [user])

    useEffect(() => {
        if (fetching_schedule === 2) {
            setListBranch(schedule)
            toogleAlert(false)
        }
    }, [fetching_schedule])

    useEffect(() => {
        if (fetching_available === 2) {
            setShowModalEdit(true)
            toogleAlert(false)
        }
    }, [fetching_available])

    useEffect(() => {
        if (fetching_change === 2) {
            setShowModalEdit(false)
            setModalreassign(false)
            setSelected({})
            setSecond({})
            toogleAlert(false)
            getSchedule(form.date_start, form.branch_id, current_page, true)
        }
    }, [fetching_change])


    useEffect(() => {
        if (fetching_id === 2) {
            toogleAlert(false)
            setClient(detail_reservation)
            setShow(true);
        }

    }, [fetching_id])

    useEffect(() => {

        if (fetching_cancel === 2) {
            getSchedule(form.date_start, form.branch_id, current_page, true)
        }

    }, [fetching_cancel])

    // useEffect(() => {

    //     const socket = socketIOClient(ENDPOINT);
    //     socket.on("connect", function () {
    //         console.log("connected socket reservation")
    //     })

    //     socket.on("message", data => {
    //         let { type, row } = data;

    //         if (type === 'confirm-aldelo-reservation') {
    //             updateList(row)
    //         }
    //         if (type === 'new-reservation') {
    //             newReservation(row)
    //         }

    //         console.log("llego nuevo dato reserva", data);

    //     });

    //     return () => socket.disconnect();
    // }, [listBranch])


    useEffect(() => {

        if (socket !== null) {
            socket.on("confirm-reservation", (reservation) => {
                newReservation(reservation)
            })


            socket.on("saved-aldelo", (reservation) => {
                updateList(reservation)
                console.log('reservation', reservation)
            })
        }

    }, [socket, listBranch])


    useEffect(() => {

        if (socket !== null) {
            socket.on("cancel-reservation", (reservation) => {
                newReservation(reservation)
            })



        }

    }, [socket, listBranch])

    useEffect(() => {

        if (socket !== null) {
            socket.on("reassign-reservation", (reservation) => {
                console.log('reservation-', reservation)
                setShowModalEdit(false)
                setModalreassign(false)
                setSelected({})
                setSecond({})
                toogleAlert(false)

                getSchedule(reference.current.value, referenceBranch.current.value, current_page, true)
            })
        }

    }, [socket, listBranch])


    useEffect(() => {

        if (socket !== null) {
            socket.on("activate-reservation-confirm", (reservation) => {
                console.log('reservation...', reservation)
                updateList(reservation)

            })

            socket.on("message", data => {
                let { type, row } = data;

                if (type === 'new-reservation') {
                    newReservation(row)
                }

                console.log("llego nuevo dato reserva", data);

            });
        }

    }, [socket, listBranch])


    useEffect(() => {

        if (socket !== null) {
            socket.on("error_reservation", (message) => {
                toogleAlert(false)
                Swal.fire({

                    title: "Servidor Offline",
                    text: message.message,
                    icon: "error",
                })
            })

            socket.on("error-reservation", (message) => {
                toogleAlert(false)
                Swal.fire({

                    title: "Error",
                    text: "Datos ingresado",
                    icon: "error",


                })
            })

            socket.on("error-available", (message) => {
                console.log('message', message)
                toogleAlert(false)
                Swal.fire({
                    title: "No disponible",
                    text: message.message,
                    icon: "error",
                })
            })



        }

    }, [socket])

    const newReservation = (row) => {

        let _listBranch = [...listBranch]

        _listBranch.map((item, i) => {

            if (item.dining_table_id === row.dining_table_id && row.hour_start === item.hour_start && row.position === item.position) {
                _listBranch[i].reservation_id = row.reservation_id
                _listBranch[i].name = row.name
                _listBranch[i].email = row.email
                _listBranch[i].phone = row.phone
                _listBranch[i].pet_client = row.pet_client
                _listBranch[i].reservation_person = row.reservation_person
                _listBranch[i].reservation_status_id = row.reservation_status_id
                _listBranch[i].recommendation = row.recommendation
                _listBranch[i].aldelo_id = row.aldelo_id
                _listBranch[i].order_day = row.order_day
            }
        })


        setListBranch(_listBranch)
        toogleAlert(false)
        setShow(false)
        setForm({})
        onHide()
    }

    const updateList = (row) => {

        let _listBranch = [...listBranch]

        _listBranch.map((item, i) => {

            if (item.reservation_id !== null) {
                if (item.reservation_id === row.id) {
                    _listBranch[i].reservation_status_id = row.status_id
                    _listBranch[i].order_day = row.order_day
                    _listBranch[i].aldelo_id = row.aldelo_id
                }
            }
        })


        setListBranch(_listBranch)
        toogleAlert(false)
        setShow(false)
        onHide()
    }

    const onHideActivate = () => {
        setShow(false)

    }

    const onSubmit = (form) => {
        let error = ''

        // if (form.symptons === false) {
        //     error = "Sintomas"

        // }
        // if (form.temperature < 30 || form.temperature > 38) {
        //     error = +"Temperatura"
        // }

        // if (error !== '') {
        //     alert("Problemas con la temperatura o el sintoma")
        //     return;
        // }



        //let { schedule } = client

        // client.temperature = form.temperature
        // client.symptoms = form.symptoms

        if (window.confirm(`¿Realmente deseas Activar la Reserva ${form.reservation_id}?`)) {
            toogleAlert(true, "Esperando confirmación de Pos Aldelo ...")

            if (socket) {
                // socket.emit("activate-reservation", { client, schedule })
                socket.emit("activate-reservation", { form })
            }
        }




        // joinReservation(client, schedule)
    }


    const setReservationItem = (item) => {

        if (item.reservation_status_id === 1) {
            setFormTemp(initalState)
            toogleAlert(true)
            console.log('llego', item)
            onSubmit(item)
            //getReservationDetail(item)
        }
    }

    const cancelReservationForm = (id) => {
        if (window.confirm("¿Deseas cancelar esta Reserva?")) {

            cancelReservation(id)
        }
    }

    const reserve = (item) => {

        let date_start = reference.current.value

        setRow(item)
        setShowModal(true)

        setForm({
            ...form,
            date_start,
            branch_id: item.branch_id
        })
    }

    const updateField = useCallback((e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        toogleAlert(true, "Espere por favor...")

        getSchedule(e.target.value, form.branch_id, 1, true);
    })

    const updateFieldBranch = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        toogleAlert(true, "Espere por favor...")
        // observer.current.disconnect()
        getSchedule(form.date_start, e.target.value, current_page, true);
    }

    const onHide = () => {
        setShowModal(false);
        setSelected({})
    }
    const onCreateReservation = (_form) => {

        if (socket) {

            _form.date_start = form.date_start
            socket.emit("create_reservation", _form)
        }
    }

    const showReservation = (item) => {
        setContent(item)
    }
    const restartContent = () => {
        setContent({})
    }
    const cancelReservationTemp = useCallback((item) => {

        Swal.fire({
            title: "¿Deseas cancelar esta Reserva?",
            text: 'No lo podras volver a recuperar',
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: 'Si, Cancelarlo!',
            cancelButtonText: 'No'
        }).then(result => {
            if (result.value) {
                toogleAlert(true)
                if (socket) {
                    socket.emit("cancel-reservation", item)
                }
            }
            console.log('result', result)
        })

        // if (window.confirm("¿Deseas cancelar esta Reserva?")) {
        //     toogleAlert(true)
        //     if (socket) {
        //         socket.emit("cancel-reservation", item)
        //     }
        //     // cancelReservation(item.reservation_id)
        // }
    })

    const lastBookElementRef = useCallback(node => {
        if (observer.current) observer.current.disconnect()

        // console.log('current_page',current_page)

        observer.current = new IntersectionObserver(entries => {

            if (entries[0].isIntersecting && current_page <= last_page) {
                // toogleAlert(true,"Cargando datos...")

                // getSchedule(reference.current.value, referenceBranch.current.value, current_page + 1,false)
            }
        })

        // console.log('node',node)
        if (node) observer.current.observe(node)

    }, [current_page, status])

    const editTable = (dining) => {
        setShow(false)
        //setShowModalEdit(true)
        console.log('dining', dining)
        console.log('client', client)
        console.log('form', form)

        let _date = client.date_start.split(" ");

        toogleAlert(true)
        getDiningAvailableChange(dining.branch_id, client.people, _date[0], false)
    }


    const onHideEdit = () => {
        setShowModalEdit(false)
        setSelected({})
        setSecond({})
        setModalreassign(false)
    }
    const confirmEditTable = (row) => {

        updateChangeDining(row, client.id)
    }

    const selectRow = (item) => {


        if (item.reservation_status_id !== 2) {



            if (item.reservation_id !== null) {
                if (selected === item) {
                    setSelected({})
                } else {

                    console.log('selected', selected)

                    console.log('Object.keys(item).length', Object.keys(selected).length)
                    if (Object.keys(selected).length > 0) {

                        if (item.reservation_id === null) {
                            setSelected(item)
                        } else {
                            setSecond(item)
                            setModalChange(true)
                        }
                    } else {
                        setSelected(item)
                    }
                }
            } else {

                if (Object.keys(selected).length > 0) {
                    setSecond(item)
                    setModalreassign(true)
                }
            }
        }
    }

    const onSuccessExchange = () => {
        setModalChange(false)
        getSchedule(form.date_start, form.branch_id, current_page, true)
        setSecond({})
        setSelected({})
    }

    const onConfirmReassign = () => {

        if (socket) {
            toogleAlert(true)
            socket.emit("reassign-reservation", { reservation_id: selected.reservation_id, schedule_id: second.id, })
        }

        //  updateChangeDining(second.id, selected.reservation_id)
    }

    const updateFieldTemp = (e) => {
        if (e.target.type === 'checkbox') {
            setFormTemp({
                ...formTemp,
                [e.target.name]: e.target.checked
            })
        } else {
            setFormTemp({
                ...formTemp,
                [e.target.name]: e.target.value
            })
        }
    }


    return (
        <Container fluid>
            <Row className="my-4">
                <Col lg={3}>
                    <h3 className="pl-3">Disponibilidad</h3>
                </Col>
                <Col lg={3}>
                    <SelectFieldReservation name="date_start" data={dates || []} onChange={updateField} reference={reference} />
                </Col>
                <Col lg={3}>
                    <SelectFieldDate name="branch_id" data={branch || []} onChange={updateFieldBranch} value={form.branch_id} reference={referenceBranch} />
                </Col>
            </Row>
            <Row className="pr-3">
                <Col lg={12}>
                    <table className="p-0 mx-3 bg-azul table-bordered rounded-lg m-0 w-100 table-hover table-header-custom">
                        <thead>
                            <tr>
                                <td>PDV</td>
                                <td>Mesa / Silla</td>
                                <td>Día/Hora</td>
                                <td>Nombres</td>
                                <td>Teléfono</td>
                                <td>Pax</td>
                                <td>Mascotas</td>
                                <td>Reservar</td>
                            </tr>
                        </thead>
                        <tbody>

                            {(listBranch.length > 0) ? listBranch.map((row, i) =>
                                <DetailReservationItem content={content}
                                    key={i} index={i} item={row}
                                    day={date_text}
                                    setReservationItem={setReservationItem}
                                    restartContent={restartContent}
                                    showReservation={showReservation}
                                    cancelReservationTemp={cancelReservationTemp}
                                    reserve={reserve}
                                    selectRow={selectRow}
                                    selected={selected}
                                    refs={lastBookElementRef} />) : <tr className="p-0 mx-0 active_list_reservation m-0 w-100"><td>No hay Reservas</td></tr>}
                        </tbody>


                    </table>
                </Col>
            </Row>

            <FormreservationModal
                show={showModal}
                onHide={onHide}
                row={row}
                onSuccess={onCreateReservation}
                day={date_selected}
                dates={form} />

            {/* <ReservationModal
                show={show}
                onHide={onHideActivate}
                client={client}
                form={formTemp}
                updateField={updateFieldTemp}
                onSubmit={onSubmit}
                cancelReservation={cancelReservationForm}
                editTable={editTable}
            /> */}

            <ChangeDiningModal show={modalChange} first={selected} second={second} onHide={() => setModalChange(false)} onSuccess={onSuccessExchange} />
            <ReassignModal first={selected} second={second} show={modalReassign} onHide={() => setModalreassign(false)} onConfirm={onConfirmReassign} />

            <EditmodalTable show={showModalEdit} onHide={onHideEdit} availables={availables} confirmEditTable={confirmEditTable} />
        </Container>
    )
}

const mapsState = (props) => {

    return {
        detail_reservation: props.reservation.detail,
        fetching_cancel: props.reservation.fetching_cancel,
        fetching_activate: props.reservation.fetching_activate,
        fetching_dates: props.reservation.fetching_dates,
        branch: props.branchoffice.data,
        schedule: props.reservation.data,
        current_page: props.reservation.current_page,
        fetching_id: props.reservation.fetching_id,
        last_page: props.reservation.last_page,
        fetching: props.branchoffice.fetching,
        fetching_schedule: props.reservation.fetching_reservation,
        fetching_available: props.reservation.fetching_available,
        fetching_change: props.reservation.fetching_change,
        date_text: props.reservation.date_text,
        date_selected: props.reservation.date,
        availables: props.reservation.availables,
        dates: props.reservation.dates,
        user: props.user.data
    }
}

export default connect(mapsState, {
    getSchedule, getDateReserv, getListBranch, cancelReservation, getReservationDetail,
    joinReservation, toogleAlert, getDiningAvailableChange, updateChangeDining,
})(AllReservationPage);


