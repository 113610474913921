import React, { useEffect, useState } from 'react'
import TemplateMenu from "../../../template/menu.template";
import { Row, Table, Col } from 'react-bootstrap';

import { getPayrollReport } from "../../../Redux/payrollDuck";
import { getWorkersActives } from '../../../Redux/workerDuck'

import { connect } from 'react-redux';
import { formatNumber } from '../../../utils';
import { Link } from 'react-router-dom';
import { AiFillEdit, AiFillFilePdf, AiFillLock } from 'react-icons/ai';
import moment from 'moment';

const API_URL = process.env.REACT_APP_API_URL

const ReportPayrollPage = ({ getPayrollReport, fetching_list, data, getWorkersActives, data_worker, fetching_list_worker }) => {

    const [list, setList] = useState([])
    const [listWorker, setListWorker] = useState([])
    const [form, setForm] = useState({
        date_init: moment().format("YYYY-MM-01"),
        date_end: moment().format("YYYY-MM-DD"), worker_id: 0
    })

    useEffect(() => {
        getPayrollReport(form)
        getWorkersActives()
    }, [])

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
        }

    }, [fetching_list])

    useEffect(() => {
        if (fetching_list_worker === 2) {
            setListWorker(data_worker)
        }

    }, [fetching_list_worker])

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }
    const onFilter = () => {
        getPayrollReport(form)
    }

    return (
        <TemplateMenu>
            <Row >
                <Col>Reporte de Nomina</Col>
            </Row>
            <Row>

                <Col>
                    <div className="form-group">
                        <label>Fecha de Inicio</label>
                        <input
                            className="form-control input-sm"
                            name="date_init"
                            type={"date"}
                            onChange={updateInput}
                            value={form.date_init}
                        />
                    </div>
                </Col>
                <Col>
                    <div className="form-group">
                        <label>Fecha de Fin</label>
                        <input
                            className="form-control input-sm"
                            name="date_end"
                            type={"date"}
                            onChange={updateInput}
                            value={form.date_end}
                        />
                    </div>
                </Col>

                <Col>
                    <div className="form-group">
                        <label>Trabajadores</label>
                        <select
                            className="form-control"
                            name="worker_id"
                            value={form.worker_id}
                            onChange={updateInput}>
                            <option value="0">Seleccionar Todos</option>
                            {listWorker.map((d, k) => <option value={d.id} key={k}>{d.name}</option>)}
                        </select>
                    </div>
                </Col>
                <Col className="my-1 d-flex align-items-center">
                    <button className="btn btn-warning" onClick={onFilter}>
                        Filtrar
                    </button>
                </Col>
            </Row>
            <Row className='my-1'>
                <Col>
                    <Table className='table-sm table-bordered table-hover table-striped'>
                        <thead className='thead-dark'>
                            <tr>
                                <th></th>
                                <th>Fecha Corte</th>
                                <th>Nombre</th>
                                <th>Sucursal</th>
                                <th>Dias</th>
                                <th>Básico</th>
                                <th>Bonificación</th>
                                <th>Desc</th>
                                <th>Otros Desc</th>
                                <th>Otros Pagos</th>
                                <th>Subtotal</th>
                                <th>A pagar</th>
                                <th>PDF</th>
                            </tr>
                        </thead>
                        <tbody>

                            {list.length === 0 ? <tr><td colSpan={13} align='center'>No Tienes datos</td></tr> :
                                list.map((d, k) => <tr key={k}>
                                    <td></td>
                                    <td>{d.init_end}</td>
                                    <td>{d.worker}</td>
                                    <td>{d.branch_office}</td>
                                    <td>{d.worked_days}</td>
                                    <td>{formatNumber(d.salary_real)}</td>
                                    <td>${formatNumber(d.bonification_real)}</td>
                                    <td>${formatNumber(d.deductions)}</td>
                                    <td>${formatNumber(d.other_deductions)}</td>
                                    <td>${formatNumber(d.other_payment)}</td>
                                    <td>${formatNumber(0)}</td>
                                    <td>${formatNumber(0)}</td>
                                    <td>
                                        <a href={`${API_URL}/pdf/${d.id}.pdf`} target="_blank"><AiFillFilePdf size={25} /></a>
                                    </td>
                                </tr>)}

                        </tbody>
                    </Table>
                </Col>
            </Row>

        </TemplateMenu>

    )
}

const mapsState = (props) => {
    let { payroll, worker } = props

    return {
        fetching_list: payroll.fetching_list,
        data: payroll.data,
        fetching_list_worker: worker.fetching_list,
        data_worker: worker.data,

    }
}

export default connect(mapsState, { getPayrollReport, getWorkersActives })(ReportPayrollPage);