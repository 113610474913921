import React, { useEffect, useState } from "react";
import Select from "react-select";
import uuid from "react-uuid";
import { formatNumber, getHeaders } from "../../utils";
import { Form, InputGroup } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import moment from "moment";
import { connect } from "react-redux";
import { getLastInventoryByCode } from '../../Redux/inventoryDuck'


const API_URL = process.env.REACT_APP_API_URL;

const RowProduct = ({ listProduct, onSetSelectedProduct, onDelete, onAddItem, formEdit,
  onEditItem, registration_date, charge_id, getLastInventoryByCode, fetching_by_id, row, selectedCharge }) => {
  const [form, setForm] = useState({
    amount: 0,
    cost_average: 0,
    product: {},
    subtotal: "",
    charge: 0
  });
  const [selectedProduct, setSelectedProduct] = useState({
    tax: { description: "" },
    measure_unit: { description: "" },
  });
  const [showButton, setShowButton] = useState(false);
  const [productSearch, setProductSearch] = useState("");
  const [listSearch, setListSearch] = useState([]);

  useEffect(() => {
    if (fetching_by_id === 2) {
      setForm({
        ...form,
        ["cost_average"]: row.cost_average,
        ["amount_total"]: row.amount_total,
      });
    }
  }, [fetching_by_id])

  useEffect(() => {
    if (charge_id !== 0) {
      let { detail } = selectedCharge;

      if (productSearch.value !== undefined) {
        let _charge = detail.filter(d => d.product_id === parseInt(productSearch.value))

        if (_charge.length > 0) {
          setForm({
            ...form,
            ["charge"]: _charge[0].value
          })
        }
      }
    }

  }, [charge_id])

  useEffect(() => {
    if (Object.keys(formEdit).length > 0) {
      setForm(formEdit);
      setSelectedProduct(formEdit.product);
      setShowButton(true);
      setProductSearch({
        label: formEdit.product.name,
        value: formEdit.product.id,
      });
    }
  }, [formEdit]);

  const loadOptions = async (inputValue) => {
    let branch = JSON.parse(localStorage.getItem("branch"));
    let date_init = moment(registration_date).format("YYYY-MM-01")
    let _date_end = moment(registration_date).format("YYYY-MM-DD")
    let url = `${API_URL}/api/product-inventory-search/${inputValue}?date_init=${date_init}&date_end=${_date_end}&branch=${branch.id}`;

    let _search = await fetch(url, {
      headers: getHeaders(),
    }).then((resp) => resp.json());

    setListSearch(_search);

    return _search;
  };

  const updateInput = (e) => {

    setForm({
      ...form,
      [e.target.name]: e.target.value,
      ["subtotal"]: form.cost_average * e.target.value,
    });
  };

  const onAddItemBefore = (item) => {

    if (parseFloat(form.amount_total) >= parseFloat(form.amount)) {
      if (parseFloat(form.amount) > 0) {
        item._id = uuid();
        item.amount_total = form.amount_total;
        onAddItem(item);
      } else {
        alert(`Solo puedes Solicitar Valores superiores a 1`)
      }

    } else {
      alert(`No puedes superar el Stock Actual ${form.amount_total}`)
    }
  }

  const onEditItemBefore = (form) => {

    onEditItem(form)
  }


  return (
    <tr>
      <td>
        <AsyncSelect
          value={productSearch}
          loadOptions={loadOptions}
          onChange={(e) => {
            setSelectedProduct(e);

            let _form = {
              ...form,
              ["cost_average"]: e.cost_average,
              ["charge"]: 0,
              ["product"]: e,
              ["amount_total"]: e.amount_total,
            }


            setShowButton(true);
            setProductSearch({ label: e.name, value: e.id });
            let _date_end = moment(registration_date).format("YYYY-MM-DD")

            getLastInventoryByCode(e.code, _date_end)

            if (charge_id !== 0) {
              let { detail } = selectedCharge;
              let _charge = detail.filter(d => d.product_id === parseInt(e.id))

              if (_charge.length > 0) {
                _form.charge = _charge[0].value
              }
            }

            setForm(_form);

          }}
          placeholder="Busca el producto"
        />
      </td>
      <td align="right">{formatNumber(form.cost_average)}</td>
      <td align="right">
        <InputGroup className="mb-3">
          <Form.Control
            type="number"
            onChange={updateInput}
            value={form.amount}
            name="amount"
            min="0"
            placeholder="Cantidad"
            aria-describedby="basic-addon2"
          />
          <InputGroup.Text id="basic-addon2">
            ({form.amount_total}) {selectedProduct.measure_unit.description}
          </InputGroup.Text>
        </InputGroup>
      </td>
      <td align="right">{formatNumber(form.cost_average * form.amount)}</td>
      {charge_id !== 0 && <>
        <td align="right">
          {form.charge}%
        </td>
        <td align="right">
          {formatNumber(form.cost_average * form.amount + (form.cost_average * form.amount * (form.charge / 100)))}
        </td>
      </>}

      {showButton && (
        <td>
          {form._id !== undefined ? (
            <button className="btn btn-sm btn-info mx-2" onClick={() => onEditItemBefore(form)}>Editar</button>
          ) : (
            <button className="btn btn-sm btn-success mx-2" onClick={() => onAddItemBefore(form)}>Guardar</button>
          )}

          <button className="btn btn-sm btn-danger" onClick={() => onDelete(form)}>Cancelar</button>
        </td>
      )}

    </tr>
  );
};

const mapsState = (props) => {
  let { inventory } = props

  return {
    fetching_by_id: inventory.fetching_by_id,
    row: inventory.row
  }
}

export default connect(mapsState, { getLastInventoryByCode })(RowProduct);
