import { getHeaders } from '../utils';

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    data: [],
    errors: {}
}

let ADD_FRANCHISE = "ADD_FRANCHISE";
let ADD_FRANCHISE_SUCCESS = "ADD_FRANCHISE_SUCCESS";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ADD_FRANCHISE:
            return { ...state, fetching: 1, }
        case ADD_FRANCHISE_SUCCESS:
            return { ...state, fetching: 2 }
        default:
            return state;
    }
}

export let addFranchiseManual = (item) => (dispatch, getState) => {
    dispatch({
        type: ADD_FRANCHISE,
    });

    let url = `${API_URL}/api/report/franchise/${item.id}`;

    fetch(url, {
        method: "PUT",
        body: JSON.stringify(item),
        headers: getHeaders(),
    })
        .then((resp) => resp.json())
        .then((resp) => {
            console.log('resp', resp);
        });


}