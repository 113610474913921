import React from 'react'
import { Link } from 'react-router-dom';
import { formatNumber } from '../../utils';

const ItemTransformationTable = ({ item, onDelete }) => {
    return (
        <tr>
            <td>{item.name}</td>
            <td>{item.items_prescription}</td>
            <td>{formatNumber(item.cost_prescription)}</td>
            <td>{item.status_text}</td>
            <td>
                <Link className='btn btn-sm btn-info' to={`/subrecetas/${item.id}`}>Editar</Link>
                <button className='btn btn-sm btn-danger mx-2' onClick={onDelete}>Eliminar</button>
            </td>
        </tr>
    )
}

export default ItemTransformationTable;