import React, { useEffect, useState } from 'react'
import { formatNumber } from '../../utils';
import { AiFillCloseSquare, AiFillEdit } from "react-icons/ai";
import Select from 'react-select'


const ItemDetailRequisition = ({ item, onDelete, listProduct, has_charge, onNotifierChange, index }) => {

    let { product } = item
    let cost = item.cost_average !== '' ? item.cost_average : product.cost
    let charge = item.amount * item.cost_average * (item.charge / 100);
    const [form, setForm] = useState({ amount: '', cost: '', subtotal: '' })
    const [show, setShow] = useState(false)

    useEffect(() => {
        setForm(item)
    }, [item])

    const onEdit = (row) => {
        setShow(true);
        setForm({
            ...form,
            ["amount"]: row.amount,
            ["cost"]: row.cost,
            ["subtotal"]: row.subtotal,
        })
    }

    const updateInput = (e) => {
        if (e.target.name === 'amount') {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
                ["cost"]: form.subtotal / e.target.value
            })
        }
    }

    const onSaveRow = (item) => {

        console.log('item', item);

        if (parseFloat(form.amount) > 0) {
            setShow(false);
            item.amount = form.amount
            item.cost = form.cost
            item.subtotal = form.cost * form.amount
            onNotifierChange()

        } else {
            setForm({
                ...form,
                ["amount"]: 0,
                ["cost"]: 0
            })
        }

    }


    return (
        <tr>
            <td>
                {item.product.name}
            </td>
            <td>{item.amount_total}</td>
            <td align='right'>
                <input type='number' className='form-control text-right' value={form.amount} name="amount"
                    onChange={updateInput} onBlur={() => onSaveRow(item)} tabIndex={index} />
            </td>
            <td>
                {!show ? <>
                    {/* <button className='btn btn-info btn-sm' onClick={() => onEdit(item)}>Editar</button> */}
                    <button className='btn btn-danger btn-sm mx-3' onClick={() => onDelete(item)}>Borrar</button>
                </> :
                    <>
                        <button className='btn btn-success btn-info btn-sm' onClick={onSaveRow}>Guardar</button>
                        <button className='btn btn-danger btn-info btn-sm mx-2' onClick={() => setShow(false)}>Cancelar</button>
                    </>}

            </td>
        </tr>
    )
}

export default ItemDetailRequisition;