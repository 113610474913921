import React, { useState } from 'react'

const ItemDetailParameter=({item, onUpdate})=>{

    const [show,setShow] = useState(false)
    const [description,setDescription] = useState('')

    const onEditRow=()=>{
        setShow(true)
        setDescription(item.description)
    }

    const onUpdateParameter=()=>{
        item.description=description
        onUpdate(item)
        setShow(false)
        
    }

    return (
        <tr>
            <td>{show===false?item.description:<input placeholder='Descripción' value={description} className='form-control' 
            onChange={(e)=>setDescription(e.target.value)}/>}</td>
            <td>
                {show===false?<button className='btn btn-sm btn-info' onClick={onEditRow}>Editar</button>:
                <button className='btn btn-sm btn-success' onClick={onUpdateParameter}>Guardar</button>}
            </td>
        </tr>
    )
}

export default ItemDetailParameter;