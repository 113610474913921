import React, { useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap';
import InputField from '../Components/Widgets/InputField';
import uuid from 'react-uuid';

const initState = { uuid: '', observation: '' }

const ModalNovelty = ({ show, onHide, onSubmit }) => {

    const [form, setForm] = useState(initState)
    const [error, setError] = useState('')

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSave = (e) => {
        e.preventDefault()


        if (form.observation === '') {
            setError("No puede estar vacio")
            return;
        }

        let _form = form
        _form.uuid = uuid();

        setForm(initState)
        onSubmit(_form)
    }

    return (
        <Form onSubmit={onSave}>
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Novedades del Día</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Observaciones</label>

                                <textarea
                                    className='form-control'
                                    required={"required"}
                                    placeholder={"Observaciones"}
                                    value={form.observation || ''}
                                    name="observation"
                                    onChange={updateInput}
                                    rows={3}
                                />
                                {error !== undefined && (<label className="error-label">{error}</label>)}



                            </div>
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col>
                            <button type='submit' className='btn btn-success' onClick={onSave}>Agregar</button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal >
        </Form>
    )
}

export default ModalNovelty;