import React, { Fragment, useEffect, useRef, useState } from "react";
import { Card, Col, Form, Row, Table } from "react-bootstrap";
import { connect } from "react-redux";
import TemplateMenu from "../../template/menu.template";
import { getInventoryCounterDiffence, updateInventoryCounter, resetStatus, onValidateInventory } from "../../Redux/inventoryDuck";
import { onToogleInventory, getLockInventory } from "../../Redux/settingDuck";
import { getParameterBySegment } from "../../Redux/parameterDuck";
import { toogleLoader } from "../../Redux/alertDuck";
import moment from "moment";
import ItemTableDiference from "./item.table.diference";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { RiFileExcel2Fill } from "react-icons/ri";
import ModalAdjust from "./modal.ajust";
import ModalConfirmation from "../Components/modal.confirmation";

const DiferencePage = ({ getInventoryCounterDiffence, fetching_diference, data, updateInventoryCounter, resetStatus,
  getParameterBySegment, fetching_by_segment, data_segment, last_date, toogleLoader, onValidateInventory, fetching_validate,
  onLockInventory, getLockInventory, fetching_app, row_app, onToogleInventory, fetching_lock
}) => {
  const [list, setList] = useState([]);
  const [form, setForm] = useState({
    date_init: moment().format("YYYY-MM-01"), date_end: moment().format("YYYY-MM-DD"),
    daily: true, week: false, month: false, search: '', min: '', max: moment().format("YYYY-MM-DD")
  });

  const [listFilter, setListFilter] = useState([]);
  const [listControl, setListControl] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const debounceRef = useRef();
  const tableRef = useRef()
  const tableRef2 = useRef()
  const [lock, setLock] = useState(null);
  const [titleModal, setTitleModal] = useState('')


  useEffect(() => {
    let _dates = JSON.parse(localStorage.getItem("date"));

    let _form = {
      ...form,
      ["date_init"]: _dates.date_init,
      ["date_end"]: _dates.date_end,
      ["min"]: _dates.date_init,
    };

    setForm(_form);
    getInventoryCounterDiffence(_form);
    getParameterBySegment("control_inventory");
    getLockInventory("difference")
    toogleLoader(true)
  }, []);

  useEffect(() => {
    if (fetching_lock === 2) {
      setModalConfirm(false)
      getLockInventory("difference")
    }
  }, [fetching_lock])

  useEffect(() => {
    if (fetching_app === 2) {
      if (row_app !== null) {

        setLock(row_app)
        if (row_app.value === 0) {
          setIsLocked(false)
        } else {
          setIsLocked(true)
        }

      }
    }
  }, [fetching_app])

  useEffect(() => {
    if (fetching_validate === 2) {
      toogleLoader(false)
    }
  }, [fetching_validate]);

  useEffect(() => {
    if (fetching_by_segment === 2) {
      setListControl(data_segment);

    }
  }, [fetching_by_segment]);

  useEffect(() => {
    if (fetching_diference === 2) {
      resetStatus();
      setList(data);
      setListFilter(data);


      setForm({
        ...form,
        ["date_end"]: last_date
      })

      toogleLoader(false)

    }
  }, [fetching_diference]);

  const updateInput = (e) => {
    let _form = {
      ...form,
      [e.target.name]: e.target.value,
    };

    setForm(_form);
    _form.filter = true
    toogleLoader(true)

    getInventoryCounterDiffence(_form);
  };

  const updateCheckBox = (e) => {
    let _form = {
      ...form,
      [e.target.name]: e.target.checked,
    };
    setForm(_form);
    getInventoryCounterDiffence(_form);
    toogleLoader(true)

  };

  const onUpdateItem = (row, amount) => {
    let _form = row;

    if (amount !== "") {
      _form.amount = amount;
      _form.registration_date = form.registration_date;

      updateInventoryCounter(_form);
      toogleLoader(true)
    }
  };

  const onSearch = (e) => {
    if (debounceRef.current)
      clearTimeout(debounceRef.current)


    let _form = form
    _form.search = e.target.value.toLowerCase()
    getInventoryCounterDiffence(form);

  };

  const onSearchFilter = () => {
    toogleLoader(true)

    getInventoryCounterDiffence(form);
  };

  const onValidateInventoryPre = () => {
    toogleLoader(true)
    onValidateInventory();
  }

  const onToogleLock = (e) => {
    setModalConfirm(true)
  }

  const onHideModalLock = () => {
    setModalConfirm(false)
  }


  return (
    <TemplateMenu>
      <Row>
        <Col lg={4}>
          <h2>Diferencias de Inventario</h2>
        </Col>
        <Col>
          <DownloadTableExcel
            filename={`${form.date_init}_${form.date_end}_diferencias_inventario`}
            sheet="cierres"
            currentTableRef={tableRef.current}>
            <RiFileExcel2Fill size={25} color="green" className="cursor-pointer" />
          </DownloadTableExcel>
        </Col>
      </Row>
      <Row className="my-2">

        <Col lg={2}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Producto</Form.Label>
            <Form.Control
              ref={debounceRef}
              type="text"
              value={form.product}
              name="product"
              placeholder="Nombre"
              onChange={onSearch} />
          </Form.Group>
        </Col>
        <Col lg={2}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Fecha Inicio</Form.Label>
            <Form.Control
              type="date"
              value={form.date_init}
              min={form.min}
              max={form.max}
              name="date_init"
              onChange={updateInput}
            />
          </Form.Group>
        </Col>
        <Col lg={2}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Fecha Fin</Form.Label>
            <Form.Control
              type="date"
              value={form.date_end}
              min={form.min}
              max={form.max}
              name="date_end"
              onChange={updateInput}
            />
          </Form.Group>
        </Col>
        <Col lg={2}>
          <Form.Check
            type={"checkbox"}
            id={`daily`}
            label={`Diario`}
            name="daily"
            checked={form.daily}
            onChange={updateCheckBox}
          />
          <Form.Check
            type={"checkbox"}
            id={`semanal`}
            label={`Semanal`}
            name="week"
            checked={form.week}
            onChange={updateCheckBox}
          />
          <Form.Check
            type={"checkbox"}
            id={`mensual`}
            label={`Mensual`}
            checked={form.month}
            name="month"
            onChange={updateCheckBox}
          />
        </Col>
        <Col lg={1} className="d-flex align-items-center">
          <button className="btn btn-sm btn-info" onClick={onSearchFilter}>
            Buscar
          </button>
        </Col>
        <Col lg={1} className="d-flex align-items-center">
          <button className="btn btn-sm btn-success" onClick={() => setModal(true)}>
            Ver Ajuste
          </button>
        </Col>
        <Col className="d-flex align-items-center">
          {/* <div className="form-check form-switch">
            <input type="checkbox" className="form-check-input" checked={isLocked} onChange={onToogleLock} />
            <label className="form-check-label">{isLocked ? 'Bloqueado' : 'Bloquear'}</label>
          </div> */}
          {/* <button className="btn btn-sm btn-warning" onClick={onValidateInventoryPre}>
            Validar Inventario
          </button> */}
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col lg={12}>
          {listFilter.length > 0 ? (
            listFilter.map((d, key) => (
              <Card key={key} className="mt-2">
                <Card.Body>
                  <Card.Title><b>{d.description.toUpperCase()}</b></Card.Title>
                  <Table className="table table-sm table-hover table-bordered">
                    <thead className="thead-dark">
                      <tr>
                        <th colSpan={2}></th>
                        <th colSpan={4} className="text-center bg-table-blue">
                          INGRESOS
                        </th>
                        <th colSpan={5} className="text-center bg-table-orange">
                          SALIDAS
                        </th>
                        <th colSpan={3} className="text-center -table-green">
                          PERIODO
                        </th>
                      </tr>
                      <tr>
                        <th></th>
                        <th>Producto</th>
                        <th className="bg-table-blue">Inv Inicial</th>
                        <th className="bg-table-blue">Compras</th>
                        <th className="bg-table-blue">Producciones</th>
                        <th className="bg-table-blue">Transform</th>
                        <th className="bg-table-orange">Bajas</th>
                        <th className="bg-table-orange">Producción</th>
                        <th className="bg-table-orange">Transform</th>
                        <th className="bg-table-orange">Venta</th>
                        <th className="bg-table-orange">Transfer</th>
                        <th>Cant Kardex</th>
                        <th>Conteo {form.date_end}</th>
                        <th>Diferencias</th>
                      </tr>
                    </thead>
                    <tbody>
                      {d.products.map((row, k) => <ItemTableDiference
                        row={row}
                        key={k}
                        index={k + 1}
                        onUpdate={onUpdateItem}
                        form={form}
                      />)}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            ))
          ) : (
            <Card>
              <Card.Body>
                <Card.Title>No se encontraron registros</Card.Title>
              </Card.Body>
            </Card>
          )}

        </Col>
      </Row>
      <ModalAdjust show={modal} onHide={() => setModal(false)} list={listFilter} tableRef2={tableRef2} />
      <ModalConfirmation show={modalConfirm}
        title={`¿Deseas ${!isLocked ? 'Bloquear' : 'Desbloquear'} compras, Bajas y Transformaciones?`}
        text={`El sistema ${!isLocked ? 'Bloqueará' : 'Desbloqueará'} la Edición de Compras, bajas, transformaciones y producciones del Sistema desde ${form.date_init} hasta ${form.date_end}`}
        onHide={onHideModalLock} onSubmit={() => onToogleInventory(lock, isLocked)} />
    </TemplateMenu >
  );
};
const mapsState = (props) => {
  let { inventory, product_inventory, parameter, setting } = props;
  return {
    fetching_diference: inventory.fetching_diference,
    data: inventory.data_diference,
    products: product_inventory.data,
    last_date: inventory.last_date,
    fetching_list_product: product_inventory.fetching_list,
    fetching_validate: inventory.fetching_validate,
    fetching_by_segment: parameter.fetching_by_segment,
    data_segment: parameter.data_segment,
    fetching_app: setting.fetching_app,
    fetching_lock: setting.fetching_lock,
    row_app: setting.row,
  };
};

export default connect(mapsState, {
  getInventoryCounterDiffence, updateInventoryCounter, resetStatus,
  getParameterBySegment, toogleLoader, onValidateInventory, onToogleInventory, getLockInventory
})(DiferencePage);
