import React, { Fragment, useEffect, useState } from 'react'
import TemplateMenu from "../../template/menu.template";
import { Col, Row, Table } from 'react-bootstrap';
import {
    getProductsMaster, copyProductsMaster, deleteProductsMaster, onToogleCopyProduct,
    onDeleteCheckProduct, onCopyAllProduct
} from '../../Redux/productDuck';
import { connect } from 'react-redux';
import { toogleLoader } from '../../Redux/alertDuck';
import ItemTableMaster from './item.table.master';
import uuid from 'react-uuid';


const MasterProductsPage = ({ data, fetching, getProductsMaster, fetching_master, data_master, toogleLoader,
    copyProductsMaster, fetching_copy, deleteProductsMaster, data_check, data_check_delete, onToogleCopyProduct,
    onDeleteCheckProduct, onCopyAllProduct }) => {
    const [listBranch, setListBranch] = useState([])
    const [listProducts, setListProducts] = useState([])
    const [check, setCheck] = useState({ id: 0 })

    useEffect(() => {
        getProductsMaster()
        toogleLoader(true)
    }, [])

    useEffect(() => {

        if (fetching_master === 2) {
            setListProducts(data_master)
            toogleLoader(false)
        }
    }, [fetching_master])

    useEffect(() => {
        if (fetching_copy === 2) {
            toogleLoader(true)
            getProductsMaster()
        }
    }, [fetching_copy])

    useEffect(() => {
        if (fetching === 2) {
            setListBranch(data)
        }
    }, [fetching])

    const onCopyBranch = () => {
        if (window.confirm(`¿Copiaras (${data_check.length}) Productos, Deseas Continuar?`)) {
            toogleLoader(true)
            copyProductsMaster()
        }
    }

    const onCopyAllProductPre = (branch) => {

        setCheck(branch)

        listProducts.map(d => {

            let row = { id: branch.id, title: branch.title, code: d.code, name: d.product, uuid: uuid() }
            d.branch = d.branch.map(b => {
                if (b.id === branch.id) {


                }
                return b;
            });

            onToogleCopyProduct(row, true)
            return d;
        })
    }


    return (
        <TemplateMenu>
            <Row>
                <Col><h3>Listado de Productos</h3></Col>
                <Col lg={2}>
                    <button className="btn btn-sm btn-info" onClick={onCopyBranch}>Replicar ({data_check.length}) Productos</button>
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col>
                    <Table className='table-sm table-bordered'>
                        <thead className='thead-dark'>
                            <tr>
                                <th></th>
                                <th>Nombre Producto</th>
                                <th>Código</th>
                                {listBranch.map((d, k) => <th key={k}>
                                    <div className="form-inline">
                                        <div className="form-group">
                                            <input type='checkbox'
                                                checked={check.id === d.id}
                                                onChange={() => onCopyAllProductPre(d)} /> {d.title}
                                        </div>

                                    </div>
                                </th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {listProducts.map((d, k) => <ItemTableMaster i={k + 1} item={d} key={k} row_branch={check} />)}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { branchoffice, product } = props

    return {
        data: branchoffice.data,
        fetching: branchoffice.fetching,
        fetching_master: product.fetching_master,
        data_master: product.data_master,
        fetching_copy: product.fetching_copy,
        data_check: product.data_check,
        data_check_delete: product.data_check_delete,
    }
}
export default connect(mapsState, {
    getProductsMaster, toogleLoader, copyProductsMaster,
    deleteProductsMaster, onToogleCopyProduct, onDeleteCheckProduct,
    onCopyAllProduct
})(MasterProductsPage); 