import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux';
import ItemRequisition from './item.requisition';
import { getListRequisition, onDeleteRequisition } from '../../Redux/requisitionDuck';

;

const ListRequisitionOrigin = ({ fetching_list, products, onDeleteRequisition }) => {
    const [list, setList] = useState([])
    const [branch, setBranch] = useState({})

    useEffect(() => {
        if (fetching_list === 2) {
            let _branch = JSON.parse(localStorage.getItem("branch"))
            let _p = products.filter(p => p.request_branch_id === _branch.id);
            setList(_p)
            setBranch(_branch)
        }
    }, [fetching_list])


    const onDelete = (item) => {
        if (window.confirm("Realmente deseas Eliminar esta Requisic¡ón?")) {
            onDeleteRequisition(item.id)
        }
    }


    return (
        <>
            <Row className='mt-3'>
                <Col>
                    <table className="table table-sm table-bordered">
                        <thead className='thead-dark'>
                            <tr>
                                <th></th>
                                <th>Fecha de Solicitud</th>
                                <th>Punto Destino</th>
                                <th>Quien Solicito</th>
                                <th>Estado</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(list.length > 0) ? list.map(row => <ItemRequisition
                                show_delete={row.status_id === 1}
                                show_reverse={branch.id === row.request_branch_id}
                                branch={branch}
                                type={1}
                                title={row.branch_request.title} row={row} key={row.id}
                                onDelete={onDelete}
                                titleButton={"Ver"} />) : <tr><td colSpan={6}>No se encontraron registros</td></tr>}
                        </tbody>

                    </table>
                </Col>
            </Row>
        </>
    )
}

const mapsState = (props) => {
    let { requisition } = props
    return {
        fetching_list: requisition.fetching_list,
        fetching_create: requisition.fetching_create,
        products: requisition.data,
    }
}


export default connect(mapsState, { onDeleteRequisition })(ListRequisitionOrigin);