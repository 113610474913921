import React, { useContext, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import Orders from "./Pages/Orders";
import Home from "./Pages/Home";
import LandingPage from "./Pages/Landing";
import Products from "./Pages/Products";
// import DetailProduct from './Pages/Products/DetailProduct';
import AddProduct from "./Pages/Products/AddProduct";
import EditProduct from "./Pages/Products/EditProduct";
import Modifier from "./Pages/Modifier";
import Categories from "./Pages/Categories";
import BranchOffice from "./Pages/BranchOffice";
import Parameters from "./Pages/Parameters";
import Reservation from "./Pages/Reservation";
import Login from "./Pages/Login";
import DiningTable from "./Pages/DiningTable";
import PrivateRoute from "./RoutesPrivate";
import Users from "./Pages/Users";
import AllReservationPage from "./Pages/Reservation/allreservation.page";
import SchedulePage from "./Pages/Orders/scheduled.page";
import ClientPage from "./Pages/Clients";
import WorkersPage from "./Pages/Workers";
import WorkerTimerPage from "./Pages/WorkersTimer";
import OrdersHistory from "./Pages/Orders/orders-history";

import ProductsAldelo from "./Pages/ProductsAldelo";
import MenuPdfPage from "./Pages/MenuPdf";
import BannerPage from "./Pages/Banners";
import createBannerPage from "./Pages/Banners/createBanner";
import IngresosPage from "./Pages/Ingresos";
import IngresosAlternPage from "./Pages/Ingresos/index.altern";
import CreateIngresosPage from "./Pages/Ingresos/createIngresos";
import EditIngresosPage from "./Pages/Ingresos/editIngresos";
import PermissionPage from "./Pages/Permission";
import EditPermissionPage from "./Pages/Permission/edit.permission";
import createIngresosWorkerPage from "./Pages/Ingresos/createIngresos.worker";
import IngresosDocumentPdf from "./Pages/Ingresos/ingresos.document.pdf";
import { PDFViewer } from "@react-pdf/renderer";
import NominaPage from "./Pages/Nomina";
import NoveltiesPage from "./Pages/Workers/novelties";
import EditNominaPage from "./Pages/Nomina/edit.nomina";
import ConfigurationPage from "./Pages/Configuration";
import payrollDocumentPdf from "./Pages/Nomina/payroll.document.pdf";
import ProductRecipesPage from "./Pages/ProductRecipes";
import CreateProductRecipePage from "./Pages/ProductRecipes/create.product.recipe";
import SupplierPage from "./Pages/Suppliers";
import ExpensesPage from "./Pages/Expenses";
import TipsPage from "./Pages/Tips";
import EditTipPage from "./Pages/Tips/edit.tip";
import CreateExpensePage from "./Pages/Expenses/create.expenses";
import editExpensePage from "./Pages/Expenses/edit.expense";
import CreateTipsPage from "./Pages/Tips/create.tips";
import BalancePage from "./Pages/Balance";
import BalanceAlternativePage from "./Pages/Balance/index.alternative";
import AddExpensePage from "./Pages/Balance/add.expense.page";
import PublicityPage from "./Pages/Publicity";
import AddDiningTablePage from "./Pages/DiningTable/add.dining.table";
import TransformationPage from "./Pages/Transformation";
import CreateTransforationPage from "./Pages/Transformation/create.transformation";
import KardexPage from "./Pages/Kardex";
import PrescriptionPage from "./Pages/Prescription";
import CreatePrescriptionPage from "./Pages/Prescription/create.prescription";
import PurchasePage from "./Pages/Purchase";
import CreatePurchasePage from "./Pages/Purchase/create.purchase";
import EditPurchasePage from "./Pages/Purchase/edit.purchase";
import InventoryPage from "./Pages/Inventory";
import ProductionPage from "./Pages/Production";
import CouponPage from "./Pages/Coupon";
import EditCouponPage from "./Pages/Coupon/edit.coupon";
import createProductionPage from "./Pages/Production/create.production";
import EditPrescription from "./Pages/Prescription/edit.prescription";
import InitialInventoryPage from "./Pages/Inventory/initial.inventory";
import EditTransformationPage from "./Pages/Transformation/edit.transformation";
import ReportEndMonth from "./Pages/Report/end_month";
import franchiseReport from "./Pages/Report/franchise.report";
import SettlementPage from "./Pages/Settlement";
import CreateSettlementPage from "./Pages/Settlement/create.settlement";
import EditSettlementPage from "./Pages/Settlement/edit.settlement";
import SubPrescription from "./Pages/SubPrescription";
import EditSubPrescription from "./Pages/SubPrescription/edit.subprescription";
import CreateSubPrescription from "./Pages/SubPrescription/create.subprescription";
import PrescriptionKitckenPage from "./Pages/PrescriptionKitchen";
import CreatePrescriptionKitckenPage from "./Pages/PrescriptionKitchen/create.prescription.kitchen";
import EditPrescriptionKitchen from "./Pages/PrescriptionKitchen/edit.prescription.kitchen";
import EditProductionPage from "./Pages/Production/edit.production";
import counterPage from "./Pages/Inventory/counter.page";
import DiferencePage from "./Pages/Inventory/diference.page";
import UploadSalesPage from "./Pages/Inventory/upload_sales";
import UploadSalesDetailPage from "./Pages/Inventory/upload_sales_detail";
import CategoryRecipe from "./Pages/CategoryRecipe";
import showPrescription from "./Pages/PrescriptionKitchen/show.prescription";
import LowDownPage from "./Pages/Inventory/low_down.page";
import PayrollGroupPage from "./Pages/Nomina/index.payroll.group";
import CourtesyPage from "./Pages/Courtesy";
import RemainderPage from "./Pages/Reminder";
import EditCourtesyPage from "./Pages/Courtesy/edit.courtesy";
import EndMonth from "./Pages/EndMonth";
import { AuthContext } from "./context/AuthContext";
import RequisitionPage from "./Pages/Requisition";
import CreateRequisitionPage from "./Pages/Requisition/create.requisition";
import EditRequisitionPage from "./Pages/Requisition/edit.requisition";

import TransferPage from "./Pages/Transfer";
import CreateTransferPage from "./Pages/Transfer/create.transfer";
import EditTransferPage from "./Pages/Transfer/edit.transfer";
import RequisitionPdf from "./Pages/Requisition/requisition.pdf";
import workerDocuments from "./Pages/Workers/worker.documents";
import DataMasterPage from "./Pages/DataMaster";
import MasterProductsPage from "./Pages/DataMaster/master.products";
import ChargePage from "./Pages/Charge";
import CreateChargePage from "./Pages/Charge/create.charge";
import EditChargePage from "./Pages/Charge/edit.charge";
import WhatsappPage from "./Pages/Whatsapp";
import MessageBot from "./Pages/MessagesBot";
import EditMessageBot from "./Pages/MessagesBot/edit.message";
import ComparationSalesPage from "./Pages/Report/comparation.sales";
import comparationProduct from "./Pages/Report/comparation.product";
import EditCategoryRecipePage from "./Pages/CategoryRecipe/edit.category.recipe";
import DiferenceDetailPage from "./Pages/Inventory/diference.detail.page";
import ReportPayrollPage from "./Pages/Report/payroll";
import ProfilePage from "./Pages/Users/profile";
import SalesBrandPage from "./Pages/Report/sales_brand";
import MasterPrescriptionPage from "./Pages/DataMaster/master.prescription";

const Routes = () => {

  const { auth, verificaToken } = useContext(AuthContext);

  useEffect(() => {
    verificaToken();
  }, [verificaToken])

  return (
    <Switch>
      <Route exact path="/" component={LandingPage} />
      <PrivateRoute exact path="/home" component={Home} />
      <Route exact path="/login" component={Login} />
      <PrivateRoute exact path="/ordenes" component={Orders} />
      <PrivateRoute exact path="/productos" component={Products} />
      <PrivateRoute exact path="/productos-aldelo" component={ProductsAldelo} />
      <PrivateRoute exact path="/historial-pedidos" component={OrdersHistory} />
      <PrivateRoute exact path="/productos/new" component={AddProduct} />
      <PrivateRoute exact path="/productos/:id" component={EditProduct} />
      <Route exact path="/modificadores" component={Modifier} />
      <Route exact path="/categorias" component={Categories} />
      <PrivateRoute exact path="/sucursales" component={BranchOffice} />
      <PrivateRoute exact path="/reservas-disponibles" component={AllReservationPage} />
      <Route exact path="/parametros" component={Parameters} />
      <PrivateRoute exact path="/usuarios" component={Users} />
      <PrivateRoute exact path="/mesas" component={DiningTable} />
      <PrivateRoute exact path="/mesas/:id" component={AddDiningTablePage} />
      <PrivateRoute exact path="/clientes" component={ClientPage} />
      <PrivateRoute exact path="/trabajadores" component={WorkersPage} />
      <PrivateRoute exact path="/trabajadores/:id" component={NoveltiesPage} />
      <PrivateRoute exact path="/trabajadores-documents/:id" component={workerDocuments} />
      <PrivateRoute exact path="/tiempos" component={WorkerTimerPage} />
      <PrivateRoute exact path="/menu-pdf" component={MenuPdfPage} />
      <PrivateRoute exact path="/banners" component={BannerPage} />
      <PrivateRoute exact path="/ingresos-altern" component={IngresosAlternPage} />
      <PrivateRoute exact path="/ingresos" component={IngresosPage} />
      <PrivateRoute exact path="/ingresos/new" component={CreateIngresosPage} />
      <PrivateRoute exact path="/ingresos/:id" component={createIngresosWorkerPage} />
      <PrivateRoute exact path="/ingresos/:registration_date/check"
        component={EditIngresosPage}
      />
      <PrivateRoute
        exact
        path="/ingresos/:id/pdf"
        component={IngresosDocumentPdf}
      />
      <PrivateRoute exact path="/banners/new" component={createBannerPage} />
      <PrivateRoute exact path="/permisos" component={PermissionPage} />
      <PrivateRoute exact path="/permisos/:id" component={EditPermissionPage} />
      <Route exact path="/programados" component={SchedulePage} />
      <PrivateRoute exact path="/mesas-reservas" component={Reservation} />
      <PrivateRoute exact path="/nomina" component={NominaPage} />
      <PrivateRoute exact path="/nomina/:id" component={EditNominaPage} />
      <PrivateRoute exact path="/nomina/:id/pdf" component={payrollDocumentPdf} />
      <PrivateRoute exact path="/nomina-group/:id" component={PayrollGroupPage} />
      <PrivateRoute exact path="/configuracion" component={ConfigurationPage} />
      <PrivateRoute exact path="/productos-inventario" component={ProductRecipesPage} />
      <PrivateRoute exact path="/productos-inventario/new" component={CreateProductRecipePage} />
      <PrivateRoute exact path="/proveedores" component={SupplierPage} />
      <PrivateRoute exact path="/gastos" component={ExpensesPage} />
      <PrivateRoute exact path="/gastos/new" component={CreateExpensePage} />
      <PrivateRoute exact path="/gastos/:id" component={editExpensePage} />
      <PrivateRoute exact path="/propinas" component={TipsPage} />
      <PrivateRoute exact path="/propinas/new" component={CreateTipsPage} />
      <PrivateRoute exact path="/propinas/:id" component={EditTipPage} />
      <PrivateRoute exact path="/balance" component={BalancePage} />
      <PrivateRoute exact path="/balance/:type" component={AddExpensePage} />
      <PrivateRoute exact path="/balance-alternative" component={BalanceAlternativePage} />
      <PrivateRoute exact path="/publicidad" component={PublicityPage} />
      <PrivateRoute exact path="/transformaciones" component={TransformationPage} />
      <PrivateRoute
        exact
        path="/transformaciones/new"
        component={CreateTransforationPage}
      />
      <PrivateRoute
        exact
        path="/transformaciones/:id"
        component={EditTransformationPage}
      />
      <PrivateRoute exact path="/kardex" component={KardexPage} />
      <PrivateRoute exact path="/recetas" component={PrescriptionPage} />
      <PrivateRoute
        exact
        path="/recetas/new"
        component={CreatePrescriptionPage}
      />
      <PrivateRoute exact path="/recetas/:id/:type" component={EditPrescription} />
      <PrivateRoute exact path="/subrecetas" component={SubPrescription} />
      <PrivateRoute exact path="/subrecetas/new" component={CreateSubPrescription} />
      <PrivateRoute exact path="/subrecetas/:id" component={EditSubPrescription} />
      <PrivateRoute exact path="/compras" component={PurchasePage} />
      <PrivateRoute exact path="/compras/new" component={CreatePurchasePage} />
      <PrivateRoute exact path="/compras/:id" component={EditPurchasePage} />
      <PrivateRoute exact path="/inventario" component={InventoryPage} />
      <PrivateRoute
        exact
        path="/inventario-inicial"
        component={InitialInventoryPage}
      />
      <PrivateRoute exact path="/produccion" component={ProductionPage} />
      <PrivateRoute
        exact
        path="/produccion/new"
        component={createProductionPage}
      />
      <PrivateRoute
        exact
        path="/produccion/:id"
        component={EditProductionPage}
      />
      <PrivateRoute exact path="/cupones" component={CouponPage} />
      <PrivateRoute exact path="/cupones/:id" component={EditCouponPage} />
      <PrivateRoute exact path="/fin-de-mes" component={ReportEndMonth} />
      <PrivateRoute exact path="/franquicias" component={franchiseReport} />
      <PrivateRoute exact path="/liquidaciones" component={SettlementPage} />
      <PrivateRoute
        exact
        path="/liquidaciones/new"
        component={CreateSettlementPage}
      />
      <PrivateRoute exact path="/liquidaciones/:id" component={EditSettlementPage} />
      <PrivateRoute exact path="/recetario" component={PrescriptionKitckenPage} />
      <PrivateRoute exact path="/recetario/new" component={CreatePrescriptionKitckenPage} />
      <PrivateRoute
        exact
        path="/recetario/:id"
        component={EditPrescriptionKitchen}
      />
      <PrivateRoute
        exact
        path="/recetario-show/:id"
        component={showPrescription}
      />
      <PrivateRoute exact path="/conteo" component={counterPage} />
      <PrivateRoute exact path="/diferencias" component={DiferencePage} />
      <PrivateRoute exact path="/diferencias-detail/:id" component={DiferenceDetailPage} />
      <PrivateRoute exact path="/carga-ventas" component={UploadSalesPage} />
      <PrivateRoute
        exact
        path="/carga-ventas/:id/:date_init/:date_end"
        component={UploadSalesDetailPage}
      />
      <PrivateRoute exact path="/categoria-recetario" component={CategoryRecipe} />
      <PrivateRoute exact path="/categoria-recetario/:id" component={EditCategoryRecipePage} />
      <PrivateRoute exact path="/bajas" component={LowDownPage} />
      <PrivateRoute exact path="/cortesias" component={CourtesyPage} />
      <PrivateRoute exact path="/cortesias/:id" component={EditCourtesyPage} />
      <PrivateRoute exact path="/recordatorios" component={RemainderPage} />
      <PrivateRoute exact path="/cierre-de-mes" component={EndMonth} />
      <PrivateRoute exact path="/requisiciones" component={RequisitionPage} />
      <PrivateRoute exact path="/requisiciones/new" component={CreateRequisitionPage} />
      <PrivateRoute exact path="/requisiciones/:id" component={EditRequisitionPage} />
      <PrivateRoute exact path="/requisiciones/:id/pdf" component={RequisitionPdf} />
      <PrivateRoute exact path="/transferencias" component={TransferPage} />
      <PrivateRoute exact path="/transferencias/new" component={CreateTransferPage} />
      <PrivateRoute exact path="/transferencias/:id" component={EditTransferPage} />
      <PrivateRoute exact path="/maestros" component={DataMasterPage} />
      <PrivateRoute exact path="/maestros-productos" component={MasterProductsPage} />
      <PrivateRoute exact path="/maestro-recetas" component={MasterPrescriptionPage} />
      <PrivateRoute exact path="/cargos-productos" component={ChargePage} />
      <PrivateRoute exact path="/cargos-productos/new" component={CreateChargePage} />
      <PrivateRoute exact path="/cargos-productos/:id" component={EditChargePage} />
      <PrivateRoute exact path="/whatsapp" component={WhatsappPage} />
      <PrivateRoute exact path="/mensajes-bot" component={MessageBot} />
      <PrivateRoute exact path="/mensajes-bot/:id" component={EditMessageBot} />
      <PrivateRoute exact path="/comparacion-ventas" component={ComparationSalesPage} />
      <PrivateRoute exact path="/analisis-productos" component={comparationProduct} />
      <PrivateRoute exact path="/reporte-nomina" component={ReportPayrollPage} />
      <PrivateRoute exact path="/profile" component={ProfilePage} />
      <PrivateRoute exact path="/ventas-sucursales" component={SalesBrandPage} />
    </Switch>
  );
};

export default Routes;
