import React, { useState } from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap';
import uuid from 'react-uuid';
import { MdClose } from "react-icons/md";

import BlueTitleBack from '../Components/Widgets/BlueTitleBack';
import InputField from '../Components/Widgets/InputField';

const ModalExpenses = ({ show, onHide, typePayments, suppliers, onSubmit, listBranch }) => {

    const [form, setForm] = useState({ method_payment_id: 0, status_id: true })
    const [errors, setErrors] = useState({})

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSubmitTmp = () => {
        let _form = form
        _form.payment = typePayments.filter(row => row.id === parseInt(form.method_payment_id))[0]
        _form.supplier = suppliers.filter(row => row.id === parseInt(form.supplier_id))[0]
        _form._id = uuid();
        onSubmit(_form)
        setForm({})
    }

    return (
        <Modal show={show} onHide={onHide}>
            <div className="modal-header border-bottom-custom mt-2">
                <BlueTitleBack title={"Agregar Gastos"} />
                <button type="button" className="close p-0 m-0" data-dismiss="modal" aria-label="Close" onClick={onHide}>
                    <MdClose />
                </button>
            </div>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Sucursal</label>
                                <select className="form-control" name="branch_id" value={form.branch_id} onChange={updateInput}>
                                    <option>Seleccione</option>
                                    {listBranch.map((row, k) => <option key={row.id} value={row.id}>{row.title}</option>)}
                                </select>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Método de Pago</label>
                                <select className="form-control" name="method_payment_id" value={form.method_payment_id} onChange={updateInput}>
                                    <option>Seleccione</option>
                                    {typePayments.map((row, k) => <option key={row.id} value={row.id}>{row.description}</option>)}
                                </select>
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Proveedor</label>
                                <select className="form-control" name="supplier_id" value={form.supplier_id} onChange={updateInput}>
                                    <option>Seleccione</option>
                                    {suppliers.map((row, k) => <option key={row.id} value={row.id}>{row.business}</option>)}
                                </select>
                            </div>
                        </Col>
                    </Row>
                    <Row>

                        <Col>
                            <div className="form-group">
                                <label>Monto</label>
                                <InputField
                                    type="number"
                                    placeholder={"Monto"}
                                    value={form.amount || ''}
                                    name="amount"
                                    onChange={updateInput}
                                    error={errors.amount}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Estado Pago</label>
                                <select className='form-control' name='status_id' value={form.status_id} onChange={updateInput}>
                                    <option value={1}>Pendiente Pago</option>
                                    <option value={2}>Pagado</option>
                                </select>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Row >
                    <Col>
                        <button className='btn btn-danger' onClick={onHide}>Cerrar</button>
                    </Col>
                    <Col>
                        <button className='btn btn-success' onClick={onSubmitTmp}>Guardar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalExpenses;