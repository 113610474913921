import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  getExpenseByType,
  onCreateExpensesPersonal,
  deleteExpensePersonal,
  onUpdateExpensesPersonal,
  onCreateorUpdateExpenses,
} from "../../Redux/expensesDuck";
import {
  getParameterByGroup,
  createParameter,
} from "../../Redux/parameterDuck";
import { formatNumber } from "../../utils";
import ModalAddParameter from "../Components/ModalAddParameter";
import ModalParameter from "../Parameters/ModalParameter";
import ItemExpensesBalance from "./item.expenses.balance";

import ModalAddExpense from "./modal.add.expense";

import TemplateMenu from "../../template/menu.template";
import ModalAddAlterIngreso from "./modal.add.alter.ingreso";
import ModalAddAlterOtherExpense from "./modal.add.alter.other.expense";

const AddExpensePage = ({
  getExpenseByType,
  fetching_by_id,
  row,
  fetching_branch,
  branch_offices,
  getParameterByGroup,
  onCreateExpensesPersonal,
  fetching_create,
  createParameter,
  fetching_create_parameter,
  deleteExpensePersonal,
  onUpdateExpensesPersonal,
  errors,
  onCreateorUpdateExpenses,
  fetching_group,
  data_group,
}) => {
  let { type } = useParams();

  const [data, setData] = useState([]);
  const [form, setForm] = useState({});
  const [modalOther, setModalOther] = useState(false);
  const [tips, setTips] = useState(0);
  const [title, setTitle] = useState("");
  const [modal, setModal] = useState(false);
  const [modalIngreso, setModalIngreso] = useState(false);
  const [listBranch, setListBranch] = useState([]);
  const [listExpenses, setListExpenses] = useState([]);
  const [modalParam, setModalParam] = useState({
    open: false,
    title: "Pendiente",
  });
  const [lastParam, setLastParam] = useState({});
  const [filter, setFilter] = useState({
    date_init: moment().format("YYYY-MM-01"),
    date_end: moment().format("YYYY-MM-DD"),
  });
  const history = useHistory();
  const [total, setTotal] = useState(0);
  const [totalIngreso, setTotalIngreso] = useState(0);
  const [totalIngresoWitoutTax, setTotalIngresoWithoutTax] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);
  const [listParameterGroup, setListParameterGroup] = useState([]);

  useEffect(() => {
    getParameterByGroup("category");
  }, []);

  useEffect(() => {
    if (fetching_by_id === 2) {
      setTitle(row.type);
      setData(row.total);
      setTips(row.total_tip);
      setListExpenses(row.results);

      let total_ingreso = 0;

      if (row.type === "datafono") {
        total_ingreso = row.total.reduce(
          (a, b) =>
            (a =
              a +
              parseFloat(
                b.datafono_with_tax === "0"
                  ? b.ingreso || 0
                  : b.datafono_with_tax || 0
              )),
          0
        );
      } else {
        total_ingreso = row.total.reduce(
          (a, b) => (a = a + parseFloat(b.ingreso || 0)),
          0
        );
      }

      setTotalIngreso(total_ingreso);
      let total_ingreso_without_tax = row.total.reduce(
        (a, b) => (a = a + parseFloat(b.ingreso || 0)),
        0
      );
      setTotalIngresoWithoutTax(total_ingreso_without_tax);

      let total_expense = row.total
        .filter((d) => d.expense !== undefined)
        .reduce((a, b) => (a = a + parseFloat(b.expense || 0)), 0);

      setTotalExpense(total_expense);

      let tip = 0;

      if (row.type === "efectivo") {
        tip = parseFloat(row.total_tip);
      }

      setTotal(parseFloat(total_ingreso) - parseFloat(total_expense) - tip);
    }
  }, [fetching_by_id]);

  useEffect(() => {
    if (fetching_create_parameter === 2) {
      setModalParam(false);
      getParameterByGroup("category");
    }
  }, [fetching_create_parameter]);

  useEffect(() => {
    if (fetching_create === 2) {
      let _date = JSON.parse(localStorage.date);

      let q = `?init=${_date.date_init}&end=${_date.date_end}`;
      getExpenseByType(type, q);
      setModalIngreso(false);
      setModalOther(false);
    }
  }, [fetching_create]);

  useEffect(() => {
    if (fetching_group === 2) {
      setListParameterGroup(data_group);
    }
  }, [fetching_group]);

  useEffect(() => {
    if (fetching_branch === 2) {
      let branch = JSON.parse(localStorage.getItem("branch"));
      setListBranch(branch_offices.filter((r) => r.id !== branch.id));

      // setListBranch(branch_offices)
    }
  }, [fetching_branch]);

  useEffect(() => {
    let _date = JSON.parse(localStorage.date);

    let q = `?init=${_date.date_init}&end=${_date.date_end}`;

    getExpenseByType(type, q);
  }, []);

  const onDeleteTmp = (item) => {
    if (window.confirm("¿Deseas eliminar este Registro?")) {
      if (item.id === undefined) {
        let _list = data.filter((r) => r.id !== item.id);
        setData(_list);
      } else {
        deleteExpensePersonal(item.id);
        let _list = data.filter((r) => r.id !== item.id);
        setData(_list);
      }
    }
  };

  const onAddExpenses = (form) => {
    setModal(false);
    // setListExpenses([
    //     ...listExpenses,
    //     form
    // ])
    // setData([
    //     ...data,
    //     form
    // ])

    form.method_payment_id = type;

    onCreateExpensesPersonal(form);
  };

  const onSave = () => {
    onCreateExpensesPersonal(type, data);
  };

  const onOpenParameter = (title, dataType) => {
    let _title =
      dataType === 1 ? "Agregar otro Concepto" : "Pago otro Turneros";

    setModalParam({
      ...modalParam,
      open: true,
      title: _title,
      dataType,
    });
  };

  const onCreateParameter = (form) => {
    form.segment = "expense";
    form.priority = 1;
    setLastParam(form);
    createParameter(form);
  };

  const onEditServerItem = (item, value) => {
    onUpdateExpensesPersonal(item.id, value);
  };

  const updateInput = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const onFilter = () => {
    let q = `?init=${filter.date_init}&end=${filter.date_end}`;
    getExpenseByType(type, q);
  };
  const onAddIngresos = (form) => {
    form.movement_type_id = 2;
    onCreateorUpdateExpenses(form);
  };

  const onAddIngresosOther = (form) => {
    form.movement_type_id = 1;
    onCreateorUpdateExpenses(form);
  };

  return (
    <TemplateMenu>
      <Container fluid className="mt-3">
        <Row>
          <Col>
            <button
              className="btn btn-secondary mr-4 btn-sm"
              onClick={() => history.push("/balance")}
            >
              Volver
            </button>
          </Col>
          <Col lg={2} className="d-flex align-items-center">
            <button className="btn btn-primary" onClick={() => setModal(true)}>
              Agregar Gasto
            </button>
          </Col>
          <Col lg={2} className="d-flex align-items-center">
            <button
              className="btn btn-info"
              onClick={() => setModalOther(true)}
            >
              Pagar a otro Punto
            </button>
          </Col>
          <Col lg={2} className="d-flex align-items-center">
            <button
              className="btn btn-success"
              onClick={() => setModalIngreso(true)}
            >
              Agregar Ingreso
            </button>
          </Col>
          {/* <Col lg={5}>
                        <Row>
                            <Col>
                                <div className='form-group'>
                                    <label>Fecha de Inicio</label>
                                    <input className='form-control input-sm' name='date_init' type={"date"} onChange={updateInput} value={filter.date_init} />
                                </div>
                            </Col>
                            <Col >
                                <div className='form-group'>
                                    <label>Fecha de Fin</label>
                                    <input className='form-control input-sm' name='date_end' type={"date"} onChange={updateInput} value={filter.date_end} />
                                </div>
                            </Col>
                            <Col className='my-1 d-flex align-items-center'>
                                <button className='btn btn-warning' onClick={onFilter}>Filtrar</button>
                            </Col>
                        </Row>
                    </Col> */}
        </Row>

        <Row className="my-3">
          <Col lg={12}>
            <table className="table table-sm table-bordered">
              <thead className="thead-dark">
                <tr>
                  <th colSpan={6} className="text-center py-2 h3">
                    MOVIMIENTOS con (
                    <span className="text-uppercase">{title}</span>) desde{" "}
                    {moment(row.init).format("DD MMM YYYY")} hasta{" "}
                    {moment(row.end).format("DD MMM YYYY")}
                  </th>
                </tr>
                <tr>
                  <th className="text-center">FECHA</th>
                  <th className="text-center">GRUPO</th>
                  <th className="text-center">CONCEPTO</th>
                  <th className="text-center">INGRESO</th>
                  <th className="text-center">GASTO</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.length === 0 ? (
                  <tr>
                    <td colSpan={4} align="center">
                      No hay datos
                    </td>
                  </tr>
                ) : (
                  data.map((row, k) => (
                    <ItemExpensesBalance
                      row={row}
                      key={k}
                      onDeleteItem={onDeleteTmp}
                      onEditServerItem={onEditServerItem}
                    />
                  ))
                )}
                <tr>
                  <td colSpan={3}>
                    <b>SubTotal</b>
                  </td>
                  <td align="right">
                    <Row>
                      <Col>
                        <b>{formatNumber(totalIngreso)}</b>
                      </Col>
                    </Row>
                    {type == 375 && (
                      <>
                        <Row>
                          <Col className="text-danger">
                            {formatNumber(totalIngresoWitoutTax)}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="text-muted">
                            {formatNumber(totalIngreso - totalIngresoWitoutTax)}
                          </Col>
                        </Row>
                      </>
                    )}
                  </td>
                  <td align="right">
                    <b>{formatNumber(totalExpense)}</b>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <b>Propinas</b>
                  </td>
                  <td align="right">
                    <b>{formatNumber(tips)}</b>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <b>TOTAL</b>
                  </td>
                  <td align="right">
                    <b>{formatNumber(total)}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>

        <ModalAddExpense
          show={modal}
          listBranch={listBranch}
          listParameterGroup={listParameterGroup}
          onSubmit={onAddExpenses}
          onOpenParameter={onOpenParameter}
          type={title}
          onHide={() => setModal(false)}
          available={total}
        />

        <ModalAddParameter
          show={modalParam.open}
          title={modalParam.title}
          dataType={modalParam.dataType}
          onHide={() => setModalParam({ ...modalParam, open: false })}
          onSuccess={onCreateParameter}
        />

        <ModalAddAlterIngreso
          show={modalIngreso}
          listBranch={listBranch}
          listParameterGroup={listParameterGroup}
          onSubmit={onAddIngresos}
          onOpenParameter={onOpenParameter}
          listMethods={[]}
          title={title}
          type={type}
          onHide={() => setModalIngreso(false)}
          formEdit={form}
          errors={errors}
          available={total}
        />

        <ModalAddAlterOtherExpense
          show={modalOther}
          listBranch={listBranch}
          listParameterGroup={listParameterGroup}
          onSubmit={onAddIngresosOther}
          onOpenParameter={onOpenParameter}
          listMethods={[]}
          type={type}
          onHide={() => setModalOther(false)}
          formEdit={form}
          errors={errors}
          available={total}
        />
      </Container>
    </TemplateMenu>
  );
};

const mapsState = (props) => {
  let { expense, branchoffice, parameter } = props;
  return {
    row: expense.row,
    fetching_by_id: expense.fetching_by_id,
    fetching_create: expense.fetching_create,
    branch_offices: branchoffice.data,
    fetching_branch: branchoffice.fetching,
    fetching_by_segment: parameter.fetching_by_segment,
    parameters: parameter.data_segment,
    fetching_create_parameter: parameter.fetching_create,
    errors: expense.errors,
    fetching_group: parameter.fetching_group,
    data_group: parameter.data_group,
  };
};

export default connect(mapsState, {
  getExpenseByType,
  getParameterByGroup,
  onCreateExpensesPersonal,
  createParameter,
  deleteExpensePersonal,
  onUpdateExpensesPersonal,
  onCreateorUpdateExpenses,
})(AddExpensePage);
