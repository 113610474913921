import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import TemplateMenu from '../../template/menu.template'
import { getListTransformationById, onEditTransformation } from '../../Redux/transformationDuck';
import { connect } from 'react-redux';
import { Card, Col, Row } from 'react-bootstrap';

import FormTransformation from './form.transformation';


const EditTranformationPage = ({ getListTransformationById, errors, fetching_by_id, row, onEditTransformation, fetching_create }) => {
    let { id } = useParams();

    const [form, setForm] = useState({});
    const history = useHistory();


    useEffect(() => {
        getListTransformationById(id)
    }, [id])

    useEffect(() => {
        if (fetching_by_id === 2) {
            setForm(row)
        }
    }, [fetching_by_id])

    useEffect(() => {
        if (fetching_create === 2) {
            setForm({})
            history.goBack()
        }
    }, [fetching_create])


    return (
        <TemplateMenu>
            <Row className='d-flex justify-content-center'>
                <Col>
                    <Row className='my-2'>
                        <Col>
                            <button className='btn btn-sm btn-secondary' onClick={() => {
                                setForm({})
                                history.goBack()
                            }}>Volver</button>
                        </Col>
                    </Row>

                    <Row className='d-flex justify-content-center'>
                        <Col>
                            <FormTransformation title="Editar Transformación" formEdit={form} errors={errors} onSubmit={onEditTransformation} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { transformation } = props

    return {
        errors: transformation.errors,
        fetching_by_id: transformation.fetching_by_id,
        fetching_create: transformation.fetching_create,
        row: transformation.row,
    }
}

export default connect(mapsState, { getListTransformationById, onEditTransformation })(EditTranformationPage);