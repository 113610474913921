import React, { useEffect, Fragment, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { connect } from "react-redux";
import { getBranch, deleteBranch } from "../../Redux/branchofficeDuck";
import { Card, Button } from "react-bootstrap";
import ModalBranch from "./ModalBranch";
import BtnWhiteHeader from "./../Components/BtnWhiteHeader";
import BlueTitleBack from "./../Components/Widgets/BlueTitleBack";
import BtnOrangeSm from "./../Components/Widgets/BtnOrangeSm";
import BtnBlueModal from "./../Components/Widgets/BtnBlueModal";
import { FaWarehouse } from "react-icons/fa";
import TemplateMenu from "../../template/menu.template";

const BranchOffice = ({ getBranch, data, deleteBranch, user }) => {
  const initState = { title: "", address: "", priority: "" };

  const [showModal, setShowModal] = useState(false);
  const [current, setCurrent] = useState(initState);

  useEffect(() => {
    getBranch();
  }, []);

  const deleteItemBranch = (item) => {
    if (window.confirm("¿Deseas eliminar esta sucursal?")) {
      deleteBranch(item);
    }
  };

  const editBranch = (item) => {
    setShowModal(true);
    setCurrent(item);
  };

  const showModalTmp = () => {
    setCurrent(initState);
    setShowModal(true);
  };
  const onHide = () => {
    setShowModal(false);
  };

  const printSchedule = (item, i) => {
    return (
      <Row key={i}>
        <Col lg={5} className="text-uppercase">
          {item.day}
        </Col>
        <Col>{item.content}</Col>
      </Row>
    );
  };

  const onSuccess = () => {
    getBranch();
  };

  const printDetail = (item, i) => {
    let { schedule } = item;

    return (
      <Fragment key={i}>
        <Col lg={4} className="my-2">
          <Card className=" bg-white rounded-lg card-scale">
            <Card.Body>
              <Row className="p-3">
                <div className="p-3 rounded-lg border-dot text-center d-flex justify-content-center align-items-center w-100 bg-golden-light">
                  <h6 className="text-naranja text-center pt-1 mr-3">Sede</h6>
                  <h6 className="display-4 text-naranja text-center">
                    {item.title}
                  </h6>
                </div>
              </Row>
              {item.is_warehouse === true && (
                <Row className="my-2 d-flex justify-content-center">
                  <Col lg={2}>
                    <FaWarehouse size={40} />
                  </Col>
                </Row>
              )}
              <Row className="my-2">
                <Col lg={4} className="text-gris">
                  Orden
                </Col>
                <Col>{item.priority}</Col>
              </Row>
              <Row className="my-2">
                <Col lg={4} className="text-gris">
                  Dirección
                </Col>
                <Col>
                  {item.address}{" "}
                  {item.indicator !== null ? " - " + item.indicator : ""}
                </Col>
              </Row>
              <Row className="my-2">
                <Col lg={4} className="text-gris">
                  Celular
                </Col>
                <Col>{item.phone}</Col>
              </Row>
              <Row className="my-2">
                <Col lg={4} className="text-gris">
                  Estado
                </Col>
                <Col>{item.status_id === 1 ? "Activo" : "Inactivo"}</Col>
              </Row>

              <Row className="mt-4">
                <BlueTitleBack title="HORARIO DE ATENCIÓN" />
              </Row>
              <Row className="mt-4">
                <Col>
                  {schedule !== undefined && schedule.length > 0 ? (
                    schedule.map(printSchedule)
                  ) : (
                    <Row>
                      <Col className="text-center">
                        No hay horario de atención
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Card.Body>
            <Card.Body>
              <Row className="justify-content-center">
                {/* <Col>
                  <button
                    title="Eliminar"
                    className="btn btn-danger form-control"
                    onClick={() => deleteItemBranch(item)}
                  >
                    Eliminar
                  </button>
                </Col> */}
                <Col>
                  <button
                    className="btn btn-info form-control"
                    onClick={() => editBranch(item)}
                  >
                    Editar
                  </button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Fragment>
    );
  };

  console.log('user', user);

  return (
    <TemplateMenu>
      <Container fluid>
        <Row className="my-4">
          <Col lg={2} className="d-flex align-items-center">
            <h4 className="font-title h1 ml-3">SUCURSALES</h4>
          </Col>
          <Col lg={2}>
            {(user.role_id === 1 || user.role_id === 2) &&
              <BtnWhiteHeader title="Crear" onClick={showModalTmp} />}

          </Col>
        </Row>
        <Row>{data !== undefined && data.map(printDetail)}</Row>
        <ModalBranch
          showModal={showModal}
          onHide={onHide}
          current={current}
          onSuccess={onSuccess}
        />
      </Container>
    </TemplateMenu>
  );
};

const mapsState = (props) => {
  let { user, branchoffice } = props
  return {
    data: branchoffice.data,
    user: user.data,
  };
};

export default connect(mapsState, { getBranch, deleteBranch })(BranchOffice);
