import React, { useState } from 'react'
import { formatNumber } from '../../utils';
import { AiFillEdit } from 'react-icons/ai';
import { Col, Row } from 'react-bootstrap';

const ItemTableWorker = ({ item, tip, onChangeStatus, onChangeDiscount }) => {

    let { position } = item
    const [show, setShow] = useState(false)
    const [discount, setDiscount] = useState('')
    let total = item.tips===true?item.pay_individual - (item.pay_individual * (item.discount / 100) || 0):0
    let _tip = item.tips===true?tip:0

    const onEdit = () => {
        setShow(true)
    }
    const onSaveDiscount = () => {
        onChangeDiscount(item,discount)
        setShow(false)
    }

    const onSelectTip = () => {
        onChangeStatus(item)
    }

    return (
        <tr className={`${item.tips===true?'table-primary':''}`}>
            <td><input type={"checkbox"} className='form-control form-control-custom' checked={item.tips} onChange={() => onSelectTip(!item.tips)} /></td>
            <td>{item.name}</td>
            <td>{position.description}</td>
            <td>{item.type_worker_text}</td>
            <td>{formatNumber(_tip)}</td>
            <td>{item.discount || 0}%</td>
            <td>{formatNumber(total || 0)}</td>
            <td>
                {show ? <>
                    <Row>
                        <Col lg={12} className="my-1">
                            <input className='form-control' placeholder='%' name="discount" value={discount} onChange={(e) => setDiscount(e.target.value)} />
                        </Col>
                        <Col>
                            <button className='btn form-control btn-sm btn-info' onClick={onSaveDiscount}>Guardar</button>
                        </Col>
                    </Row>

                </> : <button className='btn btn-sm btn-warning' onClick={onEdit} >Agregar Descuento</button>}

            </td>
        </tr>
    )
}

export default ItemTableWorker;