import React, { Fragment } from 'react'

const SelectFieldDate = ({ name, value, data, onChange, error,reference,selected }) => {

    return (
        <Fragment>
            <select className="form-control" name={name} value={value} onChange={onChange} ref={reference}>
                {/* <option value="0">Seleccion la Sucursal</option> */}
                {data.map((row, i) => <option key={i} value={row.id}>{row.title}</option>)}
            </select>
            {error !== undefined && (<label className="error-label">{error}</label>)}
        </Fragment>
    )
}

export default SelectFieldDate