import axios from 'axios'
import { getHeaders } from '../utils';
import { logOutAction } from './userDuck';
const API_URL = process.env.REACT_APP_API_URL


let initialState = {
    fetching: 0,
    fetching_by_end: 0,
    fetching_available: 0,
    fetching_product: 0,
    fetching_franchise: 0,
    fetching_by_brand: 0,
    data: [],
    data_end: [],
    data_available: [],
    data_franchise: [],
    data_sales: [],
    data_product: [],
}

let REVIEW = "REVIEW";
let REVIEW_SUCCESS = "REVIEW_SUCCESS";
let END_MONTH = "END_MONTH";
let END_MONTH_SUCCESS = "END_MONTH_SUCCESS";
let END_MONTH_FILTER_SUCCESS = "END_MONTH_FILTER_SUCCESS";
let AVAILABLE = "AVAILABLE";
let AVAILABLE_SUCCESS = "AVAILABLE_SUCCESS";
let SALES = "SALES";
let SALES_SUCCESS = "SALES_SUCCESS";
let COMPARATION_PRODUCT = "COMPARATION_PRODUCT";
let COMPARATION_PRODUCT_SUCCESS = "COMPARATION_PRODUCT_SUCCESS";
let FRANCHISE = "FRANCHISE";
let FRANCHISE_SUCCESS = "FRANCHISE_SUCCESS";
let SALES_BY_BRAND = "SALES_BY_BRAND";
let SALES_BY_BRAND_SUCCESS = "SALES_BY_BRAND_SUCCESS";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case REVIEW:
            return { ...state, fetching: 1 }
        case REVIEW_SUCCESS:
            return { ...state, fetching: 2, data: action.payload }
        case END_MONTH:
            return { ...state, fetching_by_end: 1, data_end: [] }
        case END_MONTH_SUCCESS:
            return { ...state, fetching_by_end: 2, data_end: action.payload }
        case END_MONTH_FILTER_SUCCESS:
            return { ...state, fetching_by_end: 3, data_end: action.payload }
        case AVAILABLE:
            return { ...state, fetching_available: 1 }
        case AVAILABLE_SUCCESS:
            return { ...state, fetching_available: 2, data_available: action.payload }
        case SALES:
            return { ...state, fetching_sales: 1 }
        case SALES_SUCCESS:
            return { ...state, fetching_sales: 2, data_sales: action.payload }
        case COMPARATION_PRODUCT:
            return { ...state, fetching_product: 1 }
        case COMPARATION_PRODUCT_SUCCESS:
            return { ...state, fetching_product: 2, data_product: action.payload }
        case FRANCHISE:
            return { ...state, fetching_franchise: 1 }
        case FRANCHISE_SUCCESS:
            return { ...state, fetching_franchise: 2, data_franchise: action.payload }
        case SALES_BY_BRAND:
            return { ...state, fetching_by_brand: 1, }
        case SALES_BY_BRAND_SUCCESS:
            return { ...state, fetching_by_brand: 2, data_sales: action.payload }
        default:
            return state;
    }
}

export let getReview = () => (dispatch, getState) => {

    dispatch({
        type: REVIEW
    })

    if (getState().user.loggedIn) {

        let url = `${API_URL}/api/report/review`

        if (localStorage.date !== undefined) {
            console.log('localStorage', localStorage.date);

            let _date = JSON.parse(localStorage.date);
            let _branch = JSON.parse(localStorage.branch);

            url = `${API_URL}/api/report/review?init=${_date.date_init}&end=${_date.date_end}&branch=${_branch.id}`
        }


        axios.get(url, {
            headers: getHeaders()

        }).then(resp => {

            dispatch({
                type: REVIEW_SUCCESS,
                payload: resp.data.results
            });

        }).catch(error => {
            console.log('error', error)
            let { status } = error.response;

            if (status === 401) {
                logOutAction()(dispatch, getState)
            }

        });
    } else {
        console.log("no Ingreso");
    }
}
export let getComparationSales = () => (dispatch, getState) => {

    dispatch({
        type: SALES
    })

    if (getState().user.loggedIn) {


        let branch = JSON.parse(localStorage.getItem("branch"))
        let url = `${API_URL}/api/report-comparation-sales?branch_id=${branch.id}`

        if (localStorage.date !== undefined) {
            let _date = JSON.parse(localStorage.date);
            let _branch = JSON.parse(localStorage.branch);

            url = `${API_URL}/api/report-comparation-sales?init=${_date.date_init}&end=${_date.date_end}&branch=${_branch.id}`
        }


        axios.get(url, {
            headers: getHeaders()

        }).then(resp => {

            dispatch({
                type: SALES_SUCCESS,
                payload: resp.data
            });

        }).catch(error => {
            console.log('error', error)
            let { status } = error.response;

            if (status === 401) {
                logOutAction()(dispatch, getState)
            }

        });
    } else {
        console.log("no Ingreso");
    }
}
export let getComparationProduct = () => (dispatch, getState) => {

    dispatch({
        type: COMPARATION_PRODUCT
    })

    if (getState().user.loggedIn) {


        let branch = JSON.parse(localStorage.getItem("branch"))
        let url = `${API_URL}/api/report-comparation-product?branch_id=${branch.id}`

        if (localStorage.date !== undefined) {
            let _date = JSON.parse(localStorage.date);
            let _branch = JSON.parse(localStorage.branch);

            url = `${API_URL}/api/report-comparation-product?init=${_date.date_init}&end=${_date.date_end}&branch=${_branch.id}`
        }


        axios.get(url, {
            headers: getHeaders()

        }).then(resp => {

            dispatch({
                type: COMPARATION_PRODUCT_SUCCESS,
                payload: resp.data
            });

        }).catch(error => {
            console.log('error', error)
            let { status } = error.response;

            if (status === 401) {
                logOutAction()(dispatch, getState)
            }

        });
    } else {
        console.log("no Ingreso");
    }
}


export let getReviewSales = () => (dispatch, getState) => {

    dispatch({
        type: SALES
    })

    if (getState().user.loggedIn) {


        let branch = JSON.parse(localStorage.getItem("branch"))
        let url = `${API_URL}/api/report/review-sales?branch_id=${branch.id}`

        if (localStorage.date !== undefined) {
            let _date = JSON.parse(localStorage.date);
            let _branch = JSON.parse(localStorage.branch);

            url = `${API_URL}/api/report/review-sales?init=${_date.date_init}&end=${_date.date_end}&branch=${_branch.id}`
        }


        axios.get(url, {
            headers: getHeaders()

        }).then(resp => {

            dispatch({
                type: SALES_SUCCESS,
                payload: resp.data
            });

        }).catch(error => {
            console.log('error', error)
            let { status } = error.response;

            if (status === 401) {
                logOutAction()(dispatch, getState)
            }

        });
    } else {
        console.log("no Ingreso");
    }
}

export let getAvailableMoney = (method_payment_id, branch_id) => (dispatch, getState) => {
    let _date = JSON.parse(localStorage.date);
    let url = `${API_URL}/api/report/cash-available?date_init${_date.date_init}&date_end=${_date.date_end}&method_payment_id=${method_payment_id}`

}

export let getReviewCash = (date_init, date_end) => (dispatch, getState) => {

    dispatch({
        type: REVIEW
    })

    let branch = JSON.parse(localStorage.getItem("branch"))

    let _date = JSON.parse(localStorage.date);

    date_init = (date_init !== undefined) ? date_init : _date.date_init
    date_end = (date_end !== undefined) ? date_end : _date.date_end


    let url = `${API_URL}/api/report/cash?branch_id=${branch.id}&init=${date_init}&end=${date_end}`

    if (getState().user.loggedIn) {

        axios.get(url, {
            headers: getHeaders()

        }).then(resp => {

            dispatch({
                type: REVIEW_SUCCESS,
                payload: resp.data
            });

        }).catch(error => {
            console.log('error', error)
            let { status } = error.response;

            if (status === 401) {
                logOutAction()(dispatch, getState)
            }

        });
    } else {
        console.log("no Ingreso");
    }
}

export let getReviewEndMonth = () => (dispatch, getState) => {
    dispatch({
        type: END_MONTH
    })

    let branch = JSON.parse(localStorage.getItem("branch"))

    let _date = JSON.parse(localStorage.date);

    let filter = `?branch_id=${branch.id}&init=${_date.date_init}&end=${_date.date_end}`

    let url = `${API_URL}/api/report/end-month${filter}`

    if (getState().user.loggedIn) {

        axios.get(url, {
            headers: getHeaders()

        }).then(resp => {

            dispatch({
                type: END_MONTH_SUCCESS,
                payload: resp.data
            });

        }).catch(error => {
            console.log('error', error)
            let { status } = error.response;

            if (status === 401) {
                logOutAction()(dispatch, getState)
            }

        });
    } else {
        console.log("no Ingreso");
    }
}

export let getReviewFranchise = (external_filter) => (dispatch, getState) => {
    dispatch({
        type: FRANCHISE
    })

    let branch = JSON.parse(localStorage.getItem("branch"))

    external_filter = external_filter || '';

    let _date = JSON.parse(localStorage.date);
    let filter;
    if (external_filter !== '') {
        filter = `?branch_id=${branch.id}&${external_filter}`
    } else {
        filter = `?branch_id=${branch.id}&init=${_date.date_init}&end=${_date.date_end}${external_filter}`
    }


    let url = `${API_URL}/api/report/franchise${filter}`

    if (getState().user.loggedIn) {

        axios.get(url, {
            headers: getHeaders()

        }).then(resp => {

            console.log('resp.data', resp.data);

            if (external_filter === '') {
                dispatch({
                    type: FRANCHISE_SUCCESS,
                    payload: resp.data.results
                });
            } else {
                dispatch({
                    type: FRANCHISE_SUCCESS,
                    payload: resp.data.results
                });
            }


        }).catch(error => {
            console.log('error', error)
            let { status } = error.response;

            if (status === 401) {
                logOutAction()(dispatch, getState)
            }

        });
    } else {
        console.log("no Ingreso");
    }
}


export let getSalesByBrand = (form) => (dispatch, getState) => {
    dispatch({
        type: SALES_BY_BRAND
    })

    let branch = JSON.parse(localStorage.getItem("branch"))

    let url = `${API_URL}/api/report/sales-by-brand?date_init=${form.date_init}&date_end=${form.date_end}&branch_id=${branch.id}&order_by_total=${form.order_by_total}&search=${form.search}`

    if (getState().user.loggedIn) {

        axios.get(url, {
            headers: getHeaders()

        }).then(resp => {

            dispatch({
                type: SALES_BY_BRAND_SUCCESS,
                payload: resp.data
            });
        }).catch(error => {
            console.log('error', error)
            let { status } = error.response;

            if (status === 401) {
                logOutAction()(dispatch, getState)
            }

        });
    } else {
        console.log("no Ingreso");
    }
}