import React, { memo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ItemGroupDining from './item.group.dining'

const CardDinig = ({ item, deleteCategory, editCategory }) => {

    let { dining_table } = item

    return (
        <>
            <Row className='d-flex justify-content-center border-bottom mt-4'>
                <Col lg={2}>
                    <h4 className='text-center'>{item.description}</h4>
                </Col>
                <Col lg={2}>
                    <Link className='btn btn-sm btn-info' to={`/mesas/${item.id}`}>Crear Mesa en {item.description}</Link>
                </Col>
            </Row>
            <Row>

                {dining_table.length > 0
                    ? dining_table.map(d => <ItemGroupDining item={d} key={d.id} />)
                    : <Row className='my-4'><Col className='text-center'>No tienes Mesas Asociadas</Col></Row>}
            </Row>
        </>)


}
export default CardDinig;