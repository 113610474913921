import React from 'react';

const ButtonNaranja =({title, onClick, disabled}) => {

    return(
        <div className="w-100 btn-confirm bg-naranja cursor mt-1 rounded" disabled={disabled}>
            <button type="submit" className="w-100 py-2 " onClick={onClick}>{title}</button>
        </div>
    )
}

export default ButtonNaranja;

