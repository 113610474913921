import React from 'react'

const ItemPublicity = ({ item, onClick, onClickAdd, onClickDelete }) => {
    return (
        <tr>
            <td>{item.description}</td>
            <td>
                <button className='btn btn-sm btn-success' onClick={() => onClickAdd(item)}>Agregar</button>
                <button className='btn btn-sm btn-info  mx-2' onClick={() => onClick(item)}>Mostrar</button>
                <button className='btn btn-sm btn-danger' onClick={() => onClickDelete(item)}>Eliminar</button>
            </td>
        </tr>
    )
}

export default ItemPublicity;