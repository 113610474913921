import { Page, PDFViewer, View,Text, Document, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getRequisitionById } from '../../Redux/requisitionDuck'

const styles = StyleSheet.create({
    page: {
        display: 'flex',
        flexDirection: 'column',
        borderTop: '1px solid #EEE',
        paddingTop: 8,
        paddingBottom: 8,
        marginLeft:15,
        width:"100%",
        height:"100%",
        fontSize:"12px"
    },
    table:{
        backgroundColor:"red"
       
    },
    row:{
        display:"flex",
        width:"95%",
        flexDirection:"row"
    },
    column:{
        width:"100%",
        flexDirection:"column"
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    }
});


const RequisitionPDF=({getRequisitionById, fetching_by_id,row})=>{
    const { id } = useParams();
    const [data,setData] = useState({})

    useEffect(() => {
        if(fetching_by_id === 2){
            setData(row)
        }
    }, [fetching_by_id])

    useEffect(() => {
        getRequisitionById(id)
    }, [id])

    return (
        <PDFViewer style={{ width: '100%', height: '100vh' }}>
              <Document>
                <Page size="A4" style={styles.page}>
                    <View style={[styles.row,{marginTop:"20px",justifyContent:"center"}]}>
                        <Text style={[{fontSize:"18px"}]}>Requisición #{data.id}</Text>
                    </View>
                    <View style={[styles.row,{marginTop:"5px"}]}>
                        <View style={styles.column}>
                            <Text>Fecha: {moment(data.registration_date).format("YYYY-MM-DD") }</Text>
                        </View>
                        <View style={styles.column}>
                            <Text>Estado: {data.status_text}</Text>
                        </View>
                    </View>
                    <View style={[styles.row,{marginTop:"5px"}]}>
                        <View style={styles.column}>
                            <Text>Punto de Solicitud: {data.branch}</Text>
                        </View>
                        <View style={styles.column}>
                            <Text>Responsable: {data.branch}</Text>
                        </View>
                    </View>
                    <View style={[styles.row,{marginTop:"5px"}]}>
                        <View style={styles.column}>
                            <View style={styles.table}>asdasd</View>
                        </View>
                    </View>
                    
                </Page>
                
            </Document>
        </PDFViewer>
    )
}

const mapsState=(props)=>{
    let {requisition} = props

    console.log('requisition',requisition);
    
    return {
        fetching_by_id:requisition.fetching_by_id,
        row:requisition.row,
    }
}

export default connect(mapsState,{getRequisitionById})(RequisitionPDF);