import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import {
  getIngresos,
  reverseIngreso,
  deleteIngreso
} from "../../Redux/IngresosDuck";
import { getBranch } from "../../Redux/branchofficeDuck";
import { } from "../../Redux/menuDuck";
import { formatNumber } from "../../utils";
import { Link } from "react-router-dom";

import "./index.css";
import IngresosItem from "./ingresos.item";
import moment from "moment";
import ModalUnlockIngresos from "./modal.unlock.ingresos";
import { AiFillFilter } from "react-icons/ai";
import TemplateMenu from "../../template/menu.template";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { RiFileExcel2Fill } from "react-icons/ri";
import ModalRegistrationDate from "./modal.registration_date";
import { toogleLoader } from "../../Redux/alertDuck";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

// import ModalFilterIngresos from './modal.filter.segment';

const API_URL = process.env.REACT_APP_API_URL;

const IngresosPage = ({
  getIngresos,
  data,
  deleteIngreso,
  fetching,
  fetching_create,
  user,
  reverseIngreso,
  fetching_reverse,
  fetching_list,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalFilter, setModalFilter] = useState(false);
  const [filter, setFilter] = useState({
    date_init: moment().format("YYYY-MM-01"),
    date_end: moment().format("YYYY-MM-DD"),
    status_selected: 0,
  });
  const [form, setForm] = useState();
  const [formRow, setFormRow] = useState({});
  const [showBranch, setShowBranch] = useState(false);
  const [modalDate, setModalDate] = useState(false);
  const [list, setList] = useState([]);
  const [title, setTitle] = useState('FECHA DE CIERRE');
  const tableRef = useRef()
  const history = useHistory()

  useEffect(() => {
    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))
    let q = `init=${date_init}&end=${date_end}&status=${filter.status_selected}`;
    getIngresos(q);
  }, []);

  useEffect(() => {
    if (fetching === 2) {
      let q = `init=${filter.date_init}&end=${filter.date_end}&status=${filter.status_selected}`;
      getIngresos(q);
    }
  }, [fetching]);

  useEffect(() => {
    if (fetching_list === 2) {
      let branch = JSON.parse(localStorage.branch)

      if (branch.id === 0) {
        setShowBranch(true)
      } else {
        setShowBranch(false)
      }
      setList(data)
    }
  }, [fetching_list]);

  useEffect(() => {
    if (fetching_create === 2) {
      let q = `init=${filter.date_init}&end=${filter.date_end}&status=${filter.status_selected}`;

      getIngresos(q);
    }
  }, [fetching_create]);
  useEffect(() => {
    if (fetching_reverse === 2) {
      setShowModal(false);
      let q = `init=${filter.date_init}&end=${filter.date_end}&status=${filter.status_selected}`;

      getIngresos(q);
    }
  }, [fetching_reverse]);

  const updateInput = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const onFilter = () => {
    let q = `init=${filter.date_init}&end=${filter.date_end}&status=${filter.status_selected}`;
    getIngresos(q);
  }

  const onUnLock = (item) => {
    setForm(item);
    setShowModal(true);
  }

  const onReverseIngreso = (item) => {
    reverseIngreso(item);
  }

  const onDeleteIngreso = (item) => {
    if (window.confirm("¿Realmente deseas eliminar el Cierre?")) {
      deleteIngreso(item);
    }
  };

  const onFilterData = (form) => {
    setModalFilter(false);

    let q = `?status=${form.status_selected}&init=${filter.date_init}&end=${filter.date_end}`;
    getIngresos(q);
  }

  const onEditRegistrationDate = (row) => {
    setFormRow(row)
    setModalDate(true)
    setTitle("MODIFICAR FECHA DE CIERRE")
  }

  const onSuccessEditRegistrationDate = () => {
    setFormRow({})
    setModalDate(false)
    toogleLoader(true)
    let q = `init=${filter.date_init}&end=${filter.date_end}&status=${filter.status_selected}`;
    getIngresos(q);
  }

  const onSuccessCreateRegistrationDate = (row) => {
    setModalDate(false)
    toogleLoader(true)
    history.push(`/ingresos/${row.id}`)
  }

  const onCreate = () => {
    setModalDate(true);

    setTitle("CREAR CIERRE")
  }

  return (
    <TemplateMenu>
      <Container fluid>
        <Row className="my-4">
          <Col lg={3} md={4}>
            <Row>
              <Col>
                <h4 className="font-title pt-3">INGRESOS DIARIOS</h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <button className="btn btn-info" onClick={onCreate}>
                  Crear
                </button>

                {/* <Link className="btn btn-info" to="/ingresos/new">
                  Crear
                </Link> */}
              </Col>
              <Col className="d-flex align-items-center">
                <DownloadTableExcel
                  filename="cierres"
                  sheet="cierres"
                  currentTableRef={tableRef.current}>
                  <RiFileExcel2Fill size={25} color="green" />
                </DownloadTableExcel>
              </Col>
              <Col>
                <Link to={`/ingresos-altern`} className="btn btn-warning">Cambio</Link>
              </Col>
            </Row>
          </Col>
          {(user.role_id === 1 || user.role_id === 2 || user.role_id === 5) && (
            <Col lg={9} md={10}>
              <Row>
                <Col>
                  <div className="form-group">
                    <label>Fecha de Inicio</label>
                    <input
                      className="form-control input-sm"
                      name="date_init"
                      type={"date"}
                      onChange={updateInput}
                      value={filter.date_init}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="form-group">
                    <label>Fecha de Fin</label>
                    <input
                      className="form-control input-sm"
                      name="date_end"
                      type={"date"}
                      onChange={updateInput}
                      value={filter.date_end}
                    />
                  </div>
                </Col>

                <Col>
                  <div className="form-group">
                    <label>Estado</label>
                    <select
                      className="form-control"
                      name="status_selected"
                      value={filter.status_selected}
                      onChange={updateInput}>
                      <option value="0">Seleccionar Todos</option>
                      <option value="1">Nuevo</option>
                      <option value="2">Revisión</option>
                      <option value="4">Finalizado</option>
                    </select>
                  </div>
                </Col>
                <Col className="my-1 d-flex align-items-center">
                  <button className="btn btn-warning" onClick={onFilter}>
                    Filtrar
                  </button>
                </Col>

              </Row>
            </Col>
          )}
        </Row>

        <Row>
          <Col lg={12} className="px-0 mx-0">
            <div className="table-responsive">
              <table className="table table-sm table-bordered  table-hover" ref={tableRef}>
                <thead className="thead-dark">
                  <tr>
                    <th className="text-center">Fecha</th>
                    {showBranch && <th>Punto</th>}
                    <th className="text-center">Venta POS</th>
                    <th className="text-center">Venta POS-Propina</th>
                    <th className="text-center">Pagos</th>
                    <th className="text-center">Venta Total</th>
                    <th>Efectivo</th>
                    <th>Dafono</th>
                    <th>Bancolombia</th>
                    <th>Davivienda</th>
                    <th>Rappi</th>
                    <th>Casa</th>
                    <th>Entregado</th>
                    <th>Diferenc</th>
                    <th>Propina</th>
                    <th>Estado</th>
                    {!showBranch && <th>Acciones</th>}

                  </tr>
                </thead>
                <tbody>
                  {list.length > 0 ? (
                    list.map((row, i) => (
                      <IngresosItem
                        item={row}
                        key={row.id}
                        user={user}
                        onUnLock={onUnLock}
                        is_up={false}
                        showBranch={showBranch}
                        onDoubleClick={() => onEditRegistrationDate(row)}
                      />
                    ))
                  ) : (
                    <tr>
                      <td colSpan={14} className="text-center">
                        No Tienes Registros
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr className="table-primary">
                    <td colSpan={showBranch ? 2 : 1}>Totales</td>
                    <td align="right">
                      {formatNumber(
                        data.reduce((a, b) => (a = a + parseFloat(b.status_id !== 1 ? b.aldelo_real || 0 : b.aldelo || 0)), 0)
                      )}
                    </td>
                    <td align="right">
                      {formatNumber(
                        data.reduce((a, b) => (a = a + parseFloat(b.status_id !== 1 ? b.aldelo_real || 0 : b.aldelo || 0)), 0) -
                        data.reduce((a, b) => (a = a + parseFloat(b.status_id === 4 ? b.tips_real || 0 : b.tips || 0)), 0)
                      )}
                    </td>
                    <td align="right">
                      {formatNumber(data.reduce((a, b) => (a = a + parseFloat(b.payments || 0)), 0))}
                    </td>
                    <td align="right">
                      {formatNumber(data.reduce((a, b) => (a = a + parseFloat(b.entregado || 0)), 0))}
                    </td>
                    <td align="right">
                      {formatNumber(data.reduce((a, b) => (a = a + parseFloat(b.status_id === 4 ? b.cash_real || 0 : b.cash || 0)), 0))}
                    </td>
                    <td align="right">
                      {formatNumber(data.reduce((a, b) => (a = a + parseFloat(b.status_id === 4 ? b.datafono_real || 0 : b.datafono || 0)), 0))}
                    </td>
                    <td align="right">
                      {formatNumber(data.reduce((a, b) => (a = a + parseFloat(b.status_id === 4 ? b.nequi_real || 0 : b.nequi || 0)), 0))}
                    </td>
                    <td align="right">
                      {formatNumber(data.reduce((a, b) => (a = a + parseFloat(b.status_id === 4 ? b.daviplata_real || 0 : b.daviplata || 0)), 0))}
                    </td>
                    <td align="right">
                      {formatNumber(data.reduce((a, b) => (a = a + parseFloat(b.status_id === 4 ? b.rappi_real || 0 : b.rappi || 0)), 0))}
                    </td>
                    <td align="right">
                      {formatNumber(data.reduce((a, b) => (a = a + parseFloat(b.status_id === 4 ? b.credit_home_real || 0 : b.credit_home || 0)), 0))}
                    </td>
                    <td align="right">
                      {formatNumber(data.reduce((a, b) => (a = a + parseFloat(b.entregado || 0)), 0)
                      )}
                    </td>
                    <td align="right">
                      {formatNumber(data.reduce((a, b) => (a = a + parseFloat(b.diference || 0)), 0))}
                    </td>
                    <td align="right">
                      {formatNumber(data.reduce((a, b) => (a = a + parseFloat(b.status_id === 4 ? b.tips_real || 0 : b.tips || 0)), 0))}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </Col>
        </Row>

        <ModalUnlockIngresos
          show={showModal}
          item={form}
          onDeleteIngreso={onDeleteIngreso}
          onSuccess={onReverseIngreso}
          handleClose={() => setShowModal(false)}
        />

        <ModalRegistrationDate show={modalDate} formEdit={formRow} title={title}
          onHide={() => setModalDate(false)}
          onEditSuccess={onSuccessEditRegistrationDate}
          onCreateSuccess={onSuccessCreateRegistrationDate} />

      </Container>
    </TemplateMenu>
  );
};

const mapsState = (props) => {
  let { ingresos, user, branchoffice, menu } = props
  return {
    user: user.data,
    form_permission: user.form_permission,
    data: ingresos.data,
    fetching: ingresos.fetching_delete,
    fetching_list: ingresos.fetching_list,
    fetching_create: ingresos.fetching_create,
    fetching_reverse: ingresos.fetching_reverse,
    fetching_branch: branchoffice.fetching,
    branch_offices: branchoffice.data,
    filter_branch: menu.branch.filter,
    fetching_filter: menu.fetching_filter,
  };
};

export default connect(mapsState, {
  getIngresos,
  reverseIngreso,
  deleteIngreso,
  getBranch
})(IngresosPage);
