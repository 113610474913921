import React from 'react'
import TemplateMenu from "../../template/menu.template";
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom';



const DataMasterPage = () => {
    return (
        <TemplateMenu>
            <Row>
                <Col><h4>Configuración Productos</h4></Col>
            </Row>
            <Row>
                <Col><Link to={"/maestros-productos"} >Productos</Link></Col>
            </Row>
            <Row className='mt-3'>
                <Col><Link to={"/cargos-productos"} >Listado de Cargos</Link></Col>
            </Row>
            <Row className='mt-3'>
                <Col><Link to={"/maestro-recetas"} >Listado de Recetas</Link></Col>
            </Row>

        </TemplateMenu>
    )
}

export default DataMasterPage;