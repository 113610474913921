import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import ItemMasterProduct from './item.master.product';

const ItemTableMaster = ({ item, i, row_branch }) => {

    const [form, setForm] = useState({ id: 0 })

    useEffect(() => {
        if (row_branch.id !== 0) {
            row_branch.product = item
            setForm(row_branch)
        }

    }, [row_branch])


    return (
        <tr>
            <td>{i}</td>
            <td>{item.product}</td>
            <td>{item.code}</td>
            {item.branch.map((b, k) => {
                return <ItemMasterProduct item={b} key={k} product_master={item} row_branch={form} />
            })}
        </tr>
    )
}

export default ItemTableMaster;