import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { getProducts } from '../../Redux/productDuck'
import { toogleAlert } from '../../Redux/alertDuck'
import { Container, Col, Row, Card } from 'react-bootstrap';

import { useHistory } from 'react-router-dom'
import BtnWhiteHeader from './../Components/BtnWhiteHeader'
import ListCategory from './ListCategory';
import TemplateMenu from '../../template/menu.template'

const Products = ({ getProducts, categories, fetching, fetching_list, toogleAlert }) => {

    const history = useHistory();

    useEffect(() => {
        toogleAlert(true)
        getProducts()
    }, [])


    useEffect(() => {

        if (fetching_list === 2) {
            toogleAlert(false)
        }

    }, [fetching_list])

    useEffect(() => {

        if (fetching === 4) {
            window.location = "/home"
        }

    }, [categories, fetching])

    const edit = (item) => {
        toogleAlert(true)
        history.push("productos/" + item.id)
    }


    return (<TemplateMenu>
        <Container fluid>
            <Row className="my-3">
                <Col lg={2} md={4} className="d-flex align-items-center pt-2"><h3 className="font-title">PRODUCTOS</h3></Col>
                <Col lg={2} md={4} >
                    <BtnWhiteHeader title="Crear" to="/productos/new" />
                </Col>
            </Row>

            {(categories !== undefined) && categories.map((row, key) => <ListCategory item={row} edit={edit} key={key} />)}


        </Container>
    </TemplateMenu>
    )
}

const mapsState = (props) => {
    return {
        categories: props.product.data,
        fetching: props.product.fetching,
        fetching_list: props.product.fetching_list
    }
}

export default connect(mapsState, { getProducts, toogleAlert })(Products);