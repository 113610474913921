import axios from 'axios'
import { getHeaders } from '../utils';

import { logOutAction } from './userDuck'

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_id: 0,
    fetching_all: 0,
    fetching_partial: 0,
    fetching_cancel: 0,
    fetching_programmed: 0,
    fetching_order_finish: 0,
    data: [],
    row: {},
    detail: [],
    reservation: {}
}

let ORDER = "ORDER";
let ORDER_SUCCESS = "ORDER_SUCCESS";
let CONFIRM = "CONFIRM";
let ORDER_RESTART = "ORDER_RESTART";
let CONFIRM_SUCCESS = "CONFIRM_SUCCESS";
let PARTIAL = "PARTIAL";
let PARTIAL_SUCCESS = "PARTIAL_SUCCESS";
let CANCEL_ORDER = "CANCEL_ORDER";
let CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
let PROGRAMMED = "PROGRAMMED";
let PROGRAMMED_SUCCESS = "PROGRAMMED_SUCCESS";
let ORDER_ID = "ORDER_ID";
let ORDER_ID_SUCCESS = "ORDER_ID_SUCCESS";
let ORDER_HISTORY = "ORDER_HISTORY";
let ORDER_HISTORY_SUCCESS = "ORDER_HISTORY_SUCCESS";

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ORDER:
            return { ...state, fetching_all: 1 }
        case ORDER_SUCCESS:
            console.log('action', action)
            return { ...state, fetching_all: 2, fetching_id: 0, fetching_order_finish: 0, data: action.payload, reservation: action.reservation }
        case ORDER_RESTART:
            return { ...state, fetching_all: 0 }
        case CONFIRM:
            return { ...state, fetching: 1 }
        case CONFIRM_SUCCESS:
            return { ...state, fetching: 2, row: action.payload }
        case PARTIAL:
            return { ...state, fetching_partial: 1 }
        case PARTIAL_SUCCESS:
            return { ...state, fetching_partial: 2 }
        case CANCEL_ORDER:
            return { ...state, fetching_cancel: 1 }
        case CANCEL_ORDER_SUCCESS:
            return { ...state, fetching_cancel: 2 }
        case PROGRAMMED:
            return { ...state, fetching_programmed: 1 }
        case PROGRAMMED_SUCCESS:
            return { ...state, fetching_programmed: 2, data: action.payload }
        case ORDER_ID:
            return { ...state, fetching_id: 1 }
        case ORDER_ID_SUCCESS:
            return { ...state, fetching_id: 2, detail: action.payload }
        case ORDER_HISTORY:
            return { ...state, fetching_order_finish: 1 }
        case ORDER_HISTORY_SUCCESS:
            return { ...state, fetching_order_finish: 2, data: action.payload }
        default:
            return state;
    }
}

export let restartOrder = () => (dispatch, getState) => {
    dispatch({
        type: ORDER_RESTART
    })
}

export let getOrderScheduled = () => (dispatch, getState) => {
    dispatch({
        type: PROGRAMMED
    })

    if (getState().user.loggedIn) {

        axios.get(`${API_URL}/api/list-scheduled`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: PROGRAMMED_SUCCESS,
                payload: resp.data.results
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let onFreeDining = (dining_table) => (dispatch, getState) => {
    dispatch({
        type: PARTIAL
    })

    if (getState().user.loggedIn) {

        axios.put(`${API_URL}/api/free-dining/${dining_table}`, {}, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: PARTIAL_SUCCESS,
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let onCancelOrder = (item, message) => (dispatch, getState) => {
    dispatch({
        type: CANCEL_ORDER
    })

    if (getState().user.loggedIn) {

        item.comment = message;

        axios.put(`${API_URL}/api/cancel-order/${item.id}`, item, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: CANCEL_ORDER_SUCCESS,
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let acceptPartialOrder = (reservation) => (dispatch, getState) => {
    dispatch({
        type: PARTIAL
    })

    if (getState().user.loggedIn) {

        axios.put(`${API_URL}/api/accept-partial/${reservation.id}`, {}, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: PARTIAL_SUCCESS,
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let getOrdersDiningTable = (reservation_id) => (dispatch, getState) => {
    dispatch({
        type: ORDER
    })

    if (getState().user.loggedIn) {

        axios.get(`${API_URL}/api/dining-order/${reservation_id}`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: ORDER_SUCCESS,
                payload: resp.data.results.detail,
                reservation: resp.data.results.reservation
            });

        }).catch(error => {


        });
    } else {
        console.log("no Ingreso");
    }
}

export let createOrder = () => (dispatch, getState) => {

    dispatch({
        type: ORDER
    })

    if (getState().user.loggedIn) {

        axios.post(`${API_URL}/api/orders`, {}, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: ORDER_SUCCESS,
                payload: resp.data.results,
                selected: resp.data
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}



export let acceptOrder = (id) => (dispatch, getState) => {
    dispatch({
        type: CONFIRM
    })


    if (getState().user.loggedIn) {

        axios.put(`${API_URL}/api/confirm-order/${id}`, {}, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: CONFIRM_SUCCESS,
                payload: resp.data.row,
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let getOrders = () => (dispatch, getState) => {
    dispatch({
        type: ORDER
    })

    let branch = JSON.parse(localStorage.getItem("branch"))


    if (getState().user.loggedIn) {

        axios.get(`${API_URL}/api/list-by-accept?branch_id=${branch.id}`, {
            headers: getHeaders()

        }).then(resp => {
            console.log('resp.results', resp.data.dates)
            dispatch({
                type: ORDER_SUCCESS,
                payload: resp.data.results
            });

        }).catch(error => {
            // console.log('error',error)
            // let { status } = error.response;

            // if (status === 401) {
            //     logOutAction()(dispatch, getState)
            // }

        });
    } else {
        console.log("no Ingreso");
    }
}

export let getOrdersFinish = () => (dispatch, getState) => {
    dispatch({
        type: ORDER_HISTORY
    })

    if (getState().user.loggedIn) {

        axios.get(`${API_URL}/api/list-order-finished`, {
            headers: getHeaders()


        }).then(resp => {
            dispatch({
                type: ORDER_HISTORY_SUCCESS,
                payload: resp.data.results
            });

        }).catch(error => {
            console.log('error', error)
            let { status } = error.response;

            if (status === 401) {
                logOutAction()(dispatch, getState)
            }

        });
    } else {
        console.log("no Ingreso");
    }
}

export let getOrderId = (id) => (dispatch, getState) => {
    dispatch({
        type: ORDER_ID
    })

    if (getState().user.loggedIn) {

        axios.get(`${API_URL}/api/list-by-accept-id/${id}`, {
            headers: getHeaders()

        }).then(resp => {
            dispatch({
                type: ORDER_ID_SUCCESS,
                payload: resp.data.results
            });

        }).catch(error => {
            let { status } = error.response;

            if (status === 401) {
                logOutAction()(dispatch, getState)
            }

        });
    } else {
        console.log("no Ingreso");
    }
}


export let getOrdersDining = () => (dispatch, getState) => {
    dispatch({
        type: ORDER
    })

    if (getState().user.loggedIn) {

        axios.get(`${API_URL}/api/list-by-accept`, {
            headers: getHeaders()

        }).then(resp => {
            dispatch({
                type: ORDER_SUCCESS,
                payload: resp.data.results
            });

        }).catch(error => {
            let { status } = error.response;

            if (status === 401) {
                logOutAction()(dispatch, getState)
            }

        });
    } else {
        console.log("no Ingreso");
    }
}
