import React, { useEffect, useState } from 'react'
import TemplateMenu from "../../template/menu.template";
import { Col, Row } from 'react-bootstrap';
import { getProductsCharge, getChargeById, onEditProductsCharge } from '../../Redux/ProductInventoryDuck'
import { connect } from 'react-redux';
import { toogleLoader } from '../../Redux/alertDuck'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import FormCharge from './form.charge';



const EditChargePage = ({ getProductsCharge, toogleLoader, fetching_charge, data_charge, getChargeById,
    fetching_by_id, row, onEditProductsCharge, fetching_create_charge }) => {

    const [list, setList] = useState({})
    const [form, setForm] = useState({ name: '' })

    let { id } = useParams();

    const history = useHistory();


    useEffect(() => {
        if (fetching_create_charge === 2) {
            history.goBack()
        }
    }, [fetching_create_charge])

    useEffect(() => {
        if (fetching_charge === 2) {

            let { detail } = row
            setForm(row)

            let _list = data_charge.map(d => {
                d.products.map(pro => {
                    detail.map(r => {
                        if (r.product_id === pro.id) {
                            pro.value = r.value
                        }

                    })
                    return pro;
                })
                return d
            });

            setList(_list)
            toogleLoader(false)
        }
    }, [fetching_charge])

    useEffect(() => {
        if (fetching_by_id === 2) {
            getProductsCharge()
        }
    }, [fetching_by_id])

    useEffect(() => {
        getChargeById(id)
        toogleLoader(true)
    }, [id])

    const onSucess = (form) => {
        onEditProductsCharge(form)
    }

    return (
        <TemplateMenu>
            <Row>
                <Col><h4>Editar Listado</h4></Col>
            </Row>
            <FormCharge list={list} onEdit={onSucess} formEdit={form} />

        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { product_inventory } = props

    return {
        fetching_create_charge: product_inventory.fetching_create_charge,
        fetching_charge: product_inventory.fetching_charge,
        fetching_by_id: product_inventory.fetching_by_id,
        data_charge: product_inventory.data_charge,
        row: product_inventory.row
    }
}

export default connect(mapsState, { getProductsCharge, toogleLoader, getChargeById, onEditProductsCharge })(EditChargePage);