import React from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap';

const ModalUnLockPayroll = ({ item,show, onSuccess,onHide }) => {
    
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                Reversar Liquidación
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>ID</Col>
                    <Col>{item.id}</Col>
                </Row>
                <Row>
                    <Col>Fecha</Col>
                    <Col>{item.init_end}</Col>
                </Row>
                <Row>
                    <Col>Nombre</Col>
                    <Col>{item.worker}</Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Container fluid>
                    <Row>
                        {/* <Col className='d-flex justify-content-center'>
                            <button className='btn btn-danger' onClick={() => onDeleteIngreso(item)}>Eliminar Cierre</button>
                        </Col> */}
                        <Col className='d-flex justify-content-center'>
                            <button className='btn btn-info' onClick={() => onSuccess(item)}>Confirmar</button>
                        </Col>
                    </Row>
                </Container>

            </Modal.Footer>
        </Modal>
    )
}

export default ModalUnLockPayroll;