import React, { useEffect, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TemplateMenu from '../../template/menu.template'
import { getListProduction, onDeleteProduction } from '../../Redux/ProductionDuck'
import { connect } from 'react-redux';
import ItemProductionTable from './item.production.table';
import { toogleLoader } from "../../Redux/alertDuck";

const ProductionPage = ({ getListProduction, fetching_list, data, onDeleteProduction, fetching_create, toogleLoader }) => {

    const [list, setList] = useState([])

    useEffect(() => {
        getListProduction()
    }, [])

    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
            toogleLoader(false)
        }
    }, [fetching_list])

    useEffect(() => {
        if (fetching_create === 2) {
            getListProduction()
        }
    }, [fetching_create])

    const onDeleteItem = (item) => {
        if (window.confirm("¿Realmente deseas eliminar esta Producción?")) {
            toogleLoader(true)
            onDeleteProduction(item)
        }
    }

    return (
        <TemplateMenu>
            <Row className='mt-3'>
                <Col lg={6}>
                    <Link to="/produccion/new" className='btn btn-sm btn-info'>Crear Producción</Link>
                </Col>
                <Col lg={6} className="pr-4 justify-content-end d-flex align-items-center">
                </Col>
            </Row>
            <Row className='my-2'>
                <Col>
                    <Table className='table table-sm table-bordered'>
                        <thead className='thead-dark'>
                            <tr>
                                <th></th>
                                <th>Fecha</th>
                                <th>Nombre</th>
                                <th>Cantidad generar</th>
                                <th>Cantidad Resultado</th>
                                <th>Costo Unitario</th>
                                <th>Costo Total</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>{list.length > 0
                            ? list.map(p => <ItemProductionTable key={p.id} item={p} onDeleteItem={onDeleteItem} />)
                            : <tr><td colSpan={5}>No se encontraron Registros</td></tr>}</tbody>
                    </Table>
                </Col>
            </Row>
        </TemplateMenu >
    )
}

const mapsState = (props) => {
    let { production } = props
    return {
        fetching_list: production.fetching_list,
        fetching_create: production.fetching_create,
        data: production.data,
    }
}

export default connect(mapsState, { getListProduction, onDeleteProduction, toogleLoader })(ProductionPage);