import React from 'react'
import { Card, Col, Row } from "react-bootstrap"
import { formatNumber } from '../../utils'

const CardDataAlternative=({title,available,onClick})=>{
    return <Card className='cursor-pointer' onClick={onClick}>

                    <Card.Body>
                    <Card.Title>{title}</Card.Title>

                    <Row>
                        <Col>DISPONIBLE</Col>
                        <Col className='text-right'> <b>{formatNumber(available)}</b></Col>
                    </Row>
                        
                    </Card.Body>
                </Card>
}

export default CardDataAlternative