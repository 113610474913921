import React, { useEffect, useState } from 'react'
import TemplateMenu from "../../template/menu.template";
import { Col, Row, Table } from 'react-bootstrap';
import { getPrescriptionMaster, onCreatePrescriptionMaster } from "../../Redux/prescriptionDuck"
import { toogleLoader } from "../../Redux/alertDuck"
import { connect } from 'react-redux';
import { formatNumber } from '../../utils';
import ItemMasterPrescription from './item.master.prescription';


const MasterPrescriptionPage = ({ getPrescriptionMaster, fetching_list, data, branchoffices, fetching_list_branch, toogleLoader,
    onCreatePrescriptionMaster, fetching_create }) => {

    const [list, setList] = useState([])
    const [listFilter, setListFilter] = useState([])
    const [isDetail, setIsDetail] = useState(false)
    const [product, setProduct] = useState('')
    const [listBranch, setListBranch] = useState([])
    const [countNew, setCountNew] = useState(0)

    useEffect(() => {
        if (fetching_list === 2) {
            toogleLoader(false)
            setList(data)
            setListFilter(data)
        }
    }, [fetching_list])

    useEffect(() => {
        if (fetching_create === 2) {
            toogleLoader(true)
            getPrescriptionMaster()
        }
    }, [fetching_create])

    useEffect(() => {
        if (fetching_list_branch === 2) {
            setListBranch(branchoffices)
        }
    }, [fetching_list_branch])

    useEffect(() => {
        toogleLoader(true)
        getPrescriptionMaster()
    }, [])

    const onSelectAll = (e, branch) => {
        if (e.target.checked === true) {
            let _list = list.map(d => {
                branch.request_copy = true;
                d.branchs.push(branch)
                return d
            });
            setList(_list)
        } else {
            let _list = list.map(d => {
                let deleted = d.branchs.filter(d => d.id !== branch.id)
                d.branchs = deleted
                d.branch_deleted = deleted
                return d
            });

            setList(_list)
        }

    }

    const onToogleItem = (e, item, branch) => {
        if (e.target.checked === true) {
            let _list = list.map(d => {
                if (item.id === d.id) {
                    branch.request_copy = true;
                    d.branchs.push(branch)
                }
                return d
            })
            setList(_list)
        } else {
            let _list = list.map(d => {
                if (item.id === d.id) {
                    d.branchs = d.branchs.filter(d => d.id !== branch.id)
                    d.branchs_deleted.push(branch)
                }
                return d
            })
            setList(_list)
        }

    }

    const onSubmit = () => {
        toogleLoader(true)
        onCreatePrescriptionMaster(list)
        console.log('list', list);
    }

    return (
        <TemplateMenu>
            <Row className='mt-3'>
                <Col lg={2}>
                    <h3>Recetas</h3>
                </Col>
                <Col>
                    <button className='btn btn-sm btn-info' onClick={onSubmit}>Copiar</button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table className="table table-sm table-bordered table-condensed table-hover" striped>
                        <thead className="thead-dark">
                            <tr>
                                <th></th>
                                <th>Categoria</th>
                                <th>Nombre</th>
                                {listBranch.map(d => <th className="text-center" key={d.id}>{d.title} <input type='checkbox' onChange={(e) => onSelectAll(e, d)} /> (TODOS)</th>)}

                            </tr>
                        </thead>
                        <tbody>
                            {listFilter.length > 0 ? (
                                listFilter.map((p, k) => <ItemMasterPrescription key={k} index={k} item={p} onToogle={onToogleItem} list_branch={listBranch} />)
                            ) : (
                                <tr>
                                    <td colSpan={6}>No se encontraron Registros</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Row>

        </TemplateMenu >
    )
}

const mapsState = (props) => {
    let { prescription, branchoffice } = props
    return {
        fetching_list: prescription.fetching_list,
        data: prescription.data,
        branchoffices: branchoffice.data,
        fetching_list_branch: branchoffice.fetching,
        fetching_create: prescription.fetching_create
    }
}

export default connect(mapsState, { getPrescriptionMaster, toogleLoader, onCreatePrescriptionMaster })(MasterPrescriptionPage);