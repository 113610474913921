import axios from "axios";
import { getHeaders, removeSession, validatePayroll } from "../utils";
import { logOutAction } from "./userDuck";
const API_URL = process.env.REACT_APP_API_URL;

let initialState = {
  fetching_list: 0,
  fetching_create: 0,
  fetching_update: 0,
  fetching_by_id: 0,
  fetching_confirm: 0,
  fetching_files: 0,
  fetching_files_pos: 0,
  fetching_counter: 0,
  fetching_diference: 0,
  fetching_validate: 0,
  fetching_reload: 0,
  fetching_diference_by_product: 0,

  data: [],
  data_counter: [],
  data_diference: [],
  errors: {},
  row: {},
  last_date: ''
};

let LIST_INVENTORY = "LIST_INVENTORY";
let LIST_INVENTORY_SUCCESS = "LIST_INVENTORY_SUCCESS";
let CREATE_INVENTORY = "CREATE_INVENTORY";
let CREATE_INVENTORY_SUCCESS = "CREATE_INVENTORY_SUCCESS";
let CREATE_INVENTORY_INITIAL = "CREATE_INVENTORY_INITIAL";
let CREATE_INVENTORY_INITIAL_SUCCESS = "CREATE_INVENTORY_INITIAL_SUCCESS";
let UPDATE_INVENTORY_COUNTER = "UPDATE_INVENTORY_COUNTER";
let UPDATE_INVENTORY_COUNTER_SUCCESS = "UPDATE_INVENTORY_COUNTER_SUCCESS";
let RESET_STATUS_INVENTORY_SUCCESS = "RESET_STATUS_INVENTORY_SUCCESS";
let RESET_STATUS_INVENTORY = "RESET_STATUS_INVENTORY";
let INVENTORY_BY_ID = "INVENTORY_BY_ID";
let INVENTORY_BY_ID_SUCCESS = "INVENTORY_BY_ID_SUCCESS";
let LAST_INVENTORY = "LAST_INVENTORY";
let LAST_INVENTORY_SUCCESS = "LAST_INVENTORY_SUCCESS";
let CONFIRM_UPLOAD_SALE = "CONFIRM_UPLOAD_SALE";
let CONFIRM_UPLOAD_SALE_SUCCESS = "CONFIRM_UPLOAD_SALE_SUCCESS";
let CONFIRM_UPLOAD_SALE_ERROR = "CONFIRM_UPLOAD_SALE_ERROR";
let GET_FILES = "GET_FILES";
let GET_FILES_SUCCESS = "GET_FILES_SUCCESS";
let END_MONTH = "END_MONTH";
let END_MONTH_SUCCESS = "END_MONTH_SUCCESS";
let LIST_COUNTER = "LIST_COUNTER";
let LIST_COUNTER_SUCCESS = "LIST_COUNTER_SUCCESS";
let GET_FILES_POS = "GET_FILES_POS";
let GET_FILES_POS_SUCCESS = "GET_FILES_POS_SUCCESS";
let LIST_DIFERENCE = "LIST_DIFERENCE";
let LIST_DIFERENCE_SUCCESS = "LIST_DIFERENCE_SUCCESS";
let VALIDATE_INVENTORY = "VALIDATE_INVENTORY";
let VALIDATE_INVENTORY_SUCCESS = "VALIDATE_INVENTORY_SUCCESS";
let DIFFENCE_BY_PRODUCT = "DIFFENCE_BY_PRODUCT";
let DIFFENCE_BY_PRODUCT_SUCCESS = "DIFFENCE_BY_PRODUCT_SUCCESS";
let RELOAD_ITEAM = "RELOAD_ITEAM";
let RELOAD_ITEAM_SUCCESS = "RELOAD_ITEAM_SUCCESS";
let CONFIRM_LOCK = "CONFIRM_LOCK";
let CONFIRM_LOCK_SUCCESS = "CONFIRM_LOCK_SUCCESS";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LIST_INVENTORY:
      return { ...state, fetching_list: 1 };
    case LIST_INVENTORY_SUCCESS:
      return { ...state, fetching_list: 2, data: action.payload, last_date: action.last_date };
    case CREATE_INVENTORY:
      return { ...state, fetching_create: 1 };
    case CREATE_INVENTORY_SUCCESS:
      return { ...state, fetching_create: 2 };
    case CREATE_INVENTORY_INITIAL:
      return { ...state, fetching_create: 1 };
    case CREATE_INVENTORY_INITIAL_SUCCESS:
      return { ...state, fetching_create: 2 };
    case UPDATE_INVENTORY_COUNTER:
      return { ...state, fetching_update: 1 };
    case UPDATE_INVENTORY_COUNTER_SUCCESS:
      return { ...state, fetching_update: 2, row: action.payload };
    case RESET_STATUS_INVENTORY: {
      return { ...state, fetching_list: 1, data: [] };
    }
    case RESET_STATUS_INVENTORY_SUCCESS: {
      return { ...state, fetching_list: 4, data: action.payload };
    }

    case INVENTORY_BY_ID:
      return { ...state, fetching_by_id: 1 };
    case INVENTORY_BY_ID_SUCCESS:
      return { ...state, fetching_by_id: 2, row: action.payload };
    case LAST_INVENTORY:
      return { ...state, fetching_by_id: 1 };
    case LAST_INVENTORY_SUCCESS:
      return { ...state, fetching_by_id: 2, row: action.payload };
    case CONFIRM_UPLOAD_SALE:
      return { ...state, fetching_confirm: 1 };
    case CONFIRM_UPLOAD_SALE_SUCCESS:
      return { ...state, fetching_confirm: 2 };
    case CONFIRM_UPLOAD_SALE_ERROR:
      return { ...state, fetching_confirm: 3, error: action.payload };
    case GET_FILES:
      return { ...state, fetching_files: 1 };
    case GET_FILES_SUCCESS:
      return { ...state, fetching_files: 2, data: action.payload };
    case LIST_COUNTER:
      return { ...state, fetching_counter: 1 };
    case LIST_COUNTER_SUCCESS:
      return { ...state, fetching_counter: 2, data_counter: action.payload };
    case GET_FILES_POS:
      return { ...state, fetching_files_pos: 1 };
    case GET_FILES_POS_SUCCESS:
      return { ...state, fetching_files_pos: 2, data_pos: action.payload };
    case LIST_DIFERENCE:
      return { ...state, fetching_diference: 1 };
    case LIST_DIFERENCE_SUCCESS:
      return { ...state, fetching_diference: 2, data_diference: action.payload, last_date: action.last_date };
    case VALIDATE_INVENTORY:
      return { ...state, fetching_validate: 1 };
    case VALIDATE_INVENTORY_SUCCESS:
      return { ...state, fetching_validate: 2 };
    case DIFFENCE_BY_PRODUCT:
      return { ...state, fetching_diference_by_product: 1 };
    case DIFFENCE_BY_PRODUCT_SUCCESS:
      return { ...state, fetching_diference_by_product: 2, data: action.payload };
    case RELOAD_ITEAM:
      return { ...state, fetching_reload: 1 };
    case RELOAD_ITEAM_SUCCESS:
      return { ...state, fetching_reload: 2 };

    default:
      return state;
  }
}

export let resetStatus = () => (dispatch) => {
  dispatch({
    type: RESET_STATUS_INVENTORY,
  });
  dispatch({
    type: RESET_STATUS_INVENTORY_SUCCESS,
    payload: []
  });
};

export let onValidateInventory = () => (dispatch, getState) => {
  dispatch({
    type: VALIDATE_INVENTORY,
  });

  let branch = JSON.parse(localStorage.getItem("branch"));
  let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));

  let url = `${API_URL}/api/inventory-validate?branch=${branch.id}&date_init=${date_init}&date_end=${date_end}`;

  fetch(url, {
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: VALIDATE_INVENTORY_SUCCESS,
      });
    });
}
  ;
export let getInventory = (form) => (dispatch, getState) => {
  dispatch({
    type: LIST_INVENTORY,
  });
  let url = `${API_URL}/api/inventory`;

  // let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));
  let { date_init, date_end, product_id } = form;
  let branch = JSON.parse(localStorage.getItem("branch"));

  if (form !== undefined) {
    if (parseInt(form.product_id) !== 0) {
      url = `${API_URL}/api/inventory?product_id=${product_id}&date_init=${date_init}&date_end=${date_end}&branch=${branch.id}&move_id=${form.move_id}`;
    }
  }

  axios.get(url, {
    headers: getHeaders(),
  })
    .then((resp) => {
      dispatch({
        type: LIST_INVENTORY_SUCCESS,
        payload: resp.data.results,
      });
    }).catch(err => {
      console.log('erro', err);

    })
};

export let getLastInventory = (product_id, registration_date) => (dispatch, getState) => {
  dispatch({
    type: LAST_INVENTORY,
  });

  let branch = JSON.parse(localStorage.getItem("branch"))

  let url = `${API_URL}/api/inventory-last?product_id=${product_id}&registration_date=${registration_date}&branch=${branch.id}`;

  fetch(url, {
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: LAST_INVENTORY_SUCCESS,
        payload: resp,
      });
    });
}

export let getLastInventoryByCode = (code, registration_date) => (dispatch, getState) => {
  dispatch({
    type: LAST_INVENTORY,
  });

  let branch = JSON.parse(localStorage.getItem("branch"))

  let url = `${API_URL}/api/inventory-last-code?code=${code}&registration_date=${registration_date}&branch=${branch.id}`;

  fetch(url, {
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: LAST_INVENTORY_SUCCESS,
        payload: resp,
      });
    });
};

export let getInventoryCounter = (form) => (dispatch, getState) => {
  dispatch({
    type: LIST_COUNTER,
  });

  let branch = JSON.parse(localStorage.getItem("branch"))

  let url = `${API_URL}/api/inventory-counter?registration_date=${form.registration_date}&product_id=${form.product_id}&daily=${form.daily}&week=${form.week}&month=${form.month}&branch_id=${branch.id}&search=${form.search}`;

  fetch(url, {
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {

      dispatch({
        type: LIST_COUNTER_SUCCESS,
        payload: resp.results,
        last_date: resp.last_date,
      });
    });
}

export let getInventoryCounterDiffence = (form) => (dispatch, getState) => {
  dispatch({
    type: LIST_DIFERENCE,
  });

  let url = `${API_URL}/api/inventory-counter-difference?date_init=${form.date_init}&date_end=${form.date_end}&daily=${form.daily}&week=${form.week}&month=${form.month}&search=${form.search}`;
  if (form.filter !== undefined) {
    url += "&filter=true"
  }

  let branch = JSON.parse(localStorage.getItem("branch"))

  url += `&branch_id=${branch.id}`

  axios.get(url, {
    headers: getHeaders(),
  }).then((resp) => {
    dispatch({
      type: LIST_DIFERENCE_SUCCESS,
      payload: resp.data.results,
      last_date: resp.data.last_date,

    });
  }).catch(err => {
    let { status } = err.response;

    if (status === 401) {
      logOutAction()(dispatch, getState)
    }

  });
};

export let getSalesFiles = (form) => (dispatch, getState) => {
  dispatch({
    type: GET_FILES_POS,
  });

  let branch = JSON.parse(localStorage.branch);
  let { date_init, date_end } = form;

  let url = `${API_URL}/api/pos-files?branch=${branch.id}&date_init=${date_init}&date_end=${date_end}`;

  fetch(url, {
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      console.log('resp', resp);
      if (resp.ok !== false) {
        dispatch({
          type: GET_FILES_POS_SUCCESS,
          payload: resp,
        });
      } else {

      }

    });
};

export let getSalesFilesById = (id, date_init, date_end) => (dispatch, getState) => {
  dispatch({
    type: GET_FILES,
  });

  let branch = JSON.parse(localStorage.branch);

  let url = `${API_URL}/api/pos/${branch.id}?date_init=${date_init}&date_end=${date_end}`;

  fetch(url, {
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: GET_FILES_SUCCESS,
        payload: resp,
      });
    });
};

export let updateInventoryCounter = (form) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_INVENTORY_COUNTER,
  });

  let url = `${API_URL}/api/inventory-counter/${form.id}`;

  let branch = JSON.parse(localStorage.branch);

  form.branch_id = branch.id;

  fetch(url, {
    method: "PUT",
    body: JSON.stringify(form),
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: UPDATE_INVENTORY_COUNTER_SUCCESS,
        payload: resp
      });
    });
};

export let getInventoryLowDown = (product_filter_id) => (dispatch, getState) => {
  dispatch({
    type: LIST_INVENTORY,
  });

  product_filter_id = product_filter_id || 0

  let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))
  let branch = JSON.parse(localStorage.getItem("branch"))

  let url = `${API_URL}/api/inventory-counter-lowdown?date_init=${date_init}&date_end=${date_end}&branch_id=${branch.id}&product_filter_id=${product_filter_id}`;

  fetch(url, {
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: LIST_INVENTORY_SUCCESS,
        payload: resp.results,
      });
    });
};
export let getInventoryLowDownByProduct = (form) => (dispatch, getState) => {
  dispatch({
    type: INVENTORY_BY_ID,
  });

  let branch = JSON.parse(localStorage.getItem("branch"));

  let url = `${API_URL}/api/inventory-counter-lowdown/${form.product_id}?registration_date=${form.registration_date}&branch_id=${branch.id}`;

  fetch(url, {
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: INVENTORY_BY_ID_SUCCESS,
        payload: resp,
      });
    });
};

export let onCreateLowDown = (form) => (dispatch, getState) => {
  dispatch({
    type: CREATE_INVENTORY,
  });
  let url = `${API_URL}/api/inventory-counter-lowdown`;

  let branch = JSON.parse(localStorage.branch);
  let { date_init, date_end } = JSON.parse(localStorage.date);

  form.branch_id = branch.id;
  form.date_init = date_init;
  form.date_end = date_end;

  fetch(url, {
    method: "POST",
    body: JSON.stringify(form),
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: CREATE_INVENTORY_SUCCESS,
      });
    });
};
export let onEditLowDown = (form) => (dispatch, getState) => {
  dispatch({
    type: CREATE_INVENTORY,
  });
  let url = `${API_URL}/api/inventory-counter-lowdown/${form.id}`;

  let branch = JSON.parse(localStorage.branch);

  let { date_init, date_end } = JSON.parse(localStorage.date);

  form.branch_id = branch.id;
  form.date_init = date_init;
  form.date_end = date_end;

  fetch(url, {
    method: "PUT",
    body: JSON.stringify(form),
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: CREATE_INVENTORY_SUCCESS,
      });
    });
};

export let onDeleteLowDown = (id) => (dispatch, getState) => {
  dispatch({
    type: CREATE_INVENTORY,
  });

  let { date_init, date_end } = JSON.parse(localStorage.date);

  let url = `${API_URL}/api/inventory-counter-lowdown/${id}?date_init=${date_init}&date_end=${date_end}`;

  fetch(url, {
    method: "DELETE",
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: CREATE_INVENTORY_SUCCESS,
      });
    });
};

export let onCreateInventory = (form) => (dispatch, getState) => {
  dispatch({
    type: CREATE_INVENTORY,
  });
  let url = `${API_URL}/api/inventory`;

  let branch = JSON.parse(localStorage.branch);

  form.branch_id = branch.id;

  fetch(url, {
    method: "POST",
    body: JSON.stringify(form),
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: CREATE_INVENTORY_SUCCESS,
      });
    });
};

export let onCreateInventoryInitial = (form) => (dispatch, getState) => {
  dispatch({
    type: CREATE_INVENTORY,
  });
  let url = `${API_URL}/api/inventory-initial`;

  let branch = JSON.parse(localStorage.branch);

  form.branch_id = branch.id;

  fetch(url, {
    method: "POST",
    body: JSON.stringify(form),
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: CREATE_INVENTORY_SUCCESS,
      });
    });
};

export let onConfirmUploadSales = (data) => (dispatch, getState) => {
  dispatch({
    type: CONFIRM_UPLOAD_SALE,
  });
  let url = `${API_URL}/api/pos-confirm`;

  let form = data;

  let range = JSON.parse(localStorage.getItem("date"));

  form.date_init = range.date_init;
  form.date_end = range.date_end;

  fetch(url, {
    method: "POST",
    body: JSON.stringify(form),
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      console.log("resp.ok", resp.ok);
      if (resp.ok === true) {
        dispatch({
          type: CONFIRM_UPLOAD_SALE_SUCCESS,
        });
      } else {
        dispatch({
          type: CONFIRM_UPLOAD_SALE_ERROR,
          payload: resp.msg,
        });
      }
    });
};

export let getReportEndMonth = (form) => (dispatch, getState) => {
  dispatch({
    type: END_MONTH,
  });

  let branch = JSON.parse(localStorage.getItem("branch"));
  let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));

  let url = `${API_URL}/api/inventory-end-month/${branch.id}?date_init=${date_init}&date_end=${date_end}`;

  fetch(url, {
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: END_MONTH_SUCCESS,
        payload: resp,
      });
    });
}

export let getDiffenceDetailByProduct = (form) => (dispatch, getState) => {
  dispatch({
    type: DIFFENCE_BY_PRODUCT,
  });

  let branch = JSON.parse(localStorage.getItem("branch"));

  let url = `${API_URL}/api/inventory-counter-difference-detail/${form.id}?branch_id=${branch.id}&date_init=${form.date_init}&date_end=${form.date_end}`;

  fetch(url, {
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: DIFFENCE_BY_PRODUCT_SUCCESS,
        payload: resp,
      });
    });
}
export let onReloadItemKardex = (form) => (dispatch, getState) => {
  dispatch({
    type: RELOAD_ITEAM,
  });


  let branch = JSON.parse(localStorage.branch);

  let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));

  let url = `${API_URL}/api/inventory-recalculate/${form.id}?date_init=${date_init}&date_end=${date_end}`;

  form.branch_id = branch.id;

  fetch(url, {
    method: "PUT",
    body: JSON.stringify(form),
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: RELOAD_ITEAM_SUCCESS,
      });
    });
}