import React from 'react'
import { Col, Modal, Row, Table } from 'react-bootstrap';
import { formatNumber } from '../../utils';

const ModalDetailStock = ({ show, onHide, listProducts }) => {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Detalle de Productos Negativos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Table className='table-sm table-hover table-bordered'>
                            <thead className='thead-dark'>
                                <tr>
                                    <th>PRODUCTO</th>
                                    <th>CANTIDAD</th>
                                    <th>COSTO</th>
                                    <th>COSTO TOTAL</th>
                                </tr>

                            </thead>
                            <tbody>
                                {listProducts.map((d, k) => <tr key={k}>
                                    <td>{d.product}</td>
                                    <td align='right'>{d.amount_total}</td>
                                    <td align='right'>{formatNumber(d.cost_average)}</td>
                                    <td align='right'>{formatNumber(d.cost_total)}</td>
                                </tr>)}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>TOTAL</th>
                                    <th className='text-right'>{listProducts.reduce((a, b) => a + parseFloat(b.amount_total), 0)}</th>
                                    <th></th>
                                    <th className='text-right'>{formatNumber(listProducts.reduce((a, b) => a + parseFloat(b.cost_total), 0))}</th>
                                </tr>
                            </tfoot>
                        </Table>
                    </Col>
                </Row>

            </Modal.Body>
        </Modal>
    )
}

export default ModalDetailStock;