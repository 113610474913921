import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import TemplateMenu from '../../template/menu.template'
import { getSubPrescriptionById, onUpdateSubPrescription } from '../../Redux/subprescriptionDuck'
import FormSubprescription from './form.subprescription'

const EditSubPrescription = ({ getSubPrescriptionById, row, fetching_by_id, onUpdateSubPrescription, fetching_create }) => {

    let { id } = useParams();
    const history = useHistory();
    const [form, setForm] = useState({})


    useEffect(() => {
        getSubPrescriptionById(id)
    }, [id])



    useEffect(() => {
        if (fetching_by_id === 2) {
            setForm(row)
        }
    }, [fetching_by_id])

    useEffect(() => {
        if (fetching_create === 2) {
            history.goBack();
        }
    }, [fetching_create])

    const onSuccess = (form) => {
        onUpdateSubPrescription(form)
    }

    return (
        <TemplateMenu >
            <FormSubprescription title={"Editar"} formEdit={form} onSuccess={onSuccess} />
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { subprescription } = props

    return {
        row: subprescription.row,
        fetching_by_id: subprescription.fetching_by_id,
        fetching_create: subprescription.fetching_create
    }
}

export default connect(mapsState, { getSubPrescriptionById, onUpdateSubPrescription })(EditSubPrescription);