import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getPayrollGroup } from '../../Redux/payrollDuck'
import { formatNumber } from '../../utils';
import { AiFillEye, AiFillLock } from 'react-icons/ai';


const ListPayments = ({ getPayrollGroup, data_group, fetching_group }) => {

    const [list, setList] = useState([])

    useEffect(() => {
        if (fetching_group === 2) {
            setList(data_group)
        }
    }, [fetching_group])

    useEffect(() => {
        getPayrollGroup()
    }, [])

    return (
        <Container fluid>
            <Row>
                <Col>
                    <Table className='table-sm table-bordered'>
                        <thead className='thead-dark'>
                            <tr>
                                <th>Fecha</th>
                                <th>Periodo Liquidado</th>
                                <th>Total</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(list.length > 0) ? list.map((d, k) => <tr key={k}>
                                <td>{moment(d.createdAt).format("YYYY-MM-DD")}</td>
                                <td>{d.range}</td>
                                <td align='right'>{formatNumber(d.total)}</td>
                                <td>
                                    <a href={`/nomina-group/${d.id}`}><AiFillEye className='cursor-pointer' /></a>
                                </td>
                            </tr>) : <tr><td colSpan={4}>No tienes liquidaciones</td></tr>}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    )
}

const mapsState = (props) => {

    let { payroll } = props

    return {
        fetching_group: payroll.fetching_group,
        data_group: payroll.data_group,
    }
}

export default connect(mapsState, { getPayrollGroup })(ListPayments);