import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import TemplateMenu from "../../template/menu.template";
import { getRecipeBooks, deleteRecipeBook, deleteRecipeBookCategory } from "../../Redux/recipe.book.Duck";
import { connect } from "react-redux"
import ItemCategoryPrescription from "./item.category.prescription";
import ModalAddCategory from "./modal.add.category";
import { getCategoryRecipe } from "../../Redux/recipe.book.Duck";
import { toogleLoader } from "../../Redux/alertDuck";
import toast from 'react-hot-toast';
const PrescriptionKitckenPage = ({ getRecipeBooks, fetching_list, data, deleteRecipeBook, fetching_create,
  getCategoryRecipe, toogleLoader, deleteRecipeBookCategory }) => {
  const [list, setList] = useState([]);
  const [listFilter, setListFilter] = useState([]);
  const [filter, setFilter] = useState("");

  const [show, setShow] = useState(false)

  useEffect(() => {
    if (fetching_list === 2) {
      toogleLoader(false)
      setList(data);
      setListFilter(data);
    }
  }, [fetching_list]);

  useEffect(() => {
    if (fetching_create === 2) {
      toogleLoader(true)
      getRecipeBooks();
    }
  }, [fetching_create]);

  useEffect(() => {
    toogleLoader(true)
    getRecipeBooks();


  }, []);

  const onFilter = (e) => {
    setFilter(e.target.value);
    let _list = list;

    let _filter = [];

    list.filter((row) => {
      let { recipe } = row;

      recipe.map((rec) => {
        if (rec.title.toLowerCase().includes(e.target.value.toLowerCase())) {
          _filter.push(rec);
        }
      });
    });

    setListFilter(_filter);
  };

  const onDeleteRecipeProduct = (item) => {

    if (window.confirm(`¿Realmente deseas Eliminar ${item.title}?`)) {
      deleteRecipeBook(item.id)
    }
  }

  const AddCategory = () => {
    setShow(true)
  }

  const onCreateCategoryPrescriptionKitchen = () => {
    setShow(false)
    toast.success('Categoria Creada!')
    getRecipeBooks();
  }

  const onDeleteCategory = (item) => {
    if (window.confirm(`¿Realmente deseas Eliminar ${item.name}?`)) {
      deleteRecipeBookCategory(item.id)
    }
  }

  return (
    <TemplateMenu>

      <Row className="mt-4">
        <Col>
          <button className="btn btn-sm btn-success" onClick={AddCategory}>
            Crear Categoria
          </button>
        </Col>
        <Col lg={3}>
          <input className="form-control" placeholder="Buscar" value={filter} onChange={onFilter} />
        </Col>
      </Row>
      {list.length !== 0
        ? list.map((d, k) => <ItemCategoryPrescription key={k} item={d} onDeleteRecipeProduct={onDeleteRecipeProduct} onDeleteCategory={onDeleteCategory} />)
        :
        <Row>
          <Col className="text-center">No tienes Recetas Adicionales</Col>
        </Row>}
      <ModalAddCategory show={show} onSuccess={onCreateCategoryPrescriptionKitchen} onHide={() => setShow(true)} />
    </TemplateMenu>
  );
};

const mapsState = (props) => {
  let { recipe_book } = props;
  return {
    fetching_list: recipe_book.fetching_list,
    fetching_create: recipe_book.fetching_create,
    data: recipe_book.data,
  };
};

export default connect(mapsState, {
  getRecipeBooks, deleteRecipeBook, getCategoryRecipe,
  toogleLoader, deleteRecipeBookCategory
})(PrescriptionKitckenPage);
