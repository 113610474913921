import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PaginationTable from '../Components/PaginationTable';
import SearchSection from '../Components/SearchSection';
import TemplateMenu from '../../template/menu.template'


const KardexPage = () => {

    const [list, setList] = useState([])

    const filterContent = () => {

    }
    const onCleanFilter = () => {

    }

    return (<TemplateMenu>
        <Container fluid>
            <Row className='mt-3'>
                <Col lg={6}>
                    <Link to="/transformaciones/new" className='btn btn-info'>Kardex</Link>

                </Col>


                <Col lg={6} className="pr-4 justify-content-end d-flex align-items-center">
                    <SearchSection
                        placeholder='Buscar Producto'
                        value=''
                        name='buscar_usuario'
                        onClick={filterContent}
                        onClean={onCleanFilter}
                    >

                    </SearchSection>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col>
                    <table className="table table-sm table-bordered">
                        <thead className='thead-dark'>
                            <tr>
                                <th>Nombre</th>
                                <th>Periodo</th>
                                <th>Unidad</th>
                                <th>Costo</th>
                                <th>Stock</th>
                                <th>Grupo</th>
                                <th>Subgrupo</th>
                                <th>Estado</th>
                                <th>Modificar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(list.length > 0) ? list.map(row => <tr></tr>) : <tr><td colSpan={6}>No se encontraron registros</td></tr>}
                        </tbody>


                    </table>

                    {/* <PaginationTable /> */}
                </Col>
            </Row>
        </Container>
    </TemplateMenu>
    )
}

export default KardexPage;