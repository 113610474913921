import moment from 'moment';
import React from 'react'
import { Link } from 'react-router-dom';
import { formatNumber } from '../../utils';
import { Table } from 'react-bootstrap';

const ItemSupplier = ({ row, onDelete, isDetail, isLock }) => {
    let { products } = row;

    return (
        <>
            <tr className={`${isDetail ? 'table-primary' : ''}`}>
                <td>{row.id}</td>
                <td>{moment(row.registration_date).tz("America/Bogota").format("YYYY-MM-DD")}</td>
                <td>{row.supplier}</td>
                <td align='right'>{formatNumber(row.amount)}</td>
                <td align='right'>{formatNumber(row.total)}</td>
                <td>
                    <Link className='btn btn-sm btn-info' to={`/compras/${row.id}`}>Ver</Link>
                    {(!isDetail && !isLock) && <button className='btn btn-danger btn-sm mx-3' onClick={() => onDelete(row)}>Borrar</button>}

                </td>
            </tr>
            {isDetail && <tr>
                <td colSpan={4}>
                    <Table className='table table-sm table-bordered'>
                        <thead className='thead-dark'>
                            <tr>
                                <th>Producto</th>
                                <th>Cantidad</th>
                                <th>Costo</th>
                                <th>Subtotal</th>
                            </tr>
                        </thead>
                        <tbody>
                            {products !== undefined && products.map((d, k) => <tr key={k}>
                                <td>{d.product}</td>
                                <td align='right'>{formatNumber(d.amount)}</td>
                                <td align='right'>{formatNumber(d.cost)}</td>
                                <td align='right'>{formatNumber(d.subtotal)}</td>
                            </tr>)}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td><b>TOTAL</b></td>
                                <td align='right'><b>{formatNumber(products !== undefined && products.reduce((a, b) => a + parseFloat(b.amount), 0))}</b></td>
                                <td></td>
                                <td align='right'><b>{formatNumber(products !== undefined && products.reduce((a, b) => a + parseFloat(b.subtotal), 0))}</b></td>
                            </tr>
                        </tfoot>
                    </Table>
                </td>
            </tr>}
        </>
    )
}

export default ItemSupplier;