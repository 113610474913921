import React, { useEffect, useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { getDiningId } from '../../Redux/diningDuck'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { Col, Container, Row } from 'react-bootstrap'
import ModalDining from './ModalDining'
import { BsFillPersonCheckFill } from 'react-icons/bs'
import { MdPets } from "react-icons/md";
import RowDiningTable from './row.dining.table';


const AddDiningTablePage = ({ getDiningId, fetching_detail, detail }) => {

    let { id } = useParams()
    const [showModal, setShowModal] = useState(false);
    const [selected, setSelected] = useState({});
    const [content, setContent] = useState([]);

    const [list, setList] = useState({ description: '' })

    const [dining, setDining] = useState([])


    useEffect(() => {
        getDiningId(id)
    }, [id])

    useEffect(() => {
        if (fetching_detail === 2) {
            setList(detail)
            setDining(detail.dinings)
        }
    }, [fetching_detail])


    const onClick = (dining) => {
        setShowModal(true)
        setSelected(dining)

    }

    const onSuccess = (form) => {

        setShowModal(false)
        getDiningId(id)
    }

    return (
        <TemplateMenu>
            <Row className='mt-4'>
                <Col className='text-center'><h2>{detail.description}</h2></Col>
            </Row>
            <Row>
                <Col>
                    <div className='container-dining'>
                        {dining.map((m, k) => <RowDiningTable item={m} key={k} onSelectDining={onClick} />)}

                    </div>
                </Col>
            </Row>
            <ModalDining showModal={showModal} onHide={() => setShowModal(false)} onSuccess={onSuccess} current={selected} id={id} />

        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { dining } = props
    return {
        fetching_detail: dining.fetching_detail,
        detail: dining.detail,
    }
}

export default connect(mapsState, { getDiningId })(AddDiningTablePage);