import React, { useEffect, useState } from "react";
import { Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { connect } from "react-redux";
import InputField from "../Components/Widgets/InputField";
import { getParameterBySegment, createParameter, onUpdateParameter } from "../../Redux/parameterDuck";
import {
  onCreateProductInventory,
  onEditProductInventory,
  restartCreate,
  getProductInventoryByName
} from "../../Redux/ProductInventoryDuck";
import ModalAddParameter from "../Components/ModalAddParameter";
import { getSuppliers } from "../../Redux/supplierDuck";
import { toogleLoader } from "../../Redux/alertDuck";
import ModalProductExists from "./modal.product.exists";

const initState = {
  name: "",
  code: "",
  bar_code: "",
  measure_unit_id: 0,
  tax_id: 0,
  family_inventory_id: 0,
  type_inventory_id: 0,
  group_inventory_id: 0,
  control_inventory_id: 0,
  supplier_id: 0,
  status: true,
  charge: ''
};

const FormProductRecipe = ({ errors, onCreateProductInventory, getParameterBySegment, fetching_by_segment, data_segment, segment,
  createParameter, fetching_create, getSuppliers, fetching_list, suppliers, fetching_create_inventory,
  onSearch, formEdit, onEditProductInventory, onReload, data_parameter, onUpdateParameter, toogleLoader, restartCreate,
  onOpenAssoc, showAssoc, listAssoc, onEditDeleteProductInventory, getProductInventoryByName, fetching_validate, row
}) => {
  const [meausureUnits, setMeasureUnits] = useState([]);
  const [listTax, setListTax] = useState([]);
  const [listControl, setListControl] = useState([]);
  const [listFamily, setListFamily] = useState([]);
  const [listType, setListType] = useState([]);
  const [listGroup, setListGroup] = useState([]);
  const [listSupplier, setListSupplier] = useState([]);

  const [listParameter, setListParameter] = useState([]);

  const [modalParam, setModalParam] = useState({ open: false, title: "" });

  const [form, setForm] = useState(initState);
  const [modal, setModal] = useState(false);
  const [productExists, setProductExists] = useState({
    measure_unit: {}, family_inventory: {},
    group_inventory: {}, type_inventory: {}
  });

  useEffect(() => {
    getParameterBySegment("measure_unit");
    getSuppliers("?status=1");
  }, []);

  useEffect(() => {

    if (fetching_create_inventory === 2) {
      onReload();
    }
    if (fetching_create_inventory === 3) {
      restartCreate()
      toogleLoader(false)
    }
  }, [fetching_create_inventory]);

  useEffect(() => {
    if (fetching_validate === 2) {
      if (row !== null) {
        setProductExists(row)
        setModal(true)
      } else {
        setModal(false)
      }
    }
  }, [fetching_validate])

  useEffect(() => {
    if (fetching_create === 2) {
      getParameterBySegment(data_parameter.segment);
      toogleLoader(false)
      setModalParam({
        open: false,
      });
    }
  }, [fetching_create]);

  useEffect(() => {
    if (Object.keys(formEdit).length > 0) {
      setForm(formEdit);
    } else {
      setForm(initState);
    }
  }, [formEdit]);

  useEffect(() => {
    if (fetching_by_segment === 2) {
      if (segment === "measure_unit") {
        setMeasureUnits(data_segment);
        getParameterBySegment("tax");
      }
      if (segment === "tax") {
        setListTax(data_segment);
        getParameterBySegment("control_inventory");
      }
      if (segment === "control_inventory") {
        setListControl(data_segment);
        getParameterBySegment("family_inventory");
      }
      if (segment === "family_inventory") {
        setListFamily(data_segment);
        getParameterBySegment("type_inventory");
      }

      if (segment === "type_inventory") {
        setListType(data_segment);
        getParameterBySegment("group_inventory");
      }
      if (segment === "group_inventory") {
        setListGroup(data_segment);
      }
    }
  }, [fetching_by_segment]);

  useEffect(() => {
    if (fetching_list === 2) {
      setListSupplier(suppliers);
    }
  }, [fetching_list]);

  const updateInput = (e) => {
    let _form = {
      ...form,
      [e.target.name]: e.target.value,
    };

    setForm(_form);
  };

  const updateCheckbox = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.checked,
    });
  };

  const openModalParameter = (title, segment) => {
    setModalParam({
      ...modalParam,
      open: true,
      title: title,
      dataType: segment,
    });

    if (segment === "group_inventory") {
      setListParameter(listGroup);
    } else if (segment === "measure_unit") {
      setListParameter(meausureUnits);
    } else if (segment === "tax") {
      setListParameter(listTax);
    } else if (segment === "family_inventory") {
      setListParameter(listFamily);
    }
  };

  const onSuccessParameter = (form) => {
    form.segment = form.dataType;
    form.priority = 1;
    createParameter(form);
  };

  const onUpdateParameterForm = (item) => {
    onUpdateParameter(item);
  }

  const onClickNew = () => {
    setForm(initState);
    onReload();
  }

  const onEditProductInventoryPre = (item) => {
    let { subprescription } = item;
    toogleLoader(true)
    item.listAssoc = listAssoc
    onEditProductInventory(item);
  };

  const onCreateProductInventoryPre = () => {
    toogleLoader(true)
    form.listAssoc = listAssoc
    onCreateProductInventory(form)
  }

  const onClickDanger = () => {

  }

  const onValidateName = (e) => {
    if (e.target.value !== '') {
      getProductInventoryByName(e.target.name, e.target.value);
    }
  }

  return (
    <Form>
      <Row className="d-flex justify-content-center">
        <Col>
          <Card className={`${Object.keys(formEdit).length > 0 ? 'border-success' : ''}`}>
            <Card.Header className={`${Object.keys(formEdit).length > 0 ? 'border-success' : ''}`}>
              <Row>
                <Col>Productos Inventario</Col>
                <Col className="d-flex justify-content-end">
                  {
                    form.id === undefined ? <button type="button" className="btn btn-sm btn-secondary mx-2" onClick={onClickNew}>
                      Limpiar
                    </button> :
                      <button type="button" className="btn btn-sm btn-danger mx-2" onClick={onClickNew}>
                        Cancelar
                      </button>
                  }

                  {form.id === undefined ? (
                    <button type="button" className="btn btn-success btn-sm" onClick={() => onCreateProductInventoryPre(form)}>
                      Guardar
                    </button>
                  ) : (
                    <>
                      <button type="button" className="btn btn-info btn-sm" onClick={() => onEditProductInventoryPre(form)}>
                        Guardar
                      </button>
                    </>
                  )}
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Row className="mt-2">
                <Col>
                  <div className="form-group">
                    <label>Nombre</label>
                    <InputField
                      placeholder="Nombre"
                      value={form.name || ""}
                      name="name"
                      onChange={updateInput}
                      error={errors.name}
                      onBlur={onValidateName}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="form-group">
                    <label>Código</label>
                    <InputField
                      type={"number"}
                      placeholder="Código"
                      value={form.code || ""}
                      name="code"
                      onChange={updateInput}
                      error={errors.code}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="form-group">
                    <label>Código de Barras</label>
                    <InputField
                      placeholder="Código de Barras"
                      value={form.bar_code || ""}
                      name="bar_code"
                      onChange={updateInput}
                      error={errors.bar_code}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="form-group">
                    <label>
                      Unidad Medida{" "}
                      <AiOutlinePlusCircle
                        className="cursor-pointer"
                        size={20}
                        onClick={() =>
                          openModalParameter(
                            "Agregar Unidad de medida",
                            "measure_unit"
                          )
                        }
                      />
                    </label>
                    <select className="form-control" name="measure_unit_id" value={form.measure_unit_id} onChange={updateInput}>
                      <option value="0">Seleccione</option>
                      {meausureUnits.map((m) => (
                        <option key={m.id} value={m.id}>
                          {m.description}
                        </option>
                      ))}
                    </select>
                    {errors.measure_unit_id !== undefined && (
                      <label className="error-label">
                        {errors.measure_unit_id}
                      </label>
                    )}
                  </div>
                </Col>
                <Col>
                  <div className="form-group">
                    <label>
                      Impuesto{" "}
                      <AiOutlinePlusCircle
                        className="cursor-pointer"
                        size={20}
                        onClick={() =>
                          openModalParameter("Agregar Impuesto", "tax")
                        }
                      />
                    </label>
                    <select className="form-control" name="tax_id" value={form.tax_id} onChange={updateInput}>
                      <option value="0">Seleccione</option>
                      {listTax.map((m) => (
                        <option key={m.id} value={m.id}>
                          {m.description}
                        </option>
                      ))}
                    </select>
                    {errors.tax !== undefined && (
                      <label className="error-label">{errors.tax}</label>
                    )}
                  </div>
                </Col>

                <Col lg={4}>
                  <div className="form-group">
                    <label>
                      Familia{" "}
                      <AiOutlinePlusCircle
                        className="cursor-pointer"
                        size={20}
                        onClick={() =>
                          openModalParameter(
                            "Agregar Familia",
                            "family_inventory"
                          )
                        }
                      />
                    </label>
                    <select className="form-control" name="family_inventory_id" value={form.family_inventory_id}
                      onChange={updateInput}>
                      <option value="0">Seleccione</option>
                      {listFamily.map((m) => (
                        <option key={m.id} value={m.id}>
                          {m.description}
                        </option>
                      ))}
                    </select>
                    {errors.family_inventory_id !== undefined && (
                      <label className="error-label">
                        {errors.family_inventory_id}
                      </label>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="form-group">
                    <label>
                      Grupo{" "}
                      <AiOutlinePlusCircle
                        className="cursor-pointer"
                        size={20}
                        onClick={() =>
                          openModalParameter("Agregar Grupo", "group_inventory")
                        }
                      />
                    </label>
                    <select className="form-control" name="group_inventory_id" value={form.group_inventory_id}
                      onChange={updateInput}>
                      <option value="0">Seleccione</option>
                      {listGroup.map((m) => (
                        <option key={m.id} value={m.id}>
                          {m.description}
                        </option>
                      ))}
                    </select>
                    {errors.group_inventory_id !== undefined && (
                      <label className="error-label">
                        {errors.group_inventory_id}
                      </label>
                    )}
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="form-group">
                    <label>Tipo de Control</label>
                    <select className="form-control" name="control_inventory_id"
                      value={form.control_inventory_id} onChange={updateInput}>
                      <option value="0">Seleccione</option>
                      {listControl.map((m) => (
                        <option key={m.id} value={m.id}>
                          {m.description}
                        </option>
                      ))}
                    </select>
                    {errors.control_inventory_id !== undefined && (
                      <label className="error-label">
                        {errors.control_inventory_id}
                      </label>
                    )}
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="form-group">
                    <label>Proveedor</label>
                    <select className="form-control" name="supplier_id" value={form.supplier_id} onChange={updateInput}>
                      <option value={0}>Seleccione</option>
                      {listSupplier.map((m) => (
                        <option key={m.id} value={m.id}>
                          {m.business}
                        </option>
                      ))}
                    </select>
                    {errors.supplier_id !== undefined && (
                      <label className="error-label">
                        {errors.supplier_id}
                      </label>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <div className="form-group">
                    <label>Tipo de Inventario</label>
                    <select
                      className="form-control"
                      name="type_inventory_id"
                      value={form.type_inventory_id}
                      onChange={updateInput}>
                      <option value="0">Seleccione</option>
                      {listType.map((m) => (
                        <option key={m.id} value={m.id}>
                          {m.description}
                        </option>
                      ))}
                    </select>
                    {errors.type_inventory_id !== undefined && (
                      <label className="error-label">
                        {errors.type_inventory_id}
                      </label>
                    )}
                  </div>
                </Col>

                {/* <Col lg={4}>
                  <div className="form-group">
                    <label>Cargo</label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="number"
                        onChange={updateInput}
                        value={form.charge}
                        name="charge"
                        placeholder="Cargo"
                        aria-describedby="basic-addon2"
                      />
                      <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                    </InputGroup>
                    {errors.charge !== undefined && (
                      <label className="error-label">{errors.charge}</label>
                    )}
                  </div>

                </Col> */}
                <Col lg={2}>
                  <div className="form-group">
                    <label>¿Activo?</label>
                    <input
                      className="form-control"
                      name="status"
                      type={"checkbox"}
                      value={form.status}
                      checked={form.status}
                      onChange={updateCheckbox}
                    />
                    {errors.status !== undefined && (
                      <label className="error-label">{errors.status}</label>
                    )}
                  </div>
                </Col>
                <Col lg={2}>
                  <div className="form-group">
                    <label>Orden</label>
                    <InputField
                      placeholder="Orden"
                      value={form.priority || ""}
                      name="priority"
                      onChange={updateInput}
                      error={errors.priority}
                    />
                  </div>
                </Col>
                {!showAssoc && <Col className="d-flex align-items-center justify-content-center">
                  <button className="btn btn-sm btn-info" type="button" onClick={onOpenAssoc}>Asociar Productos</button>
                </Col>}

              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <ModalAddParameter
        show={modalParam.open}
        title={modalParam.title}
        dataType={modalParam.dataType}
        listParameter={listParameter}
        onHide={() => setModalParam({ ...modalParam, open: false })}
        onSuccess={onSuccessParameter}
        onUpdate={onUpdateParameterForm}
      />
      <ModalProductExists show={modal} product={productExists} onHide={() => setModal(false)} />
    </Form>
  );
};

const mapsState = (props) => {
  let { product_inventory, parameter, supplier } = props;

  return {
    errors: product_inventory.errors,
    fetching_create_inventory: product_inventory.fetching_create,
    fetching_validate: product_inventory.fetching_validate,
    row: product_inventory.row,
    segment: parameter.parameter,
    fetching_by_segment: parameter.fetching_by_segment,
    data_segment: parameter.data_segment,
    segment: parameter.segment,
    fetching_create: parameter.fetching_create,
    data_parameter: parameter.data,
    fetching_list: supplier.fetching_list,
    suppliers: supplier.data,
  };
};

export default connect(mapsState, {
  getSuppliers, getParameterBySegment, createParameter, onCreateProductInventory, onEditProductInventory,
  onUpdateParameter, toogleLoader, restartCreate,
  getProductInventoryByName
})(FormProductRecipe);
