import React, { useState, useEffect, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import InputForm from '../Components/Widgets/InputForm'
import ButtonNaranja from '../Components/Widgets/ButtonNaranja'

import { getUserActive, onRegisterTime, onRegisterTimeRest } from '../../Redux/userDuck'

import moment from 'moment'
import { connect } from 'react-redux';
import RowTableWorker from './row.table.worker';

import TemplateMenu from '../../template/menu.template'

import Webcam from 'react-webcam';
import ModalPhoto from './modal.photo';


const initialState = { document: '', code: '', image: '' }

const WorkerTimerPage = ({ errors, getUserActive, lists, onRegisterTime, fetching, fetching_working, data_working, onRegisterTimeRest }) => {

    const [hour, setHour] = useState('')
    const [form, setForm] = useState(initialState)
    const [data, setData] = useState([])
    const [reload, setReload] = useState(null)
    const [modal, setModal] = useState(false)
    const [image, setImage] = useState("")
    const webRef = useRef()

    useEffect(() => {
        getUserActive()
    }, [])

    useEffect(() => {
        if (fetching === 2) {
            getUserActive()
        }
    }, [fetching])

    useEffect(() => {
        if (fetching_working === 2) {
            setData(data_working)
            setForm(initialState)
        }
    }, [fetching_working])

    useEffect(() => {
        setData(lists)

    }, [lists])

    useEffect(() => {
        setHour(moment().format('MMMM Do YYYY, h:mm:ss a'))

        setReload(setInterval(() => {
            setHour(moment().format('MMMM Do YYYY, h:mm:ss a'))
        }, 5000))

        return clearInterval(reload)
    }, [])

    const handlerInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = (e) => {
        e.preventDefault()

        let _form = form
        _form.image = webRef.current.getScreenshot()
        onRegisterTime(form)
    }

    const onShowPhoto = (item) => {
        setModal(true)
        setImage(item.photo)
    }

    const onAddTimer = (item) => {
        onRegisterTimeRest(item)
    }

    return (
        <TemplateMenu>

            <Row className="mt-3">
                <Col lg={5}>
                    <Row className='d-flex justify-content-center'>
                        <Col lg={6}>
                            <Webcam ref={webRef} width={400} />
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col className="text-center">Hora Actual, {hour}</Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg={10}>
                            <form onSubmit={(e) => onSubmit(e)}>
                                <InputForm
                                    name='document'
                                    placeholder='Ingresa tu documento'
                                    error={errors.document}
                                    value={form.document}
                                    onChange={handlerInput}
                                />

                                <InputForm
                                    type='text'
                                    placeholder='Código'
                                    name="code"
                                    value={form.code}
                                    error={errors.code}
                                    onChange={handlerInput}

                                />
                                <ButtonNaranja title='Ingresar' onClick={(e) => onSubmit(e)} />

                            </form>
                        </Col>
                    </Row>

                </Col>
                <Col lg={7}>
                    <Row>
                        <Col>
                            <table className="table table-sm">
                                <thead className='thead-dark'>
                                    <tr>
                                        <th>Nombre Usuario</th>
                                        <th>Fecha y Hora Conexion</th>
                                        <th>Descansos</th>
                                        <th>Estado</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map(row => <RowTableWorker item={row} key={row.id} onClick={onShowPhoto} onClickTimer={onAddTimer} />)}
                                </tbody>
                            </table>
                        </Col>
                    </Row>

                </Col>
            </Row>
            <ModalPhoto show={modal} img={image} onHide={() => setModal(false)} />
        </TemplateMenu>
    )
}

const mapsState = (props) => {

    return {
        errors: {},
        lists: props.user.list,
        fetching: props.user.fetching_timer,
        fetching_working: props.user.fetching_working,
        data_working: props.user.working
    }
}

export default connect(mapsState, { getUserActive, onRegisterTime, onRegisterTimeRest })(WorkerTimerPage);