import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import InputFieldCurrency from "../Components/Widgets/InputFieldCurrency";
import { Form } from "react-bootstrap";

const ItemTableCounter = ({ row, onUpdate, focus }) => {
  const [amount, setAmount] = useState("");
  const [operations, setOperations] = useState("");
  const inputRef = useRef();


  useEffect(() => {
    if (row.amount !== null && row.amount !== '') {
      setAmount(row.amount);
    } else {
      setAmount("");
    }
  }, [row.amount]);

  const updateInput = (e) => {
    let value = e.target.value
    value = value.replace(/[^0-9\.\+]+/, '');
    setAmount(value)
  }

  const updateInputBlur = (e) => {
    let _amount = eval(amount);
    setOperations(amount)
    setAmount(_amount)
    onUpdate(row, _amount, amount)
  }

  const onKeyUp = (e) => {
    if (e.keyCode === 40) {
      setAmount(e.target.value)
      onUpdate(row, e.target.value, amount)
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();
    inputRef.dispatchEvent(
      new KeyboardEvent('keydown', {
        'key': 'tab'
      }));

    console.log('llego', inputRef.current.value);

  }

  return (
    <tr className={`${amount === '' && 'table-warning'}`}>
      <td><div className="w-100">{row.product}</div>
        <div className="text-muted font-9">Last update: <b>{moment(row.updated_at).format("YYYY-MM-DD hh:mm A")}</b> - Usuario: <b>{row.worker}</b></div></td>
      <td>{row.measure_unit}</td>
      <td>{row.control}</td>
      <td className="text-right">
        <Form onSubmit={onSubmit}>
          {/* <div className="text-muted font-9">{row.operations}</div> */}
          <input
            focus={true}
            ref={inputRef}
            // type={"number"}
            className="form-control input-sm text-right"
            value={amount}
            onKeyUp={onKeyUp}
            onChange={updateInput}
            onBlur={updateInputBlur}
          />
        </Form>
      </td>
    </tr>
  );
};

export default ItemTableCounter;
