import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { onToogleCopyProduct, onDeleteCheckProduct } from '../../Redux/productDuck'
import uuid from 'react-uuid';

const ItemMasterProduct = ({ item, onToogleCopyProduct, onDeleteCheckProduct, product_master, row_branch }) => {


    useEffect(() => {
        if (row_branch.id !== 0) {
            setIsChecked(true)
        }
    }, [row_branch])


    useEffect(() => {

        if (item.product !== null) {
            setIsChecked(true)
        } else {
            setIsChecked(false)
        }

    }, [item])

    const [isChecked, setIsChecked] = useState(false)

    const onChange = () => {
        let _isChecked = !isChecked
        setIsChecked(_isChecked)

        if (item.product === null) {
            item.code = product_master.code
            item.name = product_master.product
            item.uuid = uuid()
            onToogleCopyProduct(item, _isChecked)
        } else {
            onDeleteCheckProduct(item, _isChecked)
        }
    }

    return (
        <td>
            <Row>
                <Col lg={1}>
                    <input type='checkbox' checked={isChecked} onChange={onChange} />
                </Col>
                {item.product !== null &&
                    <Col>
                        <Row>
                            <Col>Producto: {item.product.name}</Col>
                        </Row>
                        <Row>
                            <Col>Medida: {item.product.measure_unit}</Col>
                        </Row>
                        <Row>
                            <Col>Grupo: {item.product.group_inventory}</Col>
                        </Row>
                    </Col>}

            </Row>

        </td>
    )
}

const mapsState = (props) => {
    let { product } = props

    return {

    }
}
export default connect(mapsState, { onToogleCopyProduct, onDeleteCheckProduct })(ItemMasterProduct);