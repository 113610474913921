import { getHeaders } from '../utils';

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    fetching_lock: 0,
    fetching_app: 0,
    data: [],
    errors: {},
    row: {}
}

let LIST_SETTING = "LIST_SETTING";
let LIST_SETTING_SUCCESS = "LIST_SETTING_SUCCESS";
let CONFIRM_LOCK = "CONFIRM_LOCK";
let CONFIRM_LOCK_SUCCESS = "CONFIRM_LOCK_SUCCESS";
let SETTING_APP = "SETTING_APP";
let SETTING_APP_SUCCESS = "SETTING_APP_SUCCESS";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_SETTING:
            return { ...state, fetching: 1, }
        case LIST_SETTING_SUCCESS:
            return { ...state, fetching: 2 }
        case CONFIRM_LOCK:
            return { ...state, fetching_lock: 1 };
        case CONFIRM_LOCK_SUCCESS:
            return { ...state, fetching_lock: 2 };
        case SETTING_APP:
            return { ...state, fetching_app: 1 };
        case SETTING_APP_SUCCESS:
            return { ...state, fetching_app: 2, row: action.payload };
        default:
            return state;
    }
}

export let getListSetting = () => (dispatch, getState) => {
    dispatch({
        type: LIST_SETTING,
    });
}

export let getLockInventory = (code) => (dispatch, getState) => {
    dispatch({
        type: SETTING_APP,
    });
    if (getState().user.loggedIn) {
        let url = `${API_URL}/api/setting-app/${code}`;
        fetch(url, {
            headers: getHeaders()
        }).then(resp => resp.json()).then(resp => {
            dispatch({
                type: SETTING_APP_SUCCESS,
                payload: resp
            });
        })
    } else {
        console.log("no Ingreso");
    }
}


export let onToogleInventory = (row, isLocked) => (dispatch, getState) => {
    dispatch({
        type: CONFIRM_LOCK,
    });

    let url = `${API_URL}/api/setting-app/${row.id}?is_locked=${isLocked}`;


    fetch(url, {
        method: "PUT",
        body: JSON.stringify({ is_locked: isLocked }),
        headers: getHeaders(),
    })
        .then((resp) => resp.json())
        .then((resp) => {
            dispatch({
                type: CONFIRM_LOCK_SUCCESS,
            });
        });
};