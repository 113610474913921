import React, { Fragment } from 'react'
import { Row, Col } from 'react-bootstrap';

const OrderDetailCart = ({ item, onClick, onCancel }) => {
    let { client } = item

    return (
        <Fragment>
            <Col lg={12} md={6}>
                <Row className="my-1 p-0  m-0 rounded-lg bg-white border active_list_reservation">
                    <Col>

                        <Row className="justify-content-center p-2">
                            <Col lg={4} md={6} >No. {item.id}</Col>
                            <Col lg={4} md={2}  className="d-flex justify-content-center">
                                <span>{item.branch_office}</span>
                            </Col>
                            <Col lg={4} md={6}  className="d-flex justify-content-end">
                                <span className=" text-center text-naranja">{`{${item.type_delivery_text}}`}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} md={12}>
                                <Row className="py-2">
                                    <Col lg={6} md={6} className="">
                                        <button className="bg-gris-20 rounded text-center text-gris  py-1 w-100" onClick={() => onCancel(item)}>
                                            Rechazar</button>
                                    </Col>
                                    <Col lg={6} md={6} className="">
                                        <button className="bg-naranja rounded text-center text-white w-100 py-1 btn-naranja" onClick={() => onClick(item)}>
                                            Aceptar</button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </Col>
        </Fragment>
    )
}

export default OrderDetailCart;