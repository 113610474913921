import React, { useState, useEffect } from 'react'
import { Modal, Container, Row, Col } from 'react-bootstrap';
import { MdClose } from "react-icons/md";
import InputField from '../Components/Widgets/InputField';
import { connect } from 'react-redux';
import { createIngresos } from '../../Redux/IngresosDuck'
import BlueTitleBack from '../Components/Widgets/BlueTitleBack'
import uuid from 'react-uuid'
import moment from 'moment';

const ModalAddNovelty = ({ showModal, onHide, selected, fetching, title, onSubmit, parameters, onOpenParameter }) => {
    const initState = { reason: '', parameter_id: '', parameter: {}, registration_date: moment().format("YYYY-MM-DD") }
    const [errors, setErrors] = useState({})
    const [form, setForm] = useState(initState)


    useEffect(() => {
        if (fetching === 2) {
            onHide()
        }

        if (Object.keys(selected).length > 0) {
            selected.status_id = (selected.status_id === 2) ? false : true;
            setForm(selected);

        }
        else {
            if (fetching !== 3 && fetching !== 2) {
                setForm(initState)
            }
        }
    }, [fetching, selected])

    const closeModalTmp = () => {
        setErrors({})
        onHide()
    }

    const updateInput = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const updateInputSelect = (e) => {
        let param = parameters.filter(row => row.id === parseInt(e.target.value))[0]
        setForm({
            ...form,
            [e.target.name]: e.target.value,
            ["text"]: param.description
        })
    }

    const printWorker = (row) => {
        return <option key={row.id} value={row.id}>{row.description}</option>
    }

    const onSubmitTmp = () => {

        let _form = form
        _form.parameter = parameters.filter(row => row.id === parseInt(form.parameter_id))[0]
        _form._id = uuid();


        let validate = false
        let err = {}

        if (form.parameter_id === '') {
            err.parameter_id = "Parametro Requerido"
            validate = true;
        }

        if (form.reason === '') {
            err.reason = "Valor Requerido"
            validate = true;
        }

        if (validate === false) {

            onSubmit(form)
            setForm(initState)
        } else {
            setErrors(err)
        }
    }


    return (
        <Modal centered show={showModal} onHide={closeModalTmp}>
            <div className="modal-header border-bottom-custom mt-2">
                <BlueTitleBack title={title} />
                <button type="button" className="close p-0 m-0" data-dismiss="modal" aria-label="Close" onClick={closeModalTmp}>
                    <MdClose />
                </button>
            </div>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Fecha</label>
                                        <input
                                            className='form-control'
                                            type="date"
                                            value={form.registration_date}
                                            name="registration_date"
                                            min={moment().subtract(1, 'd').format("YYYY-MM-DD")} max={moment().format("YYYY-MM-DD")}
                                            onChange={updateInput}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='d-flex justify-content-center'>
                                <Col xs={8}>
                                    <div className="form-group">
                                        <label>Concepto</label>
                                        <select className="form-control" name="parameter_id" value={form.parameter_id} onChange={updateInputSelect}>
                                            <option>Seleccione</option>
                                            {parameters.map(printWorker)}
                                        </select>
                                        {errors.parameter_id !== undefined && (<label className="error-label">{errors.parameter_id}</label>)}

                                    </div>
                                </Col>
                                <Col className='d-flex align-items-center'>
                                    <button className='btn btn-info' onClick={onOpenParameter}>Agregar</button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label>Motivo</label>
                                        <InputField
                                            placeholder={"Describe el Motivo"}
                                            value={form.reason || ''}
                                            name="reason"
                                            onChange={updateInput}
                                            error={errors.reason}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Container>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Row >
                    <Col>
                        <button className='btn btn-danger' onClick={closeModalTmp}>Cerrar</button>
                    </Col>
                    <Col>
                        <button className='btn btn-success' onClick={onSubmitTmp}>Guardar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal >
    )
}

const mapsState = (props) => {
    return {
        errors: props.ingresos.errors,
        selected: props.ingresos.selected,
        fetching: props.ingresos.fetching_create
    }
}

export default connect(mapsState, { createIngresos })(ModalAddNovelty);