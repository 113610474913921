import axios from 'axios'
import { getHeaders, removeSession, validateProduct } from '../utils';
const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    fetching_edit: 0,
    fetching_by_id: 0,
    fetching_delete: 0,
    fetching_master: 0,
    fetching_copy: 0,
    fetching_check: 0,

    data: [],
    data_master: [],
    data_check: [],
    data_check_delete: [],
    selected: {},
    errors: { title: '' }
}

let PRODUCT = "PRODUCT";
let PRODUCT_SUCCESS = "PRODUCT_SUCCESS";
let PRODUCT_ERROR = "PRODUCT_ERROR";
let PRODUCT_LIST = "PRODUCT_LIST";
let PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
let PRODUCT_ID = "PRODUCT_ID"
let PRODUCT_ID_SUCCESS = "PRODUCT_ID_SUCCESS"
let INIT_PRODUCT = "INIT_PRODUCT"
let LOSE_SESSION = "LOSE_SESSION"
let DELETE_GROUP = "DELETE_GROUP"
let DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS"
let DELETE_PRODUCT = "DELETE_PRODUCT"
let DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS"

let PRODUCT_ALDELO = "PRODUCT_ALDELO"
let PRODUCTS_MASTER = "PRODUCTS_MASTER"
let PRODUCTS_MASTER_SUCCESS = "PRODUCTS_MASTER_SUCCESS"
let COPY_PRODUCT = "COPY_PRODUCT"
let COPY_PRODUCT_SUCCESS = "COPY_PRODUCT_SUCCESS"
let ITEM_COPY_CHECK = "ITEM_COPY_CHECK"
let ITEM_COPY_CHECK_SUCCESS = "ITEM_COPY_CHECK_SUCCESS"
let ITEM_DELETE_CHECK = "ITEM_DELETE_CHECK"
let ITEM_DELETE_CHECK_SUCCESS = "ITEM_DELETE_CHECK_SUCCESS"

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case INIT_PRODUCT:
            return { ...state, fetching: 0, fetching_edit: 0, selected: {} }
        case PRODUCT:
            return { ...state, fetching_edit: 1 }
        case PRODUCT_SUCCESS:
            return { ...state, fetching_edit: 2, selected: action.payload }
        case PRODUCT_ERROR:
            return { ...state, fetching_edit: 3, errors: action.payload, selected: action.form }
        case PRODUCT_LIST:
            return { ...state, fetching_list: 1 }
        case PRODUCT_LIST_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload, fetching_edit: 0 }
        case PRODUCT_ID:
            return { ...state, fetching_by_id: 1 }
        case PRODUCT_ID_SUCCESS:
            return { ...state, fetching_by_id: 2, selected: action.payload }

        case LOSE_SESSION: {
            return { ...state, fetching: 4 }
        }

        case DELETE_GROUP:
            return { ...state, fetching_delete: 1 }
        case DELETE_GROUP_SUCCESS:
            return { ...state, fetching_delete: 2, selected: action.payload }
        case DELETE_PRODUCT:
            return { ...state, fetching: 1 }
        case DELETE_PRODUCT_SUCCESS:
            return { ...state, fetching: 5 }
        case PRODUCTS_MASTER:
            return { ...state, fetching_master: 1 }
        case PRODUCTS_MASTER_SUCCESS:
            return { ...state, fetching_master: 2, data_master: action.payload }
        case COPY_PRODUCT:
            return { ...state, fetching_copy: 1 }
        case COPY_PRODUCT_SUCCESS:
            return { ...state, fetching_copy: 2, data_check: [] }
        case ITEM_COPY_CHECK:
            return { ...state, fetching_check: 1 }
        case ITEM_COPY_CHECK_SUCCESS:
            let _data = [...action.payload]
            return { ...state, fetching_check: 2, data_check: _data }
        case ITEM_DELETE_CHECK:
            return { ...state, fetching_check: 1 }
        case ITEM_DELETE_CHECK_SUCCESS:
            let _data2 = [...action.payload]
            return { ...state, fetching_check: 2, data_check_delete: _data2 }
        default:
            return state;
    }
}

export let initDuck = () => (dispatch, getState) => {
    dispatch({
        type: INIT_PRODUCT,
    })
}

export let onToogleCopyProduct = (product, _isChecked) => (dispatch, getState) => {
    dispatch({
        type: ITEM_COPY_CHECK,
    })

    let data_check = getState().product.data_check

    if (_isChecked) {
        data_check.push(product)
    } else {
        data_check = data_check.filter(d => d.code !== product.code)
    }

    dispatch({
        type: ITEM_COPY_CHECK_SUCCESS,
        payload: data_check
    })
}
export let onDeleteCheckProduct = (product, _isChecked) => (dispatch, getState) => {
    dispatch({
        type: ITEM_DELETE_CHECK,
    })


    console.log('product-delete', product);


    let data_check_delete = getState().product.data_check_delete

    if (!_isChecked) {
        data_check_delete.push(product)
    } else {
        data_check_delete = data_check_delete.filter(d => d.code !== product.code)
    }

    dispatch({
        type: ITEM_DELETE_CHECK_SUCCESS,
        payload: data_check_delete
    })
}

export let createProduct = (form, group, brands) => (dispatch, getState) => {

    dispatch({
        type: PRODUCT
    })

    if (group !== '0') {
        form.group = group
    }

    let validate = validateProduct(form)

    if (Object.keys(validate).length > 0) {
        console.log("ingrsdso validado");
        dispatch({
            type: PRODUCT_ERROR,
            payload: validate,
            form: form
        })
        return;
    }

    if (getState().user.loggedIn) {

        let _form = new FormData()
        _form.append("id", (form.id === undefined) ? '' : form.id);
        _form.append("banner", form.banner);
        _form.append("image", form.image);
        _form.append("title", form.title);
        _form.append("description", form.description);
        _form.append("aldelo_id", form.aldelo_id);
        _form.append("category_id", form.category_id);
        _form.append("priority", form.priority);
        _form.append("status", form.status);
        _form.append("groups", JSON.stringify(form.groups));
        _form.append("brands", JSON.stringify(form.brands));

        axios.post(`${API_URL}/api/products`, _form, {
            headers: getHeaders()

        }).then(resp => {

            dispatch({
                type: PRODUCT_SUCCESS,
                payload: resp.data
            });

        }).catch(error => {
            let err = {}

            for (const [key, value] of Object.entries(error.response.data.errors)) {
                if ('title' === key) {
                    err.title = value[0]
                }
                if ('description' === key) {
                    err.description = value[0]
                }
                if ('priority' === key) {
                    err.priority = value[0]
                }
                if ('price' === key) {
                    err.price = value[0]
                }
                if ('category_id' === key) {
                    err.category_id = value[0]
                }
            }

            dispatch({
                type: PRODUCT_ERROR,
                payload: err,
                form: form
            })
        });
    } else {
        console.log("no Ingreso");
    }
}

export let addModifierProduct = (form, group) => (dispatch, getState) => {

    let param = {}
    param.group = group

    dispatch({
        type: PRODUCT
    })

    if (getState().user.loggedIn) {

        axios.put(`${API_URL}/api/products/${form.id}`, param, {
            headers: getHeaders()

        }).then(resp => {

            dispatch({
                type: PRODUCT_SUCCESS,
                payload: resp.data
            });

        }).catch(err => {
            console.log("ERORR", err.response);
        });
    } else {
        console.log("no Ingreso");
    }
}


export let editModifierProduct = (form) => (dispatch, getState) => {
    let product = getState().product.selected.product

    form.product_id = product.id;

    dispatch({
        type: PRODUCT
    })

    if (getState().user.loggedIn) {

        axios.put(`${API_URL}/api/edit-modifier-product/${form.id}`, form, {
            headers: getHeaders()

        }).then(resp => {

            dispatch({
                type: PRODUCT_SUCCESS,
                payload: resp.data
            });

        }).catch(err => {
            console.log("ERORR", err.response);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let editModifierProductHeader = (form) => (dispatch, getState) => {
    dispatch({
        type: PRODUCT
    })

    if (getState().user.loggedIn) {

        axios.put(`${API_URL}/api/edit-modifier-product-header/${form.id}`, form, {
            headers: getHeaders()

        }).then(resp => {

            dispatch({
                type: PRODUCT_SUCCESS,
                payload: resp.data
            });

        }).catch(err => {
            console.log("ERORR", err.response);
        });
    } else {
        console.log("no Ingreso");
    }
}



export let getProductById = (id) => (dispatch, getState) => {
    dispatch({
        type: PRODUCT_ID,
        payload: []
    })

    if (getState().user.loggedIn) {

        axios.get(`${API_URL}/api/products/${id}`, {
            headers: getHeaders()

        }).then(resp => {
            dispatch({
                type: PRODUCT_ID_SUCCESS,
                payload: resp.data
            });

        }).catch(err => {
            // let { status } = err.response;

            // if (status === 401) {
            //     removeSession()
            //     dispatch({
            //         type: LOSE_SESSION
            //     });
            // }
        });
    } else {
        console.log("no Ingreso");
    }
}


export let getProducts = () => (dispatch, getState) => {
    dispatch({
        type: PRODUCT_LIST
    })

    if (getState().user.loggedIn) {

        axios.get(`${API_URL}/api/products`, {
            headers: getHeaders()

        }).then(resp => {
            dispatch({
                type: PRODUCT_LIST_SUCCESS,
                payload: resp.data.results
            });

        }).catch(err => {
            let { status } = err.response;

            if (status === 401) {
                removeSession()
                dispatch({
                    type: LOSE_SESSION
                });
            }
        });
    } else {
        console.log("no Ingreso");
    }
}

export let getProductsAldelo = (socket) => (dispatch, getState) => {
    dispatch({
        type: PRODUCT_ALDELO
    })

    if (getState().user.loggedIn) {
        socket.emit("list-products-aldelo", (data) => {
            console.log('data', data)
        })

    } else {
        console.log("no Ingreso");
    }
}

export let deleteGroup = (item) => (dispatch, getState) => {
    dispatch({
        type: DELETE_GROUP
    })

    console.log("deleteGroup", item)

    if (getState().user.loggedIn) {

        axios.delete(`${API_URL}/api/delete-group-product/${item.id}`, {
            headers: getHeaders()

        }).then(resp => {
            dispatch({
                type: DELETE_GROUP_SUCCESS,
                payload: resp.data
            });

        }).catch(err => {
            let { status } = err.response;

            if (status === 401) {
                removeSession()
                dispatch({
                    type: LOSE_SESSION
                });
            }
        });
    } else {
        console.log("no Ingreso");
    }
}

export let deleteProduct = () => (dispatch, getState) => {
    dispatch({
        type: DELETE_PRODUCT
    })

    let { product } = getState().product.selected

    if (getState().user.loggedIn) {

        axios.delete(`${API_URL}/api/products/${product.id}`, {
            headers: getHeaders()

        }).then(resp => {
            dispatch({
                type: DELETE_PRODUCT_SUCCESS,
                payload: resp.data
            });

        }).catch(err => {
            let { status } = err.response;

            if (status === 401) {
                removeSession()
                dispatch({
                    type: LOSE_SESSION
                });
            }
        });
    } else {
        console.log("no Ingreso");
    }
}

export let getProductsMaster = () => (dispatch, getState) => {
    dispatch({
        type: PRODUCTS_MASTER
    })

    if (getState().user.loggedIn) {

        let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))

        axios.get(`${API_URL}/api/product-inventory-master?date_init=${date_init}`, {
            headers: getHeaders()

        }).then(resp => {
            dispatch({
                type: PRODUCTS_MASTER_SUCCESS,
                payload: resp.data.results
            });

        }).catch(err => {

        });
    } else {
        console.log("no Ingreso");
    }
}

export let onCopyAllProduct = (list_products) => (dispatch, getState) => {
    dispatch({
        type: PRODUCTS_MASTER
    })

    if (getState().user.loggedIn) {

        dispatch({
            type: PRODUCTS_MASTER_SUCCESS,
            payload: [...list_products]
        });
    } else {
        console.log("no Ingreso");
    }
}

export let copyProductsMaster = () => (dispatch, getState) => {
    dispatch({
        type: COPY_PRODUCT
    })

    if (getState().user.loggedIn) {

        let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))


        let { data_check, data_check_delete } = getState().product

        axios.put(`${API_URL}/api/product-inventory-master?date_init=${date_init}`, {
            data_check,
            data_check_delete
        }, {
            headers: getHeaders()

        }).then(resp => {
            dispatch({
                type: COPY_PRODUCT_SUCCESS,
            });

        }).catch(err => {

        });
    } else {
        console.log("no Ingreso");
    }
}

export let deleteProductsMaster = (id) => (dispatch, getState) => {
    dispatch({
        type: COPY_PRODUCT
    })

    if (getState().user.loggedIn) {

        axios.delete(`${API_URL}/api/product-inventory-master/${id}`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: COPY_PRODUCT_SUCCESS,
            });

        }).catch(err => {

        });
    } else {
        console.log("no Ingreso");
    }
}
