import React, { useEffect, useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { connect } from 'react-redux';
import { getMessageBotById, editMessageBotById } from '../../Redux/whatsappDuck'

const EditMessageBot = ({ getMessageBotById, fetching_by_id, row, editMessageBotById }) => {

    let { id } = useParams()

    const [form, setForm] = useState({ message: '', template: '' })

    useEffect(() => {
        getMessageBotById(id)
    }, [id])

    useEffect(() => {
        if (fetching_by_id === 2) {

            let _row = row
            if (row.template !== '') {
                _row.template = JSON.parse(row.template)
            }

            setForm(_row)
        }
    }, [fetching_by_id])


    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }
    const onSave = () => {
        let _form = form
        _form.template = JSON.stringify(form.template)
        editMessageBotById(_form)
    }

    return (
        <TemplateMenu>
            <Row>
                <Col>Editar Mensaje</Col>
            </Row>
            <Row>
                <Col>
                    <input value={form.message} name='message' className='form-control' onChange={updateInput} />
                </Col>
            </Row>
            <Row className='my-2'>
                <Col>
                    <textarea value={form.template} name='template' rows={10} className='form-control' onChange={updateInput} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <button className='btn btn-sm btn-info' onClick={onSave}>Guardar</button>
                </Col>
            </Row>
        </TemplateMenu>
    )
}


const mapsState = (props) => {
    let { whatsapp } = props
    return {
        fetching_by_id: whatsapp.fetching_by_id,
        row: whatsapp.row,
    }
}
export default connect(mapsState, { getMessageBotById, editMessageBotById })(EditMessageBot);