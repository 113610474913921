import moment from 'moment';
import React from 'react'
import { formatNumber } from '../../utils';

const ItemMainTable = ({ item, onDeleteItem }) => {
    let { branch_office } = item
    return (
        <tr>
            <td>{item.id}</td>
            <td>{moment(item.created_at).format("DD-MM-YYYY")}</td>
            <td>{branch_office.title}</td>
            <td>{moment(item.date_init).format("DD MMM YYYY")} / {moment(item.date_end).format("DD MMM YYYY")}</td>
            <td>{formatNumber(item.amount)}</td>
            <td>{item.status_id_text}</td>
            <td><a className='btn btn-sm btn-info' href={`/propinas/${item.id}`}>Ver</a>
                <button className='btn btn-danger btn-sm mx-3' onClick={() => onDeleteItem(item)}>Borrar</button></td>
        </tr>
    )
}

export default ItemMainTable;