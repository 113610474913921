import axios from "axios";
import { getHeaders, validateInput } from "../utils";
const API_URL = process.env.REACT_APP_API_URL;

let initialState = {
  fetching_list: 0,
  fetching_create: 0,
  fetching_update: 0,
  fetching_initial: 0,
  fetching_list_charge: 0,
  fetching_charge: 0,
  fetching_create_charge: 0,
  fetching_by_id: 0,
  fetching_assoc: 0,
  fetching_validate: 0,
  data: [],
  data_initial: [],
  data_charge: [],
  row: {},
  charge: [],
  errors: {},
};

let LIST_PRODUCTS_INVENTORY = "LIST_PRODUCTS_INVENTORY";
let LIST_PRODUCTS_INVENTORY_SUCCESS = "LIST_PRODUCTS_INVENTORY_SUCCESS";
let PRODUCTS_INVENTORY_BY_ID = "PRODUCTS_INVENTORY_BY_ID";
let PRODUCTS_INVENTORY_BY_ID_SUCCESS = "PRODUCTS_INVENTORY_BY_ID_SUCCESS";
let CREATE_PRODUCTS_INVENTORY = "CREATE_PRODUCTS_INVENTORY";
let CREATE_PRODUCTS_INVENTORY_SUCCESS = "CREATE_PRODUCTS_INVENTORY_SUCCESS";
let CREATE_PRODUCTS_INVENTORY_ERROR = "CREATE_PRODUCTS_INVENTORY_ERROR";
let PRODUCTS_INVENTORY_INITIAL = "PRODUCTS_INVENTORY_INITIAL";
let PRODUCTS_INVENTORY_INITIAL_SUCCESS = "PRODUCTS_INVENTORY_INITIAL_SUCCESS";
let LIST_PRODUCTS_INVENTORY_INITIAL = "LIST_PRODUCTS_INVENTORY_INITIAL";
let LIST_PRODUCTS_INVENTORY_INITIAL_SUCCESS = "LIST_PRODUCTS_INVENTORY_INITIAL_SUCCESS";
let RESTART_CREATE = "RESTART_CREATE";
let LIST_PRODUCT_CHARGE = "LIST_PRODUCT_CHARGE";
let LIST_PRODUCT_CHARGE_SUCCESS = "LIST_PRODUCT_CHARGE_SUCCESS";

let CREATE_CHARGE = "CREATE_CHARGE";
let CREATE_CHARGE_SUCCESS = "CREATE_CHARGE_SUCCESS";

let LIST_CHARGE = "LIST_CHARGE";
let LIST_CHARGE_SUCCESS = "LIST_CHARGE_SUCCESS";
let CHARGE_BY_ID = "CHARGE_BY_ID";
let CHARGE_BY_ID_SUCCESS = "CHARGE_BY_ID_SUCCESS";
let ASSOC_PRODUCT = "ASSOC_PRODUCT";
let ASSOC_PRODUCT_SUCCESS = "ASSOC_PRODUCT_SUCCESS";
let VALIDATE_PRODUCT = "VALIDATE_PRODUCT";
let VALIDATE_PRODUCT_SUCCESS = "VALIDATE_PRODUCT_SUCCESS";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LIST_PRODUCTS_INVENTORY:
      return { ...state, fetching_list: 1 };
    case LIST_PRODUCTS_INVENTORY_SUCCESS:
      return {
        ...state,
        fetching_list: 2,
        data: action.payload,
        fetching_create: 0,
      };
    case CREATE_PRODUCTS_INVENTORY:
      return { ...state, fetching_create: 1 };
    case CREATE_PRODUCTS_INVENTORY_SUCCESS:
      return { ...state, fetching_create: 2, errors: {} };
    case CREATE_PRODUCTS_INVENTORY_ERROR:
      return { ...state, fetching_create: 3, errors: action.payload };
    case PRODUCTS_INVENTORY_INITIAL:
      return { ...state, fetching_update: 1 };
    case PRODUCTS_INVENTORY_INITIAL_SUCCESS:
      return { ...state, fetching_update: 2 };
    case LIST_PRODUCTS_INVENTORY_INITIAL:
      return { ...state, fetching_initial: 1 };
    case LIST_PRODUCTS_INVENTORY_INITIAL_SUCCESS:
      return { ...state, fetching_initial: 2, data_initial: action.payload };
    case RESTART_CREATE:
      return { ...state, fetching_create: 0 }
    case LIST_PRODUCT_CHARGE:
      return { ...state, fetching_charge: 1 }
    case LIST_PRODUCT_CHARGE_SUCCESS:
      return { ...state, fetching_charge: 2, data_charge: action.payload }
    case CREATE_CHARGE:
      return { ...state, fetching_create_charge: 1 }
    case CREATE_CHARGE_SUCCESS:
      return { ...state, fetching_create_charge: 2 }
    case LIST_CHARGE:
      return { ...state, fetching_list_charge: 1 }
    case LIST_CHARGE_SUCCESS:
      return { ...state, fetching_list_charge: 2, charge: action.payload }
    case CHARGE_BY_ID:
      return { ...state, fetching_by_id: 1 }
    case CHARGE_BY_ID_SUCCESS:
      return { ...state, fetching_by_id: 2, row: action.payload }
    case ASSOC_PRODUCT:
      return { ...state, fetching_assoc: 1 }
    case ASSOC_PRODUCT_SUCCESS:
      return { ...state, fetching_assoc: 2 }
    case PRODUCTS_INVENTORY_BY_ID:
      return { ...state, fetching_by_id: 1 }
    case PRODUCTS_INVENTORY_BY_ID_SUCCESS:
      return { ...state, fetching_by_id: 2, row: action.payload }
    case VALIDATE_PRODUCT:
      return { ...state, fetching_validate: 1 }
    case VALIDATE_PRODUCT_SUCCESS:
      return { ...state, fetching_validate: 2, row: action.payload }
    default:
      return state;
  }
}

export let restartCreate = () => (dispatch, getState) => {
  dispatch({
    type: RESTART_CREATE,
  });
}
export let getProductsInventory = (q) => (dispatch, getState) => {
  dispatch({
    type: LIST_PRODUCTS_INVENTORY,
  });

  q = q || "";


  let branch = JSON.parse(localStorage.getItem("branch"))
  let { date_init } = JSON.parse(localStorage.getItem("date"))

  let url = `${API_URL}/api/product-inventory?branch_id=${branch.id}${q}&date_init=${date_init}`;

  fetch(url, {
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: LIST_PRODUCTS_INVENTORY_SUCCESS,
        payload: resp.results,
      });
    });
}
export let getProductInventoryById = (id) => (dispatch, getState) => {
  dispatch({
    type: PRODUCTS_INVENTORY_BY_ID,
  });

  let url = `${API_URL}/api/product-inventory/${id}`;

  fetch(url, {
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: PRODUCTS_INVENTORY_BY_ID_SUCCESS,
        payload: resp,
      });
    });
}
export let getProductInventoryByName = (field, name) => (dispatch, getState) => {
  dispatch({
    type: VALIDATE_PRODUCT,
  });

  let url = `${API_URL}/api/product-inventory-validate-attribute/${name}?field=${field}`;

  fetch(url, {
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: VALIDATE_PRODUCT_SUCCESS,
        payload: resp,
      });
    });
}

export let getProductsInventoryLite = (q) => (dispatch, getState) => {
  dispatch({
    type: LIST_PRODUCTS_INVENTORY,
  });

  q = q || "";


  let branch = JSON.parse(localStorage.getItem("branch"))
  let { date_init } = JSON.parse(localStorage.getItem("date"))

  let url = `${API_URL}/api/product-inventory-lite?branch_id=${branch.id}${q}&date_init=${date_init}`;

  fetch(url, {
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: LIST_PRODUCTS_INVENTORY_SUCCESS,
        payload: resp.results,
      });
    });
};


export let getProductsInventoryInitital = () => (dispatch, getState) => {
  dispatch({
    type: LIST_PRODUCTS_INVENTORY_INITIAL,
  });

  let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));
  let branch = JSON.parse(localStorage.getItem("branch"));

  let url = `${API_URL}/api/product-inventory-initial?date_init=${date_init}&date_end=${date_end}&branch=${branch.id}`;

  fetch(url, {
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: LIST_PRODUCTS_INVENTORY_INITIAL_SUCCESS,
        payload: resp.results,
      });
    });
};

export let getListCharge = () => (dispatch, getState) => {
  dispatch({
    type: LIST_CHARGE,
  });

  let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));
  let branch = JSON.parse(localStorage.getItem("branch"));

  let url = `${API_URL}/api/product-inventory-list-charge?date_init=${date_init}&date_end=${date_end}&branch=${branch.id}`;

  fetch(url, {
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: LIST_CHARGE_SUCCESS,
        payload: resp.results,
      });
    });
}

export let getChargeById = (id) => (dispatch, getState) => {
  dispatch({
    type: CHARGE_BY_ID,
  });

  let url = `${API_URL}/api/product-inventory-charge/${id}`;

  fetch(url, {
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: CHARGE_BY_ID_SUCCESS,
        payload: resp,
      });
    });
};

export let getProductsCharge = () => (dispatch, getState) => {
  dispatch({
    type: LIST_PRODUCT_CHARGE,
  });

  let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));
  let branch = JSON.parse(localStorage.getItem("branch"));

  let url = `${API_URL}/api/product-inventory-charge?date_init=${date_init}&date_end=${date_end}&branch=${branch.id}`;

  fetch(url, {
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: LIST_PRODUCT_CHARGE_SUCCESS,
        payload: resp.results,
      });
    });
}

export let onEditProductsCharge = (form) => (dispatch, getState) => {
  dispatch({
    type: CREATE_CHARGE,
  });

  let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));
  let branch = JSON.parse(localStorage.getItem("branch"));

  let url = `${API_URL}/api/product-inventory-charge/${form.id}`;

  form.branch_id = branch.id;
  form.date_init = date_init;

  axios
    .put(url, form, {
      headers: getHeaders(),
    })
    .then((resp) => {
      dispatch({
        type: CREATE_CHARGE_SUCCESS,
      });
    })
    .catch((error) => {
      let err = {};

      for (const [key, value] of Object.entries(error.response.data.errors)) {
        if ("code" === key) {
          err.code = value;
        }
        if ("name" === key) {
          err.name = value;
        }
      }
    });
}

export let onCreateProductsCharge = (form) => (dispatch, getState) => {
  dispatch({
    type: CREATE_CHARGE,
  });

  let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));
  let branch = JSON.parse(localStorage.getItem("branch"));

  let url = `${API_URL}/api/product-inventory-charge`;

  form.branch_id = branch.id;
  form.date_init = date_init;

  axios
    .post(url, form, {
      headers: getHeaders(),
    })
    .then((resp) => {
      dispatch({
        type: CREATE_CHARGE_SUCCESS,
      });
    })
    .catch((error) => {
      let err = {};

      for (const [key, value] of Object.entries(error.response.data.errors)) {
        if ("code" === key) {
          err.code = value;
        }
        if ("name" === key) {
          err.name = value;
        }
      }
    });
}

export let updateInventoryInitital = (row) => (dispatch, getState) => {
  dispatch({
    type: PRODUCTS_INVENTORY_INITIAL,
  });

  let branch = JSON.parse(localStorage.branch);
  let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));

  let url = `${API_URL}/api/product-inventory-initial/${row.id}?date_init=${date_init}&date_end=${date_end}`;

  row.branch_id = branch.id;

  fetch(url, {
    method: "PUT",
    body: JSON.stringify(row),
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: PRODUCTS_INVENTORY_INITIAL_SUCCESS,
        payload: resp.results,
      });
    });
};

export let onCreateProductInventory = (form) => (dispatch, getState) => {
  dispatch({
    type: CREATE_PRODUCTS_INVENTORY,
  });

  let rules = [
    { field: "name", msg: "El nombre es requerido" },
    { field: "code", msg: "El Código es requerido" },
    { field: "measure_unit_id", msg: "La unidad de Medida requerido" },
    { field: "tax_id", msg: "El impuesto es requerido" },
    // { field: "charge", msg: "El Cargo es requerido" },
    { field: "family_inventory_id", msg: "La familia es requerida" },
    { field: "type_inventory_id", msg: "El tipo de Inventario es requerida" },
    { field: "group_inventory_id", msg: "El grupo es requerida" },
    { field: "control_inventory_id", msg: "El control es requerido" },
    { field: "supplier_id", msg: "El Proveedor es requerido" },
  ];

  let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));

  let validate = validateInput(form, rules);

  if (validate !== null) {
    dispatch({
      type: CREATE_PRODUCTS_INVENTORY_ERROR,
      payload: validate,
    });
    return;
  }

  let url = `${API_URL}/api/product-inventory`;

  let branch = JSON.parse(localStorage.branch);

  form.branch_id = branch.id;
  form.date_init = date_init;

  axios
    .post(url, form, {
      headers: getHeaders(),
    })
    .then((resp) => {
      dispatch({
        type: CREATE_PRODUCTS_INVENTORY_SUCCESS,
      });
    })
    .catch((error) => {
      let err = {};

      for (const [key, value] of Object.entries(error.response.data.errors)) {
        if ("code" === key) {
          err.code = value;
        }
        if ("name" === key) {
          err.name = value;
        }
      }

      dispatch({
        type: CREATE_PRODUCTS_INVENTORY_ERROR,
        payload: err,
      });
    });
};

export let onEditProductInventory = (form) => (dispatch, getState) => {
  dispatch({
    type: CREATE_PRODUCTS_INVENTORY,
  });

  let rules = [
    { field: "name", msg: "El nombre es requerido" },
    { field: "code", msg: "El Código es requerido" },
    { field: "measure_unit_id", msg: "La unidad de Medida requerido" },
    { field: "tax_id", msg: "El impuesto es requerido" },
    { field: "cost", msg: "El Costo es requerido" },
    { field: "family_inventory_id", msg: "La familia es requerida" },
    { field: "type_inventory_id", msg: "El tipo de Inventario es requerida" },
    { field: "group_inventory_id", msg: "El grupo es requerida" },
    { field: "control_inventory_id", msg: "El control es requerido" },
    { field: "supplier_id", msg: "El Proveedor es requerido" },
  ];

  let validate = validateInput(form, rules);

  if (validate !== null) {
    dispatch({
      type: CREATE_PRODUCTS_INVENTORY_ERROR,
      payload: validate,
    });
    return;
  }

  let url = `${API_URL}/api/product-inventory/${form.id}`;

  let branch = JSON.parse(localStorage.getItem("branch"));

  form.branch_id = branch.id

  axios
    .put(url, form, {
      headers: getHeaders(),
    })
    .then((resp) => {
      dispatch({
        type: CREATE_PRODUCTS_INVENTORY_SUCCESS,
      });
    })
    .catch((error) => {
      let err = {};

      for (const [key, value] of Object.entries(error.response.data.errors)) {
        if ("code" === key) {
          err.code = value;
        }
        if ("name" === key) {
          err.name = value;
        }
      }

      dispatch({
        type: CREATE_PRODUCTS_INVENTORY_ERROR,
        payload: err,
      });
    });
}

export let onDeleteProductInventory = (form) => (dispatch, getState) => {
  dispatch({
    type: CREATE_PRODUCTS_INVENTORY,
  });


  let url = `${API_URL}/api/product-inventory/${form.id}`;

  axios
    .delete(url, {
      headers: getHeaders(),
    })
    .then((resp) => {
      dispatch({
        type: CREATE_PRODUCTS_INVENTORY_SUCCESS,
      });
    })
    .catch((error) => {
      let err = {};

      for (const [key, value] of Object.entries(error.response.data.errors)) {
        if ("code" === key) {
          err.code = value;
        }
        if ("name" === key) {
          err.name = value;
        }
      }

      dispatch({
        type: CREATE_PRODUCTS_INVENTORY_ERROR,
        payload: err,
      });
    });
}


export let onAssocListProduct = (form) => (dispatch, getState) => {
  dispatch({
    type: ASSOC_PRODUCT,
  });

  let url = `${API_URL}/api/product-inventory-assoc/${form.id}`;

  axios
    .put(url, form, {
      headers: getHeaders(),
    })
    .then((resp) => {
      dispatch({
        type: ASSOC_PRODUCT_SUCCESS,
      });
    })
    .catch((error) => {
      let err = {};

      for (const [key, value] of Object.entries(error.response.data.errors)) {
        if ("code" === key) {
          err.code = value;
        }
        if ("name" === key) {
          err.name = value;
        }
      }

      dispatch({
        type: CREATE_PRODUCTS_INVENTORY_ERROR,
        payload: err,
      });
    });
};
export let onDeleteAssocProduct = (id) => (dispatch, getState) => {
  dispatch({
    type: ASSOC_PRODUCT,
  });

  let url = `${API_URL}/api/product-inventory-assoc/${id}`;

  axios
    .delete(url, {
      headers: getHeaders(),
    })
    .then((resp) => {
      dispatch({
        type: ASSOC_PRODUCT_SUCCESS,
      });
    })
    .catch((error) => {
      let err = {};

      for (const [key, value] of Object.entries(error.response.data.errors)) {
        if ("code" === key) {
          err.code = value;
        }
        if ("name" === key) {
          err.name = value;
        }
      }

      dispatch({
        type: CREATE_PRODUCTS_INVENTORY_ERROR,
        payload: err,
      });
    });
};
