import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { BsFillPersonCheckFill } from 'react-icons/bs'
import { MdPets } from "react-icons/md";

const ColDiningTable = ({ item, onSelectDining }) => {

    let { row } = item



    let title = Object.keys(row).length > 0 ? <Container>
        <Row>
            <Col className='text-center text-white'>Mesa</Col>
        </Row>
        <Row>
            <Col className='text-center  text-white'>{row.name}</Col>
        </Row>

        {Object.keys(row).length > 0 && <Row className='px-0'>
            <Col className='text-center text-white mx-0'>(<span className='mx-2'>{row.people}</span><BsFillPersonCheckFill />)
                {row.pet === true && <MdPets />}</Col>
        </Row>}

    </Container> : ''

    return (
        <div className={`${Object.keys(row).length > 0 ? 'col-dining-active' : 'col-dining'}`} onClick={() => onSelectDining(item)}>{title}</div>
    )
}

export default ColDiningTable;