import React, { useState, useEffect } from 'react'
import { Modal, Container, Row, Col, Button } from 'react-bootstrap'
import { MdClose } from "react-icons/md";

import { connect } from 'react-redux'
import { createGroup } from '../../Redux/modifierDuck'
import InputField from '../Components/Widgets/InputField'
import ModalHeader from 'react-bootstrap/esm/ModalHeader'
import BtnOrangeSm from './../Components/Widgets/BtnOrangeSm'
import BtnBlueModal from './../Components/Widgets/BtnBlueModal'
import BlueTitleBack from './../Components/Widgets/BlueTitleBack'

const ModalGroup = ({ showModal, onSuccess, createGroup, onHideGroup, fetching, errors, formEdit }) => {
    const initState = { title: '', description: '', priority: '' };
    const [form, setForm] = useState(initState);

    // useEffect(()=>{
    //     if(fetching === 2){
    //         onSuccess()
    //     }


    //     if(Object.keys(selected).length>0){
    //         setForm(selected);
    //     }
    //     else{
    //         if(fetching!==3 && fetching!==2){
    //             setForm(initState)
    //         }
    //     }


    // },[fetching,selected])

    useEffect(() => {

        if (Object.keys(formEdit).length > 0) {
            setForm(formEdit);
        }


    }, [formEdit])

    useEffect(() => {
        if (fetching === 2) {
            onSuccess()
            setForm(initState)
        }

    }, [fetching])

    const closeModalTmp = () => {
        setForm(initState)
        onHideGroup()
    }

    const updateInput = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const updateCheck = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.checked
        })
    }

    const onSubmit = () => {
        createGroup(form)
    }

    return (
        <Modal show={showModal} onHide={onHideGroup} centered>
            <ModalHeader className="border-bottom-custom d-flex justify-content-end">
                <Modal.Title>Grupos</Modal.Title>

                <button type="button" className="close p-0 m-0" data-dismiss="modal" aria-label="Close" onClick={closeModalTmp}>
                    <MdClose />
                </button>
            </ModalHeader>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label className="text-gray-60">Titulo Grupo</label>
                                        <InputField
                                            placeholder="Titulo del Grupo"
                                            value={form.title || ''}
                                            name="title"
                                            onChange={updateInput}
                                            error={errors.title}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label className="text-gray-60">Descripción Producto</label>
                                        <InputField
                                            placeholder="Descripcion del Grupo"
                                            value={form.description || ''}
                                            name="description"
                                            onChange={updateInput}
                                            error={errors.description}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <label className="text-gray-60">Tipo de Formulario</label>
                                        <select className="form-control" name="type_input" value={form.type_input || 1} onChange={updateInput}>
                                            <option value='1'>Radio</option>
                                            <option value='2'>Checkbox</option>
                                            <option value='3'>Incrementar</option>
                                            <option value='4'>Otros</option>
                                        </select>

                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-group">
                                        <label className="text-gray-60">Orden</label>
                                        <InputField
                                            type="number"
                                            placeholder="Orden en Lista"
                                            value={form.priority || ''}
                                            name="priority"
                                            onChange={updateInput}
                                            error={errors.priority}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={5}>
                                    <div className="form-group">
                                        <label className="text-gray-60">¿Modifica Precio?</label>
                                        <input
                                            type="checkbox"
                                            className="form-control"
                                            checked={form.modify_price || false}
                                            name="modify_price"
                                            onChange={updateCheck}
                                        />
                                    </div>
                                </Col>
                                <Col lg={3}>
                                    <div className="form-group">
                                        <label className="text-gray-60">Obligatorio</label>
                                        <input
                                            type="checkbox"
                                            className="form-control"
                                            checked={form.required || false}
                                            name="required"
                                            placeholder="Recomendado"
                                            onChange={updateCheck}
                                        />

                                    </div>
                                </Col>
                                <Col lg={3}>
                                    <div className="form-group">
                                        <label className="text-gray-60">Estado</label>
                                        <input
                                            type="checkbox"
                                            className="form-control"
                                            checked={form.status || false}
                                            name="status"
                                            placeholder="Recomendado"
                                            onChange={updateCheck}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>

                                <Col lg={6}>
                                    <button
                                        className='btn btn-secondary'
                                        title='Cerrar'
                                        onClick={closeModalTmp}>banner</button>
                                </Col>
                                <Col lg={6}>
                                    <button
                                        title='Guardar'
                                        className='btn btn-success'
                                        onClick={onSubmit} >Guardar</button>
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>

        </Modal>
    )
}

const mapsState = (props) => {
    return {
        errors: props.modifier.errors,
        selected: props.modifier.selected,
        fetching: props.modifier.fetching_create
    }

}

export default connect(mapsState, { createGroup })(ModalGroup)