import React, { useEffect, useState } from 'react'
import { Badge, Card, Col, Container, ListGroup, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getListTemplate, onCreateTemplate, onEditTemplate } from '../../Redux/requisitionDuck';
import InputField from '../Components/Widgets/InputField';
import RowProductTemplate from './row.product.template';


const initState = { name: '' }
const ListTemplateRequisition = ({ getListTemplate, onCreateTemplate, fetching_template, data_template,
    onEditTemplate, fetching_template_create }) => {

    const [isNew, setIsNew] = useState(true)
    const [isAdd, setIsAdd] = useState(false)
    const [form, setForm] = useState(initState)
    const [selected, setSelected] = useState({})
    const [listProducts, setListProducts] = useState([])
    const [list, setList] = useState([])

    useEffect(() => {
        getListTemplate()
    }, [])

    useEffect(() => {
        if (fetching_template_create === 2) {
            setForm(initState)
            setListProducts([])
            getListTemplate()
            setIsNew(true)
        }
    }, [fetching_template_create])

    useEffect(() => {
        if (fetching_template === 2) {
            setList(data_template)
        }
    }, [fetching_template])


    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onAddItem = (item) => {
        let validate = listProducts.filter(d => d.product.id === item.product.id && d.deleted === undefined)

        if (validate.length > 0) {
            alert("Producto ya existe");

            return;
        }

        setListProducts([
            ...listProducts,
            item
        ])

        setIsAdd(false)

    }

    const onDelete = (form) => {
        if (form._id === undefined) {
            setIsNew(false)
        }
    }

    const onSuccess = () => {
        let _form = form

        if (form.name !== '') {
            _form.list_products = listProducts

            if (listProducts.length > 0) {
                if (form.id === undefined) {
                    onCreateTemplate(_form)
                } else {
                    onEditTemplate(_form)
                }
            } else {
                alert("Agrega Productos")
            }
        } else {
            alert("Agrega un nombre")
        }
    }

    const onDeleteRow = (item) => {
        console.log('item', item);

        if (item.id !== undefined) {
            let list = listProducts.map(d => {
                if (d.id === item.id) {
                    d.deleted = true;
                }

                return d;
            });

            setListProducts(list)
        } else {
            setListProducts(listProducts.filter(d => d._id !== item._id));
        }


    }

    const onSelectRow = (item) => {

        if (item.id === selected.id) {
            setSelected({})
            setForm(initState)
            setListProducts([])
            setIsNew(true)
        } else {
            let { detail } = item
            setSelected(item)
            setForm(item)
            setListProducts(detail)
            setIsNew(false)
        }

    }

    const onCancelEdit = () => {
        setSelected({})
        setForm(initState)
        setListProducts([])
        setIsNew(true)
    }

    return (
        <Container fluid>
            <Row>
                <Col lg={5}>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col><h4>Listado</h4></Col>
                                <Col lg={3}>
                                    <button className='btn btn-sm btn-info' onClick={() => setIsNew(true)}>Crear</button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <ListGroup as="ul">
                                {list.length > 0 ? list.map((d, k) =>
                                    <ListGroup.Item key={k} as="li"
                                        className={`${selected.id === d.id ? "active" : ''} d-flex justify-content-between align-items-start`}
                                        onClick={() => onSelectRow(d)}>
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold">{d.name}</div>
                                        </div>
                                        <Badge variant="primary" pill>
                                            {d.detail.length}
                                        </Badge>
                                    </ListGroup.Item>) :
                                    <ListGroup.Item as="li" disabled>No tienes Listados creados</ListGroup.Item>}
                                {/* <ListGroup.Item as="li" active>
                                    Cras justo odio
                                </ListGroup.Item>
                                <ListGroup.Item as="li">Dapibus ac facilisis in</ListGroup.Item>
                                <ListGroup.Item as="li">Porta ac consectetur ac</ListGroup.Item> */}
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header>
                                    <Row>
                                        <Col><h4>Plantilla</h4></Col>
                                        <Col lg={3}>
                                            <button className={`btn btn-sm btn-${isNew ? 'success' : 'info'}`} onClick={onSuccess}>{isNew ? 'Guardar' : 'Editar'}</button>
                                            {!isNew && <button className='btn btn-sm btn-danger mx-2' onClick={onCancelEdit}>Cancelar</button>}
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body>
                                    <div className="form-group">
                                        <label>Nombre</label>
                                        <InputField
                                            placeholder="Agrega el nombre de la Plantilla"
                                            value={form.name}
                                            name="name"
                                            onChange={updateInput}
                                        // error={errors.document}
                                        />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header>
                                    <Row>
                                        <Col><h4>Productos Asociados</h4></Col>
                                    </Row>

                                </Card.Header>
                                <Card.Body>
                                    <Table className="table table-sm table-borderd">
                                        <thead className='thead-dark'>
                                            <tr>
                                                <th>Producto</th>
                                                <th>Cantidad</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {listProducts.length > 0 ? listProducts.filter(d => d.deleted === undefined).map((d, k) => <tr key={k}>
                                                <td>{d.product.name}</td>
                                                <td>{d.amount}</td>
                                                <td><button className='btn btn-danger btn-sm' onClick={() => onDeleteRow(d)}>Del</button></td>
                                            </tr>) : <tr><td>No Tienes Productos</td></tr>}
                                            {isAdd && <RowProductTemplate formEdit={{}} onAddItem={onAddItem} onDelete={onDelete} />}
                                            {!isAdd && <tr>
                                                <td colSpan={3}><button className='btn btn-sm btn-info' onClick={() => setIsAdd(true)}>Agregar</button></td>
                                            </tr>}

                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>

                        </Col>
                    </Row>
                </Col>
            </Row>

        </Container >
    )
}

const mapsState = (props) => {
    let { requisition } = props
    return {
        fetching_template: requisition.fetching_template,
        data_template: requisition.data_template,
        fetching_template_create: requisition.fetching_template_create,
    }
}
export default connect(mapsState, { getListTemplate, onCreateTemplate, onEditTemplate })(ListTemplateRequisition);