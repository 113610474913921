import moment from 'moment';
import React, { useState } from 'react'
import { Table } from 'react-bootstrap';
import { formatNumber } from '../../utils';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const ItemDetailIntern = ({ item }) => {
    const [show, setShow] = useState(false)
    return (
        <>
            <tr onClick={() => setShow(!show)}>
                <td>{item.concepto}</td>
                <td align="right">{item.quantity}</td>
                <td align="right">{formatNumber(item.total)}</td>
            </tr>{
                show &&
                <tr>
                    <td colSpan={3}>
                        <Table className='table-sm table-hover table-bordered'>
                            <thead className='thead-dark'>
                                <tr>
                                    <th>Fecha</th>
                                    <th>Observación</th>
                                    <th>Valor</th>
                                </tr>
                            </thead>
                            <tbody>
                                {item.detail.map((d, k) => <tr key={k}>
                                    <td>
                                        <Link to={`/ingresos/${moment(d.registration_date).format("YYYY-MM-DD")}/check`}>{moment(d.registration_date).format("YYYY-MM-DD")}</Link>
                                    </td>
                                    <td>{d.observation}</td>
                                    <td align='right'>{formatNumber(d.total)}</td>
                                </tr>)}
                            </tbody>
                        </Table>
                    </td>
                </tr>}
        </>
    )
}

export default ItemDetailIntern;