import React, { useState } from 'react'
import { Col } from 'react-bootstrap';
import { calculateCost, formatNumber } from '../../utils';

const ItemTransformation = ({ row, onDelete, totalTransform, amountUsed, onCancel, notifyUpdate, isEdit }) => {

    const [show, setShow] = useState(false)
    const [form, setForm] = useState({ amount_generate: 0, amount_used: 0 })

    let { product } = row
    let { measure_unit, inventory } = product
    let label = product.label === undefined ? product.name : product.label

    const onEdit = () => {
        setShow(true)
        setForm({
            ...form,
            ["amount_generate"]: row.amount_generate,
            ["amount_used"]: row.amount_used
        })

    }

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSaveRow = () => {
        setShow(false)
        row.amount_generate = form.amount_generate
        row.amount_used = form.amount_used
        notifyUpdate(row)
    }

    let _cost = calculateCost(row.amount_used, amountUsed, totalTransform, row.amount_generate);
    console.log('row', row);
    console.log('_cost', _cost);
    let id = (row.id === undefined) ? row._id : row.id


    return (
        // <tr className={`${selected.id === id ? 'd-none' : ''}`}>
        <tr>
            <td>{label}</td>
            <td align='right'>{formatNumber(_cost)}</td>
            <td align='right'>
                {!show ? row.amount_generate : <input className='form-control' name='amount_generate' value={form.amount_generate} onChange={updateInput} />}
            </td>
            <td align='right'>
                {!show ? row.amount_used : <input className='form-control' name="amount_used" value={form.amount_used} onChange={updateInput} />}
            </td>
            {/* <td align='right'>{formatNumber(_cost * row.amount_generate)}</td> */}
            <td>

                {!show ?
                    !isEdit && <button className='btn btn-info btn-sm mx-2' onClick={() => onEdit(row)}>Editar</button>
                    :
                    <button className='btn btn-success btn-sm mx-2' onClick={() => onSaveRow(row)}>Guardar</button>
                }
                {!isEdit && <button className='btn btn-danger btn-sm' onClick={() => onDelete(row)}>Borrar</button>}


            </td>
        </tr>
    )
}
export default ItemTransformation;