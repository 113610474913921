import { getHeaders } from "../utils";

const API_URL = process.env.REACT_APP_API_URL;

let initialState = {
  fetching_list: 0,
  fetching_create: 0,
  fetching_by_id: 0,
  fetching_validation: 0,
  data: [],
  row: {},
  row_validation: {},
};

let LIST_PRESCRIPTION = "LIST_PRESCRIPTION";
let LIST_PRESCRIPTION_SUCCESS = "LIST_PRESCRIPTION_SUCCESS";
let CREATE_PRESCRIPTION = "CREATE_PRESCRIPTION";
let CREATE_PRESCRIPTION_SUCCESS = "CREATE_PRESCRIPTION_SUCCESS";
let PRESCRIPTION_BY_ID = "PRESCRIPTION_BY_ID";
let PRESCRIPTION_BY_ID_SUCCESS = "PRESCRIPTION_BY_ID_SUCCESS";
let VALIDATION_BY_ID = "VALIDATION_BY_ID";
let VALIDATION_BY_ID_SUCCESS = "VALIDATION_BY_ID_SUCCESS";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LIST_PRESCRIPTION:
      return { ...state, fetching_list: 1 };
    case LIST_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        fetching_list: 2,
        data: action.payload,
        fetching_create: 0,
      };
    case CREATE_PRESCRIPTION:
      return { ...state, fetching_create: 1, data: [] };
    case CREATE_PRESCRIPTION_SUCCESS:
      return { ...state, fetching_create: 2, row: {} };
    case PRESCRIPTION_BY_ID:
      return { ...state, fetching_by_id: 1 };
    case PRESCRIPTION_BY_ID_SUCCESS:
      return { ...state, fetching_by_id: 2, row: action.payload };
    case VALIDATION_BY_ID:
      return { ...state, fetching_validation: 1 };
    case VALIDATION_BY_ID_SUCCESS:
      return { ...state, fetching_validation: 2, row_validation: action.payload };
    default:
      return state;
  }
}

export let getPrescription = (isDetail, product) => (dispatch, getState) => {
  dispatch({
    type: LIST_PRESCRIPTION,
  });


  let branch = JSON.parse(localStorage.getItem("branch"));
  let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));

  let url = `${API_URL}/api/prescription?branch=${branch.id}&is_detail=${isDetail}&date_init=${date_init}&date_end=${date_end}`;
  url += `&product_filter_id=${product}`;


  fetch(url, {
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: LIST_PRESCRIPTION_SUCCESS,
        payload: resp.results,
      });
    });
}
export let getPrescriptionMaster = () => (dispatch, getState) => {
  dispatch({
    type: LIST_PRESCRIPTION,
  });


  let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));
  let branch = JSON.parse(localStorage.getItem("branch"));

  let url = `${API_URL}/api/prescription-master?date_init=${date_init}&date_end=${date_end}&branch=${branch.id}`;


  fetch(url, {
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: LIST_PRESCRIPTION_SUCCESS,
        payload: resp.results,
      });
    });
};

export let getPrescriptionById = (id, type) => (dispatch, getState) => {
  dispatch({
    type: PRESCRIPTION_BY_ID,
  });

  let branch = JSON.parse(localStorage.getItem("branch"));

  fetch(`${API_URL}/api/prescription/${id}/${type}?branch=${branch.id}`, {
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: PRESCRIPTION_BY_ID_SUCCESS,
        payload: resp,
      });
    });
};

export let getPrescriptionByProductId = (id, type) => (dispatch, getState) => {
  dispatch({
    type: VALIDATION_BY_ID,
  });

  let branch = JSON.parse(localStorage.getItem("branch"));
  let { date_init } = JSON.parse(localStorage.getItem("date"));


  fetch(`${API_URL}/api/prescription-validate/${id}/${type}?branch=${branch.id}&date=${date_init}`, {
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: VALIDATION_BY_ID_SUCCESS,
        payload: resp,
      });
    });
};

export let onCreatePrescription = (form) => (dispatch, getState) => {
  dispatch({
    type: CREATE_PRESCRIPTION,
  });

  let branch = JSON.parse(localStorage.branch);
  let { date_init } = JSON.parse(localStorage.date);

  form.branch_id = branch.id;
  form.date_init = date_init;

  fetch(`${API_URL}/api/prescription`, {
    method: "POST",
    body: JSON.stringify(form),
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: CREATE_PRESCRIPTION_SUCCESS,
      });
    });
};

export let onCreatePrescriptionMaster = (list) => (dispatch, getState) => {
  dispatch({
    type: CREATE_PRESCRIPTION,
  });

  let branch = JSON.parse(localStorage.branch);

  let form = { list, branch_id: branch.id }

  fetch(`${API_URL}/api/prescription-master`, {
    method: "POST",
    body: JSON.stringify(form),
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: CREATE_PRESCRIPTION_SUCCESS,
      });
    });
};

export let onUpdatePrescription = (form) => (dispatch, getState) => {
  dispatch({
    type: CREATE_PRESCRIPTION,
  });

  let branch = JSON.parse(localStorage.branch);
  let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));

  form.branch_id = branch.id;

  fetch(
    `${API_URL}/api/prescription/${form.id}?date_init=${date_init}&date_end=${date_end}`,
    {
      method: "PUT",
      body: JSON.stringify(form),
      headers: getHeaders(),
    }
  )
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: CREATE_PRESCRIPTION_SUCCESS,
      });
    });
};
