import { getHeaders } from "../utils";
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

let initialState = {
  fetching: 0,
  fetching_list: 0,
  fetching_create: 0,
  fetching_by_id: 0,
  data: [],
  row: {},
  errors: {},
};

let LIST_COURTESY = "LIST_COURTESY";
let LIST_COURTESY_SUCCESS = "LIST_COURTESY_SUCCESS";
let CREATE_COURTESY = "CREATE_COURTESY";
let CREATE_COURTESY_SUCCESS = "CREATE_COURTESY_SUCCESS";
let COURTESY_BY_ID = "COURTESY_BY_ID";
let COURTESY_BY_ID_SUCCESS = "COURTESY_BY_ID_SUCCESS";
let CREATE_COURTESY_ERROR = "CREATE_COURTESY_ERROR";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LIST_COURTESY:
      return { ...state, fetching_list: 1 };
    case LIST_COURTESY_SUCCESS:
      return { ...state, fetching_list: 2, data: action.payload };
    case CREATE_COURTESY:
      return { ...state, fetching_create: 1 };
    case CREATE_COURTESY_SUCCESS:
      return { ...state, fetching_create: 2 };
    case COURTESY_BY_ID:
      return { ...state, fetching_by_id: 1 };
    case COURTESY_BY_ID_SUCCESS:
      return { ...state, fetching_by_id: 2, row: action.payload };
    case CREATE_COURTESY_ERROR:
      return { ...state, fetching_create: 3 };
    default:
      return state;
  }
}

export let getListCourtesy = () => (dispatch, getState) => {
  dispatch({
    type: LIST_COURTESY,
  });

  if (getState().user.loggedIn) {
    axios
      .get(`${API_URL}/api/courtesy`, {
        headers: getHeaders(),
      })
      .then((resp) => {
        dispatch({
          type: LIST_COURTESY_SUCCESS,
          payload: resp.data.results,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    console.log("no Ingreso");
  }
};
export let getCourtesyById = (id) => (dispatch, getState) => {
  dispatch({
    type: COURTESY_BY_ID,
  });

  if (getState().user.loggedIn) {
    axios
      .get(`${API_URL}/api/courtesy/${id}`, {
        headers: getHeaders(),
      })
      .then((resp) => {
        dispatch({
          type: COURTESY_BY_ID_SUCCESS,
          payload: resp.data,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    console.log("no Ingreso");
  }
};

export let onCreateCourtesy = (form) => (dispatch, getState) => {
  dispatch({
    type: CREATE_COURTESY,
  });

  let branch = JSON.parse(localStorage.getItem("branch"));

  if (getState().user.loggedIn) {
    form.branch_id = branch.id;
    axios
      .post(`${API_URL}/api/courtesy`, form, {
        headers: getHeaders(),
      })
      .then((resp) => {
        dispatch({
          type: CREATE_COURTESY,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    console.log("no Ingreso");
  }
};
export let onCreateCourtesyDetail = (form) => (dispatch, getState) => {
  dispatch({
    type: CREATE_COURTESY,
  });

  let branch = JSON.parse(localStorage.getItem("branch"));

  if (getState().user.loggedIn) {
    form.branch_id = branch.id;
    axios
      .post(`${API_URL}/api/courtesy-detail`, form, {
        headers: getHeaders(),
      })
      .then((resp) => {
        if (resp.data.ok === true) {
          dispatch({
            type: CREATE_COURTESY_SUCCESS,
          });
        } else {
          dispatch({
            type: CREATE_COURTESY_ERROR,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    console.log("no Ingreso");
  }
};
