import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { AiFillFilter } from 'react-icons/ai';
import { connect } from 'react-redux';
import { getPayroll } from '../../Redux/payrollDuck'

import { getWorkersActives, saveFilter } from '../../Redux/workerDuck'
import { getParameterBySegment } from '../../Redux/parameterDuck'
import ListLiquidate from './list.liquidate';
import ListWorker from './list.worker';
import moment from 'moment';
import TemplateMenu from '../../template/menu.template'
import ListPayments from './list.payments';


const NominaPage = ({ getWorkersActives, workers, fetching_list, getParameterBySegment, getPayroll, saveFilter, fetching_by_segment, data_segment }) => {
    const [listWorker, setListWorker] = useState([])
    const [listMethod, setListMethods] = useState([]);
    const [form, setForm] = useState({ date_init: moment().format("YYYY-MM-01"), date_end: moment().format("YYYY-MM-DD"), status_selected: 0 })
    const [keyTab, setKeyTab] = useState('home')

    const [modalFilter, setModalFilter] = useState(false)

    useEffect(() => {
        getWorkersActives()
        getPayroll()
        getParameterBySegment("methods_payment")
    }, [])

    useEffect(() => {
        if (fetching_by_segment === 2) {
            setListMethods(data_segment)
        }
    }, [fetching_by_segment])

    useEffect(() => {
        if (fetching_list === 2) {
            setListWorker(workers)
        }
    }, [fetching_list])


    const onFilterData = () => {

        let q = `&status=true`;


        if (form.status_selected !== '' && form.status_selected !== undefined) {
            q += `&status_id=${form.status_selected}`
        }
        if (form.date_init !== '' && form.date_init !== undefined) {
            q += `&date_init=${form.date_init}`
        }
        if (form.date_end !== '' && form.date_end !== undefined) {
            q += `&date_end=${form.date_end}`
        }

        if (form.status_selected === 2) {
            setKeyTab("home")
        }

        saveFilter(q)
        getWorkersActives(q)
        getPayroll(q)
    }


    const updateField = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const setKeyTabTmp = (e) => {
        setKeyTab(e)
    }



    return (
        <TemplateMenu>

            <Container fluid>
                <Row className='my-2'>
                    <Col lg={3}><h3>Listado trabajadores</h3></Col>
                    <Col lg={8}>
                        <Row className='d-flex align-items-center'>
                            <Col>
                                <div className='form-group'>
                                    <label>Fecha Inicio</label>
                                    <input type={"date"} name="date_init" className='form-control' value={form.date_init} onChange={updateField} />
                                </div>

                            </Col>
                            <Col>
                                <div className='form-group'>
                                    <label>Fecha Fin</label>
                                    <input type={"date"} name="date_end" className='form-control' value={form.date_end} onChange={updateField} />
                                </div>
                            </Col>
                            <Col>
                                <div className='form-group'>
                                    <label>Estado</label>
                                    <select className='form-control' name='status_selected' value={form.status_selected} onChange={updateField}>
                                        <option value='0'>Seleccionar Todos</option>
                                        <option value='1'>Pendiente por Liquidar</option>
                                        <option value='2'>Liquidado</option>
                                    </select>
                                </div>
                            </Col>
                            <Col className=''>
                                <button className='btn btn-success' onClick={onFilterData}>Filtrar</button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Tabs activeKey={keyTab} onSelect={(k) => setKeyTabTmp(k)} id="uncontrolled-tab-example" className="mb-3">
                            <Tab eventKey="payments" title="Listado de Pagos">
                                <ListPayments />
                            </Tab>
                            <Tab eventKey="home" title="Para Liquidar">
                                <ListLiquidate listMethod={listMethod} status={form.status_selected} />
                            </Tab>
                            <Tab eventKey="profile" title="Listado de Trabajadores">
                                <ListWorker listWorker={listWorker} />
                            </Tab>

                        </Tabs>
                    </Col>
                </Row>

            </Container>
        </TemplateMenu>
    )
}

const mapsState = (props) => {

    return {
        fetching_list: props.worker.fetching_list,
        workers: props.worker.data,
        fetching_branch: props.branchoffice.fetching,
        branch_offices: props.branchoffice.data,
        fetching_filter: props.worker.fetching_filter,
        fetching_by_segment: props.parameter.fetching_by_segment,
        data_segment: props.parameter.data_segment,
        filter: props.worker.filter,


    }
}

export default connect(mapsState, { getWorkersActives, getPayroll, saveFilter, getParameterBySegment })(NominaPage);