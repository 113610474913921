import React, { useState } from 'react'
import { Col, ListGroup, Row } from 'react-bootstrap'
import ItemSubMenu from './item.submenu'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const ItemMenu = ({ item, onClick }) => {
    let { permission_role, children } = item

    const [value, setValue] = useState(permission_role === null ? false : true)

    const onChange = () => {
        item.checked = !value;
        setValue(!value)
    }

    const onDragEnd=(item)=>{
        console.log('item',item);
        
    }

    return (
        <Col lg={2}>

            <ListGroup>
                <ListGroup.Item variant="secondary">
                    <Row>
                        <Col lg={10}>({item.priority}) {item.title} </Col>
                        <Col className='d-flex justify-content-end'><input type={"checkbox"} className="pl-2" checked={value} onChange={onChange} /></Col>
                    </Row>

                </ListGroup.Item>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId='permissions'>
                        {(provided) => (
                            <div className='permissions' {...provided.droppableProps} ref={provided.innerRef}>
                                {children.map((c, index) => {
                                    return (
                                        <Draggable draggableId={`${item.id}_${c.id}`} index={index} key={c.id}>
                                            {(provided) => (
                                                // <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>asdas</li>
                                                <ListGroup.Item className="cursor-pointer" 
                                                {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                    {/* <Row>
                                                        <Col lg={10}>{c.priority}. {c.title} </Col>
                                                        <Col className='d-flex justify-content-end'><input type={"checkbox"} className="pl-2" checked={value} onChange={onChange} /></Col>
                                                        <Col className='d-flex justify-content-end'>
                                                            <input type={"checkbox"} className="pl-2" checked={value} />
                                                            </Col>
                                                    </Row> */}
                                                    <ItemSubMenu item={c} onClick={onClick}
                                                 />

                                                </ListGroup.Item>

                                                // <Draggable key={c.id} draggableId={c.id} index={index}>
                                                //     {(provived) => <ItemSubMenu item={c} onClick={onClick}
                                                //         {...provided.droppableProps} ref={provided.innerRef} />}

                                                // </Draggable>
                                            )}
                                        </Draggable>
                                    )


                                }

                                )}{provided.placeholder}
                            </div>
                        )}

                    </Droppable>
                </DragDropContext>

            </ListGroup>



        </Col >
    )
}

export default ItemMenu;