import React, { useEffect, useState } from "react";
import { Alert, Card, Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import InputField from "../Components/Widgets/InputField";
import { createIngresos } from "../../Redux/IngresosDuck";
import ModalAddIngresos from "./ModalAddIngresos";
import { getParameter, createParameter } from "../../Redux/parameterDuck";
import { getBranch } from "../../Redux/branchofficeDuck";
import {
  getWorkersActives,
  getWorkersTurnActives,
} from "../../Redux/workerDuck";
import {
  ToastProvider, useToasts,
  removeAllToasts,
} from "react-toast-notifications";
import { formatNumber } from "../../utils";
import ModalAddIngresosWorker from "./ModalAddIngresosWorker";
import moment from "moment";
import { useHistory } from "react-router-dom";
import ModalParameter from "../Components/ModalAddParameter";
import ModalAddTurn from "./modal.add.turn";
import ItemDetailPayment from "./item.detail.payment";
import ModalConfirmation from "../Components/modal.confirmation";
import TemplateMenu from "../../template/menu.template";
import CurrencyInput from "react-currency-input-field";
import InputFieldCurrency from "../Components/Widgets/InputFieldCurrency";
import ModalNovelty from "./modal.novelty";
import 'moment/locale/es';

const initState = {
  registration_date: "",
  aldelo: null,
  intern: "",
  cancellations: null,
  cash: null,
  datafono: null,
  total_collection: null,
  nequi: null,
  daviplata: null,
  rappi: null,
  tips: null,
  amount_delivery: null,
  total_delivery: null,
  comments: "",
  branch_id: 0,
  intern_total: null,
  total_input: ''
};

const q = `?f=true`;

const CreateIngresosPage = ({
  errors,
  getParameter,
  parameters,
  createIngresos,
  fetching,
  getWorkersActives,
  workers,
  fetching_list,
  row_saved,
  createParameter,
  fetching_create,
  fetching_parameter,
  getBranch,
  fetching_branch,
  branch_offices,
  user,
  getWorkersTurnActives,
  fetching_list_turn,
  data_turn,
}) => {

  const { addToast } = useToasts();
  const [form, setForm] = useState(initState);
  const [showModal, setShowModal] = useState(false);
  const [showModalWorker, setShowModalWorker] = useState(false);
  const [showModalTurn, setShowModalTurn] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [typeData, setTypeData] = useState(0);
  const [listIntern, setListIntern] = useState([]);
  const [listTurn, setListTurn] = useState([]);
  const [listEmploy, setListEmploy] = useState([]);
  const [listParameter, setListParameter] = useState([]);
  const [listWorkers, setListWorkers] = useState([]);
  const [listWorkersTurn, setListWorkersTurn] = useState([]);
  const [listBranch, setListBranch] = useState([]);
  const [modalParam, setModalParam] = useState({
    open: false,
    title: "Pendiente",
  });
  const history = useHistory();
  const [lastParam, setLastParam] = useState({});
  const [listNovelty, setListNovelty] = useState([]);
  const [modalNovelty, setModalNovelty] = useState(false);

  useEffect(() => {
    getParameter(q);
    getWorkersActives();
    getWorkersTurnActives();
    getBranch();
  }, []);

  useEffect(() => {
    if (fetching_branch === 2) {
      setListBranch(branch_offices);
    }
  }, [fetching_branch]);
  useEffect(() => {
    if (fetching_list_turn === 2) {
      setListWorkersTurn(data_turn);
    }
  }, [fetching_list_turn]);

  useEffect(() => {
    if (fetching === 2) {
      if (row_saved.status_id === 2) {
        history.goBack();
      } else {
        addToast("Guardado con Exito", {
          appearance: "success",
          autoDismiss: true,
        });
        setForm(initState);
        setListIntern([]);
        setListEmploy([]);
        setListTurn([]);
        history.push(`/ingresos/${row_saved.id}`);
      }
    }
  }, [fetching]);

  useEffect(() => {
    if (fetching_parameter === 2) {
      setListParameter(
        parameters.filter((param) => param.segment === lastParam.segment)
      );
    }
  }, [fetching_parameter]);

  useEffect(() => {
    if (fetching_list === 2) {
      setListWorkers(workers);
    }
  }, [fetching_list]);

  useEffect(() => {
    if (fetching_create === 2) {
      setModalParam(false);
      getParameter(q);
    }
  }, [fetching_create]);

  const updateInput = (e) => {

    if (e.target.name === 'registration_date') {

    }

    // if (e.target.name === 'amount_delivery') {
    //   let value = e.target.value

    //   if (value.length < 4) {
    //     setForm({
    //       ...form,
    //       [e.target.name]: e.target.value,
    //     });
    //   }

    // } else {

    // }

    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const onHide = () => {
    setShowModal(false);
  };
  const onHideTurn = () => {
    setShowModalTurn(false);
  };
  const onHideWorker = () => {
    setShowModalWorker(false);
  };

  const showModalTmp = (title, segment, type) => {
    setTitleModal(title);
    setTypeData(type);

    let param = parameters.filter((row) => row.segment === segment);

    setListParameter(param);

    setShowModal(true);
  };

  const showModalTmpWorker = (type) => {
    setTypeData(type);
    setShowModalWorker(true);
  };

  const onSubmitModal = (form) => {
    setListIntern([...listIntern, form]);
    setShowModal(false);
  }

  const onSubmitModalTurn = (form) => {
    setListTurn([...listTurn, form]);
    setShowModalTurn(false);
  }

  const onSubmitModalWorker = (form) => {
    setListEmploy([...listEmploy, form]);
    setShowModalWorker(false);
  }

  const removeItem = (item) => {
    let data;

    if (item.type_data === 1) {
      data = [...listIntern.filter((intern) => intern._id !== item._id)];
      setListIntern(data);
    }
    if (item.type_data === 2) {
      data = [...listTurn.filter((intern) => intern._id !== item._id)];
      setListTurn(data);
    }
  };

  const removeItemWorker = (item) => {
    let data;
    data = [...listEmploy.filter((row) => row._id !== item._id)];
    setListEmploy(data);
  };

  const removeItemWorkerTurn = (item) => {
    let data;
    data = [...listTurn.filter((row) => row._id !== item._id)];
    setListTurn(data);
  };

  const printIntern = (row, key) => {
    return (
      <tr key={key}>
        <td>{row.text}</td>
        <td>{row.observation}</td>
        <td align="right">{formatNumber(row.amount)}</td>
        <td>
          <button className="btn btn-sm btn-danger" onClick={() => removeItem(row)}>X</button>
        </td>
      </tr>
    );
  };

  const printEmploy = (row, key) => {
    let { worker } = row;
    return (
      <tr key={key}>
        <td>{worker.name}</td>
        <td align="right">{formatNumber(row.amount)}</td>
        <td>
          <button className="btn btn-sm btn-danger" onClick={() => removeItemWorker(row)}>X</button>
        </td>
      </tr>
    );
  };

  const onSubmit = (status) => {
    if (status === 2) {
      console.log('listNovelty', listNovelty);

      if (listNovelty.length === 0) {
        alert("No puede Finalizar el cierre sin agregar Novedades")
        return;
      }
    }
    setForm({
      ...form,
      ["status_id"]: status,
    });

    setModalConfirm(true);
  };

  const onConfirmClose = () => {
    let _form = form;
    _form.intern = listIntern;
    _form.turn = listTurn;
    _form.employee = listEmploy;
    _form.novelty = listNovelty;

    setModalConfirm(false);

    createIngresos(_form);
  };

  const onOpenParameter = (title, dataType) => {
    let _title =
      dataType === 1 ? "Agregar otro Concepto" : "Pago otro Turneros";

    setModalParam({
      ...modalParam,
      open: true,
      title: _title,
      dataType,
    });
  };

  const onCreateParameter = (form) => {
    form.segment = "payment_intern";
    form.priority = 1;
    setLastParam(form);
    createParameter(form);
  };

  const onAddNovelty = (data) => {
    console.log('data', data);

    setListNovelty([
      ...listNovelty,
      data
    ])
    setModalNovelty(false)
  }
  const onDeleteNovelty = (data) => {
    let _list = listNovelty.filter(d => d.uuid !== data.uuid)
    setListNovelty(_list)

  }

  let total_sales =
    form.aldelo -
    (listEmploy.reduce((a, b) => (a = a + parseFloat(b.amount)), 0) || 0) -
    (listIntern.reduce((a, b) => (a = a + parseFloat(b.amount)), 0) || 0) -
    listTurn.reduce((a, b) => (a = a + parseFloat(b.amount)), 0) || 0;
  let total_recaudado =
    parseFloat(form.cash || 0) +
    parseFloat(form.datafono || 0) +
    parseFloat(form.nequi || 0) +
    parseFloat(form.daviplata || 0) +
    parseFloat(form.rappi || 0) +
    parseFloat(form.credit_home || 0);

  let diference = total_recaudado - total_sales;

  return (
    <TemplateMenu>
      <Container fluid className="pb-5">
        <Row className="my-2">
          <Col>
            <h3>Registro de Cierres</h3>
          </Col>
        </Row>
        <Row>
          <Col lg={9}>
            <button className="btn btn-info" onClick={() => onSubmit(1)}>
              Guardar
            </button>
          </Col>
          <Col lg={3} className="d-flex justify-content-end">
            <button className="btn btn-success" onClick={() => onSubmit(2)}>
              Guardar y finalizar
            </button>
          </Col>
        </Row>
        {Object.keys(errors).length > 0 && <Row className="my-1">
          <Col className="text-center">
            <Alert variant="danger">
              <ul>
                {Object.values(errors).map((d, k) => <li key={k}>{d}</li>)}
              </ul>
            </Alert>
          </Col>
        </Row>}
        <Row>
          <Col>
            <hr />
          </Col>
        </Row>
        <Row className="mt-1">
          <Col>
            <Row>
              <Col lg={4}>Fecha</Col>
              <Col>
                <input
                  className="form-control"
                  type="date"
                  value={form.registration_date || ""}
                  name="registration_date"
                  min={moment().subtract(1, "d").format("YYYY-MM-DD")}
                  max={moment().format("YYYY-MM-DD")}
                  onChange={updateInput}
                />
                {errors.registration_date !== undefined && (
                  <label className="error-label">
                    {errors.registration_date}
                  </label>
                )}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg={4}>Ventas POS</Col>
              <Col>
                <InputFieldCurrency
                  name={"aldelo"}
                  placeholder="Ventas POS"
                  error={errors.aldelo}
                  onValueChange={(value, name) =>
                    setForm({ ...form, ["aldelo"]: value })
                  }
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg={4}>Pagos Internos</Col>
              <Col>
                <InputField
                  placeholder="Pagos Internos"
                  textRight={true}
                  readOnly={true}
                  value={formatNumber(
                    listIntern.reduce(
                      (a, b) => (a = a + parseFloat(b.amount)),
                      0
                    )
                  )}
                  name="intern"
                  onChange={updateInput}
                  error={errors.intern}
                />
              </Col>
            </Row>

            <Row className="my-2">
              <Col lg={4}>Pagos Empleados</Col>
              <Col>
                <InputField
                  placeholder="Pagos Empleados"
                  textRight={true}
                  readOnly={true}
                  value={formatNumber(
                    listEmploy.reduce(
                      (a, b) => (a = a + parseFloat(b.amount)),
                      0
                    )
                  )}
                  name="intern"
                />
              </Col>
            </Row>
            <Row className="my-2">
              <Col lg={4}>Pagos Turneros</Col>
              <Col>
                <InputField
                  placeholder="Pagos Turneros"
                  readOnly={true}
                  textRight={true}
                  value={formatNumber(
                    listTurn.reduce((a, b) => (a = a + parseFloat(b.amount)), 0)
                  )}
                  name="turn"
                  onChange={updateInput}
                  error={errors.turn}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={4}>Ventas Totales</Col>
              <Col>
                <InputField
                  placeholder="Ventas totales"
                  readOnly={true}
                  textRight={true}
                  name="total"
                  value={formatNumber(total_sales)}
                  onChange={updateInput}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={4}>Efectivo</Col>
              <Col>
                <InputFieldCurrency
                  name={"cash"}
                  placeholder="Efectivo"
                  error={errors.cash}
                  onValueChange={(value, name) =>
                    setForm({ ...form, ["cash"]: value })
                  }
                />
              </Col>
            </Row>
            <Row className="my-1">
              <Col lg={4}>DataFono</Col>
              <Col>
                <InputFieldCurrency
                  name={"datafono"}
                  placeholder="Datafono"
                  error={errors.datafono}
                  onValueChange={(value, name) =>
                    setForm({ ...form, ["datafono"]: value })
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col lg={4}>Bancolombia</Col>
              <Col>
                <InputFieldCurrency
                  name={"nequi"}
                  placeholder="Bancolombia"
                  error={errors.nequi}
                  onValueChange={(value, name) =>
                    setForm({ ...form, ["nequi"]: value })
                  }
                />
              </Col>
            </Row>
            <Row className="my-1">
              <Col lg={4}>Davivienda</Col>

              <Col>
                <InputFieldCurrency
                  name={"daviplata"}
                  placeholder="Davivienda"
                  error={errors.daviplata}
                  onValueChange={(value, name) =>
                    setForm({ ...form, ["daviplata"]: value })
                  }
                />
              </Col>
            </Row>
            <Row className="my-1">
              <Col lg={4}>Rappi</Col>
              <Col>
                <InputFieldCurrency
                  name={"rappi"}
                  placeholder="Rappi"
                  error={errors.rappi}
                  onValueChange={(value, name) =>
                    setForm({ ...form, ["rappi"]: value })
                  }
                />
              </Col>
            </Row>
            <Row className="my-1">
              <Col lg={4}>Credito de la Casa</Col>
              <Col>
                <InputFieldCurrency
                  name={"credit_home"}
                  placeholder="Credito de la casa"
                  error={errors.credit_home}
                  onValueChange={(value, name) =>
                    setForm({ ...form, ["credit_home"]: value })
                  }
                />
              </Col>
            </Row>

            <Row className="mt-4">
              <Col lg={4}>Total Recaudo</Col>
              <Col>
                <InputField
                  placeholder="Total Recaudo"
                  value={formatNumber(total_recaudado)}
                  readOnly={true}
                  textRight={true}
                  name="total_collection"
                  onChange={updateInput}
                  error={errors.total_collection}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg={4}>Diferencia</Col>
              <Col>
                <input
                  className={`form-control text-right ${diference === 0 ? "input-success" : "input-danger"
                    }`}
                  placeholder="Total Recaudo"
                  readOnly={true}
                  value={formatNumber(diference)}
                  name="total_collection"
                  onChange={updateInput}
                  error={errors.total_collection}
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col lg={4}>Cantidad Domicilios</Col>
              <Col>
                <InputField
                  type="number"
                  placeholder="Cantidad Domicilios"
                  value={form.amount_delivery || ""}
                  name="amount_delivery"
                  onChange={updateInput}
                  error={errors.amount_delivery}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={4}>Monto Total Domicilios</Col>
              <Col>
                <InputField
                  type="number"
                  placeholder="Monto Total Domicilios"
                  value={form.total_delivery || ""}
                  name="total_delivery"
                  onChange={updateInput}
                  error={errors.total_delivery}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={4}>Número de Entradas</Col>
              <Col>
                <InputField
                  type="number"
                  placeholder="Número de Entradas"
                  value={form.total_input || ""}
                  name="total_input"
                  onChange={updateInput}
                  error={errors.total_input}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={4}>Cancelaciones</Col>
              <Col>
                <InputField
                  type="number"
                  placeholder="Cancelaciones"
                  value={form.cancellations || ""}
                  name="cancellations"
                  onChange={updateInput}
                  error={errors.cancellations}
                />
              </Col>
            </Row>

            <Row className="mt-2">
              <Col lg={4}>Propinas</Col>
              <Col>
                <InputFieldCurrency
                  name={"tips"}
                  placeholder="Propinas"
                  error={errors.tips}
                  onValueChange={(value, name) =>
                    setForm({ ...form, ["tips"]: value })
                  }
                />
              </Col>
            </Row>

            <Row className="mt-2">
              <Col lg={4}>Descuentos</Col>

              <Col>
                <InputFieldCurrency
                  name={"discount"}
                  placeholder="Descuentos"
                  error={errors.discount}
                  onValueChange={(value, name) =>
                    setForm({ ...form, ["discount"]: value })
                  }
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg={4}>Comentarios</Col>

              <Col>
                <textarea
                  className="form-control"
                  name="comments"
                  onChange={updateInput}
                  placeholder="Agrega Comentarios"
                ></textarea>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col>
                        <div className="form-group">
                          <label>TOTAL PAGOS INTERNOS POS</label>
                          <InputFieldCurrency
                            name={"intern_total"}
                            value={form.intern_total}
                            placeholder="Valor total PAGOS INTERNOS"
                            error={errors.intern_total}
                            onValueChange={(value, name) =>
                              setForm({ ...form, ["intern_total"]: value })
                            } />

                        </div>
                      </Col>
                    </Row>

                    <Row className="mb-1">
                      <Col>DIFERENCIA</Col>
                      <Col className={`${(form.intern_total - listIntern.reduce(
                        (a, b) => (a = a + parseFloat(b.amount)), 0)) > 0 ? 'text-danger' : 'text-success'} text-right`}>
                        <b>{formatNumber(form.intern_total -
                          (listIntern.reduce((a, b) => (a = a + parseFloat(b.amount)), 0) +
                            listEmploy.reduce((a, b) => (a = a + parseFloat(b.amount)), 0) +
                            listTurn.reduce(
                              (a, b) => (a = a + parseFloat(b.amount)),
                              0
                            ))
                        )}</b>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row className="mt-1">
              <Col lg={9}>Agregar Pagos Internos</Col>
              <Col className="d-flex justify-content-end">
                <button className="btn btn-info" onClick={() => showModalTmp("Pagos Internos", "payment_intern", 1)}>
                  +
                </button>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <table className="table table-bordered table-striped table-sm">
                  <thead>
                    <tr>
                      <th>Descripción</th>
                      <th>Observación</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listIntern.map(printIntern)}
                    <tr>
                      <td colSpan={2}>
                        <b>Total</b>
                      </td>
                      <td align="right">
                        {formatNumber(
                          listIntern.reduce(
                            (a, b) => (a = a + parseFloat(b.amount)),
                            0
                          )
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={9}>Agregar pago a Empleados</Col>
              <Col className="d-flex justify-content-end">
                <button className="btn btn-info" onClick={() => showModalTmpWorker(2)}>
                  +
                </button>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <table className="table table-bordered table-striped table-sm">
                  <thead>
                    <tr>
                      <th>Descripción</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listEmploy.map(printEmploy)}
                    <tr>
                      <td>
                        <b>Total</b>
                      </td>
                      <td align="right">
                        {formatNumber(listEmploy.reduce((a, b) => (a = a + parseFloat(b.amount)), 0))}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>

            <Row>
              <Col lg={9}>Agregar pago a Turneros</Col>
              <Col className="d-flex justify-content-end">
                <button
                  className="btn btn-info"
                  onClick={() => setShowModalTurn(true)}>
                  +
                </button>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <table className="table table-bordered table-striped table-sm">
                  <thead>
                    <tr>
                      <th>Descripción</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listTurn.map((row, k) => (
                      <ItemDetailPayment
                        row={row}
                        key={k}
                        onRemoveItem={() => removeItemWorkerTurn(row)}
                      />
                    ))}
                    <tr>
                      <td>
                        <b>Total</b>
                      </td>
                      <td align="right">
                        {formatNumber(listTurn.reduce((a, b) => (a = a + parseFloat(b.amount)), 0))}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col lg={9}>Novedades del día <span className="text-danger">(Obligatorio)</span></Col>
              <Col className="d-flex justify-content-end">
                <button className="btn btn-info" onClick={() => setModalNovelty(true)}>+</button>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <table className="table table-bordered table-striped table-sm">
                  <thead>
                    <tr>
                      <th>Descripción</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {listNovelty.length > 0 ? listNovelty.map((row, k) => (
                      <tr key={k}>
                        <td>{row.observation}</td>
                        <td>
                          <button className="btn btn-sm btn-danger" onClick={() => onDeleteNovelty(row)}>X</button>
                        </td>
                      </tr>
                    )) : <tr>
                      <td colSpan={2} align="center">No tienes Novedades</td>
                    </tr>}

                  </tbody>
                </table>
              </Col>
            </Row>
          </Col>
        </Row>
        <ModalAddTurn
          showModal={showModalTurn}
          onHide={onHideTurn}
          onSubmit={onSubmitModalTurn}
          listWorkerTurn={listWorkersTurn}
        />

        <ModalAddIngresos
          showModal={showModal}
          onHide={onHide}
          title={titleModal}
          typeData={typeData}
          onSubmit={onSubmitModal}
          onOpenParameter={onOpenParameter}
          parameters={listParameter}
        />

        <ModalAddIngresosWorker
          showModal={showModalWorker}
          onHide={onHideWorker}
          typeData={typeData}
          onSubmit={onSubmitModalWorker}
          workers={listWorkers}
        />

        <ModalParameter
          show={modalParam.open}
          title={modalParam.title}
          dataType={modalParam.dataType}
          onHide={() => setModalParam({ ...modalParam, open: false })}
          onSuccess={onCreateParameter}
        />

        <ModalConfirmation
          show={modalConfirm}
          text={
            <span>
              {`¿Deseas Realizar el Cierre de`}
              <br />
              <h2>{`${moment(form.registration_date).format("dddd D [de] MMMM YYYY")}?`.toUpperCase()}</h2>
            </span>
          }
          onHide={() => setModalConfirm(false)}
          onSubmit={onConfirmClose}
        />

        <ModalNovelty show={modalNovelty} onHide={() => setModalNovelty(false)} onSubmit={onAddNovelty} />
      </Container>
    </TemplateMenu>
  );
};

const mapsState = (props) => {
  let { ingresos, parameter, worker, branchoffice, user } = props
  return {
    errors: ingresos.errors,
    selected: ingresos.selected,
    row_saved: ingresos.row,
    fetching: ingresos.fetching_create,
    parameters: parameter.data,
    fetching_parameter: parameter.fetching,
    workers: worker.data,
    data_turn: worker.data_turn,
    fetching_list: worker.fetching_list,
    fetching_list_turn: worker.fetching_list_turn,
    fetching_create: parameter.fetching_create,
    branch_offices: branchoffice.data,
    fetching_branch: branchoffice.fetching,
    user: user.data,
  };
};

export default connect(mapsState, {
  createIngresos,
  getParameter,
  getWorkersActives,
  createParameter,
  getBranch,
  getWorkersTurnActives,
})(CreateIngresosPage);
