import React, { useEffect, useState } from 'react'
import { Col, Modal, Row, Table } from 'react-bootstrap';
import { formatNumber } from '../../utils';
import ItemModalTax from './item.modal.tax';

const ModalTaxPage = ({ show, onHide, listFranchise, onSuccess, datafono }) => {
    const [list, setList] = useState([])
    const [total, setTotal] = useState(0)
    const [subTotal, setSubtotal] = useState(0)

    useEffect(() => {
        if (listFranchise.length > 0) {

            setList(listFranchise)
            setTotal(listFranchise.reduce((a, b) => a = a + parseFloat((b.total === null || b.total.cost === '' ? 0 : b.total.cost)), 0))
            let _subtotal = listFranchise.reduce((a, b) => a = a + parseFloat((b.total === null || b.total.cost === '' ? 0 : b.total.cost) * (b.percent / 100) || 0), 0)

            setSubtotal(_subtotal)
        }

    }, [listFranchise])


    const onUpdate = (item, value) => {
        console.log('value', value);

        let _list = list.map(l => {
            if (parseInt(l.id) === parseInt(item.id)) {
                if (value === '') {
                    l.delete = true;
                    l.total.cost = value
                } else {
                    l.total = { cost: value }
                }
            }
            return l;
        });

        setList(_list)

        setTotal(_list.filter(d => d.total !== null).reduce((a, b) => a = a + parseFloat(b.total.cost || 0), 0))
        setSubtotal(_list.reduce((a, b) => a = a + parseFloat((b.total !== null ? b.total.cost : 0) * (b.percent / 100) || 0), 0))
    }

    const onSuccessPre = () => {
        let param = { list, total }
        onSuccess(param);
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Agregar Impuesto (${formatNumber(datafono)})</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Table className='table-sm table-hover table-bordered'>
                            <thead className='thead-dark'>
                                <tr>
                                    <th>Franquicia</th>
                                    <th>Porcentaje</th>
                                    <th>Montos</th>
                                    <th>Subtotal</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.map((f, k) => <ItemModalTax item={f} key={k} onUpdate={onUpdate} />)}
                                <tr>
                                    <td colSpan={2}>SUBTOTAL</td>
                                    <td align="right">{formatNumber(total)}</td>
                                    <td align="right">{formatNumber(subTotal)}</td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>TOTAL</td>
                                    <td align="right">{formatNumber(total - subTotal)}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>


            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col><button className='btn btn-success' onClick={() => onSuccessPre()}>Guardar</button></Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalTaxPage;