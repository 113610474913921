import React, { useEffect, useState } from 'react'
import { Card, Col, ListGroup, Row } from 'react-bootstrap';
import ItemPrescriptionDetail from './item.prescription.detail';
import uuid from 'react-uuid';
import chooseimage from '../../Images/no_available.png'
import { connect } from 'react-redux';
import InputField from '../Components/Widgets/InputField';


const FormPrescriptionKitchen = ({ onSubmit, formEdit }) => {
    const [form, setForm] = useState({ title: '', image: '', category_id: 0, image_ingredient: '', image_preparation: '' })
    const [descriptions, setDescriptions] = useState({ ingredients: '', preparations: '' })

    const [listIngredients, setListIngredients] = useState([])
    const [listPreparations, setListPreparations] = useState([])
    const [listTable, setListTable] = useState([])
    const [listDelivery, setListDelivery] = useState([])
    const [video, setVideo] = useState('')
    const [errors, setErrors] = useState({})

    const [images, setImages] = useState({ image: '', image_ingredient: '', image_preparation: '' })

    const [formEditDetail, setFormEditDetail] = useState({})

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const categoryId = params.get('category_id');

    useEffect(() => {
        if (categoryId !== undefined) {
            setForm({
                ...form,
                ["category_id"]: categoryId
            })
        }
    }, [categoryId])


    useEffect(() => {
        if (formEdit !== undefined && formEdit.id !== undefined) {
            setForm(formEdit)
            let { detail } = formEdit;

            let _ingredients = detail.filter(ing => ing.recipe_type === 2)
            setListIngredients(_ingredients)
            let _preparation = detail.filter(ing => ing.recipe_type === 1)
            setListPreparations(_preparation)
        }

    }, [formEdit])

    const updateInputVideo = (e) => {
        setVideo(e.target.files[0])
    }

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

    }
    const inputFile = (e) => {
        setImages({
            ...images,
            [e.target.name]: e.target.files[0]
        })

        let file = e.target.files[0];
        const name = e.target.name;
        let reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setForm({ ...form, [name]: reader.result })
            }
        }
        reader.readAsDataURL(file)
    }

    const onEdit = (row) => {

        setFormEditDetail(row)

        if (row.recipe_type === 2) {
            setDescriptions({
                ...descriptions,
                ["ingredients"]: row.description
            })
        } else if (row.recipe_type === 1) {
            setDescriptions({
                ...descriptions,
                ["preparations"]: row.description
            })
        }
    }

    const updateDescription = (e) => {
        setDescriptions({
            ...descriptions,
            [e.target.name]: e.target.value
        })
    }


    const onUpdateInput = (e, type) => {

        let _form = {}
        _form.recipe_type = type
        _form._id = uuid()

        if (e.key === 'Enter') {
            if (type === 1 && descriptions.ingredients !== '') {
                _form.description = descriptions.ingredients

                if (Object.keys(formEditDetail).length > 0) {
                    let _list = listIngredients.map(d => {
                        if (formEditDetail.id !== undefined) {
                            if (formEditDetail.id === d.id) {
                                d.description = descriptions.ingredients;
                            }
                        } else {
                            if (formEditDetail._id === d._id) {
                                d.description = descriptions.ingredients;
                            }
                        }

                        return d;
                    })
                    setListIngredients(_list)
                } else {
                    setListIngredients([
                        ...listIngredients,
                        _form
                    ])
                }

                setFormEditDetail({})

                setDescriptions({
                    ...descriptions,
                    ["ingredients"]: ''
                });
            } else if (type === 2 && descriptions.preparations !== '') {
                _form.description = descriptions.preparations

                if (Object.keys(formEditDetail).length > 0) {
                    let _list = listPreparations.map(d => {
                        if (formEditDetail.id !== undefined) {
                            if (formEditDetail.id === d.id) {
                                d.description = descriptions.preparations;
                            }
                        } else {
                            if (formEditDetail._id === d._id) {
                                d.description = descriptions.preparations;
                            }
                        }
                        return d;
                    })
                    setListPreparations(_list)
                } else {
                    setListPreparations([
                        ...listPreparations,
                        _form
                    ])
                }

                setFormEditDetail({})

                setDescriptions({
                    ...descriptions,
                    ["preparations"]: ''
                });
            }
        }
    }

    const onDeleteDetail = (item) => {
        let _list = []

        if (item.recipe_type === 2) {
            if (item.id !== undefined) {
                _list = listIngredients.map(d => {
                    if (d.id === item.id) {
                        d.delete = true;
                    }
                    return d
                })
            } else {
                _list = listIngredients.filter(l => l._id !== item._id)
            }

            setListIngredients(_list)
        } else if (item.recipe_type === 1) {
            if (item.id !== undefined) {
                _list = listPreparations.map(d => {
                    if (d.id === item.id) {
                        d.delete = true;
                    }
                    return d
                })
            } else {
                _list = listPreparations.filter(l => l._id !== item._id)
            }

            setListPreparations(_list)
        }
    }

    const onSubmitPre = () => {
        let _form = form;

        _form.image = images.image;
        _form.video = video;
        _form.category_id = form.category_id;
        _form.image_ingredient = images.image_ingredient;
        _form.image_preparation = images.image_preparation;
        _form.listIngredients = listIngredients;
        _form.listPreparations = listPreparations;

        if (form.title === '') {
            setErrors({ title: 'El titulo es Requerido' })
            return;
        }

        onSubmit(_form)
    }

    return (
        <>
            <Row className='my-2'>
                <Col> <button className='btn btn-success btn-sm' onClick={onSubmitPre}>Guardar</button></Col>
            </Row>
            <Row>
                <Col lg={3}>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <InputField
                                        placeholder="Agrega un titulo"
                                        value={form.title || ""}
                                        name="title"
                                        onChange={updateInput}
                                        error={errors.title}
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-1'>
                                <Col>
                                    <div className="form-group">
                                        <label htmlFor="chooseimage" className="uploadfile">
                                            {form.image !== '' ? (<img src={form.image} className="img-fluid" />) : <img src={chooseimage} className="img-fluid" />}
                                        </label>
                                        <input type="file" name="image" className="form-control" onChange={inputFile} id="chooseimage" />
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className='form-group'>
                                        <label>Video
                                            {form.video !== '' && (<video width="100%" height="100%" src={form.video} controls autoPlay>
                                            </video>)}
                                        </label>
                                        <input type={"file"} className='form-control' name='video' placeholder='Agrega un Video'
                                            onChange={updateInputVideo} />
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col lg={6}>
                                            <h3>Ingredientes</h3>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className='form-group'>
                                                <label>
                                                    {form.image_ingredient !== '' ? (<img src={form.image_ingredient} className="img-fluid" />)
                                                        : <img src={chooseimage} className="img-fluid" />}

                                                </label>
                                                <input type={"file"} className='form-control' name='image_ingredient' placeholder='Agrega un Video'
                                                    onChange={inputFile} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className='form-group'>
                                                <label>Agrega los Ingredientes</label>
                                                <input className='form-control' value={descriptions.ingredients} name="ingredients" placeholder='Describe los Ingredientes' onChange={updateDescription} onKeyDown={(e) => onUpdateInput(e, 1)} />
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <ListGroup>
                                                {listIngredients.filter(d => d.delete !== true).map((ing, k) => <ItemPrescriptionDetail index={k + 1}
                                                    item={ing} key={k} onEdit={onEdit}
                                                    onDelete={onDeleteDetail} />)}
                                            </ListGroup>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <h3>Preparación</h3>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className='form-group'>
                                                <label>
                                                    {form.image_preparation !== '' ? (<img src={form.image_preparation} className="img-fluid" />)
                                                        : <img src={chooseimage} className="img-fluid" />}

                                                </label>
                                                <input type={"file"} className='form-control' name='image_preparation' placeholder='Agrega un Video'
                                                    onChange={inputFile} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className='form-group'>
                                                <label>Agrega los Ingredientes</label>
                                                <textarea className='form-control' value={descriptions.preparations} name="preparations"
                                                    placeholder='Describe los Preparación'
                                                    rows={4}
                                                    onChange={updateDescription}
                                                    onKeyDown={(e) => onUpdateInput(e, 2)} ></textarea>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <ListGroup>
                                                {listPreparations.filter(d => d.delete !== true).map((ing, k) => <ItemPrescriptionDetail index={k + 1} item={ing} key={k}
                                                    onEdit={onEdit}
                                                    onDelete={onDeleteDetail} />)}
                                            </ListGroup>

                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

const mapsState = (props) => {
    let { recipe_book } = props
    return {
    }
}

export default connect(mapsState, {})(FormPrescriptionKitchen);