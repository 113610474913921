import React, { useEffect, useState } from 'react'
import { Col, Container, ListGroup, Row, Table } from 'react-bootstrap';
import ItemFranchise from './item.franchise';
import ModalFranchise from './modal.franchise';
import { getFranchiseByMethod } from '../../Redux/parameterDuck'
import { connect } from 'react-redux';


const FranchiseConfigPage = ({ listMethods, listFranchises, onSubmit, getFranchiseByMethod, fetching_franchise_list, data_franchise }) => {

    const [selected, setSelected] = useState({ id: 0 });
    const [modal, setModal] = useState(false)
    const [list, setList] = useState([])
    const [form,setForm] = useState({})

    useEffect(() => {
        if (listMethods.length > 0) {
            setSelected(listMethods[0])
            getFranchiseByMethod(listMethods[0])
        }
    }, [listMethods])



    useEffect(() => {
        if (fetching_franchise_list === 2) {
            setList(data_franchise)
        }
    }, [fetching_franchise_list])

    const onSelected = (item) => {
        setSelected(item)
        getFranchiseByMethod(item)
    }

    const onAddFranchise = (item) => {
        setModal(false)
        setList([
            ...list,
            item
        ])
    }

    const onEditFranchise = (item) => {
        setModal(false)

        let _list = list.map(l=>{
            if(l.id===item.id){
                l.percent = item.percent
            }

            return l
        })

        setList(_list)
    }

    const onSaveFranchise = () => {
        let param = selected
        param.list = list
        onSubmit(param)
    }

    const onEdit=(item)=>{
        setForm(item)
        setModal(true)
    }


    return (
        <Container fluid>
            <Row className='my-2'>
                <Col>
                    <button className='btn btn-success btn-sm' onClick={onSaveFranchise}>Guardar</button>
                </Col>
            </Row>
            <Row>
                <Col lg={3}>
                    <ListGroup>
                        {listMethods.map((l, key) => <ListGroup.Item
                            onClick={() => onSelected(l)} className={`${selected.id === l.id ? 'active' : ''} cursor-pointer`} key={key}>{l.description}</ListGroup.Item>)}
                    </ListGroup>
                </Col>
                <Col lg={3}>
                    <Row>
                        <Col><button className='btn btn-sm btn-info' onClick={() => setModal(true)}>Agregar</button></Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col>
                            <Table className='table-sm table-bordered'>
                                <thead>
                                    <tr>
                                        <th>Descripción</th>
                                        <th>Valor</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(list.length > 0) ? list.map((f, k) => <ItemFranchise key={k} item={f} onEdit={onEdit} />) : <tr><td colSpan={2}>No hay datos</td></tr>}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <ModalFranchise show={modal} listFranchises={listFranchises} 
            onHide={() => setModal(false)} 
            onSuccess={onAddFranchise}
            onEdit={onEditFranchise}
            formEdit={form}/>

        </Container>
    )
}


const mapsState = (props) => {
    let { parameter } = props
    return {
        fetching_franchise_list: parameter.fetching_franchise_list,
        data_franchise: parameter.data_franchise
    }
}

export default connect(mapsState, { getFranchiseByMethod })(FranchiseConfigPage);