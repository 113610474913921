import React, { useEffect, useState } from 'react'
import TemplateMenu from "../../template/menu.template";
import { Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { getListCharge } from '../../Redux/ProductInventoryDuck'


const ChargePage = ({ getListCharge, fetching_list_charge, data_charge }) => {

    const [list, setList] = useState([])

    useEffect(() => {
        if (fetching_list_charge === 2) {
            setList(data_charge)
        }
    }, [fetching_list_charge])

    useEffect(() => {
        getListCharge()
    }, [])

    return (
        <TemplateMenu>
            <Row className='my-2'>
                <Col lg={2}><h4>Listado de Cargos</h4></Col>
                <Col lg={2}>
                    <Link className="btn btn-info btn-sm" to="/cargos-productos/new">Crear</Link>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table className='table-sm table-bordered'>
                        <thead className='thead-dark'>
                            <tr>
                                <th>Nombre</th>
                                <th>Cantidad de Productos</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((d, k) => <tr key={k}>
                                <td>{d.name}</td>
                                <td>{d.detail.length}</td>
                                <td><Link className='btn btn-info btn-sm' to={`/cargos-productos/${d.id}`}>Editar</Link></td>
                            </tr>)}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { product_inventory } = props

    return {
        fetching_list_charge: product_inventory.fetching_list_charge,
        data_charge: product_inventory.charge
    }
}
export default connect(mapsState, { getListCharge })(ChargePage);