import React from "react";
import { formatNumber } from "../../utils";

const ItemProductInventory = ({ row, onEdit, product }) => {

  return (
    <tr className={`${row.status === false ? "table-secondary" : ""} ${product.id === row.id ? 'table-info' : ''}`}>
      <td>{row.priority} </td>
      <td>{row.name} {row.is_generic === true ? '(ASOC)' : ''}</td>
      <td>{row.code}</td>
      <td>{row.measure_unit}</td>
      <td>{row.tax}</td>
      <td>{row.family_inventory}</td>
      <td>{row.type_inventory}</td>
      <td>{row.group_inventory}</td>
      <td>{row.control_inventory}</td>
      <td>{row.supplier}</td>
      <td align="right">{formatNumber(row.quantity_available)}</td>
      <td align="right">{formatNumber(row.cost_average || 0)}</td>
      <td >{row.status === true ? 'Activo' : 'Inactivo'}</td>
      <td>
        <button className="btn btn-sm btn-info" onClick={() => onEdit(row)}>
          Editar
        </button>
      </td>
    </tr>
  );
};

export default ItemProductInventory;
