import React, { Fragment, useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import TemplateMenu from '../../template/menu.template'
import ModalCategoryRecipe from './modal.category.recipe';
import { createCategoryRecipe, getCategoryRecipe, updateCategoryRecipe, updateStatusForApp } from '../../Redux/recipe.book.Duck'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import not_available from '../../Images/no_available.png'
import { toogleLoader } from "../../Redux/alertDuck";

const API_URL = process.env.REACT_APP_API_URL


const CategoryRecipe = ({ createCategoryRecipe, updateCategoryRecipe, fetching_create, getCategoryRecipe,
    data, fetching_list, fetching_list_filter, toogleLoader, data_filter, updateStatusForApp }) => {
    const [modal, setModal] = useState(false)
    const [listCategory, setListCategory] = useState([])
    const [listCategoryFilter, setListCategoryfilter] = useState([])
    const [form, setForm] = useState({ category_id: 0, search: '' })

    useEffect(() => {
        toogleLoader(true)
        getCategoryRecipe(form)
    }, [])

    useEffect(() => {
        if (fetching_list === 2) {
            setListCategory(data)
            setListCategoryfilter(data)
            toogleLoader(false)
        }
    }, [fetching_list])

    useEffect(() => {
        if (fetching_list_filter === 2) {
            setListCategoryfilter(data_filter)
            toogleLoader(false)
        }
    }, [fetching_list_filter])


    useEffect(() => {
        if (fetching_create === 2) {
            setModal(false)
            getCategoryRecipe(form)
        }
    }, [fetching_create])

    const onEdit = (row) => {
        setModal(true)
        setForm(row)
    }

    const onFilter = (e) => {
        let value = e.target.value;
        let _form = { ...form, ["search"]: value }

        setForm(_form);
        if (value !== '') {
            getCategoryRecipe(_form)
        } else {
            setListCategoryfilter(listCategory)
        }
    }

    const onChangeCategory = (e) => {
        let _form = { ...form, ["category_id"]: e.target.value }
        setForm(_form);
        getCategoryRecipe(_form)
    }

    const onToggleStatus = (prescription) => {
        if (window.confirm(`¿Realmente deseas Inactivar ${prescription.title} del Recetario?`)) {
            prescription.is_for_app = !prescription.is_for_app
            updateStatusForApp(prescription)
        }
    }

    return (
        <TemplateMenu>
            <Row className='mt-3'>
                <Col lg={7}><h2>Listado de Recetas</h2></Col>
                <Col lg={2}>
                    <div className='form-group'>
                        <label>Categoria</label>
                        <select className='form-control' name="status_selected" value={form.category_id} onChange={onChangeCategory}>
                            <option value={0}>VER TODOS</option>
                            {listCategory.map((d, k) => <option value={d.id} key={k}>{d.title}</option>)}
                        </select>
                    </div>
                </Col>
                <Col className='' lg={3}>
                    <div className='form-group'>
                        <label>Buscar Producto</label>
                        <input placeholder='Buscar' className='form-control input-sm' onChange={onFilter} />
                    </div>

                </Col>
            </Row>

            {listCategoryFilter.length === 0 ? <Row>
                <Col className='text-center'><h4>No tienes Recetas Creadas</h4></Col>
            </Row> : listCategoryFilter.map((d, k) => {
                const { products } = d

                return <Fragment key={k}>
                    <Row className='mt-3'>
                        <Col key={k} className="mt-2 text-center border-bottom">
                            <h4>{d.title}</h4>
                        </Col>
                    </Row>
                    <Row>
                        {products.map((p, key) => {
                            let image = `${API_URL}${p.image}`

                            return <Col key={key} lg={3} className='mt-2'>
                                <Card bg={p.is_for_app === true ? '' : 'secondary'}>
                                    <Card.Img variant="top" src={image} />
                                    <Card.Body>
                                        <Row>
                                            <Col className='text-center'>{p.title}</Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col className='d-flex justify-content-center'>
                                                <Link to={`/categoria-recetario/${p.id}`} className="btn btn-sm btn-info">Editar</Link>
                                                <button type='button' className={`btn btn-sm btn-${p.is_for_app !== true ? 'success' : 'warning'} mx-2`}
                                                    onClick={() => onToggleStatus(p)}>{p.is_for_app !== true ? 'Activar' : 'Inactivar'} de Recetario</button>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        })}

                    </Row>
                </Fragment>
            })}


            <ModalCategoryRecipe show={modal} onCreate={createCategoryRecipe} onEdit={updateCategoryRecipe} formEdit={form} onHide={() => setModal(false)} />
        </TemplateMenu>

    )
}

const mapsState = (props) => {
    let { recipe_book } = props
    return {
        fetching_create: recipe_book.fetching_create,
        fetching_list: recipe_book.fetching_list,
        fetching_list_filter: recipe_book.fetching_list_filter,
        data_filter: recipe_book.data_filter,
        data: recipe_book.data,
    }
}

export default connect(mapsState, {
    createCategoryRecipe, updateCategoryRecipe,
    getCategoryRecipe, toogleLoader, updateStatusForApp
})(CategoryRecipe);