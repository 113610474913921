import axios from "axios";
import { getHeaders, validateIngresos, validateIngresosPre, validateInput } from "../utils";
const API_URL = process.env.REACT_APP_API_URL;

let initialState = {
  fetching_list: 0,
  fetching_create: 0,
  fetching_edit: 0,
  fetching_delete_payment: 0,
  fetching_delete_payment_worker: 0,
  fetching_deduction: 0,
  fetching_franchise: 0,
  fetching_novelty: 0,
  fetching_novelty_create: 0,
  fetching_registration: 0,
  fetching_create_registration: 0,
  fetching_update: 0,
  fetching_extra: 0,
  fetching_worker: 0,
  calendar: [],
  data: [],
  selected: {},
  row: {},
  extra: {},
  worker: {},
  novelty: {},
  errors: {},
  tag: '',
  fetching_delete: 0,
};

let LIST_INGRESOS = "LIST_INGRESOS";
let LIST_INGRESOS_SUCCESS = "INGRESOS_SUCCESS";
let INGRESOS_CREATE = "INGRESOS_CREATE";
let INGRESOS_CREATE_SUCCESS = "INGRESOS_CREATE_SUCCESS";
let CATEGORY_CLEAR = "CATEGORY_CLEAR";
let DELETE_CATEGORY = "DELETE_CATEGORY";
let DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
let INGRESOS_EDIT = "INGRESOS_EDIT";
let INGRESOS_EDIT_SUCCESS = "INGRESOS_EDIT_SUCCESS";
let COST_EDIT = "COST_EDIT";
let COST_EDIT_SUCCESS = "INGRESOS_EDIT_SUCCESS";
let INGRESOS_ERROR = "INGRESOS_ERROR";
let DELETE_PAYMENT_WORKER = "DELETE_PAYMENT_WORKER";
let DELETE_PAYMENT_WORKER_SUCCESS = "DELETE_PAYMENT_WORKER_SUCCESS";
let DELETE_PAYMENT = "DELETE_PAYMENT";
let DELETE_PAYMENT_SUCCESS = "DELETE_PAYMENT_SUCCESS";
let REVERSE_INGRESO = "REVSERSE_INGRESO";
let REVERSE_INGRESO_SUCCESS = "REVSERSE_INGRESO_SUCCESS";
let CREATE_DEDUCTION = "CREATE_DEDUCTION";
let CREATE_DEDUCTION_SUCCESS = "CREATE_DEDUCTION_SUCCESS";
let INGRESOS_FRANCHISE = "INGRESOS_FRANCHISE";
let INGRESOS_FRANCHISE_SUCCESS = "INGRESOS_FRACHISE_SUCCESS";
let RESTART_INGRESOS = "RESTART_INGRESOS";
let INGRESOS_CREATE_ERROR = "INGRESOS_CREATE_ERROR";
let DELETE_NOVELTY_TOTAL = "DELETE_NOVELTY_TOTAL";
let DELETE_NOVELTY_TOTAL_SUCCESS = "DELETE_NOVELTY_TOTAL_SUCCESS";
let UPDATE_REGISTRATION_DATE = "UPDATE_REGISTRATION_DATE";
let UPDATE_REGISTRATION_DATE_SUCCESS = "UPDATE_REGISTRATION_DATE_SUCCESS";
let UPDATE_REGISTRATION_DATE_ERROR = "UPDATE_REGISTRATION_DATE_ERROR";
let CREATE_REGISTRATION_DATE = "CREATE_REGISTRATION_DATE";
let CREATE_REGISTRATION_DATE_SUCCESS = "CREATE_REGISTRATION_DATE_SUCCESS";
let CREATE_REGISTRATION_DATE_ERROR = "CREATE_REGISTRATION_DATE_ERROR";
let INGRESOS_UPDATE_ROW = "INGRESOS_UPDATE_ROW";
let INGRESOS_UPDATE_ROW_SUCCESS = "INGRESOS_UPDATE_ROW_SUCCESS";
let INGRESOS_UPDATE_EXTRA = "INGRESOS_UPDATE_EXTRA";
let INGRESOS_UPDATE_EXTRA_SUCCESS = "INGRESOS_UPDATE_EXTRA_SUCCESS";
let INGRESOS_UPDATE_WORKER = "INGRESOS_UPDATE_WORKER";
let INGRESOS_UPDATE_WORKER_SUCCESS = "INGRESOS_UPDATE_WORKER_SUCCESS";
let INGRESOS_UPDATE_NOVELTY = "INGRESOS_UPDATE_NOVELTY";
let INGRESOS_UPDATE_NOVELTY_SUCCESS = "INGRESOS_UPDATE_NOVELTY_SUCCESS";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LIST_INGRESOS:
      return { ...state, fetching_list: 1, fetching_create: 0, fetching_create_registration: 0, fetching_edit: 0, fetching_create_registration: 0 };
    case LIST_INGRESOS_SUCCESS:
      return { ...state, fetching_list: 2, data: action.payload, errors: {}, calendar: action.calendar };

    case INGRESOS_CREATE:
      return { ...state, fetching_create: 1, errors: {} };
    case INGRESOS_CREATE_SUCCESS:
      return { ...state, fetching_create: 2, row: action.payload, errors: {} };
    case INGRESOS_CREATE_ERROR:
      return { ...state, fetching_create: 3, errors: action.payload };
    case CATEGORY_CLEAR:
      return { ...state, fetching: 0, selected: {} };
    case INGRESOS_EDIT:
      return { ...state, fetching_edit: 1 };
    case INGRESOS_EDIT_SUCCESS:
      return { ...state, fetching_edit: 2, row: action.payload, errors: {} };
    case INGRESOS_ERROR:
      return { ...state, fetching_edit: 3, errors: action.payload };
    case COST_EDIT:
      return { ...state, fetching_edit: 1 };
    case COST_EDIT_SUCCESS:
      return { ...state, fetching_edit: 2, row: action.payload };
    case DELETE_CATEGORY:
      return { ...state, fetching_delete: 1 };
    case DELETE_CATEGORY_SUCCESS:
      return { ...state, fetching_delete: 2 };
    case DELETE_PAYMENT:
      return { ...state, fetching_delete_payment: 1 };
    case DELETE_PAYMENT_SUCCESS:
      return { ...state, fetching_delete_payment: 2 };
    case DELETE_PAYMENT_WORKER:
      return { ...state, fetching_delete_payment_worker: 1 };
    case DELETE_PAYMENT_WORKER_SUCCESS:
      return { ...state, fetching_delete_payment_worker: 2 };
    case REVERSE_INGRESO:
      return { ...state, fetching_reverse: 1 };
    case REVERSE_INGRESO_SUCCESS:
      return { ...state, fetching_reverse: 2 };
    case INGRESOS_FRANCHISE:
      return { ...state, fetching_franchise: 1 };
    case INGRESOS_FRANCHISE_SUCCESS:
      return { ...state, fetching_franchise: 2 };
    case RESTART_INGRESOS:
      return { ...state, fetching_franchise: 0, fetching_list: 0, fetching_edit: 0 };
    case DELETE_NOVELTY_TOTAL:
      return { ...state, fetching_novelty: 1 };
    case DELETE_NOVELTY_TOTAL_SUCCESS:
      return { ...state, fetching_novelty: 2 };
    case UPDATE_REGISTRATION_DATE:
      return { ...state, fetching_registration: 1 };
    case UPDATE_REGISTRATION_DATE_SUCCESS:
      return { ...state, fetching_registration: 2 };
    case UPDATE_REGISTRATION_DATE_ERROR:
      return { ...state, fetching_registration: 3, errors: action.payload };
    case CREATE_REGISTRATION_DATE:
      return { ...state, fetching_create_registration: 1, };
    case CREATE_REGISTRATION_DATE_SUCCESS:
      return { ...state, fetching_create_registration: 2, row: action.payload };
    case CREATE_REGISTRATION_DATE_ERROR:
      return { ...state, fetching_create_registration: 3, errors: action.payload };
    case INGRESOS_UPDATE_ROW:
      return { ...state, fetching_update: 1 };
    case INGRESOS_UPDATE_ROW_SUCCESS:
      return { ...state, fetching_update: 2, tag: action.payload };
    case INGRESOS_UPDATE_EXTRA:
      return { ...state, fetching_extra: 1 };
    case INGRESOS_UPDATE_EXTRA_SUCCESS:
      return { ...state, fetching_extra: 2, extra: action.payload };
    case INGRESOS_UPDATE_WORKER:
      return { ...state, fetching_worker: 1 };
    case INGRESOS_UPDATE_WORKER_SUCCESS:
      return { ...state, fetching_worker: 2, worker: action.payload };
    case INGRESOS_UPDATE_NOVELTY:
      return { ...state, fetching_novelty_create: 1 };
    case INGRESOS_UPDATE_NOVELTY_SUCCESS:
      return { ...state, fetching_novelty_create: 2, novelty: action.payload };
    default:
      return state;
  }
}

export let restartIngresos = () => (dispatch, getState) => {

  dispatch({
    type: RESTART_INGRESOS,
  });
}

export let getIngresos = (query) => (dispatch, getState) => {
  dispatch({
    type: LIST_INGRESOS,
  });

  let branch = JSON.parse(localStorage.getItem("branch"));

  let _date = JSON.parse(localStorage.getItem("date"));
  let url = "";

  if (query !== undefined) {
    url = `${API_URL}/api/ingresos?${query}&branch=${branch.id}`;
  } else {
    url = `${API_URL}/api/ingresos?branch=${branch.id}&init=${_date.date_init}&end=${_date.date_end}`;
  }

  if (getState().user.loggedIn) {
    axios
      .get(url, {
        headers: getHeaders()
      })
      .then((resp) => {
        dispatch({
          type: LIST_INGRESOS_SUCCESS,
          payload: resp.data.results,
          calendar: resp.data.calendar,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    console.log("no Ingreso");
  }
};

export let getIngresoById = (id) => (dispatch, getState) => {
  dispatch({
    type: INGRESOS_EDIT,
  });

  if (getState().user.loggedIn) {
    axios
      .get(`${API_URL}/api/ingresos/${id}`, {
        headers: getHeaders(),
      })
      .then((resp) => {
        dispatch({
          type: INGRESOS_EDIT_SUCCESS,
          payload: resp.data,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    console.log("no Ingreso");
  }
}

export let getIngresoByDate = (date) => (dispatch, getState) => {
  dispatch({
    type: INGRESOS_EDIT,
  });

  let branch = JSON.parse(localStorage.getItem("branch"));

  if (getState().user.loggedIn) {
    axios
      .get(`${API_URL}/api/ingresos-date/${date}?branch=${branch.id}`, {
        headers: getHeaders(),
      })
      .then((resp) => {
        if (resp.data.status === true) {
          dispatch({
            type: INGRESOS_EDIT_SUCCESS,
            payload: resp.data.result,
          });
        } else {
          console.log("no se puede");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    console.log("no Ingreso");
  }
};

export let updateCost = (id, value) => (dispatch, getState) => {
  dispatch({
    type: COST_EDIT,
  });

  if (getState().user.loggedIn) {
    let param = { value };

    axios
      .put(`${API_URL}/api/ingresos-cost/${id}`, param, {
        headers: getHeaders(),
      })
      .then((resp) => {
        // dispatch({
        //     type: COST_EDIT_SUCCESS,
        //     payload: resp.data
        // });
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    console.log("no Ingreso");
  }
}

export let onUpdateRegistrationDate = (form) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_REGISTRATION_DATE,
  });

  if (getState().user.loggedIn) {
    axios
      .put(`${API_URL}/api/ingresos-registration-date/${form.id}`, form, {
        headers: getHeaders(),
      })
      .then((resp) => {
        dispatch({
          type: UPDATE_REGISTRATION_DATE_SUCCESS,
        });
      })
      .catch(function (error) {
        let { data } = error.response;
        dispatch({
          type: UPDATE_REGISTRATION_DATE_ERROR,
          payload: data
        });

      });
  } else {
    console.log("no Ingreso");
  }
}

export let onCreateRegistrationDate = (form) => (dispatch, getState) => {
  dispatch({
    type: CREATE_REGISTRATION_DATE,
  });

  if (getState().user.loggedIn) {
    if (form.registration_date === '') {
      dispatch({
        type: UPDATE_REGISTRATION_DATE_ERROR,
        payload: { registration_date: "Fecha es Requerida" }
      });
      return;
    }

    let branch = JSON.parse(localStorage.getItem("branch"));
    form.branch_id = branch.id

    axios
      .post(`${API_URL}/api/ingresos-registration-date`, form, {
        headers: getHeaders(),
      })
      .then((resp) => {
        dispatch({
          type: CREATE_REGISTRATION_DATE_SUCCESS,
          payload: resp.data
        });
      })
      .catch(function (error) {
        let { data } = error.response;

        dispatch({
          type: CREATE_REGISTRATION_DATE_ERROR,
          payload: data
        });

      });
  } else {
    console.log("no Ingreso");
  }
}

export let reverseIngreso = (item) => (dispatch, getState) => {
  dispatch({
    type: REVERSE_INGRESO,
  });

  if (getState().user.loggedIn) {
    axios
      .put(`${API_URL}/api/ingresos-reverse/${item.id}`, item, {
        headers: getHeaders(),
      })
      .then((resp) => {
        dispatch({
          type: REVERSE_INGRESO_SUCCESS,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    console.log("no Ingreso");
  }
};

export let deleteIngreso = (item) => (dispatch, getState) => {
  dispatch({
    type: REVERSE_INGRESO,
  });

  if (getState().user.loggedIn) {
    axios
      .delete(`${API_URL}/api/ingresos/${item.id}`, {
        headers: getHeaders(),
      })
      .then((resp) => {
        dispatch({
          type: REVERSE_INGRESO_SUCCESS,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    console.log("no Ingreso");
  }
};

export let deletePayment = (item) => (dispatch, getState) => {
  dispatch({
    type: DELETE_PAYMENT,
  });

  if (getState().user.loggedIn) {
    axios
      .delete(`${API_URL}/api/ingresos-cost/${item.id}`, {
        headers: getHeaders(),
      })
      .then((resp) => {
        dispatch({
          type: DELETE_PAYMENT_SUCCESS,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    console.log("no Ingreso");
  }
};

export let deletePaymentWorker = (item) => (dispatch, getState) => {
  dispatch({
    type: DELETE_PAYMENT_WORKER,
  });

  if (getState().user.loggedIn) {
    axios
      .delete(`${API_URL}/api/ingresos-cost-worker/${item.id}`, {
        headers: getHeaders(),
      })
      .then((resp) => {
        dispatch({
          type: DELETE_PAYMENT_WORKER_SUCCESS,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    console.log("no Ingreso");
  }
};
export let deleteNoveltyTotal = (item) => (dispatch, getState) => {
  dispatch({
    type: DELETE_NOVELTY_TOTAL,
  });

  if (getState().user.loggedIn) {
    axios
      .delete(`${API_URL}/api/ingresos-novelty/${item.id}`, {
        headers: getHeaders(),
      })
      .then((resp) => {
        dispatch({
          type: DELETE_NOVELTY_TOTAL_SUCCESS,
        });
      })
      .catch(function (error) {
        let { status } = error.response;

        console.log('error.response', error.response);


      });
  } else {
    console.log("no Ingreso");
  }
};

export let createIngresos = (form) => (dispatch, getState) => {
  dispatch({
    type: INGRESOS_CREATE,
  });

  let branch = JSON.parse(localStorage.getItem("branch"));

  let validate;


  console.log('form---', form);



  if (form.status_id === 1) {
    validate = validateIngresos(form);
  } else {

    let rules = [
      { field: "registration_date", msg: "La fecha es Requerida" },
      { field: "aldelo", msg: "La venta es Requerida" },
      { field: "cash", msg: "El efectivo es Requerida" },
      { field: "datafono", msg: "El datafono es Requerida" },
      { field: "daviplata", msg: "El daviplata es Requerida" },
      { field: "nequi", msg: "El nequi es Requerida" },
      { field: "intern_total", msg: "TOTAL PAGOS INTERNO POS es requerido" },
      { field: "amount_delivery", msg: "La Cantidad de domicilios es Requerida" },
      { field: "total_delivery", msg: "El monto total de domicilios es Requerida" },
      { field: "total_input", msg: "El Cantidad de Entradas es Requerida" },
    ];

    console.log('rules', rules);
    validate = validateInput(form, rules);
  }
  console.log('validate', validate);

  if (validate !== null) {
    dispatch({
      type: INGRESOS_CREATE_ERROR,
      payload: validate,
    });
    return;
  }

  if (getState().user.loggedIn) {
    form.branch_id = branch.id;
    axios
      .post(`${API_URL}/api/ingresos`, form, {
        headers: getHeaders(),
      })
      .then((resp) => {
        let { data } = resp;

        if (data.status == false) {
          dispatch({
            type: INGRESOS_ERROR,
            payload: data.errors,
            form: form,
          });
        } else {
          dispatch({
            type: INGRESOS_CREATE_SUCCESS,
            payload: data.data,
          });
        }
      })
      .catch((error) => {
        let err = {};

        for (const [key, value] of Object.entries(error.response.data.errors)) {
          if ("title" === key) {
            err.title = value[0];
          }
          if ("description" === key) {
            err.description = value[0];
          }
        }

        dispatch({
          type: INGRESOS_ERROR,
          payload: err,
          form: form,
        });
      });
  } else {
    console.log("no Ingreso");
  }
};

export let updateIngresos = (form) => (dispatch, getState) => {
  dispatch({
    type: INGRESOS_CREATE,
  });

  let rules = [
    { field: "registration_date", msg: "La fecha es Requerida" },
    { field: "aldelo", msg: "La venta es Requerida", min: 0 },
    { field: "cash", msg: "El efectivo es Requerida", min: 0 },
    { field: "datafono", msg: "El datafono es Requerida", min: 0 },
    { field: "daviplata", msg: "El daviplata es Requerida", min: 0 },
    { field: "nequi", msg: "El nequi es Requerida", min: 0 },
    { field: "intern_total", msg: "TOTAL PAGOS INTERNO POS es requerido", min: 0 },
    { field: "amount_delivery", msg: "La Cantidad de domicilios es Requerida", min: 0 },
    { field: "total_delivery", msg: "El monto total de domicilios es Requerida", min: 0 },
    { field: "total_input", msg: "El Cantidad de Entradas es Requerida", min: 0 },
  ];

  let validate = validateInput(form, rules);

  if (validate !== null) {
    dispatch({
      type: INGRESOS_ERROR,
      payload: validate,
    });
    return;
  }

  if (getState().user.loggedIn) {
    axios
      .put(`${API_URL}/api/ingresos/${form.id}`, form, {
        headers: getHeaders(),
      })
      .then((resp) => {
        let { data } = resp;
        dispatch({
          type: INGRESOS_CREATE_SUCCESS,
          payload: data.data,
        });
      })
      .catch((error) => { });
  } else {
    console.log("no Ingreso");
  }
};


export let updateIngresosRow = (form) => (dispatch, getState) => {
  dispatch({
    type: INGRESOS_UPDATE_ROW,
  });

  if (getState().user.loggedIn) {
    axios
      .put(`${API_URL}/api/ingresos-row/${form.id}`, form, {
        headers: getHeaders(),
      })
      .then((resp) => {
        let { tag } = resp.data;

        dispatch({
          type: INGRESOS_UPDATE_ROW_SUCCESS,
          payload: tag,
        });
      })
      .catch((error) => { });
  } else {
    console.log("no Ingreso");
  }
}

export let updateIngresosExtra = (form) => (dispatch, getState) => {
  dispatch({
    type: INGRESOS_UPDATE_EXTRA,
  });

  if (getState().user.loggedIn) {
    axios
      .put(`${API_URL}/api/ingresos-extra/${form.id}`, form, {
        headers: getHeaders(),
      })
      .then((resp) => {

        dispatch({
          type: INGRESOS_UPDATE_EXTRA_SUCCESS,
          payload: resp.data,
        });
      })
      .catch((error) => { });
  } else {
    console.log("no Ingreso");
  }
}

export let updateIngresosWorker = (form) => (dispatch, getState) => {
  dispatch({
    type: INGRESOS_UPDATE_WORKER,
  });

  if (getState().user.loggedIn) {
    axios
      .put(`${API_URL}/api/ingresos-worker/${form.id}`, form, {
        headers: getHeaders(),
      })
      .then((resp) => {

        dispatch({
          type: INGRESOS_UPDATE_WORKER_SUCCESS,
          payload: resp.data,
        });
      })
      .catch((error) => { });
  } else {
    console.log("no Ingreso");
  }
}

export let updateIngresosNovelty = (form) => (dispatch, getState) => {
  dispatch({
    type: INGRESOS_UPDATE_NOVELTY,
  });

  if (getState().user.loggedIn) {
    axios
      .put(`${API_URL}/api/ingresos-novelty/${form.id}`, form, {
        headers: getHeaders(),
      })
      .then((resp) => {

        dispatch({
          type: INGRESOS_UPDATE_NOVELTY_SUCCESS,
          payload: resp.data,
        });
      })
      .catch((error) => { });
  } else {
    console.log("no Ingreso");
  }
}


export let updateIngresosCheck = (form) => (dispatch, getState) => {
  dispatch({
    type: INGRESOS_CREATE,
  });

  if (getState().user.loggedIn) {
    axios
      .put(`${API_URL}/api/ingresos-check/${form.id}`, form, {
        headers: getHeaders(),
      })
      .then((resp) => {
        let { data } = resp;

        dispatch({
          type: INGRESOS_CREATE_SUCCESS,
          payload: data.data,
        });
      })
      .catch((error) => {
        let { data } = error.response
        dispatch({
          type: INGRESOS_CREATE_ERROR,
          payload: data.errors,
        });
      });
  } else {
    console.log("no Ingreso");
  }
};

export let addTaxFranchise = (param) => (dispatch, getState) => {
  dispatch({
    type: INGRESOS_FRANCHISE,
  });

  if (getState().user.loggedIn) {
    axios
      .post(`${API_URL}/api/ingresos-franchise`, param, {
        headers: getHeaders(),
      })
      .then((resp) => {
        dispatch({
          type: INGRESOS_FRANCHISE_SUCCESS,
        });
      })
      .catch((error) => { });
  } else {
    console.log("no Ingreso");
  }
};

export let createOtherDeduccion = (form) => (dispatch, getState) => {
  dispatch({
    type: CREATE_DEDUCTION,
  });

  if (getState().user.loggedIn) {
    axios
      .post(`${API_URL}/api/ingresos-deduction`, form, {
        headers: getHeaders(),
      })
      .then((resp) => {
        dispatch({
          type: CREATE_DEDUCTION_SUCCESS,
        });
      })
      .catch((error) => { });
  } else {
    console.log("no Ingreso");
  }
};
