import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import './index.css'
import { connect } from 'react-redux';
import { onLogin, getInformationUser, getDates, getFormPermissions } from '../../Redux/userDuck'
import { getMenu } from '../../Redux/menuDuck'
import { getBranch } from '../../Redux/branchofficeDuck'
import { toogleAlert } from '../../Redux/alertDuck'
import ButtonNaranja from '../Components/Widgets/ButtonNaranja'
import WhiteTitle from '../Components/Widgets/WhiteTitle'
import InputForm from '../Components/Widgets/InputForm'
import logo_mana_white2 from '../../Images/logo.png'
import { useHistory } from "react-router-dom";
import { SocketContext } from '../../context/SocketContext';


const Login = ({ onLogin, errors, fetching, getInformationUser, toogleAlert, getMenu, getBranch, getDates, fetching_dates,
    data_user, getFormPermissions }) => {

    const [form, setForm] = useState({ email: '', password: '' })
    const history = useHistory();
    const { socket } = useContext(SocketContext)


    useEffect(() => {
        toogleAlert(false)
    }, [])

    useEffect(() => {
        if (fetching === 2) {
            let branch = JSON.parse(localStorage.branch)
            getBranch()
            getDates(branch.id)
            getMenu()
            getInformationUser();
            getFormPermissions()
            history.push("/home")
        }
    }, [fetching])

    const handlerInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        onLogin(form)
    }

    return (
        <Container fluid className="d-flex pt-lg-5 pt-2 justify-content-center align-items-center container-fluid px-0 vh-100 bg-azul ">
            <Row className="p-3 d-flex justify-content-center align-items-center w-100">
                <Col lg={6} className="h-100 card-custom">
                    <Row >
                        <Col lg={6} className="d-lg-block d-none">
                            <div className='text-logo-login'>Onix Control</div>
                            <Row className="h-100 d-flex align-items-center justify-content-center">
                                <Col lg={6} md={6} xs={6}><img src={logo_mana_white2} alt="" className="img-fluid px-lg-2 px-md-2" /></Col>
                            </Row>
                        </Col>

                        <Col lg={6} className="d-flex flex-column justify-content-center align-items-center py-3 bg-burger col-lg-6">
                            <div className="bg-image-login"></div>
                            <Row className="h-100 d-flex align-items-center my-4 justify-content-center d-lg-none d-xs-block">
                                <Col lg={7} xs={6}><img src={logo_mana_white2} alt="" className="img-fluid px-lg-4" /></Col>
                            </Row>
                            <Row className="justify-content-center my-4">
                                <Col lg={12} className="d-flex justify-content-center">
                                    <WhiteTitle title='Inicio Sesión' />
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col lg={12} className="d-flex justify-content-center">
                                    <span className="text-danger font-18">{errors.message}</span>
                                </Col>
                            </Row>

                            <Row className="justify-content-center py-5 w-100">
                                <Col lg={11} className="w-100">
                                    <form onSubmit={(e) => onSubmit(e)}>
                                        <InputForm
                                            type='email'
                                            name='email'
                                            placeholder='Ingresa tu Correo'
                                            error={errors.email}
                                            value={form.email}
                                            onChange={handlerInput}
                                        />

                                        <InputForm
                                            name='password'
                                            type='password'
                                            placeholder='Contraseña'
                                            value={form.password}
                                            error={errors.password}
                                            onChange={handlerInput}

                                        />
                                        <div className="mt-4" />
                                        <ButtonNaranja title='Ingresar' onClick={(e) => onSubmit(e)} />

                                    </form>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>

    )
}

const mapsState = (props) => {
    let { user } = props
    return {
        fetching: user.fetching,
        fetching_dates: user.fetching_dates,
        data_user: user.user,
        errors: user.errors
    }
}

export default connect(mapsState, { onLogin, getInformationUser, toogleAlert, getMenu, getBranch, getDates, getFormPermissions })(Login);