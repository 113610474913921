import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux';
import ItemRequisition from './item.requisition';
import { getListRequisition, onDeleteRequisition, onReverseRequisition } from '../../Redux/requisitionDuck';
import ModalReserveRequisition from './modal.reverse.requisition';

const ListRequisitionDeliver = ({ fetching_list, products, onDeleteRequisition, onReverseRequisition, fetching_delete }) => {
    const [list, setList] = useState([])
    const [branch, setBranch] = useState({})
    const [modal, setModal] = useState(false)
    const [selected, setSelected] = useState({ branch: { title: '' }, branch_request: { title: '' } })

    useEffect(() => {
        if (fetching_list === 2) {
            let _branch = JSON.parse(localStorage.getItem("branch"))
            let _p = products.filter(p => p.branch_id === _branch.id);
            setList(_p)
            setBranch(_branch)
        }
    }, [fetching_list])

    useEffect(() => {
        if (fetching_delete === 2) {
            setModal(false)
        }
    }, [fetching_delete])


    const onDelete = (item) => {
        if (window.confirm("Realmente deseas Eliminar esta Requisición?")) {
            console.log('item', item);
            return;
            onDeleteRequisition(item.id)
        }
    }

    const onReverseOptions = (item) => {
        setModal(true)
        setSelected(item)

    }

    const onReverse = (item) => {
        if (window.confirm("Realmente deseas Reversar esta Requisición?")) {
            onReverseRequisition(item.id)
        }
    }
    const onDeleteRequisitionForm = (item) => {
        if (window.confirm("Realmente deseas Eliminar esta Requisición?")) {
            onDeleteRequisition(item.id)
        }
    }

    return (
        <>
            <Row className='mt-3'>
                <Col>
                    <table className="table table-sm table-bordered table-sm">
                        <thead className='thead-dark'>
                            <tr>
                                <th></th>
                                <th>Fecha de Solicitud</th>
                                <th>Punto Destino</th>
                                <th>Quien Solicito</th>
                                <th className='text-center'>Estado</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(list.length > 0) ? list.map((row, k) => <ItemRequisition
                                onReverse={onReverseOptions}
                                show_reverse={row.request_branch_id === branch.id}
                                show_delete={row.branch_id === branch.id}
                                branch={branch}
                                type={2} title={row.branch.title}
                                row={row} key={row.id}
                                onDelete={onDelete}
                                titleButton={row.status_id === 3 ? 'Ver' : "Despacho"} />) : <tr><td colSpan={6}>No se encontraron registros</td></tr>}
                        </tbody>

                    </table>
                </Col>
            </Row>

            <ModalReserveRequisition show={modal} selected={selected} onReverse={onReverse}
                onDeleteRequisition={onDeleteRequisitionForm} onHide={() => setModal(false)} />
        </>
    )
}

const mapsState = (props) => {
    let { requisition } = props
    return {
        fetching_list: requisition.fetching_list,
        fetching_create: requisition.fetching_create,
        fetching_delete: requisition.fetching_delete,
        products: requisition.data,
    }
}


export default connect(mapsState, { onDeleteRequisition, onReverseRequisition })(ListRequisitionDeliver);