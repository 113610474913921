import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import { connect } from "react-redux";
import {
  getIngresoByDate,
  createIngresos,
  updateIngresosCheck,
  addTaxFranchise,
} from "../../Redux/IngresosDuck";
import ModalAddIngresos from "./ModalAddIngresos";
import {
  getParameter,
  createParameter,
  getFranchiseByMethodTotal,
} from "../../Redux/parameterDuck";
import { withRouter } from "react-router-dom";
import {
  ToastProvider,
  useToasts,
  removeAllToasts,
} from "react-toast-notifications";
import ItemDetailCost from "./item.detail.cost";
import { formatNumber } from "../../utils";
import InputEditIngresos from "./input.edit.ingresos";
import { FcCheckmark } from "react-icons/fc";
import { AiFillBank } from "react-icons/ai";

import { useHistory } from "react-router-dom";
import TextEditIngresos from "./text.edit.ingresos";
import ItemDetailCostWorker from "./item.detail.cost.worker";
import ModalAddIngresosWorker from "./ModalAddIngresosWorker";
import { getWorkersActives, getWorkersTurnActives } from "../../Redux/workerDuck";
import { toogleLoader } from "../../Redux/alertDuck";
import ModalAddParameter from "../Components/ModalAddParameter";
import TemplateMenu from "../../template/menu.template";
import ModalTaxPage from "./modal.tax";
import { MdEditNote } from "react-icons/md";
import ModalAddTurn from "./modal.add.turn";

const initState = {
  aldelo: "", intern: "", cancellations: "", cash: "",
  datafono: "", total_collection: "", nequi: "", daviplata: "", rappi: '', tips: "", comments_check: "", total_input: ''
}

const EditIngresosPage = ({ errors, getParameter, parameters, getIngresoByDate, createIngresos, fetching, parameter, match,
  ingreso, updateCost, updateIngresosCheck, fetching_create, user, fetching_info,
  fetching_list, workers, getWorkersActives, createParameter, fetching_create_parameter, fetching_parameter,
  getFranchiseByMethodTotal, fetching_franchise_list, data_franchise, addTaxFranchise, fetching_franchise, toogleLoader,
  getWorkersTurnActives, fetching_list_turn, data_turn }) => {
  let registration_date = match.params.registration_date;

  const history = useHistory();

  const { addToast } = useToasts();

  const [form, setForm] = useState(initState);
  const [locked, setLocked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [typeData, setTypeData] = useState(0);
  const [listIntern, setListIntern] = useState([]);
  const [listTurn, setListTurn] = useState([]);
  const [listEmploy, setListEmploy] = useState([]);
  const [listParameter, setListParameter] = useState([]);
  const [inputSelected, setInputSelected] = useState({});
  const [showModalWorker, setShowModalWorker] = useState(false);
  const [listWorkers, setListWorkers] = useState([]);
  const [modalParam, setModalParam] = useState({ open: false, title: "Pendiente" });
  const [lastParam, setLastParam] = useState({});
  const [modalTax, setModaltax] = useState(false);
  const [listFranchise, setListFranchise] = useState([]);
  const [dataFranchise, setDataFranchise] = useState([]);
  const [listWorkersTurn, setListWorkersTurn] = useState([]);
  const [showModalTurn, setShowModalTurn] = useState(false);
  const [totalIntern, setTotalIntern] = useState(0);

  const [id, setId] = useState(0);

  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    toogleLoader(true)
    getIngresoByDate(registration_date);
    getWorkersTurnActives();
  }, [registration_date]);

  useEffect(() => {
    if (fetching_info === 2) {
      if (user.role_id === 1 || user.role_id === 2 || user.role_id === 5) {
        setLocked(false);
      } else {
        setLocked(true);
      }
    }
  }, [fetching_info]);

  useEffect(() => {
    if (fetching_list_turn === 2) {

      setListWorkersTurn(data_turn);
    }
  }, [fetching_list_turn]);

  useEffect(() => {
    if (fetching_parameter === 2) {
      setListParameter(
        parameters.filter((param) => param.segment === lastParam.segment)
      );
    }
  }, [fetching_parameter]);

  useEffect(() => {
    if (fetching_franchise === 2) {
      setModaltax(false);
    }
  }, [fetching_franchise]);

  useEffect(() => {
    if (fetching_franchise_list === 2) {
      setListFranchise(data_franchise);
      setModaltax(true);
    }
  }, [fetching_franchise_list]);

  useEffect(() => {
    if (fetching_create_parameter === 2) {
      let q = `?branch=${ingreso.branch_id}`;

      setModalParam(false);
      getParameter(q);
    }
  }, [fetching_create_parameter]);

  useEffect(() => {
    if (fetching === 2) {
      let q = `?branch=${ingreso.branch_id}`;
      getParameter(q);
      getWorkersActives(q);
      setForm(ingreso);
      setId(ingreso.id);
      if (ingreso.status_id === 4) {
        setReadOnly(true);
      }

      setListIntern(
        ingreso.payment_operation.filter((row) => row.type_payment === 1)
      );
      setListEmploy(ingreso.payment_worker.filter((w) => w.is_turn === false));
      setListTurn(ingreso.payment_worker.filter((w) => w.is_turn === true));

      let _totalIntern = ingreso.payment_operation.filter((row) => row.type_payment === 1)
        .reduce((a, b) => (a = a + parseFloat(b.amount_real)), 0) +
        ingreso.payment_worker.filter((w) => w.is_turn === false)
          .reduce((a, b) => (a = a + parseFloat(b.amount_real)), 0) +
        ingreso.payment_worker.filter((w) => w.is_turn === true)
          .reduce((a, b) => (a = a + parseFloat(b.amount_real)), 0)

      setTotalIntern(
        _totalIntern
      )


      toogleLoader(false)
    }
  }, [fetching]);

  useEffect(() => {
    if (fetching_create === 2) {
      toogleLoader(false)
      window.location.reload();

      if (ingreso.status_id === 4) {
        history.push("/ingresos");
      }
    }

  }, [fetching_create]);

  useEffect(() => {
    if (fetching_list === 2) {
      setListWorkers(workers);
    }
  }, [fetching_list]);

  const updateInput = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  }

  const onHide = () => {
    setShowModal(false);
  }

  const showModalTmp = (title, segment, type) => {

    setTitleModal(title);
    setTypeData(type);
    setShowModal(true);
  }

  const onSubmitModal = (form) => {
    if (form.type_data === 1) {
      setListIntern([...listIntern, form]);
    }
    if (form.type_data === 2) {
      setListTurn([...listTurn, form]);
    }

    setShowModal(false);
  };

  const onConfirmCost = (item, value, type) => {
    if (type === 1) {
      setListIntern(replaceData(listIntern, item.id, value));
    } else if (type === 2) {
      setListTurn(replaceData(listTurn, item.id, value));
    }
  }

  const onConfirmCostWorker = (item, value, type) => {
    setListEmploy(replaceData(listEmploy, item.id, value));
  }

  const onConfirmCheck = (item, type) => {
    if (type === 1) {
      setListIntern(replaceData(listIntern, item.id, item.amount));
    } else if (type === 2) {
      setListTurn(replaceData(listTurn, item.id, item.amount));
    } else if (type === 3) {
      setListEmploy(replaceData(listEmploy, item.id, item.amount));
    }
  }

  const replaceData = (array, id, value) => {
    array.map((row) => {
      if (row.id === id) {
        row.amount_real = value;
        row.amount_real_formated = formatNumber(value);
      }
    });

    return Object.assign([], array);
  }

  const onSubmit = (status_id, status) => {
    let _form = form;
    _form.intern = listIntern;
    _form.turn = listTurn;
    _form.employee = listEmploy;
    _form.status_id = status_id;
    _form.status = status;
    _form.dataFranchise = dataFranchise;

    toogleLoader(true)

    updateIngresosCheck(_form);
  }

  const onEditData = (value, input) => {
    setInputSelected(input);
  }

  const onConfimData = (value, inputSelected) => {
    setForm({
      ...form,
      [`${inputSelected}_real`]: value,
    });
  }

  const onCreateParameter = (_form) => {
    _form.segment = _form.dataType === 2 ? "payment_turn" : "payment_intern";
    _form.priority = 1;
    _form.branch_id = form.branch_id;
    setLastParam(_form);
    createParameter(_form);
  };

  const onConfirm = (value, inputSelected) => {

    setForm({
      ...form,
      [`${inputSelected}_real`]: value,
    });

    setInputSelected({});
  }

  const onConfirmText = (value, inputSelected) => {
    setForm({
      ...form,
      [`${inputSelected}`]: value,
    });

    setInputSelected({});
  }

  const showModalTmpWorker = (type) => {
    setTypeData(type);

    setShowModalWorker(true);
  }

  const onHideWorker = () => {
    setShowModalWorker(false);
  }

  const onSubmitModalWorker = (form) => {
    setListEmploy([...listEmploy, form]);

    setShowModalWorker(false);
  }

  const onOpenParameter = (title, dataType) => {
    let _title =
      dataType === 1 ? "Agregar otro Concepto" : "Pago otro Turneros";

    setModalParam({
      ...modalParam,
      open: true,
      title: _title,
      dataType,
    });
  }

  const onShowTax = () => {

    // if (listFranchise.length > 0) {
    //   setModaltax(true);
    // } else {
    //   setListFranchise([])
    //   getFranchiseByMethodTotal({ id: 375, total_id: id });
    // }
    setListFranchise([])
    getFranchiseByMethodTotal({ id: 375, total_id: id });
  }

  const onAddTax = (data) => {
    let param = { id, list: data.list, total: data.total };
    setForm({
      ...form,
      ["datafono_real"]: data.total,
    });

    setDataFranchise(data.list);
    setListFranchise(data.list);
    setModaltax(false);
  }

  const onSubmitModalTurn = (form) => {
    setListTurn([...listTurn, form]);
    setShowModalTurn(false);
  };

  let diff_aldelo = form.aldelo_real - form.aldelo;

  let total_turn = listTurn.reduce((a, b) => (a = a + parseFloat(b.amount)), 0);
  let total_turn_real = listTurn.reduce((a, b) => (a = a + parseFloat(b.amount_real)), 0);
  let diff_turn = total_turn - total_turn_real;

  let total_intern = listIntern.reduce((a, b) => (a = a + parseFloat(b.amount)), 0);
  let total_intern_real = listIntern.reduce((a, b) => (a = a + parseFloat(b.amount_real)), 0);
  let diff_intern = total_intern - total_intern_real;

  let total_employ = listEmploy.reduce((a, b) => (a = a + parseFloat(b.amount)), 0);
  let total_employ_real = listEmploy.reduce((a, b) => (a = a + parseFloat(b.amount_real)), 0);
  let diff_employ = total_employ - total_employ_real;

  let diff_cash = form.cash_real - form.cash;
  let diff_datafono = form.datafono_real - form.datafono;
  let diff_nequi = form.nequi_real - form.nequi;
  let diff_daviplata = form.daviplata_real - form.daviplata;
  let diff_rappi = form.rappi_real - form.rappi;
  let diff_credit_home = form.credit_home_real - form.credit_home;

  let total_entregado =
    parseFloat(form.cash || 0) +
    parseFloat(form.datafono || 0) +
    parseFloat(form.nequi || 0) +
    parseFloat(form.daviplata || 0) +
    parseFloat(form.rappi || 0) +
    parseFloat(form.credit_home || 0);

  let total_entregado_real =
    parseFloat(form.cash_real) +
    parseFloat(form.datafono_real || 0) +
    parseFloat(form.nequi_real || 0) +
    parseFloat(form.daviplata_real || 0) +
    parseFloat(form.rappi_real || 0) +
    parseFloat(form.credit_home_real || 0);

  let diff_entregado = total_entregado_real - total_entregado;

  let total_sales =
    parseFloat(form.aldelo) -
    (parseFloat(total_employ) + parseFloat(total_turn) + total_intern);

  let total_sales_real =
    parseFloat(form.aldelo_real || 0) -
    (parseFloat(total_employ_real || 0) +
      parseFloat(total_turn_real || 0) +
      total_intern_real || 0);


  let diff_sales = total_sales_real - total_sales;
  let diff_tip = form.tips_real - form.tips;
  let diff_discount = form.discount_real - form.discount;
  let diff_cancellations = form.cancellations - form.cancellations_real;
  let diference = total_entregado - total_sales;
  let diference_real = total_entregado_real - total_sales_real;
  let total_diff = diference_real - diference;

  return (
    <TemplateMenu>
      <Row className="my-3">
        <Col>
          <button className="btn btn-sm btn-secondary" onClick={() => history.goBack()}>Volver</button>
        </Col>
      </Row>
      <Row className="my-3">
        <Col>
          <Row>
            <Col>
              <h4>{ingreso.branch_office !== undefined ? ingreso.branch_office.title : ""}</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <h4>{ingreso.registration_date_formated}</h4>
            </Col>
          </Row>
        </Col>

        {readOnly !== true && (
          <>
            <Col lg={1}>
              {locked === false && (
                <button className="btn btn-info" onClick={() => onSubmit(3, 'auditando')}>Guardar</button>
              )}
            </Col>
            <Col lg={2}>
              {locked === false && (
                <button className="btn btn-success" onClick={() => onSubmit(4, 'finalizado')}>Guardar y Cerrar</button>
              )}
            </Col>
          </>
        )}
      </Row>
      <Row>
        <Col lg={6} xs={6}>
          <Table className="table table-sm table-hover">
            <thead>
              <tr>
                <th>Concepto</th>
                <th>
                  <Row>
                    <Col className="text-center">
                      <span className="text-capitalize">
                        (
                        {ingreso.user !== undefined
                          ? "Registrado: " + ingreso.user.name
                          : "User"}
                        )
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center">
                      {ingreso.user_date_formated}
                    </Col>
                  </Row>
                </th>

                <th>
                  <Row>
                    <Col className="text-center">
                      <span className="text-capitalize">
                        (
                        {ingreso.update_user !== null &&
                          ingreso.update_user !== undefined
                          ? "Revisado: " + ingreso.update_user.name
                          : "Pendiente por Revisar"}
                        )
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center">
                      {ingreso.update_user !== null &&
                        ingreso.update_user !== undefined
                        ? ingreso.update_check_formated
                        : ""}
                    </Col>
                  </Row>
                </th>
                <th>Diferencia</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Ventas POS</td>
                <td align="right">${formatNumber(form.aldelo)}</td>
                {inputSelected === "aldelo" ? (
                  <td>
                    <InputEditIngresos
                      inputSelected={inputSelected}
                      initial_value={form.aldelo_real}
                      onConfirm={onConfirm}
                      onClose={() => setInputSelected({})}
                    />
                  </td>
                ) : (
                  <>
                    <td align="right">
                      ${formatNumber(form.aldelo_real === "0" ? form.aldelo : form.aldelo_real || 0)}
                    </td>
                    <td align="right">
                      <span
                        className={`${diff_aldelo < 0 ? "text-danger" : "text-success"}`}>
                        ${formatNumber(diff_aldelo)}
                      </span>
                    </td>
                    <td>
                      {locked === false && (
                        <>
                          {readOnly !== true && (
                            <MdEditNote size={30} className="cursor" onClick={() => onEditData(form.aldelo, "aldelo")} />
                          )}
                          {form.aldelo !== form.aldelo_real && (
                            <FcCheckmark
                              className="icon-edit cursor-pointer"
                              onClick={() =>
                                onConfimData(form.aldelo, "aldelo")
                              }
                            />
                          )}
                        </>
                      )}
                    </td>
                  </>
                )}
              </tr>
              <tr>
                <td>Pago Internos</td>
                <td align="right">${formatNumber(total_intern)}</td>
                <td align="right">${formatNumber(total_intern_real)}</td>
                <td align="right">
                  <span className={`${diff_intern < 0 ? "text-danger" : "text-success"}`}>
                    {" "}
                    ${formatNumber(diff_intern)}
                  </span>
                </td>
              </tr>

              <tr>
                <td>Pagos Empleados</td>
                <td align="right">${formatNumber(total_employ)}</td>
                <td align="right">${formatNumber(total_employ_real)}</td>
                <td align="right">
                  <span
                    className={`${diff_employ < 0 ? "text-danger" : "text-success"
                      }`}
                  >
                    {" "}
                    ${formatNumber(diff_employ)}
                  </span>
                </td>
              </tr>
              <tr>
                <td>Pago Turneros</td>
                <td align="right">${formatNumber(total_turn)}</td>
                <td align="right">${formatNumber(total_turn_real)}</td>
                <td align="right">
                  <span
                    className={`${diff_turn < 0 ? "text-danger" : "text-success"
                      }`}
                  >
                    ${formatNumber(diff_turn)}
                  </span>
                </td>
              </tr>
              <tr className="table-secondary">
                <td>Ventas Totales</td>
                <td align="right">${formatNumber(total_sales)}</td>
                <td align="right">${formatNumber(total_sales_real)}</td>
                <td align="right">${formatNumber(diff_sales || 0)}</td>
              </tr>
              <tr>
                <td>Efectivo</td>
                <td align="right">${formatNumber(form.cash)}</td>

                {inputSelected === "cash" ? (
                  <td>
                    <InputEditIngresos
                      inputSelected={inputSelected}
                      initial_value={form.cash_real}
                      onConfirm={onConfirm}
                      onClose={() => setInputSelected({})}
                    />
                  </td>
                ) : (
                  <>
                    <td align="right">${formatNumber(form.cash_real || 0)}</td>
                    <td align="right">
                      <span className={`${diff_cash < 0 ? "text-danger" : "text-success"}`}>
                        ${formatNumber(diff_cash)}
                      </span>
                    </td>
                    <td>
                      {locked === false && (
                        <>
                          {readOnly !== true && (
                            <MdEditNote size={30} className="cursor" onClick={() => onEditData(form.cash, "cash")} />
                          )}
                          {form.cash !== form.cash_real && (
                            <FcCheckmark
                              className="icon-edit cursor-pointer"
                              onClick={() => onConfimData(form.cash, "cash")}
                            />
                          )}
                        </>
                      )}
                    </td>
                  </>
                )}
              </tr>
              <tr>
                <td>DataFono</td>
                <td align="right">${formatNumber(form.datafono)}</td>
                <td align="right">${formatNumber(form.datafono_real || 0)}</td>
                <td align="right">
                  {" "}
                  <span
                    className={`${diff_datafono < 0 ? "text-danger" : "text-success"}`}>
                    ${formatNumber(diff_datafono || 0)}
                  </span>
                </td>
                <td>
                  <AiFillBank
                    size={22}
                    className="cursor-pointer"
                    onClick={onShowTax}
                  />
                </td>
              </tr>
              <tr>
                <td>Bancolombia</td>
                <td align="right">${formatNumber(form.nequi)}</td>

                {inputSelected === "nequi" ? (
                  <td>
                    <InputEditIngresos
                      inputSelected={inputSelected}
                      initial_value={form.nequi_real}
                      onConfirm={onConfirm}
                      onClose={() => setInputSelected({})}
                    />
                  </td>
                ) : (
                  <>
                    <td align="right">${formatNumber(form.nequi_real || 0)}</td>
                    <td align="right">
                      <span
                        className={`${diff_nequi < 0 ? "text-danger" : "text-success"
                          }`}
                      >
                        ${formatNumber(diff_nequi)}
                      </span>
                    </td>
                    <td>
                      {locked === false && (
                        <>
                          {readOnly !== true && (
                            <MdEditNote size={30} className="cursor" onClick={() => onEditData(form.nequi, "nequi")} />
                          )}
                          {form.nequi !== form.nequi_real && (
                            <FcCheckmark
                              className="icon-edit cursor-pointer"
                              onClick={() => onConfimData(form.nequi, "nequi")}
                            />
                          )}
                        </>
                      )}
                    </td>
                  </>
                )}
              </tr>
              <tr>
                <td>Davivienda</td>
                <td align="right">${formatNumber(form.daviplata)}</td>

                {inputSelected === "daviplata" ? (
                  <td>
                    <InputEditIngresos
                      inputSelected={inputSelected}
                      initial_value={form.daviplata_real}
                      onConfirm={onConfirm}
                      onClose={() => setInputSelected({})}
                    />
                  </td>
                ) : (
                  <>
                    <td align="right">${formatNumber(form.daviplata_real || 0)}</td>
                    <td align="right">
                      <span
                        className={`${diff_daviplata < 0 ? "text-danger" : "text-success"
                          }`}
                      >
                        ${formatNumber(diff_daviplata)}
                      </span>
                    </td>
                    <td>
                      {locked === false && (
                        <>
                          {readOnly !== true && (
                            <MdEditNote size={30} className="cursor" onClick={() =>
                              onEditData(form.daviplata, "daviplata")
                            } />
                          )}
                          {form.daviplata !== form.daviplata_real && (
                            <FcCheckmark
                              className="icon-edit cursor-pointer"
                              onClick={() =>
                                onConfimData(form.daviplata, "daviplata")
                              }
                            />
                          )}
                        </>
                      )}
                    </td>
                  </>
                )}
              </tr>
              <tr>
                <td>Rappi</td>
                <td align="right">${formatNumber(form.rappi || 0)}</td>

                {inputSelected === "rappi" ? (
                  <td>
                    <InputEditIngresos
                      inputSelected={inputSelected}
                      initial_value={form.rappi_real}
                      onConfirm={onConfirm}
                      onClose={() => setInputSelected({})}
                    />
                  </td>
                ) : (
                  <>
                    <td align="right">${formatNumber(form.rappi_real || 0)}</td>
                    <td align="right">
                      <span
                        className={`${diff_rappi < 0 ? "text-danger" : "text-success"
                          }`}
                      >
                        ${formatNumber(diff_rappi || 0)}
                      </span>
                    </td>
                    <td>
                      {locked === false && (
                        <>
                          {readOnly !== true && (
                            <MdEditNote size={30} className="cursor" onClick={() =>
                              onEditData(form.rappi, "rappi")
                            } />
                          )}
                          {form.rappi !== form.rappi_real && (
                            <FcCheckmark
                              className="icon-edit cursor-pointer"
                              onClick={() =>
                                onConfimData(form.rappi, "rappi")
                              }
                            />
                          )}
                        </>
                      )}
                    </td>
                  </>
                )}
              </tr>
              <tr>
                <td>Credito de la Casa</td>
                <td align="right">${formatNumber(form.credit_home || 0)}</td>

                {inputSelected === "credit_home" ? (
                  <td>
                    <InputEditIngresos
                      inputSelected={inputSelected}
                      initial_value={form.credit_home_real}
                      onConfirm={onConfirm}
                      onClose={() => setInputSelected({})}
                    />
                  </td>
                ) : (
                  <>
                    <td align="right">${formatNumber(form.credit_home_real || 0)}</td>
                    <td align="right">
                      <span
                        className={`${diff_credit_home < 0 ? "text-danger" : "text-success"
                          }`}
                      >
                        ${formatNumber(diff_credit_home)}
                      </span>
                    </td>
                    <td>
                      {locked === false && (
                        <>
                          {readOnly !== true && (
                            <MdEditNote size={30} className="cursor" onClick={() =>
                              onEditData(form.credit_home, "credit_home")
                            } />
                          )}
                          {form.credit_home !== form.credit_home_real && (
                            <FcCheckmark
                              className="icon-edit cursor-pointer"
                              onClick={() =>
                                onConfimData(form.credit_home, "credit_home")
                              }
                            />
                          )}
                        </>
                      )}
                    </td>
                  </>
                )}
              </tr>
              <tr className="table-secondary">
                <td>Entregado</td>
                <td align="right">${formatNumber(total_entregado)}</td>
                <td align="right">${formatNumber(total_entregado_real || 0)}</td>
                <td align="right">${formatNumber(diff_entregado || 0)}</td>
              </tr>
              <tr className="table-secondary">
                <td>Diferencia</td>
                <td align="right">${formatNumber(diference)}</td>
                <td align="right">${formatNumber(diference_real || 0)}</td>
                <td align="right">${formatNumber(total_diff || 0)}</td>
              </tr>
              <tr>
                <td>Cancelaciones</td>
                <td align="right">${formatNumber(form.cancellations || 0)}</td>

                {inputSelected === "cancellations" ? (
                  <td>
                    <InputEditIngresos
                      inputSelected={inputSelected}
                      initial_value={form.cancellations_real}
                      onConfirm={onConfirm}
                      onClose={() => setInputSelected({})}
                    />
                  </td>
                ) : (
                  <>
                    <td align="right">
                      $
                      {formatNumber(
                        form.cancellations_real === "0"
                          ? form.cancellations
                          : form.cancellations_real || 0
                      )}
                    </td>
                    <td align="right">
                      <span
                        className={`${diff_cancellations < 0
                          ? "text-danger"
                          : "text-success"
                          }`}
                      >
                        ${formatNumber(diff_cancellations)}
                      </span>
                    </td>
                    <td>
                      {locked === false && (
                        <>
                          {readOnly !== true && (
                            <MdEditNote size={30} className="cursor" onClick={() =>
                              onEditData(form.cancellations, "cancellations")
                            } />
                          )}
                          {form.cancellations !== form.cancellations_real && (
                            <FcCheckmark
                              className="icon-edit cursor-pointer"
                              onClick={() =>
                                onConfimData(
                                  form.cancellations,
                                  "cancellations"
                                )
                              }
                            />
                          )}
                        </>
                      )}
                    </td>
                  </>
                )}
              </tr>
              <tr>
                <td>Propinas</td>
                <td align="right">${formatNumber(form.tips || 0)}</td>

                {inputSelected === "tips" ? (
                  <td>
                    <InputEditIngresos
                      inputSelected={inputSelected}
                      initial_value={form.tips_real}
                      onConfirm={onConfirm}
                      onClose={() => setInputSelected({})}
                    />
                  </td>
                ) : (
                  <>
                    <td align="right">${formatNumber(form.tips_real || 0)}</td>
                    <td align="right">
                      <span
                        className={`${diff_tip < 0 ? "text-danger" : "text-success"
                          }`}
                      >
                        ${formatNumber(diff_tip || 0)}
                      </span>
                    </td>
                    <td>
                      {locked === false && (
                        <>
                          {readOnly !== true && (
                            <MdEditNote size={30} className="cursor" onClick={() => onEditData(form.tips, "tips")} />
                          )}
                          {form.tips !== form.tips_real && (
                            <FcCheckmark
                              className="icon-edit cursor-pointer"
                              onClick={() => onConfimData(form.tips, "tips")}
                            />
                          )}
                        </>
                      )}
                    </td>
                  </>
                )}
              </tr>
              <tr>
                <td>Descuentos</td>
                <td align="right">${formatNumber(form.discount || 0)}</td>

                {inputSelected === "discount" ? (
                  <td>
                    <InputEditIngresos
                      inputSelected={inputSelected}
                      initial_value={form.discount_real}
                      onConfirm={onConfirm}
                      onClose={() => setInputSelected({})}
                    />
                  </td>
                ) : (
                  <>
                    <td align="right">${formatNumber(form.discount_real || 0)}</td>
                    <td align="right">
                      <span
                        className={`${diff_discount < 0 ? "text-danger" : "text-success"
                          }`}
                      >
                        ${formatNumber(diff_discount)}
                      </span>
                    </td>
                    <td>
                      {locked === false && (
                        <>
                          {readOnly !== true && (
                            <MdEditNote size={30} className="cursor" onClick={() =>
                              onEditData(form.discount, "discount")
                            } />

                          )}
                          {form.discount !== form.discount_real && (
                            <FcCheckmark
                              className="icon-edit cursor-pointer"
                              onClick={() =>
                                onConfimData(form.discount, "discount")
                              }
                            />
                          )}
                        </>
                      )}
                    </td>
                  </>
                )}
              </tr>
              <tr>
                <td>Cantidad De Domicilios</td>
                <td align="right">{formatNumber(form.amount_delivery)}</td>
              </tr>
              <tr>
                <td>Monto Total De Domicilios</td>
                <td align="right">${formatNumber(form.total_delivery)}</td>
              </tr>
              <tr>
                <td>Número De Entradas</td>
                <td align="right">{formatNumber(form.total_input || 0)}</td>
              </tr>
              <tr>
                <td>Comentarios</td>
                <td>{form.comments}</td>
                {inputSelected === "comments_check" ? (
                  <td colSpan={2}>
                    <TextEditIngresos
                      placeholder="Comentarios Check"
                      type="text"
                      inputSelected={inputSelected}
                      initial_value={form.comments_check}
                      onConfirm={onConfirmText}
                      onClose={() => setInputSelected({})}
                    />
                  </td>
                ) : (
                  <>
                    <td>{form.comments_check}</td>
                    <td>
                      {locked === false && (
                        <>
                          {readOnly !== true && (
                            <MdEditNote size={30} className="cursor" onClick={() =>
                              onEditData(
                                form.comments_check,
                                "comments_check"
                              )
                            } />
                          )}
                        </>
                      )}
                    </td>
                  </>
                )}
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col lg={6} xs={6}>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Row>
                    <Col><h5>TOTAL PAGOS TIRILLA POS</h5></Col>
                    <Col lg={3} className="text-right"><h5>${formatNumber(form.intern_total || 0)}</h5></Col>
                  </Row>
                  <Row className="my-1">
                    <Col><h5>TOTAL DETALLE PAGOS ONIX</h5></Col>
                    <Col lg={3} className="text-right"><h5>${formatNumber(totalIntern || 0)}</h5></Col>
                  </Row>

                  <Row className="border-top">
                    <Col><h5>DIFERENCIA PAGOS</h5></Col>
                    <Col lg={3} className={`${(totalIntern - form.intern_total) > 0
                      ? ''
                      : 'text-danger'} " text-right`}>
                      <h5>${formatNumber((totalIntern || 0) - (form.intern_total || 0))}</h5>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col><h5>Pagos Internos</h5></Col>
            <Col>
              {locked === false && (
                <>
                  {readOnly !== true && (
                    <button className="btn btn-info" onClick={() => showModalTmp("Pagos Internos", "payment_intern", 1)}>
                      +
                    </button>
                  )}
                </>
              )}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <table className="table table-bordered table-striped table-sm">
                <thead>
                  <tr>
                    <th>Descripción</th>
                    <th>Observación</th>
                    <th>Valor</th>
                    <th>Confirmación</th>
                    <th>Diferencia</th>
                  </tr>
                </thead>
                <tbody>
                  {listIntern.map((row) => (
                    <ItemDetailCost
                      item={row}
                      key={row.id}
                      onConfirmCost={onConfirmCost}
                      onConfirmCheck={onConfirmCheck}
                      type={1}
                      readOnly={readOnly}
                      locked={locked}
                    />
                  ))}
                  <tr>
                    <td colSpan={2}>
                      <b>Total</b>
                    </td>
                    <td align="right">
                      ${" "}
                      {formatNumber(listIntern.reduce((a, b) => (a = a + parseFloat(b.amount)), 0))}
                    </td>
                    <td align="right">
                      ${" "}
                      {formatNumber(
                        listIntern.reduce((a, b) => (a = a + parseFloat(b.amount_real)), 0)
                      )}
                    </td>
                    <td align="right">
                      ${" "}
                      {formatNumber(
                        listIntern.reduce(
                          (a, b) => (a = a + parseFloat(b.amount_real)),
                          0
                        ) -
                        listIntern.reduce((a, b) => (a = a + parseFloat(b.amount)), 0)
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>

          <Row>
            <Col>Pagos a Empleados</Col>
            <Col>
              {locked === false && (
                <>
                  {readOnly !== true && (
                    <button className="btn btn-info" onClick={() => setShowModalWorker(true)}>+</button>
                  )}
                </>
              )}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <table className="table table-bordered table-striped table-sm">
                <thead>
                  <tr>
                    <th>Descripcións</th>
                    <th>Valor</th>
                    <th>Confirmación</th>
                    <th>Diferencia</th>
                  </tr>
                </thead>
                <tbody>
                  {listEmploy.map((row) => (
                    <ItemDetailCostWorker
                      item={row}
                      key={row.id}
                      onConfirmCost={onConfirmCostWorker}
                      onConfirmCheck={onConfirmCheck}
                      type={3}
                      readOnly={readOnly}
                      locked={locked}
                    />
                  ))}
                  <tr>
                    <td>
                      <b>Total</b>
                    </td>
                    <td align="right">
                      ${" "}
                      {formatNumber(listEmploy.reduce((a, b) => (a = a + parseFloat(b.amount)), 0))}
                    </td>
                    <td align="right">
                      ${" "}
                      {formatNumber(listEmploy.reduce((a, b) => (a = a + parseFloat(b.amount_real)), 0))}
                    </td>
                    <td align="right">
                      ${" "}
                      {formatNumber(
                        listEmploy.reduce(
                          (a, b) => (a = a + parseFloat(b.amount)),
                          0
                        ) -
                        listEmploy.reduce(
                          (a, b) => (a = a + parseFloat(b.amount_real)),
                          0
                        )
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>Pagos a Turneros</Col>
            <Col>
              {locked === false && (
                <>
                  {readOnly !== true && (
                    <button className="btn btn-info" onClick={() => setShowModalWorker(true)}>+</button>
                  )}
                </>
              )}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <table className="table table-bordered table-striped table-sm">
                <thead>
                  <tr>
                    <th>Descripción</th>
                    <th>Valor</th>
                    <th>Confirmación</th>
                    <th>Diferencia</th>
                  </tr>
                </thead>
                <tbody>
                  {listTurn.map((row) => (
                    <ItemDetailCostWorker
                      item={row}
                      key={row.id}
                      onConfirmCost={onConfirmCost}
                      onConfirmCheck={onConfirmCheck}
                      type={2}
                      readOnly={readOnly}
                      locked={locked}
                    />
                  ))}

                  <tr>
                    <td>
                      <b>Total</b>
                    </td>
                    <td align="right">
                      ${" "}
                      {formatNumber(listTurn.reduce((a, b) => (a = a + parseFloat(b.amount)), 0))}
                    </td>
                    <td align="right">
                      ${" "}
                      {formatNumber(listTurn.reduce((a, b) => (a = a + parseFloat(b.amount_real)), 0))}
                    </td>
                    <td align="right">
                      ${" "}
                      {formatNumber(
                        listTurn.reduce((a, b) => (a = a + parseFloat(b.amount)), 0) -
                        listTurn.reduce((a, b) => (a = a + parseFloat(b.amount_real)), 0)
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </Col>
      </Row>
      <ModalAddIngresos
        onOpenParameter={onOpenParameter}
        showModal={showModal}
        onHide={onHide}
        title={titleModal}
        typeData={typeData}
        onSubmit={onSubmitModal}
        parameters={listParameter}
      />

      <ModalAddIngresosWorker
        showModal={showModalWorker}
        onHide={onHideWorker}
        typeData={typeData}
        onSubmit={onSubmitModalWorker}
        workers={listWorkers}
      />

      <ModalAddParameter
        show={modalParam.open}
        title={modalParam.title}
        dataType={modalParam.dataType}
        onHide={() => setModalParam({ ...modalParam, open: false })}
        onSuccess={onCreateParameter}
      />

      <ModalAddTurn
        showModal={showModalTurn}
        onHide={() => setShowModalTurn(false)}
        onSubmit={onSubmitModalTurn}
        listWorkerTurn={listWorkersTurn}
      />

      <ModalTaxPage
        show={modalTax}
        onHide={() => setModaltax(false)}
        listFranchise={listFranchise}
        onSuccess={onAddTax}
        datafono={form.datafono}
      />
    </TemplateMenu>

  );
};

const mapsState = (props) => {
  let { parameter, ingresos, worker } = props;
  return {
    errors: props.ingresos.errors,
    selected: props.ingresos.selected,
    fetching: props.ingresos.fetching_edit,
    fetching_create: props.ingresos.fetching_create,
    ingreso: props.ingresos.row,
    parameters: props.parameter.data,
    user: props.user.data,
    fetching_info: props.user.fetching_info,
    workers: props.worker.data,
    fetching_list: props.worker.fetching_list,
    fetching_parameter: props.parameter.fetching,
    fetching_create_parameter: props.parameter.fetching_create,
    fetching_franchise_list: parameter.fetching_franchise_list,
    data_franchise: parameter.data_franchise,
    fetching_franchise: ingresos.fetching_franchise,
    fetching_list_turn: worker.fetching_list_turn,
    data_turn: worker.data_turn,
  };
};

export default connect(mapsState, {
  getIngresoByDate,
  createIngresos,
  getParameter,
  updateIngresosCheck,
  getWorkersActives,
  createParameter,
  getFranchiseByMethodTotal,
  addTaxFranchise,
  toogleLoader,
  getWorkersTurnActives
})(withRouter(EditIngresosPage));
