import React from 'react'
import { Card } from "react-bootstrap"
import { formatNumber } from "../../utils";

const CardData=({title,onClick,available,expense,amount,tips})=>{
    let color =(available<0)?'danger':'success'
    
    return <Card className='cursor-pointer' onClick={onClick}>
    <Card.Body>
        <Card.Title>{title}</Card.Title>
        
        <table className='table table-sm'>
            <tbody>
            <tr>
                <td>Ingresos</td>
                <td align='right'>{formatNumber(amount)}</td>
            </tr>
            {tips>0?<tr>
                <td>Propinas</td>
                <td align='right'>{formatNumber(tips)}</td>
            </tr>:null}
            <tr>
                <td>Gastos</td>
                <td align='right'>{formatNumber(expense)}</td>
            </tr>
            <tr>
                <td><b>Disponible</b></td>
                <td align='right'><b className={`text-${color}`}>{formatNumber(available)}</b></td>
            </tr>
            </tbody>
        </table>

    </Card.Body>
</Card>
}

export default CardData;