import React, { useEffect, useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';

import { MdClose } from "react-icons/md";

import './index.css'
import { toogleAlert } from '../../Redux/alertDuck'
import { connect } from 'react-redux';
import { jsPDF } from "jspdf";
import ResumeProductOrder from '../Components/Widgets/ResumeProductOrder';
import ButtonNaranja from './../Components/Widgets/ButtonNaranja'
import moment from 'moment'
import { formatNumber } from '../../utils';



const ModalDetailOrder = ({ show, data, onSubmit, updateOrder, onHide, fetching, onData, new_data, toogleAlert }) => {
    const [detail, setDetail] = useState([])
    const [client, setClient] = useState({})
    const [payment, setPayment] = useState({})

    useEffect(() => {
        if (data.length !== 0) {
            setDetail(data.detail);
            setClient(data.client);
            setPayment(data.payment.parameter);
        }
    }, [data])

    useEffect(() => {

        if (fetching === 2) {
            if (new_data !== undefined) {
                if (Object.keys(new_data).length > 0) {
                    if (new_data.status_id === 4 || new_data.status_id === 5) {
                        onHide()
                        toogleAlert(false)
                        updateOrder(new_data)
                    }
                }
            }
        }
    }, [fetching])


    const closeModalTmp = () => {
        onHide()
    }

    useEffect(() => {
        window.onbeforeprint = function () {
            console.log('This will be called before the user prints.');
        };
        window.onafterprint = function () {
            console.log('This will be called after the user prints');
        };
    })



    const printCommand = () => {
        const doc = new jsPDF('p', 'cm', [20, 27]);

        console.log('data', data)
        console.log('detail', detail)


        doc.setProperties({
            title: "Report"
        });

        doc.setFont("helvetica");

        var width = doc.internal.pageSize.getWidth();
        var height = doc.internal.pageSize.getHeight();
        console.log('width', width)
        console.log('height', height)


        doc.text(`Orden ${data.id}`, 4, 1);

        doc.setFontSize(9);

        doc.text(`Cliente ${client.name}`, 3.8, 1.5);
        doc.text(`Telefono ${client.phone}`, 3.8, 2);
        doc.text(`Dirección ${data.address}`, 3.8, 2.5);
        doc.text(`Medio de Pago (${data.payment})`, 3.8, 3);

        let space = 4.2;


        doc.text(`Unit.`, 1, 3.8);
        doc.text(`Descripción`, 2, 3.8);
        doc.text(`Subtotal`, 7, 3.8);

        detail.map(row => {
            doc.text(`${row.quantity}`, 1, space);
            doc.text(`${row.title}`, 2, space);
            doc.text(`$${parseInt(row.price)}`, 7, space);
            space = space + 0.5;
        })

        doc.text(`Total: `, 1, space + 0.5);
        doc.text(`$${data.total}`, 7, space + 0.5);

        doc.autoPrint();
        let win = window.open(doc.output('bloburl'), '_blank');

    }


    return (
        <Modal show={show} size="xl" onHide={closeModalTmp} centered>
            <Modal.Header className="border-bottom-custom py-2 m-0">
                <Row className="d-flex align-items-center m-0 p-0 w-100">
                    <Col lg={9} className="p-0 m-0 d-flex align-items-center ">
                        <h5 className="modal-title text-center font-title pt-1">RESUMEN DE PEDIDO # {data.id}</h5>
                        <span className="pl-3">Origen</span>
                        <span className="bg-gris-20 text-gris rounded-pill px-2 py-0 ml-2">E-commerce</span>
                    </Col>
                    <Col lg={3} className="d-flex justify-content-end p-0 m-0">
                        <span className="text-naranja pr-3 pt-1">{`{ ${data.type_delivery_text} }`} </span>
                        {/* <img src={tus_pedidos} className="size-icon-35  cursor" onClick={printCommand} /> */}
                        <button type="button" className="close p-0 m-0" data-dismiss="modal" aria-label="Close" onClick={closeModalTmp}>
                            <MdClose />
                        </button>
                    </Col>
                </Row>

            </Modal.Header>
            <Modal.Body className="p-0 mx-2 my-0">
                <Row className=" p-0 mx-0 mb-1 rounded-lg bg-azul py-2">
                    <Col lg={1} className="text-white text-center">
                        Cant.
                    </Col>
                    <Col lg={8} className="text-white text-center">
                        Producto
                    </Col>
                    <Col lg={3} className="text-white text-center">
                        Modificadores
                    </Col>
                </Row>

                <Row className="p-0 m-0">
                    {(detail !== undefined) && detail.map((row, i) => <ResumeProductOrder item={row} total={data.total} key={i} />)}
                </Row>


            </Modal.Body>
            <Modal.Footer>

                <Row className="m-0 px-0 py-2 border-top border-bottom w-100">
                    <Col lg={6} className="p-0 m-0 border-right">
                        <Row>
                            <Col lg={4} className="border-right d-flex flex-column justify-content-center">
                                <span >Cliente</span>
                                <span >Teléfono</span>
                                <span >Medio de Pago</span>
                            </Col>
                            <Col lg={8} className="d-flex flex-column justify-content-center">
                                <span className="text-gris">{client.name !== '' && client.name !== null ? client.name : 'No registrado'}</span>
                                <span className="text-gris">{(client !== undefined) ? client.phone : ''}</span>
                                <span className="text-naranja">{payment.description}</span>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={6} className="pr-0">
                        <Row>
                            <Col lg={4} className="border-right d-flex flex-column justify-content-center">
                                <span >Dirección</span>
                                <span >Cubiertos</span>
                                <span >Observaciones</span>
                            </Col>
                            <Col lg={8} className="d-flex flex-column justify-content-center">
                                <span className="text-gris">{data.address}</span>
                                <span className="text-gris">{(data.cutlery == 0 ? 1 : data.cutlery)}</span>
                                <span className="text-naranja">{data.observation}&nbsp;</span>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row className="m-0 px-0 pt-2 w-100">
                    <Col lg={5} className="p-0 m-0">
                        <Row>
                            <Col>
                                <span>Fecha</span>
                                <span className="bg-light rounded-pill px-3 py-0 ml-2 text-gris">{moment(data.updated_at).format('DD MMMM YYYY')}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span>Tiempo</span>
                                <span className="bg-light rounded-pill px-3 py-0 ml-2 text-gris">{moment().diff(data.updated_at, 'minutes')} Min</span>
                                <button className="text-naranja active_list_reservation px-3 rounded-pill" onClick={closeModalTmp}>Cancelar Pedido</button>
                            </Col>

                        </Row>
                        <Row>
                            <Col>
                                <span>Fecha Completa</span>
                                <span className="bg-light rounded-pill px-3 py-0 ml-2 text-gris">{moment(data.updated_at).format('MM-DD-YYYY, h:mm:ss a')}</span>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={7} className="p-0 m-0 d-flex align-items-center justify-content-end">
                        <Row className="w-100 p-0 m-0">
                            <Col lg={6} className="p-0 m-0 mr-3">
                                <Row>
                                    <Col>
                                        Subtotal
                                    </Col>
                                    <Col>
                                        <span className=" d-flex justify-content-end">${formatNumber(data.total)}</span>
                                    </Col>
                                </Row>
                                {data.type_delivery === 1 && (
                                    <Row>
                                        <Col>
                                            Domicilio
                                        </Col>
                                        <Col>
                                            <span className=" d-flex justify-content-end">${parseInt(0)}</span>
                                        </Col>
                                    </Row>
                                )}

                                <Row>
                                    <Col>
                                        <h3 className=" h5 pt-2">Total</h3>
                                    </Col>
                                    <Col>
                                        <h3 className=" h5  pt-2  d-flex justify-content-end">${formatNumber(data.total)}</h3>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={5} className="d-flex align-items-center">
                                {(data.status_id === 2) &&
                                    (<ButtonNaranja title="Listo" onClick={() => onSubmit(data)} />)}

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

const mapsState = (props) => {
    return {
        fetching: props.order.fetching,
        new_data: props.order.row
    }
}

export default connect(mapsState, { toogleAlert })(ModalDetailOrder);