import React from 'react';
import { MdOutlineListAlt } from 'react-icons/md';
import { Link } from 'react-router-dom';


const RowTableWorkerTurn = ({ item, onEdit }) => {
    return (
        <tr className={`p-0 mx-0 border-bottom active_list_reservation m-0 w-100 ${item.status === true ? '' : 'table-secondary'}`}>
            <td className="text-center border-right"><span className="font-15 pt-3 pb-2">{item.name}</span></td>
            <td className="text-center border-right"><span className="font-15 pt-3 pb-2">{item.document}</span></td>
            <td className="text-center border-right"><span className="font-15 pt-3 pb-2">{item.country.description}</span></td>
            <td className="text-center border-right"><span className="font-15 pt-3 pb-2">{item.position.description}</span></td>
            <td className="text-center border-right"><span className="font-15 pt-3 pb-2">{item.branch_office.title}</span></td>
            <td className="text-center border-right"><span className="font-15 pt-3 pb-2">{item.area.description}</span></td>
            <td className={`text-center border-right ¡${item.status === true ? '' : 'table-secondary'}`}><span className="font-15 pt-3 pb-2">{item.status_text}</span></td>
            <td>
                <button className='btn btn-info btn-sm' onClick={() => onEdit(item)}>Editar</button>
                <Link className='cursor-pointer' to={`/trabajadores-documents/${item.id}`}><MdOutlineListAlt size={30} /></Link>

            </td>
            <td>

            </td>
        </tr>
    )
}
export default RowTableWorkerTurn;