import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { formatNumber } from '../../utils'
import InputFieldCurrency from '../Components/Widgets/InputFieldCurrency';


const ItemModalTax = ({ item, onUpdate }) => {

    let { parameter, total } = item

    const [value, setValue] = useState('')
    const [subTotal, setSubtotal] = useState(0)

    useEffect(() => {

        if (item.total !== null) {
            let _cost = item.total.cost === '' && Object.keys(item.total).length === 0 ? 0 : item.total.cost;
            setValue(_cost)
            setSubtotal(_cost * (item.percent / 100))
        }
    }, [item])

    const updateInput = (value, name) => {
        value = value || ''

        setValue(value);
        setSubtotal(((item.percent / 100) * (value || 0)))
        onUpdate(item, value)
    }

    return (
        <tr>
            <td>{parameter.description}</td>
            <td lg={2}>{item.percent}%</td>
            <td>
                <InputFieldCurrency
                    name={"amount"}
                    placeholder=""
                    value={value}
                    onValueChange={updateInput}
                />
            </td>
            <td align='right'>{formatNumber(subTotal)}</td>
        </tr>
    )
}

export default ItemModalTax;