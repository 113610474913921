import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
const TextEditIngresos=({type="number",onConfirm,placeholder="Agrega Valor", initial_value, onClose,inputSelected})=>{
    const [value,setValue] = useState(initial_value)
    return <Row>
        <Col>
            <textarea className='form-control input-sm' placeholder={placeholder} value={value} onChange={(e)=>setValue(e.target.value)}></textarea>
        </Col>
        <Col>
            <button className='btn btn-sm btn-info mr-3' onClick={()=>onConfirm(value,inputSelected)}>Guardar</button>
            <button className='btn btn-sm btn-danger' onClick={onClose}>Cerrar</button>
        </Col>
    </Row>
}

export default TextEditIngresos