import React, { useState } from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import ListFilter from './ListFilter';
import { MdOutlineSearch } from 'react-icons/md';


const SearchSection = ({ placeholder, name, list, onClick, onClean, selectedFilted }) => {
    const [value, setValue] = useState('')
    const [filter, setFilter] = useState('')


    const updateField = (e) => {
        let value = e.target.value;
        setValue(e.target.value);

        if (value === '') {
            onClean()
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        onClick(value);
    }

    const selectedFiltedTmp = (item) => {
        setFilter(item.title)
        selectedFilted(item)
    }


    const printDetail = (item, i) => {
        return (
            <ListFilter key={i} text={item.title} onClick={() => selectedFiltedTmp(item)} />
        )
    }

    return (
        <Row className="mx-0 p-0 justify-content-end d-flex">
            <Col lg={8} md={9} className="bg-white rounded-pill ">
                <div className=" mr-2 ">
                    <form className="" onSubmit={onSubmit}>
                        <Row>
                            <Col md={10}>
                                <input type="text"
                                    placeholder={placeholder}
                                    value={value}
                                    name={name}
                                    className="rounded-pill py-2 search_input"
                                    onChange={updateField} />
                            </Col>
                            <Col md={2} className="pt-1">
                                <button type="button" className="icon-lg btn_search pt-1 " onClick={() => onClick(value)}>
                                    <MdOutlineSearch />

                                </button>
                            </Col>
                        </Row>
                    </form>
                </div>
            </Col>

            {/* <Col lg={4} md={3} className=" p-0 ">
                <div className="btn-group justify-content-end d-flex pt-2">
                    <button type="button" className="btn_filter " data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
                        <div className="icon-header cursor ">
                            <img src={filter_img} className="cursor" />
                        </div>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right shadow  border-filter-naranja px-4  justify-content-center">
                        <button className="dropdown-item list_dark w-100 px-0 " type="button">
                            <div>
                                <img src={separador_superior} className="py-3 " />
                            </div>
                            {(list!==undefined)?list.map((row,i)=>printDetail(row,i)):null}
                            <div >
                                <img src={separador_inferior} className="py-3 " />
                            </div>
                        </button>
                    </div>
                    <div className="d-flex align-items-center mx-3">{filter}</div>
                </div>
            </Col> */}
        </Row>
    )
}

export default SearchSection;