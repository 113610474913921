import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap';
import InputField from '../Components/Widgets/InputField';

const ModalClient = ({ show, onHide, errors, client, onSuccess }) => {

    const [form, setForm] = useState({ business: '' });

    useEffect(() => {
        setForm(client)
    }, [client])

    const onSubmitTmp = () => {
        onSuccess(form)
    }

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Modal show={show}>
            <Modal.Body>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Nombre</label>
                            <InputField
                                placeholder={"Nombre"}
                                value={form.business || ''}
                                name="business"
                                onChange={updateInput}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Correo</label>
                            <InputField
                                type={"email"}
                                placeholder={"Email"}
                                value={form.email || ''}
                                name="email"
                                onChange={updateInput}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <label>Celular</label>
                            <InputField
                                placeholder={"Celulaer"}
                                value={form.phone || ''}
                                name="phone"
                                onChange={updateInput}
                            />
                        </div>
                    </Col>
                </Row>

            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Row >
                    <Col>
                        <button className='btn btn-danger' onClick={onHide}>Cerrar</button>
                    </Col>
                    <Col>
                        <button className='btn btn-success' onClick={onSubmitTmp}>Actualizar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalClient;