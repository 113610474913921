import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import {
  getIngresos,
  reverseIngreso,
  deleteIngreso,
} from "../../Redux/IngresosDuck";
import { getBranch } from "../../Redux/branchofficeDuck";
import { } from "../../Redux/menuDuck";
import { formatNumber } from "../../utils";
import "./index.css";
import moment from "moment";
import ModalUnlockIngresos from "./modal.unlock.ingresos";
import TemplateMenu from "../../template/menu.template";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const IngresosAlternPage = ({
  getIngresos, data,
  deleteIngreso,
  fetching,
  fetching_create,
  user,
  reverseIngreso,
  fetching_reverse,
  fetching_list,
  data_calendar
}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalFilter, setModalFilter] = useState(false);
  const [filter, setFilter] = useState({
    date_init: moment().format("YYYY-MM-01"),
    date_end: moment().format("YYYY-MM-DD"),
    status_selected: 0,
  });
  const [form, setForm] = useState();
  const [list, setList] = useState([]);
  const [diference, setDiference] = useState(0);
  const [listCalendar, setListCalendar] = useState([]);
  const [showDetail, setShowDetail] = useState(0)
  const tableRef = useRef()
  const history = useHistory()

  useEffect(() => {
    getIngresos();
  }, []);

  useEffect(() => {
    if (fetching === 2) {
      getIngresos();
    }
  }, [fetching]);

  useEffect(() => {

    if (fetching_list === 2) {
      setListCalendar(data_calendar)
      setList(data)

      setDiference(data.reduce((a, b) => (a = a + parseFloat(b.diference || 0)), 0))
    }
  }, [fetching_list]);

  useEffect(() => {
    if (fetching_create === 2) {
      getIngresos();
    }
  }, [fetching_create]);
  useEffect(() => {
    if (fetching_reverse === 2) {
      setShowModal(false);
      getIngresos();
    }
  }, [fetching_reverse]);

  const updateInput = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const onFilter = () => {
    console.log("filter", filter);

    let q = `init=${filter.date_init}&end=${filter.date_end}&status=${filter.status_selected}`;

    getIngresos(q);
  };
  const onUnLock = (item) => {
    setForm(item);
    setShowModal(true);
  };

  const onReverseIngreso = (item) => {
    reverseIngreso(item);
  };

  const onDeleteIngreso = (item) => {
    if (window.confirm("¿Realmente deseas eliminar el Cierre?")) {
      deleteIngreso(item);
    }
  };

  const onFilterData = (form) => {
    setModalFilter(false);

    let q = `?status=${form.status_selected}&init=${filter.date_init}&end=${filter.date_end}`;
    getIngresos(q);
  }


  const onGoToDetail = (item) => {
    //   if ((item.status_id === 2 || item.status_id === 4 || item.status_id === 3)) {
    //     history.push(`/ingresos/${item.registration_date}/check`)
    //   } else {

    //   }

    history.push(`/ingresos/${item.registration_date}/check`)


  }

  return (
    <TemplateMenu>
      <Container fluid>
        <Row className="my-4">
          <Col lg={3} md={4}>
            <Row>
              <Col>
                <h4 className="font-title pt-3">INGRESOS DIARIOS</h4>
              </Col>
            </Row>
          </Col>
          {(user.role_id === 1 || user.role_id === 2) && (
            <Col lg={9} md={10}>
              <Row>
                <Col>
                  <div className="form-group">
                    <label>Fecha de Inicio</label>
                    <input
                      className="form-control input-sm"
                      name="date_init"
                      type={"date"}
                      onChange={updateInput}
                      value={filter.date_init}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="form-group">
                    <label>Fecha de Fin</label>
                    <input
                      className="form-control input-sm"
                      name="date_end"
                      type={"date"}
                      onChange={updateInput}
                      value={filter.date_end}
                    />
                  </div>
                </Col>

                <Col>
                  <div className="form-group">
                    <label>Estado</label>
                    <select
                      className="form-control"
                      name="status_selected"
                      value={filter.status_selected}
                      onChange={updateInput}>
                      <option value="0">Seleccionar Todos</option>
                      <option value="1">Nuevo</option>
                      <option value="2">Revisión</option>
                      <option value="4">Finalizado</option>
                    </select>
                  </div>
                </Col>
                <Col className="my-1 d-flex align-items-center">
                  <button className="btn btn-warning" onClick={onFilter}>
                    Filtrar
                  </button>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <Row className="d-flex center justify-content-center">
          <Col lg={7} className="text-center"><h4>VENTAS POS</h4></Col>
        </Row>
        <Row>
          <Col lg={8}>
            <div className="container-calendar-ingresos">
              <div className="container-box-ingresos bg-blue">Domingo<br />{formatNumber(listCalendar.filter(d => parseInt(d.days_week) === 0).reduce((a, b) => a + parseFloat(b.sales), 0))}</div>
              <div className="container-box-ingresos alert-danger">Lunes<br />{formatNumber(listCalendar.filter(d => parseInt(d.days_week) === 1).reduce((a, b) => a + parseFloat(b.sales), 0))}</div>
              <div className="container-box-ingresos alert-secondary">Martes<br />{formatNumber(listCalendar.filter(d => parseInt(d.days_week) === 2).reduce((a, b) => a + parseFloat(b.sales), 0))}</div>
              <div className="container-box-ingresos alert-info">Miercoles<br />{formatNumber(listCalendar.filter(d => parseInt(d.days_week) === 3).reduce((a, b) => a + parseFloat(b.sales), 0))}</div>
              <div className="container-box-ingresos alert-warning">Jueves<br />{formatNumber(listCalendar.filter(d => parseInt(d.days_week) === 4).reduce((a, b) => a + parseFloat(b.sales), 0))}</div>
              <div className="container-box-ingresos alert-primary">Viernes<br />{formatNumber(listCalendar.filter(d => parseInt(d.days_week) === 5).reduce((a, b) => a + parseFloat(b.sales), 0))}</div>
              <div className="container-box-ingresos alert-success">Sabado<br />{formatNumber(listCalendar.filter(d => parseInt(d.days_week) === 6).reduce((a, b) => a + parseFloat(b.sales), 0))}</div>
              {listCalendar.map((d, k) =>
                <div key={k} className={`container-box-ingresos cursor-pointer  
                ${parseInt(d.days_week) === 1 && 'alert-danger'}
                ${parseInt(d.days_week) === 2 && 'alert-secondary'}
                ${parseInt(d.days_week) === 3 && 'alert-info'}
                ${parseInt(d.days_week) === 4 && 'alert-warning'}
                ${parseInt(d.days_week) === 5 && 'alert-primary'} 
                ${parseInt(d.days_week) === 6 && 'alert-success'}
                `} onClick={() => onGoToDetail(d)}>
                  <div className="container-title-ingresos">{d.day}</div>
                  {parseInt(d.sales) !== 0 &&
                    <div className="circle-sales-ingresos">{formatNumber(d.sales)}
                    </div>}
                </div>)}
            </div>
          </Col>
          <Col>
            <Row>
              <Col>
                <ListGroup>
                  <ListGroup.Item className="py-2">
                    <Row>
                      <Col className="cursor-pointer" onClick={() => setShowDetail(showDetail === 1 ? 0 : 1)}>TOTAL POS {showDetail === 1 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</Col>
                      <Col className="text-right">{formatNumber(list.reduce((a, b) => (a = a + parseFloat(b.status_id !== 1 ? b.aldelo_real || 0 : b.aldelo || 0)), 0))}</Col>
                    </Row>
                    {showDetail === 1 &&
                      <Row>
                        <Col>
                          <ListGroup>
                            {list.map((d, k) =>
                              <ListGroup.Item key={k}>
                                <Row>
                                  <Col>{d.registration_date}</Col>
                                  <Col className="text-right">{formatNumber(d.aldelo)}</Col>
                                </Row>
                              </ListGroup.Item>)}
                          </ListGroup>
                        </Col>
                      </Row>
                    }

                  </ListGroup.Item>
                  <ListGroup.Item className="py-2">
                    <Row>
                      <Col className="cursor-pointer" onClick={() => setShowDetail(showDetail === 2 ? 0 : 2)}>TOTAL PAGOS {showDetail === 2 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</Col>
                      <Col className="text-right">{formatNumber(list.reduce((a, b) => (a = a + parseFloat(b.payments || 0)), 0))}</Col>
                    </Row>
                    {showDetail === 2 &&
                      <Row>
                        <Col>
                          <ListGroup>
                            {list.map((d, k) =>
                              <ListGroup.Item key={k}>
                                <Row>
                                  <Col>{d.registration_date}</Col>
                                  <Col className="text-right">{formatNumber(d.payments || 0)}</Col>
                                </Row>
                              </ListGroup.Item>)}
                          </ListGroup>
                        </Col>
                      </Row>
                    }
                  </ListGroup.Item>
                  <ListGroup.Item className="py-2">
                    <Row>
                      <Col className="cursor-pointer" onClick={() => setShowDetail(showDetail === 3 ? 0 : 3)}>VENTA TOTAL {showDetail === 3 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</Col>
                      <Col className="text-right">{formatNumber(list.reduce((a, b) => (a = a + parseFloat(b.entregado || 0)), 0))}</Col>
                    </Row>
                    {showDetail === 3 &&
                      <Row>
                        <Col>
                          <ListGroup>
                            {list.map((d, k) =>
                              <ListGroup.Item key={k}>
                                <Row>
                                  <Col>{d.registration_date}</Col>
                                  <Col className="text-right">{formatNumber(d.entregado || 0)}</Col>
                                </Row>
                              </ListGroup.Item>)}
                          </ListGroup>
                        </Col>
                      </Row>
                    }
                  </ListGroup.Item>
                  <ListGroup.Item className="py-2">
                    <Row>
                      <Col className="cursor-pointer" onClick={() => setShowDetail(showDetail === 4 ? 0 : 4)}>EFECTIVO {showDetail === 4 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</Col>
                      <Col className="text-right">{formatNumber(list.reduce((a, b) => (a = a + parseFloat(b.status_id === 4 ? b.cash_real || 0 : b.cash || 0)), 0))}</Col>
                    </Row>
                    {showDetail === 4 &&
                      <Row>
                        <Col>
                          <ListGroup>
                            {list.map((d, k) =>
                              <ListGroup.Item key={k}>
                                <Row>
                                  <Col>{d.registration_date}</Col>
                                  <Col className="text-right">{formatNumber(parseFloat(d.status_id === 4 ? d.cash_real || 0 : d.cash || 0) || 0)}</Col>
                                </Row>
                              </ListGroup.Item>)}
                          </ListGroup>
                        </Col>
                      </Row>
                    }
                  </ListGroup.Item>
                  <ListGroup.Item className="py-2">
                    <Row>
                      <Col className="cursor-pointer" onClick={() => setShowDetail(showDetail === 5 ? 0 : 5)}>DATAFONO {showDetail === 5 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</Col>
                      <Col className="text-right">{formatNumber(list.reduce((a, b) => (a = a + parseFloat(b.status_id === 4 ? b.datafono_real || 0 : b.datafono || 0)), 0))}</Col>
                    </Row>
                    {showDetail === 5 &&
                      <Row>
                        <Col>
                          <ListGroup>
                            {list.map((d, k) =>
                              <ListGroup.Item key={k}>
                                <Row>
                                  <Col>{d.registration_date}</Col>
                                  <Col className="text-right">{formatNumber(parseFloat(d.status_id === 4 ? d.datafono_real || 0 : d.datafono || 0) || 0)}</Col>
                                </Row>
                              </ListGroup.Item>)}
                          </ListGroup>
                        </Col>
                      </Row>
                    }
                  </ListGroup.Item>
                  <ListGroup.Item className="py-2">
                    <Row>
                      <Col className="cursor-pointer" onClick={() => setShowDetail(showDetail === 6 ? 0 : 6)}>BANCOLOMBIA {showDetail === 6 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</Col>
                      <Col className="text-right">{formatNumber(list.reduce((a, b) => (a = a + parseFloat(b.status_id === 4 ? b.nequi_real || 0 : b.nequi || 0)), 0))}</Col>
                    </Row>
                    {showDetail === 6 &&
                      <Row>
                        <Col>
                          <ListGroup>
                            {list.map((d, k) =>
                              <ListGroup.Item>
                                <Row>
                                  <Col>{d.registration_date}</Col>
                                  <Col className="text-right">{formatNumber(parseFloat(d.status_id === 4 ? d.nequi_real || 0 : d.nequi || 0) || 0)}</Col>
                                </Row>
                              </ListGroup.Item>)}
                          </ListGroup>
                        </Col>
                      </Row>
                    }
                  </ListGroup.Item>
                  <ListGroup.Item className="py-2">
                    <Row>
                      <Col className="cursor-pointer" onClick={() => setShowDetail(showDetail === 7 ? 0 : 7)}>DAVIVIENDA {showDetail === 7 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</Col>
                      <Col className="text-right">{formatNumber(list.reduce((a, b) => (a = a + parseFloat(b.status_id === 4 ? b.daviplata_real || 0 : b.daviplata || 0)), 0))}</Col>
                    </Row>
                    {showDetail === 7 &&
                      <Row>
                        <Col>
                          <ListGroup>
                            {list.map((d, k) =>
                              <ListGroup.Item>
                                <Row>
                                  <Col>{d.registration_date}</Col>
                                  <Col className="text-right">{formatNumber(parseFloat(d.status_id === 4 ? d.daviplata_real || 0 : d.daviplata || 0) || 0)}</Col>
                                </Row>
                              </ListGroup.Item>)}
                          </ListGroup>
                        </Col>
                      </Row>
                    }
                  </ListGroup.Item>
                  <ListGroup.Item className="py-2">
                    <Row>
                      <Col className="cursor-pointer" onClick={() => setShowDetail(showDetail === 8 ? 0 : 8)}>RAPPI {showDetail === 8 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</Col>
                      <Col className="text-right">{formatNumber(list.reduce((a, b) => (a = a + parseFloat(b.status_id === 4 ? b.rappi_real || 0 : b.rappi || 0)), 0))}</Col>
                    </Row>
                    {showDetail === 8 &&
                      <Row>
                        <Col>
                          <ListGroup>
                            {list.map((d, k) =>
                              <ListGroup.Item>
                                <Row>
                                  <Col>{d.registration_date}</Col>
                                  <Col className="text-right">{formatNumber(parseFloat(d.status_id === 4 ? d.rappi_real || 0 : d.rappi || 0) || 0)}</Col>
                                </Row>
                              </ListGroup.Item>)}
                          </ListGroup>
                        </Col>
                      </Row>
                    }
                  </ListGroup.Item>
                  <ListGroup.Item className="py-2">
                    <Row>
                      <Col className="cursor-pointer" onClick={() => setShowDetail(showDetail === 9 ? 0 : 9)}>CASA {showDetail === 9 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</Col>
                      <Col className="text-right">{formatNumber(list.reduce((a, b) => (a = a + parseFloat(b.status_id === 4 ? b.credit_home_real || 0 : b.credit_home || 0)), 0))}</Col>
                    </Row>
                    {showDetail === 9 &&
                      <Row>
                        <Col>
                          <ListGroup>
                            {list.map((d, k) =>
                              <ListGroup.Item>
                                <Row>
                                  <Col>{d.registration_date}</Col>
                                  <Col className="text-right">{formatNumber(parseFloat(d.status_id === 4 ? d.credit_home_real || 0 : d.credit_home || 0) || 0)}</Col>
                                </Row>
                              </ListGroup.Item>)}
                          </ListGroup>
                        </Col>
                      </Row>
                    }
                  </ListGroup.Item>
                  <ListGroup.Item className="py-2">
                    <Row>
                      <Col className="cursor-pointer" onClick={() => setShowDetail(showDetail === 10 ? 0 : 10)}>ENTREGADO {showDetail === 10 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</Col>
                      <Col className="text-right">{formatNumber(list.reduce((a, b) => (a = a + parseFloat(b.entregado || 0)), 0))}</Col>
                    </Row>
                    {showDetail === 10 &&
                      <Row>
                        <Col>
                          <ListGroup>
                            {list.map((d, k) =>
                              <ListGroup.Item>
                                <Row>
                                  <Col>{d.registration_date}</Col>
                                  <Col className="text-right">{formatNumber(parseFloat(d.status_id === 4 ? d.entregado || 0 : d.entregado || 0) || 0)}</Col>
                                </Row>
                              </ListGroup.Item>)}
                          </ListGroup>
                        </Col>
                      </Row>
                    }
                  </ListGroup.Item>
                  <ListGroup.Item className="py-2" variant={`${diference < 0 && 'danger'}`}>
                    <Row>
                      <Col className="cursor-pointer" onClick={() => setShowDetail(showDetail === 11 ? 0 : 11)}>DIFERENCIA {showDetail === 11 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</Col>
                      <Col className="text-right font-weigth">{formatNumber(diference)}</Col>
                    </Row>
                    {showDetail === 11 &&
                      <Row>
                        <Col>
                          <ListGroup>
                            {list.map((d, k) =>
                              <ListGroup.Item>
                                <Row>
                                  <Col>{d.registration_date}</Col>
                                  <Col className="text-right">{formatNumber(parseFloat(d.diference))}</Col>
                                </Row>
                              </ListGroup.Item>)}
                          </ListGroup>
                        </Col>
                      </Row>
                    }
                  </ListGroup.Item>
                  <ListGroup.Item className="py-2">
                    <Row>
                      <Col className="cursor-pointer" onClick={() => setShowDetail(showDetail === 12 ? 0 : 12)}>PROPINA {showDetail === 12 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</Col>
                      <Col className="text-right">{formatNumber(list.reduce((a, b) => (a = a + parseFloat(b.status_id === 4 ? b.tips_real || 0 : b.tips || 0)), 0))}</Col>
                    </Row>
                    {showDetail === 12 &&
                      <Row>
                        <Col>
                          <ListGroup>
                            {list.map((d, k) =>
                              <ListGroup.Item>
                                <Row>
                                  <Col>{d.registration_date}</Col>
                                  <Col className="text-right">{formatNumber(parseFloat(d.status_id === 4 ? d.tips_real || 0 : d.tips || 0) || 0)}</Col>
                                </Row>
                              </ListGroup.Item>)}
                          </ListGroup>
                        </Col>
                      </Row>
                    }
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
          </Col>
        </Row>

        <ModalUnlockIngresos
          show={showModal}
          item={form}
          onDeleteIngreso={onDeleteIngreso}
          onSuccess={onReverseIngreso}
          handleClose={() => setShowModal(false)}
        />
      </Container>
    </TemplateMenu >
  );
};

const mapsState = (props) => {
  let { ingresos, user, menu, branchoffice } = props
  return {
    user: user.data,
    data: ingresos.data,
    data_calendar: ingresos.calendar,
    fetching: ingresos.fetching_delete,
    fetching_create: ingresos.fetching_create,
    fetching_reverse: ingresos.fetching_reverse,
    fetching_list: ingresos.fetching_list,
    fetching_branch: branchoffice.fetching,
    branch_offices: branchoffice.data,
    filter_branch: menu.branch.filter,
    fetching_filter: menu.fetching_filter,
  };
};

export default connect(mapsState, {
  getIngresos,
  reverseIngreso,
  deleteIngreso,
  getBranch,
})(IngresosAlternPage);
