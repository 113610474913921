import React from 'react'
import { Link } from 'react-router-dom';

const ItemPermission=({item})=>{
    return (
        <tr>
            <td>{item.description}</td>
            <td>{item.status_text}</td>
            <td><Link className='btn btn-info btn-sm' to={`/permisos/${item.id}`}>Asignar</Link></td>
        </tr>
    )
}

export default ItemPermission;