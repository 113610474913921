import React from 'react';
import { Col, Row } from 'react-bootstrap';
import logo from 'Images/logo_madera.png'
import { formatNumber } from '../../../utils';

const API_URL = process.env.REACT_APP_API_URL


const ResumeProductOrder = ({ item, total }) => {
    let { product } = item
    let image = (product.image === '') ? logo : API_URL + product.image
    let { detail_additional } = item || []


    const printDetail = (row, i) => {
        let { modifier_product } = row

        return (
            <Row key={i}>
                <Col>({row.quantity}) {row.title} <span className="text-gray-60">({modifier_product.title})</span>({formatNumber(modifier_product.price)})</Col>
            </Row>
        )
    }

    return (
        <Row className="p-0 mx-0 mb-1 bg-white active_list_reservation rounded-lg border w-100">

            <Col lg={1} className="py-2 d-flex flex-column justify-content-center align-items-center border-right">
                <span className="h3 p-0 m-0">{item.quantity}</span>
                <span>Unid</span>
            </Col>
            <Col lg={5} className="d-flex align-items-center py-1 border-right">
                <Row>
                    <Col lg={3} className="pr-0">
                        <img src={image} className="rounded img-fluid" />
                    </Col>
                    <Col lg={9} className="pl-3">
                        <Row>
                            <Col><span className="h5">{product.title}</span></Col>
                        </Row>
                        <Row>
                            <Col>{product.description}</Col>
                        </Row>
                        <Row>
                            <Col>${formatNumber(total)}</Col>
                        </Row>
                    </Col>
                </Row>

            </Col>
            <Col lg={6} className="">
                {(detail_additional !== undefined) && detail_additional.map(printDetail)}
            </Col>
        </Row>
    );
}

export default ResumeProductOrder;