import React from 'react'
import { formatNumber } from '../../utils';

const ItemDetailTable=({item})=>{
    let {worker} = item
    let {position} = worker
    let total =item.amount - item.amount * (item.discount/100)
    return <tr>
        <td>{worker.name}</td>
        <td>{position.description}</td>
        <td>{worker.type_worker_text}</td>
        <td>{formatNumber(item.amount)}</td>
        <td>{item.discount}%</td>
        <td>{formatNumber(total)}</td>
    </tr>
}


export default ItemDetailTable