import React from 'react'

const ItemFranchise = ({ item, onEdit }) => {
    let { parameter } = item

    return (
        <tr>
            <td>{parameter.description}</td>
            <td>{item.percent}%</td>
            <td>
                <button className='btn btn-sm btn-info' onClick={()=>onEdit(item)}>Editar</button>
            </td>
        </tr>
    )
}

export default ItemFranchise;