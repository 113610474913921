import { getHeaders } from '../utils';

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_list: 0,
    fetching_create: 0,
    fetching_by_id: 0,
    data: [],
    errors: {},
    row: {}
}

let LIST_SETTLEMENT = "LIST_SETTLEMENT";
let LIST_SETTLEMENT_SUCCESS = "LIST_SETTLEMENT_SUCCESS";
let CREATE_SETTLEMENT = "CREATE_SETTLEMENT_SUCCESS";
let CREATE_SETTLEMENT_SUCCESS = "CREATE_SETTLEMENT_SUCCESS";
let SETTLEMENT_BY_ID = "SETTLEMENT_BY_ID";
let SETTLEMENT_BY_ID_SUCCESS = "SETTLEMENT_BY_ID_SUCCESS";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_SETTLEMENT:
            return { ...state, fetching_list: 1, }
        case LIST_SETTLEMENT_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        case CREATE_SETTLEMENT:
            return { ...state, fetching_create: 1 }
        case CREATE_SETTLEMENT_SUCCESS:
            return { ...state, fetching_create: 2 }
        case SETTLEMENT_BY_ID:
            return { ...state, fetching_by_id: 1 }
        case SETTLEMENT_BY_ID_SUCCESS:
            return { ...state, fetching_by_id: 2, row: action.payload }
        default:
            return state;
    }
}

export let getSettlement = () => (dispatch, getState) => {
    dispatch({
        type: LIST_SETTLEMENT,
    });

    let url = `${API_URL}/api/settlement`

    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: LIST_SETTLEMENT_SUCCESS,
            payload: resp.results
        });
    })
}

export let getSettlementById = (id) => (dispatch, getState) => {
    dispatch({
        type: SETTLEMENT_BY_ID,
    });

    let url = `${API_URL}/api/settlement/${id}`

    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: SETTLEMENT_BY_ID_SUCCESS,
            payload: resp
        });
    })
}

export let onCreateSettlement = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_SETTLEMENT,
    });

    let url = `${API_URL}/api/settlement`

    let branch = JSON.parse(localStorage.branch)

    form.branch_id = branch.id

    fetch(url, {
        method: 'POST',
        body: JSON.stringify(form),
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {

        dispatch({
            type: CREATE_SETTLEMENT_SUCCESS,
        });
    })
}