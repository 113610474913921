import React, { Fragment, useEffect, useState } from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import { connect } from "react-redux";
import TemplateMenu from "../../template/menu.template";
import { getSalesFilesById } from "../../Redux/inventoryDuck";
import { getParameterBySegment } from "../../Redux/parameterDuck";
import { toogleLoader } from "../../Redux/alertDuck";
import ItemTableUpload from "./item.table.upload";
import { formatNumber } from "../../utils";
import { AiFillEye } from "react-icons/ai";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const UploadSalesDetailPage = ({ getSalesFilesById, data, fetching_files, toogleLoader }) => {
  let { id, date_init, date_end } = useParams();
  const [reviews, setReviews] = useState([]);
  const [form, setForm] = useState({ date_init: "", date_end: "" });
  const [total, setTotal] = useState(0);
  useEffect(() => {

    setForm({
      ...form,
      ["date_init"]: date_init,
      ["date_end"]: date_end,
    });

    getSalesFilesById(id, date_init, date_end);
    toogleLoader(true)
  }, [id]);

  useEffect(() => {
    if (fetching_files === 2) {
      setReviews(data);
      let _total = data.reduce(
        (a, { products }) =>
          a + products.reduce((c, d) => c + parseFloat(d.total), 0),
        0
      );

      setTotal(_total);
      toogleLoader(false)

    }
  }, [fetching_files]);

  const updateInput = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const onFilter = () => {
    getSalesFilesById(id, form.date_init, form.date_end);
  };
  return (
    <TemplateMenu>
      <Row className="my-3 d-flex justify-content-center" >
        <Col lg={6}>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Fecha de Inicio</Form.Label>
                <Form.Control
                  type="date"
                  value={form.date_init}
                  name="date_init"
                  onChange={updateInput}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Fecha Fin</Form.Label>
                <Form.Control
                  type="date"
                  value={form.date_end}
                  name="date_end"
                  onChange={updateInput}
                />
              </Form.Group>
            </Col>
            <Col
              lg={3}
              className="d-flex justify-content-center align-items-center"
            >
              <button className="btn btn-sm btn-info" onClick={onFilter}>
                Filtrar
              </button>
            </Col>
          </Row>
        </Col>

      </Row>
      <Row className="my-3 d-flex justify-content-center">
        <Col lg={6}><h3>TOTAL ${formatNumber(total)}</h3></Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col lg={6}>
          <Table className="table-sm">
            <tbody>
              {reviews.map((d, k) => {
                let { products } = d;

                let total = products.reduce(
                  (a, b) => a + parseFloat(b.total),
                  0
                );
                let quantity = products.reduce(
                  (a, b) => a + parseFloat(b.quantity),
                  0
                );
                return (
                  <Fragment key={k}>
                    <tr>
                      <td>
                        <b>{d.title_pos}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Table className="table-sm table-condensed table-bordered table-hover">
                          <thead className="thead-dark">
                            <tr>
                              <th width="70%">Producto</th>
                              <th width="10%">Cantidad</th>
                              <th width="20%" className="text-right">Total Ventas</th>
                            </tr>
                          </thead>
                          <tbody>
                            {products.map((p, key) => (
                              <tr key={key}>
                                <td>{p.product}</td>
                                <td align="right">{p.quantity}</td>
                                <td align="right">{formatNumber(p.total)}</td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td>
                                <b>TOTAL</b>
                              </td>
                              <td align="right">
                                <b>{formatNumber(quantity)}</b>
                              </td>
                              <td align="right">
                                <b>{formatNumber(total)}</b>
                              </td>
                            </tr>
                          </tfoot>
                        </Table>
                      </td>
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </TemplateMenu>
  );
};
const mapsState = (props) => {
  let { inventory } = props;
  return {
    fetching_confirm: inventory.fetching_confirm,
    data: inventory.data.results,
    fetching_files: inventory.fetching_files,
  };
};

export default connect(mapsState, { getSalesFilesById, toogleLoader })(UploadSalesDetailPage);
