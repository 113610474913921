import React, { useEffect, useState } from "react";
import { Card, Col, ListGroup, Row } from "react-bootstrap";
import TemplateMenu from "../../template/menu.template";
import { createOrUpdateRecipeBooksAdd, getCategoryRecipe } from "../../Redux/recipe.book.Duck";
import { toogleLoader } from "../../Redux/alertDuck";
import { connect } from "react-redux";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FormPrescriptionKitchen from "./form.prescription.kitchen";

const CreatePrescriptionKitckenPage = ({ createOrUpdateRecipeBooksAdd, getCategoryRecipe, fetching_list,
  data, fetching_create, toogleLoader
}) => {
  const history = useHistory();


  const [modal, setModal] = useState(false);
  const [parameter, setParameter] = useState({ recipe_type: 0, title: "" });


  useEffect(() => {
    if (fetching_create === 2) {
      toogleLoader(false)
      history.goBack();
    }
  }, [fetching_create]);

  const onSubmit = (form) => {
    toogleLoader(true)
    createOrUpdateRecipeBooksAdd(form);
  }

  return (
    <TemplateMenu>
      <Row className="my-2">
        <Col lg={1}>
          <button className="btn btn-secondary btn-sm" onClick={() => history.goBack()}>
            Volver
          </button>
        </Col>
        <Col>
          <h2>Agregar Receta Adicional</h2>
        </Col>
      </Row>
      <FormPrescriptionKitchen
        onSubmit={onSubmit}
      />
    </TemplateMenu>
  );
};

const mapsState = (props) => {
  let { recipe_book } = props;
  return {
    fetching_list: recipe_book.fetching_list,
    fetching_create: recipe_book.fetching_create,
    data: recipe_book.data,
  };
};

export default connect(mapsState, {
  createOrUpdateRecipeBooksAdd,
  getCategoryRecipe, toogleLoader
})(CreatePrescriptionKitckenPage);
