import React, { useEffect, useState } from 'react';
import Routes from './Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';

import { useHistory } from "react-router-dom";
import { logOutAction, getInformationUser, getFormPermissions } from './Redux/userDuck'
import { getBranch } from './Redux/branchofficeDuck'
import { getMenu, onToogleDrawer, onGetOrSetFilter } from './Redux/menuDuck'
import { toogleFullScreen } from './Redux/screenDuck'
import { ToastProvider, } from 'react-toast-notifications';
import LoaderPage from './Pages/Loader';
import FullScreenPage from './Pages/Publicity/full.screen';

const App = ({ getInformationUser, data, getMenu, menu, fetching_menu, loader, fetching_filter, fetching_info, getBranch, full_screen, toogleFullScreen,
  selected_screen, getFormPermissions }) => {

  useEffect(() => {
    getInformationUser()
    getMenu()
    getBranch()
    getFormPermissions()
  }, [])

  // useEffect(() => {

  //   if (fetching_filter === 2) {
  //     window.location.reload()
  //   }


  // }, [fetching_filter])


  const onCloseFullScreen = () => {
    window.document.exitFullscreen().then(() => console.log("close"))
    toogleFullScreen()
  }


  return (
    <Router>
      <FullScreenPage show={full_screen} onClick={onCloseFullScreen} selected={selected_screen} />
      <LoaderPage show={loader} />
      <ToastProvider>
        <Routes />
      </ToastProvider>
    </Router>
  );
}

const mapsState = (props) => {

  let { user, menu, alert, branchoffice, screen } = props

  return {
    loggedIn: user.loggedIn,
    data: user.data,
    fetching_info: user.fetching_info,
    menu: menu.data,
    fetching_menu: menu.fetching,
    fetching_filter: menu.fetching_filter,
    drawer: menu.drawer,
    loader: alert.loader,
    full_screen: screen.full_screen,
    selected_screen: screen.selected_screen,
    branch_offices: branchoffice.data,
    branch_selected: branchoffice.branch,
    fetching_branch: branchoffice.fetching
  }
}

export default connect(mapsState, {
  getInformationUser, logOutAction, getMenu, onToogleDrawer, onGetOrSetFilter,
  getBranch, toogleFullScreen, getFormPermissions
})(App)