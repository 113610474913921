import React from 'react'
import { formatNumber } from '../../utils';

const ItemTableFranchise = ({ item }) => {
    return (
        <tr>
            <td>{item.registration_date}</td>
            <td>{item.franchise}</td>
            <td>{item.percent}</td>
            <td>{formatNumber(item.subtotal)}</td>
            <td>{formatNumber(item.tax)}</td>
            <td>{formatNumber(item.total)}</td>
        </tr>
    )
}

export default ItemTableFranchise;