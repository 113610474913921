import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_id: 0,
    current_page:1,
    last_page:1,
    fetching_activate: 0,
    fetching_available: 0,
    fetching_available_new: 0,
    fetching_exchange: 0,
    fetching_cancel: 0,
    fetching_dates: 0,
    fetching_reservation: 0,
    fetching_change: 0,
    availables: [],
    dates: [],
    detail: [],
    data: [],
    errors: ''
}

const RESERVE = "RESERVE"
const RESERVE_SUCCESS = "RESERVE_SUCCESS"
const RESERVE_ERROR = "RESERVE_ERROR"
const ACTIVATE = "ACTIVATE"
const ACTIVATE_SUCCESS = "ACTIVATE_SUCCESS"
const AVAILABLE = "AVAILABLE"
const AVAILABLE_SUCCESS = "AVAILABLE_SUCCESS"

const AVAILABLE_NEW = "AVAILABLE_NEW"
const AVAILABLE_NEW_SUCCESS = "AVAILABLE_NEW_SUCCESS"

const DATES = "DATES"
const DATES_SUCCESS = "DATES_SUCCESS"
const CANCEL_RESERV = "CANCEL_RESERV"
const CANCEL_RESERV_SUCCESS = "CANCEL_RESERV_SUCCESS"
const DETAIL_ID = "DETAIL_ID"
const DETAIL_ID_SUCCESS = "DETAIL_ID_SUCCESS"
const CHANGE_DINING = "CHANGE_DINING"
const CHANGE_DINING_SUCCESS = "CHANGE_DINING_SUCCESS"



let RESERVATION = "RESERVATION";
let RESERVATION_SUCCESS = "RESERVATION_SUCCESS";
let RESERVATION_SUCCESS_RESTART = "RESERVATION_SUCCESS_RESTART"

let EXCHANGE = "EXCHANGE";
let EXCHANGE_SUCCESS = "EXCHANGE_SUCCESS";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case RESERVE: {
            return { ...state, fetching: 1 };
        }
        case RESERVE_SUCCESS: {
            return { ...state, fetching: 2, data: action.payload };
        }
        case CANCEL_RESERV: {
            return { ...state, fetching_cancel: 1 };
        }
        case CANCEL_RESERV_SUCCESS: {
            return { ...state, fetching_cancel: 2 };
        }
        case RESERVE_ERROR: {
            return { ...state, fetching: 3, errors: action.payload };
        }
        case ACTIVATE: {
            return { ...state, fetching_activate: 1 };
        }
        case ACTIVATE_SUCCESS: {
            return { ...state, fetching_activate: 2, data: action.payload };
        }
        case AVAILABLE: {
            return { ...state, fetching_available: 1 }
        }
        case AVAILABLE_SUCCESS: {
            return { ...state, fetching_available: 2, availables: action.payload }
        }
        
        case AVAILABLE_NEW: {
            return { ...state, fetching_available_new: 1 }
        }
        case AVAILABLE_NEW_SUCCESS: {
            return { ...state, fetching_available_new: 2 }
        }
        
        case DATES:
            return { ...state, fetching_dates: 1 }
        case DATES_SUCCESS:
            return { ...state, fetching_dates: 2, dates: action.payload }

        case DETAIL_ID: {
                return { ...state, fetching_id: 1 };
        }
        case DETAIL_ID_SUCCESS: {
                return { ...state, fetching_id: 2, detail: action.payload };
        }

        case RESERVATION:
            return { ...state, fetching_reservation: 1 }
        case RESERVATION_SUCCESS:
            let _current = action.payload;
            let _current_page = action.payload.current_page;

            console.log('_current_page',_current_page)

            let _data = state.data.concat(_current.data)


            if(action.payload.current_page===1){
                 _data = _current.data
            }
            

            _current.data = _data;
        
            _current.current_page = _current_page
            
            return { ...state, fetching_reservation: 2 , ..._current }
        case RESERVATION_SUCCESS_RESTART:

            let {data} = action.payload;

            // // console.log('data',data)

            return { ...state, fetching_reservation: 2 , data }
        case CHANGE_DINING:

            return { ...state,fetching_change:1 }
        case CHANGE_DINING_SUCCESS:
            return { ...state,fetching_change:2 }

        case EXCHANGE:
            return { ...state,fetching_exchange:1 }
        case EXCHANGE_SUCCESS:
            return { ...state,fetching_exchange:2 }

        default:
            return state;
    }
}


export let exchangeDining = (first,second) => (dispatch, getState) => {


    console.log('first',first)
    console.log('second',second)

    

    dispatch({
        type: EXCHANGE
    });

    let token = localStorage.getItem("access_token")

    let url = `${API_URL}/api/exchange/${first.reservation_id}/${second.reservation_id}`

    console.log('url',url)

    axios.put(url,{},
        {
            headers: {
                Authorization: 'Bearer ' + token //the token is a variable which holds the token
            }
        }).then(resp => {

            dispatch({
                type: EXCHANGE_SUCCESS,
            });

        }).catch(function (error) {
            console.log(error);
        });
}

export let updateChangeDining = (schedule_id,reservation_id) => (dispatch, getState) => {


    console.log('schedule',schedule_id)
    console.log('reservation_id',reservation_id)

    dispatch({
        type: CHANGE_DINING
    });

    let token = localStorage.getItem("access_token")

    let url = `${API_URL}/api/reservation-change/${schedule_id}/${reservation_id}`

    console.log('url',url)

    axios.put(url,{},
        {
            headers: {
                Authorization: 'Bearer ' + token //the token is a variable which holds the token
            }
        }).then(resp => {

            dispatch({
                type: CHANGE_DINING_SUCCESS,
            });

        }).catch(function (error) {
            console.log(error);
        });
}



export let getDiningAvailableChange = (branch_id, people, date,pet) => (dispatch, getState) => {
    dispatch({
        type: AVAILABLE
    });

    let form = {}
    form.people = people;   

    let token = localStorage.getItem("access_token")

    let url = `${API_URL}/api/reservation-available/${branch_id}/${people}/${date}/${pet}`

    axios.get(url,
        {
            headers: {
                Authorization: 'Bearer ' + token //the token is a variable which holds the token
            }
        }).then(resp => {

            dispatch({
                type: AVAILABLE_SUCCESS,
                payload: resp.data.results
            });

        }).catch(function (error) {
            console.log(error);
        });
}




export let getSchedule=(date,branch_id, current_page,restart)=>(dispatch,getState)=>{
    dispatch({
        type:RESERVATION
    })
    
    restart = restart || false

    console.log('restart',restart)

    current_page = current_page || getState().reservation.current_page

    branch_id = branch_id||0

    let  d = new Date();
    let month = d.getMonth()+1
    month = (month>9)?month:'0'+month

    date = date || (d.getFullYear()+"-"+(month)+"-"+d.getDate())

    if(getState().user.loggedIn){
        let token = localStorage.getItem("access_token")


        // axios.get(`${API_URL}api/dining-table-schedule/${branch_id}/${date}?page=${current_page}`, {
        axios.get(`${API_URL}/api/dining-table-schedule/${branch_id}/${date}?page=${current_page}`, {
            headers: {
                Authorization: 'Bearer ' + token //the token is a variable which holds the token
            }
        }).then(resp=>{

            if(restart){
                
                dispatch({
                    type:RESERVATION_SUCCESS_RESTART,
                    payload:resp.data
                });
            }else{
                dispatch({
                    type:RESERVATION_SUCCESS,
                    payload:resp.data
                });    
            }
            

        }).catch(function (error) {
            console.log(error);
        });  
    }else{
        console.log("no Ingreso");
    }
}


function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

export let createReservInstant = (form, row, dates) => (dispatch, state) => {
    dispatch({
        type: AVAILABLE_NEW
    })

    let d = new Date();

    let date = formatDate(d);

    form.date_start = dates.date_start

    if (form.date_start === '') {
        form.date_start = date
    }

    let token = localStorage.getItem("access_token")

    let err = {}, cont = 0

    if (form.name === '') {
        err.name = "Nombres Obligatorio"
        cont++;
    }
    // if (form.email === '') {
    //     err.email = "Email Obligatorio"
    //     cont++;
    // }
    
    if (form.phone === '') {
        err.phone = "Telefeono Obligatorio"
        cont++;
    }
    if (form.people === '') {
        err.people = "Personas Obligatorio"
        cont++;
    }

    if (parseInt(form.people) > parseInt(row.people)) {
        err.people = `Campo Supera la cantidad Permitida ${row.people}`
        cont++;
    }

    if (cont > 0) {
        dispatch({
            type: RESERVE_ERROR,
            payload: err
        })
        return
    }

    return true


    // // axios.post(`${API_URL}api/reservation-admin`, form, {
    // axios.post(`${API_URL}/api/reservation-admin`, form, {
    //     headers: {
    //         Authorization: 'Bearer ' + token //the token is a variable which holds the token
    //     }
    // })
    //     .then(response => {
    //         dispatch({
    //             type: AVAILABLE_NEW_SUCCESS
    //         })
    //     }).catch(error => {
    //         let err = {}

    //         if (error.response.status === 422) {
    //             err = { email: '', phone: '', name: '' }

    //             for (const [key, value] of Object.entries(error.response.data.errors)) {
    //                 if ('email' === key) {
    //                     err.email = value[0]

    //                     if (value[0] === 'The email has already been taken.') {
    //                         err.message = "Email ya Existe"
    //                     }

    //                 }
    //                 if ('phone' === key) {
    //                     err.phone = value[0]
    //                     if (value[0] === 'The phone has already been taken.') {
    //                         err.message = "Número de Teléfono ya Existe"
    //                     }


    //                 }
    //                 if ('name' === key) {
    //                     err.name = value[0]
    //                 }

    //             }
    //         } else {
    //             err.message = "Datos Incorrectos"
    //         }

    //         dispatch({
    //             type: RESERVE_ERROR,
    //             payload: err
    //         })
    //     });
}

export let getReservation = (form) => (dispatch, state) => {
    dispatch({
        type: RESERVE,
        fetching: true
    })

    let token = localStorage.getItem("access_token")


    axios.get(`${API_URL}/api/reservation-admin`, {
        headers: {
            Authorization: 'Bearer ' + token //the token is a variable which holds the token
        }
    })
        .then(response => {
            dispatch({
                type: RESERVE_SUCCESS,
                payload: response.data
            })
        })
}


export let getReservationDetail = (detail) => (dispatch, state) => {

    dispatch({
        type: DETAIL_ID
    })

    let token = localStorage.getItem("access_token")


    axios.get(`${API_URL}api/reservation-admin-detail/${detail.reservation_id}`, {
        headers: {
            Authorization: 'Bearer ' + token //the token is a variable which holds the token
        }
    })
        .then(response => {
            dispatch({
                type: DETAIL_ID_SUCCESS,
                payload: response.data
            })
        })
}

export let joinReservation = (reservation, schedule) => (dispatch, state) => {
    dispatch({
        type: ACTIVATE
    })

    let token = localStorage.getItem("access_token")

    let url = `${API_URL}/api/reservation-activate/${schedule.id}`
    
    
    axios.put(url, reservation, {
        headers: {
            Authorization: 'Bearer ' + token //the token is a variable which holds the token
        }
    })
        .then(response => {
            dispatch({
                type: ACTIVATE_SUCCESS,
                payload: response.data.data
            })
        })
}

export let cancelReservation = (id) => (dispatch, state) => {
    dispatch({
        type: CANCEL_RESERV
    })

    let token = localStorage.getItem("access_token")

    axios.put(`${API_URL}/api/cancel-reservation/${id}`, {}, {
        headers: {
            Authorization: 'Bearer ' + token //the token is a variable which holds the token
        }
    })
        .then(response => {
            dispatch({
                type: CANCEL_RESERV_SUCCESS
            })
        })
}

export let getDateReserv = (branch) => (dispatch, getState) => {

    dispatch({
        type: DATES
    });

    let token = ""
    let url = `${API_URL}/api/list-dates`


    axios.get(url,
        {
            headers: {
                Authorization: 'Bearer ' + token //the token is a variable which holds the token
            }
        }).then(resp => {

            dispatch({
                type: DATES_SUCCESS,
                payload: resp.data.results
            });

        }).catch(function (error) {
            console.log(error);
        });
}


export let getDiningAvailable = (people, date) => (dispatch, getState) => {
    dispatch({
        type: AVAILABLE
    });

    let form = {}
    form.people = people;

    let token = localStorage.getItem("access_token")

    let url = `${API_URL}/api/reservation-available-admin/${people}/${date}`

    axios.get(url,
        {
            headers: {
                Authorization: 'Bearer ' + token //the token is a variable which holds the token
            }
        }).then(resp => {

            dispatch({
                type: AVAILABLE_SUCCESS,
                payload: resp.data.results
            });

        }).catch(function (error) {
            console.log(error);
        });
}

