import React, { useEffect, useState } from 'react'
import TemplateMenu from "../../template/menu.template";
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { connect } from 'react-redux';
import { Col, Row, Table } from 'react-bootstrap';
import { getDiffenceDetailByProduct } from "../../Redux/inventoryDuck";
import { formatNumber } from '../../utils';
import moment from 'moment';


const DiferenceDetailPage = ({ getDiffenceDetailByProduct, fetching_diference_by_product, data }) => {
    let { id } = useParams()

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const date_init = params.get('date_init');
    const date_end = params.get('date_end');
    const [listPurchase, setListPurchase] = useState([])
    const [listLownDown, setListLownDown] = useState([])
    const [listSales, setListSales] = useState([])
    const [product, setProduct] = useState({ name: '' })

    useEffect(() => {
        getDiffenceDetailByProduct({ date_init, date_end, id })
    }, [id])

    useEffect(() => {
        if (fetching_diference_by_product === 2) {
            let { results_purchase, product_inventory, results_lowdown, result_pos } = data
            setListPurchase(results_purchase)
            setListLownDown(results_lowdown)
            setProduct(product_inventory)
            setListSales(result_pos)
        }
    }, [fetching_diference_by_product])


    return (
        <TemplateMenu>
            <Row>
                <Col className='text-center'>
                    <button className='btn btn-sm btn-info'>Validar</button>
                    <h4>Detalles de {product.name.toUpperCase()} </h4>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Row>
                        <Col>COMPRAS</Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table className="table table-sm table-bordered">
                                <thead className='thead-dark'>
                                    <tr>
                                        <th>ID</th>
                                        <th>FECHA</th>
                                        <th className='text-center'>CANTIDAD</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listPurchase.length === 0 ? <tr><td colSpan={3}>Sin Datos</td></tr> : listPurchase.map((d, k) => <tr key={k}>
                                        <td><Link target="_blank" to={`/compras/${d.id}`}>{d.id}</Link></td>
                                        <td>
                                            <Row>
                                                <Col>{moment(d.registration_date).format("YYYY-MM-DD hh:mm A")}</Col>
                                            </Row>
                                            <Row>
                                                <Col className="text-muted font-10">Creación: {moment(d.created_at).format("YYYY-MM-DD hh:mm A")} ({d.user_update})</Col>
                                            </Row>
                                        </td>
                                        <td align='right'>{formatNumber(d.amount)}</td>
                                    </tr>)}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colSpan={2}>TOTAL</th>
                                        <th className='text-right'>{formatNumber(listPurchase.reduce((a, b) => a + parseFloat(b.amount), 0))}</th>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Col>BAJAS</Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table className="table table-sm table-bordered">
                                <thead className='thead-dark'>
                                    <tr>
                                        <th>ID</th>
                                        <th>FECHA</th>
                                        <th className='text-center'>CANTIDAD</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listLownDown.length === 0 ? <tr><td colSpan={3} align='center'>Sin Datos</td></tr> : listLownDown.map((d, k) => <tr key={k}>
                                        <td>{d.id}</td>
                                        <td>{moment(d.registration_date).format("YYYY-MM-DD hh:mm A")}</td>
                                        <td align='right'>{formatNumber(d.amount)}</td>
                                    </tr>)}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colSpan={2}>TOTAL</th>
                                        <th className='text-right'>{formatNumber(listLownDown.reduce((a, b) => a + parseFloat(b.amount), 0))}</th>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Col>VENTAS</Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table className="table table-sm table-bordered">
                                <thead className='thead-dark'>
                                    <tr>
                                        <th>FECHA</th>
                                        <th>VENTAS</th>
                                        <th className='text-center'>TOTAL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listSales.map((d, k) => <tr key={k}>
                                        <td>{d.product}</td>
                                        <td align='right'>{formatNumber(d.amount)}</td>
                                        <td align='right'>{formatNumber(parseFloat(d.prescription) * parseFloat(d.amount))}</td>
                                    </tr>)}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>TOTAL</th>
                                        <th className='text-right'>{formatNumber(listSales.reduce((a, b) => a + parseFloat(b.amount), 0))}</th>
                                        <th className='text-right'>{formatNumber(listSales.reduce((a, b) => a + parseFloat(b.amount) * parseFloat(b.prescription), 0))}</th>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { inventory } = props

    return {
        fetching_diference_by_product: inventory.fetching_diference_by_product,
        data: inventory.data,
    }
}
export default connect(mapsState, { getDiffenceDetailByProduct })(DiferenceDetailPage);