import React from 'react'
import { Col, ListGroup, Row } from 'react-bootstrap';

const ItemPrescriptionDetail = ({ item, onDelete, index, onEdit }) => {
    return (
        <ListGroup.Item>
            <Row>
                <Col lg={10} onClick={() => onEdit(item)} className="cursor-pointer">
                    {index} - {item.description}
                </Col>
                <Col className='d-flex justify-content-end'>
                    <button className='btn btn-sm btn-danger' onClick={() => onDelete(item)}>X</button>
                </Col>
            </Row>
        </ListGroup.Item>

    )
}

export default ItemPrescriptionDetail;