import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Container, Row, Col, Table } from 'react-bootstrap';
import ModalSupplier from './modal.supplier';

import { getSuppliers, getDepartments } from '../../Redux/supplierDuck'

import TemplateMenu from '../../template/menu.template'
import ItemTableSupplier from './item.table';


const SupplierPage = ({ getSuppliers, data, fetching_list, getDepartments, fetching_department, data_departments }) => {

    const [form, setForm] = useState({})
    const [modal, setModal] = useState(false)
    const [listSupplier, setListSupplier] = useState([])
    const [listSupplierFiltered, setListSupplierFiltered] = useState([])
    const [listDepartment, setListDepartment] = useState([])


    useEffect(() => {
        getSuppliers()
        getDepartments()
    }, [])


    useEffect(() => {
        if (fetching_department === 2) {
            setListDepartment(data_departments)
        }
    }, [fetching_department])

    useEffect(() => {
        if (fetching_list === 2) {
            setListSupplier(data)
            setListSupplierFiltered(data)
        }
    }, [fetching_list])

    const onHide = () => {
        setModal(false)
    }

    const edit = (item) => {
        setForm(item)
        setModal(true)
    }

    const onSuccess = () => {
        setModal(false)
        getSuppliers()
    }
    const onOpenModal = () => {
        setModal(true)
        setForm({})
    }

    const onFilter = (e) => {
        let { value } = e.target

        let list = listSupplier.filter(row => {

            if (row.business_name !== null && row.business_name.toLowerCase().includes(value.toLowerCase())) {
                return true;
            }
            return false
        })

        if (value.length === 0) {

            setListSupplierFiltered(listSupplier)
        } else {
            setListSupplierFiltered(list);
        }

    }

    return (
        <TemplateMenu>
            <Row className="my-4 justify-content-center">
                <Col lg={2}>
                    <h4>Proveedores</h4>
                </Col>
                <Col lg={2}>
                    <button className="btn btn-success" onClick={onOpenModal}>Crear</button>
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <Col lg={8}>
                    <Row className='justify-content-end my-2'>
                        <Col lg={3}>
                            <input className='form-control input-sm' placeholder='Buscar' onChange={onFilter} />
                        </Col>
                    </Row>

                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col lg={10}>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Alias</th>
                                <th>Razón Social</th>
                                <th>Documento</th>
                                <th>Dirección</th>
                                <th>Email</th>
                                <th>Estado</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listSupplierFiltered.length > 0 ? listSupplierFiltered.map(item => <ItemTableSupplier item={item} key={item.id} edit={edit} />)
                                : <tr><td colSpan="4" align="center">No tienes Registros</td></tr>
                            }
                        </tbody>
                    </Table>

                </Col>
            </Row>
            <ModalSupplier showModal={modal} onHide={onHide} onSuccess={() => onSuccess()} formEdit={form} listDepartment={listDepartment} />

        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { supplier } = props
    return {
        data: supplier.data,
        fetching_list: supplier.fetching_list,
        fetching_department: supplier.fetching_department,
        data_departments: supplier.data_departments,
    }
}

export default connect(mapsState, { getSuppliers, getDepartments })(SupplierPage);