import { getHeaders } from "../utils";

const API_URL = process.env.REACT_APP_API_URL


let initialState = {
    fetching_list: 0,
    fetching_create: 0,
    fetching_by_id: 0,
    fetching_validation: 0,
    row_validation: {},
    data: [],
    row: {}
}

let LIST_SUBPRESCRIPTION = "LIST_SUBPRESCRIPTION";
let LIST_SUBPRESCRIPTION_SUCCESS = "LIST_SUBPRESCRIPTION_SUCCESS";
let CREATE_SUBPRESCRIPTION = "CREATE_SUBPRESCRIPTION";
let CREATE_SUBPRESCRIPTION_SUCCESS = "CREATE_SUBPRESCRIPTION_SUCCESS";
let SUBPRESCRIPTION_BY_ID = "SUBPRESCRIPTION_BY_ID";
let SUBPRESCRIPTION_BY_ID_SUCCESS = "SUBPRESCRIPTION_BY_ID_SUCCESS";
let VALIDATION_BY_ID = "VALIDATION_BY_ID";
let VALIDATION_BY_ID_SUCCESS = "VALIDATION_BY_ID_SUCCESS";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_SUBPRESCRIPTION:
            return { ...state, fetching_list: 1, }
        case LIST_SUBPRESCRIPTION_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload, fetching_create: 0, fetching_by_id: 0 }
        case CREATE_SUBPRESCRIPTION:
            return { ...state, fetching_create: 1 }
        case CREATE_SUBPRESCRIPTION_SUCCESS:
            return { ...state, fetching_create: 2 }
        case SUBPRESCRIPTION_BY_ID:
            return { ...state, fetching_by_id: 1 }
        case SUBPRESCRIPTION_BY_ID_SUCCESS:
            return { ...state, fetching_by_id: 2, row: action.payload }
        case VALIDATION_BY_ID:
            return { ...state, fetching_validation: 1 }
        case VALIDATION_BY_ID_SUCCESS:
            return { ...state, fetching_validation: 2, row_validation: action.payload }
        default:
            return state;
    }
}

export let getSubPrescription = () => (dispatch, getState) => {
    dispatch({
        type: LIST_SUBPRESCRIPTION,
    });

    fetch(`${API_URL}/api/subprescription`, {
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: LIST_SUBPRESCRIPTION_SUCCESS,
            payload: resp.results
        })
    })
}

export let getSubPrescriptionById = (id) => (dispatch, getState) => {
    dispatch({
        type: SUBPRESCRIPTION_BY_ID,
    });

    fetch(`${API_URL}/api/subprescription/${id}`, {
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: SUBPRESCRIPTION_BY_ID_SUCCESS,
            payload: resp
        })
    })
}
export let getSubPrescriptionByProductId = (id) => (dispatch, getState) => {
    dispatch({
        type: VALIDATION_BY_ID,
    });

    fetch(`${API_URL}/api/subprescription-validate/${id}`, {
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: VALIDATION_BY_ID_SUCCESS,
            payload: resp
        })
    })
}

export let onCreateSubPrescription = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_SUBPRESCRIPTION,
    });

    let branch = JSON.parse(localStorage.branch)
    let { date_init, date_end } = JSON.parse(localStorage.date)

    form.branch_id = branch.id
    form.date_init = date_init

    fetch(`${API_URL}/api/subprescription`, {
        method: "POST",
        body: JSON.stringify(form),
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {

        dispatch({
            type: CREATE_SUBPRESCRIPTION_SUCCESS,
        })
    })
}

export let onUpdateSubPrescription = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_SUBPRESCRIPTION,
    });

    let branch = JSON.parse(localStorage.branch)

    form.branch_id = branch.id

    fetch(`${API_URL}/api/subprescription/${form.id}`, {
        method: "PUT",
        body: JSON.stringify(form),
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {

        dispatch({
            type: CREATE_SUBPRESCRIPTION_SUCCESS,
        })
    })
}
export let onDeleteSubPrescription = (id) => (dispatch, getState) => {
    dispatch({
        type: CREATE_SUBPRESCRIPTION,
    });

    fetch(`${API_URL}/api/subprescription/${id}`, {
        method: "DELETE",
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {

        dispatch({
            type: CREATE_SUBPRESCRIPTION_SUCCESS,
        })
    })
}