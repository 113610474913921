import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import PaginationTable from '../Components/PaginationTable';
import SearchSection from '../Components/SearchSection';
import ModalWorker from './ModalWorker';
import RowTableWorker from './row.table.worker';
import { getWorkers, deleteWorker } from '../../Redux/workerDuck'
import { getParameterBySegment } from '../../Redux/parameterDuck'
import ModalConfirmDelete from './modal.confirm.delete';
import { useToasts } from 'react-toast-notifications';

const q = "&type_worker=1"

const SegmentWorker = ({ workers, getWorkers, fetching_list, listCountry, listPosition, listArea, listEps, listArl, listTypePayment, fetching_create, user,
    deleteWorker, fetching_delete, msg }) => {

    const [showModal, setShowModal] = useState(false);
    const [form, setForm] = useState({})
    const [list, setList] = useState([]);
    const [modalDelete, setModalDelete] = useState(false);
    const [selected, setSelected] = useState({})

    const { addToast } = useToasts()


    useEffect(() => {
        if (fetching_list === 2) {
            setList(workers)
        }
    }, [fetching_list])

    useEffect(() => {
        if (fetching_delete === 2) {

            getWorkers(q)
        }
        if (fetching_delete === 3) {
            setModalDelete(false)
            addToast(msg, { appearance: 'error', autoDismiss: true });


        }
    }, [fetching_delete])


    useEffect(() => {
        if (fetching_create === 2) {
            getWorkers(q)
        }
    }, [fetching_create])

    useEffect(() => {

        getWorkers(q)
    }, [])


    useEffect(() => {
        if (fetching_list === 2) {
            setList(workers)
        }
    }, [fetching_list])


    const showModalTmp = () => {
        setShowModal(true);
        setForm({})
        // clearSelected()
    }

    const filterContent = (txt) => {
        let _list = list

        _list = list.filter(row => row.name.toLowerCase().includes(txt) || row.document.includes(txt))

        setList(_list)

    }
    const onHide = () => {
        setShowModal(false)
    }

    const onCleanFilter = () => {
        setList(workers)
    }

    const onEdit = (item) => {
        setForm(item)
        setShowModal(true)
    }

    const onSuccess = () => {
        getWorkers(q)
    }

    const onDelete = (item) => {
        setSelected(item)
        setModalDelete(true)
    }

    const onDeleteConfirm = (item) => {
        deleteWorker(item)
    }


    return (
        <Container fluid>
            <Row className='my-1'>
                <Col lg={6}>
                    {user.role_id === 1 &&
                        <button className='btn btn-sm btn-info' onClick={showModalTmp}>Crear Trabajador</button>}

                </Col>


                <Col lg={6} className="pr-4 justify-content-end d-flex align-items-center">
                    <SearchSection
                        placeholder='Buscar Trabajador'
                        value=''
                        name='search_worker'
                        onClick={filterContent}
                        onClean={onCleanFilter}
                        list={[{ id: 1, title: "Semanal" }]}
                    >

                    </SearchSection>


                </Col>
            </Row>

            <Row className="">
                <Col className='px-0'>
                    <table className="table table-bordered table-sm">
                        <thead className='thead-dark'>
                            <tr>
                                <th>Nombres</th>
                                <th>Documento</th>
                                <th>Nacionalidad</th>
                                <th>Cargo</th>
                                <th>Sucursal</th>
                                <th>Area</th>
                                <th>Fecha de Ingreso</th>
                                <th>Estado</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>

                            {(list !== undefined) && list.map(row => <RowTableWorker key={row.id} item={row} onEdit={onEdit} user={user} onDelete={onDelete} />)}
                        </tbody>


                    </table>

                    {/* <PaginationTable /> */}
                </Col>
            </Row>

            <ModalWorker
                user={user}
                showModal={showModal}
                formEdited={form}
                onHide={onHide}
                onSuccess={() => onSuccess()}
                listCountry={listCountry}
                listPosition={listPosition}
                listArea={listArea}
                listEps={listEps}
                listArl={listArl}
                listTypePayment={listTypePayment}
            />

            <ModalConfirmDelete show={modalDelete} item={selected} onSuccess={onDeleteConfirm} onHide={() => setModalDelete(false)} />
        </Container>
    )
}

const mapsState = (props) => {
    let { worker, user } = props
    return {
        workers: worker.data,
        fetching_list: worker.fetching_list,
        fetching_create: worker.fetching_create,
        fetching_delete: worker.fetching_delete,
        msg: worker.msg,
        user: user.data

    }
}


export default connect(mapsState, { getWorkers, getParameterBySegment, deleteWorker })(SegmentWorker);