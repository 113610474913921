import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import { formatNumber } from '../../utils';
import BlueTitleBack from '../Components/Widgets/BlueTitleBack';
import {
    selectedDefaultModifier,deleteAssociateById
} from '../../Redux/modifierDuck'

import suprimir from './../../Images/delete.svg'

const AssociativeItems = ({ item }) => {
    let { modifiers_assoc } = item;


    const selectedDefault = (item) => {
        selectedDefaultModifier(item)
    }
 

    const deleteAssociateTmp = (item) => {
        if (window.confirm("¿Deseas eliminar esta asociación?")) {

            deleteAssociateById(item)
        }

    }

    const printDetail = (item, i) => {
        let {modifier} = item
        return (
            <Fragment key={i}>
                <Row className="border-bottom active_list_reservation" key={i} >
                    <Col lg={5} className="d-flex align-items-center">
                        {modifier.title}
                    </Col>
                    <Col lg={3} className="d-flex align-items-center">
                        ${formatNumber(modifier.price)}
                    </Col>
                    <Col lg={2} className="d-flex align-items-center">
                        <input type="radio" checked={item.default} onChange={() => selectedDefault(item)} />
                    </Col>
                    <Col lg={2} className="d-flex justify-content-center align-items-center">
                        <button className="delete-btn" onClick={() => deleteAssociateTmp(item)}>
                            <img src={suprimir} className="icon-sm"></img>
                        </button>
                    </Col>
                </Row>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <div className="bg-white rounded-lg mb-4 p-3">
                <Row className="text-center py-3">
                    <BlueTitleBack
                        title={item.title}
                    />
                </Row>
                <Row className="border-bottom">
                    <Col lg={5}>
                        Descripción
                    </Col>
                    <Col lg={3}>
                        Precio
                    </Col>
                    <Col lg={2}>
                        Por defecto
                    </Col>
                    <Col lg={2}>

                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        {(modifiers_assoc !== undefined) ? modifiers_assoc.map(printDetail) : <div>No se encontraron Modificadores</div>}
                    </Col>
                </Row>
            </div>


        </Fragment>
    )
}
export default AssociativeItems;