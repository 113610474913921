import React, { useEffect, useState } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { connect } from "react-redux";
import TemplateMenu from "../../template/menu.template";
import { getListRemainder, getListCalendar, onCreateRemainder } from "../../Redux/remainderDuck";
import { getParameterBySegment } from "../../Redux/parameterDuck";
const initState = { title: "", period: 0 };
const RemainderPage = ({ getListRemainder, getParameterBySegment, data_segment, fetching_by_segment,
  getListCalendar, fetching_calendar, data_calendar, onCreateRemainder, fetching_list, data,
  fetching_create }) => {
  const [modal, setModal] = useState(false);
  const [content, setContent] = useState({ text: "" });
  const [list, setList] = useState([]);
  const [listParameter, setListParameter] = useState([]);
  const [listCalendar, setListCalendar] = useState([]);
  const [form, setForm] = useState(initState);

  useEffect(() => {
    if (fetching_create === 2) {
      setForm(initState);
      getListRemainder(content);
    }
  }, [fetching_create]);
  useEffect(() => {
    if (fetching_calendar === 2) {
      setListCalendar(data_calendar);
    }
  }, [fetching_calendar]);
  useEffect(() => {
    if (fetching_by_segment === 2) {
      setListParameter(data_segment);
    }
  }, [fetching_by_segment]);
  useEffect(() => {
    if (fetching_list === 2) {
      setList(data);
      setModal(true);
    }
  }, [fetching_list]);

  useEffect(() => {
    getParameterBySegment("remainder");
    getListCalendar();
  }, []);

  const selectDay = (d) => {
    getListRemainder(d);
    console.log('detail', d);

    setContent(d);
    setModal(true);
  };

  const updateInput = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  const onSaveRemain = () => {
    let _form = form;
    _form.day = content;


    onCreateRemainder(_form);
  }


  return (
    <TemplateMenu>
      <Row className="my-3">
        <Col lg={2}>Recordatorios</Col>
      </Row>
      <Row>
        <Col lg={`${modal ? 9 : 12}`} md={`${modal ? 9 : 12}`}>
          <Row>
            <div className={`div-calendar-header${modal ? "-detail" : ""}`}>
              Domingo
            </div>
            <div className={`div-calendar-header${modal ? "-detail" : ""}`}>
              Lunes
            </div>
            <div className={`div-calendar-header${modal ? "-detail" : ""}`}>
              Martes
            </div>
            <div className={`div-calendar-header${modal ? "-detail" : ""}`}>
              Miercoles
            </div>
            <div className={`div-calendar-header${modal ? "-detail" : ""}`}>
              Jueves
            </div>
            <div className={`div-calendar-header${modal ? "-detail" : ""}`}>
              Viernes
            </div>
            <div className={`div-calendar-header${modal ? "-detail" : ""}`}>
              Sabado
            </div>
          </Row>

          {listCalendar.map((c, key) => {
            let { week } = c;
            return (
              <Row key={key}>
                {week.map((d, k) => (
                  <div className={`div-calendar${modal ? "-detail" : ""}`} key={k}
                    onClick={() => selectDay(d)} >
                    {d.day}
                    {/* {d.task.map((t, key) => <p>{d.title}</p>)} */}
                  </div>
                ))}
              </Row>
            );
          })}
        </Col>
        <Col lg={`${modal ? 3 : 0}`} className={`${modal ? "border-left" : "d-none"}`} >
          <Row>
            <Col className="d-flex justify-content-end">
              <button className="btn btn-sm btn-secondary" onClick={() => setModal(false)}> Cerrar </button>
            </Col>
          </Row>
          <Row>
            <Col>
              <b>{content.text.toLocaleUpperCase()}</b>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="form-group">
                <label>Titulo</label>
                <input
                  className="form-control"
                  name="title"
                  value={form.title}
                  placeholder="Agrega un titulo"
                  onChange={updateInput}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="form-group">
                <label>Periodo</label>
                <select className="form-control" name="period" value={form.period} onChange={updateInput} >
                  <option>Seleccione</option>
                  {listParameter.map((d, k) => (
                    <option key={k} value={d.id}>
                      {d.description}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col className="d-flex justify-content-center">
              <button className="btn btn-sm btn-success" onClick={onSaveRemain}>
                Guardar
              </button>
            </Col>
          </Row>
          {list.map((d, k) => (
            <Row key={k}>
              <Col>
                <Card>
                  <Card.Body className="p-2">{d.title}</Card.Body>
                </Card>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </TemplateMenu>
  );
};

const mapsState = (props) => {
  let { parameter, remainder } = props;

  return {
    fetching_by_segment: parameter.fetching_by_segment,
    data_segment: parameter.data_segment,
    data_calendar: remainder.data_calendar,
    fetching_calendar: remainder.fetching_calendar,
    fetching_list: remainder.fetching_list,
    fetching_create: remainder.fetching_create,
    data: remainder.data,
  };
};
export default connect(mapsState, {
  getListRemainder,
  getParameterBySegment,
  getListCalendar,
  onCreateRemainder,
})(RemainderPage);
