import axios from 'axios'
import { validateBranch, getHeaders } from '../utils'
const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_save: 0,
    data: [],
    errors: {}
}

let BANNER = "BANNER";
let BANNER_SUCCESS = "BANNER_SUCCESS";
let BRANCH_ERROR = "BRANCH_ERROR";
let BRANCH_SAVE = "BRANCH_SAVE";
let BRANCH_SAVE_SUCCESS = "BRANCH_SAVE_SUCCESS";

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case BANNER:
            return { ...state, fetching: 1, fetching_save: 0 }
        case BANNER_SUCCESS:
            return { ...state, fetching: 2, data: action.payload }
        case BRANCH_SAVE:
            return { ...state, fetching_save: 1 }
        case BRANCH_SAVE_SUCCESS:
            return { ...state, fetching_save: 2 }

        case BRANCH_ERROR:
            return { ...state, errors: action.payload, fetching: 3 }
        default:
            return state;
    }
}


export let getBanners = () => (dispatch, getState) => {
    dispatch({
        type: BANNER
    })

    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"))

        axios.get(`${API_URL}/api/banner?branch_id=${branch.id}`, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: BANNER_SUCCESS,
                payload: resp.data.results
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}


export let createBanner = (form) => (dispatch, getState) => {

    dispatch({
        type: BRANCH_SAVE
    })

    let validate = validateBranch(form);


    if (Object.keys(validate).length > 0) {
        dispatch({
            type: BRANCH_ERROR,
            payload: validate
        })
        return;
    }


    if (getState().user.loggedIn) {

        let branch = JSON.parse(localStorage.getItem("branch"))

        let _form = new FormData()
        _form.append("image", form.image);
        _form.append("title", form.title);
        _form.append("description", form.description);
        _form.append("status", form.status);
        _form.append("branch_id", branch.id);
        _form.append("is_general", form.is_general);

        axios.post(`${API_URL}/api/banner`, _form, {
            headers: getHeaders()
        }).then(resp => {
            dispatch({
                type: BRANCH_SAVE_SUCCESS
            });

        }).catch(error => {

            console.log('error', error)
            // let err = {}

            // for (const [key, value] of Object.entries(error.response.data.errors)) {
            //     if('title'===key){
            //         err.title = value[0]
            //     }
            //     if('description'===key){
            //         err.description = value[0]
            //     }  
            // }

            // dispatch({
            //     type: BRANCH_ERROR,
            //     payload: err,
            //     form:form
            // })
        });
    } else {
        console.log("no Ingreso");
    }
}


// export let getListBranch=()=>(dispatch,getState)=>{
//     dispatch({
//         type:BRANCH
//     })

//     if(getState().user.loggedIn){
//         let token = localStorage.getItem("access_token")

//         axios.get(`${API_URL}api/list-branch-office`, {
//             headers: getHeaders()
//         }).then(resp=>{
//             dispatch({
//                 type:BRANCH_SUCCESS,
//                 payload:resp.data.results
//             });

//         }).catch(function (error) {
//             console.log(error);
//         });  
//     }else{
//         console.log("no Ingreso");
//     }
// }




// export let updateBranch=(form,schedule)=>(dispatch, getState)=>{

//     dispatch({
//         type:BRANCH_SAVE
//     })

//     let validate = validateBranch(form);




//     if(Object.keys(validate).length>0){
//         dispatch({
//             type:BRANCH_ERROR,
//             payload:validate
//         })
//         return;
//     }

//     form.schedule=schedule



//     if(getState().user.loggedIn){
//         let token = localStorage.getItem("access_token")

//         axios.put(`${API_URL}api/branch-office-admin/${form.id}`,form, {
//             headers: getHeaders()
//         }).then(resp=>{
//             dispatch({
//                 type:BRANCH_SAVE_SUCCESS
//             });

//         }).catch(error=>{
//             let err = {}

//             for (const [key, value] of Object.entries(error.response.data.errors)) {
//                 if('title'===key){
//                     err.title = value[0]
//                 }
//                 if('description'===key){
//                     err.description = value[0]
//                 }  
//             }

//             dispatch({
//                 type: BRANCH_ERROR,
//                 payload: err,
//                 form:form
//             })
//         });  
//     }else{
//         console.log("no Ingreso");
//     }
// }

// export let deleteBranch=(form)=>(dispatch, getState)=>{

//     dispatch({
//         type:BRANCH_SAVE
//     })

//     if(getState().user.loggedIn){
//         let token = localStorage.getItem("access_token")

//         axios.delete(`${API_URL}api/branch-office-admin/${form.id}`,{
//             headers: getHeaders()
//         }).then(resp=>{
//             dispatch({
//                 type:BRANCH_SAVE_SUCCESS
//             });

//         }).catch(error=>{
//             let err = {}

//             for (const [key, value] of Object.entries(error.response.data.errors)) {
//                 if('title'===key){
//                     err.title = value[0]
//                 }
//                 if('description'===key){
//                     err.description = value[0]
//                 }  
//             }

//             dispatch({
//                 type: BRANCH_ERROR,
//                 payload: err,
//                 form:form
//             })
//         });  
//     }else{
//         console.log("no Ingreso");
//     }
// }

// export let deleteItemBranch=(form)=>(dispatch, getState)=>{

//     console.log("updateBranch",form)

//     dispatch({
//         type:BRANCH
//     })

//     if(getState().user.loggedIn){
//         let token = localStorage.getItem("access_token")

//         axios.delete(`${API_URL}api/delete-item-day/${form.id}`,form, {
//             headers: getHeaders()
//         }).then(resp=>{
//             dispatch({
//                 type:BRANCH_SUCCESS,
//                 payload:resp.data.results,
//             });

//         }).catch(error=>{
//             let err = {}

//             for (const [key, value] of Object.entries(error.response.data.errors)) {
//                 if('title'===key){
//                     err.title = value[0]
//                 }
//                 if('description'===key){
//                     err.description = value[0]
//                 }  
//             }

//             dispatch({
//                 type: BRANCH_ERROR,
//                 payload: err,
//                 form:form
//             })
//         });  
//     }else{
//         console.log("no Ingreso");
//     }
// }



