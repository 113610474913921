import React, { useEffect, Fragment, useState } from 'react'
import { connect } from 'react-redux';
import { Row, Col, Container } from 'react-bootstrap';
import { getAllUser, setSelectedItem, clearSelected, getRoles } from '../../Redux/userDuck'
import { getParameterBySegment } from '../../Redux/parameterDuck'
import ModalUsers from './ModalUsers'
import BtnWhiteHeader from '../Components/BtnWhiteHeader';
import SearchSection from '../Components/SearchSection';
import PaginationTable from '../Components/PaginationTable';
import RowTableUser from './row.table.user';
import TemplateMenu from '../../template/menu.template'


const Users = ({ getAllUser, setSelectedItem, data, clearSelected, fetching_list, fetching_rol, getRoles, roles }) => {

    const [showModal, setShowModal] = useState(false);
    const [list, setList] = useState([]);
    const [listRoles, setListRoles] = useState([])
    const [form, setForm] = useState([])

    useEffect(() => {
        getAllUser()
        getRoles()
    }, [])

    useEffect(() => {
        if (fetching_rol === 2) {
            setListRoles(roles)
        }
    }, [fetching_rol])


    useEffect(() => {
        setList(data)
    }, [data])


    const editUser = (item) => {
        setForm(item)
        setShowModal(true)
    }
    const onHide = () => {
        setShowModal(false);
    }

    const showModalTmp = () => {
        setForm({})
        setShowModal(true);
    }


    const onSuccess = () => {
        setForm({})

        getAllUser();
    }

    const filterContent = (txt) => {
        let _list = list

        _list = list.filter(row => row.name.includes(txt) || row.email.includes(txt))

        setList(_list)

    }
    const onCleanFilter = () => {
        setList(data)
    }

    return (
        <TemplateMenu>
            <Container fluid>
                <Row className="my-2 mt-4 mb-2">
                    <Col lg={6}>
                        <button className='btn btn-info btn-sm' onClick={showModalTmp}>Crear Usuario</button>
                    </Col>


                    <Col lg={6} className="pr-4 justify-content-end d-flex align-items-center">
                        <SearchSection
                            placeholder='Buscar Usuario'
                            value=''
                            name='buscar_usuario'
                            onClick={filterContent}
                            onClean={onCleanFilter}>

                        </SearchSection>
                    </Col>
                </Row>

                <Row className="">
                    <Col>
                        <table className="table table-sm table-bordered table-hover table-striped">
                            <thead className='thead-dark'>
                                <tr>
                                    <th>Nombre Usuario</th>
                                    <th>Correo Electrónico</th>
                                    <th>No. Celular</th>
                                    <th>Sucursal</th>
                                    <th>Rol Usuario</th>
                                    <th>Estado</th>
                                    <th>Modificar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(list !== undefined) ? list.map(row => <RowTableUser key={row.id} item={row} editUser={editUser} />) : null}
                            </tbody>
                        </table>
                        {/* <PaginationTable /> */}
                    </Col>
                </Row>

                <ModalUsers showModal={showModal} onHide={onHide} onSuccess={() => onSuccess()} listRoles={listRoles} formEdited={form} />

            </Container>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    return {
        data: props.user.list,
        fetching_list: props.user.fetching_list,
        roles: props.user.data_roles,
        fetching_rol: props.user.fetching_rol,

    }
}

export default connect(mapsState, { getAllUser, setSelectedItem, clearSelected, getRoles })(Users);