import { getHeaders } from "../utils";
const API_URL = process.env.REACT_APP_API_URL;

let initialState = {
  fetching_list: 0,
  fetching_create: 0,
  fetching_by_id: 0,
  fetching_create_row: 0,
  data: [],
  row: {},
};

let LIST_PURCHASE = "LIST_PURCHASE";
let LIST_PURCHASE_SUCCESS = "LIST_PURCHASE_SUCCESS";
let CREATE_PURCHASE = "CREATE_PURCHASE";
let CREATE_PURCHASE_SUCCESS = "CREATE_PURCHASE_SUCCESS";
let PURCHASE_BY_ID = "PURCHASE_BY_ID";
let PURCHASE_BY_ID_SUCCESS = "PURCHASE_BY_ID_SUCCESS";
let RESTART_PURCHASE = "RESTART_PURCHASE";
let DELETE_PURCHASE = "DELETE_PURCHASE";
let DELETE_PURCHASE_SUCCESS = "DELETE_PURCHASE_SUCCESS";
let CREATE_PURCHASE_ROW = "CREATE_PURCHASE_ROW";
let CREATE_PURCHASE_ROW_SUCCESS = "CREATE_PURCHASE_ROW_SUCCESS";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LIST_PURCHASE:
      return {
        ...state,
        fetching_list: 1,
        fetching_create: 0,
        fetching_by_id: 0,
        row: {}
      };
    case LIST_PURCHASE_SUCCESS:
      return {
        ...state, fetching_list: 2, data: action.payload, fetching_by_id: 0, row: {}
      }
    case CREATE_PURCHASE:
      return { ...state, fetching_create: 1 };
    case CREATE_PURCHASE_SUCCESS:
      return { ...state, fetching_create: 2 };
    case PURCHASE_BY_ID:
      return { ...state, fetching_by_id: 1, fetching_create: 0 };
    case PURCHASE_BY_ID_SUCCESS:
      return {
        ...state,
        fetching_by_id: 2,
        row: action.payload,
        fetching_list: 0,
      };
    case RESTART_PURCHASE:
      return {
        ...state,
        fetching_by_id: 0,
        fetching_list: 0,
        fetching_create: 0,
      };
    case DELETE_PURCHASE:
      return { ...state, fetching_create: 1 };
    case DELETE_PURCHASE_SUCCESS:
      return { ...state, fetching_create: 2 };
    case CREATE_PURCHASE_ROW:
      return { ...state, fetching_create_row: 1 };
    case CREATE_PURCHASE_ROW_SUCCESS:
      return { ...state, fetching_create_row: 2, row: action.payload };
    default:
      return state;
  }
}

export let restartPurchase = () => (dispatch, getState) => {
  dispatch({
    type: RESTART_PURCHASE,
  });
};

export let getPurchases = (isDetail, product) => (dispatch, getState) => {
  dispatch({
    type: LIST_PURCHASE,
  });

  let { date_init, date_end } = JSON.parse(localStorage.getItem("date"));
  let branch = JSON.parse(localStorage.getItem("branch"));


  console.log('isDetail', isDetail);


  let url = `${API_URL}/api/purchase?date_init=${date_init}&date_end=${date_end}&branch=${branch.id}&is_detail=${isDetail}`;
  url += `&product_filter_id=${product}`;

  fetch(url, {
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: LIST_PURCHASE_SUCCESS,
        payload: resp.results,
      });
    });
};

export let getPurchaseById = (id) => (dispatch, getState) => {
  dispatch({
    type: PURCHASE_BY_ID,
  });
  let url = `${API_URL}/api/purchase/${id}`;

  console.log('url---', url);


  fetch(url, {
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: PURCHASE_BY_ID_SUCCESS,
        payload: resp,
      });
    });
};

export let onCreatePurchases = (form) => (dispatch, getState) => {
  dispatch({
    type: CREATE_PURCHASE,
  });
  let url = `${API_URL}/api/purchase`;

  let branch = JSON.parse(localStorage.branch);
  let { date_init, date_end } = JSON.parse(localStorage.date);

  form.branch_id = branch.id;
  form.date_init = date_init;
  form.date_end = date_end;

  fetch(url, {
    method: "POST",
    body: JSON.stringify(form),
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: CREATE_PURCHASE_SUCCESS,
      });
    });
}

export let onCreatePurchasesRow = (form) => (dispatch, getState) => {
  dispatch({
    type: CREATE_PURCHASE_ROW,
  });
  let url = `${API_URL}/api/purchase-row`;

  let branch = JSON.parse(localStorage.branch);
  let { date_init, date_end } = JSON.parse(localStorage.date);

  form.branch_id = branch.id;
  form.date_init = date_init;
  form.date_end = date_end;

  fetch(url, {
    method: "POST",
    body: JSON.stringify(form),
    headers: getHeaders(),
  }).then((resp) => resp.json()).then((resp) => {
    dispatch({
      type: CREATE_PURCHASE_ROW_SUCCESS,
      payload: resp
    });
  });
}

export let onEditPurchasesRow = (form) => (dispatch, getState) => {
  dispatch({
    type: CREATE_PURCHASE_ROW,
  });


  let url = `${API_URL}/api/purchase-row/${form.id}`;

  let branch = JSON.parse(localStorage.branch);
  let { date_init, date_end } = JSON.parse(localStorage.date);

  form.branch_id = branch.id;
  form.date_init = date_init;
  form.date_end = date_end;

  fetch(url, {
    method: "PUT",
    body: JSON.stringify(form),
    headers: getHeaders(),
  }).then((resp) => resp.json()).then((resp) => {
    dispatch({
      type: CREATE_PURCHASE_ROW_SUCCESS,
      payload: resp
    });
  });
}

export let onDeletePurchasesRow = (form) => (dispatch, getState) => {
  dispatch({
    type: CREATE_PURCHASE_ROW,
  });

  let url = `${API_URL}/api/purchase-row/${form.id}`;

  let branch = JSON.parse(localStorage.branch);
  let { date_init, date_end } = JSON.parse(localStorage.date);

  form.branch_id = branch.id;
  form.date_init = date_init;
  form.date_end = date_end;

  fetch(url, {
    method: "DELETE",
    headers: getHeaders(),
  }).then((resp) => resp.json()).then((resp) => {
    dispatch({
      type: CREATE_PURCHASE_ROW_SUCCESS,
      payload: resp
    });
  });
}


export let onEditPurchases = (form) => (dispatch, getState) => {
  dispatch({
    type: CREATE_PURCHASE,
  });
  let url = `${API_URL}/api/purchase/${form.id}`;

  let branch = JSON.parse(localStorage.branch);
  let { date_init, date_end } = JSON.parse(localStorage.date);

  form.branch_id = branch.id;
  form.date_init = date_init;
  form.date_end = date_end;

  fetch(url, {
    method: "PUT",
    body: JSON.stringify(form),
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: CREATE_PURCHASE_SUCCESS,
      });
    });
};

export let onDeletePurchases = (id) => (dispatch, getState) => {
  dispatch({
    type: DELETE_PURCHASE,
  });
  let { date_init, date_end } = JSON.parse(localStorage.date);

  let url = `${API_URL}/api/purchase/${id}?date_init=${date_init}&date_end=${date_end}`;

  let branch = JSON.parse(localStorage.branch);


  fetch(url, {
    method: "DELETE",
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: DELETE_PURCHASE_SUCCESS,
      });
    });
};

export let onCreateDirectPurchases = (form) => (dispatch, getState) => {
  dispatch({
    type: CREATE_PURCHASE,
  });
  let url = `${API_URL}/api/purchase-direct`;

  let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))

  let branch = JSON.parse(localStorage.branch);

  form.branch_id = branch.id;
  form.date_init = date_init;
  form.date_end = date_end;


  fetch(url, {
    method: "POST",
    body: JSON.stringify(form),
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: CREATE_PURCHASE_SUCCESS,
      });
    });
};
