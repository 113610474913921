import React, { useEffect, useState } from 'react';
import { MdClose } from "react-icons/md";

import { Col, Modal, Row, Table } from "react-bootstrap";
import InputField from './Widgets/InputField';
import BlueTitleBack from './Widgets/BlueTitleBack';
import { connect } from 'react-redux';
import ItemDetailParameter from './item.detail.parameter';

const initForm = { description: '' }

const ModalAddParameter = ({ show, title, onHide, onSuccess, dataType, errors, listParameter, onUpdate }) => {

    const [form, setForm] = useState(initForm)


    const updateInput = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.value.toUpperCase()
        })
    }


    const closeModalTmp = () => {
        onHide()
    }

    const onSubmitTmp = () => {
        let _form = form
        _form.description = _form.description.trim()
        _form.dataType = dataType
        setForm(initForm)
        onSuccess(_form)

    }

    return <Modal show={show}>
        <div className="modal-header border-bottom-custom mt-2">
            <BlueTitleBack title={title} />
            <button type="button" className="close p-0 m-0" data-dismiss="modal" aria-label="Close" onClick={closeModalTmp}>
                <MdClose />
            </button>
        </div>
        <Modal.Body>
            <Row>
                <Col>
                    <div className="form-group">
                        <label>Concepto</label>
                        <InputField
                            placeholder={"Agrega la Concepto"}
                            value={form.description || ''}
                            name="description"
                            onChange={updateInput}
                            error={errors.description}
                        />
                    </div>
                </Col>
            </Row>
            {(listParameter !== undefined && listParameter.length > 0) && <Row>
                <Col>
                    <Table className='table-sm table-hover table-bordered'>
                        <thead>
                            <tr>
                                <th>Descripción</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {listParameter.map((p, k) => <ItemDetailParameter item={p} key={k} onUpdate={onUpdate} />)}
                        </tbody>

                    </Table>
                </Col>
            </Row>}

        </Modal.Body>
        <Modal.Footer className="justify-content-center">
            <Row >
                <Col>
                    <button className='btn btn-danger' onClick={closeModalTmp}>Cerrar</button>
                </Col>
                <Col>
                    <button className='btn btn-success' onClick={onSubmitTmp}>Guardar</button>
                </Col>
            </Row>
        </Modal.Footer>
    </Modal>
}

const mapsState = (props) => {
    return {
        errors: props.parameter.errors
    }
}

export default connect(mapsState, {})(ModalAddParameter);