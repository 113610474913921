import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { connect } from 'react-redux';

import { getBanners } from '../../Redux/bannerDuck'
import ItemBanner from './item_banner';
import TemplateMenu from '../../template/menu.template'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


const BannerPage = ({ banner, getBanners }) => {

  let { fetching, data } = banner

  const [listBanner, setListBanner] = useState([]);

  useEffect(() => {
    if (fetching === 2) {
      setListBanner(data)
    }
  }, [fetching])

  useEffect(() => {
    getBanners()
  }, [])


  return (
    <TemplateMenu>
      <Container fluid>
        <Row className="my-3">
          <Col lg={2} md={4} className="d-flex align-items-center pt-2"><h3 className="font-title">Banners</h3></Col>
          <Col lg={2} md={4} >
            <Link className="btn btn-sm btn-info" to="/banners/new" >Crear</Link>
          </Col>
        </Row>
        <Row>
          {(listBanner.length === 0) ? <Col className='text-center'><h3>No tienes Datos</h3></Col> : listBanner.map(row => <ItemBanner row={row} key={row.id} />)}
        </Row>
      </Container>
    </TemplateMenu>
  )
}

const mapsState = (props) => {
  return {
    banner: props.banner
  }
}

export default connect(mapsState, { getBanners })(BannerPage);