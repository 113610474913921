import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import userReducer, { restoreSessionAction } from "./userDuck";
import orderReducer from "./orderDuck";
import productReducer from "./productDuck";
import modifierReducer from "./modifierDuck";
import alertReducer from "./alertDuck";
import categoryReducer from "./categoryDuck";
import branchReducer from "./branchofficeDuck";
import parameterReducer from "./parameterDuck";
import reservationReducer from "./reservationDuck";
import diningReducer from "./diningDuck";
import menuReducer from "./menuDuck";
import reportReducer from "./reportDuck";
import bannerReducer from "./bannerDuck";
import ingresosReducer from "./IngresosDuck";
import clientReducer from "./ClientDuck";
import workerReducer from "./workerDuck";
import permissionReducer from "./permissionDuck";
import payrollReducer from "./payrollDuck";
import productRecipeReducer from "./productRecipe";
import supplierReducer from "./supplierDuck";
import expensesReducer from "./expensesDuck";
import tipsReducer from "./tipDuck";
import screenReducer from "./screenDuck";
import productInventoryReducer from "./ProductInventoryDuck";
import prescriptionReducer from "./prescriptionDuck";
import subprescriptionReducer from "./subprescriptionDuck";
import purchaseReducer from "./purchaseDuck";
import inventoryReducer from "./inventoryDuck";
import couponReducer from "./couponDuck";
import productionReducer from "./ProductionDuck";
import transformationReducer from "./transformationDuck";
import franchiseReducer from "./franchiseDuck";
import settlementReducer from "./settlementDuck";
import recipeBookReducer from "./recipe.book.Duck";
import remainderReducer from "./remainderDuck";
import courtesyReducer from "./courtesyDuck";
import RequisitionReducer from "./requisitionDuck";
import TransferReducer from "./transferDuck";
import WhatsAppReducer from "./whatsappDuck";
import SettingReducer, { getLockInventory } from "./settingDuck";

let rootReducer = combineReducers({
  user: userReducer,
  order: orderReducer,
  product: productReducer,
  modifier: modifierReducer,
  alert: alertReducer,
  category: categoryReducer,
  branchoffice: branchReducer,
  parameter: parameterReducer,
  reservation: reservationReducer,
  dining: diningReducer,
  menu: menuReducer,
  report: reportReducer,
  banner: bannerReducer,
  ingresos: ingresosReducer,
  client: clientReducer,
  worker: workerReducer,
  permission: permissionReducer,
  payroll: payrollReducer,
  product_recipe: productRecipeReducer,
  supplier: supplierReducer,
  expense: expensesReducer,
  tip: tipsReducer,
  screen: screenReducer,
  product_inventory: productInventoryReducer,
  prescription: prescriptionReducer,
  subprescription: subprescriptionReducer,
  purchase: purchaseReducer,
  inventory: inventoryReducer,
  coupon: couponReducer,
  production: productionReducer,
  transformation: transformationReducer,
  franchise: franchiseReducer,
  settlement: settlementReducer,
  recipe_book: recipeBookReducer,
  remainder: remainderReducer,
  courtesy: courtesyReducer,
  requisition: RequisitionReducer,
  transfer: TransferReducer,
  whatsapp: WhatsAppReducer,
  setting: SettingReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
  let store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
  );

  restoreSessionAction()(store.dispatch);

  if (store.getState().user.loggedIn === true) {
    getLockInventory("difference")(store.dispatch, store.getState)
    // getBranch()(store.dispatch, store.getState)
    //     getCart()(store.dispatch, store.getState)
    //     getCurrentUser()(store.dispatch, store.getState)
    //     getCategories()(store.dispatch,store.getState)
  }

  return store;
}
