import React from 'react'
import { Link } from 'react-router-dom';


const BtnWhiteHeader = ({ title, to,onClick }) => {


    return (onClick?(
        <div onClick={onClick}>
            <div className="bg-white d-inline-block p-1 button_white mr-2 cursor">
                <div className=" px-4 py-2 btn_white_border text-center text-azul">
                    {title}
                </div>
            </div>
        </div>
    ):(

        <Link to={to}>
            <div className="bg-white d-inline-block p-1 button_white mr-2 cursor">
                <div className=" px-4 py-2 btn_white_border text-center text-azul">
                    {title}
                </div>
            </div>
        </Link>
        )
    )
}

export default BtnWhiteHeader;