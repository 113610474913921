import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { formatNumber } from '../../utils';

const ItemProduction = ({ row, onDelete, amount, measure }) => {
    let { product_inventory } = row;
    let { measure_unit } = product_inventory
    let total_amount = amount * row.amount;
    let total_cost = row.cost_average * total_amount

    let available = row.amount_inventory

    row.is_available = available >= total_amount ? true : false;
    row.cost = total_cost

    return (
        <tr className={`${row.is_available ? '' : 'table-danger'}`}>
            <td>{product_inventory.name}</td>
            <td>{parseFloat(available).toFixed(3)} ({measure_unit.description})</td>
            <td align='right'>{parseFloat(row.amount).toFixed(3)}</td>
            <td align='right'>{parseFloat(total_amount).toFixed(3)} ({measure_unit.description})</td>
            <td align='right'>{formatNumber(row.cost_average || 0)}</td>
            <td align='right'>{formatNumber(total_cost)}</td>
        </tr>
    )
}

export default ItemProduction;