import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ListProduct from './ListProduct';

const ListCategory = ({ item, edit }) => {
    let { products } = item
    return (
        <Row className="rounded-lg py-3 mb-3">
            <Col >
                <Row className="my-4 ">
                    <Col className="text-center font-title text-uppercase"><h2>{item.title}</h2></Col>
                </Row>
                <Row>
                    {products.map((row, key) => <ListProduct item={row} edit={edit} key={key} />)}
                </Row>
            </Col>
        </Row>
    )
}
export default ListCategory;