import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap';
import { createCategoryRecipe } from "../../Redux/recipe.book.Duck";
import { toogleLoader } from "../../Redux/alertDuck";
import { connect } from 'react-redux';

const ModalAddCategory = ({ show, onHide, onSuccess, createCategoryRecipe, fetching_create, toogleLoader }) => {
    const [name, setName] = useState('')
    const [error, setError] = useState('')

    useEffect(() => {
        if (fetching_create === 2) {
            setName('')
            setError('')
            toogleLoader(false)
            onSuccess()
        }

        if (fetching_create === 3) {
            toogleLoader(false)
            setError("Ya existe Categoria")
        }

        console.log('fetching_create', fetching_create);

    }, [fetching_create])

    const onCreate = () => {
        toogleLoader(true)
        createCategoryRecipe({ name })
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Agregar Categoria</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <div className='form-group'>
                            <label>Agrega una Descripción</label>
                            <input className='form-control' value={name} placeholder='Descripción'
                                onChange={(e) => setName(e.target.value)} />
                            {error !== '' && <label className="error-label">{error}</label>}
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col>
                        <button className='btn btn-success btn-sm' onClick={onCreate}>Guardar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

const mapsState = (props) => {
    let { recipe_book } = props

    return {
        fetching_create: recipe_book.fetching_create_category,
    }
}
export default connect(mapsState, { createCategoryRecipe, toogleLoader })(ModalAddCategory);