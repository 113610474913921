import React, { useEffect, useState } from 'react'

import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import { useParams } from 'react-router-dom';
import { getIngresoById } from '../../Redux/IngresosDuck';
import { getPayrollId } from '../../Redux/payrollDuck';
import { connect } from 'react-redux';


const styles = StyleSheet.create({
    page: {
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #EEE',
        paddingTop: 8,
        paddingBottom: 8,
        marginLeft:15,
        width:"100%"

    },
    table:{
        width:"100%"
    },
    row:{
        width:"45%"
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    }
});


const PayrollDocumentPdf = ({ getPayrollId, data, fetching_edit }) => {

    const [ingreso, setIngreso] = useState({})

    const { id } = useParams()

    // useEffect(() => {
    //     if (fetching_edit === 2) {
    //         setIngreso(data)
    //     }
    // }, [fetching_edit])

    useEffect(() => {
        console.log('id', id);
        getPayrollId(id)

    }, [id])

    return (
        <PDFViewer style={{ width: '100%', height: '90vh' }}>
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.table}>
                        <Text style={styles.row}>Cierre</Text>
                        <Text style={styles.row}>Cierre 1</Text>
                    </View>
                    <View style={styles.table}>
                        <Text style={styles.row}></Text>
                        <Text style={styles.row}>Cierre 23231</Text>
                    </View>
                    {/* <View style={styles.section}>
                        <Text>{ingreso.finish_check_formated}</Text>
                    </View> */}
                    
                </Page>
                
            </Document>
        </PDFViewer>
    )
}


const mapsState = (props) => {
    console.log('props', props.ingresos);

    return {
        data: props.ingresos.row,
        fetching_edit: props.ingresos.fetching_edit,
    }
}

export default connect(mapsState, { getPayrollId })(PayrollDocumentPdf);