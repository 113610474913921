import { getHeaders } from '../utils'

const API_URL = process.env.REACT_APP_API_URL
let initialState = {
    fetching_list: 0,
    fetching_by_id: 0,
    fetching_save: 0,
    data: [],
    row: {}
}

let LIST_PERMISSION = "LIST_PERMISSION";
let LIST_PERMISSION_SUCCESS = "LIST_PERMISSION_SUCCESS";
let PERMISSION_BY_ID = "PERMISSION_BY_ID";
let PERMISSION_BY_ID_SUCCESS = "PERMISSION_BY_ID_SUCCESS";
let CREATE_SUCCESS = "CREATE_SUCCESS";
let CREATE_SUCCESS_SUCCESS = "CREATE_SUCCESS_SUCCESS";
let EDIT_PERMISSION = "EDIT_PERMISSION";
let EDIT_PERMISSION_SUCCESS = "EDIT_PERMISSION_SUCCESS";
let RESTART_PERMISSION = "RESTART_PERMISSION";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_PERMISSION:
            return { ...state, fetching_list: 1, }
        case LIST_PERMISSION_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload, fetching_by_id: 0 }
        case PERMISSION_BY_ID:
            return { ...state, fetching_by_id: 1, }
        case PERMISSION_BY_ID_SUCCESS:
            return { ...state, fetching_by_id: 2, data: action.payload, row: action.row }
        case CREATE_SUCCESS:
            return { ...state, fetching_create: 1 }
        case CREATE_SUCCESS_SUCCESS:
            return { ...state, fetching_create: 2 }
        case EDIT_PERMISSION:
            return { ...state, fetching_save: 1 }
        case EDIT_PERMISSION_SUCCESS:
            return { ...state, fetching_save: 2 }
        case RESTART_PERMISSION:
            return { ...state, fetching_by_id: 0, fetching_list: 0 }
        default:
            return state;
    }
}

export let restartPermision = () => (dispatch, getState) => {
    dispatch({
        type: RESTART_PERMISSION,
    });
}


export let getPermission = () => (dispatch, getState) => {
    dispatch({
        type: LIST_PERMISSION,
    });

    fetch(`${API_URL}/api/permission`, {
        method: "GET",
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: LIST_PERMISSION_SUCCESS,
            payload: resp.results
        })
    })
}

export let getPermissionById = (id) => (dispatch, getState) => {
    dispatch({
        type: PERMISSION_BY_ID,
    });

    fetch(`${API_URL}/api/permission/${id}`, {
        method: "GET",
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {

        let _res = resp.results.map(row => {
            row.checked = row.permission_role === null ? false : true;
            return row
        });


        dispatch({
            type: PERMISSION_BY_ID_SUCCESS,
            payload: _res,
            row: resp.role
        })
    })
}

export let onSavePermission = (form, role_id) => (dispatch, getState) => {
    dispatch({
        type: CREATE_SUCCESS,
    });

    fetch(`${API_URL}/api/permission`, {
        method: "POST",
        body: JSON.stringify({ data: form, role_id }),
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {

        dispatch({
            type: CREATE_SUCCESS_SUCCESS,
        })
    })
}

export let onUpdatePermission = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_SUCCESS,
    });

    fetch(`${API_URL}/api/permission/${form.id}`, {
        method: "PUT",
        body: JSON.stringify(form),
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {

        dispatch({
            type: CREATE_SUCCESS_SUCCESS,
        })
    })
}