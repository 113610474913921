import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { getPermissionById, onSavePermission, onUpdatePermission } from '../../Redux/permissionDuck';

import ItemMenu from './item.menu';
import TemplateMenu from '../../template/menu.template'
import ModalPermission from './modal.permission';

const EditPermissionPage = ({ getPermissionById, data, fetching, onSavePermission, fetching_create, row, onUpdatePermission }) => {
    let { id } = useParams()

    const [listMenu, setListMenu] = useState([])
    const [title, setTitle] = useState('')
    const [selected, setSelected] = useState({})
    const [modal, setModal] = useState(false)


    const { addToast } = useToasts();


    useEffect(() => {
        if (fetching === 2) {
            setTitle(row.description)
            setListMenu(data)
        }
    }, [fetching])

    useEffect(() => {
        if (fetching_create === 2) {
            addToast('Guardado con Exito', { appearance: 'success', autoDismiss: true });
            getPermissionById(id)
        }
    }, [fetching_create])


    useEffect(() => {
        getPermissionById(id)
    }, [id])



    const onSavePermissionTmp = () => {
        onSavePermission(listMenu, id)
    }

    const onOpenModal = (item) => {
        setSelected(item)
        setModal(true)
    }

    const onEditPermission = (item) => {
        onUpdatePermission(item)
        setModal(false)
    }

    return (
        <TemplateMenu>
            <Container fluid>
                <Row className='my-2'>
                    <Col lg={2}>
                        <button className='btn btn-info btn-sm' onClick={onSavePermissionTmp}>Guardar</button>
                    </Col>
                    <Col lg={1} className="d-flex align-items-center">Editando: </Col>
                    <Col>
                        <h3 className='text-capitalize'>{title}</h3>
                    </Col>
                </Row>
                <Row>
                    {listMenu.map(menu => <ItemMenu item={menu} key={menu.id} onClick={onOpenModal} />)}
                </Row>

                

                <ModalPermission show={modal} selected={selected} onHide={() => setModal(false)} onSuccess={onEditPermission} />
            </Container>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { permission } = props
    return {
        fetching: permission.fetching_by_id,
        fetching_create: permission.fetching_create,
        data: permission.data,
        row: permission.row,

    }
}

export default connect(mapsState, { getPermissionById, onSavePermission, onUpdatePermission })(withRouter(EditPermissionPage));