import React, { Fragment, useEffect, useState } from 'react'
import TemplateMenu from "../../template/menu.template";
import { connect } from 'react-redux';
import { getComparationProduct } from '../../Redux/reportDuck'
import { Col, Row, Table } from 'react-bootstrap';
import { formatNumber } from '../../utils';

const ComparationProductPage = ({ getComparationProduct, fetching_product, data_product }) => {

    const [list, setList] = useState({ result_by_quantity: [], result_by_total: [] })

    useEffect(() => {
        getComparationProduct()
    }, [])

    useEffect(() => {
        if (fetching_product === 2) {
            setList(data_product)
        }
    }, [fetching_product])


    return (
        <TemplateMenu>
            <Row>
                <Col><p>Comparación</p></Col>
            </Row>

            <Row>
                <Col>
                    <Row>
                        <Col>Ventas por Unidades</Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table className='table table-sm table-bordered table-striped'>
                                <thead className='thead-dark'>
                                    <tr>
                                        <th>Producto</th>
                                        <th>Unidades</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {list.result_by_quantity.map((d, k) => <tr key={k}>
                                        <td>{d.product}</td>
                                        <td align='right'>{d.quantity}</td>
                                        <td align='right'>{formatNumber(d.subtotal)}</td>
                                    </tr>)}

                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>TOTAL</th>
                                        <th className='text-right'>{formatNumber(list.result_by_quantity.reduce((a, b) => a + parseInt(b.quantity), 0))}</th>
                                        <th className='text-right'>{formatNumber(list.result_by_quantity.reduce((a, b) => a + parseInt(b.subtotal), 0))}</th>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Col>Ventas por Total</Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table className='table table-sm table-bordered table-striped'>
                                <thead className='thead-dark'>
                                    <tr>
                                        <th>Producto</th>
                                        <th>Unidades</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {list.result_by_total.map((d, k) => <tr key={k}>
                                        <td>{d.product}</td>
                                        <td align='right'>{d.quantity}</td>
                                        <td align='right'>{formatNumber(d.subtotal)}</td>
                                    </tr>)}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>TOTAL</th>
                                        <th className='text-right'>{formatNumber(list.result_by_total.reduce((a, b) => a + parseInt(b.quantity), 0))}</th>
                                        <th className='text-right'>{formatNumber(list.result_by_total.reduce((a, b) => a + parseInt(b.subtotal), 0))}</th>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                    </Row></Col>
            </Row>


        </TemplateMenu >
    )
}

const mapsState = (props) => {
    let { report } = props

    return {
        fetching_product: report.fetching_product,
        data_product: report.data_product,
    }
}

export default connect(mapsState, { getComparationProduct })(ComparationProductPage);