import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FcCheckmark } from "react-icons/fc";
import { formatNumber } from '../../utils';
import { MdEditNote } from 'react-icons/md';


const ItemDetailCostWorker = ({ item, onConfirmCost, onConfirmCheck, type, readOnly, locked }) => {
    let { worker } = item;

    const [newValue, setNewValue] = useState((item.amount_real === null) ? '0' : item.amount_real)

    const [enable, setEnable] = useState(false)

    let diff = item.amount_real - item.amount;


    const onConfirmCostTmp = () => {
        setEnable(false)
        onConfirmCost(item, newValue, type)
    }

    return <tr>
        <td>{worker.name} ({worker.type_worker_text})</td>
        <td align="right">{item.amount_formated}</td>
        {enable !== true ?
            <>
                <td align="right">
                    {item.amount_real_formated}
                </td>
                <td align="right"><span className={`${diff < 0 ? 'text-danger' : 'text-success'}`}>${formatNumber(item.amount_real - item.amount)}</span></td>
                <td>
                    {locked === false ? <>
                        {readOnly !== true ?
                            <>
                                <MdEditNote size={30} className='cursor' onClick={() => setEnable(true)} />
                                {item.amount !== item.amount_real && <FcCheckmark className="icon-edit cursor-pointer" onClick={() => onConfirmCheck(item, type)} />}
                            </> : null}
                    </> : null}

                </td>
            </> :
            <td>
                <Row className="mx-0 px-0">
                    <Col>
                        <Row className='my-2'>
                            <Col className='mx-0 px-0'>
                                <input type="number" className="form-control input-sm" value={newValue} onChange={(e) => setNewValue(e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mx-0 px-0">
                                <button className="btn btn-info mr-3" onClick={() => onConfirmCostTmp(item, newValue)}>Confirmar</button>
                                <button className="btn btn-danger" onClick={() => setEnable(false)}>Cerrar</button>
                            </Col>
                        </Row>
                    </Col>

                </Row>

            </td>}


    </tr>
}
export default ItemDetailCostWorker;