import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import logo from '../../Images/logo_madera.png'
import { formatNumber } from '../../utils'

const API_URL = process.env.REACT_APP_API_URL

const ListProduct = ({ item, edit }) => {

    let image = (item.image === '' || item.image === null) ? logo : API_URL + item.image;
    let { products_brand } = item

    const printItemPrice = (item, key) => {
        let { branch_office } = item

        return (
            <Row key={key}>
                <Col>{(branch_office!==null)?branch_office.title:null}</Col>
                <Col><span className="text-naranja">{formatNumber(item.price)}</span></Col>
            </Row>
        )
    }


    return (
        <>
            <Col lg={4} className="mt-1">
                <Card className="bg-white rounded-lg active_list_reservation p-2">
                    <Row>
                        <Col lg={4} md={3} className="d-flex justifty-content-center align-items-center ">
                            <img src={image} className="img-fluid rounded-lg " />
                        </Col>
                        <Col lg={8} md={9}>
                            <Row className="h5">
                                {item.title}
                            </Row>
                            <Row className="">
                                <Col lg={6} md={6}>
                                    <Row>
                                        <span className="font-13 text-gris">Precios</span>
                                    </Row>
                                    {(products_brand !== undefined) && products_brand.map(printItemPrice)}
                                </Col>
                                <Col lg={6} md={6}>
                                    <Row>
                                        <span className="font-13 text-gris">Picante</span>
                                    </Row>
                                    <Row>
                                        <span className="text-naranja">{item.hot_spicy ? 'Si' : 'No'}</span>
                                    </Row>
                                    <Row>
                                        <Col>Prioridad</Col>
                                    </Row>
                                    <Row>
                                        <Col>{item.priority}</Col>
                                    </Row>
                                </Col>

                                <Col lg={6} md={6}>
                                    <Row>
                                        <span className="font-13 text-gris">Recomendado</span>
                                    </Row>
                                    <Row>
                                        <span className="text-naranja">{item.recomended ? 'Si' : 'No'}</span>
                                    </Row>
                                </Col>
                                <Col lg={6} md={6} className="d-flex align-items-end justify-content-start pl-0 pr-4">
                                    <button className="bg-naranja text-white w-100 rounded-lg" onClick={() => edit(item)}>
                                        Editar
                                    </button>
                                </Col>

                            </Row>
                        </Col>
                        <Col lg={12} md={12} className="px-4 py-2">
                            <Row className="text-naranja font-13 py-3">
                                <Col>Descripción</Col>
                            </Row>
                            <Row className="text-gris">
                                <Col>{item.description}</Col>
                            </Row>
                        </Col>
                    </Row>

                </Card>
            </Col>

        </>
    )
}
export default ListProduct;