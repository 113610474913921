import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Tabs, Tab } from 'react-bootstrap';

import { connect } from 'react-redux';
import SegmentWorker from './segment.worker';
import SegmentWorkerTurn from './segment.worker.turn';
import { getWorkers } from '../../Redux/workerDuck'
import { getParameterBySegment } from '../../Redux/parameterDuck'
import { getBranch } from '../../Redux/branchofficeDuck'
import TemplateMenu from '../../template/menu.template'


const WorkersPage = ({ getWorkers, fetching_by_segment, segment, data_segment, getParameterBySegment, getBranch }) => {

    const [listCountry, setListCountry] = useState([])
    const [listPosition, setListPosition] = useState([])
    const [listArea, setListArea] = useState([])
    const [listEps, setListEps] = useState([])
    const [listArl, setListArl] = useState([])
    const [listTypePayment, setListTypePayment] = useState([])

    useEffect(() => {
        getBranch()
    }, [])


    useEffect(() => {
        if (fetching_by_segment === 2) {

            if (segment === 'country') {
                setListCountry(data_segment)
                getParameterBySegment("position_worker")
            }

            if (segment === 'position_worker') {
                setListPosition(data_segment)
                getParameterBySegment("worker_area")
            }
            if (segment === 'worker_area') {
                setListArea(data_segment)
                getParameterBySegment("eps")
            }

            if (segment === 'eps') {
                setListEps(data_segment)
                getParameterBySegment("arl")
            }
            if (segment === 'arl') {
                setListArl(data_segment)
                getParameterBySegment("type_payment")
            }
            if (segment === 'type_payment') {
                setListTypePayment(data_segment)
            }
        }
    }, [fetching_by_segment])

    return (
        <TemplateMenu>
            <Container fluid>

                <Row>
                    <Col>
                        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
                            <Tab eventKey="home" title="Trabajadores">
                                <SegmentWorker
                                    listCountry={listCountry}
                                    listPosition={listPosition}
                                    listArea={listArea}
                                    listArl={listArl}
                                    listEps={listEps}
                                    listTypePayment={listTypePayment} />
                            </Tab>
                            <Tab eventKey="profile" title="Turneros">
                                <SegmentWorkerTurn
                                    listCountry={listCountry}
                                    listPosition={listPosition}
                                    listArea={listArea} />
                            </Tab>

                        </Tabs>
                    </Col>
                </Row>

            </Container>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { parameter, user } = props

    return {
        fetching_by_segment: parameter.fetching_by_segment,
        segment: parameter.segment,
        data_segment: parameter.data_segment,
    }
}

export default connect(mapsState, { getWorkers, getParameterBySegment, getBranch })(WorkersPage);