import React from 'react'
import { formatNumber } from '../../utils';
import { Link } from 'react-router-dom';
import moment from 'moment';

const ItemProductionTable = ({ item, onDeleteItem }) => {
    return (
        <tr>
            <td>{item.id}</td>
            <td>{moment(item.registration_date).format("YYYY-MM-DD")}</td>
            <td>{item.product}</td>
            <td align='right'>{item.amount}</td>
            <td align='right'>{item.amount_generate}</td>
            <td align='right'>{formatNumber(item.cost_production)}</td>
            <td align='right'>{formatNumber(item.total_production)}</td>
            <td>
                <Link className='btn btn-info btn-sm' to={`/produccion/${item.id}`}>Ver</Link>
                <button className='btn btn-sm btn-danger mx-2' onClick={() => onDeleteItem(item)}>Eliminar</button>
            </td>

        </tr>
    )
}

export default ItemProductionTable;