import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import TemplateMenu from '../../template/menu.template'
import { getTransferById, onDeleteTransfer, onDeleteTransferDetail } from '../../Redux/transferDuck'
import ItemTransferEdit from './item.transfer.edit';
import moment from 'moment';
import { formatNumber } from '../../utils';
import ItemTransferEditDelivery from './item.transfer.edit.delivery.js';

const EditTransferPage = ({ getTransferById, row, fetching_by_id, fetching_create, onDeleteTransfer,
    onDeleteTransferDetail, fetching_delete }) => {
    const { id } = useParams();
    const [data, setData] = useState({ supplier: {}, branch_delivery: { title: '' } })
    const [list, setList] = useState([])
    const [total, setTotal] = useState(0)
    const [cost, setCost] = useState(0)
    const [quantity, setQuantity] = useState(0)
    const [totalCharge, setTotalCharge] = useState(0)
    const [totalMargin, setTotalMargin] = useState(0)
    const [show, setShow] = useState(false)
    const [titleBranch, setTitleBranch] = useState('')
    const history = useHistory();
    const [title, setTitle] = useState('')
    const [point, setPoint] = useState('')

    useEffect(() => {
        getTransferById(id)
    }, [id])

    useEffect(() => {
        if (fetching_create === 2) {
            window.location.reload()
        }
    }, [fetching_create])
    useEffect(() => {
        if (fetching_delete === 2) {
            window.location.href = "/transferencias"
        }
    }, [fetching_delete])


    useEffect(() => {
        if (fetching_by_id === 2) {
            let branch = JSON.parse(localStorage.getItem("branch"));
            if (row.branch_delivery.id === branch.id) {
                setTitleBranch(row.branch.title)
                setShow(false)
                setTitle("Transferencia de Entrada")
                setPoint("Punto Origen")

            } else {
                setTitle("Transferencia de Salida")
                setPoint("Punto Destino")
                setShow(true)
                setTitleBranch(row.branch_delivery.title)
            }

            setData(row)
            setList(row.transfer_product)

            let _quantity = row.transfer_product.reduce((a, b) => a + parseFloat(b.quantity), 0)
            let _cost = row.transfer_product.reduce((a, b) => a + parseFloat(b.cost), 0)
            let _total = row.transfer_product.reduce((a, b) => a + (parseFloat(b.quantity) * parseFloat(b.cost)), 0)
            let _totalCharge = row.transfer_product.reduce((a, b) => a + (parseFloat(b.quantity) * parseFloat(b.cost)) + ((parseFloat(b.quantity) * parseFloat(b.cost)) * parseFloat(b.charge / 100)), 0)
            let _totalMargin = row.transfer_product.reduce((a, b) => a + ((parseFloat(b.quantity) * parseFloat(b.cost)) * parseFloat(b.charge / 100)), 0)
            setQuantity(_quantity)
            setCost(_cost)
            setTotalCharge(_totalCharge)
            setTotal(_total)
            setTotalMargin(_totalMargin)
        }
    }, [fetching_by_id])

    useEffect(() => {
        if (fetching_create === 2) {

        }
    }, [fetching_create])


    const onDeleterRow = (item) => {
        if (window.confirm("¿Deseas Eliminar Este Registro?")) {
            if (list.length > 1) {
                onDeleteTransferDetail(item.id)
            } else {
                onDeleteTransfer(data.id)
            }
        }
    }


    return (
        <TemplateMenu>
            <Row className='mt-3'>
                <Col>
                    <button className='btn btn-sm btn-secondary' onClick={() => history.goBack()}>Volver</button>
                </Col>
            </Row>
            <Row className='d-flex justify-content-center'>
                <Col lg={8}>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header>
                                    <Row>
                                        <Col><h4>{title} <b>#{data.id}</b></h4></Col>
                                        <Col className='d-flex justify-content-end'>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <Row>
                                                <Col>Fecha de Registro</Col>
                                                <Col>{moment(data.registration_date).format("YYYY-MM-DD")}</Col>
                                            </Row>
                                            <Row>
                                                <Col>{point}</Col>
                                                <Col>{titleBranch}</Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className='mt-2'>
                        <Col>
                            <Card>
                                <Card.Header>
                                    Productos Transferidos
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            {show ?
                                                <Table className='table-sm table-bordered'>
                                                    <thead className='thead-dark'>
                                                        <tr>
                                                            <th>Producto</th>
                                                            <th>Cantidad</th>
                                                            <th>Costo Unit</th>
                                                            <th>Subtotal</th>
                                                            <th>Cargo</th>
                                                            <th>SubTotal + Cargo</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {list.map(p => <ItemTransferEdit
                                                            item={p} key={p.id}
                                                            onDeleterRow={onDeleterRow}
                                                            show={show} />)}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td><b>TOTAL</b></td>
                                                            <td align='right'>{quantity}</td>
                                                            <td align='right'><b>{formatNumber(cost)}</b></td>
                                                            <td align='right'><b>{formatNumber(total)}</b></td>
                                                            <td align='right'><b>{formatNumber(totalMargin)}</b></td>
                                                            <td align='right'><b>{formatNumber(totalCharge)}</b></td>
                                                        </tr>
                                                    </tfoot>
                                                </Table>
                                                :
                                                <Table className='table-sm table-bordered'>
                                                    <thead className='thead-dark'>
                                                        <tr>
                                                            <th>Producto</th>
                                                            <th>Cantidad</th>
                                                            <th>Costo Unit</th>
                                                            <th>Subtotal</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {list.map(p => <ItemTransferEditDelivery item={p} key={p.id} />)}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td><b>TOTAL</b></td>
                                                            <td align='right'>{quantity}</td>
                                                            <td align='right'></td>
                                                            <td align='right'><b>{formatNumber(totalCharge)}</b></td>
                                                        </tr>
                                                    </tfoot>
                                                </Table>}

                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                </Col>
            </Row>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { transfer } = props
    return {
        row: transfer.row,
        fetching_by_id: transfer.fetching_by_id,
        fetching_create: transfer.fetching_create,
        fetching_delete: transfer.fetching_delete,
    }
}

export default connect(mapsState, { getTransferById, onDeleteTransfer, onDeleteTransferDetail })(EditTransferPage);