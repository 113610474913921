import React, { useState, useEffect } from 'react'
import { Modal, Container, Row, Col, Button } from 'react-bootstrap';
import { MdClose } from "react-icons/md";
import InputField from '../Components/Widgets/InputField';
import { connect } from 'react-redux';
import { createWorker, updateWorker } from '../../Redux/workerDuck'
import BlueTitleBack from '../Components/Widgets/BlueTitleBack'
import moment from 'moment';
import InputFieldCurrency from '../Components/Widgets/InputFieldCurrency';


const initState = {
    name: '', document: '', country_id: 12, position_id: 0, area_id: 0, date_of_admission: moment().format("YYYY-MM-01"), role_id: 0,
    branch_id: 0, status: true, salary: 0, bonification: 0, date_out: '', eps_id: 54, arl_id: 53, liquidation_id: 230
};

const ModalWorker = ({ showModal, onHide, errors, selected, fetching, createWorker, updateWorker, branch, onSuccess, listCountry,
    listPosition, listArea, formEdited, listEps, listArl, listTypePayment, user }) => {

    const [form, setForm] = useState(initState)
    const [isEnable, setIsEnable] = useState(true)

    useEffect(() => {

        if (fetching === 2) {
            onHide()
            onSuccess(selected)
        }
    }, [fetching])

    useEffect(() => {
        if (user.role_id === 1) {
            setIsEnable(true)
        } else {
            setIsEnable(false)
        }
    }, [user])


    useEffect(() => {

        if (Object.keys(formEdited).length > 0) {
            setForm(formEdited);
        } else {
            let branch = JSON.parse(localStorage.branch)
            initState.branch_id = branch.id
            setForm(initState)
        }

    }, [formEdited])


    const closeModalTmp = () => {
        onHide()
    }

    const updateInput = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const updateCheck = (e) => {

        setForm({
            ...form,
            [e.target.name]: e.target.checked
        })
    }

    const onSubmit = () => {

        if (form.id !== undefined) {
            updateWorker(form)
        } else {
            createWorker(form)
        }
    }

    const printSelect = (item, i) => {
        return (
            <option key={i} value={item.id}>{item.title}</option>
        )
    }
    const printSelectCountry = (item, i) => {
        return (
            <option key={i} value={item.id}>{item.description}</option>
        )
    }

    return (
        <Modal show={showModal} onHide={closeModalTmp} size="lg">
            <div className="modal-header border-bottom-custom">
                <BlueTitleBack title='TRABAJADORES' />
                <button type="button" className="close p-0 m-0" data-dismiss="modal" aria-label="Close" onClick={closeModalTmp}>
                    <MdClose />
                </button>
            </div>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Nombres y apellidos</label>
                                <InputField
                                    readOnly={!isEnable}
                                    placeholder="Agrega los nombres"
                                    value={form.name || ''}
                                    name="name"
                                    onChange={updateInput}
                                    error={errors.name}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Documento</label>
                                <InputField
                                    readOnly={!isEnable}

                                    type={"number"}
                                    placeholder="Agrega tu numero de Documento"
                                    value={form.document || ''}
                                    name="document"
                                    onChange={updateInput}
                                    error={errors.document}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Nacionalidad</label>
                                <select className="form-control" name="country_id" disabled={!isEnable} value={form.country_id} onChange={updateInput}>
                                    <option value="0">Selecciona la nacionalidad</option>
                                    {listCountry.map(printSelectCountry)}
                                </select>
                                {errors.country_id !== undefined && (<label className="error-label">{errors.country_id}</label>)}
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Cargo</label>
                                <select className="form-control" name="position_id" disabled={!isEnable} value={form.position_id} onChange={updateInput}>
                                    <option value="0">Selecciona El cargo</option>
                                    {listPosition.map(printSelectCountry)}
                                </select>
                                {errors.position_id !== undefined && (<label className="error-label">{errors.position_id}</label>)}
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Area</label>
                                <select className="form-control" name="area_id" disabled={!isEnable} value={form.area_id} onChange={updateInput}>
                                    <option value="0">Selecciona el area</option>
                                    {listArea.map(printSelectCountry)}
                                </select>
                                {errors.area_id !== undefined && (<label className="error-label">{errors.area_id}</label>)}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Tipo de Pago</label>
                                <select className="form-control" name="liquidation_id" disabled={!isEnable} value={form.liquidation_id} onChange={updateInput}>
                                    <option value="0">Selecciona tipo de Liquidacion</option>
                                    {listTypePayment.map(printSelectCountry)}
                                </select>
                                {errors.liquidation_id !== undefined && (<label className="error-label">{errors.liquidation_id}</label>)}
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>¿Pasar a Nomina?</label>
                                <select className="form-control" name="type_worker" disabled={!isEnable} value={form.type_worker} onChange={updateInput}>
                                    <option value="1">Trabajador Nomina</option>
                                    <option value="2">Turnero</option>

                                </select>
                                {errors.type_worker !== undefined && (<label className="error-label">{errors.type_worker}</label>)}
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="form-group">
                                <label>Salario</label>
                                <InputFieldCurrency
                                    readOnly={!isEnable}
                                    value={form.salary}
                                    name={"salary"}
                                    placeholder="Monto"
                                    onValueChange={(value, name) => setForm({ ...form, [name]: value })}
                                    error={errors.salary}
                                />
                                {/* <InputField
                                    readOnly={!isEnable}
                                    type="number"
                                    placeholder="Salario"
                                    value={form.salary || ''}
                                    name="salary"
                                    onChange={updateInput}
                                    error={errors.salary}
                                /> */}
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="form-group">
                                <label>Bonificación</label>
                                <InputFieldCurrency
                                    readOnly={!isEnable}
                                    value={form.bonification}
                                    name={"bonification"}
                                    placeholder="Bonificación"
                                    onValueChange={(value, name) => setForm({ ...form, [name]: value })}
                                    error={errors.bonification}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>EPS</label>
                                <select className="form-control" readOnly={!isEnable} name="eps_id" value={form.eps_id} onChange={updateInput}>
                                    <option value="0">Selecciona la EPS</option>
                                    {listEps.map(printSelectCountry)}
                                </select>
                                {errors.eps_id !== undefined && (<label className="error-label">{errors.eps_id}</label>)}
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="form-group">
                                <label>ARL</label>
                                <select className="form-control" readOnly={!isEnable} name="arl_id" value={form.arl_id} onChange={updateInput}>
                                    <option value="0">Selecciona la ARL</option>
                                    {listArl.map(printSelectCountry)}
                                </select>
                                {errors.arl_id !== undefined && (<label className="error-label">{errors.arl_id}</label>)}
                            </div>
                        </Col>
                    </Row>

                    <Row>


                        <Col>
                            <div className="form-group">
                                <label>Fecha de Ingreso</label>
                                <InputField
                                    type="date"
                                    placeholder="Confirmación de la clave"
                                    value={form.date_of_admission || ''}
                                    name="date_of_admission"
                                    onChange={updateInput}
                                    error={errors.date_of_admission}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                                <label>Fecha de Salida</label>
                                <InputField
                                    type="date"
                                    value={form.date_out || ''}
                                    name="date_out"
                                    onChange={updateInput}
                                    error={errors.date_out}
                                />
                            </div>
                        </Col>


                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label>Sucursal</label>
                                <select className="form-control" readOnly={!isEnable} name="branch_id" value={form.branch_id} onChange={updateInput}>
                                    <option value="0">Selecciona la sede</option>
                                    {branch.map(printSelect)}
                                </select>
                                {errors !== undefined && (<label className="error-label">{errors.branch_id}</label>)}
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="form-group">
                                <label>¿Recibe Propina?</label>
                                <input
                                    type="checkbox"
                                    className="form-control"
                                    checked={form.tips || false}
                                    name="tips"
                                    onChange={updateCheck}
                                />
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="form-group">
                                <label>Estado</label>
                                <input
                                    disabled={!isEnable}
                                    type="checkbox"
                                    className="form-control"
                                    checked={form.status || false}
                                    name="status"
                                    onChange={updateCheck}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Row className="justify-content-center">
                    <Col>
                        <button
                            className='btn btn-success'
                            onClick={onSubmit}
                        >Guardar</button>
                    </Col>
                    <Col>
                        <button
                            className='btn btn-danger'
                            onClick={closeModalTmp}
                        >Cerrar</button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

const mapsState = (props) => {
    return {
        errors: props.worker.errors,
        selected: props.worker.selected,
        fetching: props.worker.fetching_create,
        branch: props.branchoffice.data
    }
}

export default connect(mapsState, { createWorker, updateWorker })(ModalWorker);