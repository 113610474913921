import React from 'react'
import { Col, Modal, Row, Table } from 'react-bootstrap';
import ItemReview from './item.review';

const ModalReviewPurchase = ({ show, onHide, listDetail, onSuccess }) => {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Confirmación de Compra</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Table className='table-sm table-bordered'>
                            <thead className='thead-dark'>
                                <tr>
                                    <th>Producto</th>
                                    <th>Cantidad</th>
                                    <th>Costo</th>
                                    <th>Subtotal</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listDetail.filter(d => d.deleted === undefined).map((row, k) => <ItemReview item={row} key={k} />)}
                            </tbody>
                        </Table>
                    </Col>
                </Row>

            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col><button className='btn btn-sm btn-success' onClick={onSuccess}>Confirmar</button></Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalReviewPurchase;