import React, { Fragment } from 'react'

const TextAreaField = ({ name, value, placeholder, onChange, error }) => {
    return (
        <Fragment>
            <textarea
                className="form-control"
                value={value}
                name={name}
                placeholder={placeholder}
                onChange={onChange}></textarea>
            
            {error !== undefined && (<label className="error-label">{error}</label>)}
        </Fragment>
    )
}

export default TextAreaField