import React, { useEffect, useState } from 'react'

const RowCharge = ({ item, onBlur }) => {

    const [form, setForm] = useState({ value: '' })

    useEffect(() => {
        if (item.value !== undefined) {
            setForm({
                ...form,
                value: item.value
            })
        }

    }, [item])

    const onBlurPre = (e) => {
        onBlur(e.target.value, item)
    }

    const updateInput = (e) => {
        item.value = e.target.value
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    return (
        <tr>
            <td>{item.name}</td>
            <td>{item.measure_unit}</td>
            <td>
                <select className='form-control' onChange={updateInput}>
                    <option value={1}>Porcentaje</option>
                    <option value={2}>Valor</option>
                </select>
            </td>
            <td>
                <input type='number' className='form-control text-right' name='value' value={form.value} onBlur={onBlurPre} onChange={updateInput} />
            </td>
        </tr>

    )
}

export default RowCharge;