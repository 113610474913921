import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import TemplateMenu from '../../template/menu.template'
import { getSettlementById } from '../../Redux/settlementDuck'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';


const EditSettlementPage = ({ getSettlementById }) => {

    let { id } = useParams();

    useEffect(() => {
        getSettlementById(id)
    }, [id])
    return (
        <TemplateMenu>
            <Row>
                <Col>EditSettlement</Col>
            </Row>

        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { settlement } = props
    return {}
}

export default connect(mapsState, { getSettlementById })(EditSettlementPage);