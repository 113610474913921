import moment from 'moment-timezone';
import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { formatNumber } from '../../utils';
import { AiFillEdit } from 'react-icons/ai';

moment.tz("America/Bogota")

const ItemExpensesBalance = ({ row, onDeleteItem, onEditServerItem }) => {

    let { parameter_group } = row
    let color = row._id !== undefined ? 'success' : 'danger';
    const [input, setInput] = useState('');
    const [show, setShow] = useState(false);
    let group = parameter_group.parent === undefined ? row.concept : parameter_group.parent.description

    const onEditItem = (item) => {
        setInput(item.expense)
        setShow(true)
    }

    const onSuccessEdit = () => {
        onEditServerItem(row, input);
        setShow(false)
    }

    let observation = row.observation === undefined ? '' : <span className='text-muted font-size-11 '>Observación: {row.observation}</span>

    return (
        <tr>
            <td>{moment(row.registration_date).format("DD MMM YYYY")}</td>
            <td>{group}</td>
            <td>{row.concept}<br />{observation}</td>
            <td align='right'>
                {row.datafono_with_tax !== undefined && row.datafono_with_tax !== "0" ? <><Row>
                    <Col>{formatNumber(row.datafono_with_tax || '')}</Col>
                </Row>
                    <Row>
                        <Col className='text-danger'>({formatNumber(row.ingreso || 0)})</Col>
                    </Row></> :
                    <Row>
                        <Col>{formatNumber(row.ingreso || 0)}</Col>
                    </Row>
                }

                {(row.origin !== '' && row.origin !== undefined) && <Row>
                    <Col className='text-muted font-size-11'>{row.origin.branch_office.title}</Col>
                </Row>}
            </td>
            <td align='right'>
                {show === true
                    ? <>
                        <Row>
                            <Col>
                                <input className='form-control' value={input} onChange={(e) => setInput(e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <button className='btn btn-success form-control mt-1' onClick={onSuccessEdit}>Guardar</button>
                            </Col>
                            <Col>
                                <button className='btn btn-danger  form-control mt-1' onClick={() => setShow(false)}>Cerrar</button>
                            </Col>
                        </Row>
                    </>
                    : formatNumber(row.expense || 0)}
            </td>
            <td>
                {row.type === 2 ?
                    <>
                        {show !== true && row.origin === '' &&
                            <>
                                {row.move_id !== 3 && <button className={`btn btn-sm mr-2`} onClick={() => onEditItem(row)}>
                                    <AiFillEdit size={25} />
                                </button>}
                                {row.move_id !== 3 && <button className={`btn btn-${color} btn-sm`} onClick={() => onDeleteItem(row)}>X</button>}

                            </>
                        }

                    </>
                    : null}
            </td>
        </tr>
    )
}

export default ItemExpensesBalance;