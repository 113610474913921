import React, { Fragment } from 'react'
import 'index.css'
import CurrencyInput from 'react-currency-input-field'

const InputFieldCurrency = ({ name, placeholder, error, onValueChange, value, onBlur }) => {
    return (
        <Fragment>
            <CurrencyInput
                value={value}
                className="form-control text-right"
                name={name}
                decimalSeparator=','
                groupSeparator='.'
                placeholder={placeholder}
                decimalsLimit={3}
                onValueChange={onValueChange}
                onBlur={onBlur}
            />
            {error !== undefined && (
                <label className="error-label">{error}</label>
            )}
        </Fragment>
    )
}

export default InputFieldCurrency