import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import TemplateMenu from '../../template/menu.template'
import FormProduction from './form.production';
import { getProductionById, onUpdateProduction } from '../../Redux/ProductionDuck'

const EditProductionPage = ({ getProductionById, fetching_by_id, row, onUpdateProduction, fetching_create }) => {

    let { id } = useParams();

    const history = useHistory();
    const [form, setForm] = useState({})

    useEffect(() => {
        if (fetching_by_id === 2) {
            setForm(row)
        }
    }, [fetching_by_id])

    useEffect(() => {
        if (fetching_create === 2) {
            history.goBack();
        }
    }, [fetching_create])

    useEffect(() => {
        getProductionById(id)
        console.log('id', id);
    }, [id])

    const onSavePrescription = (form) => {
        onUpdateProduction(form)
    }


    return (
        <TemplateMenu>
            <Row className='d-flex justify-content-center'>
                <Col lg={8}>
                    <Row className='my-2'>
                        <Col>
                            <button className='btn btn-sm btn-secondary' onClick={() => history.goBack()}>Volver</button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormProduction title={"Editar Producción"} onSubmit={onSavePrescription} formEdit={form} />
                        </Col>
                    </Row>
                </Col>
            </Row >
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { production } = props
    return {
        fetching_by_id: production.fetching_by_id,
        fetching_create: production.fetching_create,
        row: production.row
    }
}

export default connect(mapsState, { getProductionById, onUpdateProduction })(EditProductionPage);