import axios from 'axios'
import { getHeaders, validateCategory } from '../utils';
const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching_list: 0,
    fetching_create: 0,
    data: [],
    selected: {},
    errors: {},
    fetching_delete: 0
}

let LIST_CATEGORY = "LIST_CATEGORY";
let LIST_CATEGORY_SUCCESS = "LIST_CATEGORY_SUCCESS";
let CREATE_CATEGORY = "CREATE_CATEGORY";
let CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";


let CATEGORY_ERROR = "CATEGORY_ERROR";
let CATEGORY_SELECTED = "CATEGORY_SELECTED";
let CATEGORY_CLEAR = "CATEGORY_CLEAR";
let DELETE_CATEGORY = "DELETE_CATEGORY";
let DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";



export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_CATEGORY:
            return { ...state, fetching_list: 1 }
        case LIST_CATEGORY_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        case CREATE_CATEGORY:
            return { ...state, fetching_create: 1 }
        case CREATE_CATEGORY_SUCCESS:
            return { ...state, fetching_create: 2, fetching_lis:0, }

        case CATEGORY_ERROR:
            return { ...state, errors: action.payload, fetching: 3, fetching_list:0,selected: action.form }
        case CATEGORY_SELECTED:
            return { ...state, fetching_list: 0, selected: action.payload }
        // case CATEGORY_CLEAR:
        //     return { ...state, fetching: 0, selected: {} }

        // case DELETE_CATEGORY:
        //     return { ...state, fetching_delete: 1 }
        // case DELETE_CATEGORY_SUCCESS:
        //     return { ...state, fetching_delete: 2 }
        default:
            return state;
    }
}

export let clearSelected = () => (dispatch, getState) => {
    dispatch({
        type: CATEGORY_CLEAR
    })
}



export let getCategories = () => (dispatch, getState) => {
    dispatch({
        type: LIST_CATEGORY
    })

    if (getState().user.loggedIn) {

        axios.get(`${API_URL}/api/category`, {
            headers: getHeaders()

        }).then(resp => {
            dispatch({
                type: LIST_CATEGORY_SUCCESS,
                payload: resp.data.results
            });

        }).catch(function (error) {
            console.log(error);
        });
    } else {
        console.log("no Ingreso");
    }
}

export let createCategory = (form) => (dispatch, getState) => {

    console.log("createCategory", form)
    dispatch({
        type: CREATE_CATEGORY
    })

    let validate = validateCategory(form)

    if (Object.keys(validate).length > 0) {
        dispatch({
            type: CATEGORY_ERROR,
            payload: validate,
            form: form
        })
        return;
    }



    if (getState().user.loggedIn) {


        form.status_id = (form.status_id === true) ? 1 : 0;

        let formData = new FormData();
        form.id = (form.id === undefined) ? '' : form.id;
        formData.append("id", form.id)
        formData.append("title", form.title)
        formData.append("description", form.description)
        formData.append("priority", form.priority)
        formData.append("status_id", form.status_id)
        formData.append("type_menu_id", form.type_menu_id)
        formData.append("banner", form.banner)
        formData.append("photo", form.photo)

        axios.post(`${API_URL}/api/category`, formData, {
            headers: getHeaders()

        }).then(resp => {
            dispatch({
                type: CREATE_CATEGORY_SUCCESS,
                payload: resp.data.results,

            });

        }).catch(error => {
            let err = {}

            for (const [key, value] of Object.entries(error.response.data.errors)) {
                if ('title' === key) {
                    err.title = value[0]
                }
                if ('description' === key) {
                    err.description = value[0]
                }
            }

            dispatch({
                type: CATEGORY_ERROR,
                payload: err,
                form: form
            })
        });
    } else {
        console.log("no Ingreso");
    }
}


export let deleteCategory = (form) => (dispatch, getState) => {

    dispatch({
        type: DELETE_CATEGORY
    })

    if (getState().user.loggedIn) {

        axios.delete(`${API_URL}/api/category/${form.id}`, {
            headers: getHeaders()

        }).then(resp => {
            dispatch({
                type: DELETE_CATEGORY_SUCCESS
            });

        }).catch(error => {

        });
    } else {
        console.log("no Ingreso");
    }
}




