import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap';

const ModalFranchise = ({ show, onHide, listFranchises, onSuccess,formEdit, onEdit }) => {

    const [form, setForm] = useState({ parameter_id: 0, percent: '' })

    useEffect(()=>{
        if(Object.keys(formEdit).length>0){
            setForm(formEdit)
        }
        
    },[formEdit])

    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onAssign = () => {
        let _form = form

        console.log('form',form);
        

        let parameter = listFranchises.filter(f => parseInt(f.id) === parseInt(form.parameter_id))[0]
        _form.parameter = parameter
        
        if(form.id!==undefined){
            onEdit(_form)
        }else{
            onSuccess(_form)
        }
        
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Agregar Franquicia</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <div className='form-group'>
                            <label>Nombre Franquicia</label>
                            <select className='form-control' name='parameter_id' onChange={updateInput} value={form.parameter_id}>
                                <option value={0}>Seleccione</option>
                                {listFranchises.map((f, k) => <option key={k} value={f.id}>{f.description}</option>)}
                            </select>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className='form-group'>
                            <label>Porcentaje</label>
                            <input type={"number"} className='form-control' name="percent" onChange={updateInput} value={form.percent} />
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col><button className='btn btn-info btn-sm' onClick={onAssign}>Asignar</button></Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalFranchise;