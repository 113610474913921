import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Nav, Navbar, NavDropdown, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import logo_madera from '../Images/logo.png'
import PaymentBar from '../Pages/Components/PaymentBar';
import { onGetOrSetFilter } from '../Redux/menuDuck'
import { logOutAction, getDates, logOutServer } from '../Redux/userDuck'
import { FaWarehouse } from "react-icons/fa";
import { toogleLoader } from "../Redux/alertDuck";
import { Toaster } from 'react-hot-toast';


const TemplateMenu = ({ children, data, menu, fetching_menu, branch_offices, onGetOrSetFilter, fetching_branch,
    branch_selected, logOutAction, fetching_dates, data_dates, fetching_info, tenant, fetching_filter,
    getDates, reload, logOutServer, fetching_logout, toogleLoader }) => {

    const history = useHistory()
    const [listDates, setListDates] = useState([])
    const [selectedMonth, setSelectedMonth] = useState(0)
    const [branch, setBranch] = useState(0);
    const [listMenu, setListMenu] = useState([])
    const [listBranch, setListBranch] = useState([])
    const [dataUser, setDataUser] = useState({})
    const [isPayment, setIsPayment] = useState(true)
    const [footer, setFooter] = useState({ branch: { title: '' }, date: '' })

    useEffect(() => {
        if (fetching_menu === 2) {
            setListMenu(menu)
        }
    }, [fetching_menu])

    useEffect(() => {
        if (fetching_logout === 2) {
            logOutAction();
            history.push("/")
        }
    }, [fetching_logout])

    useEffect(() => {
        if (fetching_info === 2) {
            setDataUser(data)

            let { payment } = data.plan;

            if (payment !== null) {
                if (payment.date_diff > 0) {
                    setIsPayment(false)
                }
            }
        }
    }, [fetching_info])


    useEffect(() => {
        if (fetching_dates === 2) {

            let _date = data_dates.filter(r => r.selected === true)[0];

            if (localStorage.date === undefined) {
                localStorage.date = JSON.stringify(_date)
                setSelectedMonth(_date.id)
                setFooter({
                    ...footer,
                    "date": _date
                });
            } else {

                let _date = JSON.parse(localStorage.date)

                let current = _date;

                if (_date === undefined) {
                    current = data_dates.filter(d => d.selected === true)[0]
                }

                localStorage.date = JSON.stringify(current)
                setFooter({
                    ...footer,
                    "date": current
                });
                setSelectedMonth(current.id)
            }

            setListDates(data_dates);
            if (reload) {
                window.location.reload()
            }

        }
    }, [fetching_dates])

    useEffect(() => {

        if (fetching_branch === 2) {
            let branch = JSON.parse(localStorage.getItem("branch"));

            branch_offices = branch_offices.map(d => {
                if (d.id === branch.id) {
                    d.selected = true;
                } else {
                    d.selected = false;
                }

                return d;
            });

            if (branch.id !== 0) {
                let current = branch_offices.filter(d => d.selected === true)[0]
                setBranch(current.id)
                localStorage.branch = JSON.stringify(current);



                setFooter({
                    ...footer,
                    "branch": current
                });
                getDates(current.id, false)
            }

            setListBranch(branch_offices)
        }


    }, [fetching_branch])

    const printSubmenu = (item, i) => {
        return <Link className='dropdown-item' role="button" to={item.link} key={i}>{item.title}</Link>
    }

    const printMenu = (item, i) => {
        let { children } = item

        return (children.length > 0) ?
            <NavDropdown title={item.title} key={i} id="basic-nav-dropdown">
                {children.map(printSubmenu)}
            </NavDropdown>
            : <Nav.Link href="#" key={i}>{item.title}</Nav.Link>
    }

    const onFilterBranch = (e) => {
        toogleLoader(true)
        setBranch(e.target.value)
        if (parseInt(e.target.value) !== 0) {
            let _bra = branch_offices.filter(r => parseInt(r.id) === parseInt(e.target.value))[0]
            localStorage.branch = JSON.stringify(_bra);
            delete localStorage.date;
            getDates(e.target.value, true)
            //     onGetOrSetFilter(_bra)
        } else {
            localStorage.branch = JSON.stringify({ id: 0 });
            window.location.reload()

        }
    }

    const onChangeDate = (e) => {
        let _date = data_dates.filter(r => r.id === parseInt(e.target.value))[0];
        setSelectedMonth(_date.id)
        localStorage.date = JSON.stringify(_date)
        window.location.reload()
    }

    const logOut = () => {
        logOutServer()
    }

    return (
        <>
            <PaymentBar user={data} isPayment={isPayment} />
            <Navbar bg="dark" variant="dark" expand="lg" fixed='top' className={`${isPayment ? '' : 'mt-5'}`}>
                <Container fluid>
                    <Navbar.Brand href="/home">
                        <img src={logo_madera} className="img-fluid img-menu pr-2" alt="mana_logo" onClick={() => history.push("/home")} />
                        {tenant || ''}
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            {listMenu.map(printMenu)}
                        </Nav>
                        <Form className="form-input">
                            <Row>
                                <Col xs={12} lg={6} className='mt-1'>
                                    <select className='form-control' name="branch_filter" value={branch} onChange={onFilterBranch}>
                                        <option value={0}>TODOS</option>
                                        {listBranch.map(r => <option key={r.id} value={r.id}>{r.title}</option>)}
                                    </select>
                                </Col>
                                <Col className='mt-1' lg={6}>
                                    <select className='form-control' value={selectedMonth} onChange={onChangeDate}>
                                        {listDates.map(r => <option key={r.id} value={r.id}>{r.month_text}</option>)}
                                    </select>
                                </Col>
                            </Row>
                        </Form>

                        <Navbar.Collapse className="justify-content-end">
                            <Navbar.Text>
                                Signed:
                            </Navbar.Text>
                            <NavDropdown title={data.name} id="basic-nav-dropdown" className="justify-content-end color-opcion">
                                <Link className='dropdown-item' role="button" to={"/profile"}>Perfil</Link>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#" onClick={logOut}>
                                    Cerrar Sesión
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Navbar.Collapse>

                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Container fluid className={`${isPayment ? 'mt-5 pt-4' : 'mt-5 pt-6'}`}>
                <Toaster />
                {children}
            </Container>
            <Navbar bg="light" variant="dark" fixed='bottom' className='py-0 cursor-pointer'>
                <Container fluid>
                    <Row className='w-100 d-flex justify-content-center'>
                        <Col lg={6}>
                            <Row>
                                <Col className='text-center'>Sucursal</Col>
                                <Col className='px-0 d-flex align-items-center justify-content-between'>{footer.branch.title} {footer.branch.is_warehouse === true && <FaWarehouse />}</Col>
                                <Col className='text-center'>Mes</Col>
                                <Col className='text-center'>{footer.date.month_text}</Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Navbar>
        </>
    )
}

const mapsState = (props) => {
    let { user, menu, alert, branchoffice } = props

    return {
        loggedIn: user.loggedIn,
        data: user.data,
        fetching_info: user.fetching_info,
        reload: user.reload,
        fetching_dates: user.fetching_dates,
        data_dates: user.data_dates,
        tenant: user.data.tenant,
        fetching_logout: user.fetching_logout,
        menu: menu.data,
        fetching_menu: menu.fetching,
        fetching_filter: menu.fetching_filter,
        drawer: menu.drawer,
        loader: alert.loader,
        branch_offices: branchoffice.data,
        branch_selected: branchoffice.branch,
        fetching_branch: branchoffice.fetching,
    }
}

export default connect(mapsState, { onGetOrSetFilter, logOutAction, getDates, logOutServer, toogleLoader })(TemplateMenu);