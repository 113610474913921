import React, { useEffect, useState } from 'react'
import TemplateMenu from '../../template/menu.template'
import { Col, Row, Table } from 'react-bootstrap';
import { getMessagesBot } from '../../Redux/whatsappDuck'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const MessageBot = ({ getMessagesBot, fetching_list, data }) => {

    const [list, setList] = useState([])

    useEffect(() => {
        getMessagesBot()
    }, [])
    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
        }
    }, [fetching_list])

    return (
        <TemplateMenu>
            <Row>
                <Col><h3>Mensajes para Whatsapp</h3></Col>
            </Row>
            <Row>
                <Col>
                    <Table className='table table-sm table-hover table-bordered'>
                        <thead className='thead-dark'>
                            <tr>
                                <th>#</th>
                                <th width="60%">Mensajes</th>
                                <th>Template</th>
                                <th>Orden</th>
                                <th>Segment</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((d, k) => <tr key={k}>
                                <td>{k + 1}</td>
                                <td>{d.message}</td>
                                <td>{d.template}</td>
                                <td>{d.priority}</td>
                                <td>{d.segment}</td>
                                <td><Link to={`/mensajes-bot/${d.id}`} className='btn btn-info btn-sm'>Editar</Link></td>
                            </tr>)}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </TemplateMenu>

    )
}


const mapsState = (props) => {
    let { whatsapp } = props

    return {
        fetching_list: whatsapp.fetching_list,
        data: whatsapp.data
    }
}
export default connect(mapsState, { getMessagesBot })(MessageBot);