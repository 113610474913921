import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import ItemConfiguration from './item.configuration';

const SalaryConfig = ({ list, onSubmit }) => {


    const onSave = () => {
        onSubmit(list)
    }

    return (
        <Container fluid>
            <Row>
                <Col lg={6}>
                    <table className='table table-sm'>
                        <thead>
                            <tr>
                                <th>Concepto</th>
                                <th>Valor</th>
                                <th>Tipo</th>
                                <th>Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map(row => <ItemConfiguration row={row} key={row.id} />)}
                        </tbody>
                    </table>

                </Col>
            </Row>
            <Row>
                <Col>
                    <button className='btn btn-success btn-sm' onClick={onSave}>Guardar</button>
                </Col>
            </Row>
        </Container>
    )
}
const mapsState = (props) => {
    return {

    }
}

export default connect(mapsState, {})(SalaryConfig);