import React, { useState, useEffect, Fragment, useCallback, useRef, useContext } from 'react';
import RowTableClient from './row.table.client';
import { connect } from 'react-redux';

import { getProductsAldelo } from '../../Redux/productDuck'

import { toogleAlert } from '../../Redux/alertDuck'
import { Row, Col, Container } from 'react-bootstrap';
import SearchSection from '../Components/SearchSection';
import BtnWhiteHeader from '../Components/BtnWhiteHeader';

import { SocketContext } from '../../context/SocketContext';
import Swal from 'sweetalert2'




const ProductsAldeloPage = ({ getProductsAldelo, data, fetching, toogleAlert, current_page, last_page, onSearchUser, fetching_search }) => {

    const [list, setList] = useState([])
    const observer = useRef()
    const [status, setStatus] = useState(0)

    const { socket } = useContext(SocketContext)


    useEffect(() => {
        if (socket) {
            getProductsAldelo(socket)
            toogleAlert(true)

            socket.on("error-products-aldelo",data=>{
                toogleAlert(false)
                Swal.fire({
                    title: "Servidor Offline",
                    text: data.message,
                    icon: "error",
                })
            })
            
            socket.on("list-products-aldelo",data=>{
                toogleAlert(false)
                Swal.fire({
                    title: "Servidor Offline",
                    text: data.message,
                    icon: "error",
                })
            })
        }

    }, [socket])


    useEffect(() => {
        if (fetching === 2) {
            toogleAlert(false)
            setList(data)
        }
    }, [fetching])

    useEffect(() => {
        if (fetching_search === 2) {

            setList(data)
        }
    }, [fetching_search])

    const showModalTmp = () => {

    }

    const filterContent = (txt) => {
        onSearchUser(txt)
    }


    const lastBookElementRef = useCallback(node => {
        if (observer.current) observer.current.disconnect()

        // console.log('current_page',current_page)

        // observer.current = new IntersectionObserver(entries => {

        //     if (entries[0].isIntersecting && current_page <= last_page) {
        //         toogleAlert(true,"Cargando datos...")
        //         getAllClients(parseInt(current_page) + 1)

        //     }
        // })

        console.log('node', node)
        if (node) observer.current.observe(node)

    }, [current_page, status])

    return (
        <Container fluid>
            <Row className="mb-3">
                <Col lg={6} className="pl-2">
                    <BtnWhiteHeader title={'Lista'} />
                    <BtnWhiteHeader title={'Crear Usuario'} onClick={showModalTmp} />
                </Col>


                <Col lg={6} className="pr-4 justify-content-end">
                    <SearchSection
                        placeholder='Buscar Usuario'
                        value=''
                        name='buscar_usuario'
                        list={[{ id: 1, title: "Semanal" }]}
                    >

                    </SearchSection>
                </Col>
            </Row>

            <Row>
                <Col>
                    <table className="p-0 bg-azul table-bordered rounded-lg m-0 w-100 table-hover table-header-custom">
                        <thead>
                            <tr>
                                <td>Id</td>
                                <td>Titulo</td>
                                <td>Precio</td>
                                <td>ProductID</td>
                            </tr>
                        </thead>
                        <tbody>
                            {(list !== undefined) && list.map((row, i) => <RowTableClient
                                item={row}
                                index={i}
                                key={i}
                                refs={lastBookElementRef} />)}
                        </tbody>


                    </table>
                </Col>
            </Row>
        </Container>
    )
}

const mapsState = (props) => {
    let { clients, fetching_client, current_page, last_page, fetching_search } = props.user

    return {
        data: clients,
        fetching: fetching_client,
        current_page,
        last_page,
        fetching_search
    }
}

export default connect(mapsState, { getProductsAldelo, toogleAlert })(ProductsAldeloPage);