import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Table, Card, CardDeck } from "react-bootstrap";
import ModalParameter from "./ModalParameter";

import TemplateMenu from "../../template/menu.template";

import {
  getParameterAdmin,
  closeModal,
  openModal,
} from "../../Redux/parameterDuck";
import ModalParameterSpecific from "./ModalParameterSpecific";

const Parameters = ({ getParameterAdmin, closeModal, openModal, data, fetching }) => {
  const [form, setForm] = useState({});
  const [modal, setModal] = useState(false);
  const [modalSpecific, setModalSpecific] = useState(false);
  const [list, setList] = useState([]);
  const [segment, setSegment] = useState({ title: '', size: 0 });


  useEffect(() => {
    getParameterAdmin();
  }, []);

  useEffect(() => {
    if (fetching === 2) {
      console.log("data", data);

      setList(data);
    }
  }, [fetching]);

  const onHide = () => {
    setModal(false);
  };

  const edit = (item) => {
    setForm(item);
    setModal(true);
  };

  const deleteItem = (item) => {
    console.log("item", item);
  };

  const onSuccess = () => {
    setModal(false);
    getParameterAdmin();
  };

  const onSuccessSpecific = (form) => {
    setModalSpecific(false);
  }

  const onEdit = (row) => {
    setModalSpecific(true);
    setSegment()
  }

  const onShowModal = (_segment, size) => {
    setSegment({
      ...segment,
      ["title"]: _segment,
      ["size"]: size
    })
    setModalSpecific(true);
  }

  return (
    <TemplateMenu>
      <Container fluid>
        <Row className="my-4 justify-content-center">
          <Col lg={2}>
            <h4>Parametros</h4>
          </Col>
        </Row>
        <Row>
          {list.map((d, k) => (
            <Col lg={3} key={k} className="mt-2">
              <Card>
                <Card.Header>
                  <Card.Title>
                    <Row>
                      <Col>{d.segment}</Col>
                      <Col className="text-right">
                        <button className="btn btn-sm btn-success" onClick={() => onShowModal(d.segment, d.children.length)}>Agregar</button>
                      </Col>
                    </Row>
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Table className="table-sm table-condensed">
                    <tbody>
                      {d.children.map((c, key) => {
                        return (
                          <tr
                            key={key}
                            className={`${c.status !== true ? "table-secondary" : ""
                              }`}
                          >
                            <td>{c.description}</td>
                            <td>
                              <button className="btn btn-sm btn-info" onClick={() => onEdit(c)}>
                                Editar
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        {/* <Row className="justify-content-center">
          <Col lg={8}>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Descripción</th>
                  <th>Segmento</th>
                  <th>Orden</th>
                  <th>Estado</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {data !== undefined && data.length > 0 ? (
                  data.map(printDetail)
                ) : (
                  <tr>
                    <td colSpan="2" align="center">
                      No tienes Registros
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row> */}
        <ModalParameter
          showModal={modal}
          onHide={onHide}
          onSuccess={() => onSuccess()}
          formEdit={form}
        />
        <ModalParameterSpecific
          showModal={modalSpecific}
          onHide={() => setModalSpecific(false)}
          segment={segment}
          onSuccess={() => onSuccessSpecific()}
          formEdit={form}
        />
      </Container>
    </TemplateMenu>
  );
};

const mapsState = (props) => {
  console.log("props", props.parameter);
  return {
    data: props.parameter.data,
    fetching: props.parameter.fetching,
    showModal: props.parameter.modal,
  };
};

export default connect(mapsState, { getParameterAdmin, closeModal, openModal })(
  Parameters
);
