import React, { useEffect, useState } from 'react'
import { Badge, Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SearchSection from '../Components/SearchSection';
import { getListRequisition, onDeleteRequisition } from '../../Redux/requisitionDuck';
import TemplateMenu from '../../template/menu.template'
import { connect } from 'react-redux';
import ListRequisitionOrigin from './list.requisition.origin';
import ListRequisitionDeliver from './list.requisition.deliver';
import ListTemplateRequisition from './list.template.requisition';

const RequisitionPage = ({ getListRequisition, fetching_create, fetching_delete, fetching_list, products }) => {

    const [list, setList] = useState([])


    useEffect(() => {
        getListRequisition()
    }, [])

    useEffect(() => {

        if (fetching_list === 2) {


            let _branch = JSON.parse(localStorage.getItem("branch"))
            let _p = products.filter(p => p.status_id !== 3 && p.branch_id === _branch.id);

            setList(_p)
        }

    }, [fetching_list])

    useEffect(() => {
        if (fetching_create === 2) {
            getListRequisition()
        }
    }, [fetching_create])

    useEffect(() => {
        if (fetching_delete === 2) {
            getListRequisition()
        }
    }, [fetching_delete])

    const filterContent = () => {

    }

    const onCleanFilter = () => {

    }


    return (<TemplateMenu>
        <Container fluid>
            <Row className='mt-3'>
                <Col lg={6}>
                    <Link to="/requisiciones/new" className='btn btn-info btn-sm'>Realizar Requisición</Link>
                </Col>
                <Col lg={6} className="pr-4 justify-content-end d-flex align-items-center">
                    <SearchSection
                        placeholder='Buscar Requisiciones'
                        value=''
                        name='buscar_usuario'
                        onClick={filterContent}
                        onClean={onCleanFilter}
                    ></SearchSection>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Tabs
                        defaultActiveKey="home"
                        id="uncontrolled-tab-example"
                        className="mb-3">
                        <Tab eventKey="home" title="Requisiciones Realizadas">
                            <ListRequisitionOrigin />
                        </Tab>
                        <Tab eventKey="profile" title={
                            <>
                                Requisiciones Recibidas
                                {list.length > 0 && <div className='badge-custom mx-1'>{list.length}</div>}
                            </>
                        }>
                            <ListRequisitionDeliver />
                        </Tab>
                        <Tab eventKey="template" title="Plantillas">
                            <ListTemplateRequisition />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </Container>
    </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { requisition } = props
    return {
        fetching_list: requisition.fetching_list,
        fetching_create: requisition.fetching_create,
        fetching_delete: requisition.fetching_delete,
        products: requisition.data,
    }
}

export default connect(mapsState, { getListRequisition, onDeleteRequisition })(RequisitionPage);