import React, { useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


const ItemPayment = ({ row, onChange }) => {
    const [form, setForm] = useState(row.checked)
    let { total } = row || ''

    const updateInput = (e) => {
        setForm(e.target.checked)
        row.checked = e.target.checked;
        onChange(row)
    }

    let { worker } = row
    return <tr>
        <td><Link to={`/ingresos/${total.registration_date}/check`} target="_blank">{total.registration_date}</Link></td>
        <td>{worker.name}{total.status_id !== 4 ? <span className='text-muted'> {`(Cierre Pendiente)`}</span> : ''}</td>
        <td align='right'>{row.amount_real_formated}</td>
        <td><input type={"checkbox"} className="form-control-custom" checked={form} onChange={updateInput} /></td>
    </tr>
}

export default ItemPayment;