import axios from 'axios'
import { getHeaders, removeSession, validatePayroll } from '../utils';
const API_URL = process.env.REACT_APP_API_URL


let initialState = {
    fetching_list: 0,
    fetching_create: 0,
    fetching_liquidate: 0,
    fetching_group: 0,
    fetching_delete: 0,
    fetching_by_id: 0,
    fetching_reverse: 0,
    fetching_review: 0,
    fetching_timer: 0,
    message: '',
    data: [],
    data_group: [],
    data_worker: [],
    data_review: { total: { nequi: 0, daviplata: 0, cash: 0, datafono: 0 } },
    errors: {},
    row: {},
    link: ''
}

let LIST_PAYROLL = "LIST_PAYROLL";
let LIST_PAYROLL_SUCCESS = "LIST_PAYROLL_SUCCESS";

let LIST_PAYROLL_GROUP = "LIST_PAYROLL_GROUP";
let LIST_PAYROLL_GROUP_SUCCESS = "LIST_PAYROLL_GROUP_SUCCESS";

let PAYROLL_GROUP_ID = "PAYROLL_GROUP_ID";
let PAYROLL_GROUP_ID_SUCCESS = "PAYROLL_GROUP_ID_SUCCESS";


let CREATE_PAYROLL = "CREATE_PAYROLL";
let CREATE_PAYROLL_SUCCESS = "CREATE_PAYROLL_SUCCESS";
let CREATE_PAYROLL_ERROR = "CREATE_PAYROLL_ERROR";
let LIQUIDATE = "LIQUIDATE";
let LIQUIDATE_SUCCESS = "LIQUIDATE_SUCCESS";
let DELETE_PAYROLL = "DELETE_PAYROLL";
let DELETE_PAYROLL_SUCCESS = "DELETE_PAYROLL_SUCCESS";
let DELETE_PAYROLL_DEDUCTION = "DELETE_PAYROLL_DEDUCTION";
let DELETE_PAYROLL_DEDUCTION_SUCCESS = "DELETE_PAYROLL_DEDUCTION_SUCCESS";
let PAYROLL_ID = "PAYROLL_ID";
let PAYROLL_ID_SUCCESS = "PAYROLL_ID_SUCCESS";
let PRINT_PAYROLL = "PRINT_PAYROLL";
let PRINT_PAYROLL_SUCCESS = "PRINT_PAYROLL_SUCCESS";
let PAYROLL_REVERSE = "PAYROLL_REVERSE";
let PAYROLL_REVERSE_SUCCESS = "PAYROLL_REVERSE_SUCCESS";
let REVIEW_INPUT = "REVIEW_INPUT";
let REVIEW_INPUT_SUCCESS = "REVIEW_INPUT_SUCCESS";
let TIME_WORKER = "TIME_WORKER";
let TIME_WORKER_SUCCESS = "TIME_WORKER_SUCCESS";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_PAYROLL:
            return { ...state, fetching_list: 1, }
        case LIST_PAYROLL_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload, fetching_create: 0 }
        case CREATE_PAYROLL:
            return { ...state, fetching_create: 1, }
        case CREATE_PAYROLL_SUCCESS:
            return { ...state, fetching_create: 2, }
        case CREATE_PAYROLL_ERROR:
            return { ...state, fetching_create: 3, errors: action.payload }
        case LIQUIDATE:
            return { ...state, fetching_liquidate: 1 }
        case LIQUIDATE_SUCCESS:
            return { ...state, fetching_liquidate: 2, fetching_create: 0 }
        case DELETE_PAYROLL:
            return { ...state, fetching_delete: 1 }
        case DELETE_PAYROLL_SUCCESS:
            return { ...state, fetching_delete: 2 }
        case DELETE_PAYROLL_DEDUCTION:
            return { ...state, fetching_delete: 1 }
        case DELETE_PAYROLL_DEDUCTION_SUCCESS:
            return { ...state, fetching_delete: 2 }
        case PAYROLL_ID:
            return { ...state, fetching_by_id: 1 }
        case PAYROLL_ID_SUCCESS:
            return { ...state, fetching_by_id: 2, data: action.payload }
        case PRINT_PAYROLL:
            return { ...state, fetching_pdf: 1 }
        case PRINT_PAYROLL_SUCCESS:
            return { ...state, fetching_pdf: 2, link: action.payload }
        case PAYROLL_REVERSE:
            return { ...state, fetching_reverse: 1 }
        case PAYROLL_REVERSE_SUCCESS:
            return { ...state, fetching_reverse: 2 }
        case REVIEW_INPUT:
            return { ...state, fetching_review: 1 }
        case REVIEW_INPUT_SUCCESS:
            return { ...state, fetching_review: 2, data_review: action.payload }
        case TIME_WORKER:
            return { ...state, fetching_timer: 1 }
        case TIME_WORKER_SUCCESS:
            return { ...state, fetching_timer: 2, data_worker: action.payload }
        case LIST_PAYROLL_GROUP:
            return { ...state, fetching_group: 1 }
        case LIST_PAYROLL_GROUP_SUCCESS:
            return { ...state, fetching_group: 2, data_group: action.payload }
        case PAYROLL_GROUP_ID:
            return { ...state, fetching_by_id: 1 }
        case PAYROLL_GROUP_ID_SUCCESS:
            return { ...state, fetching_by_id: 2, row: action.payload }
        default:
            return state;
    }
}

export let onGetReviewByDate = (form) => (dispatch, getState) => {
    dispatch({
        type: REVIEW_INPUT,
    });

    let branch = JSON.parse(localStorage.getItem("branch"));

    let url = `${API_URL}/api/payroll-review?${branch.filter}&date_init=${form.date_init}&date_end=${form.date_end}`;


    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: REVIEW_INPUT_SUCCESS,
            payload: resp
        })

    })
}

export let getPayroll = (query) => (dispatch, getState) => {
    dispatch({
        type: LIST_PAYROLL,
    });

    let branch = JSON.parse(localStorage.getItem("branch"));


    let url = `${API_URL}/api/payroll?branch_id=${branch.id}`;


    if (query !== undefined && query !== null) {
        url += query;
    }

    axios.get(url, {
        headers: getHeaders()
    }).then(resp => {
        dispatch({
            type: LIST_PAYROLL_SUCCESS,
            payload: resp.data.results
        })

    }).catch(error => {
        let { status } = error.response

        if (status == 401) {
            removeSession()
            // dispatch({
            //     type:LOSE_SESSION
            // }); 
        }
    })
}


export let getPayrollReport = (form) => (dispatch, getState) => {
    dispatch({
        type: LIST_PAYROLL,
    });

    let branch = JSON.parse(localStorage.getItem("branch"));

    let url = `${API_URL}/api/payroll-report?branch_id=${branch.id}&date_init=${form.date_init}&date_end=${form.date_end}`;

    if (form.worker_id !== '0') {
        url += `&worker_id=${form.worker_id}`
    }


    axios.get(url, {
        headers: getHeaders()
    }).then(resp => {
        dispatch({
            type: LIST_PAYROLL_SUCCESS,
            payload: resp.data.results
        })

    }).catch(error => {
        let { status } = error.response

        if (status == 401) {
            removeSession()
            // dispatch({
            //     type:LOSE_SESSION
            // }); 
        }
    })
}

export let getPayrollGroup = (query) => (dispatch, getState) => {
    dispatch({
        type: LIST_PAYROLL_GROUP,
    });

    let branch = JSON.parse(localStorage.getItem("branch"));


    let url = `${API_URL}/api/payroll-group?branch_id=${branch.id}`;


    if (query !== undefined && query !== null) {
        url += query;
    }

    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: LIST_PAYROLL_GROUP_SUCCESS,
            payload: resp.results
        })

    })
}

export let getPayrollGroupId = (id) => (dispatch, getState) => {
    dispatch({
        type: PAYROLL_GROUP_ID,
    });


    let url = `${API_URL}/api/payroll-group/${id}`;

    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: PAYROLL_GROUP_ID_SUCCESS,
            payload: resp
        })

    })
}

export let getPayrollId = (id) => (dispatch, getState) => {
    dispatch({
        type: PAYROLL_ID,
    });


    let url = `${API_URL}/api/payroll/${id}`;

    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        console.log('llego');

        dispatch({
            type: PAYROLL_ID_SUCCESS,
            payload: resp.results
        })

    })
}

export let getTimerWorker = (id, form) => (dispatch, getState) => {
    dispatch({
        type: TIME_WORKER,
    });

    let url = `${API_URL}/api/payroll-worker-timer/${id}?init=${form.init}&end=${form.end}`;

    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: TIME_WORKER_SUCCESS,
            payload: resp
        })

    })
}

export let onCreateMultiplePdf = (list) => (dispatch, getState) => {
    dispatch({
        type: PRINT_PAYROLL,
    });


    let url = `${API_URL}/api/payroll-pdf`;

    fetch(url, {
        method: "POST",
        body: JSON.stringify(list),
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        console.log('resp', resp);

        dispatch({
            type: PRINT_PAYROLL_SUCCESS,
            payload: resp.tmp_name
        })

    })
}

export let liquidatePayroll = (list, form, request) => (dispatch, getState) => {
    dispatch({
        type: LIQUIDATE,
    });

    let url = `${API_URL}/api/payroll-liquidate`;

    let branch = JSON.parse(localStorage.getItem("branch"));

    fetch(url, {
        method: "POST",
        body: JSON.stringify({ list, branch, form, request }),
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: LIQUIDATE_SUCCESS
        });
    })
}

export let createPayroll = (form) => (dispatch, getState) => {
    dispatch({
        type: CREATE_PAYROLL,
    });

    let validate = validatePayroll(form)

    if (Object.keys(validate).length > 0) {
        dispatch({
            type: CREATE_PAYROLL_ERROR,
            payload: validate
        })
        return;
    }


    let url = `${API_URL}/api/payroll`;

    fetch(url, {
        method: 'POST',
        body: JSON.stringify(form),
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: CREATE_PAYROLL_SUCCESS,
        })

    })
}

export let deletePayroll = (id) => (dispatch, getState) => {
    dispatch({
        type: DELETE_PAYROLL,
    });

    let url = `${API_URL}/api/payroll/${id}`;

    fetch(url, {
        method: 'DELETE',
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: DELETE_PAYROLL_SUCCESS,
        })

    })
}

export let updatePayroll = (id, form) => (dispatch, getState) => {
    dispatch({
        type: DELETE_PAYROLL,
    });

    let url = `${API_URL}/api/payroll/${id}`;

    fetch(url, {
        method: 'PUT',
        body: JSON.stringify(form),
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: DELETE_PAYROLL_SUCCESS,
        })

    })
}

export let deletePayrollOtherDeduction = (id) => (dispatch, getState) => {
    dispatch({
        type: DELETE_PAYROLL,
    });

    let url = `${API_URL}/api/payroll-deduction/${id}`;

    fetch(url, {
        method: 'DELETE',
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: DELETE_PAYROLL_SUCCESS,
        })

    })
}

export let deletePayrollOtherAdditional = (id) => (dispatch, getState) => {
    dispatch({
        type: DELETE_PAYROLL,
    });

    let url = `${API_URL}/api/payroll-additional/${id}`;

    fetch(url, {
        method: 'DELETE',
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: DELETE_PAYROLL_SUCCESS,
        })

    })
}


export let reversePayroll = (item) => (dispatch, getState) => {
    dispatch({
        type: PAYROLL_REVERSE,
    });

    let url = `${API_URL}/api/payroll-reverse/${item.id}`;

    fetch(url, {
        method: 'PUT',
        body: JSON.stringify(item),
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: PAYROLL_REVERSE_SUCCESS,
        })

    })
}
export let reverseAllPayroll = (id) => (dispatch, getState) => {
    dispatch({
        type: PAYROLL_REVERSE,
    });

    let url = `${API_URL}/api/payroll-all-reverse/${id}`;

    fetch(url, {
        method: 'PUT',
        body: JSON.stringify({ id }),
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: PAYROLL_REVERSE_SUCCESS,
        })

    })
}