import React, { useEffect, Fragment, useState } from 'react';
import { Row, Card, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import PaginationTable from '../Components/PaginationTable';
import { getOrderScheduled } from '../../Redux/orderDuck'
import logo from 'Images/logo_madera.png'


const API_URL = process.env.REACT_APP_API_URL


const SchedulePage = ({ list, getOrderScheduled, fetching_programmed }) => {

    const [selected, setSelected] = useState(false)
    const [data, setData] = useState([])

    useEffect(() => {
        getOrderScheduled()
    }, [])
    useEffect(() => {
        if (fetching_programmed === 2) {
            setData(list)
        }

    }, [fetching_programmed])



    const showDetail = (item) => {

        if (item === selected) {
            setSelected({})
        } else {
            setSelected(item)
        }



    }

    const printProducts = (item, i) => {
        let image = (item.thumbnail === '') ? logo : API_URL + item.thumbnail;

        return (
            <Fragment key={item.id}>
                <Row>
                    <Col xs={6}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col xs={2}>
                                        <img src={image} className="img-fluid rounded" />
                                    </Col>

                                    <Col xs={10}>
                                        <Row>
                                            <Col>Producto</Col>
                                            <Col>{item.title}</Col>
                                        </Row>
                                        <Row>
                                            <Col>Precio</Col>
                                            <Col>{item.price}</Col>
                                        </Row>
                                        <Row>
                                            <Col>Cantidad</Col>
                                            <Col>{item.quantity}</Col>
                                        </Row>
                                    </Col>
                                </Row>


                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </Fragment>
        )
    }

    const printDetail = (item, i) => {
        let { detail, client } = item || []
        return (
            <Fragment key={i}>
                <tr className="p-0 mx-0 border-bottom active_list_reservation m-0 w-100">
                    <td className="text-center border-right">

                        <span className="font-15 pt-3 pb-2 cursor-pointer" onClick={() => showDetail(item)}>
                            {item.id === selected.id ? 'Ocultar' : "Ver"}
                        </span>
                    </td>
                    <td className="text-center border-right"><span className="font-15 pt-3 pb-2">{item.id}</span></td>
                    <td className="text-center border-right"><span className="font-15 pt-3 pb-2">{item.address}</span></td>
                    <td className="text-center border-right"><span className="font-15 pt-3 pb-2">{client.name}</span></td>
                    <td className="text-center border-right"><span className="font-15 pt-3 pb-2">{client.phone}</span></td>
                    <td className="text-center border-right"><span className="font-15 pt-3 pb-2">{item.type_delivery_text}</span></td>
                    <td className="text-center border-right"><span className="font-15 pt-3 pb-2">{item.programmed}</span></td>

                </tr>
                <tr className={`p-0 mx-0 border-bottom active_list_reservation m-0 w-100 ${selected.id === item.id ? '' : 'd-none'}`}>
                    <td colSpan="6">

                        {detail.map(printProducts)}

                    </td>
                </tr>
            </Fragment >)
    }

    return (

        <Row className="py-2 w-100 mx-4 rounded-lg mt-2 bg-white">
            <table className="p-0 mx-3 bg-azul rounded-lg m-0 w-100">
                <thead>
                    <tr>
                        <td><h3 className="text-white text-center font-15 pt-3 pb-2 border-right">Ver</h3></td>
                        <td><h3 className="text-white text-center font-15 pt-3 pb-2 border-right">#</h3></td>
                        <td><h3 className="text-white text-center font-15 pt-3 pb-2 border-right">Address</h3></td>
                        <td><h3 className="text-white text-center font-15 pt-3 pb-2 border-right">Cliente</h3></td>
                        <td><h3 className="text-white text-center font-15 pt-3 pb-2 border-right">Telefono</h3></td>
                        <td><h3 className="text-white text-center font-15 pt-3 pb-2 border-right">Tipo</h3></td>
                        <td><h3 className="text-white text-center font-15 pt-3 pb-2 border-right">Fecha</h3></td>

                    </tr>
                </thead>
                <tbody>
                    {(data !== undefined) ? data.map(printDetail) : null}
                </tbody>


            </table>

            {/* <PaginationTable /> */}

        </Row>
    )
}

const mapsState = (props) => {
    return {
        list: props.order.data,
        fetching_programmed: props.order.fetching_programmed,
    }
}

export default connect(mapsState, { getOrderScheduled })(SchedulePage);