import React, { Fragment, useEffect, useState } from 'react'
import TemplateMenu from "../../template/menu.template";
import { connect } from 'react-redux';
import { getComparationSales } from '../../Redux/reportDuck'
import { Col, Row } from 'react-bootstrap';
import { BarChart, Bar, Cell, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ComposedChart, ResponsiveContainer, LineChart, PieChart, Rectangle, } from 'recharts';

const ComparationSalesPage = ({ getComparationSales, fetching_sales, data_sales }) => {

    const [list, setList] = useState([])
    const [data, setData] = useState([])

    const data2 = [
        {
            name: 'Enero',
            2022: 4000,
            2023: 2400,
        },
        {
            name: 'Febrer',
            2022: 4200,
            2023: 2400,
        },
        {
            name: 'Marzo',
            2022: 2000,
            2023: 2400,
        },
        {
            name: 'Abril',
            2022: 2100,
            2023: 2400,
        },
        {
            name: 'Mayo',
            2022: 2800,
            2023: 2400,
        },
        {
            name: 'Junio',
            2022: 2900,
            2023: 2400,
        },
        {
            name: 'Page G',
            2022: 4000,
            2023: 2400,
        },
    ];



    useEffect(() => {
        getComparationSales()

    }, [])

    useEffect(() => {
        if (fetching_sales === 2) {
            setList(data_sales.data)
            setData(data_sales.years)
        }
    }, [fetching_sales])


    return (
        <TemplateMenu>
            <Row className='my-2'>
                <Col className='text-center'><h4>Comparacion Ventas</h4></Col>
            </Row>
            <Row className=''>
                <Col className='d-flex justify-content-center'>
                    <BarChart
                        width={900}
                        height={500}
                        data={list}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 90,
                            bottom: 5,
                        }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        {data.map((d, k) => <Bar key={k} dataKey={d.text} fill={`${d.color}`} activeBar={<Rectangle fill="pink" stroke="red" />} />)}
                    </BarChart>
                </Col>
            </Row>
            {/* {list.map((d, k) => {
                let { months } = d

                let _data = months.map(m => {
                    m.name = m.month_text
                    return m
                })

                return <Fragment key={k}>
                    <Row>
                        <Col className='text-center'>{d.year}</Col>
                    </Row>
                    <Row>
                        <Col className='pl-5'>
                            <BarChart
                                width={800}
                                height={300}
                                data={_data}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 60,
                                    bottom: 5,
                                }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="total" fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} />
                                <Bar dataKey="uv" fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />} />

                            </BarChart>
                        </Col>
                    </Row>

                </Fragment>
            })
            } */}


        </TemplateMenu >
    )
}

const mapsState = (props) => {
    let { report } = props

    return {
        fetching_sales: report.fetching_sales,
        data_sales: report.data_sales,
    }
}

export default connect(mapsState, { getComparationSales })(ComparationSalesPage);