import React from 'react'
import { Col, Container, Modal, Row } from "react-bootstrap";

const ModalReserveRequisition = ({ show, onHide, onDeleteRequisition, onReverse, selected }) => {

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Modificación Requisición</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>REQUISICIÓN ID</Col>
                    <Col>{selected.id}</Col>

                </Row>
                <Row>
                    <Col>Sucursal Origen</Col>
                    <Col>{selected.branch.title}</Col>
                </Row>
                <Row>
                    <Col>Sucursal Destino</Col>
                    <Col>{selected.branch_request.title}</Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Container fluid>
                    <Row>
                        <Col className='d-flex justify-content-center'>
                            <button className='btn btn-danger' onClick={() => onDeleteRequisition(selected)}>Eliminar Requisición</button>
                        </Col>
                        <Col className='d-flex justify-content-center'>
                            <button className='btn btn-success' onClick={() => onReverse(selected)}>Reversar</button>
                        </Col>
                    </Row>
                </Container>

            </Modal.Footer>
        </Modal>
    )
}

export default ModalReserveRequisition;