import React, { Fragment, useEffect, useState } from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import { connect } from "react-redux";
import TemplateMenu from "../../template/menu.template";
import {
  getInventoryCounter,
  updateInventoryCounter,
  resetStatus,
} from "../../Redux/inventoryDuck";
import { getParameterBySegment } from "../../Redux/parameterDuck";
import ItemTableCounter from "./item.table.counter";
import moment from "moment";
import { toogleLoader } from "../../Redux/alertDuck";
import { FaCheck } from "react-icons/fa";


const CounterPage = ({ getInventoryCounter, fetching_list,
  data, updateInventoryCounter, resetStatus, getParameterBySegment, fetching_by_segment, data_segment, last_date,
  toogleLoader, fetching_update, row_update
}) => {
  const [list, setList] = useState([]);
  const [form, setForm] = useState({
    product_id: 0,
    registration_date: moment().format("YYYY-MM-DD"),
    daily: true,
    week: false,
    month: false,
    search: ''
  });
  const [listFilter, setListFilter] = useState([]);
  const [listControl, setListControl] = useState([]);

  const [focus, setFocus] = useState(0)

  useEffect(() => {
    let _dates = JSON.parse(localStorage.getItem("date"));

    let current_month = moment(_dates.date_end).format("M")
    let moment_month = moment().format("M")
    let _date = moment().format("YYYY-MM-DD")
    if (moment_month !== current_month) {
      _date = _dates.date_end
    }

    let _form = {
      ...form,
      ["registration_date"]: _date,
      "date_init": _dates.date_init,
      "date_end": _dates.date_end,
    };
    setForm(_form);
    getInventoryCounter(_form);
    getParameterBySegment("control_inventory");
  }, []);



  useEffect(() => {
    if (fetching_update === 2) {
      let _list = listFilter.map((d, k) => {
        if (d.id === row_update.control_inventory_id) {

          d.products.map((p, key) => {
            if (p.id === row_update.id) {
              d.products[key] = row_update;
            }
          })
        }
        return d
      })
      setList(_list)
      setListFilter(_list)

    }
  }, [fetching_update]);

  useEffect(() => {
    if (fetching_by_segment === 2) {
      setListControl(data_segment);
    }
  }, [fetching_by_segment]);

  useEffect(() => {
    if (fetching_list === 2) {
      resetStatus();
      setList(data);

      setListFilter(data);
      toogleLoader(false)
    }
  }, [fetching_list]);

  const updateInput = (e) => {
    let _form = {
      ...form,
      [e.target.name]: e.target.value,
    };
    setForm(_form);
    getInventoryCounter(_form);
  };

  const updateCheckBox = (e) => {
    let _form = {
      ...form,
      [e.target.name]: e.target.checked,
    };
    toogleLoader(true)

    setForm(_form);
    getInventoryCounter(_form);
  };

  const onUpdateItem = (row, amount, operations) => {
    let _form = row;

    _form.amount = amount;
    _form.operations = operations;
    _form.registration_date = form.registration_date;

    updateInventoryCounter(_form);
  }

  const onSearch = (e) => {
    let _list = list;

    let _form = form
    _form.search = e.target.value;
    getInventoryCounter(form);
  }

  const onSearchFilter = () => {
    toogleLoader(true)
    getInventoryCounter(form);
  }

  return (
    <TemplateMenu>
      <Row className="my-3">
        <Col>
          <h2>Conteo de Inventario</h2>
        </Col>
      </Row>
      <Row className="my-2 d-flex justify-content-center">
        <Col lg={11}>
          <Row>
            <Col lg={3}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1">
                <Form.Label>Producto</Form.Label>
                <Form.Control
                  type="text"
                  value={form.product}
                  name="product"
                  placeholder="Nombre"
                  onChange={onSearch}
                />
              </Form.Group>
            </Col>
            <Col lg={2}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Fecha</Form.Label>
                <Form.Control
                  type="date"
                  value={form.registration_date}
                  min={form.date_init}
                  max={form.date_end}
                  name="registration_date"
                  onChange={updateInput}
                />
              </Form.Group>
            </Col>
            <Col lg={1}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1">
                <Form.Label>Diario</Form.Label>
                <Form.Control
                  type="checkbox"
                  value={form.daily}
                  checked={form.daily}
                  name="daily"
                  onChange={updateCheckBox}
                />
              </Form.Group>
            </Col>
            <Col lg={1}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Semanal</Form.Label>
                <Form.Control
                  type="checkbox"
                  value={form.week}
                  checked={form.week}
                  name="week"
                  onChange={updateCheckBox}
                />
              </Form.Group>
            </Col>
            <Col lg={1}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" >
                <Form.Label>Mensual</Form.Label>
                <Form.Control
                  type="checkbox"
                  value={form.month}
                  checked={form.month}
                  name="month"
                  onChange={updateCheckBox}
                />
              </Form.Group>
            </Col>
            <Col lg={2} className="d-flex align-items-center">
              <button className="btn btn-sm btn-info" onClick={onSearchFilter}>
                Buscar
              </button>
            </Col>

          </Row>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col lg={11}>
          <Table className="table-bordered table-sm">
            <thead className="thead-dark">
              <tr>
                <th rowSpan={2}>GRUPO</th>
              </tr>
            </thead>
            <tbody>
              {listFilter.length > 0 ? (
                listFilter.map((row, k) => (
                  <Fragment key={k}>
                    <tr>
                      <td colSpan={4}>{row.description} {row.products.filter(d => d.amount === null).length > 0 ? <span className="text-muted font-12">Vacios({row.products.filter(d => d.amount === null).length})</span> : <FaCheck color="green" />} </td>
                    </tr>
                    <tr>
                      <td>
                        <Table className="table-sm table-hover">
                          <thead className="thead-dark">
                            <tr>
                              <th rowSpan={2}>Producto</th>
                              <th rowSpan={2}>Medida</th>
                              <th rowSpan={2}>Tipo de Control</th>
                              <th rowSpan={2} className="text-center">
                                Unidades
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {row.products.length > 0 ? row.products.map((d, key) => <ItemTableCounter
                              focus={focus}
                              row={d} key={key} onUpdate={onUpdateItem} />)
                              : <tr><td>No tienes Productos</td></tr>}
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                  </Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan={12} align="center">
                    No se encontraron registros
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </TemplateMenu>
  );
};
const mapsState = (props) => {
  let { inventory, product_inventory, parameter } = props;

  return {
    fetching_list: inventory.fetching_counter,
    data: inventory.data_counter,
    products: product_inventory.data,
    last_date: product_inventory.last_date,
    fetching_update: inventory.fetching_update,
    fetching_list_product: product_inventory.fetching_list,
    row_update: inventory.row,
    fetching_by_segment: parameter.fetching_by_segment,
    data_segment: parameter.data_segment,
  };
};

export default connect(mapsState, {
  getInventoryCounter,
  updateInventoryCounter,
  resetStatus,
  getParameterBySegment,
  toogleLoader
})(CounterPage);
