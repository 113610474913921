import axios from 'axios'

import { getHeaders, validateInput } from "../utils";

const API_URL = process.env.REACT_APP_API_URL;

let initialState = {
  fetching: 0,
  fetching_list: 0,
  fetching_create: 0,
  fetching_by_id: 0,
  fetching_asign: 0,
  data: [],
  errors: {},
  error: "",
  row: {},
  selected: {},
};

let LIST_COUPON = "LIST_COUPON";
let LIST_COUPON_SUCCESS = "LIST_COUPON_SUCCESS";
let CREATE_COUPON = "CREATE_COUPON";
let CREATE_COUPON_SUCCESS = "CREATE_COUPON_SUCCESS";
let COUPON_BY_ID = "COUPON_BY_ID";
let COUPON_BY_ID_SUCCESS = "COUPON_BY_ID_SUCCESS";
let CREATE_COUPON_ERROR = "CREATE_COUPON_ERROR";
let ASIGN_COUPON = "ASIGN_COUPON";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LIST_COUPON:
      return { ...state, fetching_list: 1 };
    case LIST_COUPON_SUCCESS:
      return { ...state, fetching_list: 2, data: action.payload, fetching_create: 0, fetching_by_id: 0, errors: {} };
    case CREATE_COUPON:
      return { ...state, fetching_create: 1 };
    case CREATE_COUPON_SUCCESS:
      return { ...state, fetching_create: 2 };
    case CREATE_COUPON_ERROR:
      return { ...state, fetching_create: 3, errors: action.payload };
    case ASIGN_COUPON:
      return { ...state, fetching_asign: 2, selected: action.row };
    case COUPON_BY_ID:
      return { ...state, fetching_by_id: 1 };
    case COUPON_BY_ID_SUCCESS:
      return { ...state, fetching_by_id: 2, row: action.payload };
    default:
      return state;
  }
}

export let getListCoupon = () => (dispatch, getState) => {
  dispatch({
    type: LIST_COUPON,
  });

  let branch = JSON.parse(localStorage.getItem("branch"));


  // let url = `${API_URL}/api/expense${q}${filter}`;
  let url = `${API_URL}/api/coupon?branch_id=${branch.id}`;

  fetch(url, {
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: LIST_COUPON_SUCCESS,
        payload: resp.results,
      });
    });
};

export let getCouponById = (id) => (dispatch, getState) => {
  dispatch({
    type: COUPON_BY_ID,
  });

  let { filter } = JSON.parse(localStorage.getItem("branch"));
  filter = "&" + filter;

  // let url = `${API_URL}/api/expense${q}${filter}`;
  let url = `${API_URL}/api/coupon/${id}`;

  fetch(url, {
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      dispatch({
        type: COUPON_BY_ID_SUCCESS,
        payload: resp,
      });
    });
};

export let onUpdateInvoice = (item) => (dispatch, getState) => {
  dispatch({
    type: CREATE_COUPON,
  });

  let rules = [
    { field: "name", msg: "El nombre es requerido" },
    { field: "phone", msg: "El celular es requerido" },
    { field: "email", msg: "El email es requerido" },
  ];

  let validate = validateInput(item, rules);


  if (validate !== null) {
    dispatch({
      type: CREATE_COUPON_ERROR,
      payload: validate,
    });
    return;
  }

  let { filter } = JSON.parse(localStorage.getItem("branch"));
  filter = "&" + filter;

  let url = `${API_URL}/api/coupon-detail/${item.id}`;

  fetch(url, {
    method: "PUT",
    body: JSON.stringify(item),
    headers: getHeaders(),
  })
    .then((resp) => resp.json())
    .then((resp) => {
      if (resp.ok === false) {
        dispatch({
          type: ASIGN_COUPON,
          payload: resp.msg,
          row: item
        });
      } else {
        dispatch({
          type: CREATE_COUPON_SUCCESS,
        });
      }

    });
};

export let onCreateOrUpdateCoupon = (form) => (dispatch, getState) => {
  dispatch({
    type: CREATE_COUPON,
  });

  let branch = JSON.parse(localStorage.getItem("branch"));

  // let url = `${API_URL}/api/expense${q}${filter}`;


  let rules = [
    { field: "name", msg: "El nombre es requerido" },
    { field: "code", msg: "El código es requerido" },
    { field: "amount", msg: "La cantidad es requerida" },
    { field: "value", msg: "El valor es requerido" },
  ];

  let validate = validateInput(form, rules);

  if (validate !== null) {
    dispatch({
      type: CREATE_COUPON_ERROR,
      payload: validate,
    });
    return;
  }
  let url = `${API_URL}/api/coupon`;
  let method = "POST"

  // if (form.id !== undefined) {
  //   url = `${API_URL}/api/coupon/${form.id}`;
  //   method = "PUT"
  // }


  let _form = new FormData()

  _form.append("id", (form.id === undefined) ? '' : form.id);
  _form.append("name", form.name);
  _form.append("date_init", form.date_init);
  _form.append("date_end", form.date_end);
  _form.append("code", form.code);
  _form.append("value", form.value);
  _form.append("branch_id", branch.id);
  _form.append("amount", form.amount);
  _form.append("content_email", form.content_email);

  if (form.header !== '') {
    _form.append("header", form.header);
  }
  if (form.body !== '') {
    _form.append("body", form.body);
  }
  if (form.confirmation_image !== '') {
    _form.append("confirmation_image", form.confirmation_image);
  }
  if (form.main_image !== '') {
    _form.append("main_image", form.main_image);
  }


  axios.post(`${API_URL}/api/coupon`, _form, {
    headers: getHeaders()
  })
    .then((resp) => {
      dispatch({
        type: CREATE_COUPON_SUCCESS,
      });
    });
};
