import moment from 'moment';
import React from 'react'
import { formatNumber } from '../../utils';

const ItemTableGroupPayroll = ({ item }) => {
    let { payroll } = item

    let total = parseFloat(payroll.salary_real) + parseFloat(payroll.bonification_real) + parseFloat(payroll.other_payment) - parseFloat(payroll.other_deductions) - parseFloat(payroll.deductions);

    return (
        <tr>
            <td>{moment(item.createdAt).format("YYYY-MM-DD")}</td>
            <td>{payroll.worker}</td>
            <td align='right'>{payroll.worked_days}</td>
            <td align='right'>{formatNumber(payroll.salary_real)}</td>
            <td align='right'>{formatNumber(payroll.bonification_real)}</td>
            <td align='right'>{formatNumber(payroll.deductions)}</td>
            <td align='right'>{formatNumber(payroll.other_deductions)}</td>
            <td align='right'>{formatNumber(payroll.other_payment)}</td>
            <td align='right'>{formatNumber(total)}</td>
        </tr>
    )
}

export default ItemTableGroupPayroll;